<div class="wrapper">
    <app-header-sidebar></app-header-sidebar>
  
    <div class="content-wrapper">
      <!-- Content Header (Page header) -->
      <div class="content-header">
        <div class="container-fluid">
          <div class="row mb-2" style="border: 1px solid #ced4da;border-radius: 0.25rem;">
            <div class="col-sm-6">
              <h3 class="m-0 text-dark" style="font-weight: bold;padding: 3px 0px 3px 5px;"> Configure 2D Bet Limit</h3>
            </div>
          </div>
          <!-- /.row -->
        </div><!-- /.container-fluid -->
      </div>
      <!-- /.content-header -->
  
      <!-- Main content -->
      <section class="content">
        <div class="container-fluid">
          <form>
            <div class="row">
  
              <div class="col-md-12">
                <!-- Nav tabs -->
                <ul class="nav nav-tabs nav-pills"> 
                 
                  <li>
                    <a id="pending-tab" data-toggle="tab" style="width:100% !important" href="#newAll" (click) ="clearData()">Configure Bet Limit (All)</a>
                  </li>

                  <li style="display: none;">
                    <a id="approve-tab" data-toggle="tab" style="width:100% !important" href="#editAll"> Edit All </a>
                  </li>

                  <li>
                    <a id="approve-tab" data-toggle="tab" style="width:100% !important" href="#editSpecific" (click) ="clearUsersData()"> Configure Bet Limit (Specific User) </a>
                  </li>

                  <!-- <li>
                    <a id="approve-tab" data-toggle="tab" style="width:100% !important" href="#editSpecific"> Edit Specific User </a>
                  </li>
                  -->

                  <li>
                    <a class="active"  id="rejected-tab" data-toggle="tab" style="width:100% !important" href="#betlimitUserList" (click) ="getAllData()"> Bet Limit User List</a>
                  </li>
                  <!-- <li>
                    <a id="inactived-tab" data-toggle="tab" style="width:100% !important" href="#inactived"> INACTIVED USER BY RESET QUESTION</a>
                  </li> -->
                </ul>
  
                <!-- Tab panes -->
                <div class="tab-content clearfix">
                  <div class="tab-pane" id="newAll">
                    <div class="card">
                      <div class="card-header">
                        <h3 class="card-title" style="font-weight: bold;"> Configure Bet Limit (All)</h3>
                      </div>
                      <div class="card-body">
                      <form>
                          <div class="row">
                            <label class="col-md-3"> 2D Bet Limit Amount (10:30 AM) </label>
                            <div class="form-group col-md-3">
                              <input class="form-control form-control-sm" [(ngModel)]="betLimitAmountModel.betlimit1030"
                                [ngModelOptions]="{standalone: true}">
                            </div>
                          </div>

                          <div class="row">
                            <label class="col-md-3"> 2D Bet Limit Amount (12:01 PM) </label>
                            <div class="form-group col-md-3">
                              <input  class="form-control form-control-sm" [(ngModel)]="betLimitAmountModel.betlimit1201"
                                [ngModelOptions]="{standalone: true}">
                            </div>
                          </div>

                          <div class="row">
                            <label class="col-md-3"> 2D Bet Limit Amount (02:30 PM) </label>
                            <div class="form-group col-md-3">
                              <input class="form-control form-control-sm" [(ngModel)]="betLimitAmountModel.betlimit230"
                                [ngModelOptions]="{standalone: true}">
                            </div>
                          </div>

                          <div class="row">
                            <label class="col-md-3"> 2D Bet Limit Amount (04:30 PM) </label>
                            <div class="form-group col-md-3">
                              <input  class="form-control form-control-sm" [(ngModel)]="betLimitAmountModel.betlimit430"
                                [ngModelOptions]="{standalone: true}">
                            </div>
                          </div>

                          <div class="row">
                            <label class="col-md-2"> Status </label>
                            <div class="form-group col-md-3 radio">
                              <div class="form-group clearfix">
                                <div class="icheck-success d-inline">
                                  <input type="radio" name="r3" checked id="radioSuccess1" value="all"
                                    [(ngModel)]="status" [ngModelOptions]="{standalone: true}">
                                  <label for="radioSuccess1"> All &nbsp;&nbsp; </label>
                                </div>
                                <!-- <div class="icheck-success d-inline" style="display: none;">
                                  <input type="radio" name="r3" id="radioSuccess2" value="single"
                                    [(ngModel)]="status" [ngModelOptions]="{standalone: true}">
                                  <label for="radioSuccess2">Single </label>
                                </div> -->
                              </div>
                            </div>
                          </div>

                          <div class="row" style="display: none;">
                              <label class="col-md-2"> Name or Phone Number </label>
                              <div class="form-group col-md-3">
                                  <input type="text" class="form-control form-control-sm"
                                  [(ngModel)]="name" [ngModelOptions]="{standalone: true}">
                              </div>
                          </div>

                          <div class="row">
                            <div class="col-md-2 col-sm-6" *ngIf="hardcode_add_bool === true">
                              <button type="button" class="btn btn-block btn-success" (click)="goSave()">Save</button>&nbsp;
                            </div>
                            <div class="col-md-2 col-sm-6">
                              <button type="button" class="btn btn-block btn-danger"
                              (click) = "ResetData()">Cancel</button>
                            </div>
                          </div>
                      </form>
                    </div>
                    </div>
                  </div> 


                  <div class="tab-pane show" id="newSpecific" style="display: none;">
                    <div class="card">
                      <div class="card-header">
                        <h3 class="card-title" style="font-weight: bold;"> Add New Bet Limit (Specific User)</h3>
                      </div>
                      <div class="card-body">
                        <form>
                            <div class="row">
                                <label class="col-md-2"> Phone Number </label>
                                <div class="form-group col-md-6">                             
                                  <div class="row">
                                    <div class="col-md-6">
                                      <div class="row">
                                      <div class="col-md-4">
                                        <select class="form-control form-control-sm" name="nprefix" id="nprefix"
                                        [(ngModel)]="nprefix" [ngModelOptions]="{standalone: true}">
                                          <option value="+959">+959</option>
                                          <option value="+66">+66</option>
                                        </select>
                                      </div>
                                      <div class="col-md-8">
                                        <input type="text" id="ephone" class="form-control form-control-sm"
                                        [(ngModel)]="nphone" [ngModelOptions]="{standalone: true}">
                                      </div>
                                    </div>
                                    </div>

                                    <div class="col-md-3">
                                      <button type="button" class="btn btn-primary" (click) ="getUser()">
                                        <span>
                                          <i class="fa fa-search" aria-hidden="true"></i>&nbsp;
                                        </span>Search
                                      </button>
                                    </div>

                                  </div>
                                </div>
                              
                            </div>

                            <div class="row">
                              <label class="col-md-2"> 2D Bet Limit Amount </label>
                                <div class="form-group col-md-3">
                                  <input class="form-control form-control-sm" [(ngModel)]="ntwodbetlimit"
                                    [ngModelOptions]="{standalone: true}">
                                </div>
                            </div>

                            <div class="row">
                              <div class="col-md-2 col-sm-6">
                                <button type="button" class="btn btn-block btn-success" (click)="editBetLimit()">Save</button>&nbsp;
                              </div>
                              <div class="col-md-2 col-sm-6">
                                <button type="button" class="btn btn-block btn-danger"
                                >Cancel</button>
                              </div>
                            </div>
                        </form>
                    </div>
                  </div>
                  </div>

                  <div class="tab-pane show" id="editSpecific">
                    <div class="card">
                      <div class="card-header">
                        <h3 class="card-title" style="font-weight: bold;">Configure Bet Limit (Specific User)</h3>
                      </div>
                      <div class="card-body">
                    <form>
                        <div class="row">
                            <label class="col-md-2"> Phone Number </label>
                            <div class="form-group col-md-6">                             
                              <div class="row">
                                <div class="col-md-6">
                                  <div class="row">
                                  <div class="col-md-4">
                                    <select class="form-control form-control-sm" name="prefix" id="prefix"
                                    [(ngModel)]="prefix" [ngModelOptions]="{standalone: true}">
                                      <option value="+959">+959</option>
                                      <option value="+66">+66</option>
                                    </select>
                                  </div>
                                  <div class="col-md-8">
                                    <input type="text" id="ephone" class="form-control form-control-sm"
                                    [(ngModel)]="ephone" [ngModelOptions]="{standalone: true}"  (input)="onInput($event)">
                                  </div>
                                </div>
                                </div>

                                <div class="col-md-3">
                                  <button type="button" class="btn btn-primary" (click) ="getUser()">
                                    <span>
                                      <i class="fa fa-search" aria-hidden="true"></i>&nbsp;
                                    </span>Search
                                  </button>
                                </div>

                              </div>
                            </div>
                        </div>
                       
                        <div class="row">
                          <label class="col-md-2"> 2D Bet Limit Amount (10:30 AM)</label>
                            <div class="form-group col-md-3">
                              <input class="form-control form-control-sm" [(ngModel)]="userList.betlimit1030"
                                [ngModelOptions]="{standalone: true}">
                            </div>
                        </div>

                        <div class="row">
                          <label class="col-md-2"> 2D Bet Limit Amount (12:01 PM)</label>
                            <div class="form-group col-md-3">
                              <input class="form-control form-control-sm" [(ngModel)]="userList.betlimit1201"
                                [ngModelOptions]="{standalone: true}">
                            </div>
                        </div>

                        <div class="row">
                          <label class="col-md-2"> 2D Bet Limit Amount (02:30 PM)</label>
                            <div class="form-group col-md-3">
                              <input class="form-control form-control-sm" [(ngModel)]="userList.betlimit230"
                                [ngModelOptions]="{standalone: true}">
                            </div>
                        </div>

                        <div class="row">
                          <label class="col-md-2"> 2D Bet Limit Amount (04:30 PM)</label>
                            <div class="form-group col-md-3">
                              <input class="form-control form-control-sm" [(ngModel)]="userList.betlimit430"
                                [ngModelOptions]="{standalone: true}">
                            </div>
                        </div>

                        <div class="row">
                          <div class="col-md-2 col-sm-6" *ngIf="hardcode_edit_bool === true">
                            <button type="button" class="btn btn-block btn-success" (click)="editBetLimit()">Save</button>&nbsp;
                          </div>
                          <div class="col-md-2 col-sm-6">
                            <button type="button" class="btn btn-block btn-danger"
                             (click) = "ResetData()">Cancel</button>
                          </div>
                        </div>
                    </form>
                    </div>
                    </div>
                  </div>
  
                  <div class="tab-pane show active" id="betlimitUserList">
                    <div class="card">
                      <div class="card-header">
                        <h3 class="card-title" style="font-weight: bold;"> Bet Limit User List</h3>
                      </div>
                      <div class="card-body">
                    <form>
                      <div class="row">
                        <div class="form-group col-md-2">
                          <label>Phone No</label>
                          <input class="form-control form-control-sm" [(ngModel)]="phoneNo" [ngModelOptions]="{standalone: true}"  (input)="onInput($event)">
                        </div>
                       
                        <div class="form-group col-md-2">
                          <label>Bet Limit Amount</label>
                          <input class="form-control form-control-sm" [(ngModel)]="limitAmt" [ngModelOptions]="{standalone: true}">
                        </div>

                        <div class="form-group col-md-2 col-sm-2 col-xs-2">
                          <label>&nbsp;&nbsp;</label>
                          <button id="search" type="button" class="btn btn-block btn-primary btn-sm" (click)="getAllData()">Search</button>
                        </div>

                      </div>
                     
                        <div class="col-md-12">
                          <div class="card">
                            <div class="card-header">
                              <h3 class="card-title" style="font-weight: bold;"> Bet Limit User List </h3>
                            </div>
                            <!-- /.card-header -->
                            <div class="card-body">
                              <table width='100%' id="{{'tblUser' + idIndex}}" datatable [dtTrigger]="dtTrigger" [dtOptions]="dtOptions" class="table table-bordered table-hover">
                                <thead>
                                <tr>
                                  <th style="font-family: unicode;">Name</th>
                                  <th style="font-family: unicode;">Phone No</th>
                                  <th style="font-family: unicode;">Wallet</th>
                                  <th style="font-family: unicode;">Limit Amount (10:30 AM)</th>
                                  <th style="font-family: unicode;">Limit Amount (12:01 PM)</th>
                                  <th style="font-family: unicode;">Limit Amount (02:30 PM)</th>
                                  <th style="font-family: unicode;">Limit Amount (04:30 PM)</th>
                                  <th style="font-family: unicode;">Bet Limit Updated Date</th>
                                  <th style="font-family: unicode;">Updated By</th>
                                  <th style="font-family: unicode;" *ngIf="hardcode_edit_bool===true">Action</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr *ngFor="let user of userBetLimitList">
                                  <td style="font-family: unicode;width: 150px;">{{user.name}}</td>
                           
                                  <!-- <td style="font-family: unicode;">
                                    <a [routerLink] = "['/configure-2D-bet-limit-cancel-detail', user.id]" style="text-decoration: underline; cursor: pointer;">{{user.phone_no}}
                                  </a></td> -->
                                  <td style="font-family: unicode;">
                                    <a [routerLink]="['/user-detail', user.id]" [queryParams]="{ type: 'twodbetlimituserlist' }">{{user.phone_no}}</a>
                                  </td>
                                  <!-- betlimituserlist -->
                                  <td style="font-family: unicode;" class="text-right">{{user.balance}}</td>
                                  <td style="font-family: unicode;" class="text-right">{{user.betlimit1030}} </td>
                                  <td style="font-family: unicode;" class="text-right">{{user.betlimit1201}} </td>
                                  <td style="font-family: unicode;" class="text-right">{{user.betlimit230}} </td>
                                  <td style="font-family: unicode;" class="text-right">{{user.betlimit430}} </td>
                                  <td style="font-family: unicode;">{{user.betlimit_updated_date | date:'yyyy-MM-dd HH:mm:ss'}}</td>
                                  <td style="font-family: unicode;">{{user.updated_by_name}}</td>
                                  <td style="font-family: unicode;" *ngIf="hardcode_edit_bool === true">
                                    
                                        <i class="fas fa-edit" style="color:#4b88eb" (click) = "getuserDetail(user.phone_no,user.betlimit1030,user.betlimit1201 ,user.betlimit230,user.betlimit430,user.betlimit_updated_date,user.name)"></i> 
                                      </td>
                                </tr>                        
                                </tbody>
                              </table>
                          
                                <tr *ngFor="let user of userBetLimitList | paginate
                                : {
                                    itemsPerPage: itemsPerPage,
                                    currentPage: page,
                                    totalItems: totalItems
                                  }" 
                                  >
                              </tr> 
                            <div class = "row">
                              <div class = "col-md-6"></div>
                              <div class = "col-md-6">
                                <pagination-controls
                                  class="pagi"
                                  (pageChange)="gty((page = $event))"
                                ></pagination-controls>
                            </div>
                          </div>
                            <!-- /.card-body -->
                          </div>
                        </div>
                        </div>
                      <!--</div>-->
                    </form>
                    </div>
                    </div>
                  </div>
                  <div class="tab-pane" id="inactived">
                  </div>
                </div>
              </div>
  
            </div>
          </form>
          <!-- /.row -->
  
          <!-- /.row -->
        </div>
        <!--/. container-fluid -->
      </section>
      <!-- /.content -->
    </div>
  
    <aside class="control-sidebar control-sidebar-dark">
      <!-- Control sidebar content goes here -->
    </aside>
  
    <app-footer></app-footer>
    <ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#fff" type="square-jelly-box">
      <p style="font-size: 16px; color: white">Loading...</p>
    </ngx-spinner>
  </div>
  
  <div id="deleteData" class="modal fade">
    <div class="modal-dialog">
        <div class="modal-content">
  
            <div class="modal-body">
                <form>
  
                    <div class="row" style="margin-top: 5%;margin-bottom: 3%;margin-left: 9%;">
                        <div class="form-group col-md-12">
                            <h5> This Account is used by another device. 
                                Please Sign Out. Login Again. </h5>
                        </div>
                    </div>
  
                    <div class="row" style="margin-left: 40%;margin-bottom: 3%;">
                        <div class="col-md-5 col-sm-6" id="deleteBtn">
                            <button type="button" class="btn btn-block btn-success" (click)="OkLogout()"> Ok </button>
                        </div>
                    </div>
                </form>
            </div> 
        </div>
    </div>
  </div> 


 
  <div id="showeditModal" class="modal fade">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h3 class="modal-title" style="font-weight: bold;font-size: 1.1rem;"> Detail </h3>
          <button type="button" class="close" data-dismiss="modal" aria-hidden="true"> x </button>
        </div>
        <div class="modal-body">
          <form>
            <div class="col-md-12">
              <div class="col-md-12">
                  <div class="card-body">
                    <table class="table table-bordered">
                      <tr>
                        <th>Name</th><td>{{detialName}}</td>
                      </tr>

                      <tr>
                        <th>Phone Number</th><td>{{detailph}}</td>
                      </tr>

                      <!-- <tr>
                        <th>Current Bet Limit (10:30 AM)</th>
                        <td>{{detialBetLimit}}</td>
                      </tr> -->

                      <!-- <tr>
                        <th>Current Bet Limit (12:01 PM)</th>
                        <td>{{detbetlimit1201}}</td>
                      </tr> -->

                      <!-- <tr>
                        <th>Current Bet Limit (02:30 PM)</th>
                        <td>{{detbetlimit230}}</td>
                      </tr> -->

                      <!-- <tr>
                        <th>Current Bet Limit (04:30 PM)</th>
                        <td>{{detbetlimit430}}</td>
                      </tr> -->

                      <tr>
                        <th>Bet Limit Updated Date</th>
                        <td>{{detailDate | date:'yyyy-MM-dd HH:mm:ss'}}</td>
                      </tr>

                      <tr>
                        <th>Type New Bet Limit(10:30 AM)</th>
                        <td>
                          <input class="form-control form-control-sm" [value]="detialBetLimit" [(ngModel)]="etwodbetlimit1" [ngModelOptions]="{standalone: true}">
                        </td>
                      </tr>

                      <tr>
                        <th>Type New Bet Limit(12:01 PM)</th>
                        <td>
                          <input class="form-control form-control-sm"  [value] = "detbetlimit1201" [(ngModel)]="etwodbetlimit2" [ngModelOptions]="{standalone: true}">
                        </td>
                      </tr>

                      <tr>
                        <th>Type New Bet Limit(02:30 PM)</th>
                        <td>
                          <input class="form-control form-control-sm" [value] = "detbetlimit230" [(ngModel)]="etwodbetlimit3" [ngModelOptions]="{standalone: true}">
                        </td>
                      </tr>

                      <tr>
                        <th>Type New Bet Limit(04:30 PM)</th>
                        <td>
                          <input class="form-control form-control-sm" [value] = "detbetlimit430" [(ngModel)]="etwodbetlimit4" [ngModelOptions]="{standalone: true}">
                        </td>
                      </tr>

                    </table>
                  </div>
              </div>
            </div>
          </form>
          <div class="row">
            <div class="col-md-4">
              &nbsp;
            </div>
            <div class="col-md-2">
              <button type="button" class="btn btn-block btn-success" (click)="editBetLimitModal()">Save</button>&nbsp;
            </div>
            
            <div class="col-md-2">
              <div style="display: inline-block;">              
                  <button type="button" class="btn btn-block btn-danger" data-dismiss="modal">Cancel</button>
              </div>
            </div>
          

       
        
        </div>
      </div>
    </div>
  </div>
   
  