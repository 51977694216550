// import { Component, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders ,HttpErrorResponse,HttpParams} from '@angular/common/http';
import 'rxjs/add/operator/map';
import { Router } from '@angular/router';

import { LocalStorageService } from 'ngx-webstorage';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from "ngx-spinner";


import { DtoService } from '../../service/dto.service';
import { UtilService } from '../../service/util.service';
import { FunctService } from '../../service/funct.service';
import { Component, OnInit,ViewChild, ElementRef, AfterViewInit } from '@angular/core';



//amk

import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';



//

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

   albumList : any;
  model :any;
  phone_no : any;
  password : any;
  token: any;
  errorMessage : any;
  mobNumberPattern = "^((\\+95-?)|0)?[0-9]{10}$";
  menuCodes :any;
  roleList:any;
  notidata: any;
  unreadCount:any;
  @ViewChild('loginButton') loginButton!: ElementRef<HTMLButtonElement>;
  @ViewChild('notificationSound', { static: false }) notificationSound!: ElementRef;


  constructor(private toastr: ToastrService, private spinner: NgxSpinnerService, private dto: DtoService, private http: HttpClient, private util: UtilService, private router: Router, private storage: LocalStorageService, private funct: FunctService)
  {
    this.dto.adminName ="";
    this.dto.loginIs='S';
  }

  ngAfterViewInit() {
    // Auto-focus on the login button after the view initializes
    this.loginButton.nativeElement.focus();
  }
  ngOnInit(): void {
    this.model = {
      phone_no: '',
      password: '',
    } //add new
     
  }

  login() 
  {
    this.spinner.show();
    let mobNumberPattern = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{3,6}$/im;
    if (mobNumberPattern.test(this.phone_no)) { //this.phonNo, this.password
      this.model.phone_no = this.phone_no; //AdminDTO
      this.model.password = this.password;//admin/login     
    
      console.log("Function ip address "+ this.funct.ipaddress);
      this.http.post( this.funct.ipaddress +'Authenticate/adLogin', this.model).subscribe(  
        result => {
           this.dto.Response = result;           
           if(this.dto.Response.menucodes !=null || this.dto.Response.menucodes != undefined)
           {
              this.menuCodes = this.dto.Response.menucodes;
              console.log("menu code : " + this.menuCodes)
              this.dto.menuCodes = this.dto.Response.menucodes;
              this.dto.adminName = this.dto.Response.adminName;

           }
           console.log("menu code : " + this.menuCodes)
       
           if (this.dto.Response.status != 'Error') {
           var token = result["token"];
           if(token != null)
           {
            this.util.isLoggedIn = true;
            this.dto.token = "Bearer "+token; 
            this.storage.store('token', this.dto.token);
            this.storage.store('menucodes',this.dto.menuCodes);
            this.storage.store('adminname',this.dto.adminName);
            this.storage.store('isOwnerLoggedIn', this.util.isLoggedIn);
            this.router.navigate(['/dashboard']);
          
           }
          }
          else {
            this.spinner.hide();
            this.toastr.error(this.dto.Response.message, 'Invalid!', {
              timeOut: 3000,
              positionClass: 'toast-top-right',
            });
          }
        }
      ); 

      //this.getRolesModuleList();

    }
    else {
      this.spinner.hide();
      this.toastr.error('Please enter valid phone no.', 'Invalid input!', {
        timeOut: 3000,
        positionClass: 'toast-top-right',
      });
    }

    this.dto.isNotiSong = "login";
    this.storage.store('isNotiSong', this.dto.isNotiSong);
  }


  // getRolesModuleList()
  // {
  //   this.roleList = [];
  //   this.dto.token = this.storage.retrieve('token');
  //   let headers = new HttpHeaders();
  //   headers = headers.set('Authorization', this.dto.token);
  //   let params = new HttpParams();
  //   params = params.set('roleId', localStorage.getItem("roleId"));
  //   const paramsObject = {};
  //   params.keys().forEach(key => {
  //       if (key === 'roleId') {
  //           paramsObject[key] = params.get(key); 
  //       } else {
  //           paramsObject[key] = params.getAll(key); 
  //       }
  //   });
  //   this.http.get(this.funct.ipaddress + 'admin/getrolemenu_byadmin', { params: params, headers: headers } )
  //   .pipe(
  //     catchError(this.handleError.bind(this))
  //    )
  //   .subscribe(
  //     result => {
  //       this.dto.Response = {};
  //       this.dto.Response = result;
  //       this.roleList = this.dto.Response;
  //       localStorage.setItem("roleList",this.roleList);
  //       this.storage.store('role_list',this.roleList);
  //     }
  //   );
  // }

  handleError(error: HttpErrorResponse){
    if(error.status == 423)
    {
     
    }
    if(error.status == 403)
    {
      
    }
    return throwError(error);
    }
    onKeydown(event: KeyboardEvent) {
      if (event.key === 'Enter' && document.activeElement === this.loginButton.nativeElement) {
        this.login();
      }
    }



  
 
}
