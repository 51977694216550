import { Component, OnInit ,ViewChild} from '@angular/core';
import { HttpClient, HttpHeaders,HttpErrorResponse } from '@angular/common/http';
import { Router, ActivatedRoute } from '@angular/router';
import { LocalStorageService } from 'ngx-webstorage';
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from 'ngx-toastr';
import { DtoService } from '../../service/dto.service';
import { FunctService } from '../../service/funct.service';
import { catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { Subject } from 'rxjs';
import { DataTableDirective } from 'angular-datatables';
declare var $: any;
@Component({
  selector: 'app-user-bet-limit',
  templateUrl: './user-bet-limit.component.html',
  styleUrls: ['./user-bet-limit.component.css']
})
export class UserBetLimitComponent implements OnInit {
  @ViewChild(DataTableDirective)
  dtElement: DataTableDirective;
  betlimit10_30:any;
  betlimit12_01:any;
  betlimit02_30:any;
  betlimit04_30:any;

  id: any;
  changedate: any;
  date: string = '';
  status: string = '';
  token: any;
  type : any;
  section:any;
  twodSet:any;
  twodValue:any;
  twodResult:any;
  isDone:any;
  manual10_30:any;
  manual12_01:any;
  manual02_30:any;
  manual04_30:any;
  manualOnOffList:any;
  idIndex:any;
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();
  constructor(private storage: LocalStorageService, private spinner: NgxSpinnerService, private toastr: ToastrService, private http: HttpClient, private dto: DtoService, private router: Router,
    private route: ActivatedRoute, private funct: FunctService) {
      this.section="Null";
      this.idIndex = 1;
      this.storage.store('isNotiSong', "");
     }
  ngOnInit(): void {
    if (!this.storage.retrieve('loadFlag')) {
      this.storage.store('loadFlag', 'noLoad');
      setTimeout(function () {
        location.reload();
      }, 1);
    }
    else {
      this.storage.clear('loadFlag');
    }
    this.dtOptions = {
      responsive: true,
      order: []
    }
    this.dtOptions.columnDefs = [
    ];
  }
  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }
  handleError(error: HttpErrorResponse){
    if(error.status == 423)
    {
      this.spinner.hide();
      $("#deleteData").modal("show");
    }
    if(error.status == 403)
    {
      this.spinner.hide();
      this.toastr.error("Limited Access.", 'Invalid!', {
        timeOut: 3000,
        positionClass: 'toast-top-right',
        });
    }
    return throwError(error);
    }
    OkLogout()
    {
      window.location.href ="./ad-login";
    } 
  onChangeDate() {
    $(document).ready(function () {
      this.date = $("#date").val();
    });
  }
  getTwodManualOnOff(){
    this.spinner.show();
    this.manualOnOffList =[];
    var id = 'tblManual' + this.idIndex;
    var table = $('#' + id).DataTable();
    table.destroy();
    this.idIndex = this.idIndex + 1;
    this.token = this.storage.retrieve('token');
    let headers = new HttpHeaders();
    headers = headers.set('Authorization', this.token);
    this.http.get(this.funct.ipaddress + 'back4app/GetManualOnOff', { headers: headers })
    .pipe(
      catchError(this.handleError.bind(this))
     )
    .subscribe(
      result => {
        this.dto.Response = {};
        this.dto.Response = result;
        this.manualOnOffList=this.dto.Response;
        this.dtTrigger.next();
        this.spinner.hide();
      });
  }
  goOnOffSave() {
    this.spinner.show();this.token = this.storage.retrieve('token');
    let headers = new HttpHeaders();
    headers = headers.set('Authorization', this.token);
    let param = {
      manual10_30 : this.manualOnOffList.filter(data => data.section == '10:30 AM')[0].switchManual,
      manual12_01 : this.manualOnOffList.filter(data => data.section == '12:01 PM')[0].switchManual,
      manual02_30 : this.manualOnOffList.filter(data => data.section == '02:30 PM')[0].switchManual,
      manual04_30 : this.manualOnOffList.filter(data => data.section == '04:30 PM')[0].switchManual,
    }
    this.http.post(this.funct.ipaddress + 'back4app/SetManualOnOff', param, { headers: headers })
    .pipe(
      catchError(this.handleError.bind(this))
     )
    .subscribe( 
      result => {
        this.dto.Response = {};
        this.dto.Response = result;
        if (this.dto.Response.status == 'Success') {
          this.spinner.hide();
          this.toastr.success(this.dto.Response.message, 'Success!', {
            timeOut: 5000,
            positionClass: 'toast-top-right'
          });
        }
        else {
          this.spinner.hide();
          this.toastr.error(this.dto.Response.message, 'Invalid!', {
            timeOut: 5000,
            positionClass: 'toast-top-right',
          });
        }
      }
    );
  }
  goConfigSave() {
    this.spinner.show();
    if (this.twodSet != '' && this.twodValue != '' && this.twodResult !='' && this.section!='Null') 
    {
      // const myanmarCharactersRegex = new RegExp('^[\u1000-\u109F\uAA60-\uAA7F\uA9E0-\uA9FF]+$');
      // if (myanmarCharactersRegex.test(this.twodSet)) 
      // {        
      //     this.spinner.hide();
      //     this.toastr.error( "Myanmar typing detected!", '', {
      //     timeOut: 5000,
      //     positionClass: 'toast-top-right',
      //     });   
      //     return;   
      // }
      // else if(myanmarCharactersRegex.test(this.twodValue))
      // {
      //   this.spinner.hide();
      //   this.toastr.error( "Myanmar typing detected!", '', {
      //   timeOut: 5000,
      //   positionClass: 'toast-top-right',
      //   });   
      //   return; 
      // }
      // else if(myanmarCharactersRegex.test(this.twodResult))
      // {
      //   this.spinner.hide();
      //   this.toastr.error( "Myanmar typing detected!", '', {
      //   timeOut: 5000,
      //   positionClass: 'toast-top-right',
      //   });   
      //   return; 
      // }
      // else if(myanmarCharactersRegex.test(this.section!))
      // {
      //   this.spinner.hide();
      //   this.toastr.error( "Myanmar typing detected!", '', {
      //   timeOut: 5000,
      //   positionClass: 'toast-top-right',
      //   });   
      //   return; 
      // }
      this.token = this.storage.retrieve('token');
      let headers = new HttpHeaders();
      let param = {
        section : this.section,
        set : this.twodSet,
        value : this.twodValue,
        result : this.twodResult,
        isDone : this.isDone
      }
      console.log("Save >>>>" +JSON.stringify(param))
      headers = headers.set('Authorization', this.token);
      this.http.post(this.funct.ipaddress + 'back4app/SetManualResult', param, { headers: headers })
      .pipe(
        catchError(this.handleError.bind(this))
       )
      .subscribe( 
        result => {
          this.dto.Response = {};
          this.dto.Response = result;
          if (this.dto.Response.status == 'Success') {
            this.spinner.hide();
            this.twodResult='';
            this.twodSet='';
            this.twodValue='';
            this.section='Null';
            this.toastr.success(this.dto.Response.message, 'Success!', {
              timeOut: 5000,
              positionClass: 'toast-top-right'
            });
          }
          else {
            this.spinner.hide();
            this.toastr.error(this.dto.Response.message, 'Invalid!', {
              timeOut: 5000,
              positionClass: 'toast-top-right',
            });
          }
        }
      );
    }
    else {
      this.spinner.hide();
      this.toastr.error('Please fill all fields', 'Invalid input!', {
        timeOut: 3000,
        positionClass: 'toast-top-right',
      });
    }
  }
  // checkUncheck()
  // {
  //   if($("#foo_bar").is(':checked'))
  //    {
  //     this.isMaintenance = true;
  //    }
  //   else
  //   {
  //     this.isMaintenance = false;
  //   }
      
  // }
}
