import { Component, OnInit ,ViewChild} from '@angular/core';
import { HttpClient, HttpHeaders,HttpErrorResponse, HttpParams } from '@angular/common/http';
import { Router, ActivatedRoute } from '@angular/router';
import { LocalStorageService } from 'ngx-webstorage';
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from 'ngx-toastr';
import { DtoService } from '../../service/dto.service';
import { FunctService } from '../../service/funct.service';
import { catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { Subject } from 'rxjs';
import { DataTableDirective } from 'angular-datatables';
declare var $: any;
@Component({
  selector: 'app-modern-internet',
  templateUrl: './modern-internet.component.html',
  styleUrls: ['./modern-internet.component.css']
})
export class ModernInternetComponent implements OnInit {
  token: any;
  Modern0930:any = "--";
  Internet0930:any = "--";
  Modern0230:any = "--";
  Internet0230:any = "--";

  hardcode_edit :any;
  hardcode_view:any;
  hardcode_edit_bool : any;
  hardcode_view_bool : any;
  
  constructor(private storage: LocalStorageService, private spinner: NgxSpinnerService, private toastr: ToastrService, private http: HttpClient, private dto: DtoService, private router: Router,
    private route: ActivatedRoute, private funct: FunctService) {
      this.storage.store('isNotiSong', "");

    this.hardcode_edit = "ModernInternet_Edit";
    this.hardcode_view = "ModernInternet_View";
    this.dto.menuCodes = this.storage.retrieve('menucodes');
    const filteredMenuCodeds_edit =[];
    const filteredMenuCodeds_view =[];
    this.dto.menuCodes.forEach(menuCode => {      
      if (menuCode === this.hardcode_edit) {
        filteredMenuCodeds_edit.push(menuCode);
      }
      if (menuCode === this.hardcode_view) {
        filteredMenuCodeds_view.push(menuCode);
      }
    });
      
      if(filteredMenuCodeds_edit.length != 0)
      {
          this.hardcode_edit_bool = true;
      }
      if(filteredMenuCodeds_view.length != 0)
      {
          this.hardcode_view_bool = true;
      }

      this.getModernInternetData();
  }
  ngOnInit(): void {
    if (!this.storage.retrieve('loadFlag')) {
      this.storage.store('loadFlag', 'noLoad');
      setTimeout(function () {
        location.reload();
      }, 1);
    }
    else {
      this.storage.clear('loadFlag');
    }
  }
  ngOnDestroy(): void {

  }
  handleError(error: HttpErrorResponse){
    if(error.status == 423)
    {
      this.spinner.hide();
      $("#deleteData").modal("show");
    }
    if(error.status == 403)
    {
      this.spinner.hide();
      this.toastr.error("Limited Access.", 'Invalid!', {
        timeOut: 3000,
        positionClass: 'toast-top-right',
        });
    }
    if(error.status == 404)
    {
      this.spinner.hide();
      this.toastr.error("Not Found", 'Invalid!', {
        timeOut: 3000,
        positionClass: 'toast-top-right',
        });
    }
    return throwError(error);
  }

  OkLogout()
  {
    window.location.href ="./ad-login";
  } 

  getModernInternetData(){
    this.spinner.show();
    this.token = this.storage.retrieve('token');
    let headers = new HttpHeaders();
    headers = headers.set('Authorization', this.token);
    this.http.get(this.funct.ipaddress + 'back4app/GetModernInternetData', { headers: headers })
    .pipe(
      catchError(this.handleError.bind(this))
     )
    .subscribe(
      result => {
        this.dto.Response = {};
        this.dto.Response = result;
        this.Modern0930=this.dto.Response.modern0930;
        this.Internet0930=this.dto.Response.internet0930;
        this.Modern0230=this.dto.Response.modern0230;
        this.Internet0230=this.dto.Response.internet0230;
        this.spinner.hide();
      });
  }

  save() {
    this.spinner.show(); 
    if (this.Modern0930 != '' && this.Internet0930 != '' && this.Modern0230 != '' && this.Internet0230 != '' && this.Modern0930 != null && this.Internet0930 != null && this.Modern0230 != null && this.Internet0230 != null ) {
      this.token = this.storage.retrieve('token');
      let headers = new HttpHeaders();   
      let param = {
        Modern0930 : this.Modern0930,
        Internet0930 : this.Internet0930,
        Modern0230 : this.Modern0230,
        Internet0230 : this.Internet0230
      }
      headers = headers.set('Authorization', this.token);
      this.http.post(this.funct.ipaddress + 'back4app/saveModernInternet', param, { headers: headers })
      .pipe(
        catchError(this.handleError.bind(this))
       )
      .subscribe( 
        result => {
          this.dto.Response = {};
          this.dto.Response = result;
          if (this.dto.Response.status == 'Success') {
            this.spinner.hide();            
            this.toastr.success(this.dto.Response.message, 'Success!', {
              timeOut: 5000,
              positionClass: 'toast-top-right'
            });
          }
          else {
            this.spinner.hide();
            this.toastr.error(this.dto.Response.message, 'Invalid!', {
              timeOut: 5000,
              positionClass: 'toast-top-right',
            });
          }
        }
      );
    }
    else {
      this.spinner.hide();
      this.toastr.error('Please fill all fields', 'Invalid input!', {
        timeOut: 3000,
        positionClass: 'toast-top-right',
      });
    }
  }
}
