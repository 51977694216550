<div class="wrapper">
  <app-header-sidebar></app-header-sidebar>

  <div class="content-wrapper">
    
    <div class="content-header">
      <div class="container-fluid">
     
       
      </div>
    </div>
   
    <section class="content">
      <div class="container-fluid">
        <form>
          <div class="row">

            <div class="col-md-12">
            

              <ul class="nav nav-tabs nav-pills">
                <li>
                  <a id="admindetail-tab" (click)="goToAdminDetail()" data-toggle="tab" href="#admindetail">Admin Detail</a>
                </li>
                <li>
                  <a id="resetpassword-tab" (click)="resettab()" data-toggle="tab" href="#resetpassword">Reset Password</a>
                </li>
              </ul>
              

              
              <div class="tab-content clearfix">
                <div class="tab-pane" id="admindetail">                 

                    <div class="col-md-12">
                      <div class="card">                          
                        <div class="card-body">
                          <form>        
                            <div class="row">
                              <label class="col-md-2"> Name </label>
                              <div class="form-group col-md-3">
                                <input class="form-control form-control-sm" [(ngModel)]="adminDTO.name"
                                  [ngModelOptions]="{standalone: true}">
                              </div>
                            </div>
        
                            <div class="row">
                              <label class="col-md-2"> Phone No </label>
                              <div class="form-group col-md-3">
                                <input class="form-control form-control-sm" [(ngModel)]="adminDTO.phone_no"
                                  [ngModelOptions]="{standalone: true}">
                              </div>
                              
                            </div>
        
                          
        
                            <div class="row">
                              <label class="col-md-2">Role</label>
                                <div class="form-group col-md-3">
                                 
                                <select  (change)="onRoleChange($event)" id = "role_id" class="form-control form-control-sm col-md-12 col-sm-4 col-xs-4"
                                 [(ngModel)]="adminDTO.role_id" [ngModelOptions]="{standalone: true}">
                                  <option *ngFor="let role of roleList" value = "{{role.id}}" >
                                    {{role.name}}
                                  </option>
                               </select>
                              </div> 
                              </div>
                          
        
                            <div class="row" id="password">
                              <label class="col-md-2"> Password </label>
                              <div class="input-group form-group col-md-3">
                                <input class="form-control form-control-sm" [(ngModel)]="adminDTO.password"
                                  [ngModelOptions]="{standalone: true}" [type]="fieldTextType ? 'text' : 'password'">
                                <div class="input-group-append">
                                  <span class="input-group-text">
                                    <i class="fa" [ngClass]="{'fa-eye-slash': !fieldTextType,'fa-eye': fieldTextType}"
                                      (click)="toggleFieldTextType()" style="color: #007bff;"></i>
                                  </span>
                                </div>
                              </div>
                            </div>
                           
        
                            <div class="row" id="confirmPassword">
                              <label class="col-md-2"> Confirm Password </label>
                              <div class="input-group form-group col-md-3">
                                <input class="form-control form-control-sm" [(ngModel)]="adminDTO.confirmPassword"
                                  [ngModelOptions]="{standalone: true}" [type]="confrimFieldTextType ? 'text' : 'password'">
                                <div class="input-group-append">
                                  <span class="input-group-text">
                                    <i class="fa" [ngClass]="{'fa-eye-slash': !confrimFieldTextType,'fa-eye': confrimFieldTextType}" 
                                    (click)="toggleConfrimFieldTextType()" style="color: #007bff;"></i>
                                  </span>
                                </div>
                              </div>
                            </div>
        
        
                            <div class="row">
                              <label class="col-md-2"> Status </label>
                              <div class="form-group col-md-3 radio">
                                <div class="form-group clearfix">
                                  <div class="icheck-success d-inline">
                                    <input type="radio" name="r3" checked id="radioSuccess1" value="ACTIVE"
                                      [(ngModel)]="adminDTO.status" [ngModelOptions]="{standalone: true}">
                                    <label for="radioSuccess1">Active &nbsp;&nbsp;
                                    </label>
                                  </div>
                                  <div class="icheck-success d-inline">
                                    <input type="radio" name="r3" id="radioSuccess2" value="INACTIVE"
                                      [(ngModel)]="adminDTO.status" [ngModelOptions]="{standalone: true}">
                                    <label for="radioSuccess2">Inactive
                                    </label>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="row">
                              <div class="col-md-2 col-sm-6">
                                <button type="button" class="btn btn-block btn-success" (click)="goSave()">Save</button>&nbsp;
                              </div>
                              <div class="col-md-2 col-sm-6">
                                <button type="button" class="btn btn-block btn-danger"
                                  [routerLink]="['/admin-list']">Cancel</button>
                              </div>
                            </div>

                             <!-- amk admindetail -->
                            <div class="row" id="updatedata">
                              <div class="col-md-12">
                                <div class="card">
                                  <div class="card-body">
                                    <form>
                                      <div class="row" style="margin-top: 2%;">
                                        <label class="col-md-2"> Created Date </label>
                                        <div class="form-group col-md-3">
                                          <input class="form-control form-control-sm" [(ngModel)]="adminDTO.created_date"
                                            [ngModelOptions]="{standalone: true}" disabled>
                                        </div>
                                        <div class="col-md-1"></div>
                                        <label class="col-md-2"> Created By</label>
                                        <div class="col-md-3">
                                          <input class="form-control form-control-sm" [(ngModel)]="adminDTO.created_by"
                                            [ngModelOptions]="{standalone: true}" disabled>
                                        </div>
                                      </div>

                                      <div class="row" style="margin-top: 1%;">
                                        <label class="col-md-2"> Updated Date </label>
                                        <div class="form-group col-md-3">
                                          <input class="form-control form-control-sm" [(ngModel)]="adminDTO.updated_date"
                                            [ngModelOptions]="{standalone: true}" disabled>
                                        </div>
                                        <div class="col-md-1"></div>
                                        <label class="col-md-2"> Updated By </label>
                                        <div class="col-md-3">
                                          <input class="form-control form-control-sm" [(ngModel)]="adminDTO.updated_by"
                                            [ngModelOptions]="{standalone: true}" disabled>
                                        </div>
                                      </div>

                                    </form>
                                  </div>
                                  
                                </div>
                              </div>
                            </div>



                          </form>
                        </div>
                        
                      </div>
                    </div>
            
                </div>



                <div class="tab-pane" id="resetpassword">
                  <div class="row">                    
                  </div>
                    <div class="col-md-12">
                      <div class="card">                      
                        <div class="card-body">                          
                          <form>      
                            <div class="row" id="password">
                              <label class="col-md-2"> Password </label>
                              <div class="input-group form-group col-md-3">
                                <input class="form-control form-control-sm" [(ngModel)]="adminDTO.password"
                                  [ngModelOptions]="{standalone: true}" [type]="fieldTextType ? 'text' : 'password'">
                                <div class="input-group-append">
                                  <span class="input-group-text">
                                    <i class="fa" [ngClass]="{'fa-eye-slash': !fieldTextType,'fa-eye': fieldTextType}"
                                      (click)="toggleFieldTextType()" style="color: #007bff;"></i>
                                  </span>
                                </div>
                              </div>
                            </div>
                           
        
                            <div class="row" id="confirmPassword">
                              <label class="col-md-2"> Confirm Password </label>
                              <div class="input-group form-group col-md-3">
                                <input class="form-control form-control-sm" [(ngModel)]="confirmPassword"
                                  [ngModelOptions]="{standalone: true}" [type]="confrimFieldTextType ? 'text' : 'password'">
                                <div class="input-group-append">
                                  <span class="input-group-text">
                                    <i class="fa" [ngClass]="{'fa-eye-slash': !confrimFieldTextType,'fa-eye': confrimFieldTextType}" 
                                    (click)="toggleConfrimFieldTextType()" style="color: #007bff;"></i>
                                  </span>
                                </div>
                              </div>
                            </div>
        
        
                            
                            <div class="row" *ngIf="hardcode_add_bool === true">
                              <div class="col-md-2 col-sm-6">
                              
                              </div>
                              <div class="col-md-2 col-sm-6">
                                <button type="button" class="btn btn-block btn-success" (click)="goSavePasswordReset()">Reset</button>&nbsp;
                              </div>
                            </div>
                            </form>                          

                        </div>
                      </div>
                    </div>
                 
                </div>

              </div>
            </div>

          </div>

        </form>
     
      </div>
   
    </section>
  
  </div>

  <aside class="control-sidebar control-sidebar-dark">
  
  </aside>

  <app-footer></app-footer>
  <ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#fff" type="square-jelly-box">
    <p style="font-size: 16px; color: white">Loading...</p>
  </ngx-spinner>
</div>
<div id="deleteData" class="modal fade">
  <div class="modal-dialog">
      <div class="modal-content">

          <div class="modal-body">
              <form>

                  <div class="row" style="margin-top: 5%;margin-bottom: 3%;margin-left: 9%;">
                      <div class="form-group col-md-12">
                          <h5> This Account is used by another device. 
                              Please Sign Out. Login Again. </h5>
                      </div>
                  </div>

                  <div class="row" style="margin-left: 40%;margin-bottom: 3%;">
                      <div class="col-md-5 col-sm-6" id="deleteBtn">
                          <button type="button" class="btn btn-block btn-success" (click)="OkLogout()"> Ok </button>
                      </div>
                  </div>
              </form>
          </div>

      </div>
  </div>
</div>

