<div class="wrapper">
    <app-header-sidebar></app-header-sidebar>
  
    <div class="content-wrapper">
      <div class="content-header">
        <div class="container-fluid">
          <div class="row mb-2" style="border: 1px solid #ced4da;border-radius: 0.25rem;">
            <div class="col-sm-8">
                <h3 class="m-0 text-dark" style="font-weight: bold;padding: 3px 0px 3px 5px;"> 2D Bet Amount Limitation </h3>
            </div>
        </div>
        </div>
      </div>
   
      <section class="content">
        <div class="container-fluid">
          <form>
            <div class="row">
              <div class="form-group col-md-2" style="display: none;">
                <label> Date </label>
                <input id="singleDate" readonly class="form-control form-control-sm" placeholder="Select Date" [ngxDatePicker]="dateInstanceSingle"
                  [value]="singleDate" (valueChange)="onChangeSingle()" [(ngModel)]="fordate" [ngModelOptions]="{standalone: true}">
                <ngx-date-picker #dateInstanceSingle></ngx-date-picker>
              </div>
  
              <div class="form-group col-md-2">
                <label> Number </label>
                <input type="text" class="form-control form-control-sm" [(ngModel)]="number" [ngModelOptions]="{standalone: true}">
              </div>

               <div class="form-group col-md-2">
                <label> Amount </label>
                <input type="text" class="form-control form-control-sm" [(ngModel)]="amount" [ngModelOptions]="{standalone: true}">
              </div>

              <div class="form-group col-md-2">
                <label>Time</label>
                <select class="time form-control form-control-sm col-md-12 col-sm-4 col-xs-4" [(ngModel)]="time"
                [ngModelOptions]="{standalone: true}">
                <option value="null">ALL</option>
                <option *ngFor="let objSection of sectionList" value = "{{objSection.sectionName}}" >
                  {{objSection.sectionName}}
                </option>
                </select>
              </div>
              
              <div class="form-group col-md-2 col-sm-4 col-xs-4" *ngIf="hardcode_view_bool === true">
                <label></label>
                <button id="serach" type="button" class="btn btn-block btn-primary btn-sm" style="margin-top: 4%;" (click)="search()"> Search </button>
              </div>

              <div class="form-group col-md-2 col-sm-4 col-xs-4" *ngIf="hardcode_edit_bool === true">
                <label></label>
                <button id="serach" type="button" class="btn btn-block btn-danger btn-sm"  style="background-color: #bb1717; color: white; margin-bottom: 12%; margin-top: 4%;" (click)="exportExcel()"> Export </button>
              </div>
      
            
              <div class="form-group col-md-2 col-sm-4 col-xs-4" style="display: none;">
                <label></label>
                <button id="add" type="button" class="btn btn-block btn-success btn-sm" style="margin-top: 4%;" [routerLink]="['/twod-bet-amount-limitation-detail']"> Add New </button>
              </div>
  
              <div class="col-md-12">
                <div class="card">
                  <div class="card-header">
                    <h3 class="card-title" style="font-weight: bold;">2D Bet Amount Limitation</h3>
                  </div>
                  <!-- /.card-header -->
                  <div class="card-body">
                    <div class = "row">
                      <div class="col-md-2 col-sm-4 col-xs-4" style="margin-left: -0.7%;display: none;">
                        <label></label>
                          <button id="confirm" type="button" class="btn btn-block btn-danger btn-sm"
                              style="margin-bottom: 12%;" (click)="delAll()"> Delete All  </button>
                      </div>

                      <div class="col-md-2 col-sm-4 col-xs-4" style="margin-left: -0.7%;"  *ngIf="hardcode_edit_bool === true">
                        <label></label>
                          <button id="confirm" type="button" class="btn btn-block btn-success btn-sm"
                              style="margin-bottom: 12%;" (click)="showeditAllModal()"> Edit All  </button>
                      </div>  

                      <div class="col-md-2 col-sm-4 col-xs-4" style="margin-left: -0.7%;" >                        
                     </div>

                     <div class="col-md-2 col-sm-4 col-xs-4" style="margin-left: -0.7%;" >                        
                    </div>                 
                      
                      <div class="col-md-2 col-sm-4 col-xs-4" style="margin-left: -0.7%;" >
                        <label>Total Limit Amount</label>
                        <button id="confirm" type="button" class="btn btn-block btn-sm"
                            style="background-color: #17A2BB; color: white; margin-bottom: 12%;">
                            <span>{{ total_bet_limit_amount }}</span>
                        </button>

                      </div>
                     

                      <div class="col-md-2 col-sm-4 col-xs-4" style="margin-left: -0.7%;" >
                        <label>Total Bet Amount</label>
                        <button id="confirm" type="button" class="btn btn-block btn-sm"
                            style="background-color: #FFC107; color: white; margin-bottom: 12%;">
                            <span>{{ total_bet_amount_Str }}</span>
                        </button>

                      </div>

                      <div class="col-md-2 col-sm-4 col-xs-4" style="margin-left: -0.7%;" >
                        <label>%</label>
                        <button id="confirm" type="button" class="btn btn-block btn-sm"
                            style="background-color: #17A2BB; color: white; margin-bottom: 12%;">
                            <span>{{ total_percentage }}</span>
                        </button>

                      </div>

                    

                    
                      
                      

                    </div>
                    <table width='100%' id="{{'tblTwoD' + idIndex}}" datatable [dtTrigger]="dtTrigger" [dtOptions]="dtOptions" class="table table-bordered table-hover">
                      <thead>
                      <tr>
                        <th>
                          <input type="checkbox" name="winnerscheck"
                              (click)="checkuncheckall()"
                              style="width: 1.10rem;height: 1.10rem;margin-left: 28%;"
                              (change)="changeSelection($event,0)">
                       </th>
                       !<!-- <th style="font-family: unicode;">For Date</th>--> 
                        <th style="font-family: unicode;">Time</th>
                        <th style="font-family: unicode;">2D Number</th>
                        <th style="font-family: unicode;">Limited Amount</th>
                        <th style="font-family: unicode;">Total Bet Amount</th>
                        <th style="font-family: unicode;">%</th>
                        <th style="font-family: unicode;"> Different Amount</th>
                        <th style="font-family: unicode;">Created Date</th>
                        <th style="font-family: unicode;">Created By</th>
                       <th style="font-family: unicode;">Updated Date</th>
                        <th style="font-family: unicode;">Updated By</th>
                        <th style="font-family: unicode;" *ngIf="hardcode_edit_bool === true">Action</th>
                      </tr>
                      </thead>
                      <tbody>
                      <tr *ngFor="let twodbet of twodbetamountlimitList">
                        <td>
                          <input type="checkbox" name="winnerscheck" [checked]="isChecked"
                              value="{{twodbet.id}}"
                              style="width: 1.10rem;height: 1.10rem;margin-left: 28%;"
                              (change)="changeSelection($event,twodbet.id)">
                      </td>
                       <!-- <td style="font-family: unicode;">{{twodbet.date_Str}}</td> -->
                        <td style="font-family: unicode;">{{twodbet.section}}</td>
                        <td style="font-family: unicode;">{{twodbet.number}}</td>
                        <td style="font-family: unicode;">{{twodbet.max_amt_Str}}</td>
                        <td style="font-family: unicode;">{{twodbet.total_bet_amount_Str}}</td> 
                        <td style="font-family: unicode;">{{twodbet.percentage}}</td>                         
                        <td style="font-family: unicode;">{{twodbet.diffAmt |  number:0 }}</td>
                        <td style="font-family: unicode;">{{twodbet.created_date}}</td>
                        <td style="font-family: unicode;">{{twodbet.created_by_name}}</td>
                        <td style="font-family: unicode;">{{twodbet.updated_date}}</td>
                        <td style="font-family: unicode; ">{{twodbet.updated_by_name}}</td>
                        <td style="font-family: unicode;" *ngIf="hardcode_edit_bool === true">
                          <a [routerLink] = "['/twod-bet-amount-limitation-detail-update', twodbet.id]">
                            <i class="fas fa-edit" style="color:blue; cursor: pointer;"></i>
                          </a>
                          &nbsp;&nbsp;
                          <a style="display: none;" (click)="twoDbetdelete(twodbet.id)">
                          <i class="fas fa-trash" style="color:#f40230;cursor: pointer;"></i>
                          </a>
                       
                        </td>
                      </tr>                        
                      </tbody>
                    </table>
                  </div>
                  <!-- /.card-body -->
                </div>
              </div>
  
            </div>
          </form>
          <!-- /.row -->
  
          <!-- /.row -->
        </div>
        <!--/. container-fluid -->
      </section>
      <!-- /.content -->
    </div>
  
    <aside class="control-sidebar control-sidebar-dark">
      <!-- Control sidebar content goes here -->
    </aside>
  
    <app-footer></app-footer>
    <ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#fff" type="square-jelly-box">
      <p style="font-size: 16px; color: white">Loading...</p>
    </ngx-spinner>
  </div>


  <div id="showeditModal" class="modal fade">
    <div class="modal-dialog">
        <div class="modal-content" style="width: 500px;">
  
          <div class="modal-body">
            <form>
              <div class="col-md-12">
    
                <div class="row" style="margin-left: 90px;">
                  <label class="col-md-2"> Amount </label>
                </div>
                <div class="row" style="margin-left: 90px;">
                  <div class="form-group col-md-8">
                    <input class="form-control form-control-sm" [(ngModel)]="editAmt" [ngModelOptions]="{standalone: true}" >
                  </div>
                </div>

                <div class="row" style="margin-left: 90px;">
                  <div class="col-md-3 col-sm-3" id="editBtn">
                      <button type="button" class="btn btn-block btn-success" (click)="editAll()"> Ok </button>
                  </div>

                  <div class="col-md-3 col-sm-3">
                      <button type="button" class="btn btn-block btn-danger" [routerLink]="['/twod-bet-amount-limitation-list']"> Cancel </button>
                  </div>
                </div>

              </div>
            </form>
          </div>
  
        </div>
    </div>
  </div>


  <div id="deleteData" class="modal fade">
    <div class="modal-dialog">
        <div class="modal-content">

            <div class="modal-body">
                <form>

                    <div class="row" style="margin-top: 5%;margin-bottom: 3%;margin-left: 9%;">
                        <div class="form-group col-md-12">
                            <h5> This Account is used by another device. 
                                Please Sign Out. Login Again. </h5>
                        </div>
                    </div>

                    <div class="row" style="margin-left: 40%;margin-bottom: 3%;">
                        <div class="col-md-5 col-sm-6" id="deleteBtn">
                            <button type="button" class="btn btn-block btn-success" (click)="OkLogout()"> Ok </button>
                        </div>
                    </div>
                </form>
            </div>

        </div>
    </div>
</div>
