import { Component, OnInit, ViewChild } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,HttpErrorResponse } from '@angular/common/http';
import { Router, NavigationEnd,ActivatedRoute } from '@angular/router';
import { DataTableDirective } from 'angular-datatables';

import { catchError, retry } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { ToastrService } from 'ngx-toastr';

import { LocalStorageService } from 'ngx-webstorage';
import { NgxSpinnerService } from "ngx-spinner";
import { FunctService } from '../../service/funct.service';
import { DtoService } from '../../service/dto.service';
import { DatePipe } from '@angular/common'
import Responsive from 'datatables.net-responsive';
import { Subject } from 'rxjs';
declare var $: any;


@Component({
  selector: 'app-user',
  templateUrl: './search-member.component.html',
  styleUrls: ['./search-member.component.css'],
  providers: [DatePipe]//amk
})

export class SearchMemberComponent implements OnInit {

  @ViewChild(DataTableDirective)
  dtElement: DataTableDirective;
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();

  phoneNo: string= '';
  temp_phoneNo: string= '';
  name: string= '';
  referralCode: string= '';
  status: string= '';
  search_phone : string ='';
  status_bk :any;
  userList: any;
  idIndex: any;
  approvedate : any;
  approvetodayDate : any;
  approveDate : any;
  allchangedate : any;

  approvetodate : any;
  allchangetodate : any;
  approvetodayToDate : any;
  approvetoDate : any;

  config: any;
  collection = [];
  page = 1;
  passenger: any; 
  itemsPerPage =  10;
  totalItems : any; 
  itemsPerPageFront =  10;
  appVersionList :[];
  appVersion : any;

  newTotalItems : any;

  startDate: string;
  endDate: string;
  selectedPreset: string;


  hardcode_edit :any;
  hardcode_add :any;
  hardcode_view: any;
  hardcode_delete_bool : any;
  hardcode_add_bool : any;
  hardcode_edit_bool : any;
  type:any;
  search_name :any;


  constructor(private storage: LocalStorageService,private datepipe: DatePipe, private toastr: ToastrService,private spinner: NgxSpinnerService, private http: HttpClient, private dto: DtoService, private router: Router, 
    private route: ActivatedRoute, private funct: FunctService,) 
    {
      this.storage.store('isNotiSong', "");
    this.idIndex = 1;
    this.approvedate = new Date();    
    this.approvetodayDate = this.datepipe.transform(this.approvedate, 'MMM dd, yyyy');
    this.approvetodate = new Date();
    this.approvetodayToDate = this.datepipe.transform(this.approvetodate, 'MMM dd, yyyy');
    this.hardcode_edit = "SearchMember_Edit";   
    this.dto.menuCodes = this.storage.retrieve('menucodes');   
    this.type = this.route.snapshot.paramMap.get("type");
    const filteredMenuCodeds_edit =[];
    this.dto.menuCodes.forEach(menuCode => {     
      if (menuCode === this.hardcode_edit) {
        filteredMenuCodeds_edit.push(menuCode);
      }
      });      
      if(filteredMenuCodeds_edit.length != 0)
      {
         this.hardcode_edit_bool = true;
      }
  
      this.dto.isAccMember = "h";
    this.dto.isUseSearch = true;  
    if(this.type == "d")
    {     
      this.status = "+959";
      if(this.dto.isSearchMember == "h" ) 
      {
        this.storage.store('phoneNo', ''); 
        this.storage.store('status', '');       
      }
      else
      {  
          if (!this.storage.retrieve('phoneNo')) {
            this.phoneNo = '';
          }
          else {
            this.phoneNo = this.storage.retrieve('phoneNo');
          }        
      
          if (!this.storage.retrieve('status')) 
          {
            this.status = '';
          }
          else {
            this.status = this.storage.retrieve('status');
            
          }
      } 
      this.status = "+959";
    }  
    else
    {
      this.storage.store('phoneNo', '');  
      this.status = "+959";
    }
    this.getAllAppVersion();
    this.getAllData();  
    
   }

  ngOnInit(): void {
    //this.getAllData(); 
    this.router.routeReuseStrategy.shouldReuseRoute = function () 
    {
      return false;
    };

    this.router.events.subscribe((evt) => {
      if (evt instanceof NavigationEnd) {       
        this.router.navigated = false;        
        window.scrollTo(0, 0);
      }
    });

    this.dtOptions = {
      responsive: true,
    //   responsive: {
    //     details: {
    //         renderer: Responsive.renderer.listHiddenNodes()
    //     }
    // },
      order:[],
      paging: true,
      info : true,
      dom: "Bfrltip", 
    }
    this.dtOptions.columnDefs = [
    ];

    if(!this.storage.retrieve('loadFlag')){
      this.storage.store('loadFlag', 'noLoad');
      setTimeout(function(){
        location.reload();
      }, 5);
    }
    else{
      this.storage.clear('loadFlag');
    }
  }

  onInput(event: InputEvent) {
    const myanmarCharactersRegex = new RegExp('^[\u1000-\u109F\uAA60-\uAA7F\uA9E0-\uA9FF]+$');
    const inputValue = (event.target as HTMLInputElement).value; 
    if(event.data!=null)
    {
      if (myanmarCharactersRegex.test(inputValue)) 
      {        
        this.spinner.hide();
        this.toastr.error( "Myanmar typing detected!", '', {
        timeOut: 5000,
        positionClass: 'toast-top-right',
        });  
      }
    }   
    
  }
  
  setPreset() {
    const today = new Date();
    let start;
    let end;

    switch (this.selectedPreset) {
      case 'today':
        start = today;
        end = today;
        break;
      case 'yesterday':
        start = new Date(today.getTime() - 24 * 60 * 60 * 1000);
        end = start;
        break;
      case 'last7days':
        start = new Date(today.getTime() - 7 * 24 * 60 * 60 * 1000);
        end = today;
        break;
      case 'last30days':
        start = new Date(today.getTime() - 30 * 24 * 60 * 60 * 1000);
        end = today;
        break;
      case 'thisweek':
        start = new Date(today.getTime() - (today.getDay() - 1) * 24 * 60 * 60 * 1000);
        end = today;
        break;
      case 'lastweek':
        start = new Date(today.getTime() - (today.getDay() + 6) * 24 * 60 * 60 * 1000);
        end = new Date(today.getTime() - (today.getDay() - 1) * 24 * 60 * 60 * 1000);
        break;
     }
}
  ngAfterViewInit(){
  }

  handleError(error: HttpErrorResponse){
    if(error.status == 423)
    {
      this.spinner.hide();
      $("#deleteData").modal("show");
    }
    if(error.status == 403)
    {
      this.spinner.hide();
      var id = 'tblUser' + this.idIndex;
      var table = $('#' + id).DataTable();
      this.toastr.error("Limited Access.", 'Invalid!', {
        timeOut: 3000,
        positionClass: 'toast-top-right',
        });
    }
    return throwError(error);
    }
    OkLogout()
    {
      window.location.href ="./ad-login";
    }  
 
    getAllData() 
    {     
      this.userList = [];
      var id = 'tblUser' + this.idIndex;
      var table = $('#' + id).DataTable();
      table.destroy();
      this.idIndex = this.idIndex +1;
      this.spinner.show();
      this.dto.token = this.storage.retrieve('token');  
      this.phoneNo = this.storage.retrieve('phoneNo'); 
      this.status_bk = this.storage.retrieve('status');  
      if(this.status_bk =="" || this.status_bk == undefined)
      {
        this.status = this.status;

      } 
      if(this.name == undefined || this.name =="")
      {
          this.search_name ="";
      }
      else 
      {
        this.search_name = this.name;
      }
      if(this.phoneNo =="" || this.phoneNo == undefined)
      {
          this.search_phone ="";
      }
      else 
      {
        this.search_phone = this.status +this.phoneNo;
      }

      if (!this.search_name && !this.search_phone) 
      {
        this.dto.Response = {};
        this.dto.Response = [];
        this.userList = this.dto.Response;
        this.dtTrigger.next();
        this.spinner.hide();   
        return;
      }
      
      let headers = new HttpHeaders();
      headers = headers.set('Authorization',  this.dto.token);
      let params = new HttpParams();  
      
        params = params.set('phoneNo', this.search_phone).set('name', this.search_name);      
        this.http.get(this.funct.ipaddress + 'user/userByparams_detail', { params: params, headers: headers } ).subscribe((data: any) => 
        {
          //userByparams_detail_byPhoneNo
          this.dto.Response = {};
          this.dto.Response = data;
          this.userList = this.dto.Response;
          this.totalItems = data.totalRows;
          this.dtTrigger.next();
          this.spinner.hide();
        })
      
     
    }

    // getAllData() {
    //   this.userList = [];
    //   const id = 'tblUser' + this.idIndex;
    //   const table = $('#' + id).DataTable();
    //   table.destroy();
    //   this.idIndex += 1;
    
    //   this.spinner.show();
    //   this.dto.token = this.storage.retrieve('token');
    //   this.phoneNo = this.storage.retrieve('phoneNo');
    //   this.status_bk = this.storage.retrieve('status') || "";
    
    //   this.status = this.status_bk || this.status;
    //   this.search_name = this.search_name || "";
    //   this.search_phone = this.phoneNo ? this.status + this.phoneNo : "";
    
    //   // Return early if both search_name and search_phone are empty
    //   if (!this.search_name && !this.search_phone) 
    //   {
    //     this.spinner.hide();     
    //     return;
    //   }
    
    //   const headers = new HttpHeaders().set('Authorization', this.dto.token);
    //   let params = new HttpParams().set('phoneNo', this.search_phone).set('name', this.search_name);
    
    //   this.http.get(`${this.funct.ipaddress}user/userByparams_detail`, { params, headers }).subscribe(
    //     (data: any) => {
    //       this.dto.Response = data;
    //       this.userList = data;
    //       this.totalItems = data.totalRows;
    //       this.dtTrigger.next();
    //       this.spinner.hide();
    //     },
    //     (error) => {
    //       console.error('Error fetching data:', error);
    //       this.spinner.hide();
    //     }
    //   );
    // }
    

    

    getAllAppVersion()
    {
      this.dto.token = this.storage.retrieve('token');
      let headers = new HttpHeaders();
      headers = headers.set('Authorization', this.dto.token);
      let params = new HttpParams();
      this.http.get(this.funct.ipaddress + 'user/getAllAppVersion', {headers: headers }).subscribe(
        result => {
          this.dto.Response = {};
          this.dto.Response = result;
          this.appVersionList = this.dto.Response;
        }
      );
    }

  onChangeApprove() {
    $(document).ready(function () {
      this.approveDate = $("#approveDate").val();
    });
  }

  onChangeApproveTo()
  {
    $(document).ready(function () {
      this.approvetoDate = $("#approvetoDate").val();
    });
  }
  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }
  pageChange(newPage: number) {
    this.router.navigate(['/search-member-list'], { queryParams: { page: newPage } });
  }
  onChangePhoneNo() {     
    this.storage.store('phoneNo', this.phoneNo.toString());  
  }

  onChangeName() {     
    this.storage.store('search_name', this.search_name.toString());  
  }
  onChangeTagSection() {     
    this.storage.store('status', this.status.toString());  
  }

}
