
<div class="wrapper" ng-app="" >
  <app-header-sidebar></app-header-sidebar>

      <div class="content-wrapper">
        <!-- Content Header (Page header) -->
        <div class="content-header">
          <div class="container-fluid">
           
          </div><!-- /.container-fluid -->
        </div>
        <!-- /.content-header -->
    
        <!-- Main content -->
        <section class="content">
          <div class="container-fluid">
           
            <div class="card">
              <div class="card-header">
                  <h3 class="card-title" style="font-weight: bold;"> First Topup Promotion User Report</h3>
              </div>
              <!-- /.card-header -->
              <div class="card-body">
              <form>
                <div class="row">
                  <div class="form-group col-md-2">
                      <label>First Topup From Date </label>
                  </div>
                  <div class="form-group col-md-3">
                    <input type = "date" [(ngModel)]="FromDate" name = "FromDate" class="form-control form-control-sm" id = "FromDate">
                  </div>
                </div>
                <div class="row">
                  <div class="form-group col-md-2">
                      <label>First Topup To Date </label>
                  </div>
                  <div class="form-group col-md-3">
                    <input type = "date" [(ngModel)]="ToDate" name = "ToDate" class="form-control form-control-sm" id = "ToDate">
                  </div>
                </div>
                <div class="row">
                  <div class="form-group col-md-2">
                    <label>Select Status</label>   
                  </div>                              
                  <div class="form-group col-md-3" >
                      <ngx-bootstrap-multiselect [options]="myOptions" [(ngModel)]="optionsModel" (ngModelChange)="onChange()"
                      [ngModelOptions]="{standalone: true}" class = "form-control-sm" style="margin-left : -7px" [texts]="myTexts" [settings]="mySettings">
                      </ngx-bootstrap-multiselect>                     
                  </div> 
                </div>
                <div class="row">
                  <div class = "col-lg-2">
                    <label> Choose Option</label>
                  </div>

                  <div class="form-group col-md-5" style="cursor: pointer;">
                    <img *ngIf="!clickExcel" src="assets/image/excel_pm.png" (click) = "getReportOption('excel')"
                      style="opacity: 0.3; height : 60px ;width: 60px;object-fit: cover;padding-left : 0px;border: 3px solid #ffffff;border-radius: 10px;border-color: #ffffff;">
                    <img *ngIf="clickExcel" src="assets/image/excel_pm.png" (click) = "getReportOption('excel')"
                     style="opacity: 1; height : 60px ; width: 60px; object-fit: cover;border: 3px sol;border-radius: 10px;padding-left : 0px;">
                     &nbsp;&nbsp;
    
                    <img *ngIf="!clickPDF" src="assets/image/pdf3.png" (click) = "getReportOption('pdf')"
                      style="opacity: 0.3; margin-left : 2px; height : 60px ;width: 60px;object-fit: cover;border: 3px solid #ffffff;border-radius: 10px;border-color: #ffffff;">
                    <img *ngIf="clickPDF" src="assets/image/pdf3.png" (click) = "getReportOption('pdf')"
                     style="opacity: 1; height : 60px ; margin-left : 2px; width: 60px;object-fit: cover;border: 3px sol;border-radius: 10px">
                  </div> 
                </div>
                <div class="row">
                  <div class="form-group col-md-2 col-sm-4 col-xs-4">
                  </div>
                  <div class="form-group col-md-2 col-sm-4 col-xs-4" *ngIf="hardcode_add_bool === true">
                      <label></label>
                      <button id="generaterep" type="button" class="btn btn-block btn-primary btn-sm" style="margin-top: 4%;" (click)="GenerateReport()">Generate Report</button>
                  </div>
                </div>
            </form>
            </div><!--card body-->
            </div><!--card-->
            <!-- /.row -->
          </div><!--/. container-fluid -->
        </section>
        <!-- /.content-->
      </div>
               
      <aside class="control-sidebar control-sidebar-dark">
        <!-- Control sidebar content goes here -->
      </aside>
    
      <app-footer></app-footer>
      <ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#fff" type="square-jelly-box">
        <p style="font-size: 16px; color: white">Loading...</p>
      </ngx-spinner> 
</div>

<div id="deleteData" class="modal fade">
  <div class="modal-dialog">
      <div class="modal-content">

          <div class="modal-body">
              <form>

                  <div class="row" style="margin-top: 5%;margin-bottom: 3%;margin-left: 9%;">
                      <div class="form-group col-md-12">
                          <h5> This Account is used by another device. 
                              Please Sign Out. Login Again. </h5>
                      </div>
                  </div>

                  <div class="row" style="margin-left: 40%;margin-bottom: 3%;">
                      <div class="col-md-5 col-sm-6" id="deleteBtn">
                          <button type="button" class="btn btn-block btn-success" (click)="OkLogout()"> Ok </button>
                      </div>
                  </div>
              </form>
          </div>

      </div>
  </div>
</div>