<div class="row">
  <div class="col-md-12">
    <div class="card">
      <div class="row">
        <div class="form-group col-md-2">
          <div class="card-header">
            <h3 class="card-title" style="font-weight: bold;"> Add New </h3>
          </div>
        </div>
        <div class="form-group col-md-2"> </div>
        <div class="form-group col-md-2"> </div>
        <div class="form-group col-md-2">
          <div class="card-header">
            <button type="button" class="btn btn-block btn-success btn-sm" style="margin-top: 4%;"
            onclick="window.location.href='/feedbackandfaq/faq-list'">
              <b>Go Back </b>
            </button>
          </div>
        </div>
      </div>

      <div _ngcontent-iyo-c223="" class="card-body">
        <div _ngcontent-iyo-c223="" class="row">
          <div _ngcontent-iyo-c223="" class="form-group col-md-6"><label _ngcontent-iyo-c223=""> Question
            </label><textarea _ngcontent-iyo-c223="" rows="5" [(ngModel)]="faqquestion"
              class="form-control form-control-sm ng-untouched ng-pristine ng-valid"></textarea></div>
          <div _ngcontent-iyo-c223="" class="form-group col-md-6"><label _ngcontent-iyo-c223=""> Answer
            </label><textarea _ngcontent-iyo-c223="" rows="5" [(ngModel)]="answer"
              class="form-control form-control-sm ng-untouched ng-pristine ng-valid"></textarea></div>
        </div>

          <div class="row">
            <label class="col-md-2"> Has Guide </label>
            <div class="form-group col-md-3 radio">
              <div class="form-group clearfix">
                <div class="icheck-success d-inline">
                  <input type="radio" name="r3" checked id="radioSuccess1" value="yes" [(ngModel)]="guide" [ngModelOptions]="{standalone: true}">
                  <label for="radioSuccess1">Yes &nbsp;&nbsp;
                  </label>
                </div>
                <div class="icheck-success d-inline">
                  <input type="radio" name="r3" id="radioSuccess2" value="no" [(ngModel)]="guide" [ngModelOptions]="{standalone: true}">
                  <label for="radioSuccess2">No
                  </label>
                </div>
              </div>
            </div>             
             
          </div>

        <div _ngcontent-iyo-c223="" id="insStep" [style.display]="guide === 'yes' ? 'block' : 'none'">        
          <div _ngcontent-iyo-c223="" class="row">
            <div _ngcontent-iyo-c223="" class="form-group col-md-4"><label _ngcontent-iyo-c223=""> Step No
              </label>
              <input _ngcontent-iyo-c223="" type="number" id="insstepNo" [(ngModel)]="stepNo"
                class="form-control form-control-sm ng-untouched ng-pristine ng-valid">
              </div>
            <div _ngcontent-iyo-c223="" class="form-group col-md-4">
              <label _ngcontent-iyo-c223=""> Image Type
              </label>
              <select _ngcontent-iyo-c223=""  [(ngModel)]="imageType"
                class="type form-control form-control-sm ng-untouched ng-pristine ng-valid">
                <option _ngcontent-iyo-c223="" value=".png" selected="">png</option>
                <option _ngcontent-iyo-c223="" value=".jpg">jpg</option>
                <option _ngcontent-iyo-c223="" value=".jpeg">jpeg</option>
              </select></div>
          </div>
          <div _ngcontent-iyo-c223="" class="row">
            <div _ngcontent-iyo-c223="" class="form-group col-md-4">

              <label>Image</label>                   
                    <div class="form-group col-md-3">
                      <input #file2 type="file" accept='image/*'
                          (change)="preview2(file2.files)" /> 

                          <a [href]="imgURL2" target="_blank" *ngIf="imgURL2">
                          <img [src]="imgURL2" height="100" width="155" *ngIf="imgURL2"
                          style="margin-top: 5%;">
                        </a>
                  </div>
              
            </div>

            <!-- <div _ngcontent-iyo-c223="" class="row">
              <div _ngcontent-iyo-c223="" class="form-group col-md-4">
                <label>Image</label>                   
                <div class="form-group col-md-3">
                  <input #file2 type="file" accept='image/*'
                      (change)="preview2(file2.files)" />           
             
                  <a [href]="linkURL" target="_blank" *ngIf="imgURL2">
                    <img [src]="imgURL2" [alt]="altText" height="100" width="155" 
                    style="margin-top: 5%;">
                  </a>
                </div>              
              </div>
            </div> -->
            



            <div _ngcontent-iyo-c223="" class="form-group col-md-4">
              <label _ngcontent-iyo-c223=""> Description
              </label>
              <textarea _ngcontent-iyo-c223="" [(ngModel)]="description"
                class="form-control form-control-sm ng-untouched ng-pristine ng-valid"></textarea></div>
          </div>
          <div _ngcontent-iyo-c223="" class="row">
            <div _ngcontent-iyo-c223="" class="form-group col-md-6"></div>
            <div _ngcontent-iyo-c223="" class="form-group col-md-6">
              <button _ngcontent-iyo-c223="" type="button"
                class="btn btn-danger" [routerLink]="['/feedbackandfaqs/faqsteps-list']">Cancel</button>
                 &nbsp;
                  <button _ngcontent-iyo-c223="" type="button"
                class="btn btn-primary" (click)="goSave()">Save
              </button></div>
          </div>
        </div>
      </div>


    </div>
  </div>

</div>




<aside class="control-sidebar control-sidebar-dark">

</aside>

<app-footer></app-footer>
<ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#fff" type="square-jelly-box">
  <p style="font-size: 16px; color: white">Loading...</p>
</ngx-spinner>

<div id="deleteData" class="modal fade">
  <div class="modal-dialog">
    <div class="modal-content">

      <div class="modal-body">
        <form>

          <div class="row" style="margin-top: 5%;margin-bottom: 3%;margin-left: 9%;">
            <div class="form-group col-md-12">
              <h5> This Account is used by another device.
                Please Sign Out. Login Again. </h5>
            </div>
          </div>

          <div class="row" style="margin-left: 40%;margin-bottom: 3%;">
            <div class="col-md-5 col-sm-6" id="deleteBtn">
              <button type="button" class="btn btn-block btn-success" (click)="OkLogout()"> Ok </button>
            </div>
          </div>
        </form>
      </div>

    </div>
  </div>
</div>