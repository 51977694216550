<div class="wrapper">
    <!-- Navbar -->
    <app-header-sidebar></app-header-sidebar>

    <!-- here here here -->
    <!-- Content Wrapper. Contains page content -->
    <div class="content-wrapper">
        <!-- Content Header (Page header) -->
        <div class="content-header">
            <div class="container-fluid">
                <!--                 <div class="row mb-2">
                    <div class="col-sm-8">
                        <h1 class="m-0 text-dark"> 2D Bet Amount Limitation</h1>
                    </div>
                </div> -->
                <!-- /.row -->
            </div><!-- /.container-fluid -->
        </div>
        <!-- /.content-header -->
        <!-- Main content -->
        <!-- Main content -->
        <section class="content">
            <div class="container-fluid">
                <form>
                    <div class="row">
                        <div class="col-md-12">
                            <div class="card">
                                <div class="card-header">
                                    <h3 class="card-title" style="font-weight: bold;">2D Bet Amount Limitation Detail
                                    </h3>
                                </div>
                                <!-- /.card-header -->
                                <div class="card-body">
                                    <form>
                                        <div class="row">
                                            <label class="col-md-1"> Amount </label>
                                            <div class="form-group col-md-2">
                                                <input type="number" class="form-control form-control-sm"
                                                    [(ngModel)]="twodbetDTO.max_amt"
                                                    [ngModelOptions]="{standalone: true}">
                                            </div>
                                        </div>
                                        <div class="row">
                                            <label class="col-md-1"> Number </label>
                                            <div class="form-group col-md-2">
                                                <input type="text" class="form-control form-control-sm"
                                                    [(ngModel)]="twodbetDTO.number"
                                                    [ngModelOptions]="{standalone: true}">
                                            </div>
                                        </div>
                                        <div class  = "row">
                                            <div class="col-md-4" style="display: none;">
                                                <div class="row">
                                                    <label class="col-md-3"> Date </label>
                                                    <div class="form-group col-md-6">
                                                        <input id="singleDate" readonly
                                                            class="form-control form-control-sm"
                                                            placeholder="Select Date"
                                                            [ngxDatePicker]="dateInstanceSingle" [value]="singleDate" [ngModel] = "for_date"
                                                            (valueChange)="onChangeSingle()" [ngModelOptions]="{standalone: true}">
                                                        <ngx-date-picker #dateInstanceSingle></ngx-date-picker>
                                                    </div>
                                                </div>
                                            </div>
                                    </div>
                                    <div class="row">
                                        <label class="col-md-1">Time </label> 
                                        <div class="form-group col-md-2">
                                            <select class="time form-control form-control-sm" [(ngModel)]="time"
                                                [ngModelOptions]="{standalone: true}" disabled>
                                                <option *ngFor="let objSection of sectionList" value = "{{objSection.sectionName}}" >
                                                    {{objSection.sectionName}}
                                                </option>
                                            </select>
                                        </div>
                                    </div>
                                    <div class="row">    
                                        <div class="col-md-2 col-sm-2 col-xs-2">
                                            <button id="apply" type="button"
                                                class="btn btn-block btn-success btn-sm" (click)="editBetLimit()">
                                                Save
                                            </button>
                                        </div>
                                    
                                        <div class="col-md-2 col-sm-2 col-xs-2">
                                            <button id="save" type="button"
                                                class="btn btn-block btn-danger btn-sm"  [routerLink] = "['/twod-bet-amount-limitation-list']">
                                                Cancel
                                            </button>
                                        </div>
                                   </div>
                                    </form>

                                </div>
                            </div>
                        </div>
                    </div>
                </form>
                <!-- /.row -->
                <!-- /.row -->
            </div>
            <!--/. container-fluid -->
        </section>
        <!-- /.content -->
    </div>

    <aside class="control-sidebar control-sidebar-dark">
        <!-- Control sidebar content goes here -->
    </aside>

    <app-footer></app-footer>

    <ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#fff" type="square-jelly-box">
        <p style="font-size: 16px; color: white">Loading...</p>
    </ngx-spinner>

</div>