



import { Component, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,HttpErrorResponse } from '@angular/common/http';
import { Router, ActivatedRoute } from '@angular/router';

import { LocalStorageService } from 'ngx-webstorage';
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from 'ngx-toastr';

import { DtoService } from '../../service/dto.service';
import { FunctService } from '../../service/funct.service';

import { catchError, retry } from 'rxjs/operators';
import { throwError } from 'rxjs';

import { from } from 'rxjs';
declare var $: any;

@Component({
  selector: 'app-twod-manual-onoff',
  templateUrl: './twod-manual-onoff.component.html',
  styleUrls: ['./twod-manual-onoff.component.css']
})
export class TwoDManualOnOffComponent implements OnInit {

  id: any;
  token: any;
  type : any;
  manual10_30:any;
  manual12_01:any;
  manual02_30:any;
  manual04_30:any;

  constructor(private storage: LocalStorageService, private spinner: NgxSpinnerService, private toastr: ToastrService, private http: HttpClient, private dto: DtoService, private router: Router,
    private route: ActivatedRoute, private funct: FunctService) {
      this.getTwodManualOnOff();
      this.storage.store('isNotiSong', "");
     }

  ngOnInit(): void {
    if (!this.storage.retrieve('loadFlag')) {
      this.storage.store('loadFlag', 'noLoad');
      setTimeout(function () {
        location.reload(true);
      }, 1);
    }
    else {
      this.storage.clear('loadFlag');
    }
  }

  handleError(error: HttpErrorResponse){
    if(error.status == 423)
    {
      this.spinner.hide();
      $("#deleteData").modal("show");
    }
    if(error.status == 403)
    {
      this.spinner.hide();
      this.toastr.error("Limited Access.", 'Invalid!', {
        timeOut: 3000,
        positionClass: 'toast-top-right',
        });
    }
    return throwError(error);
    }
    OkLogout()
    {
      window.location.href ="./ad-login";
    } 
  onChangeDate() {
    $(document).ready(function () {
      this.date = $("#date").val();
    });
  }
  getTwodManualOnOff(){
    this.spinner.show();
    this.token = this.storage.retrieve('token');
    let headers = new HttpHeaders();
    headers = headers.set('Authorization', this.token);
    this.http.get(this.funct.ipaddress + 'back4app/GetManualOnOff', { headers: headers })
    .pipe(
      catchError(this.handleError.bind(this))
     )
    .subscribe(
      result => {
        this.dto.Response = {};
        this.dto.Response = result;
        this.manual10_30 = this.dto.Response.manual10_30;
        this.manual12_01 = this.dto.Response.manual12_01;
        this.manual02_30 = this.dto.Response.manual02_30;
        this.manual04_30 = this.dto.Response.manual04_30;
      });
      this.spinner.hide();
  }
  goConfigSave() {
    this.spinner.show();this.token = this.storage.retrieve('token');
    let headers = new HttpHeaders();
    headers = headers.set('Authorization', this.token);
    let param = {
      manual10_30 : this.manual10_30,
      manual12_01 : this.manual12_01,
      manual02_30 : this.manual02_30,
      manual04_30 : this.manual04_30
    }
    this.http.post(this.funct.ipaddress + 'back4app/SetManualOnOff', param, { headers: headers })
    .pipe(
      catchError(this.handleError.bind(this))
     )
    .subscribe( 
      result => {
        this.dto.Response = {};
        this.dto.Response = result;
        if (this.dto.Response.status == 'Success') {
          this.spinner.hide();
          this.toastr.success(this.dto.Response.message, 'Success!', {
            timeOut: 5000,
            positionClass: 'toast-top-right'
          });
        }
        else {
          this.spinner.hide();
          this.toastr.error(this.dto.Response.message, 'Invalid!', {
            timeOut: 5000,
            positionClass: 'toast-top-right',
          });
        }
      }
    );
  }
  // checkUncheck()
  // {
  //   if($("#foo_bar").is(':checked'))
  //    {
  //     this.isMaintenance = true;
  //    }
  //   else
  //   {
  //     this.isMaintenance = false;
  //   }
      
  // }
}
