import { Component, OnInit, ViewChild } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, HttpErrorResponse } from '@angular/common/http';
import { DataTableDirective } from 'angular-datatables';
import { Router, NavigationEnd } from '@angular/router';

import { LocalStorageService } from 'ngx-webstorage';
import { NgxSpinnerService } from "ngx-spinner";

import { catchError, retry } from 'rxjs/operators';
import { throwError } from 'rxjs';

import { DtoService } from '../../service/dto.service';
import { FunctService } from '../../service/funct.service';
import { ToastrService } from 'ngx-toastr';
import Responsive from 'datatables.net-responsive'; /*for responsive not working event datatable */

import { Subscription } from 'rxjs';
import { ActivatedRoute } from '@angular/router';


import { Subject } from 'rxjs';
declare var $: any;

@Component({
  selector: 'app-faq-step',
  templateUrl: './faq-step.component.html',
  styleUrls: ['./faq-step.component.css']
})
export class FaqStepbackComponent implements OnInit {

  @ViewChild(DataTableDirective)
  dtElement: DataTableDirective;
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();

  idIndex: any;
  typeId: string = '';
  phoneNo: string = '';
  adsDTO: any;
  feedBack: any;

  hardcode_edit: any;
  hardcode_add: any;
  hardcode_view: any;
  hardcode_delete: any;
  hardcode_view_bool: any;
  hardcode_add_bool: any;
  hardcode_edit_bool: any;
  hardcode_delete_bool: any;
  hardcode_double_bool: any;
  addTranAmt: any;
  addTranDto: any;
  addfacdto: any;
  paymentList: any;
  type: any;
  question: any;
  answer: any;
  orderNumber: any;
  has: any;
  faqType: any;
  faqquestion: any;
  itemsPerPage = 10;
  totalItems: any;
  page = 1;
  stepNo: any;
  image: any;
  describe: any;
  adsDTOStep: any;
  imageType: any;

  imagePath2: string = '';
  message2: string = '';
  filePath2: any;
  imgURL2: any;

  private querySub: Subscription;
  id: string;
  faqsquestion: any;
  faqsanswer: any;
  hasGuide: any;

  constructor(private toastr: ToastrService, private storage: LocalStorageService, private spinner: NgxSpinnerService,
    private dto: DtoService, private http: HttpClient, private funct: FunctService, private router: Router, private route: ActivatedRoute,) {
    this.idIndex = 1;
    this.storage.store('isNotiSong', "");

  }

  ngOnInit(): void {


    // this.querySub = this.route.queryParams.subscribe(params => {
    //   this.id = params['id'];
    //   this.faqsquestion = params['question'];
    //   this.faqsanswer = params['answer'];
    //   this.hasGuide = params['hasGuide'];
    // });
    // var tempId = this.storage.retrieve("faqstepid");
    // if (this.dto.faqstepid == null || this.dto.faqstepid == undefined) {
    //   if (tempId =="" || tempId == null || tempId == undefined) {
    //     this.storage.store('faqstepid', this.id);
    //     this.storage.store('faqsquestion', this.faqsquestion);
    //     this.storage.store('faqsanswer', this.faqsanswer);
    //     this.storage.store('hasGuide', this.hasGuide);
    //   } else {
    //     this.id = tempId;
    //   }
    // }
    // else {
    //   this.id = tempId;
    // }

    //amk

    // this.route.queryParams.subscribe(params => {
    //   this.id = params['id'];
    //   this.question = params['question'];
    //   this.answer = params['answer'];
    //   this.hasGuide = params['hasGuide'] === 'true'; 
    // });

  
    // const faqData = localStorage.getItem('faqData');
    // if (faqData) {
    //   const data = JSON.parse(faqData);
    //   this.id = data.id;
    //   this.question = data.question;
    //   this.answer = data.answer;
    //   this.hasGuide = data.hasGuide;
    // }

    // var tempId = this.storage.retrieve("faqstepid");
    // if (this.dto.faqstepid == null || this.dto.faqstepid == undefined) {
    //   if (tempId =="" || tempId == null || tempId == undefined) {
    //     this.storage.store('faqstepid', this.id);
    //     this.storage.store('faqsquestion', this.faqsquestion);
    //     this.storage.store('faqsanswer', this.faqsanswer);
    //     this.storage.store('hasGuide', this.hasGuide);
    //   } else {
    //     this.id = tempId;
    //   }
    // }
    // else {
    //   this.id = tempId;
    // }
    //


    const faqData = localStorage.getItem('faqData');
    if (faqData) {
      const data = JSON.parse(faqData);
      this.id = data.id;
      this.faqsquestion = data.question;
      this.faqsanswer = data.answer;
      this.hasGuide = data.hasGuide;
    }
    var tempId = this.storage.retrieve("faqstepid");
    if (this.dto.faqstepid == null || this.dto.faqstepid == undefined) {
      if (tempId =="" || tempId == null || tempId == undefined) {
        this.storage.store('faqstepid', this.id);
        this.storage.store('faqsquestion', this.faqsquestion);
        this.storage.store('faqsanswer', this.faqsanswer);
        this.storage.store('hasGuide', this.hasGuide);
      } else {
        this.id = tempId;
      }
    }
    else {
      this.id = tempId;
    }



    this.search();

    this.router.routeReuseStrategy.shouldReuseRoute = function () {
      return false;
    };

    this.router.events.subscribe((evt) => {
      if (evt instanceof NavigationEnd) {
        this.router.navigated = false;
        window.scrollTo(0, 0);
      }
    });

    this.dtOptions = {
      // responsive: true,
      responsive: {
        details: {
            renderer: Responsive.renderer.listHiddenNodes()
        }
    },
      order: [],
      paging: false,
      info: true,
    }

    this.dtOptions = {
      // responsive: true,
      responsive: {
        details: {
            renderer: Responsive.renderer.listHiddenNodes()
        }
    },
      order: [],
      paging: false,
      info: true,
    }

    this.dtOptions.columnDefs = [
      { targets: [5], orderable: false }
    ];

    if (!this.storage.retrieve('loadFlag')) {
      this.storage.store('loadFlag', 'noLoad');
      setTimeout(function () {
        location.reload();
      }, 5);
    }
    else {
      this.storage.clear('loadFlag');
    }

    this.addTranDto = {
      TypeId: 0,
      Question: '',
      Answer: '',
      HasGuide: false,
      OrderNum: 0
    };

    this.addfacdto = {
      id: 0
    };



    this.adsDTOStep = {
      Id: 0,
      FaqId: 0,
      StepNo: 0,
      image: '',
      describe: ''
    };

  }

  handleError(error: HttpErrorResponse) {
    if (error.status == 423) {
      this.spinner.hide();
      $("#anotherlogin").modal("show");
    }
    if (error.status == 403) {
      this.spinner.hide();
      var id = 'tblFeedback' + this.idIndex;
      var table = $('#' + id).DataTable();
      this.toastr.error("Limited Access.", 'Invalid!', {
        timeOut: 3000,
        positionClass: 'toast-top-right',
      });
    }
    return throwError(error);
  }
  OkLogout() {
    window.location.href = "./ad-login";
  }

  search() {
    this.feedBack = [];
    var id = 'tblFeedback' + this.idIndex;
    var table = $('#' + id).DataTable();
    table.destroy();
    this.idIndex = this.idIndex + 1;
    this.spinner.show();
    this.dto.token = this.storage.retrieve('token');
    let headers = new HttpHeaders();
    headers = headers.set('Authorization', this.dto.token);
    let params = new HttpParams();
    if (this.id == undefined || this.id == "") {
      this.id = "";
    }
    params = params.set('FaqId', this.id);
    this.dto.faqstepid = this.id;
    this.http.get(this.funct.ipaddress + 'feedback/faqSteps', { params: params, headers: headers })
      .pipe(
        catchError(this.handleError.bind(this))
      )
      .subscribe(
        result => {
          this.dto.Response = {};
          this.dto.Response = result;
          this.feedBack = this.dto.Response;
          this.totalItems = result.totalRows;
          this.dtTrigger.next();
          this.spinner.hide();
          //return;
        }
      );
  }

  // gty(page:any) {
  //   this.feedBack = [];
  //   var id = 'tblFeedback' + this.idIndex;
  //   var table = $('#' + id).DataTable();
  //   table.destroy();
  //   this.idIndex = this.idIndex + 1;
  //   this.spinner.show();
  //   this.dto.token = this.storage.retrieve('token');
  //   let headers = new HttpHeaders();
  //   headers = headers.set('Authorization', this.dto.token);
  //   let params = new HttpParams();    
  //   if (this.id == undefined || this.id == "") {
  //     this.id = "";
  //   }
  //   params = params.set('FaqId', this.id);
  //   this.dto.faqstepid = this.id; 
  //    this.http.get(this.funct.ipaddress + 'feedback/faqSteps', { params: params, headers: headers })
  //    .pipe(
  //     catchError(this.handleError.bind(this))
  //    )
  //    .subscribe( 
  //     result => {
  //       this.dto.Response = {};
  //       this.dto.Response = result;
  //       this.feedBack = this.dto.Response;    
  //       this.totalItems = result.totalRows;    
  //       this.dtTrigger.next();
  //       this.spinner.hide();
  //     }
  //   );
  // }

  showEditRolesModel(editId, isTyping) {
    isTyping = false;
    this.dto.token = this.storage.retrieve('token');
    let headers = new HttpHeaders();
    headers = headers.set('Authorization', this.dto.token);
    let params = new HttpParams();
    params = params.set('id', editId);
    this.http.get(this.funct.ipaddress + 'feedback/GetfaqStepid', { params: params, headers: headers })
      .pipe(
        catchError(this.handleError.bind(this))
      )
      .subscribe(
        result => {
          this.dto.Response = {};
          this.dto.Response = result;
          this.adsDTO = this.dto.Response;
          this.stepNo = this.adsDTO.stepNo;
          this.imgURL2 = this.adsDTO.image;
          this.stepNo = this.adsDTO.stepNo;
          this.describe = this.adsDTO.describe;
          this.imageType = this.adsDTO.imageType;
          if (this.imageType == ".jpg") {
            this.imageType = ".jpg";
          }
          else if (this.imageType == ".jpeg") {
            this.imageType = ".jpeg";
          }
          else {
            this.imageType = ".png";
          }
          $('#browseAccountData9').modal("show");
        });

  }
  showAddTranModelNew() {
    $('#browseAccountData10').modal("show");
  }

  Close() {
    $('#browseAccountData10').modal("hide");
    $('#browseAccountData9').modal("hide");
  }

  preview2(files) {
    if (files.length === 0)
      return;
    var mimeType2 = files[0].type;
    if (mimeType2.match(/image\/*/) == null) {
      this.message2 = "Only images are supported.";
      return;
    }
    var reader = new FileReader();
    this.imagePath2 = files;
    this.filePath2 = files[0];
    reader.readAsDataURL(files[0]);
    reader.onload = (_event) => {
      this.imgURL2 = reader.result;
      console.log("imgURL>>>" + JSON.stringify(this.imgURL2));
    }
  }


  saveNewRole(faqType, faqquestion, answer, has, orderNumber) {
    if (faqType != null && faqType !== undefined) {
      this.dto.token = this.storage.retrieve('token');
      let headers = new HttpHeaders().set('Authorization', this.dto.token);

      if (has == "true") {
        has = true;
      }
      else {
        has = false;
      }

      const payload = {
        TypeId: faqType,
        Question: faqquestion,
        Answer: answer,
        HasGuide: has,
        OrderNum: orderNumber
      };

      this.http.post(this.funct.ipaddress + 'feedback/faqInsert', payload, { headers: headers })
        .pipe(
          catchError(this.handleError.bind(this))
        )
        .subscribe(
          result => {
            this.dto.Response = result;
            if (this.dto.Response === true) {
              $('#browseAccountData10').modal("hide");
              this.search();
              this.toastr.success("New FAQs ", 'Success!', {
                timeOut: 3000,
                positionClass: 'toast-top-right'
              });
              this.spinner.hide();
            } else {
              $('#browseAccountData10').modal("hide");
              this.toastr.error("New FAQs ", 'Invalid!', {
                timeOut: 3000,
                positionClass: 'toast-top-right',
              });
              this.spinner.hide();
            }
            this.faqType = "";
            this.faqquestion = "";
            this.answer = "";
            this.has = false
            this.orderNumber = "";

          },
          error => {
            console.error(error);
            $('#browseAccountData10').modal("hide");
            this.toastr.error("Failed to save FAQ", 'Error!', {
              timeOut: 3000,
              positionClass: 'toast-top-right',
            });
            this.spinner.hide();
          }
        );
    } else {
      this.toastr.error("Please fill data", 'Invalid!', {
        timeOut: 3000,
        positionClass: 'toast-top-right',
      });
    }
  }



  delete(id) {
    this.dto.token = this.storage.retrieve('token');
    let headers = new HttpHeaders();
    headers = headers.set('Authorization', this.dto.token);
    this.addfacdto.id = id;
    this.http.post(this.funct.ipaddress + 'feedback/faqStepDel', this.addfacdto, { headers: headers })
      .pipe(
        catchError(this.handleError.bind(this))
      )
      .subscribe(
        result => {
          this.dto.Response = {};
          this.dto.Response = result;
          if (this.dto.Response == true) {
            this.spinner.hide();
            this.search();
            this.toastr.success(this.dto.Response.message, 'Success!', {
              timeOut: 3000,
              positionClass: 'toast-top-right'
            })
          }
          else {
            this.spinner.hide();
            this.toastr.error(this.dto.Response.message, 'Invalid!', {
              timeOut: 3000,
              positionClass: 'toast-top-right',
            });
          }
        });
  }

  addTran(id, faqId, stepNo, imgURL2, imageType, describe) {

    this.dto.token = this.storage.retrieve('token');
    let headers = new HttpHeaders();
    headers = headers.set('Authorization', this.dto.token);
    this.adsDTOStep.id = id;
    this.adsDTOStep.FaqId = faqId;
    this.adsDTOStep.stepNo = stepNo;
    this.adsDTOStep.image = imgURL2;
    this.adsDTOStep.imageType = imageType;
    this.adsDTOStep.describe = describe;

    if (imgURL2 != '' || imgURL2 != null || imgURL2 != undefined) {
      if (imgURL2.includes('data:image/jpeg;base64,'))
        this.adsDTOStep.image = imgURL2.replace("data:image/jpeg;base64,", "");
      if (imgURL2.includes('data:image/png;base64,'))
        this.adsDTOStep.image = imgURL2.replace("data:image/png;base64,", "");
    }
    this.http.post(this.funct.ipaddress + 'feedback/faqstepEdit', this.adsDTOStep, { headers: headers })
      .pipe(
        catchError(this.handleError.bind(this))
      )
      .subscribe(
        result => {
          this.dto.Response = {};
          this.dto.Response = result;
          if (this.dto.Response == true) {
            this.search();
            $('#browseAccountData9').modal("hide");
            this.toastr.success(this.dto.Response.message, 'Success!', {
              timeOut: 3000,
              positionClass: 'toast-top-right'
            });
            this.spinner.hide();
          }

        }
      );
  }



  getFAQType() {
    this.dto.token = this.storage.retrieve('token');
    let headers = new HttpHeaders();
    headers = headers.set('Authorization', this.dto.token);
    let params = new HttpParams();
    params = params.set('name', "");
    this.http.get(this.funct.ipaddress + 'feedback/faqTypeListNoPage', { params: params, headers: headers }).subscribe(
      result => {
        this.dto.Response = {};
        this.dto.Response = result;
        this.paymentList = this.dto.Response;
      }
    );
  }

  close() {

  }
}
