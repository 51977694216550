<div class="wrapper">
    <!-- Navbar -->
    <app-header-sidebar></app-header-sidebar>

    <!-- here here here -->
    <!-- Content Wrapper. Contains page content -->
    <div class="content-wrapper">
        <!-- Content Header (Page header) -->
        <div class="content-header">
            <div class="container-fluid">
                <!--  <div class="row mb-2">
                    <div class="col-sm-6">
                        <h1 class="m-0 text-dark"> Promotion and News </h1>
                    </div>
                </div> -->
                <!-- /.row -->
            </div><!-- /.container-fluid -->
        </div>
       
        <section class="content">
            <div class="container-fluid">
                <form>

                    <div class="row">
                        <div class="col-md-12">

                            <div class="card">
                                <div class="card-header">
                                    <h3 class="card-title" style="font-weight: bold;"> GSC APIs Detail </h3>
                                </div>
                                <!-- /.card-header -->
                                <div class="card-body">
                                    <form>

                                        <div class="row">
                                            <label class="col-md-2">Game Provider</label>
                                              <div class="form-group col-md-3">
                                              <select (change) = "flagProvider()" id = "gameProviderCode" class="form-control form-control-sm col-md-12 col-sm-4 col-xs-4"
                                               [(ngModel)]="adsDTO.providerCode" [ngModelOptions]="{standalone: true}">
                                                <option *ngFor="let provider of gameproviderList" value = "{{provider.name}}" >
                                                  {{provider.name}}
                                                </option>
                                                
                                             </select>
                                            </div> 
                                        </div>


                                        <div class="row">
                                            <label class="col-md-2"> Api Name   </label>
                                            <div class="form-group col-md-3">
                                                     <select class="apiName form-control form-control-sm col-md-12 col-sm-4 col-xs-4"
                                                     [(ngModel)]="adsDTO.apiName" [ngModelOptions]="{standalone: true}">
                                                    <option value="gsDeposit" selected>GSC Deposit</option>
                                                    <option value="gsWithdrawal">GSC Withdrawal</option>
                                                    <option value="launchGame">Play Game</option>
                                                </select>
                                            </div>
                                          
                                        </div>


                                        <div class="row">
                                            <div class="form-group col-md-2">
                                                <label>Is Lock?</label>
                                            </div>
                                            <div class="form-group col-md-3 radio">
                                              <div class="form-group clearfix">
                                                <div class="icheck-success d-inline">
                                                  <input type="radio" name="r3" checked id="radioSuccess1" [value]="1" [(ngModel)]="adsDTO.isLock" [ngModelOptions]="{standalone: true}">
                                                  <label for="radioSuccess1">True &nbsp;&nbsp;
                                                  </label>
                                                </div>
                                                <div class="icheck-success d-inline">
                                                  <input type="radio" name="r3" id="radioSuccess2" [value]="0" [(ngModel)]="adsDTO.isLock" [ngModelOptions]="{standalone: true}">
                                                  <label for="radioSuccess2">False
                                                  </label>
                                                </div>
                                              </div>
                                            </div>
                                        </div>



                                        <div class="row" style="margin-top: 1%;">
                                            <div class="col-md-2 col-sm-6">
                                                <button type="button" class="btn btn-block btn-success"
                                                    (click)="goSave()">Save</button>&nbsp;</div>
                                            <div class="col-md-2 col-sm-6">
                                                <button type="button" class="btn btn-block btn-danger"
                                                    [routerLink]="['/lockgscapis']"> Cancel </button></div>
                                        </div>
                                    </form>

                                </div>
                            </div>
                        </div>

                    </div>
                    
                    <div class="row" id="updatedata">
                        <div class="col-md-12">
                            <div class="card">
                                <div class="card-body">
                                    <form>
                                        <!-- <div class="row" style="margin-top: 2%;">
                                            <label class="col-md-2"> Created Date </label>
                                            <div class="form-group col-md-3">
                                                <input class="form-control form-control-sm"
                                                    [(ngModel)]="adsDTO.created_date"
                                                    [ngModelOptions]="{standalone: true}" disabled>
                                            </div>
                                            <div class="col-md-1"></div>
                                            <label class="col-md-2"> Created By</label>
                                            <div class="col-md-3">
                                                <input class="form-control form-control-sm"
                                                    [(ngModel)]="adsDTO.created_by_name"
                                                    [ngModelOptions]="{standalone: true}" disabled>
                                            </div>
                                        </div> -->

                                        <!-- <div class="row" style="margin-top: 1%;">
                                            <label class="col-md-2"> Updated Date </label>
                                            <div class="form-group col-md-3">
                                                <input class="form-control form-control-sm"
                                                    [(ngModel)]="adsDTO.modified_date"
                                                    [ngModelOptions]="{standalone: true}" disabled>
                                            </div>
                                            <div class="col-md-1"></div>
                                            <label class="col-md-2"> Updated By </label>
                                            <div class="col-md-3">
                                                <input class="form-control form-control-sm"
                                                    [(ngModel)]="adsDTO.updated_by_name"
                                                    [ngModelOptions]="{standalone: true}" disabled>
                                            </div>
                                        </div> -->

                                    </form>
                                </div>
                                <!-- /.card-body -->
                            </div>
                        </div>
                    </div>
                </form>
                <!-- /.row -->

                <!-- /.row -->
            </div>
            <!--/. container-fluid -->
        </section>
        <!-- /.content -->
    </div>

    <aside class="control-sidebar control-sidebar-dark">
        <!-- Control sidebar content goes here -->
    </aside>

    <app-footer></app-footer>

    <!-- <ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#fff" type="square-jelly-box">
        <p style="font-size: 16px; color: white">Loading...</p>
    </ngx-spinner> -->
</div>

<div id="deleteData" class="modal fade">
    <div class="modal-dialog">
        <div class="modal-content">

            <div class="modal-body">
                <form>

                    <div class="row" style="margin-top: 5%;margin-bottom: 3%;margin-left: 9%;">
                        <div class="form-group col-md-12">
                            <h5> This Account is used by another device. 
                                Please Sign Out. Login Again. </h5>
                        </div>
                    </div>

                    <div class="row" style="margin-left: 40%;margin-bottom: 3%;">
                        <div class="col-md-5 col-sm-6" id="deleteBtn">
                            <button type="button" class="btn btn-block btn-success" (click)="OkLogout()"> Ok </button>
                        </div>
                    </div>
                </form>
            </div>

        </div>
    </div>
</div>