import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,HttpErrorResponse } from '@angular/common/http';
// import { Router, NavigationEnd ,ActivatedRoute} from '@angular/router';
import { Router, ActivatedRoute} from '@angular/router';
import { DataTableDirective } from 'angular-datatables';
import { LocalStorage, LocalStorageService } from 'ngx-webstorage';
import { NgxSpinnerService } from "ngx-spinner";
import { catchError, retry } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { DtoService } from '../../service/dto.service';
import { FunctService } from '../../service/funct.service';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
import { forkJoin } from 'rxjs';
import { time } from 'console';
declare var $: any;
@Component({
  selector: 'app-permission-access',
  templateUrl: './permission-access.component.html',
  styleUrls: ['./permission-access.component.css']
})
export class PermissionAccessComponent implements OnInit {
   //roleName:any;@ViewChild(DataTableDirective)
   dtElement: DataTableDirective;
   dtOptions: DataTables.Settings = {};
   dtTrigger: Subject<any> = new Subject();
   phoneNo: string= '';
   name: string= '';
   referralCode: string= '';
   status: string= '';
   agentList: any;
   idIndex: any;
  checkedItemIds: number[] = [];
  selectAllItemsLevel1 = false;
  selectAllItemsLevel2 = false;
  selectAllItemsLevel3 = false;
  permissionList: any[];
  roleList :any[];
  id:any;
  roleName:any;
  menuItems: any[];selectAllChecked: boolean = false;updatedRoleList: any[] = [];
   constructor(private cdref: ChangeDetectorRef,private storage: LocalStorageService, private spinner: NgxSpinnerService, private toastr: ToastrService, private http: HttpClient, private dto: DtoService, private router: Router,private route: ActivatedRoute,
    private funct: FunctService) { 
      this.storage.store('isNotiSong', "");
    //this.roleName = localStorage.getItem("roleName");
    this.roleName = this.route.snapshot.paramMap.get("roleName");
  }
  async ngOnInit(): Promise<void>
  {
    //this.getRolesModuleListByAdmin();
    this.router.routeReuseStrategy.shouldReuseRoute = function () {
      return false;
    };

    this.router.events.subscribe((evt) => {
      if (evt instanceof ActivatedRoute) {
        this.router.navigated = false;       
        window.scrollTo(0, 0);
      }
    });

    this.id = this.route.snapshot.paramMap.get("id");   
    this.roleName = this.route.snapshot.paramMap.get("roleName");
    this.getRolesModuleList();
    //this.getRolesModuleListByAdmin();//amk added
    this.spinner.hide();


  } 
 
  handleError(error: HttpErrorResponse){
    if(error.status == 423)
    {
      this.spinner.hide();
      $("#deleteData").modal("show");
    }
    if(error.status == 403)
    {
      this.spinner.hide();
      var id = 'tblAgent' + this.idIndex;
      var table = $('#' + id).DataTable();
      this.toastr.error("Limited Access.", 'Invalid!', {
        timeOut: 3000,
        positionClass: 'toast-top-right',
        });
    }
    return throwError(error);
    }
  getRolesModuleList() 
  {
    this.roleList = [];
    var id = 'tblAgent' + this.idIndex;
    var table = $('#' + id).DataTable();
    table.destroy();
    this.idIndex = this.idIndex +1;
    this.spinner.show();
    this.dto.token = this.storage.retrieve('token');
    let headers = new HttpHeaders();
    headers = headers.set('Authorization', this.dto.token);
    let params = new HttpParams();
    //params = params.set('roleId', localStorage.getItem("roleId"));
    params = params.set('roleId', this.id);  
    const paramsObject = {};
    params.keys().forEach(key => {
        if (key === 'roleId') {
            paramsObject[key] = params.get(key); 
        } else {
            paramsObject[key] = params.getAll(key); 
        }
    });
    this.http.get(this.funct.ipaddress + 'admin/getrolemenu', { params: params, headers: headers } )
    .pipe(
      catchError(this.handleError.bind(this))
     )
    .subscribe(
      async result => {
        this.dto.Response = {};
        this.dto.Response = result;
        this.roleList = this.dto.Response;
        localStorage.setItem('roleList', JSON.stringify(this.roleList));
        
        console.log("Menus list ",JSON.stringify(this.roleList));
        this.roleList = this.roleList.map(item => {
          item.selected = false; 
          if (item.isHaved) {
            item.selected = true;
          }
          if (item.childrens && item.childrens.length > 0) 
          {
            item.childrens = item.childrens.map(child =>
              {
              child.selected = false; 
              if (child.isHaved)
              {
                child.selected = true;
              }
              return child;
            });
          }
          return item;
        });
      
        localStorage.setItem("allmenus",JSON.stringify(this.roleList));
        this.spinner.hide();
      }
    );
  }

  //amk
  getRolesModuleListByAdmin()
  {
    this.permissionList = [];
    this.dto.token = this.storage.retrieve('token');
    let headers = new HttpHeaders();
    headers = headers.set('Authorization', this.dto.token);
    let params = new HttpParams();
    //params = params.set('roleId', localStorage.getItem("roleId"));
    params = params.set('roleId', this.id);  
    const paramsObject = {};
    params.keys().forEach(key => {
        if (key === 'roleId') {
            paramsObject[key] = params.get(key); 
        } else {
            paramsObject[key] = params.getAll(key);
        }
    });
    this.http.get(this.funct.ipaddress + 'admin/getrolemenu_byadmin', { params: params, headers: headers } )
    .pipe(
      catchError(this.handleError.bind(this))
     )
    .subscribe(
      result => {
        this.dto.Response = {};
        this.dto.Response = result;
        this.menuItems = this.dto.Response;
        localStorage.setItem("menuItems",JSON.stringify(this.menuItems));
        this.spinner.hide();
      }
    );
  }

  


  toggleAccordion(itemId: number) {
    const item = this.roleList.find((i) => i.id === itemId);
    if (item) {
      item.isExpanded = !item.isExpanded;
    }
  }
  hasPermission(code: string, action: string): boolean
  {
    alert("hi")
    console.log("Code "+code, " action "+action)
    return code.toLowerCase().includes(action.toLowerCase());
  }
  containsSubstring(code: string, substring: string): boolean {
    console.log("Code ",code);
    return code.includes(substring);
  }
  getMenuCodes()
  {
    this.roleList = [];
    var id = 'tblAgent' + this.idIndex;
    var table = $('#' + id).DataTable();
    table.destroy();
    this.idIndex = this.idIndex +1;
    this.spinner.show();
    this.dto.token = this.storage.retrieve('token');
    let headers = new HttpHeaders();
    headers = headers.set('Authorization', this.dto.token);
    this.http.get(this.funct.ipaddress + 'admin/getmenucode', { headers: headers } )
    .pipe(
      catchError(this.handleError.bind(this))
     )
    .subscribe(
      result => {
        this.dto.Response = {};
        this.dto.Response = result;
        this.roleList = this.dto.Response;
        this.dtTrigger.next();
        this.spinner.hide();
      }
    );
  }
  filterItemsWithIsHaveTrue()
  {
    const filteredItems = this.getItemsWithIsHaveTrue(this.roleList);
    this.checkedItemIds = filteredItems.map(item => item.id);
  }
  getItemsWithIsHaveTrue(items: any[]): any[] {
    let result: any[] = [];
    items.forEach(item =>
      {
      if (item.isHaved)
      {
        result.push(item);
      }
      if (item.childrens && item.childrens.length > 0)
      {
        result = result.concat(this.getItemsWithIsHaveTrue(item.childrens));
      }
    });
    return result;
  }
  createMenu(menuData) {
    let accordionHTML = '<ul>';
    for (const item of menuData) {
        if (item.type === 2) {
            // Skip items with type 2
            continue;
        }
        accordionHTML += '<li>';
        if (item.type === 3) {
            // Type 3 - Action Menu
            accordionHTML += '<div class="row">'; // First Column
            if (item.code.includes('View')) {
                accordionHTML += '<div class="column">View'; // First Column
            } else if (item.code.includes('Edit')) {
                accordionHTML += '<div class="column">Edit'; // Second Column
            } else if (item.code.includes('Add')) {
                accordionHTML += '<div class="column">Create'; // Third Column
            } else if (item.code.includes('Del')) {
                accordionHTML += '<div class="column">Delete'; // Fourth Column
            }
        } else {
            if (item.code === 'UserManagement')
            {
                accordionHTML += `<div class="dropdown"><span>${item.title}</span><ul>`;
            } else {
                accordionHTML += `<div class="dropdown"><span>${item.title}</span><ul>`;
            }
        }
        if (item.childrens && item.childrens.length > 0) {
            accordionHTML += this.createMenu(item.childrens);
        }
        if (item.type === 3) {
            accordionHTML += '</div></div>'; // Close Column
        } else if (item.type !== 2) {
            accordionHTML += '</ul></div>'; // Close Dropdown
        }
        accordionHTML += '</li>';
    }
    accordionHTML += '</ul>';
    return accordionHTML;
}

  // In your component class
  checkSubMenuType(childMenu: any): boolean {
    return childMenu.childrens && childMenu.childrens.some(subMenu => subMenu.type === 1);
  }
  checkCode(subMenu: any, substring: string): boolean 
  { 
    if(subMenu != undefined && subMenu.code.toLowerCase().includes(substring.toLowerCase()))
    {
      return true;
    }
    else
    {
      console.log("false")
      return false;
    }
  }
  
  save()
  {
    this.dto.token = this.storage.retrieve('token');
    let headers = new HttpHeaders();
    headers = headers.set('Authorization', this.dto.token);
    this.filterItemsWithIsHaveTrue();
    if(this.checkedItemIds.length <=0)
    {
      this.toastr.error("Please choose at least one menu", 'Invalid!', {
        timeOut: 3000,
        positionClass: 'toast-top-right',
      });
      return;
    }
    var menuModel = {
      // roleId: localStorage.getItem("roleId"),
      roleId: this.id,    
      menuIds : this.checkedItemIds
    }
    console.log("Model ",menuModel);
    this.http.post(this.funct.ipaddress + 'admin/updaterolemenu', menuModel, { headers: headers })
    .pipe(
      catchError(this.handleError.bind(this))
     )
    .subscribe( 
      result => {
        this.dto.Response = {};
        this.dto.Response = result;
        if (this.dto.Response.status == 'Success') {
          this.spinner.hide();
          this.toastr.success("Success edit permission", 'Success!', {
            timeOut: 3000,
            positionClass: 'toast-top-right'
          });
          this.getRolesModuleList();
        }
        else {
          this.spinner.hide();
          this.toastr.error(this.dto.Response.message, 'Invalid!', {
            timeOut: 3000,
            positionClass: 'toast-top-right',
          });
        }
      }
    );
  }
  getSelectedIds(item): number[] {
    let selectedIds: number[] = [];
    if (item.isHaved) {
      selectedIds.push(item.id);
    }
    if (item.childrens && item.childrens.length > 0)
    {
      for (const child of item.childrens)
      {
        if(child.isHaved)
        {
          selectedIds = selectedIds.concat(this.getSelectedIds(child));
        }
      }
    }
    return selectedIds;
  }
  cancel()
  {
    this.router.navigate(["/user-role"]);
  }

  selectAll() {
    this.roleList = this.addSelectedToAll(this.roleList);
 }
 addSelectedToAll(array: any[]): any[] {
  return array.map(item => {
    item.isHaved = true; 
    if (item.childrens && item.childrens.length > 0) {
      item.childrens = this.addSelectedToAll(item.childrens);
    }
    return item;
  });
}
  addDeSelectedToAll(array: any[]): any[] {
    return array.map(item => {
      item.isHaved = false; 
      if (item.childrens && item.childrens.length > 0) {
        item.childrens = this.addDeSelectedToAll(item.childrens);
      }
      return item;
    });
  }
  deselectAll()
  {
    this.roleList = this.addDeSelectedToAll(this.roleList);
  }
  trackByFn(index, item) {
    return item.id; 
  }

toggleSelectAll(mainMenu: any) {
  mainMenu.isHaved = mainMenu.isHaved;
  // Toggle all child menus
  if (mainMenu.childrens)
  {
    for (const childMenu of mainMenu.childrens)
    {
      childMenu.viewIsHaved = mainMenu.isHaved;
      childMenu.editIsHaved = mainMenu.isHaved;
      childMenu.createIsHaved = mainMenu.isHaved;
      childMenu.deleteIsHaved = mainMenu.isHaved;
      childMenu.isHaved = mainMenu.isHaved;
      // Toggle all submenus
      if (childMenu.childrens) {
        for (const subMenu of childMenu.childrens) {
          subMenu.isHaved = childMenu.isHaved;
        }
      }
    }
  }
}

toggleItem(item) 
{
  if(item.childrens.length > 0)
   item.expanded = !item.expanded;
}
toggleAllItems(item, level, list)
 {
  if (list)
  {
    list.forEach((childItem) =>
    {
      
      childItem.isHaved = item.isHaved;
      childItem.expanded = false;
      item.expanded = false;
      if (item.isHaved)
      {
        childItem.isHaved = true;       
      }     
      if (level === 'level1' && childItem.childrens)
      {
        childItem.expanded = false; 
        this.toggleAllItems(item, 'level2', childItem.childrens);
      }
      if (level === 'level2' && childItem.childrens)
      {
        childItem.expanded = false; 
        this.toggleAllItems(item, 'level3', childItem.childrens);
      }
      if (level === 'level3' && childItem.childrens)
      {
        childItem.expanded = false; 
        this.toggleAllItems(item, 'level4', childItem.childrens);
      }
      if (level === 'level4' && childItem.childrens)
      {
        childItem.expanded = false; 
        this.toggleAllItems(item, 'level5', childItem.childrens);
      }
    });
  }
}

 transformData(data: MenuItem[]): MenuItem {
  const result: MenuItem = {
    id: data[0].p_id,
    p_id: data[0].p_id,
    title: data[0].title,
    link: data[0].link,
    i18n: data[0].i18n,
    type: data[0].type,
    sort: data[0].sort,
    acl: data[0].acl,
    acls: data[0].acls,
    status: data[0].status,
    icon: data[0].icon,
    isHaved: data[0].isHaved,
    code: data[0].code,
    childrens: {
      type: data[0].type,
      data: [],
    },
  };

  for (const item of data) {
    const child: MenuItem = {
      id: item.id,
      p_id: item.p_id,
      title: item.title,
      link: item.link,
      i18n: item.i18n,
      type: item.type,
      sort: item.sort,
      acl: item.acl,
      acls: item.acls,
      status: item.status,
      icon: item.icon,
      isHaved: item.isHaved,
      code: item.code,
      childrens: {
        type: item.type,
        data: item.childrens?.data ? this.transformData(item.childrens.data).childrens.data : [],
      },
    };
    result.childrens.data.push(child);
  }
  return result;
}
}
interface MenuItem {
  id: number;
  p_id: number;
  title: string;
  link: string;
  i18n: null;
  type: number;
  sort: number;
  acl: string;
  acls: string[];
  status: number;
  icon: string;
  isHaved: boolean;
  code: string;
  childrens: MenuItemChildren;
}
interface MenuItemChildren {
  type: number;
  data: MenuItem[];
}
const originalData: MenuItem[] = [
];
