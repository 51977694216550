
<div class="wrapper" ng-app="" >
  <app-header-sidebar></app-header-sidebar>
      <div class="content-wrapper">     
        <div class="content-header">
          <div class="container-fluid">        
          </div>
        </div>
       
        <section class="content">
          <div class="container-fluid">           
            <div class="card">
              <div class="card-header">
                  <h3 class="card-title" style="font-weight: bold;"> Bank Account Detail Report </h3>
              </div>
            
            <div class="card-body">
            <form>
              <div class="row">
                <div class="form-group col-md-2">
                    <label>From Date </label><span class ="req">*</span>
                </div>
                <div class="form-group col-md-2">
                  <input type = "date" style="width:250px;"  [(ngModel)]="fromdate" name = "fromdate" class="form-control form-control-sm" id = "fdate">
                </div>
            </div>

            <div class="row">
                <div class="form-group col-md-2">
                    <label>To Date</label><span class ="req">*</span>
                </div>
                <div class="form-group col-md-2">
                  <input type = "date" style="width:250px;" name = "todate" [(ngModel)]="todate" [ngModelOptions]="{standalone: true}" min="{{fromdate | date:'yyyy-MM-dd'}}" class="form-control form-control-sm" id = "tdate">
                </div>
            </div>

            <div class="row">
              <div class="form-group col-md-2">
                  <label>Type</label><span class ="req">*</span>
              </div>
              <div class="form-group col-md-2">
                <select id = "type" style="width:250px;"  class="form-control form-control-sm" [(ngModel)]="type" [ngModelOptions]="{standalone: true}" (ngModelChange)="onChangeType()">
                    <option value = "TOPUP" selected>TOPUP</option>
                    <option value = "WITHDRAW">WITHDRAWAL</option>
                
                </select>
              </div>
          </div>

            <div class="row">
              <div class="form-group col-md-2">
                  <label>Select Account Holder (Admin) </label>
              </div>
              <div class="form-group col-md-3">
                <ngx-bootstrap-multiselect [options]="myOptions" [(ngModel)]="optionsModel" (ngModelChange)="onChange()"
                [ngModelOptions]="{standalone: true}" [texts]="myTexts" [settings]="mySettings" class = "form-control-sm"  style="margin-left : -7px"
                >
              </ngx-bootstrap-multiselect>
            </div>
          </div>

            <div class="row">
              <div class="form-group col-md-2">
                  <label>Select Code Number </label>
              </div>
              <div class="form-group col-md-3">
                <ngx-bootstrap-multiselect [options]="mycodeOptions" [(ngModel)]="codeoptionsModel" (ngModelChange)="onChangeCode()"
                [ngModelOptions]="{standalone: true}" class = "form-control-sm"  style="margin-left : -7px" [texts]="myTexts" [settings]="mySettings"
                >
              </ngx-bootstrap-multiselect>
              </div>
            </div>


          <!-- <div class="row">          

            <div class="form-group col-md-2">
              <label>Bank Type</label>
            </div>
              <div class="form-group col-md-2">
                <select class="status form-control form-control-sm col-md-12 col-sm-4 col-xs-4"
                          [(ngModel)]="BankType" 
                          [ngModelOptions]="{standalone: true}"
                          (ngModelChange)="onChangeTagSection()">                
                    <option *ngFor="let bt of bankTypeList" [value]="bt.bankType">
                      {{ bt.bankType }}
                    </option>
                    <option value="" selected>All</option>
                  </select>
              </div>

          </div> -->

          


          <div class="row">
            <div class="form-group col-md-2">
              <label>Select Bank Type</label>
            </div>
            <div class="form-group col-md-3">
              <ngx-bootstrap-multiselect
                [options]="myBankTypeOptions"
                [(ngModel)]="BankTypeOptionsModel"
                (ngModelChange)="onChangeBankType()"
                [ngModelOptions]="{ standalone: true }"
                class="form-control-sm"
                style="margin-left: -7px"
                [texts]="myTexts"
                [settings]="mySettings">
              </ngx-bootstrap-multiselect>
            </div>
          </div>
          
          

        
                <div class="row">
                  <div class = "col-lg-2">
                    <label> Choose Option</label>
                   </div>
                   <div class="form-group col-md-5" style="cursor: pointer;">
                    <img *ngIf="!clickkbzpay" src="assets/image/excel_pm.png" (click) = "getReportOption('excel')"
                      style="opacity: 0.3; height : 60px ;width: 60px;object-fit: cover;padding-left : 0px;border: 3px solid #ffffff;border-radius: 10px;border-color: #ffffff;">
                    <img *ngIf="clickkbzpay" src="assets/image/excel_pm.png" (click) = "getReportOption('excel')"
                     style="opacity: 1; height : 60px ; width: 60px; object-fit: cover;border: 3px sol;border-radius: 10px;padding-left : 0px;">
                     &nbsp;&nbsp;
    
                    <img *ngIf="!clickwavepay" src="assets/image/pdf3.png" (click) = "getReportOption('pdf')"
                      style="opacity: 0.3; margin-left : 2px; height : 60px ;width: 60px;object-fit: cover;border: 3px solid #ffffff;border-radius: 10px;border-color: #ffffff">
                    <img *ngIf="clickwavepay" src="assets/image/pdf3.png" (click) = "getReportOption('pdf')"
                     style="opacity: 1; height : 60px ; margin-left : 2px; width: 60px;object-fit: cover;border: 3px sol;border-radius: 10px">
    
                     &nbsp;&nbsp;
    
                    <img *ngIf="!csvrep" src="assets/image/csv3.png" (click) = "getReportOption('csv')"
                      style="opacity: 0.3; padding-left :0px; height : 60px ;width: 60px;object-fit: cover;border: 3px solid #ffffff;border-radius: 10px;border-color: #ffffff;">
                    <img *ngIf="csvrep" src="assets/image/csv3.png" (click) = "getReportOption('csv')"
                     style="opacity: 1; height : 60px ; padding-left : 0px; width: 60px;object-fit: cover;border: 3px sol;border-radius: 10px">
                    </div> 
                </div>
                <div class="row">
                  <div class="form-group col-md-2 col-sm-4 col-xs-4">
                  </div>
                <div class="form-group col-md-2 col-sm-4 col-xs-4" *ngIf="hardcode_add_bool === true">
                    <label></label>
                    <button id="generaterep" type="button" class="btn btn-block btn-primary btn-sm" style="margin-top: 4%;" (click)="bankAccountDetailReport()">Generate Report</button>
                </div>
                </div>



                <div class="row" style="margin-top: 1%;">
                  <div class="col-md-2 col-sm-6">
                  </div> 
                  <div class="col-md-2 col-sm-6">
                     </div>
              </div>
              <div class="row" style="margin-top: 1%;">
                <div class="col-md-2 col-sm-6">
                </div> 
                <div class="col-md-2 col-sm-6">
                   </div>
            </div>
                <div class="row" style="margin-top: 1%;">
                  <div class="col-md-2 col-sm-6">
                  </div> 
                  <div class="col-md-2 col-sm-6">
                    </div>
              </div>
              <div class="row" style="margin-top: 1%;">
                <div class="col-md-2 col-sm-6">
                </div> 
                <div class="col-md-2 col-sm-6">
                  </div>
            </div>
            <div class="row" style="margin-top: 1%;">
              <div class="col-md-2 col-sm-6">
              </div> 
              <div class="col-md-2 col-sm-6">
                </div>
          </div>
          <div class="row" style="margin-top: 1%;">
            <div class="col-md-2 col-sm-6">
            </div> 
            <div class="col-md-2 col-sm-6">
              </div>
        </div>

              <div class="row" style="margin-top: 1%;">
                <div class="col-md-2 col-sm-6">
                </div> 
                <div class="col-md-2 col-sm-6">
                  </div>
            </div>
            <div class="row" style="margin-top: 1%;">
              <div class="col-md-2 col-sm-6">
              </div> 
              <div class="col-md-2 col-sm-6">
                </div>
          </div>
              <div class="row" style="margin-top: 1%;">
                <div class="col-md-2 col-sm-6">
                </div> 
                <div class="col-md-2 col-sm-6">
                  </div>
            </div>
            <div class="row" style="margin-top: 1%;">
              <div class="col-md-2 col-sm-6">
              </div> 
              <div class="col-md-2 col-sm-6">
                </div>
          </div>
          <div class="row" style="margin-top: 1%;">
            <div class="col-md-2 col-sm-6">
            </div> 
            <div class="col-md-2 col-sm-6">
              </div>
        </div>
        <div class="row" style="margin-top: 1%;">
          <div class="col-md-2 col-sm-6">
          </div> 
          <div class="col-md-2 col-sm-6">
            </div>
      </div>






            </form>
            </div>
            </div>
          
          </div>
        </section>
    
      </div>

      <aside class="control-sidebar control-sidebar-dark">
        <!-- Control sidebar content goes here -->
      </aside>
    
 <app-footer></app-footer>
     <ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#fff" type="square-jelly-box">
      <p style="font-size: 16px; color: white">Loading...</p>
    </ngx-spinner> 
</div>

<div id="deleteData" class="modal fade">
  <div class="modal-dialog">
      <div class="modal-content">

          <div class="modal-body">
              <form>

                  <div class="row" style="margin-top: 5%;margin-bottom: 3%;margin-left: 9%;">
                      <div class="form-group col-md-12">
                          <h5> This Account is used by another device. 
                              Please Sign Out. Login Again. </h5>
                      </div>
                  </div>

                  <div class="row" style="margin-left: 40%;margin-bottom: 3%;">
                      <div class="col-md-5 col-sm-6" id="deleteBtn">
                          <button type="button" class="btn btn-block btn-success" (click)="OkLogout()"> Ok </button>
                      </div>
                  </div>
              </form>
          </div>

      </div>
  </div>
</div>