
import { Component, OnInit, ViewChild } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,HttpErrorResponse } from '@angular/common/http';
import { Router, NavigationEnd,ActivatedRoute } from '@angular/router';
import { DataTableDirective } from 'angular-datatables';
import { catchError, retry } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { LocalStorageService } from 'ngx-webstorage';
import { NgxSpinnerService } from "ngx-spinner";
import { FunctService } from '../../service/funct.service';
import { DtoService } from '../../service/dto.service';
import { DatePipe } from '@angular/common';
import { Subject } from 'rxjs';
declare var $: any;
@Component({
  selector: 'app-manage-wallets-provider-detail',
  templateUrl: './manage-wallets-provider-detail.component.html',
  styleUrls: ['./manage-wallets-provider-detail.component.css']
})
export class ManageWalletsProviderDetailComponent implements OnInit {
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();
  providerAmountList: any;
  idIndex: any;
  userId: any;
  ProviderName : any = "";
  ProviderCode : any = "";
  PhoneNo : any = "";
  MainWallet : any = 0;
  GameWallet : any = 0;
  Amount : any = 0;
  transferAmount: any;
  ProviderId : any = 0;
  MainWallet_Str : any = "0";
  GameWallet_Str : any = "0";
  UserName : any = "";
  UserPhoneNo : any = "";
  TotalGameBalance : any = 0;
  userscheck:any;
  isChecked:any;
  Remark :any;
  
  constructor(private storage: LocalStorageService,private datepipe: DatePipe, private toastr: ToastrService,private spinner: NgxSpinnerService, private http: HttpClient, private dto: DtoService, private router: Router, 
    private route: ActivatedRoute, private funct: FunctService) {
      this.idIndex = 1;
      this.userId = this.route.snapshot.paramMap.get("id");
      this.Refresh();
      this.storage.store('isNotiSong', "");
    }

  ngOnInit(): void {
    this.dtOptions = {
      order:[],
      responsive:true,
      paging: true,
      info : true,
      dom: "Bfrltip"
      }
    this.dtOptions.columnDefs = [
      { targets: [0], orderable: true }
    ];

    this.transferAmount = {
      id: 0,
      gameWallet: 0,
      amount: 0,
      type: "",
      providerId: 0,
      providerCode: "",
      phone_no: "",
      isShowHistory:false,
      remark :""
    }

  }

  handleError(error: HttpErrorResponse){
    if(error.error.status == 400)
    {
      this.spinner.hide();
      var id = 'tbPD' + this.idIndex;
      var table = $('#' + id).DataTable();
      this.toastr.error(error.statusText, 'Invalid!', {
        timeOut: 3000,
        positionClass: 'toast-top-right',
      });
    }
    if(error.status == 403)
    {
      this.spinner.hide();
      var id = 'tbPD' + this.idIndex;
      var table = $('#' + id).DataTable();
      this.toastr.error("Limited Access.", 'Invalid!', {
        timeOut: 3000,
        positionClass: 'toast-top-right',
      });
    }
    if(error.status == 406)
    {
      this.spinner.hide();
      this.toastr.error(error.error.message, 'Invalid!', {
        timeOut: 3000,
        positionClass: 'toast-top-right',
      });
    }
    return throwError(error);
  }

  OkLogout()
  {
    window.location.href ="./ad-login";
  }

  onInput(event: InputEvent) {
    const myanmarCharactersRegex = new RegExp('^[\u1000-\u109F\uAA60-\uAA7F\uA9E0-\uA9FF]+$');
    const inputValue = (event.target as HTMLInputElement).value; 
    if(event.data!=null)
    {
      if (myanmarCharactersRegex.test(inputValue)) 
      {        
        this.spinner.hide();
        this.toastr.error( "Myanmar typing detected!", '', {
        timeOut: 5000,
        positionClass: 'toast-top-right',
        });  
      }
    }   
  }
  Refresh() {
    this.providerAmountList = [];
    var id = 'tbPD' + this.idIndex;
    var table = $('#' + id).DataTable();
    table.destroy();
    this.idIndex = this.idIndex +1;
    this.spinner.show();
    this.dto.token = this.storage.retrieve('token');
    let headers = new HttpHeaders();
    headers = headers.set('Authorization',  this.dto.token);
    let params = new HttpParams();     
    params = params.set('userId',this.userId);
    this.http.get(this.funct.ipaddress + 'manageWallets/GetGameProviderAmounts', { params: params, headers: headers } )
    .pipe(
      catchError(this.handleError.bind(this))
    )
    .subscribe((data: any) => {
      this.dto.Response = {};
      this.dto.Response = data.gameProvidersBalanceList;
      this.providerAmountList = this.dto.Response;
      this.UserName = this.providerAmountList[0].name;
      this.UserPhoneNo = this.providerAmountList[0].phone_no;
      this.TotalGameBalance = data.totalGameBalance;
      this.dtTrigger.next();
      this.spinner.hide();
    })
  }

  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }

  GTMPopup( providerName,mainBalance,gameBalance,providerId,mainBalance_Str,gameBalance_Str,phoneNo,providerCode ){
    $('#GTM').modal("show");
    this.ProviderName = providerName;
    this.MainWallet = mainBalance;
    this.GameWallet = gameBalance;
    this.ProviderId = providerId;
    this.MainWallet_Str = mainBalance_Str;
    this.GameWallet_Str = gameBalance_Str;
    this.PhoneNo = phoneNo;
    this.ProviderCode = providerCode;
    this.Amount = 0;
  }

  MTGPopup( providerName,mainBalance,gameBalance,providerId,mainBalance_Str,gameBalance_Str,phoneNo,providerCode ){
    $('#MTG').modal("show");
    this.ProviderName = providerName;
    this.MainWallet = mainBalance;
    this.GameWallet = gameBalance;
    this.ProviderId = providerId;
    this.MainWallet_Str = mainBalance_Str;
    this.GameWallet_Str = gameBalance_Str;
    this.PhoneNo = phoneNo;
    this.ProviderCode = providerCode;
    this.Amount = 0;
  }

  Withdraw(){
    this.spinner.show();

    if(this.Amount == 0 || this.GameWallet < this.Amount ){
      this.spinner.hide();
      this.toastr.error("Please check Transfer Amount!", 'Invalid!', {
        timeOut: 3000,
        positionClass: 'toast-top-right',
      });
      return;
    }

    this.dto.token = this.storage.retrieve('token');

    let headers = new HttpHeaders();
    headers = headers.set('Authorization',  this.dto.token);

    if(this.ProviderCode =="SKM")
    {
      this.transferAmount.id = this.userId;
      this.transferAmount.gameWallet = this.GameWallet;
      this.transferAmount.amount = this.Amount;
      this.transferAmount.type = "Withdraw";
      this.transferAmount.providerId = this.ProviderId;
      this.transferAmount.providerCode = this.ProviderCode;
      this.transferAmount.phone_no = this.PhoneNo;
      this.transferAmount.isShowHistory = this.isChecked;
      this.transferAmount.remark = this.Remark;
  
      this.http.post(this.funct.ipaddress + 'manageWallets/SkmTransferout', this.transferAmount, {headers: headers})
      .pipe(
        catchError(this.handleError.bind(this))
       )
      .subscribe(
        result => {
          this.dto.Response = {};
          this.dto.Response = result;
          if (this.dto.Response.status == 'Success') {
            this.spinner.hide();
            this.toastr.success(this.dto.Response.message, 'Success!', {
              timeOut: 3000,
              positionClass: 'toast-top-right'
            });
          }
          else{
            this.spinner.hide();
            this.toastr.error(this.dto.Response.message, 'Invalid!', {
            timeOut: 3000,
            positionClass: 'toast-top-right',
            });
          }
          $('#GTM').modal("hide");
          this.Refresh();
          this.Close();
        });
    }
    else{
      this.transferAmount.id = this.userId;
      this.transferAmount.gameWallet = this.GameWallet;
      this.transferAmount.amount = this.Amount;
      this.transferAmount.type = "Withdraw";
      this.transferAmount.providerId = this.ProviderId;
      this.transferAmount.providerCode = this.ProviderCode;
      this.transferAmount.phone_no = this.PhoneNo;
      this.transferAmount.isShowHistory = this.isChecked;
      this.transferAmount.remark = this.Remark;
  
      this.http.post(this.funct.ipaddress + 'manageWallets/TransferAmountForManageWallets', this.transferAmount, {headers: headers})
      .pipe(
        catchError(this.handleError.bind(this))
       )
      .subscribe(
        result => {
          this.dto.Response = {};
          this.dto.Response = result;
          if (this.dto.Response.status == 'Success') {
            this.spinner.hide();
            this.toastr.success(this.dto.Response.message, 'Success!', {
              timeOut: 3000,
              positionClass: 'toast-top-right'
            });
          }
          else{
            this.spinner.hide();
            this.toastr.error(this.dto.Response.message, 'Invalid!', {
            timeOut: 3000,
            positionClass: 'toast-top-right',
            });
          }
          $('#GTM').modal("hide");
          this.Refresh();
          this.Close();
        });
    }
    
  }

  Deposit(){
    this.spinner.show();

    if(this.Amount == 0 || this.MainWallet < this.Amount ){
      this.spinner.hide();
      this.toastr.error("Please check Transfer Amount!", 'Invalid!', {
        timeOut: 3000,
        positionClass: 'toast-top-right',
      });
      return;
    }

    this.dto.token = this.storage.retrieve('token');

    let headers = new HttpHeaders();
    headers = headers.set('Authorization',  this.dto.token);
    if(this.ProviderCode == "SKM")
    {
         
    this.transferAmount.id = this.userId;
    this.transferAmount.gameWallet = this.GameWallet;
    this.transferAmount.amount = this.Amount;
    this.transferAmount.type = "Deposit";
    this.transferAmount.providerId = this.ProviderId;
    this.transferAmount.providerCode = this.ProviderCode;
    this.transferAmount.phone_no = this.PhoneNo;
    this.transferAmount.isShowHistory = this.isChecked;
    this.transferAmount.remark = this.Remark;

    this.http.post(this.funct.ipaddress + 'manageWallets/SkmTransferIn', this.transferAmount, {headers: headers})
    .pipe(
      catchError(this.handleError.bind(this))
     )
    .subscribe(
      result => {
        this.dto.Response = {};
        this.dto.Response = result;
        if (this.dto.Response.status == 'Success') {
          this.spinner.hide();
          this.toastr.success(this.dto.Response.message, 'Success!', {
            timeOut: 3000,
            positionClass: 'toast-top-right'
          });
        }
        else{
          this.spinner.hide();
          this.toastr.error(this.dto.Response.message, 'Invalid!', {
          timeOut: 3000,
          positionClass: 'toast-top-right',
          });
        }
        $('#MTG').modal("hide");
        this.Refresh();
        this.Close();
      });
    }
    else
    {
          
    this.transferAmount.id = this.userId;
    this.transferAmount.gameWallet = this.GameWallet;
    this.transferAmount.amount = this.Amount;
    this.transferAmount.type = "Deposit";
    this.transferAmount.providerId = this.ProviderId;
    this.transferAmount.providerCode = this.ProviderCode;
    this.transferAmount.phone_no = this.PhoneNo;
    this.transferAmount.isShowHistory = this.isChecked;
    this.transferAmount.remark = this.Remark;

    this.http.post(this.funct.ipaddress + 'manageWallets/TransferAmountForManageWallets', this.transferAmount, {headers: headers})
    .pipe(
      catchError(this.handleError.bind(this))
     )
    .subscribe(
      result => {
        this.dto.Response = {};
        this.dto.Response = result;
        if (this.dto.Response.status == 'Success') {
          this.spinner.hide();
          this.toastr.success(this.dto.Response.message, 'Success!', {
            timeOut: 3000,
            positionClass: 'toast-top-right'
          });
        }
        else{
          this.spinner.hide();
          this.toastr.error(this.dto.Response.message, 'Invalid!', {
          timeOut: 3000,
          positionClass: 'toast-top-right',
          });
        }
        $('#MTG').modal("hide");
        this.Refresh();
        this.Close();
      });
    }



  }
  checkuncheckall() {
    if (this.isChecked == true) {
      this.isChecked = false;
    }
    else {
      this.isChecked = true;
    }
  }
  changeSelection()
  {
    
  }
  Close()
  {
      this.Remark ="";
      this.isChecked = false;
  }
}
