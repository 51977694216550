<div class="wrapper">
    <!-- Navbar -->
    <app-header-sidebar></app-header-sidebar>

    <!-- here here here -->
    <!-- Content Wrapper. Contains page content -->
    <div class="content-wrapper">
        <!-- Content Header (Page header) -->
        <div class="content-header">
            <div class="container-fluid">
            </div><!-- /.container-fluid -->
        </div>
        <!-- /.content-header -->

        <!-- Main content -->
        <section class="content">
            <div class="container-fluid">
                <form>
                    <div class="row">
                        <div class="col-md-12">
                            <div class="card">
                                <div class="card-header">
                                    <h3 class="card-title" style="font-weight: bold;"> TwoD Manual ON/OFF</h3>
                                </div>
                                <!-- /.card-header -->
                                <div class="card-body">
                                    <form>
                                        <div class="row">
                                            <label class="col-md-2">10:30 AM    Manual Out</label>
                                            <input style = "cursor: pointer;" type="checkbox" [(ngModel)]="manual10_30" 
                                            [ngModelOptions]="{standalone: true}">
                                        </div>
                                        <div class="row">
                                            <label class="col-md-2">12:01 PM    Manual Out</label>
                                            <input style = "cursor: pointer;" type="checkbox" [(ngModel)]="manual12_01" 
                                            [ngModelOptions]="{standalone: true}">
                                        </div>
                                        <div class="row">
                                            <label class="col-md-2">02:30 PM    Manual Out</label>
                                            <input style = "cursor: pointer;" type="checkbox" [(ngModel)]="manual02_30" 
                                            [ngModelOptions]="{standalone: true}">
                                        </div>
                                        <div class="row">
                                            <label class="col-md-2">04:30 PM    Manual Out</label>
                                            <input style = "cursor: pointer;" type="checkbox" [(ngModel)]="manual04_30" 
                                            [ngModelOptions]="{standalone: true}">
                                        </div>
                                        <div class="row" style="margin-top: 1%;">
                                            <div class="col-md-1 col-sm-2"></div>
                                            <div class="col-md-2 col-sm-6">
                                                <button type="button" class="btn btn-block btn-success"
                                                    (click)="goConfigSave()"> Save </button>&nbsp;
                                            </div>
                                        </div>
                                    </form>
                                </div>
                                <!-- /.card-body -->
                            </div>
                        </div>

                    </div>
                </form>
                <!-- /.row -->

                <!-- /.row -->
            </div>
            <!--/. container-fluid -->
        </section>
        <!-- /.content -->
    </div>

    <aside class="control-sidebar control-sidebar-dark">
        <!-- Control sidebar content goes here -->
    </aside>

    <app-footer></app-footer>

    <ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#fff" type="square-jelly-box">
        <p style="font-size: 16px; color: white">Loading...</p>
    </ngx-spinner>

</div>


<div id="deleteData" class="modal fade">
    <div class="modal-dialog">
        <div class="modal-content">

            <div class="modal-body">
                <form>

                    <div class="row" style="margin-top: 5%;margin-bottom: 3%;margin-left: 9%;">
                        <div class="form-group col-md-12">
                            <h5> This Account is used by another device. 
                                Please Sign Out. Login Again. </h5>
                        </div>
                    </div>

                    <div class="row" style="margin-left: 40%;margin-bottom: 3%;">
                        <div class="col-md-5 col-sm-6" id="deleteBtn">
                            <button type="button" class="btn btn-block btn-success" (click)="OkLogout()"> Ok </button>
                        </div>
                    </div>
                </form>
            </div>

        </div>
    </div>
</div>