import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-advertising-team-add',
  templateUrl: './advertising-team-add.component.html',
  styleUrls: ['./advertising-team-add.component.css']
})
export class AdvertisingTeamAddComponent implements OnInit {

  constructor() {
  
   }

  ngOnInit(): void {
  }

}
