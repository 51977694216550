<div class="wrapper">
    <app-header-sidebar></app-header-sidebar>
    
    <div class="content-wrapper">

        <div class="content-header">
          <div class="container-fluid">
            <div class="row mb-2" style="border: 1px solid #ced4da;border-radius: 0.25rem;">
              <div class="col-sm-8">
                <h3 class="m-0 text-dark" style="font-weight: bold;padding: 3px 0px 3px 5px;"> Manage Game Wallets - Provider Detail </h3>
              </div>
            </div>
          </div>
        </div>
 
        <section class="content">
          <div class="container-fluid">
            <form>
                <div class="row">
                  &nbsp;&nbsp;&nbsp;
                  <div class="form-group col-md-2">
                    <label>Name</label>
                    <input class="form-control form-control-sm" [(ngModel)]="UserName" [ngModelOptions]="{standalone: true}" disabled>
                  </div>

                  <div class="form-group col-md-2">
                    <label>Phone No</label>
                    <input class="form-control form-control-sm" [(ngModel)]="UserPhoneNo" [ngModelOptions]="{standalone: true}" (input)="onInput($event)" disabled>
                  </div>
                </div>

                <div class="col-md-12">
                  <div class="card">
                    <div class="card-header">
                       <h3 class="card-title" style="font-weight: bold;"> Provider Detail </h3>
                    </div>
               
                    <div class="card-body">
                      <table width='100%' id="{{'tbPD' + idIndex}}" datatable [dtTrigger]="dtTrigger" [dtOptions]="dtOptions" class="table table-bordered table-hover">
                        <thead>
                        <tr>
                          <!-- <th style="font-family: unicode;">Name</th>
                          <th style="font-family: unicode;">Phone No</th> -->
                          <th style="font-family: unicode;">Provider Name</th>
                          <th style="font-family: unicode;">Main Balance</th>
                          <th style="font-family: unicode;">Game Balance</th>     
                          <th style="font-family: unicode;">Actions</th>                  
                        </tr>
                        </thead>
                        <tbody>                          
                          <tr  *ngFor="let PA of providerAmountList">
                            <!-- <td style="font-family: unicode;width: 150px;">{{PA.name}}</td>
                            <td style="font-family: unicode;" >{{PA.phone_no}}</td> -->
                            <td style="font-family: unicode;" >{{PA.providerName}}</td>
                            <td style="font-family: unicode;" >{{PA.mainBalance  | number : '1.0-2' }}</td>
                            <td style="font-family: unicode;" >{{PA.gameBalance  | number : '1.0-2' }}</td>
                            <td style="font-family: unicode;" >                            
                              <button  type="button" class="btn btn-block btn-success btn-sm " (click)="GTMPopup( PA.providerName,PA.mainBalance,PA.gameBalance,PA.providerId,PA.mainBalance_Str,PA.gameBalance_Str,PA.phone_no,PA.providerCode )" >Game To Main</button>
                              <button  type="button" class="btn btn-block btn-primary btn-sm " (click)="MTGPopup( PA.providerName,PA.mainBalance,PA.gameBalance,PA.providerId,PA.mainBalance_Str,PA.gameBalance_Str,PA.phone_no,PA.providerCode )" >Main To Game</button>
                            </td>
                          </tr>                                          
                        </tbody>
                        <tr style="background-color:steelblue; color: white">
                          <td style="font-family: unicode;text-align:center;" colspan="2" ><b>Total Game Balance</b></td>                       
                          <td style="font-family: unicode;"> <b>{{TotalGameBalance  | number : '1.0-2' }} </b> </td>
                          <td></td>
                        </tr>
                      </table>                   
                  </div>
            
                  </div>
                </div>

            </form>

          </div>
        </section>

      </div>
      <aside class="control-sidebar control-sidebar-dark">
 
      </aside>
    
    <app-footer></app-footer>
    <ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#fff" type="square-jelly-box">
      <p style="font-size: 16px; color: white">Loading...</p>
    </ngx-spinner>
</div>

<div id="deleteData" class="modal fade">
  <div class="modal-dialog">
      <div class="modal-content">

          <div class="modal-body">
              <form>

                  <div class="row" style="margin-top: 5%;margin-bottom: 3%;margin-left: 9%;">
                      <div class="form-group col-md-12">
                          <h5> This Account is used by another device. 
                              Please Sign Out. Login Again. </h5>
                      </div>
                  </div>

                  <div class="row" style="margin-left: 40%;margin-bottom: 3%;">
                      <div class="col-md-5 col-sm-6" id="deleteBtn">
                          <button type="button" class="btn btn-block btn-success" (click)="OkLogout()"> Ok </button>
                      </div>
                  </div>
              </form>
          </div>

      </div>
  </div>
</div>

<div id="GTM" class="modal fade">
  <div class="modal-dialog">
    <div class="modal-content" style="width : 700px; margin-left: -100px;">
      <div class="modal-header">
        <h3 class="modal-title" style="font-weight: bold;font-size: 1.1rem;">Game Wallet To Main Wallet</h3>
        <button type="button" class="close" data-dismiss="modal" aria-hidden="true" (click)="Close()"> x </button>
      </div>

      <div class="modal-body">
        <form>
          <div class="col-md-12">
            <div class="row" style="margin-left: 0.1%;">
              <label class="col-md-4"> Provider Name </label>
              <div class="form-group col-md-8">
                <label class="col-md-4"> {{ProviderName}} </label>
              </div>
            </div>
            <div class="row" style="margin-left: 0.1%;">
              <label class="col-md-4"> Main Wallet </label>
              <div class="form-group col-md-8">
                <label class="col-md-4"> {{MainWallet  | number : '1.0-2' }} </label>
              </div>
            </div>
            <div class="row" style="margin-left: 0.1%;">
              <label class="col-md-4"> Game Wallet </label>
              <div class="form-group col-md-8">
                <label class="col-md-4"> {{GameWallet  | number : '1.0-2' }} </label>
              </div>
            </div>

            <div class="row" style="margin-left: 0.1%;">
              <label class="col-md-4"> Transfer Amount </label>
              <div class="form-group col-md-8">              
                <input type = "text" class="form-control form-control-sm" [(ngModel)]="Amount" [ngModelOptions]="{standalone: true}" >
              </div>
            </div>    
            
            <div class="row" style="margin-left: 0.3%; margin-top: 0.3%;">
              <label class="col-md-4">  History Show </label>
              <div>              
                <input type="checkbox" name="userscheck" [checked]="isChecked"
                (click)="checkuncheckall()"
                style="width: 1.10rem;height: 1.10rem;margin-left: 28%;"
                >
              </div>
            </div> 


            <div class="row" style="margin-left: 0.1%; margin-top: 3%;">
              <label class="col-md-4"> Remark </label>
              <div class="form-group col-md-8">              
                <input type = "text" class="form-control form-control-sm" [(ngModel)]="Remark" [ngModelOptions]="{standalone: true}" >
              </div>
            </div> 
            
          
            <div class="row" style="margin-top: 1%;">
                <div class="col-md-4"></div>
                <div class="col-md-2 col-sm-6">
                  <button type="button"  class="btn btn-block btn-success" (click)="Withdraw()">Withdraw</button>&nbsp;
                </div>
                <div class="col-md-2 col-sm-6">
                  <button type="button" class="btn btn-block btn-danger" data-dismiss="modal" (click)="Close()">Cancel</button>
                </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>

<div id="MTG" class="modal fade">
  <div class="modal-dialog">
    <div class="modal-content" style="width : 700px; margin-left: -100px;">
      <div class="modal-header">
        <h3 class="modal-title" style="font-weight: bold;font-size: 1.1rem;">Main Wallet To Game Wallet </h3>
        <button type="button" class="close" data-dismiss="modal" aria-hidden="true" (click)="Close()"> x </button>
      </div>

      <div class="modal-body">
        <form>
          <div class="col-md-12">
            <div class="row" style="margin-left: 0.1%;">
              <label class="col-md-4"> Provider Name </label>
              <div class="form-group col-md-8">
                <label class="col-md-4"> {{ProviderName}} </label>
              </div>
            </div>
            <div class="row" style="margin-left: 0.1%;">
              <label class="col-md-4"> Main Wallet </label>
              <div class="form-group col-md-8">
                <label class="col-md-4"> {{MainWallet  | number : '1.0-2' }} </label>
              </div>
            </div>
            <div class="row" style="margin-left: 0.1%;">
              <label class="col-md-4"> Game Wallet </label>
              <div class="form-group col-md-8">
                <label class="col-md-4"> {{GameWallet  | number : '1.0-2' }} </label>
              </div>
            </div>

            <div class="row" style="margin-left: 0.1%;">
              <label class="col-md-4"> Transfer Amount </label>
              <div class="form-group col-md-8">              
                <input type = "text" class="form-control form-control-sm" [(ngModel)]="Amount" [ngModelOptions]="{standalone: true}" >
              </div>
            </div>     
            
            
            <div class="row" style="margin-left: 0.3%; margin-top: 0.3%;">
              <label class="col-md-4">  History Show </label>
              <div>              
                <input type="checkbox" name="userscheck" [checked]="isChecked"
                (click)="checkuncheckall()"
                style="width: 1.10rem;height: 1.10rem;margin-left: 28%;"
                >
              </div>
            </div> 

            
            <div class="row" style="margin-left: 0.1%; margin-top: 3%;">
              <label class="col-md-4"> Remark </label>
              <div class="form-group col-md-8">              
                <input type = "text" class="form-control form-control-sm" [(ngModel)]="Remark" [ngModelOptions]="{standalone: true}" >
              </div>
            </div>    
          
            <div class="row" style="margin-top: 1%;">
                <div class="col-md-4"></div>
                <div class="col-md-2 col-sm-6">
                  <button type="button"  class="btn btn-block btn-success" (click)="Deposit()">Deposit</button>&nbsp;
                </div>
                <div class="col-md-2 col-sm-6">
                  <button type="button" class="btn btn-block btn-danger" data-dismiss="modal" (click)="Close()">Cancel</button>
                </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>


