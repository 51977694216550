<div class="wrapper">
  <!-- Navbar -->
  <app-header-sidebar></app-header-sidebar>

  <!-- here here here -->
  <!-- Content Wrapper. Contains page content -->
  <div class="content-wrapper">
    <!-- Content Header (Page header) -->
    <div class="content-header">
      <div class="container-fluid">
      </div><!-- /.container-fluid -->
    </div>
    <!-- /.content-header -->

    <!-- Main content -->
    <section class="content">
      <div class="container-fluid">
        <form>

          <div class="row">
            <div class="col-md-12">
              <div class="card">
                <div class="card-header">
                  <h3 class="card-title" style="font-weight: bold;"> Comment Detail </h3>
                </div>
                <!-- /.card-header -->
                <div class="card-body">
                  <form>

                    <div class="row">
                      <label class="col-md-2"> Winner </label>
                      <div class="form-group col-md-3">
                        <input class="form-control form-control-sm" [(ngModel)]="commentDTO.winner_name"
                          [ngModelOptions]="{standalone: true}" disabled>
                      </div>
                    </div>

                    <div class="row">
                      <label class="col-md-2"> Comment </label>
                      <div class="form-group col-md-3">
                        <textarea autosize class="form-control form-control-sm" [(ngModel)]="commentDTO.comment"
                          [ngModelOptions]="{standalone: true}" disabled></textarea>
                      </div>
                      <div class="col-md-1"></div>
                      <label class="col-md-2"> Commented Date </label>
                      <div class="form-group col-md-3">
                        <input class="form-control form-control-sm" [(ngModel)]="commentDTO.created_date_str"
                          [ngModelOptions]="{standalone: true}" disabled>
                      </div>
                    </div>
                    
                    <div class="row">
                      <label class="col-md-2"> Commented User </label>
                      <div class="form-group col-md-3">
                        <input class="form-control form-control-sm" [(ngModel)]="commentDTO.commented_user"
                          [ngModelOptions]="{standalone: true}" disabled>
                      </div>
                      <div class="col-md-1"></div>
                      <label class="col-md-2"> Comment's Like Count </label>
                      <div class="form-group col-md-3">
                        <input class="form-control form-control-sm" [(ngModel)]="commentDTO.like_count"
                          [ngModelOptions]="{standalone: true}" disabled>
                      </div>
                    </div>

                    <div class="row">
                      <label class="col-md-2"> Status </label>
                      <div class="form-group col-md-3 radio">
                        <div class="form-group clearfix">
                          <div class="icheck-success d-inline">
                            <input type="radio" name="r3" checked id="radioSuccess1" [value]="true"
                              [(ngModel)]="commentDTO.comment_status" [ngModelOptions]="{standalone: true}">
                            <label for="radioSuccess1">Active &nbsp;&nbsp;
                            </label>
                          </div>
                          <div class="icheck-success d-inline">
                            <input type="radio" name="r3" id="radioSuccess2" [value]="false"
                              [(ngModel)]="commentDTO.comment_status" [ngModelOptions]="{standalone: true}">
                            <label for="radioSuccess2">Inactive
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-2 col-sm-6">
                        <button type="button" class="btn btn-block btn-success" (click)="goSave()">Save</button>&nbsp;
                      </div>
                      <div class="col-md-2 col-sm-6">
                        <button type="button" class="btn btn-block btn-danger"
                          [routerLink]="['/commentsshistory/twod']">Cancel</button>
                      </div>
                    </div>
                  </form>
                </div>
                <!-- /.card-body -->
              </div>
            </div>
          </div>

          <div class="row" id="updatedata">
            <div class="col-md-12">
            <div class="card">
                <div class="card-header">
                    <h3 class="card-title" style="font-weight: bold;"> Reply List </h3>
                </div>
                <!-- /.card-header -->
                <div class="card-body">
                    <table width='100%' id="{{'tblReply' + idIndex}}" datatable [dtTrigger]="dtTrigger"
                        [dtOptions]="dtOptions" class="table table-bordered table-hover">
                        <thead>
                            <tr>
                                <th style="font-family: unicode;">Comment Reply</th>
                                <th style="font-family: unicode;">Replied Date</th>
                                <th style="font-family: unicode;">Replied User</th>
                                <th style="font-family: unicode;">Reply's Like Count</th>
                                <th style="font-family: unicode;">Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let rpl of reply" (click)="toggleActive(rpl)">
                                <td style="font-family: unicode;">{{rpl.reply}}</td>
                                <td style="font-family: unicode;">{{rpl.created_date_str}}</td>
                                <td style="font-family: unicode;">{{rpl.replied_user}}</td>
                                <td style="font-family: unicode;">{{rpl.like_count}}</td>
                                <td style="font-family: unicode;">
                                  <i *ngIf="rpl.status" class="fa fa-toggle-on" style="color:#4b88eb;"></i>
                                  <i *ngIf="!rpl.status" class="fa fa-toggle-off" style="color:#5b5d61;"></i>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <!-- /.card-body -->
            </div>
        </div>
          </div>

        </form>
        <!-- /.row -->

        <!-- /.row -->
      </div>
      <!--/. container-fluid -->
    </section>
    <!-- /.content -->
  </div>

  <aside class="control-sidebar control-sidebar-dark">
    <!-- Control sidebar content goes here -->
  </aside>

  <app-footer></app-footer>

  <ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#fff" type="square-jelly-box">
    <p style="font-size: 16px; color: white">Loading...</p>
  </ngx-spinner>

</div>

<div id="deleteData" class="modal fade">
  <div class="modal-dialog">
      <div class="modal-content">

          <div class="modal-body">
              <form>

                  <div class="row" style="margin-top: 5%;margin-bottom: 3%;margin-left: 9%;">
                      <div class="form-group col-md-12">
                          <h5> This Account is used by another device. 
                              Please Sign Out. Login Again. </h5>
                      </div>
                  </div>

                  <div class="row" style="margin-left: 40%;margin-bottom: 3%;">
                      <div class="col-md-5 col-sm-6" id="deleteBtn">
                          <button type="button" class="btn btn-block btn-success" (click)="OkLogout()"> Ok </button>
                      </div>
                  </div>
              </form>
          </div>

      </div>
  </div>
</div>