import { Component, OnInit ,ViewChild} from '@angular/core';
import { HttpClient, HttpHeaders,HttpErrorResponse, HttpParams } from '@angular/common/http';
import { Router, ActivatedRoute } from '@angular/router';
import { LocalStorageService } from 'ngx-webstorage';
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from 'ngx-toastr';
import { DtoService } from '../../service/dto.service';
import { FunctService } from '../../service/funct.service';
import { catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { Subject } from 'rxjs';
import { DataTableDirective } from 'angular-datatables';
declare var $: any;
@Component({
  selector: 'app-game-username',
  templateUrl: './game-username.component.html',
  styleUrls: ['./game-username.component.css']
})
export class GameUsernameComponent implements OnInit {
  token: any;
  GameProviderList: any;
  PhoneNumber:any = "";
  GameProvider:any = 0;
  resultData:any ="";
  gameusername: string= '';

  hardcode_edit :any;
  hardcode_add :any;
  hardcode_view: any;
  hardcode_delete_bool : any;
  hardcode_add_bool : any;
  hardcode_edit_bool : any;
  isPhoneNo = false;
  id :any;
  
  constructor(private storage: LocalStorageService, private spinner: NgxSpinnerService, private toastr: ToastrService, private http: HttpClient, private dto: DtoService, private router: Router,
    private route: ActivatedRoute, private funct: FunctService) {
      this.storage.store('isNotiSong', "");
    this.hardcode_edit = "CheckGameUserName_Edit"; 
    this.dto.menuCodes = this.storage.retrieve('menucodes');

    const filteredMenuCodeds_edit =[];
    this.dto.menuCodes.forEach(menuCode => {
      if (menuCode === this.hardcode_edit) {
        filteredMenuCodeds_edit.push(menuCode);
      }
    });
    if(filteredMenuCodeds_edit.length != 0)
    {
          this.hardcode_edit_bool = true;
    }  
    this.getGameProviderList();

  }
  ngOnInit(): void {
    if (!this.storage.retrieve('loadFlag')) {
      this.storage.store('loadFlag', 'noLoad');
      setTimeout(function () {
        location.reload();
      }, 1);
    }
    else {
      this.storage.clear('loadFlag');
    }
  }
  ngOnDestroy(): void {

  }
  handleError(error: HttpErrorResponse){
    if(error.status == 423)
    {
      this.spinner.hide();
      $("#deleteData").modal("show");
    }
    if(error.status == 403)
    {
      this.spinner.hide();
      this.toastr.error("Limited Access.", 'Invalid!', {
        timeOut: 3000,
        positionClass: 'toast-top-right',
        });
    }
    if(error.status == 404)
    {
      this.spinner.hide();
      this.toastr.error("Not Found", 'Invalid!', {
        timeOut: 3000,
        positionClass: 'toast-top-right',
        });
    }
    else{
      this.spinner.hide();    
      this.toastr.error('User Not Found!', 'Invalid!', {
        timeOut: 5000,
        positionClass: 'toast-top-right',
      });  
      
    }
    return throwError(error);
  }

  OkLogout()
  {
    window.location.href ="./ad-login";
  } 

  getGameProviderList(){
    this.spinner.show();
    this.token = this.storage.retrieve('token');
    let headers = new HttpHeaders();
    headers = headers.set('Authorization', this.token);
    this.http.get(this.funct.ipaddress + 'gameProvider/GetGameProviderLists', { headers: headers })
    .pipe(
      catchError(this.handleError.bind(this))
     )
    .subscribe(
      result => {
        this.dto.Response = {};
        this.dto.Response = result;
        this.GameProviderList=this.dto.Response;
        this.spinner.hide();
      });
  }

  check() {
    this.spinner.show(); 
    this.resultData ="";   
     //if (this.PhoneNumber != '' && this.PhoneNumber != null ) //&& this.GameProvider != 0
      //{
        this.token = this.storage.retrieve('token');
        let headers = new HttpHeaders();   
        headers = headers.set('Authorization', this.token);
        let params = new HttpParams();
        params = params.set('PhoneNumber', this.PhoneNumber).set('GameProvider', this.GameProvider).set('GameUserId',this.gameusername);
        this.http.get(this.funct.ipaddress + 'loginGS/getUsernameInGame', { params: params, headers: headers })
        .pipe(
          catchError(this.handleError.bind(this))
         )
        .subscribe( 
          result => {
            if(result !=null)
            {
              this.dto.Response = {};
              this.dto.Response = result;
              console.log("game User Nmae " + JSON.stringify(this.dto.Response));
              this.id = result.id;
              if(result.phone_no != null)
              {
                this.spinner.hide();
                this.resultData =  "Phone Number is " + result.phone_no;  
                this.isPhoneNo = true;

              }
              else if( result.gameUserId !=null)
              {
                this.spinner.hide();
                this.resultData =  "Game User Name is " + result.gameUserId; 
                this.isPhoneNo = false;
              }
              else
              {
                if (this.dto.Response.errMsg == 'SUCCESS') {
                  this.spinner.hide();
                  this.resultData =  "Game User Name is " + this.dto.Response.data;  
                  this.isPhoneNo = false;  
              
                }
                else {
                  this.spinner.hide();                  
                  this.toastr.error(this.dto.Response.errMsg, 'Invalid!', {

                    timeOut: 5000,
                    positionClass: 'toast-top-right',
                  });
                }                 
              }
            }
            else
            {
              this.spinner.hide();
              this.toastr.error( "Phone Number/Game User Name ", 'Invalid!', {
                timeOut: 5000,
                positionClass: 'toast-top-right',
              });
            }
          
          }
        );     
      
    //}    
    // else {
    //   this.spinner.hide();
    //   this.toastr.error('Please fill all fields', 'Invalid input!', {
    //     timeOut: 3000,
    //     positionClass: 'toast-top-right',
    //   });
    // }
  }
}
