<div class="wrapper">
  <app-header-sidebar></app-header-sidebar>

  <div class="content-wrapper">
    
    <div class="content-header">
      <div class="container-fluid">
        <div class="row mb-2" style="border: 1px solid #ced4da;border-radius: 0.25rem;">
          <div class="col-sm-6">
            <h3 class="m-0 text-dark" style="font-weight: bold;padding: 3px 0px 3px 5px;"> Black List </h3>
          </div>
        </div>
       
      </div>
    </div>
   
    <section class="content">
      <div class="container-fluid">
        <form>
          <div class="row">

            <div class="col-md-12">
              
              <ul class="nav nav-tabs nav-pills">
                <li (click) ="getIPList()">
                  <a  id="twoD-tab" data-toggle="tab" href="#twoD"> IP List </a>
                </li>
                <li>
                  <a id="twoDList-tab" data-toggle="tab" href="#twoDWinnerList"> App IP </a>
                </li>
                <li  (click)="getUserIDList()">
                  <a   id="threeD-tab" data-toggle="tab" href="#threeD"> User List </a>
                </li>
                <li>
                  <a id="threeDWinnerList-tab" data-toggle="tab" href="#threeDWinnerList"> Add User Id </a>
                </li>
              </ul>

              
              <div class="tab-content clearfix">
                <div class="tab-pane" id="twoD">
                  <div class="row">                    
                    <div class="col-md-12">
                      <div class="card">
                        <div class="card-header">
                          <h3 class="card-title" style="font-weight: bold;">IP List</h3>
                        </div>
                        <div class="card-body">
                          <table width='100%' id="{{'tbltwoD' + idIndex}}" datatable
                            [dtTrigger]="dtTrigger" [dtOptions]="dtOptions" class="table table-bordered table-hover">
                            <thead>
                              <tr>
                                <th style="font-family: unicode;" class="text-center">Name</th>                                                     
                                <th style="font-family: unicode;" class="text-center" *ngIf="hardcode_delete_bool === true">Action</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr *ngFor="let ip of ipList">
                                <td style="font-family: unicode;" class="text-center">{{ip}}</td>
                                <td style="font-family: unicode;" class="text-center" *ngIf="hardcode_delete_bool === true">
                                  <a  >
                                  <i class="fas fa-trash" title = "Delete" (click)="delete(ip)" style="color:#dc3545;cursor: pointer;"></i>
                                  </a>                                   
                              </td>
                              </tr>
                            </tbody>
                          </table>
                          <!-- <tr *ngFor="let tdwl of ipList | paginate:  { itemsPerPage: itemsPerPage,  currentPage: page, totalItems: totalItems, id:'pagi1'}" ></tr>  -->
                          <!-- <div class = "row">
                            <div class = "col-md-6"></div>
                            <div class = "col-md-6">
                              <pagination-controls id="pagi1" (pageChange)="getMenuCodesgty((page = $event))"></pagination-controls>
                            </div>
                          </div> -->
                        </div>
                      </div>
                    </div>                   
                  </div>
                 
                </div>




                <div class="tab-pane" id="twoDWinnerList">
                  <div class="row">

                    <div class="form-group col-md-2">
                      <label>Enter IP</label>              
                    </div>
              
                    <div class="form-group col-md-2" >                   
                      <input type="text" class="form-control form-control-sm" [(ngModel)]="ip" placeholder="127.100.100.1" [ngModelOptions]="{standalone: true}">
                  </div>
                  
                    
                    <div class="form-group col-md-2" *ngIf="hardcode_add_bool === true">                    
                      <button type="button" class="btn btn-block btn-success" (click)="goSave(ip)">Save</button>&nbsp;
                    </div>

              

                  </div>               
                </div>
                



                <div class="tab-pane" id="threeD">                  

                    <div class="row">                    
                      <div class="col-md-12">
                        <div class="card">
                          <div class="card-header">
                            <h3 class="card-title" style="font-weight: bold;">Black User List</h3>
                          </div>
                          <div class="card-body">
                            <table width='100%' id="{{'tblthreeD' + idIndexTD}}" datatable
                              [dtTrigger]="dtTrigger1" [dtOptions]="dtOptions1" class="table table-bordered table-hover">
                              <thead>
                                <tr>
                                  <th style="font-family: unicode;" class="text-center">User Name</th>                                                     
                                  <th style="font-family: unicode;" class="text-center">Phone No</th>
                                  <th style="font-family: unicode;" class="text-center">Wallet</th>                                                     
                                  <th style="font-family: unicode;" class="text-center">Total Topup</th>
                                  <th style="font-family: unicode;" class="text-center">Total Withdrawal</th>                                                     
                                  <th style="font-family: unicode;" class="text-center">Total Bet</th>
                                  <th style="font-family: unicode;" class="text-center">Total Win</th>                                                     
                                  <th style="font-family: unicode;" class="text-center">First Topup Amount</th>
                                  <th style="font-family: unicode;" class="text-center">First Topup Date</th>                                                     
                                  <th style="font-family: unicode;" class="text-center" *ngIf="hardcode_delete_bool === true">Action</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr *ngFor="let ip of useripList">
                                  <td style="font-family: unicode;" class="text-center">{{ip.name}}</td>
                                  <td style="font-family: unicode;" class="text-center">{{ip.phone_no}}</td>
                                  <td style="font-family: unicode;" class="text-center">{{ip.topup_balance_Str
                                  }}</td>
                                  <td style="font-family: unicode;" class="text-center">{{ip.topup_balance_Str
                                  }}</td>
                                  <td style="font-family: unicode;" class="text-center">{{ip.withdraw_balance_Str
                                  }}</td>
                                  <td style="font-family: unicode;" class="text-center">{{ip.bet_amount_Str
                                  }}</td>
                                  <td style="font-family: unicode;" class="text-center">{{ip.win_amount_Str
                                  }}</td>
                                  <td style="font-family: unicode;" class="text-center">{{ip.first_topup_amt_Str}}</td>
                                  <td style="font-family: unicode;" class="text-center">{{ip.first_topup_date_Str}}</td>
                                  <td style="font-family: unicode;" class="text-center" *ngIf="hardcode_delete_bool === true">
                                    <a  >
                                    <i class="fas fa-trash" title = "Delete" (click)="deleteUser(ip.id)" style="color:#dc3545;cursor: pointer;"></i>
                                    </a>                                   
                                </td>
                                </tr>
                              </tbody>
                            </table>
                       
                          </div>
                        </div>
                      </div>                   
                    </div>
  
                </div>


                <div class="tab-pane" id="threeDWinnerList">
                  <div class="row">             

                      <div class="form-group col-md-2">
                        <label>Enter User Account</label>              
                      </div>
                
                      <div class="form-group col-md-2" >                   
                        <input type="text" class="form-control form-control-sm" [(ngModel)]="userid" placeholder="09 or 01 or 06 or 08" [ngModelOptions]="{standalone: true}">
                    </div>
                    
                      
                      <div class="form-group col-md-2" *ngIf="hardcode_add_bool === true">                    
                        <button type="button" class="btn btn-block btn-success" (click)="goSaveUserId(userid)">Save</button>&nbsp;
                      </div>

                  </div>
                </div>
              </div>
            </div>

          </div>
        </form>
    
      </div>
   
    </section>
 
  </div>

  <aside class="control-sidebar control-sidebar-dark">

  </aside>

  <app-footer></app-footer>
  <ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#fff" type="square-jelly-box">
    <p style="font-size: 16px; color: white">Loading...</p>
  </ngx-spinner>
</div>

<div id="deleteData" class="modal fade">
  <div class="modal-dialog">
      <div class="modal-content">

          <div class="modal-body">
              <form>

                  <div class="row" style="margin-top: 5%;margin-bottom: 3%;margin-left: 9%;">
                      <div class="form-group col-md-12">
                          <h5> This Account is used by another device. 
                              Please Sign Out. Login Again. </h5>
                      </div>
                  </div>

                  <div class="row" style="margin-left: 40%;margin-bottom: 3%;">
                      <div class="col-md-5 col-sm-6" id="deleteBtn">
                          <button type="button" class="btn btn-block btn-success" (click)="OkLogout()"> Ok </button>
                      </div>
                  </div>
              </form>
          </div>

      </div>
  </div>
</div>





<div id="deleteDataForTutorial" class="modal fade">
  <div class="modal-dialog">
      <div class="modal-content">

          <div class="modal-body">
              <form>

                  <div class="row" style="margin-top: 5%;margin-bottom: 3%;margin-left: 9%;">
                      <div class="form-group col-md-12" style="text-align: center;">
                          <h3 class="text-center"> Are you sure want to delete? </h3>
                      </div>
                  </div>

                  <div class="row" style="margin-left: 10%;margin-bottom: 3%;">
                      
                      <div class="col-md-5">
                          <button type="button" class="btn btn-block btn-danger" (click) = "closeTran()" [routerLink] = "['/blacklist/blacklistiplist']"> Cancel </button>
                      </div>

                      <div class="col-md-5" id="deleteBtn">
                        <button type="button" class="btn btn-block btn-success" (click)="deleteUserId()"> Confirm </button>
                    </div>

                  </div>

              </form>
          </div>

      </div>
  </div>
</div>
