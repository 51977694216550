<div class="wrapper">
    <app-header-sidebar></app-header-sidebar>

    <div class="content-wrapper">
        <!-- Content Header (Page header) -->
        <div class="content-header">
            <div class="container-fluid">
                <div class="row mb-2" style="border: 1px solid #ced4da;border-radius: 0.25rem;">
                    <div class="col-sm-8">
                        <h3 class="m-0 text-dark" style="font-weight: bold;padding: 3px 0px 3px 5px;"> Commission Calculation List </h3>
                    </div>
                </div><!-- /.row -->
            </div><!-- /.container-fluid -->
        </div>
        <!-- /.content-header -->
        <!-- Main content -->
        <section class="content">
            <div class="container-fluid">
                <form>
                    <div class="row">
                        <div class="form-group col-md-2" style="display: none;">
                        <label>Commission Amount</label>
                            <input class="form-control form-control-sm" [(ngModel)]="comissionAmount" id = "commissionAmount"
                                [ngModelOptions]="{standalone: true}">
                        </div>

                        <div class="form-group col-md-2" style = "display: none;">
                            <label>Wallet Amount</label>
                            <input class="form-control form-control-sm" [(ngModel)]="walletAmount" id = "walletAmount"
                                [ngModelOptions]="{standalone: true}">
                        </div>
                        
                        <div class="form-group col-md-2">
                            <label> Calculated Date </label>
                            <input id="createdDate" readonly class="form-control form-control-sm"
                                placeholder="Select Date" [ngxDatePicker]="dateInstanceSingle1" [value]="d"
                                [(ngModel)]="d" [ngModelOptions]="{standalone: true}"  (valueChange)="onChangeDate()">
                            <ngx-date-picker #dateInstanceSingle1></ngx-date-picker>
                        </div>

                        <div class="form-group col-md-2 col-sm-4 col-xs-4">
                            <label></label>
                            <button id="search" type="button" class="btn btn-block btn-primary btn-sm"
                                style="margin-top: 4%;" (click)="search()">Search</button>
                        </div>

                        <div class="form-group col-md-2 col-sm-4 col-xs-4">
                            <label></label>
                            <button type="button" class="btn btn-block btn-success btn-sm" style="margin-top: 4%;"
                                [routerLink]="['/commission-calculation']"> Add New </button>
                        </div>

                        <div class="col-md-12">
                            <div class="card">
                                <div class="card-header">
                                    <h3 class="card-title" style="font-weight: bold;"> Commission Calculation List </h3>
                                </div>
                                <!-- /.card-header -->
                                <div class="card-body">
                                    <table width='100%' id="{{'tblcommission' + idIndex}}" datatable
                                        [dtTrigger]="dtTrigger" [dtOptions]="dtOptions"
                                        class="table table-bordered table-hover">
                                        <thead>
                                            <tr>
                                                <th style="font-family: unicode;">From Date</th>
                                                <th style="font-family: unicode;">To Date</th>
												<th style="font-family: unicode;">Batch</th>
                                                <th style="font-family: unicode;">Created By</th>
                                                <th style="font-family: unicode;">Created Date</th>
                                                <th style="font-family: unicode;">Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr *ngFor="let objComission of comissionDTOList">
                                                <td style="font-family: unicode;">{{objComission.from_date}}</td>
												<td style="font-family: unicode;">{{objComission.to_date}}</td>                                               
											    <td style="font-family: unicode;">{{objComission.batch_no}}</td>
                                                <td style="font-family: unicode;">{{objComission.created_by}}</td>
                                                <td style="font-family: unicode;">{{objComission.created_date}}</td>
                                                <td style="font-family: unicode;">
                                                    <a>
                                                    <i class="fas fa-trash" title = "Delete" (click)="delete(objComission.id)" style="color:#dc3545;cursor: pointer;"></i>
                                                    </a>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <!-- /.card-body -->
                            </div>
                        </div>

                    </div>
                </form>
                <!-- /.row -->
            </div>
            <!--/. container-fluid -->
        </section>
        <!-- /.content -->
    </div>
    <aside class="control-sidebar control-sidebar-dark">
        <!-- Control sidebar content goes here -->
    </aside>
    <app-footer></app-footer>
    <ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#fff" type="square-jelly-box">
        <p style="font-size: 16px; color: white">Loading...</p>
    </ngx-spinner>
</div>

<div id="deleteData" class="modal fade">
    <div class="modal-dialog">
        <div class="modal-content">

            <div class="modal-body">
                <form>

                    <div class="row" style="margin-top: 5%;margin-bottom: 3%;margin-left: 9%;">
                        <div class="form-group col-md-12">
                            <h5> Are you sure want to delete? </h5>
                        </div>
                    </div>

                    <div class="row" style="margin-left: 10%;margin-bottom: 3%;">
                        <div class="col-md-5 col-sm-6" id="deleteBtn">
                            <button type="button" class="btn btn-block btn-success" (click)="deleteCommissionById()"> Ok </button>
                        </div>

                        <div class="col-md-5 col-sm-6">
                            <button type="button" class="btn btn-block btn-danger" (click) = "btncancel()" [routerLink] = "['/commission']"> Cancel </button>
                        </div>
                    </div>

                </form>
            </div>

        </div>
    </div>
</div>