
                    <div class="row">
                        <div class="form-group col-md-2">
                            <label> Date </label>
                            <input id="date" readonly class="form-control form-control-sm" placeholder="Select Date"
                                [ngxDatePicker]="dateInstanceSingle" [value]="date"
                                [(ngModel)] = "holidayDate"
                                [ngModelOptions]="{standalone: true}"
                                (valueChange)="onChangeDate()">
                            <ngx-date-picker #dateInstanceSingle></ngx-date-picker>
                        </div>

               

                        <!-- <div class="form-group col-md-4">
                        </div> -->

                        <div class="form-group col-md-2 col-sm-4 col-xs-4">
                            <label></label>
                            <button id="search" type="button" class="btn btn-block btn-primary btn-sm"
                                style="margin-top: 4%;" (click)="search()"> Search </button>
                        </div>

                        <div class="form-group col-md-2 col-sm-4 col-xs-4" style = "display: none;">
                            <label></label>
                            <button type="button" class="btn btn-block btn-success btn-sm" style="margin-top: 4%;"
                                [routerLink]="['/odd-detail']"> Add New </button>
                        </div>

                        <div class="col-md-12">
                            <div class="card">
                                <div class="card-header">
                                    <h3 class="card-title" style="font-weight: bold;"> Odd List </h3>
                                </div>
                          
                                <div class="card-body">
                                    <table width='100%' id="{{'tblodd' + idIndex}}" datatable
                                        [dtTrigger]="dtTrigger" [dtOptions]="dtOptions"
                                        class="table table-bordered table-hover">
                                        <thead>
                                            <tr>
                                                <th style="font-family: unicode;">Odd</th>
                                                <th style="font-family: unicode;">Type</th>
                                                <th style="font-family: unicode;">Section</th>
                                                <th style="font-family: unicode;">Created Date</th>
                                                <th style="font-family: unicode;">Created By</th>
                                                <th style="font-family: unicode;">Updated Date</th>
                                                <th style="font-family: unicode;">Updated By</th>
                                                <th style="font-family: unicode;" *ngIf="hardcode_edit_bool === true">Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr *ngFor="let odd of oddlist">
                                                <td style="font-family: unicode;">{{odd.odd}}</td>
                                                <td style="font-family: unicode;">{{odd.type}}</td>
                                                <td style="font-family: unicode;">{{odd.section}}</td>
                                                <td style="font-family: unicode;">{{odd.created_date_Str}}</td>
                                                <td style="font-family: unicode;">{{odd.created_by_name}}</td>
                                                <td style="font-family: unicode;">{{odd.updated_date | date:'yyyy-MM-dd HH:mm:ss'}}</td>
                                                <td style="font-family: unicode;">{{odd.updated_by_name}}</td>
                                                <td style="font-family: unicode;" *ngIf="hardcode_edit_bool === true">
                                                    <a [routerLink]="['/oddentry-detail', odd.id]">
                                                        <i class="fas fa-edit" style="color:#4b88eb"></i>
                                                    </a>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                       
                            </div>
                        </div>

                    </div>
          
      
    <ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#fff" type="square-jelly-box">
        <p style="font-size: 16px; color: white">Loading...</p>
    </ngx-spinner>


<div id="deleteData" class="modal fade">
    <div class="modal-dialog">
        <div class="modal-content">

            <div class="modal-body">
                <form>

                    <div class="row" style="margin-top: 5%;margin-bottom: 3%;margin-left: 9%;">
                        <div class="form-group col-md-12">
                            <h5> This Account is used by another device. 
                                Please Sign Out. Login Again. </h5>
                        </div>
                    </div>

                    <div class="row" style="margin-left: 40%;margin-bottom: 3%;">
                        <div class="col-md-5 col-sm-6" id="deleteBtn">
                            <button type="button" class="btn btn-block btn-success" (click)="OkLogout()"> Ok </button>
                        </div>
                    </div>
                </form>
            </div>

        </div>
    </div>
</div>