    <div class="wrapper">      
        <app-header-sidebar></app-header-sidebar>
        <div class="content-wrapper">
        
          <div class="content-header">
            <div class="container-fluid">
              <div class="row mb-2" style="border: 1px solid #ced4da;border-radius: 0.25rem;">
                <div class="col-sm-8">
                  <h3 class="m-0 text-dark" style="font-weight: bold;padding: 3px 0px 3px 5px;"> Game Transaction </h3>
                </div>
              </div>
            </div>
          </div>
        
          <section class="content">
            <div class="container-fluid">
              <form>
                <div class="row">
                  <div class="form-group col-md-2">
                    <label>Phone Number</label>
                    <input class="form-control form-control-sm" [(ngModel)]="phoneNo" [ngModelOptions]="{standalone: true}">
                  </div>

                  <div class="form-group col-md-2">
                    <label>Amount</label>
                    <input class="form-control form-control-sm" [(ngModel)]="amount" [ngModelOptions]="{standalone: true}">
                  </div>                  

                  <div class="form-group col-md-2 col-sm-4 col-xs-4">
                    <label></label>
                    <button id="search" type="button" class="btn btn-block btn-primary btn-sm" style="margin-top: 4%;" (click)="search()">Search</button>
                  </div>  
                  
                  <!-- <div class="form-group col-md-2 col-sm-4 col-xs-4">
                    <label></label>
                    <button id="delete" type="button" class="btn btn-block btn-primary btn-sm" style="margin-top: 4%;" (click)="confirmAll()">BatchDelete</button>
                  </div> -->


                   <div class="col-md-12">
                    <div class="card">
                      <div class="card-header">
                        <h3 class="card-title" style="font-weight: bold;"> Game Transaction Logs List </h3>
                      </div>
                     
                      <div class="card-body">
                        <table width='100%' id="{{'tblAdmin' + idIndex}}" datatable [dtTrigger]="dtTrigger" [dtOptions]="dtOptions" class="table table-bordered table-hover">
                          <thead>
                          <tr>

                            <!-- <th >
                              <input type="checkbox" name="logcheck"
                                  (click)="checkuncheckall()"
                                  style="width: 1.10rem;height: 1.10rem;margin-left: 28%;"
                                  (change)="changeSelection($event,0)">
                          </th> -->
                           
                            <th style="font-family: unicode;">Name</th>
                            <th style="font-family: unicode;">Phone Number</th>
                            <th style="font-family: unicode;">Provider code</th>
                            <th style="font-family: unicode;">Amount</th>
                            <th style="font-family: unicode;">Deposit/Withdraw </th>
                            <th style="font-family: unicode;">Transaction Number</th>
                            <th style="font-family: unicode;">Error Code</th>
                            <th style="font-family: unicode;">Error Desc</th>
                            <th style="font-family: unicode;">Created Date</th> 
                          
                          </tr>
                          </thead>
                          <tbody>
                          <tr *ngFor="let game of gameTransactionErrorList">

                            <!-- <td >
                              <input type="checkbox" name="logcheck" [checked]="isChecked"
                                  value="{{game.id}}"
                                  style="width: 1.10rem;height: 1.10rem;margin-left: 28%;"
                                  (change)="changeSelection($event,game.id)">
                          </td> -->
                            <td style="font-family: unicode;">{{game.name}}</td>
                            <td style="font-family: unicode;">{{game.phone_no}}</td>
                            <td style="font-family: unicode;">{{game.provider_code}}</td>
                            <td style="font-family: unicode;">{{game.amount}}</td>
                            <td style="font-family: unicode;">{{game.method}}</td>
                            <td style="font-family: unicode;">{{game.transaction_number}}</td>
                            <th style="font-family: unicode;">{{game.error_code}}</th>
                            <th style="font-family: unicode;">{{game.error_description}}</th>
                            <td style="font-family: unicode;">{{game.created_date_Str}}</td>
                     
                          </tr>                        
                          </tbody>
                        </table>
                      </div>
                     
                    </div>
                  </div>

                </div>
              </form>
            
            </div>
          </section>
        
        </div>
  
        <aside class="control-sidebar control-sidebar-dark">
        
        </aside>
  
        <app-footer></app-footer>

        <ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#fff" type="square-jelly-box">
          <p style="font-size: 16px; color: white">Loading...</p>
        </ngx-spinner>
  
    </div>

    
    <div id="deleteData" class="modal fade">
      <div class="modal-dialog">
          <div class="modal-content">
  
              <div class="modal-body">
                  <form>
  
                      <div class="row" style="margin-top: 5%;margin-bottom: 3%;margin-left: 9%;">
                          <div class="form-group col-md-12">
                              <h5> This Account is used by another device. 
                                  Please Sign Out. Login Again. </h5>
                          </div>
                      </div>
  
                      <div class="row" style="margin-left: 40%;margin-bottom: 3%;">
                          <div class="col-md-5 col-sm-6" id="deleteBtn">
                              <button type="button" class="btn btn-block btn-success" (click)="OkLogout()"> Ok </button>
                          </div>
                      </div>
                  </form>
              </div>
  
          </div>
      </div>
  </div>