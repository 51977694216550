<div class="wrapper">
  <app-header-sidebar></app-header-sidebar>

  <div class="content-wrapper">

    <div class="content-header">
      <div class="container-fluid">
        <div class="row mb-2" style="border: 1px solid #ced4da;border-radius: 0.25rem;">
          <div class="col-sm-8">
            <h3 class="m-0 text-dark" style="font-weight: bold;padding: 3px 0px 3px 5px;"> Transaction Tutorial </h3>
          </div>
        </div>
      </div>
    </div>

    <section class="content">
      <div class="container-fluid">
        <form>
          <div class="row">


            <div class="form-group col-md-2">
              <label> Bank Type </label>
              <select class="status form-control form-control-sm col-md-12 col-sm-4 col-xs-4" [(ngModel)]="BankType"
                [ngModelOptions]="{standalone: true}" (ngModelChange)="onChangeTagSection()">
                <option value="" selected>All</option>
                <option *ngFor="let bt of bankTypeList" value="{{bt.bankType}}">
                  {{bt.bankType}}
                </option>
              </select>
            </div>



            <div class="form-group col-md-2">
              <label>Transaction Type</label>
              <select class="transactiontype form-control form-control-sm col-md-12 col-sm-4 col-xs-4"
                [(ngModel)]="transactiontype" [ngModelOptions]="{standalone: true}">
                <option value="Topup" selected>TOPUP</option>
                <option value="Withdrawal">WITHDRAWAL</option>
                <option value="TRANSFER">TRANSFER</option>
                <option value="">All</option>
              </select>
            </div>



            <div class="form-group col-md-2 col-sm-4 col-xs-4">
              <label></label>
              <button id="search" type="button" class="btn btn-block btn-primary btn-sm" style="margin-top: 4%;"
                (click)="search()">Search</button>
            </div>



            <div class="form-group col-md-2 col-sm-4 col-xs-4" *ngIf="hardcode_add_bool===true">
              <label></label>
              <button type="button" class="btn btn-block btn-success btn-sm" style="margin-top: 4%;"
                [routerLink]="['/transaction-tutorial-detail']">
                <b> Add New </b>
              </button>
            </div>




            <div class="col-md-12">
              <div class="card">
                <div class="card-header">
                  <h3 class="card-title" style="font-weight: bold;"> Setting List </h3>
                </div>

                <div class="card-body">
                  <table width='100%' id="{{'tblAgent' + idIndex}}" datatable [dtTrigger]="dtTrigger"
                    [dtOptions]="dtOptions" class="table table-bordered table-hover">
                    <thead>
                      <tr>
                        <th style="font-family: unicode; width: 20%;">Video Link</th>
                        <th style="font-family: unicode; width: 20%;">Description (Myan)</th>
                        <th style="font-family: unicode; width: 18%;">Description (Eng)</th>
                        <th style="font-family: unicode; width: 18%;">Description (Thai)</th>
                        <th style="font-family: unicode; width: 20%;">Description (Chinese)</th>
                        <th style="font-family: unicode; width: 20%;">Transaction Type</th>
                        <th style="font-family: unicode; width: 20%;">Bank Type</th>
                        <th style="font-family: unicode; width: 20%;">Sort</th>
                        <th style="font-family: unicode;">Created Date</th>
                        <th style="font-family: unicode;">Created By</th>
                        <th style="font-family: unicode;">Updated Date</th>
                        <th style="font-family: unicode;">Updated By</th>
                        <th style="font-family: unicode;" *ngIf="hardcode_edit_bool === true">Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let agent of agentList">
                        <td class="live-link">{{agent.liveLink}}</td>
                        <th style="font-family: unicode; width: 20%;">{{agent.description_my}}</th>
                        <th style="font-family: unicode; width: 20%;">{{agent.description_en}}</th>
                        <th style="font-family: unicode; width: 20%;">{{agent.description_th}}</th>
                        <th style="font-family: unicode; width: 20%;">{{agent.description_zh}}</th>
                        <th style="font-family: unicode; width: 20%;">{{agent.transType}}</th>
                        <th style="font-family: unicode; width: 20%;">{{agent.bankType}}</th>
                        <th style="font-family: unicode; width: 20%;">{{agent.orderNumber}}</th>
                        <td style="font-family: unicode;">{{agent.created_date_Str}} </td>
                        <td style="font-family: unicode;">{{agent.created_by}} </td>
                        <td style="font-family: unicode;">{{agent.updated_date_Str}}</td>
                        <td style="font-family: unicode;">{{agent.updated_by}}</td>
                        <td style="font-family: unicode;" *ngIf="hardcode_edit_bool === true">
                          <a 
                            [routerLink]="['/transaction-tutorial-detail', agent.id]">
                            <i title="Edit" class="fas fa-edit" style="color:#4b88eb;cursor: pointer;"></i>
                          </a>
                          &nbsp;&nbsp;
                        <a *ngIf="hardcode_delete_bool == true">
                          <i class="fas fa-trash" title="Delete" (click)="delete(agent.id)"
                            style="color:#dc3545;cursor: pointer;"></i>
                        </a>

                        </td>

                      </tr>
                    </tbody>
                  </table>

                </div>

              </div>
            </div>

          </div>
        </form>

      </div>

    </section>

  </div>

  <aside class="control-sidebar control-sidebar-dark">

  </aside>

  <app-footer></app-footer>
  <ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#fff" type="square-jelly-box">
    <p style="font-size: 16px; color: white">Loading...</p>
  </ngx-spinner>
</div>

<div id="deleteData" class="modal fade">
  <div class="modal-dialog">
    <div class="modal-content">

      <div class="modal-body">
        <form>

          <div class="row" style="margin-top: 5%;margin-bottom: 3%;margin-left: 9%;">
            <div class="form-group col-md-12">
              <h5> This Account is used by another device.
                Please Sign Out. Login Again. </h5>
            </div>
          </div>

          <div class="row" style="margin-left: 40%;margin-bottom: 3%;">
            <div class="col-md-5 col-sm-6" id="deleteBtn">
              <button type="button" class="btn btn-block btn-success" (click)="OkLogout()"> Ok </button>
            </div>
          </div>
        </form>
      </div>

    </div>
  </div>
</div>

<div id="browseAccountData9" class="modal fade">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" style="font-weight: bold;font-size: 1rem;"> Edit Link </h5>
        <button type="button" class="close" data-dismiss="modal" aria-hidden="true"> x </button>
      </div>

      <div class="modal-body">
        <form>
          <div class="col-md-12">
            <div class="col-md-12">
              <div class="card-body">
                <label>Link </label>

                <input placeholder="Type Here.." class="form-control form-control-sm" [(ngModel)]="adsDTO.livelink"
                  [ngModelOptions]="{standalone: true}">


              </div>
            </div>
          </div>
        </form>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-primary btn-sm" (click)="Close()">Cancel</button>
        <button type="button" class="btn btn-primary btn-sm" (click)="addTran(adsDTO.id,adsDTO.livelink)">Save
          Changes</button>
      </div>
    </div>
  </div>
</div>

<div id="browseAccountData10" class="modal fade">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" style="font-weight: bold;font-size: 1rem;"> Add New Video </h5>
        <button type="button" class="close" data-dismiss="modal" aria-hidden="true"> x </button>
      </div>
      <div class="modal-body">
        <form>
          <div class="col-md-12">
            <div class="col-md-12">
              <div class="card-body">
                <label> Link </label>
                <input placeholder="Type Here.." class="form-control form-control-sm" [(ngModel)]="addlink"
                  [ngModelOptions]="{standalone: true}">

              </div>
            </div>
          </div>
        </form>
      </div>


      <div class="modal-footer">
        <button type="button" class="btn btn-primary btn-sm" (click)="Close()">Close</button>
        <button type="button" class="btn btn-primary btn-sm" (click)="SaveNewRole(addlink)">Save</button>

      </div>

    </div>
  </div>
</div>






<div id="deleteDataForTutorial" class="modal fade">
  <div class="modal-dialog">
      <div class="modal-content">

          <div class="modal-body">
              <form>

                  <div class="row" style="margin-top: 5%;margin-bottom: 3%;margin-left: 9%;">
                      <div class="form-group col-md-12" style="text-align: center;">
                          <h3 class="text-center"> Are you sure want to delete? </h3>
                      </div>
                  </div>

                  <div class="row" style="margin-left: 10%;margin-bottom: 3%;">
                      
                      <div class="col-md-5">
                          <button type="button" class="btn btn-block btn-danger" (click) = "closeTran()" [routerLink] = "['/transactiontutorial']"> Cancel </button>
                      </div>

                      <div class="col-md-5" id="deleteBtn">
                        <button type="button" class="btn btn-block btn-success" (click)="Yesdelete()"> Confirm </button>
                    </div>

                  </div>

              </form>
          </div>

      </div>
  </div>
</div>