<div class="wrapper">
    <!-- Navbar -->
    <app-header-sidebar></app-header-sidebar>

    <!-- here here here -->
    <!-- Content Wrapper. Contains page content -->
    <div class="content-wrapper">
        <!-- Content Header (Page header) -->
        <div class="content-header">
            <div class="container-fluid">
            </div><!-- /.container-fluid -->
        </div>
        <!-- /.content-header -->

        <!-- Main content -->
        <section class="content">
            <div class="container-fluid">
                <form>
                    <div class="row">
                        <div class="col-md-12">
                            <div class="card">
                                <div class="card-header">
                                    <h3 class="card-title" style="font-weight: bold;"> User Bet Limit Configuration</h3>
                                </div>
                                <!-- /.card-header -->
                                <div class="card-body">
                                    <form> <!--start of tabs -->
                                      <div class="row">
                                        <div class="col-md-12">
                                          <!-- Nav tabs -->
                                          <ul class="nav nav-tabs nav-pills">
                                            <li>
                                               <a class="active" id="all-tab" data-toggle="tab" href="#betLimitUserList">Bet Limit User List </a>
                                            </li>
                                            <li>
                                               <a class="added-tab" id="all-tab" data-toggle="tab" href="#allUserBetLimit">All User Bet Limit </a>
                                            </li>
                                            <li>
                                              <a id="added-tab" data-toggle="tab" href="#specificUserBetLimit">Specific User Bet Limit</a>
                                            </li>
                                          </ul>
                            
                                          <!-- Tab panes -->
                                          <div class="tab-content clearfix">
                                            
                                            <div class="tab-pane show active" id="betLimitUserList">
                                                <div class="card">
                                                    <div class="card-header">
                                                        <h3 class="card-title" style="font-weight: bold;"> Bet Limit User List</h3>
                                                    </div>
                                                    <div class="card-body">
                                                        <table width='100%' datatable [dtTrigger]="dtTrigger" [dtOptions]="dtOptions"
                                                        id="{{'tblManual' + idIndex}}" class="table table-bordered table-hover">
                                                            <thead>
                                                                <tr>
                                                                    <th style="font-family: unicode;">Name</th>
                                                                    <th style="font-family: unicode;">Phone</th> 
                                                                    <th style="font-family: unicode;">Wallet</th> 
                                                                    <th style="font-family: unicode;">Bet Limit 10:30 AM</th>
                                                                    <th style="font-family: unicode;">Bet Limit 12:01 PM</th>
                                                                    <th style="font-family: unicode;">Bet Limit 02:30 PM</th>
                                                                    <th style="font-family: unicode;">Bet Limit 04:30 PM</th>
                                                                    <th style="font-family: unicode;">Bet Limit Updated Date</th>
                                                                    <th style="font-family: unicode;">Action</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                <tr *ngFor="let item of manualOnOffList">
                                                                    <td style="font-family: unicode;">{{item.section}}</td>
                                                                    <td style="font-family: unicode;">{{item.fromDateTime.replace('T', ' ')}}</td> 
                                                                    <td style="font-family: unicode;">{{item.toDateTime.replace('T', ' ')}}</td> 
                                                                    <td style="font-family: unicode;">
                                                                        <input style = "cursor: pointer;" type="checkbox" [(ngModel)]="item.switchManual" 
                                                                        [ngModelOptions]="{standalone: true}" (change)="goOnOffSave()">
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="tab-pane" id="allUserBetLimit">                            
                                                <div class="card">
                                                    <div class="card-header">
                                                        <h3 class="card-title" style="font-weight: bold;"> All User Bet Limit</h3>
                                                    </div>
                                                    <div class="card-body">
                                                        <div class="row">
                                                            <label class="col-md-2">TwoD Bet Limit for 10:30 AM</label>
                                                            <div class="col-md-1"></div>
                                                            <div class="form-group col-md-3">
                                                            <input type = "number" class="form-control form-control-sm" [(ngModel)]="betlimit10_30"
                                                                [ngModelOptions]="{standalone: true}" id = "regbalance">
                                                            </div>
                                                        </div>
                                                        <div class="row">
                                                            <label class="col-md-2">TwoD Bet Limit for 12:01 PM</label>
                                                            <div class="col-md-1"></div>
                                                            <div class="form-group col-md-3">
                                                            <input type = "number" class="form-control form-control-sm" [(ngModel)]="betlimit12_01"
                                                                [ngModelOptions]="{standalone: true}" id = "regbalance">
                                                            </div>
                                                        </div>
                                                        <div class="row">
                                                            <label class="col-md-2">TwoD Bet Limit for 02:30 PM</label>
                                                            <div class="col-md-1"></div>
                                                            <div class="form-group col-md-3">
                                                            <input type = "number" class="form-control form-control-sm" [(ngModel)]="betlimit02_30"
                                                                [ngModelOptions]="{standalone: true}" id = "regbalance">
                                                            </div>
                                                        </div>
                                                        <div class="row">
                                                            <label class="col-md-2">TwoD Bet Limit for 04:30 PM</label>
                                                            <div class="col-md-1"></div>
                                                            <div class="form-group col-md-3">
                                                            <input type = "number" class="form-control form-control-sm" [(ngModel)]="betlimit04_30"
                                                                [ngModelOptions]="{standalone: true}" id = "regbalance">
                                                            </div>
                                                        </div>
                                                        <div class="row">
                                                            <label class="col-md-2">User List</label>
                                                            <div class="col-md-1"></div>
                                                            <div class="form-group col-md-3">
                                                                <select class="form-control" disabled>
                                                                    <option value="ALL">-- ALL --</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                        <div class="row" style="margin-top: 1%;">
                                                            <div class="col-md-4 col-sm-2"></div>
                                                            <div class="col-md-2 col-sm-6">
                                                                <button type="button" class="btn btn-block btn-success"
                                                                    (click)="goConfigSave()"> Save </button>&nbsp;
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                      
                                            <div class="tab-pane" id="specificUserBetLimit">
                                                <div class="row">
                                                    <label class="col-md-2"> Section </label>
                                                    <div class="form-group col-md-3">
                                                        <select class="form-control form-control-sm col-md-12 col-sm-4 col-xs-4"
                                                            [(ngModel)]="section" [ngModelOptions]="{standalone: true}">
                                                            <option value="Null">-- Please Select --</option>
                                                            <option value="10:30 AM">10:30 AM</option>
                                                            <option value="12:01 PM">12:01 PM</option>
                                                            <option value="02:30 PM">02:30 PM</option>
                                                            <option value="04:30 PM">04:30 PM</option>
                                                        </select>
                                                    </div> 
                                                </div>
                                                <div class="row">
                                                    <label class="col-md-2">Set</label>
                                                    <div class="form-group col-md-3">
                                                    <input type = "text" class="form-control form-control-sm" [(ngModel)]="twodSet"
                                                        [ngModelOptions]="{standalone: true}" id = "regbalance">
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <label class="col-md-2">Value</label>
                                                    <div class="form-group col-md-3">
                                                    <input type = "text" class="form-control form-control-sm" [(ngModel)]="twodValue"
                                                        [ngModelOptions]="{standalone: true}" id = "regbalance">
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <label class="col-md-2">Result</label>
                                                    <div class="form-group col-md-3">
                                                    <input type = "text" class="form-control form-control-sm" [(ngModel)]="twodResult"
                                                        [ngModelOptions]="{standalone: true}" id = "regbalance">
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <label class="col-md-2">Is Done?</label>
                                                    <input style = "cursor: pointer;" type="checkbox" [(ngModel)]="isDone" 
                                                    [ngModelOptions]="{standalone: true}">
                                                </div>
                                                <div class="row" style="margin-top: 1%;">
                                                    <div class="col-md-3 col-sm-6"></div>
                                                    <div class="col-md-2 col-sm-6">
                                                        <button type="button" class="btn btn-block btn-success"
                                                            (click)="goConfigSave()"> Save </button>&nbsp;
                                                    </div>
                                                </div>
                                            </div>
                            
                                          </div>
                                        </div>
                            
                                      </div>
                                    </form> <!--end of all tabs form-->
                                </div>
                                <!-- /.card-body -->
                            </div>
                        </div>

                    </div>
                </form>
                <!-- /.row -->

                <!-- /.row -->
            </div>
            <!--/. container-fluid -->
        </section>
        <!-- /.content -->
    </div>

    <aside class="control-sidebar control-sidebar-dark">
        <!-- Control sidebar content goes here -->
    </aside>

    <app-footer></app-footer>

    <ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#fff" type="square-jelly-box">
        <p style="font-size: 16px; color: white">Loading...</p>
    </ngx-spinner>

</div>


<div id="deleteData" class="modal fade">
    <div class="modal-dialog">
        <div class="modal-content">

            <div class="modal-body">
                <form>

                    <div class="row" style="margin-top: 5%;margin-bottom: 3%;margin-left: 9%;">
                        <div class="form-group col-md-12">
                            <h5> This Account is used by another device. 
                                Please Sign Out. Login Again. </h5>
                        </div>
                    </div>

                    <div class="row" style="margin-left: 40%;margin-bottom: 3%;">
                        <div class="col-md-5 col-sm-6" id="deleteBtn">
                            <button type="button" class="btn btn-block btn-success" (click)="OkLogout()"> Ok </button>
                        </div>
                    </div>
                </form>
            </div>

        </div>
    </div>
</div>