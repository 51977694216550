import { Component, OnInit } from '@angular/core';
@Component({
  selector: 'app-reports-history',
  templateUrl: './reports-history.component.html',
  styleUrls: ['./reports-history.component.css']
})
export class ReportsHistoryComponent implements OnInit {
 constructor() { 
  }

  ngOnInit(): void {
  }


}
