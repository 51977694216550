
<div class="wrapper">
    <!-- Navbar -->
    <app-header-sidebar></app-header-sidebar>

    <!-- here here here -->
    <!-- Content Wrapper. Contains page content -->
    <div class="content-wrapper">
        <!-- Content Header (Page header) -->
        <div class="content-header">
            <div class="container-fluid">
                <div class="row mb-2" style="border: 1px solid #ced4da;border-radius: 0.25rem;">
                    <div class="col-sm-8">
                        <h3 class="m-0 text-dark" style="font-weight: bold;padding: 3px 0px 3px 5px;">
                            <i class="nav-icon fas fa-star"></i> Point Promotion
                        </h3>
                    </div>
                </div><!-- /.row -->
            </div><!-- /.container-fluid -->
        </div>
        <!-- /.content-header -->

        <!-- Main content -->
        <section class="content">
            <div class="container-fluid">
                <form>
                    <div class="row">
                        <div class="col-md-12">
                            <div class="card">
                                <div class="card-header">
                                    <h3 class="card-title" style="font-weight: bold;"> <i class="nav-icon fas fa-star"></i> Point Promotion List </h3>
                                </div>
                                <div class="card-body">
                                    <table width='100%' id="tblpayment" datatable
                                        [dtTrigger]="dtTrigger" [dtOptions]="dtOptions"
                                        class="table table-bordered table-hover">
                                        <thead>
                                            <tr>
                                                <th style="font-family: unicode;">User Type</th>

                                                <th style="font-family: unicode;">Percentage (%)</th>

                                                <th style="font-family: unicode;">From Amount</th>

                                                <th style="font-family: unicode;">To Amount</th>

                                                <th style="font-family: unicode;">Updated Date</th>

                                                <th style="font-family: unicode;">Updated By</th>

                                                <th style="font-family: unicode;">Status</th>

                                                <th style="font-family: unicode;" *ngIf="hardcode_edit_bool === true">Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>

                                                <td style="font-family: unicode;">{{pointPromotionModel.userType}}</td>

                                                <td style="font-family: unicode;">{{pointPromotionModel.percentage}}</td>

                                                <td style="font-family: unicode;">{{pointPromotionModel.fromAmount | number : 0}}</td>

                                                <td style="font-family: unicode;">{{pointPromotionModel.toAmount | number : 0}}</td>

                                                <td style="font-family: unicode;">{{pointPromotionModel.updated_date}}</td>

                                                <td style="font-family: unicode;">{{pointPromotionModel.updated_by_name}}</td>

                                                <td style="font-family: unicode;">{{pointPromotionModel.status}}</td>
                                             
                                                <td style="font-family: unicode;" *ngIf="hardcode_edit_bool === true">
                                                    <!-- <a [routerLink]="['/point-promotion-detail', pointPromotionModel.id]">
                                                        <i class="fas fa-edit" style="color:#4b88eb;"></i>
                                                    </a>  -->
                                                    <a (click)="addStepAndNavigate(pointPromotionModel)">
                                                        <i class="fas fa-edit" style="color:#4b88eb;"></i>
                                                    </a> 
                                                </td>

                                            </tr>

                                        </tbody>
                                    </table>
                                </div>
                                <!-- /.card-body -->
                            </div>
                        </div>
                    </div>
                </form>
                <!-- /.row -->
                <!-- /.row -->
            </div>
            <!--/. container-fluid -->
        </section>
        <!-- /.content -->
    </div>

    <aside class="control-sidebar control-sidebar-dark">
       
    </aside>

    <app-footer></app-footer>

    <ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#fff" type="square-jelly-box">
        <p style="font-size: 16px; color: white">Loading...</p>
    </ngx-spinner>
</div>
<div id="deleteData" class="modal fade">
    <div class="modal-dialog">
        <div class="modal-content">

            <div class="modal-body">
                <form>

                    <div class="row" style="margin-top: 5%;margin-bottom: 3%;margin-left: 9%;">
                        <div class="form-group col-md-12">
                            <h5> This Account is used by another device. 
                                Please Sign Out. Login Again. </h5>
                        </div>
                    </div>

                    <div class="row" style="margin-left: 40%;margin-bottom: 3%;">
                        <div class="col-md-5 col-sm-6" id="deleteBtn">
                            <button type="button" class="btn btn-block btn-success" (click)="OkLogout()"> Ok </button>
                        </div>
                    </div>
                </form>
            </div>

        </div>
    </div>
</div>
