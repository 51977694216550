import { Component, OnInit, ViewChild } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,HttpErrorResponse } from '@angular/common/http';
import { DataTableDirective } from 'angular-datatables';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { DatePipe } from '@angular/common';

import { LocalStorageService } from 'ngx-webstorage';
import { NgxSpinnerService } from "ngx-spinner";

import { ToastrService } from 'ngx-toastr';


import { catchError, retry } from 'rxjs/operators';
import { throwError } from 'rxjs';

import { DtoService } from '../../service/dto.service';
import { FunctService } from '../../service/funct.service';

import { Subject } from 'rxjs';
import Responsive from 'datatables.net-responsive';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';


import {IMultiSelectOption  } from 'ngx-bootstrap-multiselect';
import {IMultiSelectSettings } from 'ngx-bootstrap-multiselect';
import {IMultiSelectTexts} from 'ngx-bootstrap-multiselect';


declare var $: any;

@Component({
  selector: 'app-payment_info',
  templateUrl: './payment_info.component.html',
  styleUrls: ['./payment_info.component.css']
})
export class PaymentInfoComponent implements OnInit {

  @ViewChild(DataTableDirective)
  dtElement: DataTableDirective;
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();

  dtElement1: DataTableDirective;
  dtOptions1: DataTables.Settings = {};
  dtTrigger1: Subject<any> = new Subject();

  dtElement2: DataTableDirective;
  dtOptions2: DataTables.Settings = {};
  dtTrigger2: Subject<any> = new Subject();

  dtElement3: DataTableDirective;
  dtOptions3: DataTables.Settings = {};
  dtTrigger3: Subject<any> = new Subject();

  dtElement4: DataTableDirective;
  dtOptions4: DataTables.Settings = {};
  dtTrigger4: Subject<any> = new Subject();

  dtElement5: DataTableDirective;
  dtOptions5: DataTables.Settings = {};
  dtTrigger5: Subject<any> = new Subject();

  dtElement6: DataTableDirective;
  dtOptions6: DataTables.Settings = {};
  dtTrigger6: Subject<any> = new Subject();

  idpendingIndex: any;
  idcheckedIndex: any;
  idapproveIndex: any;
  iddeniedIndex: any;
  
  accountNo: string = '';
  accountNo_pending : string = '';
  accountNo_cbpay : string = '';
  accountNo_ayapay : string = '';
  accountNo_wavepay : string = '';
  accountNo_waveshop : string = '';
  type: string = '';
  type_pending : string = '';
  type_cbpay : string = '';
  type_ayapay : string = '';
  type_wavepay : string = '';
  type_waveshop : string = '';
  status: string = '';
  status_pending: string = '';
  status_cbpay: string = '';
  status_ayapay: string = '';
  status_wavepay: string = '';
  status_waveshop : string = '';
  BankType: string = null;
  BankType_pending: string = null;
  BankType_cbpay: string = null;
  BankType_ayapay: string = null;
  BankType_wavepay: string = null;
  BankType_waveshop: string = null;
  paymentList: any;
  bankTypeList: any;
  idIndex: any;
  idIndex2 : any;
  idIndex4 : any;
  idIndex3:any;
  idIndex5:any;
  idIndex6:any;
  token: any;
  bankName : any;
  account_no : any;
  code_number : any;
  account_name : any;
  paymentBankAccountHolderDetaiList : any;
  hardcode_edit :any;
  hardcode_add :any;
  hardcode_view: any;
  hardcode_add_Account_Number:any;
  hardcode_add_Account_Name:any;
  hardcode_add_Code_Number:any;
  hardcode_view_bool : any;
  hardcode_add_bool : any;
  hardcode_edit_bool : any;
  paymentList1:any;
  paymentList2:any;
  paymentList3:any;
  paymentList4:any;
  paymentList5:any;
  paymentList6:any;
  idIndex1:any;
  config:any;
  persentage :any;
  persentage_data:any;
  selectedAccountHolder: number;
 
  paymentIds: any;

  optionsModel: number[];myOptions:any;
  adminList:any;
  mySettings: IMultiSelectSettings;
  myTexts: IMultiSelectTexts;

  constructor( private toastr: ToastrService, private storage: LocalStorageService, private spinner: NgxSpinnerService, private dto: DtoService, private http: HttpClient, private router: Router,
    private route: ActivatedRoute, private funct: FunctService, private datepipe: DatePipe) 
    {

    this.storage.store('isNotiSong', "");
    this.idIndex = 1;
    this.idIndex1 = 1;
    this.idIndex2 = 1;
    this.idIndex3 = 1;
    this.idIndex4 = 1;
    this.idIndex5 = 1;
    this.idIndex6 = 1;
    this.config = "0.00";
    this.persentage =" %";
    this.hardcode_view = "BankAccount_View";
    this.hardcode_edit = "BankAccount_Edit";
    this.hardcode_add = "BankAccount_Add";  
    this.dto.menuCodes = this.storage.retrieve('menucodes');

    const filteredMenuCodes_view = [];
    const filteredMenuCodeds_add =[];
    const filteredMenuCodeds_edit =[];
    this.dto.menuCodes.forEach(menuCode => 
      {
      if (menuCode === this.hardcode_view) {
        filteredMenuCodes_view.push(menuCode);
      }
      if (menuCode === this.hardcode_add) {
        filteredMenuCodeds_add.push(menuCode);
      }
      if (menuCode === this.hardcode_edit) {
        filteredMenuCodeds_edit.push(menuCode);
      }
    });
    if(filteredMenuCodes_view.length != 0)
    {
         this.hardcode_view_bool = true;
    }
    if(filteredMenuCodeds_add.length != 0)
    {
         this.hardcode_add_bool = true;
    }
    if(filteredMenuCodeds_edit.length != 0)
    {
         this.hardcode_edit_bool = true;
    }
    this.route.queryParams.subscribe(queryParams => 
    {
      this.type = queryParams['type'];
      
    });
    this.route.queryParams.subscribe(queryParams => 
      {
        this.type_pending = queryParams['type_pending'];
        
      });
      this.route.queryParams.subscribe(queryParams => 
        {
          this.type_cbpay = queryParams['type_cbpay'];
          
        });
        this.route.queryParams.subscribe(queryParams => 
          {
            this.type_ayapay = queryParams['type_ayapay'];
            
          });
          this.route.queryParams.subscribe(queryParams => 
            {
              this.type_wavepay = queryParams['type_wavepay'];
              
            });
            this.route.queryParams.subscribe(queryParams => 
              {
                this.type_waveshop = queryParams['type_waveshop'];
                
              });
    if(this.type == "" || this.type == undefined)
    {
       this.type ="TOPUP";
    }
    if(this.type_pending == "" || this.type_pending == null)
    {
       this.type_pending="TOPUP";
    }
    if(this.type_cbpay == "" || this.type_cbpay == null)
    {
       this.type_cbpay="TOPUP";
    }

      if(this.type == 'TOPUP' || this.type_pending =='TOPUP' || this.type_cbpay =='TOPUP' || this.type_ayapay =='TOPUP' || this.type_ayapay == 'TOPUP' || this.type_wavepay =='TOPUP' || this.type_waveshop =='TOPUP')
      {
        this.getBankTypeList();      
      }
      else
      {
        this.getAllBankTypeWithdraw();
      }  

      this.getAllAdminsWithWithdrawal();
  }

  ngOnInit(): void 
  {

    this.mySettings = {
      enableSearch: true,
      checkedStyle: 'checkboxes',
      buttonClasses: 'btn btn-default btn-block',
      dynamicTitleMaxItems: 3,
      displayAllSelectedText: true,
      showCheckAll : true,
      showUncheckAll : true,
      autoUnselect : true,
      pullRight : false,
      closeOnSelect : false
    };


    this.myTexts = {
      checkAll: 'Select all',
      uncheckAll: 'Unselect all',
      checked: 'item selected',
      checkedPlural: 'items selected',
      searchPlaceholder: 'Find',
      searchEmptyResult: 'Nothing found...',
      searchNoRenderText: 'Type in search box to see results...',
      defaultTitle: 'Select',
      allSelected: 'All selected',
    };

    this.router.routeReuseStrategy.shouldReuseRoute = function () 
    {
      return false;
    };

    this.router.events.subscribe((evt) => {
      if (evt instanceof NavigationEnd) {
     
        this.router.navigated = false;

        window.scrollTo(0, 0);
      }
    });

    this.dtOptions = {
      responsive: {
        details: {
            renderer: Responsive.renderer.listHiddenNodes()
        }
    },
      order: [],
      paging:true
    }   

    this.dtOptions.columnDefs = [
      { targets: [9], orderable: false }
    ];

    this.dtOptions1 = {
      responsive: {
        details: {
            renderer: Responsive.renderer.listHiddenNodes()
        }
    },
      order: [],
      paging:true
    }

    this.dtOptions1.columnDefs = [
      { targets: [9], orderable: false }
    ];


    this.dtOptions2 = {
      responsive: {
        details: {
            renderer: Responsive.renderer.listHiddenNodes()
        }
    },
      order: [],
      paging:false
    }

    this.dtOptions2.columnDefs = [
      { targets: [9], orderable: false }
    ];

    this.dtOptions3 = {
      responsive: {
        details: {
            renderer: Responsive.renderer.listHiddenNodes()
        }
    },
      order: [],
      paging:false
    }

    this.dtOptions3.columnDefs = [
      { targets: [9], orderable: false }
    ];

    this.dtOptions4 = {
      responsive: {
        details: {
            renderer: Responsive.renderer.listHiddenNodes()
        }
    },
      order: [],
      paging:false
    }

    this.dtOptions4.columnDefs = [
      { targets: [9], orderable: false }
    ];

    this.dtOptions5 = {
      responsive: {
        details: {
            renderer: Responsive.renderer.listHiddenNodes()
        }
    },
      order: [],
      paging:false
    }

    this.dtOptions6.columnDefs = [
      { targets: [9], orderable: false }
    ];

    this.dtOptions6 = {
      responsive: {
        details: {
            renderer: Responsive.renderer.listHiddenNodes()
        }
    },
      order: [],
      paging:false
    }

    this.dtOptions6.columnDefs = [
      { targets: [9], orderable: false }
    ];

    if (!this.storage.retrieve('loadFlag')) 
    {
      this.storage.store('loadFlag', 'noLoad');
      setTimeout(function () {
        location.reload();
      }, 5);
    }
    else {
      this.storage.clear('loadFlag');
    }

    if (!this.storage.retrieve('WithdrawalTag')) 
    {     
      this.storage.store('WithdrawalTag', "ALL");      
      this.search('tabsearch');
      $('#all-tab').tab('show');
    }
    else
     {
      if(this.storage.retrieve('WithdrawalTag') == "ALL")
      {  
        this.search('tabsearch');
        $('#all-tab').tab('show');
      }
      else if(this.storage.retrieve('WithdrawalTag') == "PENDING")
      {     
        this.type ="";
        this.type_ayapay ="";
        this.type_cbpay ="";
        this.type_wavepay ="";
        this.search_pending('tabsearch');
        $('#pending-tab').tab('show');
      }
      else if(this.storage.retrieve('WithdrawalTag') == "CBPAY")
      {      
        this.type ="";
        this.type_ayapay ="";
        this.type_wavepay ="";
        this.type_pending ="";  
        this.search_cbpay('tabsearch');
        $('#checked-tab').tab('show');
      }
      else if(this.storage.retrieve('WithdrawalTag') == "AYAPAY")
      {       
        this.type ="";
        this.type_cbpay ="";
        this.type_wavepay ="";
        this.type_pending ="";   
        this.search_ayapay('tabsearch');
        $('#approve-tab').tab('show');
      }
      else if(this.storage.retrieve('WithdrawalTag') == "WAVEPAY")
      {       
        this.type ="";
        this.type_ayapay ="";
        this.type_cbpay ="";
        this.type_pending =""; 
        this.search_wavepay('tabsearch');
        $('#denied-tab').tab('show');
      }
      else 
      {
        this.search('tabsearch');
        $('#all-tab').tab('show');
      }
    }  
  }

  ngAfterViewInit()
  {
    console.log('ContactsComponent - ngAfterViewInit()');
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => 
    {
        dtInstance.columns().every(function () {
            const that = this;
            $('input', this.footer()).on('keyup change', function () 
            {
                console.log('search(): ' + that.search());
                console.log('value: ' + this['value']);
                if (that.search() !== this['value']) {
                    that.search(this['value'])
                        .draw();
                }
            });
        });
      });
  }


  handleError(error: HttpErrorResponse)
  {
    if(error.status == 423)
    {
      this.spinner.hide();
      $("#deleteData").modal("show");
    }
    if(error.status == 403)
    {
      this.spinner.hide();
      var id1 = 'tblWithdraw' + this.idIndex;
      var table1 = $('#' + id1).DataTable();

      var id2 = 'tblpendingWithdraw' + this.idpendingIndex;
      var table2 = $('#' + id2).DataTable();

      var id3 = 'tblcheckedWithdraw' + this.idcheckedIndex;
      var table3 = $('#' + id3).DataTable();
    
      var id4 = 'tblapproveWithdraw' + this.idapproveIndex;
      var table4 = $('#' + id4).DataTable();

      var id5 = 'tbldeniedWithdraw' + this.iddeniedIndex;
      var table5 = $('#' + id5).DataTable();

      this.toastr.error("Limited Access.", 'Invalid!', {
        timeOut: 3000,
        positionClass: 'toast-top-right',
        });
    }
    return throwError(error);
    }
    OkLogout()
    {
      window.location.href ="./ad-login";
    } 

    

     
  search(searchKey) 
  {   
    // this.dtTrigger = new Subject<any>();
    // this.storage.store('WithdrawalTag', "ALL");
    // // if (this.dtTrigger.isStopped) 
    // // {
    // //   this.dtTrigger = new Subject<any>();
    // // }
    // this.paymentList = [];
    // // var id = 'tblpayment';
    // // this.idIndex = this.idIndex + 1;
    // // $('#tblpayment').dataTable().fnClearTable();
    // // $('#tblpayment').dataTable().fnDestroy();
    // // this.spinner.show();

     // this.dtTrigger = new Subject<any>();
    // var id = 'tblpayment' + this.idIndex;
    // var table = $('#' + id).DataTable();
    // this.spinner.show();
    // table.destroy();

    this.storage.store('WithdrawalTag', "ALL");
    if (this.dtTrigger.isStopped) 
    {
      this.dtTrigger = new Subject<any>();
    }
    this.paymentList = [];
    var id = 'tblpayment';
    this.idIndex = this.idIndex + 1;
    $('#tblpayment').dataTable().fnClearTable();
    $('#tblpayment').dataTable().fnDestroy();
    this.spinner.show();  

    this.dto.token = this.storage.retrieve('token');
    let headers = new HttpHeaders();
    headers = headers.set('Authorization',  this.dto.token);
    let params = new HttpParams();
    this.type_pending ="";
    this.type_ayapay ="";
    this.type_cbpay ="";
    this.type_wavepay ="";
    if(this.type == "")
    {
        this.type ="TOPUP";
    }
   
    params = params.set('accountNo', this.accountNo).set('status', this.status).set('bankType', this.BankType).set('Type',this.type);
    this.http.get(this.funct.ipaddress + 'payment/GetPaymentDetailListPyParams_new', { params: params, headers: headers })
    .pipe(
      catchError(this.handleError.bind(this))
     )
    .subscribe(
      result => {
        this.dto.Response = {};
        this.dto.Response = result;
        this.paymentList = this.dto.Response;
       
        let paymentIds: number[] = [];         
        this.paymentList.forEach(item => {
          if (!paymentIds.includes(item.payment_id)) 
          {
            paymentIds.push(item.payment_id);  
          }
        });
        let paymentIdString = paymentIds.join(',');
        this.paymentIds = paymentIdString;      
          
        console.log(paymentIdString);          

        this.spinner.hide();
        this.dtTrigger.next(); 
        // if (this.paymentList && this.paymentList.length) 
        // {
        //   this.dtTrigger.next(); 
        // }
      });
  }


  
  
  search_pending(searchKey) 
  {
    this.type ="";
    this.storage.store('WithdrawalTag', "PENDING");
    if (this.dtTrigger1.isStopped) 
    {
      this.dtTrigger1 = new Subject<any>();
    }
    this.paymentList1 = [];
    var id = 'tblpayment1';
    this.idIndex1 = this.idIndex1 + 1;
    $('#tblpayment1').dataTable().fnClearTable();
    $('#tblpayment1').dataTable().fnDestroy();
    this.spinner.show();
    this.dto.token = this.storage.retrieve('token');
    let headers = new HttpHeaders();
    headers = headers.set('Authorization',  this.dto.token);
    let params = new HttpParams();
   
    if(searchKey == "btnsearch")
    {
       if(this.type_pending == undefined || this.type_pending == null)
       {
          this.type_pending ="TOPUP";
       } 
    }
    else
    {
      if(this.type_pending == undefined || this.type_pending == null || this.type_pending == "")
       {
          this.type_pending ="TOPUP";
       }
       
    }
    if(this.type_pending == 'TOPUP')
    {
        this.BankType_pending = "1";
    }
    else
    {
      this.BankType_pending = "4";
    }

    params = params.set('accountNo', this.accountNo_pending).set('status', this.status_pending).set('bankType', this.BankType_pending).set('Type',this.type_pending);
    this.http.get(this.funct.ipaddress + 'payment/GetPaymentDetailListPyParams_new', { params: params, headers: headers })
    .pipe(
      catchError(this.handleError.bind(this))
     )
    .subscribe(
      result => {
        this.dto.Response = {};
        this.dto.Response = result;
        this.paymentList1 = this.dto.Response;  
        let paymentIds: number[] = [];         
        this.paymentList1.forEach(item => {
          if (!paymentIds.includes(item.payment_id)) {
            paymentIds.push(item.payment_id);  
          }
        });
        let paymentIdString = paymentIds.join(',');
        this.paymentIds = paymentIdString;       
          
        console.log(paymentIdString);            


        this.spinner.hide();
        this.dtTrigger1.next();         
      });
  } 

  
  // search_cbpay(searchKey) 
  // {
  //   this.storage.store('WithdrawalTag', "CBPAY");  
  //   this.paymentList3 = [];

  //   this.dtTrigger = new Subject<any>();
  //   var id = 'tblpayment' + this.idIndex;
  //   var table = $('#' + id).DataTable();
  //   table.destroy();

  //   this.dto.token = this.storage.retrieve('token');
  //   let headers = new HttpHeaders();
  //   headers = headers.set('Authorization',  this.dto.token);
  //   let params = new HttpParams();
  //   if(searchKey == "btnsearch")
  //   {
  //      if(this.type_cbpay == undefined || this.type_cbpay == null)
  //      {
  //         this.type_cbpay ="TOPUP";
  //      } 
  //   }
  //   else
  //   {
  //     if(this.type_cbpay == undefined || this.type_cbpay == null)
  //      {
  //         this.type_cbpay ="TOPUP";
  //      }
       
  //   }
  //   if(this.type_cbpay == 'TOPUP')
  //   {
  //       this.BankType_cbpay = "3";
  //   }
  //   else{
  //     this.BankType_cbpay = "6";
  //   }

  //   params = params.set('accountNo', this.accountNo_cbpay).set('status', this.status_cbpay).set('bankType', this.BankType_cbpay).set('Type',this.type_cbpay);
  //   this.http.get(this.funct.ipaddress + 'payment/GetPaymentDetailListPyParams_new', { params: params, headers: headers })
  //   .pipe(
  //     catchError(this.handleError.bind(this))
  //    )
  //   .subscribe(
  //     result => {
  //       this.dto.Response = {};
  //       this.dto.Response = result;
  //       this.paymentList3 = this.dto.Response;  
        
  //       let paymentIds: number[] = [];         
  //       this.paymentList3.forEach(item => {
  //         if (!paymentIds.includes(item.payment_id)) {
  //           paymentIds.push(item.payment_id);  
  //         }
  //       });
  //       let paymentIdString = paymentIds.join(',');
  //       this.paymentIds = paymentIdString;       
          
  //       console.log(paymentIdString);   
  //       this.spinner.hide();
  //       this.dtTrigger.next();         
  //     });
  // } 


  
  search_cbpay(searchKey) 
  {
    this.type ="";   
    this.storage.store('WithdrawalTag', "CBPAY");   
    if (this.dtTrigger.isStopped) 
    {
      this.dtTrigger = new Subject<any>();
    }
    this.paymentList3 = [];
    var id = 'tblpayment';
    this.idIndex = this.idIndex + 1;
    $('#tblpayment').dataTable().fnClearTable();
    $('#tblpayment').dataTable().fnDestroy();
    this.spinner.show();
    this.dto.token = this.storage.retrieve('token');
    let headers = new HttpHeaders();
    headers = headers.set('Authorization',  this.dto.token);
    let params = new HttpParams();
   
    if(searchKey == "btnsearch")
    {
       if(this.type_cbpay == undefined || this.type_cbpay == null)
       {
          this.type_cbpay ="TOPUP";
       } 
    }
    else
    {
      if(this.type_cbpay == undefined || this.type_cbpay == null || this.type_cbpay == "")
       {
          this.type_cbpay ="TOPUP";
       }
       
    }
    if(this.type_cbpay == 'TOPUP')
    {
        this.BankType_cbpay = "3";
    }
    else
    {
      this.BankType_cbpay = "6";
    }

    params = params.set('accountNo', this.accountNo_cbpay).set('status', this.status_cbpay).set('bankType', this.BankType_cbpay).set('Type',this.type_cbpay);
    this.http.get(this.funct.ipaddress + 'payment/GetPaymentDetailListPyParams_new', { params: params, headers: headers })
    .pipe(
      catchError(this.handleError.bind(this))
     )
    .subscribe(
      result => {
        this.dto.Response = {};
        this.dto.Response = result;
        this.paymentList3 = this.dto.Response;  
        let paymentIds: number[] = [];         
        this.paymentList3.forEach(item => {
          if (!paymentIds.includes(item.payment_id)) {
            paymentIds.push(item.payment_id);  
          }
        });
        let paymentIdString = paymentIds.join(',');
        this.paymentIds = paymentIdString;       
        console.log(paymentIdString);         
        this.spinner.hide();
        this.dtTrigger.next();         
      });
  } 
  

  
  // search_ayapay(searchKey) 
  // {
  //   this.storage.store('WithdrawalTag', "AYAPAY");
  //   this.paymentList4 = [];

  //   this.dtTrigger = new Subject<any>();
  //   var id = 'tblpayment' + this.idIndex;
  //   var table = $('#' + id).DataTable();
  //   table.destroy();


  //   this.dto.token = this.storage.retrieve('token');
  //   let headers = new HttpHeaders();
  //   headers = headers.set('Authorization',  this.dto.token);
  //   let params = new HttpParams();
  //   if(searchKey == "btnsearch")
  //   {
  //      if(this.type_ayapay == undefined || this.type_ayapay == null)
  //      {
  //         this.type_ayapay ="TOPUP";
  //      } 
  //   }
  //   else
  //   {
  //     if(this.type_ayapay == undefined || this.type_ayapay == null || this.type_ayapay =="")
  //      {
  //         this.type_ayapay ="TOPUP";
  //      }
       
  //   }
  //   if(this.type_ayapay == 'TOPUP')
  //   {
  //       this.BankType_ayapay = "8";//11  , testing =8
  //   }
  //   else{
  //     this.BankType_ayapay = "11";//12  , testing = 11
  //   }

  //   params = params.set('accountNo', this.accountNo_ayapay).set('status', this.status_ayapay).set('bankType', this.BankType_ayapay).set('Type',this.type_ayapay);
  //   this.http.get(this.funct.ipaddress + 'payment/GetPaymentDetailListPyParams_new', { params: params, headers: headers })
  //   .pipe(
  //     catchError(this.handleError.bind(this))
  //    )
  //   .subscribe(
  //     result => {
  //       this.dto.Response = {};
  //       this.dto.Response = result;
  //       this.paymentList4 = this.dto.Response; 

  //       let paymentIds: number[] = [];         
  //       this.paymentList4.forEach(item => {
  //         if (!paymentIds.includes(item.payment_id)) {
  //           paymentIds.push(item.payment_id);  
  //         }
  //       });
  //       let paymentIdString = paymentIds.join(',');
  //       this.paymentIds = paymentIdString;       
          
  //       console.log(paymentIdString);        
  //       this.spinner.hide();
  //       this.dtTrigger.next();         
  //     });
  // } 

  
  search_ayapay(searchKey) 
  {
    this.type ="";
    this.storage.store('WithdrawalTag', "AYAPAY");
    if (this.dtTrigger.isStopped) 
    {
      this.dtTrigger = new Subject<any>();
    }
    this.paymentList4 = [];
    var id = 'tblpayment';
    this.idIndex = this.idIndex + 1;
    $('#tblpayment').dataTable().fnClearTable();
    $('#tblpayment').dataTable().fnDestroy();
    this.spinner.show();
    this.dto.token = this.storage.retrieve('token');
    let headers = new HttpHeaders();
    headers = headers.set('Authorization',  this.dto.token);
    let params = new HttpParams();
   
    if(searchKey == "btnsearch")
    {
       if(this.type_ayapay == undefined || this.type_ayapay == null)
       {
          this.type_ayapay ="TOPUP";
       } 
    }
    else
    {
      if(this.type_ayapay == undefined || this.type_ayapay == null || this.type_ayapay == "")
       {
          this.type_ayapay ="TOPUP";
       }
       
    }
    if(this.type_ayapay == 'TOPUP')
    {
        this.BankType_ayapay = "11";//prod =11 , testing=8
    }
    else
    {
      this.BankType_ayapay = "12";//prod =12 , testing =11
    }

    params = params.set('accountNo', this.accountNo_ayapay).set('status', this.status_ayapay).set('bankType', this.BankType_ayapay).set('Type',this.type_ayapay);
      this.http.get(this.funct.ipaddress + 'payment/GetPaymentDetailListPyParams_new', { params: params, headers: headers })
    .pipe(
      catchError(this.handleError.bind(this))
     )
    .subscribe(
      result => {
        this.dto.Response = {};
        this.dto.Response = result;
        this.paymentList4 = this.dto.Response;  
        let paymentIds: number[] = [];         
        this.paymentList4.forEach(item => {
          if (!paymentIds.includes(item.payment_id)) {
            paymentIds.push(item.payment_id);  
          }
        });
        let paymentIdString = paymentIds.join(',');
        this.paymentIds = paymentIdString;            
        this.spinner.hide();
        this.dtTrigger.next();         
      });
  } 
  


  
  // search_wavepay(searchKey) 
  // {
  //   this.storage.store('WithdrawalTag', "WAVEPAY");   
  //   this.paymentList5 = [];
  //   //‌amk
  //   this.dtTrigger = new Subject<any>();
  //   var id = 'tblpayment' + this.idIndex;
  //   var table = $('#' + id).DataTable();
  //   table.destroy();
  //   //
  //   this.spinner.show();
  //   this.dto.token = this.storage.retrieve('token');
  //   let headers = new HttpHeaders();
  //   headers = headers.set('Authorization',  this.dto.token);
  //   let params = new HttpParams();
  //   if(searchKey == "btnsearch")
  //   {
  //      if(this.type_wavepay == undefined || this.type_wavepay == null)
  //      {
  //         this.type_wavepay ="TOPUP";
  //      } 
  //   }
  //   else
  //   {
  //     if(this.type_wavepay == undefined || this.type_wavepay == null || this.type_wavepay =="")
  //      {
  //         this.type_wavepay ="TOPUP";
  //      }
       
  //   }
  //   if(this.type_wavepay == 'TOPUP')
  //   {
  //       this.BankType_wavepay = "2";
  //   }
  //   else{
  //     this.BankType_wavepay = "5";
  //   }

  //   params = params.set('accountNo', this.accountNo_wavepay).set('status', this.status_wavepay).set('bankType', this.BankType_wavepay).set('Type',this.type_wavepay);
  //   this.http.get(this.funct.ipaddress + 'payment/GetPaymentDetailListPyParams_new', { params: params, headers: headers })
  //   .pipe(
  //     catchError(this.handleError.bind(this))
  //    )
  //   .subscribe(
  //     result => {
  //       this.dto.Response = {};
  //       this.dto.Response = result;
  //       this.paymentList5 = this.dto.Response;  
        
  //       let paymentIds: number[] = [];         
  //       this.paymentList5.forEach(item => {
  //         if (!paymentIds.includes(item.payment_id)) {
  //           paymentIds.push(item.payment_id);  
  //         }
  //       });
  //       let paymentIdString = paymentIds.join(',');
  //       this.paymentIds = paymentIdString;        
  //       console.log(paymentIdString); 
  //       this.spinner.hide();       
  //       this.dtTrigger.next();        
  //     });
  // } 



  
  search_wavepay(searchKey) 
  {
    this.type ="";
    this.storage.store('WithdrawalTag', "WAVEPAY");   
    if (this.dtTrigger.isStopped) 
    {
      this.dtTrigger = new Subject<any>();
    }
    this.paymentList5 = [];
    var id = 'tblpayment';
    this.idIndex = this.idIndex + 1;
    $('#tblpayment').dataTable().fnClearTable();
    $('#tblpayment').dataTable().fnDestroy();
    this.spinner.show();
    this.dto.token = this.storage.retrieve('token');
    let headers = new HttpHeaders();
    headers = headers.set('Authorization',  this.dto.token);
    let params = new HttpParams();
   
    if(searchKey == "btnsearch")
    {
       if(this.type_wavepay == undefined || this.type_wavepay == null)
       {
          this.type_wavepay ="TOPUP";
       } 
    }
    else
    {
      if(this.type_wavepay == undefined || this.type_wavepay == null || this.type_wavepay == "")
       {
          this.type_wavepay ="TOPUP";
       }
       
    }
    if(this.type_wavepay == 'TOPUP')
    {
        this.BankType_wavepay = "2";
    }
    else
    {
      this.BankType_wavepay = "5";
    }

    params = params.set('accountNo', this.accountNo_wavepay).set('status', this.status_wavepay).set('bankType', this.BankType_wavepay).set('Type',this.type_wavepay);
      this.http.get(this.funct.ipaddress + 'payment/GetPaymentDetailListPyParams_new', { params: params, headers: headers })
    .pipe(
      catchError(this.handleError.bind(this))
     )
    .subscribe(
      result => {
        this.dto.Response = {};
        this.dto.Response = result;
        this.paymentList5 = this.dto.Response;  
        let paymentIds: number[] = [];         
        this.paymentList5.forEach(item => {
          if (!paymentIds.includes(item.payment_id)) {
            paymentIds.push(item.payment_id);  
          }
        });
        let paymentIdString = paymentIds.join(',');
        this.paymentIds = paymentIdString;       
        console.log(paymentIdString);         
        this.spinner.hide();
        this.dtTrigger.next();         
      });
  } 
  

  

  ngOnDestroy(): void 
  {
    this.dtTrigger.unsubscribe();
    this.dtTrigger1.unsubscribe();
    this.dtTrigger2.unsubscribe();
    this.dtTrigger3.unsubscribe();
  }


  getBankTypeList() {
    this.bankTypeList = [];
    this.spinner.show();
    this.dto.token = this.storage.retrieve('token');
    let headers = new HttpHeaders();
    headers = headers.set('Authorization',  this.dto.token);
    this.http.get(this.funct.ipaddress + 'payment/GetBankTypeList', { headers: headers })
    .pipe(
      catchError(this.handleError.bind(this))
     )
    .subscribe(
      result => {
        this.dto.Response = {};
        this.dto.Response = result;
        this.bankTypeList = this.dto.Response;
        this.spinner.hide();
      });
  }

  addStepAndNavigate(payment: any): void
  { 
   localStorage.setItem('paymentData', JSON.stringify({
    id:payment.id,
    status:payment.status,
    min_amount:payment.min_amount,
    paymentId:payment.paymentId,
    account_no:payment.account_no,
    account_name:payment.account_name,
    code_number:payment.code_number,
    max_amount:payment.max_amount,
    limited_amt:payment.limited_amt,
    maxAccountCount:payment.maxAccountCount,
    type :payment.type

   }));
  this.router.navigate(['/payment-info-detail', payment.id,payment.type]);
 }

 onChangeType()
 {    
        if(this.type == 'TOPUP')
        {
          this.getBankTypeList();      
        }
        else
        {
          this.getAllBankTypeWithdraw();
          this.type ="WITHDRAWAL";
        }
        this.router.navigate(['/payment-info'] , { queryParams: { type: this.type } }).then(() => 
      {

      });
      this.search("btnsearch");    
 }

 
 onChangeType_pending()
 {    
        if(this.type_pending == 'TOPUP')
        {
          this.getBankTypeList();      
        }
        else
        {
          this.getAllBankTypeWithdraw();
          this.type_pending ="WITHDRAWAL";
        }
        this.router.navigate(['/payment-info'] , { queryParams: { type_pending: this.type_pending } }).then(() => 
      {

      });
      this.search_pending('btnsearch');    
 }


 
 
 onChangeType_cbpay()
 {    
        if(this.type_cbpay == 'TOPUP')
        {
          this.getBankTypeList();      
        }
        else
        {
          this.getAllBankTypeWithdraw();
          this.type_cbpay ="WITHDRAWAL";
        }
        this.router.navigate(['/payment-info'] , { queryParams: { type_cbpay: this.type_cbpay } }).then(() => 
      {

      });
      this.search_cbpay('btnsearch');    
 }
 
//  onChangeType_cbpay()
//  {    
//         if(this.type_cbpay == 'TOPUP')
//         {
//           this.getBankTypeList();      
//         }
//         else
//         {
//           this.getAllBankTypeWithdraw();
//           this.type_cbpay ="WITHDRAWAL";
//         }
//         this.router.navigate(['/payment-info'] , { queryParams: { type_cbpay: this.type_cbpay } }).then(() => 
//       {

//       });
//       this.search_cbpay('btnsearch');    
//  }

 
 onChangeType_ayapay()
 {    
        if(this.type_ayapay == 'TOPUP')
        {
          this.getBankTypeList();      
        }
        else
        {
          this.getAllBankTypeWithdraw();
          this.type_ayapay ="WITHDRAWAL";
        }
        this.router.navigate(['/payment-info'] , { queryParams: { type_ayapay: this.type_ayapay } }).then(() => 
      {

      });
      this.search_ayapay('btnsearch');    
 }

 
 onChangeType_wavepay()
 {    
        if(this.type_wavepay == 'TOPUP')
        {
          this.getBankTypeList();      
        }
        else
        {
          this.getAllBankTypeWithdraw();
          this.type_wavepay ="WITHDRAWAL";
        }
        this.router.navigate(['/payment-info'] , { queryParams: { type_wavepay: this.type_wavepay } }).then(() => 
      {

      });
      this.search_wavepay('btnsearch');    
 }

 


 getAllBankTypeWithdraw()
  {
    this.bankTypeList = [];
    this.dto.token = this.storage.retrieve('token');
    let headers = new HttpHeaders();
    headers = headers.set('Authorization',  this.dto.token );
    this.http.get(this.funct.ipaddress + 'payment/GetWithdrawBankTypeList', { headers: headers }).subscribe(
      result => {
        this.dto.Response = {};
        this.dto.Response = result;
        this.bankTypeList = this.dto.Response;
        this.spinner.hide();
      }
    );
  }

  readNotiData()
  {    
    $("#readData").modal("show");
  }
  closeTran()
  {
      $('#readData').modal("hide");
      this.router.navigate(['/payment-info']).then(() => 
      {      
    });
  }
  
readNoti()
{
    this.dto.token = this.storage.retrieve('token');
    let headers = new HttpHeaders();
    headers = headers.set('Authorization', this.dto.token);
    const formData = new FormData();
    formData.append("code",'bank_amount_reminder_percentage');   
    formData.append("value",this.persentage_data);  
    this.http.post(this.funct.ipaddress + 'SysParam/setvalue', formData, {headers: headers})
    .pipe(
      catchError(this.handleError.bind(this))
    )
    .subscribe(
      result => {
        this.dto.Response = {};
        this.dto.Response = result;
        if(this.dto.Response == true)
        {   
          $('#readData').modal("hide"); 
          this.getPersentage(); 
          this.persentage_data ="";
           
        }
       
      });
}



getPersentage() 
{    
 
  this.dto.token = this.storage.retrieve('token');
  if (!this.dto.token) {
    console.error('Token is missing. Cannot make the request.');
    return;
  }  
  let headers = new HttpHeaders().set('Authorization', `Bearer ${this.dto.token}`);
  let params = new HttpParams().set('code', 'bank_amount_reminder_percentage');
  this.http.get(this.funct.ipaddress + 'SysParam/getvalue', { params: params, headers: headers }) 
    .pipe(
      catchError((error) => 
      {
        console.error('Request failed with error:', error);
        return this.handleError(error); 
      })
    )
    .subscribe(
      result => 
      {        
        this.dto.Response = result;
        this.dto.persentage = result;
        this.storage.store('persentage', this.dto.persentage);
        this.config = this.dto.persentage;
      
      }
      
    );
}




goModal(id,  accountNo, bankName, codeNumber , accountName)
{
  this.dtTrigger2 = new Subject<any>();//
  var id1 = 'paymentBankAccountHolderDetaiList' + this.idIndex2;
  var table = $('#' + id1).DataTable();
  table.destroy();
  this.idIndex2 = this.idIndex2 +1;
  this.spinner.show();
  this.token = this.storage.retrieve('token');
  let headers = new HttpHeaders();
  headers = headers.set('Authorization', this.token);
  let params = new HttpParams();
  this.paymentBankAccountHolderDetaiList = [];
   params = params.set('paymentDetailId', id);
  this.http.get(this.funct.ipaddress + 'payment/GetAccountHolderDetaiList', { params: params, headers: headers })
  .pipe(
    catchError(this.handleError.bind(this))
   )
  .subscribe(
    result => 
    {
      this.dto.Response = {};
      this.dto.Response = result;
      this.paymentBankAccountHolderDetaiList = this.dto.Response;
      this.account_no = accountNo;
      this.bankName = bankName;
      this.code_number = codeNumber;
      this.account_name = accountName;
      this.spinner.hide();
      this.dtTrigger2.next();
    }
  ); 
   this.spinner.hide();
  $('#browseAccountHolderList').modal("show");
}

getHolder()
{
  if(this.type == 'TOPUP' )
      {
          this.getAllAdmins();
      }
      else
      {
        this.getAllAdminsWithWithdrawal();
      }
  $('#accountholder').modal("show");
  
}

getHolder_pending()
{
  if(this.type_pending == 'TOPUP' )
      {
          this.getAllAdmins();
      }
      else
      {
        this.getAllAdminsWithWithdrawal();
      }
  $('#accountholder').modal("show");
  
}



getHolder_cbpay()
{
  if(this.type_cbpay =='TOPUP')
      {
          this.getAllAdmins();
      }
      else
      {
        this.getAllAdminsWithWithdrawal();
      }
  $('#accountholder').modal("show");
  
}
 

getHolder_ayapay()
{
  if(this.type_ayapay =='TOPUP' )
      {
          this.getAllAdmins();
      }
      else
      {
        this.getAllAdminsWithWithdrawal();
      }
  $('#accountholder').modal("show");
  
}

getHolder_wavepay()
{
  if(this.type_wavepay =='TOPUP')
      {
          this.getAllAdmins();
      }
      else
      {
        this.getAllAdminsWithWithdrawal();
      }
  $('#accountholder').modal("show");
  
}


onChange() {
}

getAllAdminsWithWithdrawal()
    {
      this.adminList =[];
      this.dto.token = this.storage.retrieve('token');
      let headers = new HttpHeaders();
      headers = headers.set('Authorization',  this.dto.token );
      this.http.get(this.funct.ipaddress + 'admin/GetWithdrawalAdmin', { headers: headers }).subscribe(
        result => {
          this.dto.Response = {};
          this.dto.Response = result;
          this.adminList = this.dto.Response;
          this.myOptions = this.adminList;
        }
      );
    }

    getAllAdmins()
    {
      this.adminList =[];
      this.dto.token = this.storage.retrieve('token');
      let headers = new HttpHeaders();
      headers = headers.set('Authorization',  this.dto.token );
      this.http.get(this.funct.ipaddress + 'admin/GetAllAdmin', { headers: headers }).subscribe(
        result => {
          this.dto.Response = {};
          this.dto.Response = result;
          this.adminList = this.dto.Response;
          this.myOptions = this.adminList;
        }
      );
    }


    confirmSelection() 
    {   
        
      this.spinner.show();  
      this.dto.token = this.storage.retrieve('token');
      let headers = new HttpHeaders(); 
      headers = headers.set('Authorization', this.dto.token);

      var formData = new FormData();
      // if(this.type_pending == 'TOPUP')
      // {
      //     this.BankType_pending = "1";
      // }
      // else
      // {
      //   this.BankType_pending = "4";
      // }
      formData.append("bankIds",this.paymentIds);
      formData.append("adminId",this.optionsModel.toString());    

      this.http.post(this.funct.ipaddress + 'payment/AddAccountHolder', formData, {headers: headers})
      .pipe(
        catchError(this.handleError.bind(this))
        )
      .subscribe( 
        result => 
        {
          this.dto.Response = {};
          this.dto.Response = result;
          if(this.dto.Response.status =="Success")
          {
            this.toastr.success(this.dto.Response.message, 'Success!', 
            {
              timeOut: 3000,
              positionClass: 'toast-top-right'
            }) 
            this.spinner.hide();
          }
          else{
            this.toastr.error(this.dto.Response.message, 'Invalid!', 
            {
              timeOut: 3000,
              positionClass: 'toast-top-right',
              });
              this.spinner.hide();             
              return;
          }         

          
        });
        $('#accountholder').modal("hide");
        this.optionsModel= null;
        console.log('Current this.type:', this.type);
        if (this.type !== null && this.type !== "") {
            this.search('tabsearch');
            return;
        }
        if (this.type_pending !== null && this.type_pending !== "") 
        {
          this.search_pending('tabsearch');
          return;
        }
        if (this.type_ayapay !== null && this.type_ayapay !== "") {
          this.search_ayapay('tabsearch');
          return;
        }
        if (this.type_cbpay !== null && this.type_cbpay !== "") {
          this.search_cbpay('tabsearch');
          return;
        }
        if (this.type_wavepay !== null && this.type_wavepay !== "") {
          this.search_wavepay('tabsearch');
          return;
        }

    }
    close()
    {
      $('#accountholder').modal("hide");
      this.optionsModel= null;
    }

}
