<div class="wrapper">
    <!-- Navbar -->
    <app-header-sidebar></app-header-sidebar>

    <!-- here here here -->
    <!-- Content Wrapper. Contains page content -->
    <div class="content-wrapper">
        <!-- Content Header (Page header) -->
        <div class="content-header">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-sm-10" style="border: 1px solid #ced4da;border-radius: 0.25rem;">
                        <h3 class="m-0 text-dark" style="font-weight: bold;padding: 3px 0px 3px 5px;">
                            Service Phone </h3>
                    </div>
                    <div class="col-sm-2">
                        <button type="button" class="btn btn-block btn-success" [routerLink] = "['/servicephone-sort']">
                        Sort For Mobile
                        </button>
                    </div>
                </div>
            </div><!-- /.container-fluid -->
        </div>
        <!-- /.content-header -->

        <!-- Main content -->
        <section class="content">
            <div class="container-fluid">
                <form>
                    <div class="row">

                        <div class="form-group col-md-2">
                            <label> Phone </label>
                            <input class="form-control form-control-sm" [(ngModel)]="phone"
                                [ngModelOptions]="{standalone: true}">
                        </div>

                        <div class="form-group col-md-2">
                       
                            <label class="col-md-2"> Type</label>
                            <select class="status form-control form-control-sm col-md-12 col-sm-4 col-xs-4"
                                [(ngModel)]="type" [ngModelOptions]="{standalone: true}">
                                <option value="TRANSACTION" selected> Transaction </option>
                                <option value="AGENT"> Agent </option>
                                <option value="ERROR"> Error </option>
                                <!-- <option value = "TECHNICAL">Technical</option> -->
                                <option value = "JOINUS">Join Us</option>
                                <option value = "CUSTOMERSERVICE">Customer Service</option>
                                <option value = "">All</option>
                            </select>
                        </div>

                        <div class="form-group col-md-2">
                            <label> Status </label>
                            <select class="status form-control form-control-sm col-md-12 col-sm-4 col-xs-4"
                                [(ngModel)]="status" [ngModelOptions]="{standalone: true}">
                                <option value="ACTIVE" selected>Active</option>
                                <option value="INACTIVE">Inactive</option>
                            </select>
                        </div>

                        <div class="form-group col-md-2">
                        </div>

                        <div class="form-group col-md-2 col-sm-4 col-xs-4">
                            <label></label>
                            <button id="search" type="button" class="btn btn-block btn-primary btn-sm"
                                style="margin-top: 4%;" (click)="search()">
                                Search
                            </button>
                        </div>

                        <div class="form-group col-md-2 col-sm-4 col-xs-4" *ngIf="hardcode_add_bool === true">
                            <label></label>
                            <button type="button" class="btn btn-block btn-success btn-sm" style="margin-top: 4%;"
                                [routerLink]="['/servicephone-detail']">
                                Add New
                            </button>
                        </div>

                        <div class="col-md-12">
                            <div class="card">
                                <div class="card-header">
                                    <h3 class="card-title" style="font-weight: bold;"> Service Phone List </h3>
                                </div>
                                <!-- /.card-header -->
                                <div class="card-body">
                                    <table width='100%' id="{{'tblServicePhone' + idIndex}}" datatable
                                        [dtTrigger]="dtTrigger1" [dtOptions]="dtOptions1"
                                        class="table table-bordered table-hover">
                                        <thead>
                                            <tr>
                                                <th style="font-family: unicode;">Phone</th>
                                                <th style="font-family: unicode;">Type</th>
                                                <th style="font-family: unicode;">Showing From Time</th>
                                                <th style="font-family: unicode;">Showing To Time</th>
                                                <th style="font-family: unicode;">Status</th>
                                                <th style="font-family: unicode;">Updated Date</th>
                                                <th style="font-family: unicode;">Updated By</th>
                                                <th style="font-family: unicode;">Created Date</th>
                                                <th style="font-family: unicode;">Created By</th>
                                                <th style="font-family: unicode;" *ngIf="hardcode_double_bool === true">Action</th> 
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr *ngFor="let service of servicephoneList">
                                                <td style="font-family: unicode;">{{service.phone_no}}</td>
                                                <td style="font-family: unicode;">{{service.type}}</td>
                                                <td style="font-family: unicode;">{{service.fromTime}}</td>
                                                <td style="font-family: unicode;">{{service.toTime}}</td>
                                                <td style="font-family: unicode;">{{service.status}}</td>
                                                <td style="font-family: unicode;">{{service.updated_date_Str}}</td>
                                                <td style="font-family: unicode;">{{service.updated_by_name}}</td>
                                                <td style="font-family: unicode;">{{service.created_date_Str}}</td>
                                                <td style="font-family: unicode;">{{service.created_by_name}}</td>
                                                <td style="font-family: unicode;" *ngIf="hardcode_double_bool === true">
                                                        <a [routerLink]="['/servicephone-detail', service.id]" *ngIf="hardcode_edit_bool === true">
                                                            <i class="fas fa-edit" style="color:#4b88eb;"></i>
                                                        </a>
                                                        &nbsp;&nbsp;
                                                        <a *ngIf="hardcode_delete_bool === true">
                                                            <i class="fas fa-trash"  (click)="deleteservice(service.id)" style="color:#dc3545;cursor: pointer;"></i>
                                                        </a>
                                                </td> 
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <!-- /.card-body -->
                            </div>
                        </div>
                    </div>
                </form>
                <!-- /.row -->
                <!-- /.row -->
            </div>
            <!--/. container-fluid -->
        </section>
        <!-- /.content -->
    </div>

    <aside class="control-sidebar control-sidebar-dark">
        <!-- Control sidebar content goes here -->
    </aside>

    <app-footer></app-footer>

    <ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#fff" type="square-jelly-box">
        <p style="font-size: 16px; color: white">Loading...</p>
    </ngx-spinner>

</div>


<div id="deleteData" class="modal fade">
    <div class="modal-dialog">
        <div class="modal-content">

            <div class="modal-body">
                <form>

                    <div class="row" style="margin-top: 5%;margin-bottom: 3%;margin-left: 9%;">
                        <div class="form-group col-md-12">
                            <h5> This Account is used by another device. 
                                Please Sign Out. Login Again. </h5>
                        </div>
                    </div>

                    <div class="row" style="margin-left: 40%;margin-bottom: 3%;">
                        <div class="col-md-5 col-sm-6" id="deleteBtn">
                            <button type="button" class="btn btn-block btn-success" (click)="OkLogout()"> Ok </button>
                        </div>
                    </div>
                </form>
            </div>

        </div>
    </div>
</div>




<div id="deleteDataForService" class="modal fade">
    <div class="modal-dialog">
        <div class="modal-content">
  
            <div class="modal-body">
                <form>
  
                    <div class="row" style="margin-top: 5%;margin-bottom: 3%;margin-left: 9%;">
                        <div class="form-group col-md-12" style="text-align: center;">
                            <h3 class="text-center"> Are you sure want to delete? </h3>
                        </div>
                    </div>
  
                    <div class="row" style="margin-left: 10%;margin-bottom: 3%;">
                        
                        <div class="col-md-5">
                            <button type="button" class="btn btn-block btn-danger" (click) = "closeTran()" [routerLink] = "['/servicephone-list']"> Cancel </button>
                        </div>
  
                        <div class="col-md-5" id="deleteBtn">
                          <button type="button" class="btn btn-block btn-success" (click)="deleteServicePhoneById()"> Confirm </button>
                      </div>
  
                    </div>
  
                </form>
            </div>
  
        </div>
    </div>
  </div>