<div class="wrapper">
    <!-- Navbar -->
    <app-header-sidebar></app-header-sidebar>

    <!-- here here here -->
    <!-- Content Wrapper. Contains page content -->
    <div class="content-wrapper">
        <!-- Content Header (Page header) -->
        <div class="content-header">
            <div class="container-fluid">
                <div class="row mb-2" style="border: 1px solid #ced4da;border-radius: 0.25rem;">
                    <div class="col-sm-8">
                        <h3 class="m-0 text-dark" style="font-weight: bold;padding: 3px 0px 3px 5px;">
                            Game 
                        </h3>
                    </div>
                </div><!-- /.row -->
            </div><!-- /.container-fluid -->
        </div>
        <!-- /.content-header -->

        <!-- Main content -->
        <section class="content">
            <div class="container-fluid">
                <form>
                    <div class="row">

                        <div class="form-group col-md-2">
                            <label> Game Name </label>
                            <input class="form-control form-control-sm" [(ngModel)]="gameName"
                                [ngModelOptions]="{standalone: true}">
                        </div>
                        
                        <div class = "form-group col-md-2">
                            <label>Game Provider</label>
                              <div class="form-control-sm col-md-12 col-sm-4 col-xs-4">
                              <select (change) = "flagProvider()" id = "providerId" class="form-control form-control-sm col-md-12 col-sm-4 col-xs-4"
                               [(ngModel)]="providerId" [ngModelOptions]="{standalone: true}">
                                <option *ngFor="let provider of gameproviderList" value = "{{provider.provider_id}}" >
                                  {{provider.display_name}}
                                </option>
                             </select>
                            </div> 
                        </div>

                        <div class="form-group col-md-2">
                            <label>Status</label>
                            <select class="form-control form-control-sm col-md-12 col-sm-4 col-xs-4" [(ngModel)]="status" [ngModelOptions]="{standalone: true}">
                              <option value="ACTIVE" selected>Active</option>
                              <option value="INACTIVE">Inactive</option>
                            </select>
                          </div>

                        <div class="form-group col-md-2">
                        </div>

                        <div class="form-group col-md-2 col-sm-4 col-xs-4">
                            <label></label>
                            <button id="search" type="button" class="btn btn-block btn-primary btn-sm"
                                style="margin-top: 4%;" (click)="search()">
                                Search
                            </button>
                        </div>

                        <div class="form-group col-md-2 col-sm-4 col-xs-4" style="display: none;">
                            <label></label>
                            <button type="button" class="btn btn-block btn-success btn-sm" style="margin-top: 4%;"
                                [routerLink]="['/gs-game-detail']">
                                Add New
                            </button>
                        </div>

                        <div class="col-md-12">
                            <div class="card">
                                <div class="card-header">
                                    <h3 class="card-title" style="font-weight: bold;">Game List </h3>
                                </div>
                                <!-- /.card-header -->
                                <div class="card-body">
                                    <table width='100%' id="{{'tblpayment' + idIndex}}" datatable
                                        [dtTrigger]="dtTrigger" [dtOptions]="dtOptions"
                                        class="table table-bordered table-hover">
                                        <thead>
                                            <tr>
                                                <th style="font-family: unicode;">Game Name (English)</th>
                                                <th style="font-family: unicode;">Game Name (Burmese)</th>
                                                <th style="font-family: unicode;">Game Name (Chinese)</th>
                                                <th style="font-family: unicode;">Game Name (Thai)</th>
                                                <th style="font-family: unicode;">Game Code</th> 
                                                <th style="font-family: unicode;">Provider Code</th> 
                                                <th style="font-family: unicode;">Order Number</th>
                                                <th style="font-family: unicode;">Is Hot</th>
                                                <th style="font-family: unicode;">Status</th> 
                                                <th style="font-family: unicode;">Updated By</th>                                           
                                                <th style="font-family: unicode;" *ngIf="hardcode_edit_bool === true">Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr *ngFor="let game of gameList">
                                                <td style="font-family: unicode;">{{game.name}}</td>
                                                <td style="font-family: unicode;">{{game.name_my}}</td>
                                               
                                                <td style="font-family: unicode;">{{game.name_zh}}</td>                                                 
                                                <td style="font-family: unicode;">{{game.name_th}}</td>
                                                
                                                <td style="font-family: unicode;">{{game.code}}</td>
                                                <td style="font-family: unicode;">{{game.providercode}}</td>
                                                <td style="font-family: unicode;">{{game.order_id}}</td>

                                                <td style="font-family: unicode;">{{game.isHot}}</td>

                                                <td style="font-family: unicode;">{{game.status}}</td>
                                                <td style="font-family: unicode;">{{game.updated_by}}</td>
                                                
                                                <td style="font-family: unicode;" *ngIf="hardcode_edit_bool === true">
                                                    <!-- <a [routerLink]="['/gsgame-detail', game.id]">
                                                        <i  title = "Edit" class="fas fa-edit" style="color:#4b88eb;cursor: pointer;"></i>
                                                    </a> -->
                                                    <a (click)="addStepAndNavigate(game)">
                                                        <i  title = "Edit" class="fas fa-edit" style="color:#4b88eb;cursor: pointer;"></i>
                                                    </a>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                         
                            </div>
                        </div>
                    </div>
                </form>
               
            </div>
          
        </section>
   
    </div>

    <aside class="control-sidebar control-sidebar-dark">
     
    </aside>

    <app-footer></app-footer>

    <ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#fff" type="square-jelly-box">
        <p style="font-size: 16px; color: white">Loading...</p>
    </ngx-spinner>

</div>

<div id="browseAccountData" class="modal fade">
    <div class="modal-dialog">
      <div class="modal-content" style="width: 600px;">
        <div class="modal-header">
          <h3 class="modal-title" style="font-weight: bold;font-size: 1.1rem;"> Bank Account Detail </h3>
          <button type="button" class="close" data-dismiss="modal" aria-hidden="true"> x </button>
        </div>
        <div class="modal-body">
          <form>
            <div class="col-md-12">
              <div class="row" style="margin-left: 0.1%;">
                    <label class="col-md-2"> Name </label>
                    <div class="form-group col-md-3">
                    <input class="form-control form-control-sm" [(ngModel)]="name" [ngModelOptions]="{standalone: true}"
                        disabled>
                    </div>
              </div>
              <div class="col-md-12">
                <div class="card">
                  <div class="card-body"  style = "width : 100%">
                    <table width='100%' id="{{'tbltwodbetdetail' + idIndex2}}" datatable [dtTrigger]="dtTrigger2"
                      [dtOptions]="dtOptions2" class="table table-bordered table-hover">
                      <thead>
                        <tr>
                          <th style="font-family: unicode;">No</th>
                          <th style="font-family: unicode;">Account Number</th>
                          <th style="font-family: unicode;">Status</th>
                          <th style="font-family: unicode;">Order Number</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr *ngFor="let temp of paymentTempList, let i = index">
                          <td style="font-family: unicode; padding: 0.35rem 0.75rem 0.30rem 0.75rem;">{{ i + 1 }}</td>
                          <td style="font-family: unicode; padding: 0.35rem 0.75rem 0.30rem 0.75rem;">{{temp.account_no}}
                          <td style="font-family: unicode; padding: 0.35rem 0.75rem 0.30rem 0.75rem;">{{temp.status}}
                        <td style="font-family: unicode; padding: 0.35rem 0.75rem 0.30rem 0.75rem;">{{temp.order_id}}

                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
  
            </div>
  
          </form>
        </div>
  
    </div>
  </div>


  <div id="deleteData" class="modal fade">
    <div class="modal-dialog">
        <div class="modal-content">

            <div class="modal-body">
                <form>

                    <div class="row" style="margin-top: 5%;margin-bottom: 3%;margin-left: 9%;">
                        <div class="form-group col-md-12">
                            <h5> This Account is used by another device. 
                                Please Sign Out. Login Again. </h5>
                        </div>
                    </div>

                    <div class="row" style="margin-left: 40%;margin-bottom: 3%;">
                        <div class="col-md-5 col-sm-6" id="deleteBtn">
                            <button type="button" class="btn btn-block btn-success" (click)="OkLogout()"> Ok </button>
                        </div>
                    </div>
                </form>
            </div>

        </div>
    </div>
</div>