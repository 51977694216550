import { Component, OnInit, ViewChild } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,HttpErrorResponse } from '@angular/common/http';
import { Router, NavigationEnd } from '@angular/router';
import { DataTableDirective } from 'angular-datatables';

import { LocalStorageService } from 'ngx-webstorage';
import { NgxSpinnerService } from "ngx-spinner";

import { catchError, retry } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { DtoService } from '../../service/dto.service';
import { FunctService } from '../../service/funct.service';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
import Responsive from 'datatables.net-responsive';
declare var $: any;
@Component({
  selector: 'app-3D-live-setting',
  templateUrl: './3D-live-setting.component.html',
  styleUrls: ['./3D-live-setting.component.css']
})
export class ThreeDLiveListComponent implements OnInit {

  @ViewChild(DataTableDirective)
  dtElement: DataTableDirective;
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();

  phoneNo: string= '';
  name: string= '';
  referralCode: string= '';
  status: string= '';
  agentList: any;
  idIndex: any;

  hardcode_view : any;
  hardcode_edit :any;
  hardcode_add :any;
  hardcode_delete: any;
  hardcode_delete_bool : any;
  hardcode_add_bool : any;
  hardcode_edit_bool : any;
  hardcode_double :any;
  addlink:any;
  livelink:any;
  addlivelink:any;
  addTranDto:any;
  adsDTO:any;
  totalItems:any;
  page = 1;
  bankTypeList:any;
  BankType:any;
  transaction_tutorial:any;
  editId:any;
  originalAdminDTO:any;

  constructor(private storage: LocalStorageService, private spinner: NgxSpinnerService, private toastr: ToastrService, private http: HttpClient, private dto: DtoService, private router: Router,
    private funct: FunctService) 
    {
      this.storage.store('isNotiSong', "");
    this.idIndex = 1;
    this.status = 'ACTIVE';    
    this.hardcode_view = "3DLive_View";
    this.hardcode_delete = "3DLive_Del";
    this.hardcode_edit = "3DLive_Edit";
    this.hardcode_add = "3DLive_Add";
    this.dto.menuCodes = this.storage.retrieve('menucodes');

    const filteredMenuCodes_delete = [];
    const filteredMenuCodeds_edit =[];
    const filteredMenuCodeds_add =[];
    this.dto.menuCodes.forEach(menuCode => {
      if (menuCode === this.hardcode_delete) {
        filteredMenuCodes_delete.push(menuCode);
      }   
      if (menuCode === this.hardcode_edit) {
        filteredMenuCodeds_edit.push(menuCode);
      }
      if (menuCode === this.hardcode_add) {
        filteredMenuCodeds_add.push(menuCode);
      }
    });
        if(filteredMenuCodes_delete.length != 0)
        {
            this.hardcode_delete_bool = true;
        }
        else
        {
          this.hardcode_delete_bool = false;
        }    
     
        if(filteredMenuCodeds_edit.length != 0)
        {
            this.hardcode_edit_bool = true;
        }
        else
        {
          this.hardcode_edit_bool = false;
        }

        if(filteredMenuCodeds_add.length != 0)
        {
            this.hardcode_add_bool = true;
        }
        else
        {
          this.hardcode_add_bool = false;
        }

        if(this.hardcode_delete_bool == false && this.hardcode_edit_bool == false)        
        {
            this.hardcode_double = false;
        }
        else
        {
          this.hardcode_double = true;
        }

     this.getBankTypeList();
    this.search();
    
   }

  ngOnInit(): void {

    
    this.router.routeReuseStrategy.shouldReuseRoute = function () {
      return false;
    };

    this.router.events.subscribe((evt) => {
      if (evt instanceof NavigationEnd) {
        this.router.navigated = false;
        window.scrollTo(0, 0);
      }
    });
    this.dtOptions = {
      responsive: {
        details: {
            renderer: Responsive.renderer.listHiddenNodes()
        }
    },
      order: [],
      paging: true,
      info:true,
      destroy : true
    }


    this.dtOptions.columnDefs = [
      { targets: [5], orderable: true }

    ];    
    this.addTranDto = {    
      livelink: ''
    };

    this.adsDTO ={
      livelink:''
    };

   
    if(!this.storage.retrieve('loadFlag')){
      this.storage.store('loadFlag', 'noLoad');
      setTimeout(function(){
        location.reload();
      }, 5);
    }
    else{
      this.storage.clear('loadFlag');
    }
  }

  ngAfterViewInit(){

  }

  handleError(error: HttpErrorResponse){
    this.spinner.hide();
    if(error.status == 423)
    {
      this.spinner.hide();
      $("#deleteData").modal("show");
    }
    if(error.status == 403)
    {
      this.spinner.hide();
      var id = 'tblAgent' + this.idIndex;
      var table = $('#' + id).DataTable();
      this.toastr.error("Limited Access.", 'Invalid!', {
        timeOut: 3000,
        positionClass: 'toast-top-right',
        });
    }
    if(error.status == 406)
    {
      this.spinner.hide();
      this.toastr.error("Can't delete because users use this referral code!", 'Invalid!', {
        timeOut: 3000,
        positionClass: 'toast-top-right',
      });
    }
    return throwError(error);
    }
    OkLogout()
    {
      window.location.href ="./ad-login";
    } 
    showAddTranModelNew()
    {     
 
      $('#browseAccountData10').modal("show");

      
    }

  search(){
    this.agentList = [];
    var id = 'tblAgent' + this.idIndex;
    var table = $('#' + id).DataTable();
    table.destroy();
    this.idIndex = this.idIndex +1;
    this.spinner.show();
    this.dto.token = this.storage.retrieve('token');
    let headers = new HttpHeaders();
    headers = headers.set('Authorization', this.dto.token);
    let params = new HttpParams();
    if(this.addlivelink == undefined || this.addlivelink ==null)
    {
      this.addlivelink = '';
    }
    params = params.set('liveLink',this.addlivelink);
    this.http.get(this.funct.ipaddress + 'agent/3DlivelinkByparams', { params: params, headers: headers } )
    .pipe(
      catchError(this.handleError.bind(this))
     )
    .subscribe(
      result => {
        this.dto.Response = {};
        this.dto.Response = result;
        this.agentList = this.dto.Response;
          this.totalItems = result.totalItems;
        this.dtTrigger.next();
        this.spinner.hide();
      }
    );
  }

  gty(page:any){
    this.agentList = [];
    var id = 'tblAgent' + this.idIndex;
    var table = $('#' + id).DataTable();
    table.destroy();
    this.idIndex = this.idIndex +1;
    this.spinner.show();
    this.dto.token = this.storage.retrieve('token');
    let headers = new HttpHeaders();
    headers = headers.set('Authorization', this.dto.token);
    let params = new HttpParams();
    if(this.addlivelink == undefined || this.addlivelink ==null)
    {
      this.addlivelink = '';
    }
    params = params.set('liveLink',this.addlivelink);
    this.http.get(this.funct.ipaddress + 'agent/3DlivelinkByparams', { params: params, headers: headers } )
    .pipe(
      catchError(this.handleError.bind(this))
     )
    .subscribe(
      result => {
        this.dto.Response = {};
        this.dto.Response = result;
        this.agentList = this.dto.Response;
        this.totalItems = result.totalItems;
        this.dtTrigger.next();
        this.spinner.hide();
      }
    );
  }
  delete(id)
  {
    $("#deleteDataForTutorial").modal("show");
    this.transaction_tutorial = id;       
  } 

  closeTran()
  {
      $('#deleteDataForTutorial').modal("hide");
      this.router.navigate(['/3D-live-list']).then(() => 
      {
      
    });
  }
  deleteLive()
  {
    this.dto.token = this.storage.retrieve('token');
    let headers = new HttpHeaders();
    headers = headers.set('Authorization',  this.dto.token);
    let params = new HttpParams();
    params = params.set('id', this.transaction_tutorial);
    this.http.get(this.funct.ipaddress + 'agent/deletelivelink', { params: params, headers: headers })
    .pipe(
      catchError(this.handleError.bind(this))
     )
    .subscribe(
      result => {
        this.dto.Response = {};
        this.dto.Response = result;
        if (this.dto.Response.status == 'Success') {
          this.spinner.hide();  
          this.search();         
          this.toastr.success(this.dto.Response.message, 'Success!', {
            timeOut: 3000,
            positionClass: 'toast-top-right'
          })     
        }
        else {
          this.spinner.hide();
          this.toastr.error(this.dto.Response.message, 'Invalid!', {
            timeOut: 3000,
            positionClass: 'toast-top-right',
          });
        }
      });
      $('#deleteDataForTutorial').modal("hide");
    }

  getBankTypeList() {
    this.bankTypeList = [];
    this.spinner.show();
    this.dto.token = this.storage.retrieve('token');
    let headers = new HttpHeaders();
    headers = headers.set('Authorization',  this.dto.token);
    this.http.get(this.funct.ipaddress + 'payment/GetBankTypeLists', { headers: headers })
    .pipe(
      catchError(this.handleError.bind(this))
     )
    .subscribe(
      result => {
        this.dto.Response = {};
        this.dto.Response = result;
        this.bankTypeList = this.dto.Response;
        this.spinner.hide();
      });
  }

  showEditRolesModel(editId)
  {   

      this.dto.token = this.storage.retrieve('token');
      let headers = new HttpHeaders();
      headers = headers.set('Authorization',  this.dto.token);
      let params = new HttpParams();
      params = params.set('id', editId);
      this.http.get(this.funct.ipaddress + 'agent/Getlivelink', { params: params, headers: headers })
      .pipe(
        catchError(this.handleError.bind(this))
       )
      .subscribe( 
        result => {
          this.dto.Response = {};
          this.dto.Response = result;
          this.adsDTO = this.dto.Response;             
          $('#browseAccountData9').modal("show");
        });     
      
  }

  
  onInput(event: InputEvent) {
    const myanmarCharactersRegex = new RegExp('^[\u1000-\u109F\uAA60-\uAA7F\uA9E0-\uA9FF]+$');
    const inputValue = (event.target as HTMLInputElement).value; 
    if(event.data!=null)
    {
      if (myanmarCharactersRegex.test(inputValue)) 
      {        
        this.spinner.hide();
        this.toastr.error( "Myanmar typing detected!", '', {
        timeOut: 5000,
        positionClass: 'toast-top-right',
        });  
      }
    }   
  }
  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }

    Close()    
    {     
      $('#browseAccountData10').modal("hide"); 
      $('#browseAccountData9').modal("hide"); 
      this.addlink ="";
      
      
    }

    SaveNewRole(addlink)
    {
      if(addlink)
      {
        this.dto.token = this.storage.retrieve('token');
        let headers = new HttpHeaders();
        headers = headers.set('Authorization', this.dto.token);  
        this.addTranDto.livelink = addlink; 
        this.http.post(this.funct.ipaddress + 'agent/AddThreeDLiveLink', this.addTranDto,{ headers: headers })
        .pipe(
          catchError(this.handleError.bind(this))
         )
        .subscribe(
          result => {
            this.dto.Response = {};
            this.dto.Response = result;
            if(this.dto.Response.status == 'Success')
            {    
              this.search(); 
              this.addlink="";  
              $('#browseAccountData10').modal("hide");               
              this.toastr.success(this.dto.Response.message, 'Success!', {
                timeOut: 3000,
                positionClass: 'toast -top-right'
              });    
              this.spinner.hide();
                    
            }
            else{
           
              $('#browseAccountData10').modal("hide");         
              this.toastr.error(this.dto.Response.message, 'Invalid!', {
              timeOut: 3000,
              positionClass: 'toast-top-right',
              });
              this.spinner.hide(); 
              
             }
        
          }
        );
        
      } 
        else
        {
          this.toastr.error("Please fill data ", 'Invalid!',
           {
            timeOut: 3000,
            positionClass: 'toast-top-right',
          });
          this.spinner.hide();
          return;
       
      }
      
    }

   

    addTran(id ,addlink)
    {
      
      if(addlink)
      {
        this.dto.token = this.storage.retrieve('token');
        let headers = new HttpHeaders();
        headers = headers.set('Authorization',this.dto.token);  

        //let editedArray = this.getEditedArray();   
        //let headers = new HttpHeaders();
      
        // if(editedArray.length > 0)
        // {
        //   headers = headers.set('Authorization', this.dto.token)
        //   .set('editedArray', editedArray); 
        // }
        // else{
          //headers = headers.set('Authorization',  this.dto.token);
        //}


        this.adsDTO.id = id; 
        this.adsDTO.livelink = addlink; 
        this.http.post(this.funct.ipaddress + 'agent/UpdateLiveLink', this.adsDTO,{ headers: headers })
        .pipe(
          catchError(this.handleError.bind(this))
         )
        .subscribe(
          result => {
            this.dto.Response = {};
            this.dto.Response = result;
            if(this.dto.Response.status == 'Success')
            {   
              this.search();
                $('#browseAccountData9').modal("hide");   
              this.toastr.success(this.dto.Response.message, 'Success!', {
                timeOut: 3000,
                positionClass: 'toast-top-right'
              });
              this.spinner.hide();
           }
         
          }
        );
      }
      else
      {
        this.toastr.error("Please fill data ", 'Invalid!',
         {
          timeOut: 3000,
          positionClass: 'toast-top-right',
        });
        this.spinner.hide();
        return;
     
    }

    }

 
  //  addStepAndNavigate(agent: any): void
  //  { 
  //   localStorage.setItem('threeDData', JSON.stringify({
  //     id: agent.id,
  //     liveLink:agent.liveLink,
  //     created_date:agent.created_date,
  //     created_by_name:agent.created_by_name,
  //     updated_date:agent.updated_date,
  //     updated_by_name:agent.updated_by_name

  //   }));
  //   this.editId = agent.id;
  //   const adminDataString = localStorage.getItem('threeDData');
  //   this.originalAdminDTO = JSON.parse(adminDataString);
  //   this.showEditRolesModel();
    
  // }

  
  getEditedArray() {
    let editedArray = '';
  
    if (this.adsDTO.livelink !== this.originalAdminDTO.liveLink) 
    {
      editedArray += 'Link: ' + this.originalAdminDTO.liveLink + ' -> ' + this.adsDTO.livelink + ', ';
    }  
    if (editedArray.endsWith(', ')) 
    {
      editedArray = editedArray.slice(0, -2);
    }
    
    return editedArray;
  }
  

    


}
