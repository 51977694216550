import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { DtoService } from '../app/service/dto.service';
import { UtilService } from '../app/service/util.service';
import { FunctService } from '../app/service/funct.service'

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';
import { DataTablesModule } from 'angular-datatables';
import { DatePipe } from '@angular/common';

import { ToastrModule } from 'ngx-toastr';
import { NgxSpinnerModule } from "ngx-spinner";
import { NgxWebstorageModule } from 'ngx-webstorage';
import { NgxDatePickerModule } from '@ngx-tiny/date-picker';

import { FormsModule, ReactiveFormsModule} from '@angular/forms';

import { LoginComponent } from './component/login/login.component';
import { PageNotfoundComponent } from './component/page-notfound/page-notfound.component';
import { WelcomeComponent } from './component/welcome/welcome.component';
import { DashboardComponent } from './component/dashboard/dashboard.component';
// import { HeaderSidebarComponent } from './component/header-sidebar-bk/header-sidebar.component';
import { HeaderSidebarComponent } from './component/header-sidebar/header-sidebar.component';
import { FooterComponent } from './component/footer/footer.component';
import { AdminComponent } from './component/admin/admin.component';
import { AdminLogsComponent } from './component/admin-logs/admin-logs.component';
import { GameTransactionComponent } from './component/game-transaction/game-transaction.component';
import { GameTransactionsComponent } from './component/game-transactions/game-transactions.component';
import { AdminDetailComponent } from './component/admin-detail/admin-detail.component';
import { AdminDetailHistorysComponent } from './component/admin-detail-historys/admin-detail-historys.component';
import { AgentComponent } from './component/agent/agent.component';
import { AgentDetailComponent } from './component/agent-detail/agent-detail.component';
 import { UserComponent } from './component/user/user.component';
 import { SMKLockUserListComponent } from './component/skm-lock-user-list/skm-lock-user-list.component'; 
import { UserActivInactiveComponent } from './component/user-active/user-active.component';
import { UserInactiveComponent } from './component/user-inactive/user-inactive.component';
import { UserInactiveByAdminComponent } from './component/user-inactive-byadmin/user-inactive-byadmin.component';
import { UserInactiveByForgotComponent } from './component/user-inactive-byforgot/user-inactive-byforgot.component';
import { SearchMemberComponent } from './component/search-member/search-member.component';
import { ThreeDLiveListComponent } from './component/3D-live-setting/3D-live-setting.component';
import { TransactionTutorialListComponent } from './component/transaction-tutorial/transaction-tutorial.component';
import { UserDetailComponent } from './component/user-detail/user-detail.component';
// import { FeedBackUserDetailCancelComponent } from './component/feedback-user-detail-cancel/feedback-user-detail-cancel.component';
// import { BetHistoryDetailCancelComponent } from './component/bet-history-cancel-detail/bet-history-cancel-detail.component';
// import { Configure2DBetLimitCancelDetailComponent } from './component/configure-2D-bet-limit-cancel-detail/configure-2D-bet-limit-cancel-detail.component';
// import { Configure3DBetLimitCancelDetailComponent } from './component/configure-3D-bet-limit-cancel-detail/configure-3D-bet-limit-cancel-detail.component';
// import { GameTransactionCashDetailCancelComponent } from './component/game-transaction-cash-cancel-detail/game-transaction-cash-cancel-detail.component';
// import { UserDetailActiveCancleComponent } from './component/user-detail-active-cancle/user-detail-active-cancle.component';
// import { WinnerConfirmCancelDetailComponent } from './component/winner-confirm-cancel-detail/winner-confirm-cancel-detail.component';
// import { WithdrawCancelDetailComponent } from './component/withdraw-cancel-detail/withdraw-cancel-detail.component';

// import { TopupCancelDetailComponent } from './component/topup-cancel-detail/topup-cancel-detail.component';

// import { ManageWalletLogCancelComponent } from './component/manage-wallet-log-cancel/manage-wallet-log-cancel.component';
// import { ManageWithdrawCancelComponent } from './component/manage-withdraw-cancel/manage-withdraw-cancel.component';
// import { ManageTopupCancelComponent } from './component/manage-topup-cancel/manage-topup-cancel.component';
// import { UserForgotPasswordDetailComponent } from './component/user-forgotpassword-detail-cancel/user-forgotpassword-detail-cancel.component';
// import { UserOtpDetailComponent } from './component/user-otp-detail/user-otp-detail.component';
// import { UserDetailInActiveCancleComponent } from './component/user-detail-inactive-cancle/user-detail-inactive-cancle.component';
// import { UserDetailInActiveByForgotCancleComponent } from './component/user-detail-inactiveByforgot-cancle/user-detail-inactiveByforgot-cancle.component';
// import { UserDetailInActiveByAdminCancleComponent } from './component/user-detail-inactiveByadmin-cancle/user-detail-inactiveByadmin-cancle.component';
import { UserDetailInactiveComponent } from './component/user-detail-inactive/user-detail-inactive.component';
//import { UserRegisterationComponent } from './component/user-registeration/user-registeration.component';
import { UserDetailActiveComponent } from './component/user-detail-active/user-detail-active.component';
// import { BankAccSearchMemberDetailComponent } from './component/bank-acc-search-member-detail/bank-acc-search-member-detail.component';
// import { SearchMemberDetailComponent } from './component/search-member-detail/search-member-detail.component';
// import { AdjustUserBalanceDetailComponent } from './component/adjust-user-balance-detail/adjust-user-balance-detail.component';
import { TwodComponent } from './component/twod/twod.component';
import { TwodDetailComponent } from './component/twod-detail/twod-detail.component';
import { ThreedComponent } from './component/threed/threed.component';
import { ThreedDetailComponent } from './component/threed-detail/threed-detail.component';
import { TopupCheckComponent } from './component/topup-check/topup-check.component';
import { DetailReportComponent } from './component/detailreports/detailreports.component';
import { TopupCheckDetailComponent } from './component/topup-check-detail/topup-check-detail.component';
import { WithdrawCheckComponent } from './component/withdraw-check/withdraw-check.component';
import { WithdrawCheckDetailComponent } from './component/withdraw-check-detail/withdraw-check-detail.component';
import { TwodbetRecordComponent } from './component/twodbet-record/twodbet-record.component';
import { TwodbetCancelRecordComponent } from './component/twodbet-cancel-list/twodbet-cancel-list.component';
import { ThreedbetRecordComponent } from './component/threedbet-record/threedbet-record.component';
import { ThreedbetCancelListRecordComponent } from './component/threedbet-cancel-list/threedbet-cancel-list.component';
import { ChangepasswordComponent } from './component/changepassword/changepassword.component';
import { ChangepasswordDetailComponent } from './component/changepassword-detail/changepassword-detail.component';
import { ServicephoneComponent } from './component/servicephone/servicephone.component';
import { ServicephoneDetailComponent } from './component/servicephone-detail/servicephone-detail.component';
import { TwodBetAmountLimitationComponent } from './component/twod-bet-amount-limitation/twod-bet-amount-limitation.component';
import { TwodBetAmountLimitationDetailComponent } from './component/twod-bet-amount-limitation-detail/twod-bet-amount-limitation-detail.component';
import { TwodBetAmountLimitationDetailUpdateComponent } from './component/twod-bet-amount-limitation-detail-update/twod-bet-amount-limitation-detail-update.component';
import { ThreedBetAmountLimitationComponent } from './component/threed-bet-amount-limitation/threed-bet-amount-limitation.component';
import { ThreedBetAmountLimitationDetailComponent } from './component/threed-bet-amount-limitation-detail/threed-bet-amount-limitation-detail.component';
import { ThreedBetAmountLimitationDetailUpdateComponent } from './component/threed-bet-amount-limitation-detail-update/threed-bet-amount-limitation-detail-update.component';

import { PromotionAndNewsComponent } from './component/promotion-and-news/promotion-and-news.component';
import { PromotionAndNewsDetailComponent } from './component/promotion-and-news-detail/promotion-and-news-detail.component';
import { TopupCheckDetailUpdateComponent } from './component/topup-check-detail-update/topup-check-detail-update.component';
import { TwodWinnersComponent } from './component/twod-winners/twod-winners.component';
import { ThreedWinnersComponent } from './component/threed-winners/threed-winners.component';
import { FeedbackComponent } from './component/feedback/feedback.component';
import { FaqAddnewbackComponent } from './component/faq-addnew/faq-addnew.component';
import { FaqTypebackComponent } from './component/faq-type/faq-type.component';
import { FaqbackComponent } from './component/faq/faq.component';
import { FaqStepbackComponent } from './component/faq-step/faq-step.component';
import { FeedbackDetailComponent } from './component/feedback-detail/feedback-detail.component';
import { HolidayComponent } from './component/holiday/holiday.component';
import { HolidayDetailComponent } from './component/holiday-detail/holiday-detail.component';
import { ThreedResultConfigurationComponent } from './component/threed-result-configuration/threed-result-configuration.component';
import { ThreedResultConfigurationDetailComponent } from './component/threed-result-configuration-detail/threed-result-configuration-detail.component';
import {TwoReportComponent} from './component/twodreport/twodreport.component';
import {ThreeDReportComponent} from './component/threedreport/threedreport.component';
import {ThreeDDetailsReportComponent} from './component/threeddetailsreport/threeddetailsreport.component';
import { TwodWinnerListReportComponent } from './component/twodwinnerlistreport/twodwinnerlistreport.component';
import { ThreedWinnerListReportComponent } from './component/threedwinnerlistreport/threedwinnerlistreport.component';
import { ThreeddetailWinnerListReportComponent } from './component/threeddetailwinnerlistreport/threeddetailwinnerlistreport.component';
import { TwoddetailWinnerListReportComponent } from './component/twoddetailwinnerlistreport/twoddetailwinnerlistreport.component';
import { UserStatementsReportComponent } from './component/userstatementsreport/userstatementsreport.component';
import { OddEntryComponent } from './component/oddentry/oddentry.component';
import { OddEntryDetailComponent } from './component/oddentry_detail/oddentry-detail.component';

import { CommissionCalculationComponent } from './component/commission-calculation/commission-calculation.component';
import { CommissionCalculationDetailComponent } from './component/commission-calculation-detail/commission-calculation-detail.component'
import { CommissionConfrimComponent } from './component/commission-comfirmation/commission-comfirmation.component';
import { AgentWithdrawCheckComponent } from './component/agent-withdraw-check-list/withdraw-check-list.component';
import { AgentWithdrawCheckDetailComponent } from './component/agent-withdraw-check-detail/agent-withdraw-check-detail.component'; 
import { AgentWithdrawAddNewComponent } from './component/agent-withdraw-add-new/agent-withdraw-check-new.component';

/*import { ConfirguationComponent } from './component/thai2d3d-confirguration/thai2d3d-confirguation.component';*/
import { TwoDDetailReportComponent } from './component/twoddetailreport/twoddetailreport.component';
import { TwoDDetailsReportComponent } from './component/twoddetailsreport/twoddetailsreport.component';
import { ThreeDDetailReportComponent } from './component/threeddetailreport/threeddetailreport.component';

import { ConfirguationListComponent } from './component/thai2d3d-confirguation-list/thai2d3d-confirguation-list.component';

import { from } from 'rxjs';
import { PaymentComponent } from './component/payment/payment.component';
import { PaymentDetailComponent } from './component/payment-detail/payment-detail.component';
import { NotificationsComponent } from './component/notification/notification.component';
import { NotificationListComponent } from './component/notification-list/notification-list.component';

import { AdsComponent } from './component/ads/ads.component';
import { AdsDetailComponent } from './component/ads-detail/ads-detail.component';

//paymentInfo
import {PaymentInfoComponent} from './component/payment_info/payment_info.component';
// import {BankAccountInfoComponent} from './component/bank-account-list/bank-account-list.component';

import {PaymentDetailInfoComponent} from './component/payment_info_detail/payment-detail-info.component';
import {PaymentInfoDetailHistoryComponent} from './component/payment-info-detail-history/payment-info-detail-history.component';
import {PaymentInfoNewHistoryComponent} from './component/payment-info-new-history/payment-info-new-history.component';
import {FinancialReportComponent} from './component/userfinancialreport/userfinancialreport.component';
import {FinancialDetailReportComponent} from './component/userfinancialdetailreport/userfinancialdetailreport.component';
import {GameTransactionReportComponent} from './component/gametransactionreport/gametransactionreport.component';
import {TransactionAllReportComponent} from './component/transactionallreport/transactionallreport.component';
import { UserRegistrationComponent } from './component/userregistrationreport/userregistrationreport.component';
import { TwoDWinLoseReportComponent } from './component/twodwinlosereport/twodwinlosereport.component';
import { ThreeDWinLoseReportComponent } from './component/threedwinlosereport/threedwinlosereport.component';
import { PromoterCommissionReportComponent } from './component/promotercommissionreport/promoter-commission-report.component';
import { FirstTopupReportComponent } from './component/firsttopupreport/firsttopup-report.component';
import { WinnerMonthlyReportComponent } from './component/winnerreportmonthly/winnerreport_monthly.component';
import { FirstTopupReportDailyComponent } from './component/firsttopupreport_daily/firsttopup-report-daily.component';
import { AccountHolderReportComponent } from './component/handle-amount-report/handle-amount-report.component';
import { Twod50PercentReportComponent } from './component/twodbet50percentreport/twodbet50percentreport.component';
import { Twod35PercentReportComponent } from './component/twodbetreport35percent/twodbet35percentreport.component';

import { BlockBankAccountComponent } from './component/blockbankaccount/blockbankaccount.component';
import { BlockBankAccountDetailComponent } from './component/blockbankaccount-detail/blockbankaccount-detail.component';
import {TwoBetLimitReportComponent} from './component/twod-bet-amount-limit-report/twod-bet-amount-limit-report.component';

import {CustomerServiceComponent} from './component/customer-service/customer-service.component';
import {CustomerServiceDetailComponent} from './component/customer-service-detail/customer-service-detail.component';

import {SalePromoterComponent} from './component/sale-promoter/sale-promoter.component';
import {SalePromoterDetailComponent} from './component/sale-promoter-detail/sale-promoter-detail.component';
import {ThreedBetLimitReportComponent} from './component/threed-bet-amount-limit-report/threed-bet-amount-limit-report.component';
import {GameComponent} from './component/awc-game/game.component';
import {AWCGameDetailComponent} from './component/awc-game-detail/awc-game-detail.component';
import {DiscountComponent} from './component/discount-entry/discount.component';
import {DiscountDetailComponent} from './component/discount-detail/discount-detail.component';
import {GSGameComponent } from './component/gs-game/gs-game.component';

import { NgxPaginationModule } from 'ngx-pagination';
import { MDBBootstrapModule } from 'angular-bootstrap-md';
import { NgxBootstrapMultiselectModule } from 'ngx-bootstrap-multiselect';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { GSDetailGameComponent } from './component/gs-game-detail/gs-game-detail.component';

import { PromotionAdsComponent } from './component/promotion-ads/promotion-ads.component';
import { PromotionAdsDetailComponent } from './component/promotion-ads-detail/promotion-ads-detail.component';

import { AccountHolderBeforeCleanReportComponent } from './component/handel-amount-report-beforeclean/handle-amount-beforeclean-report.component';
import {GSGameProviderComponent} from './component/game-provider/game-provider.component';
import {GameProviderDetailComponent} from './component/game-provider-detail/provider-detail.component';
import {TransactionTutorialDetailDetailComponent} from './component/transaction-tutorial-detail/transaction-tutorial-detail.component';
import {GSGameAlertProviderComponent} from './component/game-alert/game-alert.component';
import {GameAlertDetailComponent} from './component/game-alert-detail/game-alert-detail.component';
import {DreamBookAddNewComponent} from './component/dream-book-addnew/dream-book-addnew.component';
import {DreamBookListComponent} from './component/dream-book-list/dream-book-list.component';
import {DreamBookUpdateComponent} from './component/dream-book-update/dream-book-update.component';
import {NeedHelpComponent} from './component/needhelp/need-help.component';
import {NeedHelpDetailComponent} from './component/needhelp-detail/need-help-detail.component';
import {WithdrawalBankAccReportComponent} from './component/withdrawal-bankacc-report/withdrawalbankacc-report.component';
import {MarqueeComponent} from './component/marquee/marquee.component';
import {MarqueeDetailComponent} from './component/marquee-component/marquee-component.component';
import {PointPromotionComponent } from './component/point-promotion/point-promotion.component';
import {PointPromotionDetailComponent } from './component/point-promotion-detail/point-promotion-detail.component';
import {TwodLiveResultComponent } from './component/twod-live-result/twod-live-result.component';
import { TwodLiveDetailComponent } from './component/twod-live-result-detail/twod-live-detail.component';
import { PointPromotionUsersComponent } from './component/point-promotion-users/point-promotion-users.component';


import { PointPromotionUserDetilsComponent } from './component/point-promotion-user-detils/point-promotion-user-detils.component';
import { PromotionConfirmedUsersComponent } from './component/promotion-confirmed-users/promotion-confirmed-users.component';
import { PromotionConfirmedUsersDetailComponent } from './component/promotion-confirmed-users-detail/promotion-confirmed-users-detail.component';
import { Version129PointUserListComponent } from './component/version129-point-user-list/version129-point-user-list.component';
import { Version129ConfirmedPointUserListComponent } from './component/version129-confirmed-point-user-list/version129-confirmed-point-user-list.component';
import { UserActivityReportComponent } from './component/user-activity-report/user-activity-report.component';
import { AdvertiseTeamListComponent } from './component/advertise-team-list/advertise-team-list.component';

import { AdvertiseTeamDetailComponent } from './component/advertise-team-detail/advertise-team-detail.component';
import { AdvertisingTeamAddComponent } from './component/advertising-team-add/advertising-team-add.component';
import { TwodWinnerCommentComponent } from './component/twod-winner-comment/twod-winner-comment.component';
import { TwodWinnerCommentDetailComponent } from './component/twod-winner-comment-detail/twod-winner-comment-detail.component';
import { ThreedWinnerCommentComponent } from './component/threed-winner-comment/threed-winner-comment.component';
import { FirstTopupComponent } from './component/first-topup/first-topup.component';
import { BanListComponent } from './component/ban-list/ban-list.component';
import { ThreedWinnerCommentDetailComponent } from './component/threed-winner-comment-detail/threed-winner-comment-detail.component';
import { BankSlipComponent } from './component/bank-slip/bank-slip.component';
import { BankSlipDetailComponent } from './component/bank-slip-detail/bank-slip-detail.component';
import { TwodPackageComponent } from './component/twod-package/twod-package.component';
import { TwodPackageDetailComponent } from './component/twod-package-detail/twod-package-detail.component';
import { TwodDreamBookComponent } from './component/twod-dream-book/twod-dream-book.component';
import { TwodDreamBookAddNewComponent } from './component/twod-dream-book-addnew/twod-dream-book-addnew.component';
import { TwodDreamBookUpdateComponent } from './component/twod-dream-book-update/twod-dream-book-update.component';
import { TwodSectionComponent } from './component/twod-section/twod-section.component';
import { TwodSectionDetailComponent } from './component/twod-section-detail/twod-section-detail.component';
import { BankMaintenanceTopupComponent } from './component/bank-maintenance-topup/bank-maintenance-topup.component';
import { BankMaintenanceTopupDetailComponent } from './component/bank-maintenance-topup-detail/bank-maintenance-topup-detail.component';
import { BankMaintenanceWithdrawComponent } from './component/bank-maintenance-withdraw/bank-maintenance-withdraw.component';
import { BankMaintenanceWithdrawDetailComponent } from './component/bank-maintenance-withdraw-detail/bank-maintenance-withdraw-detail.component';
import { ServicephoneSortComponent } from './component/servicephone-sort/servicephone-sort.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { GameProviderSortComponent } from './component/game-provider-sort/game-provider-sort.component';
import { TwoDManualDetailComponent } from './component/twod-manual-detail/twod-manual-detail.component';
import { TwoDManualOnOffComponent } from './component/twod-manual-onoff/twod-manual-onoff.component';
import { UserBetLimitComponent } from './component/user-bet-limit/user-bet-limit.component';
import { AdjustUserBalanceComponent } from './component/adjust-user-balance/adjust-user-balance.component';
import { UsersWithdrawalComponent } from './component/users-withdrawal/users-withdrawal.component';
import { UsersTopupComponent } from './component/users-topup/users-topup.component';
import { TopupWithdrawLogsComponent } from './component/topup-withdraw-logs/topup-withdraw-logs.component';
import {AutosizeModule} from '@techiediaries/ngx-textarea-autosize';
import { ConfigureUserBetlimitComponent } from './component/configure-user-betlimit/configure-user-betlimit.component';
import { ConfigureUser3DBetlimitComponent } from './component/configure-user-3Dbetlimit/configure-user-3Dbetlimit.component';
import { TwoDHistoryLogComponent } from './component/twoDHistoryLog/twoDHistoryLog.component';
import { GameMaintainAllComponent } from './component/game-maintain-all/game-maintain-all.component';
import { UserOtpsComponent } from './component/user-otps/user-otps.component';
import { ManageWalletsComponent } from './component/manage-wallets/manage-wallets.component';
import { UserGameWalletlogsComponent } from './component/user-game-walletlogs/user-game-walletlogs.component';
import { ManageWalletsProviderDetailComponent } from './component/manage-wallets-provider-detail/manage-wallets-provider-detail.component';
import { ModernInternetComponent } from './component/modern-internet/modern-internet.component';
import { FirstTopupPromotionUserReportComponent } from './component/first_topup_promotionuser_report/first_topup_promotionuser_report.component';
import { GameUsernameComponent } from './component/game-username/game-username.component';
import { WinnerInputComponent } from './component/winner-input/winner-input.component';
import { BlackListComponent } from './component/black-list/black-list.component';
//import { WinnerInputsComponent } from './component/winner-inputs/winner-inputs.component';
import { WinnerInputDetailComponent } from './component/winner-input-detail/winner-input-detail.component';
import { RiskyComponent } from './component/risky/risky.component';
import { RiskyAdminComponent } from './component/risky-admin/risky-admin.component';
import { RiskyHistoryComponent } from './component/risky-history/risky-history.component';
import { BankAccComponent } from './component/bankAcc/bankAcc.component';
import { SmsOperatorComponent } from './component/smsoperator/smsoperator.component';

import { LockgscapisComponent } from './component/lockgscapis/lockgscapis.component';
import { LockgscapisDetailComponent } from './component/lockgscapis-detail/lockgscapis-detail.component';
import { UserRoleComponent } from './component/user-role/user-role.component';
//import { PermessionAccessComponent } from './component/permession-access/permession-access.component';
import { PermissionAccessComponent } from './component/permission-access/permission-access.component';
import { BetHistoryComponent } from './component/bet-history/bet-history.component';
import { WinHistoryComponent } from './component/win-history/win-history.component';
import { DetailsHistoryComponent } from './component/details-history/details-history.component';
import { ResultHistoryComponent } from './component/result-history/result-history.component';
import { FeedBackHistoryComponent } from './component/feedback-history/feedback-history.component';
import { FeedBackHistoryCopyComponent } from './component/feedback-history-copy/feedback-history-copy.component';
import { FaqsHistoryComponent } from './component/faqs-history/faqs-history.component';
import { ConfigHistoryComponent } from './component/config-history/config-history.component';
import { OddsHistoryComponent } from './component/odds-history/odds-history.component';
import { ReportsHistoryComponent } from './component/reports-history/reports-history.component';
import { ProuserHistoryComponent } from './component/prouser-history/prouser-history.component';
// import { AdminDetailHistoryComponent } from './component/admin-detail-history/admin-detail-history.component';
import { DrmHistoryComponent } from './component/drm-history/drm-history.component';
// import { ManageGameWalletUserDetailComponent } from './component/manage-wallet-user-detail/manage-wallet-user-detail.component';
import { CommentssHistoryComponent } from './component/commentss-history/commentss-history.component';
import { WinLoseHistoryComponent } from './component/win-lose-history/win-lose-history.component';
import { PaymaintanceHistoryComponent } from './component/paymaintance-history/paymaintance-history.component';
// import { FirstTopupUserDetailComponent } from './component/first-topup-user-detail/first-topup-user-detail.component';


@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    PageNotfoundComponent,
    WelcomeComponent,
    DashboardComponent,
    HeaderSidebarComponent,
    FooterComponent,
    AdminComponent,
    AdminLogsComponent,
    GameTransactionComponent,
    GameTransactionsComponent,
    AdminDetailComponent,
    AdminDetailHistorysComponent,
    AgentComponent,
    AgentDetailComponent,
    UserComponent,
    SMKLockUserListComponent,
    UserActivInactiveComponent,
    UserInactiveComponent,
    UserInactiveByAdminComponent,
    UserInactiveByForgotComponent,
    SearchMemberComponent,
    // UserDetailActiveCancleComponent,
    // UserDetailInActiveCancleComponent,
    // UserDetailInActiveByForgotCancleComponent,
    UserDetailComponent,
    TwodComponent,
    TwodDetailComponent,
    ThreedComponent,
    ThreedDetailComponent,
    TopupCheckComponent,
    DetailReportComponent,
    TopupCheckDetailComponent,
    WithdrawCheckComponent,
    WithdrawCheckDetailComponent,
    TwodbetRecordComponent,
    TwodbetCancelRecordComponent,
    ThreedbetRecordComponent,
    ThreedbetCancelListRecordComponent,
    ChangepasswordComponent,
    ChangepasswordDetailComponent,
    ServicephoneComponent,
    ServicephoneDetailComponent,
    TwodBetAmountLimitationComponent,
    TwodBetAmountLimitationDetailComponent,
    TwodBetAmountLimitationDetailUpdateComponent,
    ThreedBetAmountLimitationComponent,
    ThreedBetAmountLimitationDetailComponent,
    ThreedBetAmountLimitationDetailUpdateComponent,

    PromotionAndNewsComponent,
    PromotionAndNewsDetailComponent,
    TopupCheckDetailUpdateComponent,
    TwodWinnersComponent,
    ThreedWinnersComponent,
     FeedbackComponent,
     FaqAddnewbackComponent,
    FaqTypebackComponent,
    FaqbackComponent,
    FaqStepbackComponent,
    FeedbackDetailComponent,
    HolidayComponent,
    HolidayDetailComponent,
    ThreedResultConfigurationComponent,
    ThreedResultConfigurationDetailComponent,
    PaymentComponent,
    PaymentDetailComponent,
    TwoReportComponent,
    ThreeDReportComponent,
    ThreeDDetailsReportComponent,
    TwodWinnerListReportComponent,
    TwoddetailWinnerListReportComponent,
    ThreedWinnerListReportComponent,
    ThreeddetailWinnerListReportComponent,
    UserStatementsReportComponent,
    NotificationsComponent,
    NotificationListComponent,
    OddEntryComponent,
    OddEntryDetailComponent,
    
    CommissionCalculationComponent,
    CommissionCalculationDetailComponent,
    CommissionConfrimComponent,
    //ConfirguationComponent,
    AgentWithdrawCheckComponent,
    AgentWithdrawCheckDetailComponent, 
    AgentWithdrawAddNewComponent,
    
    ConfirguationListComponent,
    TwoDDetailReportComponent,
    TwoDDetailsReportComponent,
    ThreeDDetailReportComponent,
    AdsComponent,
    AdsDetailComponent,
    PaymentInfoComponent,
    // BankAccountInfoComponent,
    PaymentDetailInfoComponent,  
    PaymentInfoDetailHistoryComponent,   
    PaymentInfoNewHistoryComponent, 
    FinancialReportComponent,
    FinancialDetailReportComponent,
    GameTransactionReportComponent,
    TransactionAllReportComponent,
    UserRegistrationComponent,
    TwoDWinLoseReportComponent,
    ThreeDWinLoseReportComponent,
    PromoterCommissionReportComponent,
    FirstTopupReportComponent,
    WinnerMonthlyReportComponent,
    FirstTopupReportDailyComponent,
    AccountHolderReportComponent,
    BlockBankAccountComponent,
    BlockBankAccountDetailComponent,
    Twod50PercentReportComponent,
    Twod35PercentReportComponent,
    TwoBetLimitReportComponent,
    MarqueeDetailComponent,
    CustomerServiceComponent,
    CustomerServiceDetailComponent,
    SalePromoterComponent,
    SalePromoterDetailComponent,
    ThreedBetLimitReportComponent,
    GameComponent,
    AWCGameDetailComponent,
    DiscountComponent,
    DiscountDetailComponent,
    GSGameComponent,
    GSDetailGameComponent,
    PromotionAdsComponent,
    PromotionAdsDetailComponent,
    AccountHolderBeforeCleanReportComponent,
    GSGameProviderComponent,
    GameProviderDetailComponent,
    TransactionTutorialDetailDetailComponent,
    GSGameAlertProviderComponent,
    GameAlertDetailComponent,
    DreamBookAddNewComponent,
    DreamBookListComponent,
    DreamBookUpdateComponent,
    NeedHelpComponent,
    NeedHelpDetailComponent,
    WithdrawalBankAccReportComponent,  
    MarqueeComponent,
    PointPromotionComponent,
    PointPromotionDetailComponent,
    TwodLiveResultComponent,
    TwodLiveDetailComponent,
    PointPromotionUsersComponent,  
    PointPromotionUserDetilsComponent,
    PromotionConfirmedUsersDetailComponent,
    PromotionConfirmedUsersComponent,
    Version129PointUserListComponent,
    Version129ConfirmedPointUserListComponent,
    UserActivityReportComponent,
    AdvertiseTeamListComponent,
    AdvertiseTeamDetailComponent,
    AdvertisingTeamAddComponent,
    TwodWinnerCommentComponent,
    TwodWinnerCommentDetailComponent,
    ThreedWinnerCommentComponent,
    FirstTopupComponent,
    BanListComponent,
    ThreedWinnerCommentDetailComponent,
    BankSlipComponent,
    BankSlipDetailComponent,
    TwodPackageComponent,
    TwodPackageDetailComponent,
    TwodDreamBookComponent,
    TwodDreamBookAddNewComponent,
    TwodDreamBookUpdateComponent,
    TwodSectionComponent,
    TwodSectionDetailComponent,
    BankMaintenanceTopupComponent,
    BankMaintenanceTopupDetailComponent,
    BankMaintenanceWithdrawComponent,
    BankMaintenanceWithdrawDetailComponent,
    ServicephoneSortComponent,
    GameProviderSortComponent,
    TwoDManualDetailComponent,
    TwoDManualOnOffComponent,
    UserBetLimitComponent,
    AdjustUserBalanceComponent,
    UsersWithdrawalComponent,
    UsersTopupComponent,
    TopupWithdrawLogsComponent,
    ConfigureUserBetlimitComponent,
    ConfigureUser3DBetlimitComponent,
    TwoDHistoryLogComponent,
    GameMaintainAllComponent,
    UserOtpsComponent,
    ManageWalletsComponent,
    UserGameWalletlogsComponent,
    ManageWalletsProviderDetailComponent,
    ModernInternetComponent,
    FirstTopupPromotionUserReportComponent,
    GameUsernameComponent,
    WinnerInputComponent,
    BlackListComponent,
    //WinnerInputsComponent,
    WinnerInputDetailComponent,
    RiskyComponent,
    RiskyAdminComponent,
    RiskyHistoryComponent,
    BankAccComponent,
    SmsOperatorComponent,
    LockgscapisComponent,
    LockgscapisDetailComponent,
    UserRoleComponent,
    //PermessionAccessComponent,
    PermissionAccessComponent,
    BetHistoryComponent,
  
    WinHistoryComponent,
    ResultHistoryComponent,
    FeedBackHistoryComponent,
    FeedBackHistoryCopyComponent,
    FaqsHistoryComponent,
    ConfigHistoryComponent,
    OddsHistoryComponent,
    ReportsHistoryComponent,
    DrmHistoryComponent,
    ProuserHistoryComponent,
    // AdminDetailHistoryComponent,
    // ManageGameWalletUserDetailComponent,
    //UserRegisterationComponent,
    UserDetailInactiveComponent,
    UserDetailActiveComponent,
    CommentssHistoryComponent,
    WinLoseHistoryComponent,
    PaymaintanceHistoryComponent,
    // SearchMemberDetailComponent,
    DetailsHistoryComponent,  
    // UserOtpDetailComponent,
    // UserDetailInActiveByAdminCancleComponent,
    // UserForgotPasswordDetailComponent,
    // ManageTopupCancelComponent,
    // ManageWithdrawCancelComponent,
    // ManageWalletLogCancelComponent,
    // TopupCancelDetailComponent,
    // WithdrawCancelDetailComponent,
    // GameTransactionCashDetailCancelComponent,
    // WinnerConfirmCancelDetailComponent,
    ThreeDLiveListComponent,
    TransactionTutorialListComponent

  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot(),
    NgxSpinnerModule,
    HttpClientModule,
    NgxWebstorageModule.forRoot(),
    DataTablesModule,
    NgxDatePickerModule,
    NgxPaginationModule,
    MDBBootstrapModule.forRoot(),
    NgxBootstrapMultiselectModule,
    NgMultiSelectDropDownModule.forRoot(),
    AutosizeModule,
    DragDropModule
  ],
  providers: [
    DtoService,
    UtilService,
    FunctService,
    DatePipe,
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
