<div class="wrapper">
    <!-- Navbar -->
    <app-header-sidebar></app-header-sidebar>

    <!-- here here here -->
    <!-- Content Wrapper. Contains page content -->
    <div class="content-wrapper">
        <!-- Content Header (Page header) -->
        <div class="content-header">
            <div class="container-fluid">
            </div><!-- /.container-fluid -->
        </div>
        <!-- /.content-header -->

        <!-- Main content -->
        <section class="content">
            <div class="container-fluid">
                <form>
                    <div class="row">
                        <div class="col-md-12">
                            <div class="card">
                                <div class="card-header">
                                    <h3 class="card-title" style="font-weight: bold;"> Change Password Detail </h3>
                                </div>
                                <!-- /.card-header -->
                                <div class="card-body">
                                    <form>

                                        <!--  <div class="row">
                                            <label class="col-md-2">Old Password</label>
                                            <div class="form-group col-md-3">
                                                <input type="password" class="form-control form-control-sm" [(ngModel)]="adminDTO.oldPassword" [ngModelOptions]="{standalone: true}">
                                            </div>
                                        </div> -->

                                        <div class="row">
                                            <label class="col-md-2"> Old Password </label>
                                            <div class="input-group form-group col-md-3">
                                                <input class="form-control form-control-sm"
                                                    [(ngModel)]="adminDTO.oldPassword"
                                                    [ngModelOptions]="{standalone: true}"
                                                    [type]="oldFieldTextType ? 'text' : 'password'">
                                                <div class="input-group-append">
                                                    <span class="input-group-text">
                                                        <i class="fa"
                                                            [ngClass]="{'fa-eye-slash': !oldFieldTextType,'fa-eye': oldFieldTextType}"
                                                            (click)="toggleOldFieldTextType()" style="color: #007bff;"></i>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>


                                        <!-- <div class="row">
                                            <label class="col-md-2">New Password</label>
                                            <div class="form-group col-md-3">
                                                <input type="password" class="form-control form-control-sm"
                                                    [(ngModel)]="adminDTO.newPassword"
                                                    [ngModelOptions]="{standalone: true}">
                                            </div>
                                        </div> -->

                                        <div class="row">
                                            <label class="col-md-2">New Password</label>
                                            <div class="input-group form-group col-md-3">
                                                <input (keyup) ="StrengthChecker()" class="form-control form-control-sm passwordInput"
                                                    [(ngModel)]="adminDTO.newPassword"
                                                    [ngModelOptions]="{standalone: true}"
                                                    [type]="newFieldTextType ? 'text' : 'password'">
                                                <div class="input-group-append">
                                                    <span class="input-group-text">
                                                        <i class="fa"
                                                            [ngClass]="{'fa-eye-slash': !newFieldTextType,'fa-eye': newFieldTextType}"
                                                            (click)="toggleNewFieldTextType()" style="color: #007bff;"></i>
                                                    </span>
                                                </div>
                                                <span id="StrengthDisp" class="badge displayBadge">Weak</span>
                                            </div>
                                        </div>
<!-- 
                                        <div class="row">
                                            <label class="col-md-2">Confirm Password</label>
                                            <div class="form-group col-md-3">
                                                <input type="password" class="form-control form-control-sm"
                                                    [(ngModel)]="adminDTO.confirmPassword"
                                                    [ngModelOptions]="{standalone: true}">
                                            </div>
                                        </div> -->

                                        <div class="row">
                                            <label class="col-md-2">Confirm Password</label>
                                            <div class="input-group form-group col-md-3">
                                                <input class="form-control form-control-sm"
                                                    [(ngModel)]="adminDTO.confirmPassword"
                                                    [ngModelOptions]="{standalone: true}"
                                                    [type]="confrimFieldTextType ? 'text' : 'password'">
                                                <div class="input-group-append">
                                                    <span class="input-group-text">
                                                        <i class="fa" [ngClass]="{'fa-eye-slash': !confrimFieldTextType,'fa-eye': confrimFieldTextType}"
                                                            (click)="toggleConfrimFieldTextType()" style="color: #007bff;"></i>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="row" style="margin-top: 1%;">
                                            <div class="col-md-2 col-sm-6">
                                                <button type="button" class="btn btn-block btn-success"
                                                    (click)="goSave()">
                                                    Save
                                                </button> &nbsp;
                                            </div>
                                            <div class="col-md-2 col-sm-6">
                                                <button type="button" class="btn btn-block btn-danger"
                                                    [routerLink]="['/changepassword-list']">
                                                    Cancel
                                                </button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                                <!-- /.card-body -->
                            </div>
                        </div>
                    </div>
                </form>
                <!-- /.row -->
                <!-- /.row -->
            </div>
            <!--/. container-fluid -->
        </section>
        <!-- /.content -->
    </div>

    <aside class="control-sidebar control-sidebar-dark">
        <!-- Control sidebar content goes here -->
    </aside>

    <app-footer></app-footer>

    <ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#fff" type="square-jelly-box">
        <p style="font-size: 16px; color: white">Loading...</p>
    </ngx-spinner>

</div>


<div id="deleteData" class="modal fade">
    <div class="modal-dialog">
        <div class="modal-content">
  
            <div class="modal-body">
                <form>
  
                    <div class="row" style="margin-top: 5%;margin-bottom: 3%;margin-left: 9%;">
                        <div class="form-group col-md-12">
                            <h5> This Account is used by another device. 
                                Please Sign Out. Login Again. </h5>
                        </div>
                    </div>
  
                    <div class="row" style="margin-left: 40%;margin-bottom: 3%;">
                        <div class="col-md-5 col-sm-6" id="deleteBtn">
                            <button type="button" class="btn btn-block btn-success" (click)="OkLogout()"> Ok </button>
                        </div>
                    </div>
                </form>
            </div>
  
        </div>
    </div>
  </div>