
import { Component, OnInit, ViewChild } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,HttpErrorResponse } from '@angular/common/http';
import { Router, NavigationEnd,ActivatedRoute } from '@angular/router';
import { DataTableDirective } from 'angular-datatables';
import { catchError, retry } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { LocalStorageService } from 'ngx-webstorage';
import { NgxSpinnerService } from "ngx-spinner";
import { FunctService } from '../../service/funct.service';
import { DtoService } from '../../service/dto.service';
import { DatePipe } from '@angular/common';
import { Subject } from 'rxjs';
declare var $: any;
@Component({
  selector: 'app-manage-wallets',
  templateUrl: './manage-wallets.component.html',
  styleUrls: ['./manage-wallets.component.css']
})
export class ManageWalletsComponent implements OnInit {
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();
  phoneNo: string= '';
  name: string= '';
  userList: any;
  idIndex: any;
  itemsPerPage =  10;
  page = 1;
  totalItems : any; 
  
  hardcode_edit :any;
  hardcode_add :any;
  hardcode_view: any;
  hardcode_view_bool : any; 
  hardcode_edit_bool : any;

  constructor(private storage: LocalStorageService,private datepipe: DatePipe, private toastr: ToastrService,private spinner: NgxSpinnerService, private http: HttpClient, private dto: DtoService, private router: Router, 
    private route: ActivatedRoute, private funct: FunctService) {
      this.idIndex = 1;
      this.storage.store('isNotiSong', "");
    this.hardcode_view = "UserGameBalance_View";
    this.hardcode_edit = "UserGameBalance_Edit";
    this.dto.menuCodes = this.storage.retrieve('menucodes');

    const filteredMenuCodes_view = [];    
    const filteredMenuCodeds_edit =[];
    this.dto.menuCodes.forEach(menuCode => {
      if (menuCode === this.hardcode_view) {
        filteredMenuCodes_view.push(menuCode);
      }     
      if (menuCode === this.hardcode_edit) {
        filteredMenuCodeds_edit.push(menuCode);
      }
    });
        if(filteredMenuCodes_view.length != 0)
        {
              this.hardcode_view_bool = true;
        }       
        if(filteredMenuCodeds_edit.length != 0)
        {
              this.hardcode_edit_bool = true;
        }

      this.Search();
    }

  ngOnInit(): void {
    this.dtOptions = {
      order:[],
      responsive:true,
      paging: false,
      info : true,
      dom: "Bfrltip"
      }
    this.dtOptions.columnDefs = [
      { targets: [0], orderable: true }
    ];
    if(!this.storage.retrieve('loadFlag')){
      this.storage.store('loadFlag', 'noLoad');
      setTimeout(function(){
        location.reload();
      }, 5);
    }
    else{
      this.storage.clear('loadFlag');
    }
  }

  handleError(error: HttpErrorResponse){
    if(error.status == 423)
    {
      
    }
    if(error.status == 403)
    {
      this.spinner.hide();
      var id = 'tbuser' + this.idIndex;
      var table = $('#' + id).DataTable();
      this.toastr.error("Limited Access.", 'Invalid!', {
        timeOut: 3000,
        positionClass: 'toast-top-right',
      });
    }
    return throwError(error);
  }

  OkLogout()
  {
    window.location.href ="./ad-login";
  }

  Search() {
    this.userList = [];
    var id = 'tbuser' + this.idIndex;
    var table = $('#' + id).DataTable();
    table.destroy();
    this.idIndex = this.idIndex +1;
    this.spinner.show();
    this.dto.token = this.storage.retrieve('token');
    let headers = new HttpHeaders();
    headers = headers.set('Authorization',  this.dto.token);
    let params = new HttpParams();     
    params = params.set('phoneNo',this.phoneNo).set('name',this.name).set("pageNumber","1").set("rowsOfPage","10");
    this.http.get(this.funct.ipaddress + 'manageWallets/GetUserListsForManageWallets', { params: params, headers: headers } )
    .pipe(
      catchError(this.handleError.bind(this))
    )
    .subscribe((data: any) => {
      this.dto.Response = {};
      this.dto.Response = data.results;
      this.userList = this.dto.Response;
      this.totalItems = data.totalRows;
      this.dtTrigger.next();
      this.spinner.hide();
    })
  }
  gty(page: any){
    this.userList = [];
    var id = 'tbuser' + this.idIndex;
    var table = $('#' + id).DataTable();   
    table.destroy();
    this.idIndex = this.idIndex +1;
    this.spinner.show();
    this.dto.token = this.storage.retrieve('token');
    let headers = new HttpHeaders();
    headers = headers.set('Authorization',  this.dto.token);
    let params = new HttpParams();  
    params = params.set('phoneNo',this.phoneNo).set('name',this.name).set("pageNumber",page).set("rowsOfPage",this.itemsPerPage.toString()); 
    
    this.http.get(this.funct.ipaddress + 'manageWallets/GetUserListsForManageWallets', { params: params, headers: headers } )
    .pipe(
      catchError(this.handleError.bind(this))
    )
    .subscribe((data: any) => {
      this.dto.Response = {};
      this.dto.Response = data.results;
      this.userList = this.dto.Response;
      this.totalItems = data.totalRows;
      this.dtTrigger.next();
      this.spinner.hide();
    })
  }
  onInput(event: InputEvent) {
    const myanmarCharactersRegex = new RegExp('^[\u1000-\u109F\uAA60-\uAA7F\uA9E0-\uA9FF]+$');
    const inputValue = (event.target as HTMLInputElement).value; 
    if(event.data!=null)
    {
      if (myanmarCharactersRegex.test(inputValue)) 
      {        
        this.spinner.hide();
        this.toastr.error( "Myanmar typing detected!", '', {
        timeOut: 5000,
        positionClass: 'toast-top-right',
        });  
      }
    }   
  }

  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }
}
