<div class="wrapper">
  <app-header-sidebar></app-header-sidebar>

  <div class="content-wrapper">
    
    <div class="content-header">
      <div class="container-fluid">
        <div class="row mb-2" style="border: 1px solid #ced4da;border-radius: 0.25rem;">
          <div class="col-sm-6">
            <h3 class="m-0 text-dark" style="font-weight: bold;padding: 3px 0px 3px 5px;"> 2D/3D Winner </h3>
          </div>
        </div>
       
      </div>
    </div>
   
    <section class="content">
      <div class="container-fluid">
        <form>
          <div class="row">

            <div class="col-md-12">
              
              <ul class="nav nav-tabs nav-pills">
                <li>
                  <a  id="twoD-tab" data-toggle="tab" href="#twoD"> 2D </a>
                </li>
                <li (click)="TDWLSearchTabClick()">
                  <a id="twoDWinnerList-tab" data-toggle="tab" href="#twoDWinnerList"> 2D Winner List </a>
                </li>
                <li  (click)="threeDOddSearch()">
                  <a   id="threeD-tab" data-toggle="tab" href="#threeD"> 3D </a>
                </li>
                <li (click)="ThDWLSearchTabClick()">
                  <a id="threeDWinnerList-tab" data-toggle="tab" href="#threeDWinnerList"> 3D Winner List </a>
                </li>
              </ul>

              
              <div class="tab-content clearfix">

                <div class="tab-pane" id="twoD">
                  <div class="row">

                    <div class="form-group col-md-2">
                      <label>Choose Section</label>
                      <select class="form-control form-control-sm col-md-12 col-sm-4 col-xs-4"
                          [(ngModel)]="section" [ngModelOptions]="{standalone: true}" (ngModelChange) = "twoDOddSearch()">
                          <option value="10:30 AM">10:30 AM</option>
                          <option value="12:01 PM">12:01 PM</option>
                          <option value="02:30 PM">02:30 PM</option>
                          <option value="04:30 PM">04:30 PM</option>
                      </select>                      
                    </div>
                    <div class="form-group col-md-8" style="margin-top: 2%;">                                      
                      <span style="color: red;"><b>***(can save maximum 10 users for each time)</b></span>
                    </div>

                    <div class="form-group col-md-2 col-sm-4 col-xs-4" style="margin-top: 2%;" *ngIf="hardcode_add_bool === true">                      
                      <button id="add" type="button" class="btn btn-block btn-primary btn-sm" 
                        (click)="addBtn()"><i class="fa fa-plus"></i></button>
                    </div>                
                  </div> <!--row-->

                    <div class="col-md-12">
                      <div class="card">                      
                        <div class="card-body">
                          <table width='100%' id="{{'tbltwoD' + idIndex}}" datatable [dtTrigger]="dtTrigger"
                            [dtOptions]="dtOptions" class="table table-bordered table-hover">
                            <thead>
                              <tr>
                                <th style="font-family: unicode;">No</th>
                                <th style="font-family: unicode;">Name</th>
                                <th style="font-family: unicode;">Phone Number</th>
                                <th style="font-family: unicode;">Profile Image</th>
                                <th style="font-family: unicode;">Bet Amount</th>                        
                                <th style="font-family: unicode;">Status</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr *ngFor="let w of twoDWinnerInputList; let i = index">
                                <td style="font-family: unicode;">{{i +1}}</td>
                                <td style="font-family: unicode;"><input type="text"  class="form-control form-control-sm" id = "name{{i}}" >
                                </td>
                                <td style="font-family: unicode;"><input type="text"  class="form-control form-control-sm" id = "phoneNumber{{i}}" >
                                </td>                          


                                  <td style="font-family: unicode;">
                                    <div class="form-group col-md-3">
                                      <input #file type="file" accept='image/*' (change)="preview(file.files, i)" />
                                      <img [src]="imgURL[i]" height="200" width="255" *ngIf="imgURL" style="margin-top: 5%;">
                                    </div>
                                  </td>                                  
                               

                                
                                <td style="font-family: unicode;"><input type="number"  class="form-control form-control-sm" id = "betAmount{{i}}" (keyup) = "calculatedWinAmount(i)" required  >
                                </td>
                             
                                <td style="font-family: unicode;">
                                  <div class="icheck-success ">
                                    <input type="radio" name="r{{i}}" checked id="Active{{i}}" value="Active">
                                    <label for="Active{{i}}">Active</label>
                                  </div>
                                  <div class="icheck-success ">
                                    <input type="radio" name="r{{i}}"  id="Inactive{{i}}" value="Inactive">
                                    <label for="Inactive{{i}}">Inactive</label>
                                  </div>                                
                                </td>
                              </tr>
                            </tbody>
                          </table>

                          <div class="row" style="margin-top: 1%;">
                            <div class="col-md-2 col-sm-6" *ngIf="hardcode_add_bool === true">
                                <button type="button" class="btn btn-block btn-success" (click)="save()">Save</button>
                            </div>
                            <div class="col-md-2 col-sm-6">
                                <button type="button" class="btn btn-block btn-danger" (click)="cancel()">Cancel</button>
                            </div>
                           </div>

                        </div>
                      </div>
                    </div>
                  <!--</div>-->
                </div>



                <div class="tab-pane" id="threeD">
                  <div class="row">

                    <!-- <div class="form-group col-md-2">
                      <label>Choose Section</label>
                      <select class="form-control form-control-sm col-md-12 col-sm-4 col-xs-4"
                          [(ngModel)]="section" [ngModelOptions]="{standalone: true}" (ngModelChange) = "twoDOddSearch()">
                          <option value="10:30 AM">10:30 AM</option>
                          <option value="12:01 PM">12:01 PM</option>
                          <option value="02:30 PM">02:30 PM</option>
                          <option value="04:30 PM">04:30 PM</option>
                      </select>                      
                    </div> -->
                    <div class="form-group col-md-8" style="margin-top: 2%;">                                      
                      <span style="color: red;"><b>***(can save maximum 10 users for each time)</b></span>
                    </div>



                    <div class="form-group col-md-2 col-sm-4 col-xs-4" style="margin-top: 2%;" *ngIf="hardcode_add_bool === true">                      
                      <button id="add3D" type="button" class="btn btn-block btn-primary btn-sm" 
                        (click)="threeDAddBtn()"><i class="fa fa-plus"></i></button>
                    </div>                
                  </div>

                    <div class="col-md-12">
                      <div class="card">                      
                        <div class="card-body">
                          <table width='100%' id="{{'tblthreeD' + idIndex}}" datatable [dtTrigger]="dtTrigger"
                            [dtOptions]="dtOptions" class="table table-bordered table-hover">
                            <thead>
                              <tr>
                                <th style="font-family: unicode;">No</th>
                                <th style="font-family: unicode;">Name</th>
                                <th style="font-family: unicode;">Phone Number</th>
                                <th style="font-family: unicode;">Profile Image</th>
                                <th style="font-family: unicode;">Bet Amount</th>   
                                <th style="font-family: unicode;">Odd</th>  
                                <th style="font-family: unicode;">Win Amount</th>                      
                                <th style="font-family: unicode;">Status</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr *ngFor="let w of threeDWinnerInputList; let i = index">
                                <td style="font-family: unicode;">{{i +1}}</td>
                                <td style="font-family: unicode;"><input type="text"  class="form-control form-control-sm" id = "threeDName{{i}}" >
                                </td>
                                <td style="font-family: unicode;"><input type="text"  class="form-control form-control-sm" id = "threeDPhoneNumber{{i}}" >
                                </td>                       
                                  <td style="font-family: unicode;">
                                    <div class="form-group col-md-3">
                                      <input #file type="file" accept='image/*' (change)="preview(file.files, i)" />
                                      <img [src]="imgURL[i]" height="200" width="255" *ngIf="imgURL" style="margin-top: 5%;">
                                    </div>
                                  </td>                                
                                <td style="font-family: unicode;"><input type="number"  class="form-control form-control-sm" id = "threeDBetAmount{{i}}" (keyup) = "calculatedWinAmount(i)" required  >
                                </td>
<!-- 
                                <td style="font-family: unicode;"><input type="number"  class="form-control form-control-sm" id = "ThreeDOdd{{i}}" (keyup) = "calculatedWinAmount(i)" required  >
                                </td> -->

                                <td style="font-family: unicode;">
                                  <input type="number" class="form-control form-control-sm" id="ThreeDOdd{{i}}" (keyup)="calculatedWinAmount(i)" required value="700">
                              </td>
                              

                                <td style="font-family: unicode;"><input type="number"  class="form-control form-control-sm" id = "threeDWinAmount{{i}}" (keyup) = "calculatedWinAmount(i)" required  >
                                </td>
                             
                                <td style="font-family: unicode;">
                                  <div class="icheck-success">
                                    <input type="radio" name="rthreeD{{i}}" checked id="Active3D{{i}}" value="Active">
                                    <label for="Active3D{{i}}">Active</label>
                                  </div>
                                  <div class="icheck-success">
                                    <input type="radio" name="rthreeD{{i}}"  id="Inactive3D{{i}}" value="Inactive">
                                    <label for="Inactive3D{{i}}">Inactive</label>
                                  </div>                                
                                </td>
                              </tr>
                            </tbody>
                          </table>

                          <div class="row" style="margin-top: 1%;">
                            <div class="col-md-2 col-sm-6" *ngIf="hardcode_add_bool === true">
                                <button type="button" class="btn btn-block btn-success" (click)="threeDSave()">Save</button>
                            </div>
                            <div class="col-md-2 col-sm-6">
                                <button type="button" class="btn btn-block btn-danger" (click)="threeDCancel()">Cancel</button>
                            </div>
                           </div>

                        </div>
                      </div>
                    </div>
                 
                </div>




                

                <div class="tab-pane" id="twoDWinnerList">
                  <div class="row">
                    <div class="form-group col-md-2">
                      <!-- <label>Phone No</label>
                      <input type="text"  class="form-control form-control-sm" [(ngModel)]="TDWLPhoneNo"
                        [ngModelOptions]="{standalone: true}"> -->

                        <label>Name</label>
                      <input type="text"  class="form-control form-control-sm" [(ngModel)]="TDWLName"
                        [ngModelOptions]="{standalone: true}">
                    </div>

                    <div class="form-group col-md-2">
                      <label>Bet Amount</label>
                      <input type="text"  class="form-control form-control-sm" [(ngModel)]="TDWLBetAmount"
                        [ngModelOptions]="{standalone: true}">
                    </div>

                    <div class="form-group col-md-2">
                      <label>Section</label>
                      <select class="form-control form-control-sm col-md-12 col-sm-4 col-xs-4"
                          [(ngModel)]="TDWLsection" [ngModelOptions]="{standalone: true}"  (ngModelChange) = "onChangeTDWLTagSection()">
                          <option value="10:30 AM">10:30 AM</option>
                          <option value="12:01 PM">12:01 PM</option>
                          <option value="02:30 PM">02:30 PM</option>
                          <option value="04:30 PM">04:30 PM</option>
                      </select>                      
                    </div>

                    <div class="form-group col-md-2">
                      <label>Status</label>
                      <select class="form-control form-control-sm col-md-12 col-sm-4 col-xs-4"
                          [(ngModel)]="TDWLStatus" [ngModelOptions]="{standalone: true}">
                          <option value="Active">Active</option>
                          <option value="Inactive">Inactive</option>                        
                      </select>                      
                    </div>

                    <div class="form-group col-md-2 col-sm-4 col-xs-4">
                      <label></label>
                      <button id="search" type="button" class="btn btn-block btn-primary btn-sm" style="margin-top: 4%;"
                        (click)="TDWLSearch()">Search</button>
                    </div>

                    <div class="col-md-12">
                      <div class="card">
                        <div class="card-header">
                          <h3 class="card-title" style="font-weight: bold;">2D Winner List</h3>
                        </div>
                        <div class="card-body">
                          <table width='100%' id="{{'tblTDWL' + idIndexTDWL}}" datatable
                            [dtTrigger]="dtTrigger1" [dtOptions]="dtOptions1" class="table table-bordered table-hover">
                            <thead>
                              <tr>
                                <th style="font-family: unicode;">Name</th>
                                <th style="font-family: unicode;">Phone No</th>
                                <th style="font-family: unicode;">Bet Amount</th>
                               
                                <th style="font-family: unicode;">Section</th>
                                <th style="font-family: unicode;">Status</th>
                                <th style="font-family: unicode;">Created Date</th>
                                <th style="font-family: unicode;">Created By</th>
                                <th style="font-family: unicode;">Updated Date</th>
                                <th style="font-family: unicode;">Updated By</th>                       
                                <th style="font-family: unicode;" *ngIf="hardcode_edit_bool === true">Action</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr *ngFor="let tdwl of TDWList">
                                <td style="font-family: unicode;">{{tdwl.name}}</td>
                                <td style="font-family: unicode;">{{tdwl.phoneNo}}</td>
                                <td style="font-family: unicode;">{{tdwl.betAmount}}</td>
                              
                                <td style="font-family: unicode;">{{tdwl.section}}</td>
                                <td style="font-family: unicode;">{{tdwl.status}}</td>
                                <td style="font-family: unicode;">{{tdwl.createdDate_Str}}</td>
                                <td style="font-family: unicode;">{{tdwl.createdBy}}</td>
                                <td style="font-family: unicode;">{{tdwl.updatedDate_Str}}</td>
                                <td style="font-family: unicode;">{{tdwl.updatedBy}}</td>                             
                                <td style="font-family: unicode;" *ngIf="hardcode_edit_bool === true">
                                  <a [routerLink]="['/winner-input-detail','2D', tdwl.id]">
                                    <i class="fas fa-edit" style="color:#4b88eb"></i>
                                  </a>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                          <tr *ngFor="let tdwl of TDWList | paginate:  { itemsPerPage: itemsPerPage,  currentPage: page, totalItems: totalItems, id:'pagi1'}" ></tr> 
                          <div class = "row">
                            <div class = "col-md-6"></div>
                            <div class = "col-md-6">
                              <pagination-controls id="pagi1" (pageChange)="gty((page = $event))"></pagination-controls>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                  </div>
                </div>

                <div class="tab-pane" id="threeDWinnerList">
                  <div class="row">
                    <div class="form-group col-md-2">
                      <!-- <label>Phone No</label>
                      <input type="text"  class="form-control form-control-sm" [(ngModel)]="ThDWLPhoneNo"
                        [ngModelOptions]="{standalone: true}"> -->

                        <label>Name</label>
                        <input type="text"  class="form-control form-control-sm" [(ngModel)]="ThDWLName"
                          [ngModelOptions]="{standalone: true}">

                    </div>

                    <div class="form-group col-md-2">
                      <label>Bet Amount</label>
                      <input type="text"  class="form-control form-control-sm" [(ngModel)]="ThDWLBetAmount"
                        [ngModelOptions]="{standalone: true}">
                    </div>
                    
                    <div class="form-group col-md-2">
                      <label>Win Amount</label>
                      <input type="text"  class="form-control form-control-sm" [(ngModel)]="ThDWLWinAmount"
                        [ngModelOptions]="{standalone: true}">
                    </div>

                    <div class="form-group col-md-2">
                      <label>Status</label>
                      <select class="form-control form-control-sm col-md-12 col-sm-4 col-xs-4"
                          [(ngModel)]="ThDWLStatus" [ngModelOptions]="{standalone: true}">
                          <option value="Active">Active</option>
                          <option value="Inactive">Inactive</option>                        
                      </select>                      
                    </div>

                    <div class="form-group col-md-2 col-sm-4 col-xs-4">
                      <label></label>
                      <button id="Thsearch" type="button" class="btn btn-block btn-primary btn-sm" style="margin-top: 4%;"
                        (click)="ThDWLSearch()">Search</button>
                    </div>

                    <div class="col-md-12">
                      <div class="card">
                        <div class="card-header">
                          <h3 class="card-title" style="font-weight: bold;">3D Winner List</h3>
                        </div>
                        <div class="card-body">
                          <table width='100%' id="{{'tblThDWL' + idIndexThDWL}}" datatable
                            [dtTrigger]="dtTrigger3" [dtOptions]="dtOptions3" class="table table-bordered table-hover">
                            <thead>
                              <tr>
                                <th style="font-family: unicode;">Name</th>
                                <th style="font-family: unicode;">Phone No</th>
                                <th style="font-family: unicode;">Bet Amount</th>
                                <th style="font-family: unicode;">Odd</th>
                                <th style="font-family: unicode;">Win Amount</th>                             
                                <th style="font-family: unicode;">Status</th>
                                <th style="font-family: unicode;">Created Date</th>
                                <th style="font-family: unicode;">Created By</th>
                                <th style="font-family: unicode;">Updated Date</th>
                                <th style="font-family: unicode;">Updated By</th>                       
                                <th style="font-family: unicode;" *ngIf="hardcode_edit_bool === true">Action</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr *ngFor="let thdwl of ThDWList">
                                <td style="font-family: unicode;">{{thdwl.name}}</td>
                                <td style="font-family: unicode;">{{thdwl.phoneNo}}</td>
                                <td style="font-family: unicode;">{{thdwl.betAmount}}</td>
                                <td style="font-family: unicode;">{{thdwl.odd}}</td>
                                <td style="font-family: unicode;">{{thdwl.winAmount}}</td>                          
                                <td style="font-family: unicode;">{{thdwl.status}}</td>
                                <td style="font-family: unicode;">{{thdwl.createdDate_Str}}</td>
                                <td style="font-family: unicode;">{{thdwl.createdBy}}</td>
                                <td style="font-family: unicode;">{{thdwl.updatedDate_Str}}</td>
                                <td style="font-family: unicode;">{{thdwl.updatedBy}}</td>                             
                                <td style="font-family: unicode;" *ngIf="hardcode_edit_bool === true">
                                  <a [routerLink]="['/winner-input-detail','3D', thdwl.id]">
                                    <i class="fas fa-edit" style="color:#4b88eb"></i>
                                  </a>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                          <tr *ngFor="let thdwl of ThDWList | paginate:  { itemsPerPage: itemsPerPage1,  currentPage: page1, totalItems: totalItems1, id:'pagi2'}" ></tr> 
                          <div class = "row">
                            <div class = "col-md-6"></div>
                            <div class = "col-md-6">
                              <pagination-controls id="pagi2" (pageChange)="gty1((page1 = $event))"></pagination-controls>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                  </div>
                </div>
              </div>
            </div>

          </div>
        </form>
        <!-- /.row -->

        <!-- /.row -->
      </div>
      <!--/. container-fluid -->
    </section>
    <!-- /.content -->
  </div>

  <aside class="control-sidebar control-sidebar-dark">
    <!-- Control sidebar content goes here -->
  </aside>

  <app-footer></app-footer>
  <ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#fff" type="square-jelly-box">
    <p style="font-size: 16px; color: white">Loading...</p>
  </ngx-spinner>
</div>

<div id="deleteData" class="modal fade">
  <div class="modal-dialog">
      <div class="modal-content">

          <div class="modal-body">
              <form>

                  <div class="row" style="margin-top: 5%;margin-bottom: 3%;margin-left: 9%;">
                      <div class="form-group col-md-12">
                          <h5> This Account is used by another device. 
                              Please Sign Out. Login Again. </h5>
                      </div>
                  </div>

                  <div class="row" style="margin-left: 40%;margin-bottom: 3%;">
                      <div class="col-md-5 col-sm-6" id="deleteBtn">
                          <button type="button" class="btn btn-block btn-success" (click)="OkLogout()"> Ok </button>
                      </div>
                  </div>
              </form>
          </div>

      </div>
  </div>
</div>

