<div class="wrapper">
    <app-header-sidebar></app-header-sidebar>
    
    <div class="content-wrapper">

        <div class="content-header">
          <div class="container-fluid">
            <div class="row mb-2" style="border: 1px solid #ced4da;border-radius: 0.25rem;">
              <div class="col-sm-8">
                <h3 class="m-0 text-dark" style="font-weight: bold;padding: 3px 0px 3px 5px;"> Manage Game Wallets </h3>
              </div>
            </div>
          </div>
        </div>
 
        <section class="content">
          <div class="container-fluid">
            <form>
              <div class="row">

                <div class="form-group col-md-2">
                  <label>Name</label>
                  <input class="form-control form-control-sm" [(ngModel)]="name" [ngModelOptions]="{standalone: true}">
                </div>

                <div class="form-group col-md-2">
                  <label>Phone No</label>
                  <input class="form-control form-control-sm" [(ngModel)]="phoneNo" [ngModelOptions]="{standalone: true}" (input)="onInput($event)">
                </div>

                <div class="form-group col-md-2 col-sm-2 col-xs-2">
                  <label>&nbsp;&nbsp;</label>
                  <button id="search" type="button" class="btn btn-block btn-primary btn-sm" (click)="Search()">Search</button>
                </div>
         
              </div>

             
                <div class="col-md-12">
                  <div class="card">
                    <div class="card-header">
                       <h3 class="card-title" style="font-weight: bold;"> User Lists </h3>
                    </div>
               
                    <div class="card-body">
                      <table width='100%' id="{{'tbuser' + idIndex}}" datatable [dtTrigger]="dtTrigger" [dtOptions]="dtOptions" class="table table-bordered table-hover">
                        <thead>
                        <tr>
                          <th style="font-family: unicode;">Name</th>
                          <th style="font-family: unicode;">Phone No</th>
                          <th style="font-family: unicode;">Main Balance</th>
                          <th style="font-family: unicode;" *ngIf="hardcode_edit_bool ===true">Provider Detail</th>                     
                        </tr>
                        </thead>
                        <tbody>
                        <tr *ngFor="let user of userList">
                          <td style="font-family: unicode;width: 150px;">{{user.name}}</td>                   

                          <!-- <td style="font-family: unicode;">
                            <a [routerLink] = "['/manage-wallet-user-detail', user.id]" style="text-decoration: underline; cursor: pointer;">{{user.phone_no}}
                          </a></td> -->

                          <td style="font-family: unicode;">
                            <a [routerLink]="['/user-detail', user.id]" [queryParams]="{ type: 'managewalletslist' }">{{user.phone_no}}</a>
                          </td>

                          <td style="font-family: unicode;" >{{user.balance  | number : '1.0-2'  }}</td>
                          <td style="font-family: unicode;" *ngIf="hardcode_edit_bool ===true">
                            <a [routerLink] = "['/manage-wallets-provider-detail', user.id]"><i class="fas fa-edit" style="color:#4b88eb"></i></a>
                          </td>
                        </tr>                        
                        </tbody>
                      </table>

                      <tr *ngFor="let user of userList | paginate
                        : {
                            itemsPerPage: itemsPerPage,
                            currentPage: page,
                            totalItems: totalItems
                          }" 
                          >
                      </tr>                    

                      <div class = "row">
                        <div class = "col-md-6"></div>
                        <div class = "col-md-6">
                          <pagination-controls
                            class="pagi"
                            (pageChange)="gty((page = $event))">
                          </pagination-controls>
                        </div> 
  
                      </div>
                   
                  </div>
            
                  </div>
                </div>

            </form>

          </div>
        </section>

      </div>
      <aside class="control-sidebar control-sidebar-dark">
 
      </aside>
    
    <app-footer></app-footer>
    <ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#fff" type="square-jelly-box">
      <p style="font-size: 16px; color: white">Loading...</p>
    </ngx-spinner>
</div>

<div id="deleteData" class="modal fade">
  <div class="modal-dialog">
      <div class="modal-content">

          <div class="modal-body">
              <form>

                  <div class="row" style="margin-top: 5%;margin-bottom: 3%;margin-left: 9%;">
                      <div class="form-group col-md-12">
                          <h5> This Account is used by another device. 
                              Please Sign Out. Login Again. </h5>
                      </div>
                  </div>

                  <div class="row" style="margin-left: 40%;margin-bottom: 3%;">
                      <div class="col-md-5 col-sm-6" id="deleteBtn">
                          <button type="button" class="btn btn-block btn-success" (click)="OkLogout()"> Ok </button>
                      </div>
                  </div>
              </form>
          </div>

      </div>
  </div>
</div>


