import { Component, OnInit, ViewChild } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,HttpErrorResponse } from '@angular/common/http';
import { Router, NavigationEnd,ActivatedRoute } from '@angular/router';
import { DataTableDirective } from 'angular-datatables';

import { catchError, retry } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { ToastrService } from 'ngx-toastr';

import { LocalStorageService } from 'ngx-webstorage';
import { NgxSpinnerService } from "ngx-spinner";
import { FunctService } from '../../service/funct.service';
import { DtoService } from '../../service/dto.service';
import { DatePipe } from '@angular/common'

import { Subject } from 'rxjs';
import { DateRange } from '@ngx-tiny/date-picker';
import Responsive from 'datatables.net-responsive'; /*for responsive not working event datatable */
declare var $: any;


@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.css'],
  providers: [DatePipe]//amk
})

export class UserComponent implements OnInit {

  @ViewChild(DataTableDirective)
  dtElement: DataTableDirective;
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();

  phoneNo: string= '';
  name: string= '';
  referralCode: string= '';
  status: string= '';
  userList: any;
  idIndex: any;
  approvedate : any;
  approvetodayDate : any;
  approveDate : any;
  allchangedate : any;

  approvetodate : any;
  allchangetodate : any;
  approvetodayToDate : any;
  approvetoDate : any;

  config: any;
  collection = [];
  page = 1;
  passenger: any; 
  itemsPerPage =  10;
  totalItems : any; 
  itemsPerPageFront =  10;
  appVersionList :[];
  appVersion : any;

  newTotalItems : any;

  startDate: string;
  defaultTodayDate: string;
  isDateRange : Date;
  endDate: string;
  selectedPreset: string;

  hardcode_edit :any; 
  hardcode_view: any;
  hardcode_view_bool : any;
  hardcode_edit_bool : any;

  deviceType:any;
  browserType:any;
  countryCode:any;
  
  constructor(private storage: LocalStorageService,private datepipe: DatePipe, private toastr: ToastrService,private spinner: NgxSpinnerService, private http: HttpClient, private dto: DtoService, private router: Router, 
    private route: ActivatedRoute, private funct: FunctService,) {
    this.idIndex = 1;
    this.storage.store('isNotiSong', "");
    this.approvedate = new Date();
    console.log("this.approvedate>> " + this.approvedate);
    this.approvetodayDate = this.datepipe.transform(this.approvedate, 'MMM dd, yyyy');
    console.log("this.todayDate>> " + this.approvetodayDate);

    this.approvetodate = new Date();
    console.log("this.approvetodate>> " + this.approvetodate);
    this.approvetodayToDate = this.datepipe.transform(this.approvetodate, 'MMM dd, yyyy');
    console.log("this.todayDate>> " + this.approvetodayToDate);

    //amk          
      this.hardcode_view = "DailyNewRegistration_View";
      this.hardcode_edit = "DailyNewRegisterList_Edit";   
      this.dto.menuCodes = this.storage.retrieve('menucodes');

      const filteredMenuCodes_view = [];    
      const filteredMenuCodeds_edit =[];
      this.dto.menuCodes.forEach(menuCode => {
        if (menuCode === this.hardcode_view) {
          filteredMenuCodes_view.push(menuCode);
        }        
        if (menuCode === this.hardcode_edit) {
          filteredMenuCodeds_edit.push(menuCode);
        }
      });
      if(filteredMenuCodes_view.length != 0)
      {
         this.hardcode_view_bool = true;
      }    
      if(filteredMenuCodeds_edit.length != 0)
      {
         this.hardcode_edit_bool = true;
      }
    //



    this.getAllData();

    this.getAllAppVersion();   
    this.selectedPreset="today";
    this.setPreset();
    this.dto.isSearchMember = "d";
    this.dto.isGameTransaction ="d";
    this.dto.isForgotPassword = "d";
    this.dto.isAccMember="d";
    this.dto.isUseSearch = false;
    this.onDateRangeChange() ;
   }   

  ngOnInit(): void {
    this.router.routeReuseStrategy.shouldReuseRoute = function () {
      return false;
    };

    this.router.events.subscribe((evt) => {
      if (evt instanceof NavigationEnd) {        
        this.router.navigated = false;      
        window.scrollTo(0, 0);
      }
    });
    this.dtOptions = {
      responsive: {
        details: {
            renderer: Responsive.renderer.listHiddenNodes()
        }
    },
      order: [],
      paging: false,
      destroy : true,
      info:true,
      dom:"Bfrltip"
    }

    this.dtOptions.columnDefs = [
      //{ targets: [12], orderable: true }// amk edit for ui over for min /max

    ];

    if(!this.storage.retrieve('loadFlag')){
      this.storage.store('loadFlag', 'noLoad');
      setTimeout(function(){
        location.reload();
      }, 5);
    }
    else{
      this.storage.clear('loadFlag');
    }
  }
  onInput(event: InputEvent) {
    const myanmarCharactersRegex = new RegExp('^[\u1000-\u109F\uAA60-\uAA7F\uA9E0-\uA9FF]+$');
    const inputValue = (event.target as HTMLInputElement).value; 
    if(event.data!=null)
    {
      if (myanmarCharactersRegex.test(inputValue)) 
      {        
        this.spinner.hide();
        this.toastr.error( "Myanmar typing detected!", '', {
        timeOut: 5000,
        positionClass: 'toast-top-right',
        });  
      }
    } 
    
  }

  onDateRangeChange() {

    const today = new Date();
    let start;
    let end;
    let daterange;

    const today_defalut = new Date();
    const year_default = today_defalut.getFullYear();
    const month_default = (today_defalut.getMonth() + 1).toString().padStart(2, '0');
    const day_detault = today_defalut.getDate().toString().padStart(2, '0');
    this.defaultTodayDate = `${year_default}-${month_default}-${day_detault}`; 

    switch (this.selectedPreset) {      
      case 'today':
        const today = new Date();
        const year = today.getFullYear();
        const month = (today.getMonth() + 1).toString().padStart(2, '0');
        const day = today.getDate().toString().padStart(2, '0');
        this.startDate = `${year}-${month}-${day}` + " - " + `${year}-${month}-${day}`; 
        break;
     
      case 'yesterday':     
        const today_y = new Date();
        const yesterday = new Date(today_y.getTime() - 24 * 60 * 60 * 1000);
        const year_y = yesterday.getFullYear();
        const month_y = (yesterday.getMonth() + 1).toString().padStart(2, '0');
        const day_y = yesterday.getDate().toString().padStart(2, '0');
        this.startDate = `${year_y}-${month_y}-${day_y}` + " - " + this.defaultTodayDate;
        break;
      case 'last7days':
        const today_l = new Date();
        const last7days_l = new Date(today_l.getTime() - 7 * 24 * 60 * 60 * 1000);
        const year_l = last7days_l.getFullYear();
        const month_l = (last7days_l.getMonth() + 1).toString().padStart(2, '0');
        const day_l = last7days_l.getDate().toString().padStart(2, '0');
        this.startDate = `${year_l}-${month_l}-${day_l}`+ " - " + this.defaultTodayDate;
        break;
      case 'last30days':
        const today_ld = new Date();
        const last30days_ld = new Date(today_ld.getTime() - 30 * 24 * 60 * 60 * 1000);
        const year_ld = last30days_ld.getFullYear();
        const month_ld = (last30days_ld.getMonth() + 1).toString().padStart(2, '0');
        const day_ld = last30days_ld.getDate().toString().padStart(2, '0');
        this.startDate = `${year_ld}-${month_ld}-${day_ld}`+ " - " + this.defaultTodayDate;
        break;
      case 'thisweek':
        const today_w = new Date();
        const startOfWeek_w = new Date(today_w.getTime() - (today_w.getDay() - 1) * 24 * 60 * 60 * 1000);
        const year_w = startOfWeek_w.getFullYear();
        const month_w = (startOfWeek_w.getMonth() + 1).toString().padStart(2, '0');
        const day_w = startOfWeek_w.getDate().toString().padStart(2, '0');
        this.startDate = `${year_w}-${month_w}-${day_w}`+ " - " + this.defaultTodayDate;
        break;
      case 'lastweek':
        const today_lw = new Date();
        const start = new Date(today_lw.getTime() - (today_lw.getDay() + 6) * 24 * 60 * 60 * 1000);
        const end = new Date(today_lw.getTime() - (today_lw.getDay()) * 24 * 60 * 60 * 1000);
        const startDate = start.toISOString().substr(0, 10);
        const endDate = end.toISOString().substr(0, 10);
        this.startDate = startDate + " - " + endDate ;     
        break;        
      case 'thismonth':
        const today_m = new Date();
        const year_m = today_m.getFullYear();
        const month_m = (today_m.getMonth() + 1).toString().padStart(2, '0');
        const day_m = '01';
        this.startDate = `${year_m}-${month_m}-${day_m}`+ " - " + this.defaultTodayDate;
        break;
      case 'lastmonth':
        const today_lm = new Date();
        const lastMonth_lm = new Date(today_lm.getFullYear(), today_lm.getMonth() - 1, 1);
        const start_lm = new Date(lastMonth_lm.getFullYear(), lastMonth_lm.getMonth(), 2);
        const end_lm = new Date(lastMonth_lm.getFullYear(), lastMonth_lm.getMonth(), 32);
        const startDate_lm = start_lm.toISOString().substr(0, 10);
        const endDate_lm = end_lm.toISOString().substr(0, 10);
        this.startDate = startDate_lm +" - "+ endDate_lm;
        break;  
     }
   
  }

  setPreset() {
    const today = new Date();
    let start;
    let end;
    let daterange;

    switch (this.selectedPreset) {
      case 'today':
        start = today;
        end = today;
        daterange = start + end;
        break;
      case 'yesterday':
        start = new Date(today.getTime() - 24 * 60 * 60 * 1000);
        end = start;
        break;
      case 'last7days':
        start = new Date(today.getTime() - 7 * 24 * 60 * 60 * 1000);
        end = today;
        break;
      case 'last30days':
        start = new Date(today.getTime() - 30 * 24 * 60 * 60 * 1000);
        end = today;
        break;
      case 'thisweek':
        start = new Date(today.getTime() - (today.getDay() - 1) * 24 * 60 * 60 * 1000);
        end = today;
        break;
      case 'lastweek':
        start = new Date(today.getTime() - (today.getDay() + 6) * 24 * 60 * 60 * 1000);
        end = new Date(today.getTime() - (today.getDay() - 1) * 24 * 60 * 60 * 1000);
        break;
     }
}
  ngAfterViewInit(){
  }

  handleError(error: HttpErrorResponse){
    if(error.status == 423)
    {
      this.spinner.hide();
      $("#deleteData").modal("show");
    }
    if(error.status == 403)
    {
      this.spinner.hide();
      var id = 'tblUser' + this.idIndex;
      var table = $('#' + id).DataTable();
      this.toastr.error("Limited Access.", 'Invalid!', {
        timeOut: 3000,
        positionClass: 'toast-top-right',
        });
    }
    return throwError(error);
    }
    OkLogout()
    {
      window.location.href ="./ad-login";
    }

  
    getAllData() {      
      this.userList = [];
      var id = 'tblUser' + this.idIndex;
      var table = $('#' + id).DataTable();
      table.destroy();
      this.idIndex = this.idIndex +1;
      this.spinner.show();
      this.dto.token = this.storage.retrieve('token');
      let headers = new HttpHeaders();
      headers = headers.set('Authorization',  this.dto.token);
      let params = new HttpParams(); 
      this.approveDate = $("#approveDate").val();
      this.approvetoDate = $("#approvetoDate").val();
      if (this.approveDate == '' || this.approveDate == undefined) {
        this.allchangedate = this.approvetodayDate;
      }
      else {
        this.allchangedate = this.approveDate;
      }
  
      if (this.approvetoDate == '' || this.approvetoDate == undefined) {
        this.allchangetodate = this.approvetodayToDate;
      }
      else {
        this.allchangetodate = this.approvetoDate;
      }
      if(this.deviceType == null || this.deviceType == undefined)
      {
          this.deviceType = "";
      }
      if(this.browserType == null || this.browserType == undefined)
      {
          this.browserType ="";
      }
      if(this.countryCode == null || this.countryCode == undefined)
      {
          this.countryCode ="";
      }
      
      params = params.set('app_version',this.appVersion).set('phoneNo',this.phoneNo).set('name',this.name).set('referralCode',this.referralCode).set('status', this.status)
      .set('register_date',this.allchangedate).set('register_todate',this.allchangetodate)
      .set('deviceType',this.deviceType).set('browserType',this.browserType).set('countryCode', this.countryCode).set("pageNumber","1").set("rowsOfPage","10");
      this.http.get(this.funct.ipaddress + 'user/userByparams_1', { params: params, headers: headers } ).subscribe((data: any) => {
        this.dto.Response = {};
        this.dto.Response = data.results;
        this.userList = this.dto.Response;
        this.totalItems = data.totalRows;
        this.dtTrigger.next();
        this.spinner.hide();
      })
    }

    gty(page: any){
      this.userList = [];
      var id = 'tblUser' + this.idIndex;
      var table = $('#' + id).DataTable();
     
      table.destroy();
      this.idIndex = this.idIndex +1;
      this.spinner.show();
      let headers = new HttpHeaders();
      headers = headers.set('Authorization',  this.dto.token);
      let params = new HttpParams();
      this.approveDate = $("#approveDate").val();
    this.approvetoDate = $("#approvetoDate").val();
    if (this.approveDate == '' || this.approveDate == undefined) {
      this.allchangedate = this.approvetodayDate;
    }
    else {
      this.allchangedate = this.approveDate;
    }

    if (this.approvetoDate == '' || this.approvetoDate == undefined) {
      this.allchangetodate = this.approvetodayToDate;
    }
    else {
      this.allchangetodate = this.approvetoDate;
    }
    if(this.deviceType == null || this.deviceType == undefined)
    {
        this.deviceType = null;
    }
    if(this.browserType == null || this.browserType == undefined)
    {
        this.browserType =null;
    }
    if(this.countryCode == null || this.countryCode == undefined)
    {
        this.countryCode ="";
    }
    
      params = params.set('app_version',this.appVersion).set('phoneNo',this.phoneNo).set('name',this.name).set('referralCode',this.referralCode).set('status', this.status)
      .set('register_date',this.allchangedate).set('register_todate',this.allchangetodate).set('deviceType',this.deviceType).set('browserType',this.browserType).set('countryCode', this.countryCode).set("pageNumber",page).set("rowsOfPage",this.itemsPerPage.toString());
      this.http.get(this.funct.ipaddress + 'user/userByparams_1', { params: params, headers: headers } ).subscribe((data: any) => {
        this.dto.Response = {};
        this.dto.Response = data.results;
        this.userList = this.dto.Response;
        this.totalItems = data.totalRows;
        this.dtTrigger.next();
        this.spinner.hide();
      })
    }

    getAllAppVersion()
    {
      this.dto.token = this.storage.retrieve('token');
      let headers = new HttpHeaders();
      headers = headers.set('Authorization', this.dto.token);
      let params = new HttpParams();
      this.http.get(this.funct.ipaddress + 'user/getAllAppVersion', {headers: headers }).subscribe(
        result => {
          this.dto.Response = {};
          this.dto.Response = result;
          this.appVersionList = this.dto.Response;
        }
      );
    }

  search(){
    this.spinner.show();
    this.userList = [];
    var id = 'tblUser' + this.idIndex;
    var table = $('#' + id).DataTable();
    table.destroy();
    this.idIndex = this.idIndex +1;
    this.dto.token = this.storage.retrieve('token');
    let headers = new HttpHeaders();
    headers = headers.set('Authorization',  this.dto.token);
    let params = new HttpParams();
    this.approveDate = $("#approveDate").val();
    this.approvetoDate = $("#approvetoDate").val();
    if (this.approveDate == '' || this.approveDate == undefined) {
      this.allchangedate = this.approvetodayDate;
    }
    else {
      this.allchangedate = this.approveDate;
    }

    if (this.approvetoDate == '' || this.approvetoDate == undefined) {
      this.allchangetodate = this.approvetodayToDate;
    }
    else {
      this.allchangetodate = this.approvetoDate;
    }
   
    
    params = params.set('app_version',this.appVersion).set('phoneNo',this.phoneNo).set('name',this.name).set('referralCode',this.referralCode).set('status', this.status)
    .set('register_date',this.allchangedate).set('register_todate',this.allchangetodate);
   
	  this.http.get(this.funct.ipaddress + 'user/userByparams', { params: params, headers: headers } )
    .pipe(
      catchError(this.handleError.bind(this))
     )
    .subscribe(  
      result => {
        this.dto.Response = {};
        this.dto.Response = result;
        this.userList = this.dto.Response;
        this.page =  0
        this.dtTrigger.next();
        this.spinner.hide();
     
      }
    );
  }

  onChangeApprove() {
    $(document).ready(function () {
      this.approveDate = $("#approveDate").val();
    });
  }

  onChangeApproveTo()
  {
    $(document).ready(function () {
      this.approvetoDate = $("#approvetoDate").val();
    });
  }
  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }

 /*XXXXXXXXXXXXXXXXXXXXXXXX*/
  pageChange(newPage: number) {
    this.router.navigate(['/user-list'], { queryParams: { page: newPage } });
  }

  
  addStepAndNavigate(user: any): void
  { 
   localStorage.setItem('userData', JSON.stringify({
     id: user.id,
     referral_code: user.referral_code,
     app_version:user.app_version,
     balance_Str:user.balance_Str,
     topup_balance_Str: user.topup_balance_Str,
     win_amount_Str: user.win_amount_Str,
     withdraw_balance_Str: user.withdraw_balance_Str,
     bet_amount_Str:user.bet_amount_Str,
     status: user.status,
     created_date_Str:user.created_date_Str,
     last_used_time_str:user.last_used_time_str,
     first_topup_date_Str:user.first_topup_date_Str,
     first_topup_amt_Str:user.first_topup_amt_Str,
     updated_date_Str:user.updated_date_Str,
     updated_by_name:user.updated_by_name

   }));

   this.router.navigate(['/user-detail', user.id]);
 }
 
 onChangeTagSection() {     
  this.storage.store('countryCode', this.countryCode.toString());  
}

}
