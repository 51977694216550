import { Component, OnInit, ViewChild } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,HttpErrorResponse } from '@angular/common/http';
import { Router, NavigationEnd } from '@angular/router';
import { DataTableDirective } from 'angular-datatables';

import { LocalStorageService } from 'ngx-webstorage';
import { NgxSpinnerService } from "ngx-spinner";

import { catchError, retry } from 'rxjs/operators';
import { throwError } from 'rxjs';

import { DtoService } from '../../service/dto.service';
import { FunctService } from '../../service/funct.service';
import { ToastrService } from 'ngx-toastr';
import Responsive from 'datatables.net-responsive'; /*for responsive not working event datatable */



import { Subject } from 'rxjs';
declare var $: any;

@Component({
  selector: 'app-customerservice',
  templateUrl: './customer-service.component.html',
  styleUrls: ['./customer-service.component.css']
})
export class CustomerServiceComponent implements OnInit {

  @ViewChild(DataTableDirective)
  dtElement: DataTableDirective;
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();

  phoneNo: string= '';
  name: string= '';
  referralCode: string= '';
  status: string= '';
  agentList: any;
  idIndex: any;

  hardcode_edit :any;
  hardcode_add :any;
  hardcode_view: any;  
  hardcode_add_bool : any;
  hardcode_edit_bool : any;
  hardcode_view_bool:any;

  constructor(private storage: LocalStorageService, private spinner: NgxSpinnerService, private toastr: ToastrService, private http: HttpClient, private dto: DtoService, private router: Router,
    private funct: FunctService) {
    this.idIndex = 1;
    this.status = 'ACTIVE';
    this.storage.store('isNotiSong', "");
    this.hardcode_view = "CustomerService_View";
    this.hardcode_add = "CustomerService_Add";
    this.hardcode_edit = "CustomerService_Edit"; 
    this.dto.menuCodes = this.storage.retrieve('menucodes');

    const filteredMenuCodes_view = [];
    const filteredMenuCodeds_add =[];
    const filteredMenuCodeds_edit =[];
    
    this.dto.menuCodes.forEach(menuCode => {
      if (menuCode === this.hardcode_view) {
        filteredMenuCodes_view.push(menuCode);
      }
      if (menuCode === this.hardcode_add) {
        filteredMenuCodeds_add.push(menuCode);
      }
      if (menuCode === this.hardcode_edit) {
        filteredMenuCodeds_edit.push(menuCode);
      }
    
    });
    if(filteredMenuCodes_view.length != 0)
    {
        this.hardcode_view_bool = true;
    } 
    if(filteredMenuCodeds_add.length != 0)
    {
        this.hardcode_add_bool = true;
    }
    if(filteredMenuCodeds_edit.length != 0)
    {
        this.hardcode_edit_bool = true;
    }

    this.search();
    
   }

  ngOnInit(): void
  {    
    this.router.routeReuseStrategy.shouldReuseRoute = function () {
      return false;
    };

    this.router.events.subscribe((evt) => {
      if (evt instanceof NavigationEnd) {       
        this.router.navigated = false;       
        window.scrollTo(0, 0);
      }
    });  
    this.dtOptions = {
      responsive: {
        details: {
            renderer: Responsive.renderer.listHiddenNodes()
        }
    },
      order: [],
      paging: false,
      destroy : true
    }

    this.dtOptions.columnDefs = [
      { targets: [12], orderable: true }

    ];

    if(!this.storage.retrieve('loadFlag')){
      this.storage.store('loadFlag', 'noLoad');
      setTimeout(function(){
        location.reload();
      }, 5);
    }
    else{
      this.storage.clear('loadFlag');
    }
  }

  ngAfterViewInit(){

  }

  handleError(error: HttpErrorResponse){
    if(error.status == 423)
    {
      this.spinner.hide();
      $("#deleteData").modal("show");
    }
    if(error.status == 403)
    {
      this.spinner.hide();
      var id = 'tblAgent' + this.idIndex;
      var table = $('#' + id).DataTable();
      this.toastr.error("Limited Access.", 'Invalid!', {
        timeOut: 3000,
        positionClass: 'toast-top-right',
        });
    }
    return throwError(error);
    }
    OkLogout()
    {
      window.location.href ="./ad-login";
    } 

  search(){
    this.agentList = [];
    var id = 'tblAgent' + this.idIndex;
    var table = $('#' + id).DataTable();
    table.destroy();
    this.idIndex = this.idIndex +1;
    this.spinner.show();
    this.dto.token = this.storage.retrieve('token');
    let headers = new HttpHeaders();
    headers = headers.set('Authorization', this.dto.token);
    let params = new HttpParams();
    params = params.set('phoneNo',this.phoneNo).set('name',this.name).set('referralCode',this.referralCode).set('status', this.status).set('role_name', 'CUSTOMER_SERVICE');
    this.http.get(this.funct.ipaddress + 'agent/agentsByparams', { params: params, headers: headers } )
    .pipe(
      catchError(this.handleError.bind(this))
     )
    .subscribe(
      result => {
        this.dto.Response = {};
        this.dto.Response = result;
        this.agentList = this.dto.Response;
        this.dtTrigger.next();
        this.spinner.hide();
      }
    );
  }


  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }
  addStepAndNavigate(agent: any): void {
    console.log('Agent data:', agent);
    localStorage.setItem('agentData', JSON.stringify({
      id: agent.id,
      name: agent.name,
      phone_no: agent.phone_no,
      role_name: agent.role_name,
      referral_code: agent.referral_code,
      balance_Str: agent.balance_Str,
      commission_balance_Str: agent.commission_balance_Str,
      withdrawal_balance_Str: agent.withdrawal_balance_Str,
      status: agent.status,
      created_date_string: agent.created_date_string,
      created_by_name: agent.created_by_name,
      updated_date_string: agent.updated_date_string,
      updated_by_name: agent.updated_by_name
    }));
    
    this.router.navigate(['/customer-service-detail', agent.id]);
  }
  

}
