<div class="row">


  <div class="form-group col-md-2">
    <label> Type </label>
    <input class="form-control form-control-sm" [(ngModel)]="typeId" [ngModelOptions]="{standalone: true}">
  </div>


  <div class="form-group col-md-2">
    <label></label>
    <button id="search" type="button" class="btn btn-block btn-primary btn-sm" style="margin-top: 4%;"
      (click)="search()"> Search </button>
  </div>

  <div class="form-group col-md-2" *ngIf="hardcode_add_bool === true"> 
    <label></label>
    <button type="button" class="btn btn-block btn-success btn-sm" style="margin-top: 4%;"
      (click)="showAddTranModelNew()">
      <b> Add New </b>
    </button>
  </div>

  <div class="col-md-12">
    <div class="card">
      <div class="card-header">
        <h3 class="card-title" style="font-weight: bold;"> FAQ Type List </h3>
      </div>

      <div class="card-body">
        <table width='100%' id="{{'tblFeedback' + idIndex}}" datatable [dtTrigger]="dtTrigger" [dtOptions]="dtOptions"
          class="table table-bordered table-hover">
          <thead>
            <tr>
              <th style="font-family: unicode;">Type</th>
              <th style="font-family: unicode;">Order No</th>
              <th style="font-family: unicode;">Created Date</th>
              <th style="font-family: unicode;">Created By</th>
              <th style="font-family: unicode;">Updated Date</th>
              <th style="font-family: unicode;">Updated By</th>
              <th style="font-family: unicode;" *ngIf="hardcode_double === true">Action</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let feed of feedBack">
              <td style="font-family: unicode;">{{feed.name}}</td>
              <td style="font-family: unicode;">{{feed.orderNum}}</td>
              <td style="font-family: unicode;">{{feed.created_date_Str}}</td>
              <td style="font-family: unicode;">{{feed.created_by_name}}</td>
              <td style="font-family: unicode;">{{feed.updated_time_Str}}</td>
              <td style="font-family: unicode;" >{{feed.updated_by_name}}</td>
        

              <td style="font-family: unicode;"  *ngIf="hardcode_double === true" class="ng-star-inserted">
                <a  *ngIf="hardcode_edit_bool === true" (click)="!isTyping ? showEditRolesModel(feed.id ,isTyping) : null">
                  <i class="fas fa-edit" style="color: #4b88eb;">
                  </i>
                </a> &nbsp;&nbsp;
                <a (click)="delete(feed.id,feed.name)" *ngIf="hardcode_delete_bool === true" >
                  <i class="fa fa-trash" style="color: red;">
                  </i>
                </a>
              </td>

            </tr>
          </tbody>
        </table>


        <tr *ngFor="let feed of feedBack | paginate
                                    : {
                                        itemsPerPage: itemsPerPage,
                                        currentPage: page,
                                        totalItems: totalItems
                                      }">
        </tr>
        <div class="row">
          <div class="col-md-6"></div>
          <div class="col-md-6">
            <pagination-controls class="pagi" (pageChange)="gty((page = $event))"></pagination-controls>
          </div>
        </div>




      </div>

    </div>
  </div>

</div>




<aside class="control-sidebar control-sidebar-dark">

</aside>




<app-footer></app-footer>
<ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#fff" type="square-jelly-box">
  <p style="font-size: 16px; color: white">Loading...</p>
</ngx-spinner>

<div id="browseAccountData9" class="modal fade">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" style="font-weight: bold;font-size: 1rem;"> Edit FAQ Type </h5>
        <button type="button" class="close" data-dismiss="modal" aria-hidden="true"> x </button>
      </div>

      <div class="modal-body">
        <form>
          <div class="col-md-12">


            <div class="col-md-12">
              <div class="card-body">
                <label>FAQ Type </label>
                <input  class="form-control form-control-sm" [(ngModel)]="faqtypename" [ngModelOptions]="{standalone: true}">
             
              </div>
            </div>


            <div class="col-md-12">
              <div class="card-body">
                <label>Order Number </label>
                <input type="number" class="form-control form-control-sm" [(ngModel)]="faqorderNumber" [ngModelOptions]="{standalone: true}" />
             
              </div>
            </div>


          

      
   


          </div>
        </form>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-primary btn-sm" (click)="close()">Cancel</button>
        <button type="button" class="btn btn-primary btn-sm" (click)="addTran(adsDTO.id,faqtypename,faqorderNumber)">Save
          Changes</button>
      </div>
    </div>
  </div>
</div>

<div id="browseAccountData10" class="modal fade">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" style="font-weight: bold;font-size: 1rem;"> Add FAQ Type </h5>
        <button type="button" class="close" data-dismiss="modal" aria-hidden="true"> x </button>
      </div>
      <div class="modal-body">
        <form>
          <div class="col-md-12">
            <div class="col-md-12">
              <div class="card-body">

                <label>FAQ Type </label>
                <input placeholder="Type Here.." class="form-control form-control-sm" [(ngModel)]="addTranAmt"
                  [ngModelOptions]="{standalone: true}">

              </div>
            </div>


            <div class="col-md-12">
              <div class="card-body">
                <label>Order Number </label>
                <input type="number" class="form-control form-control-sm" [(ngModel)]="orderNumber" [ngModelOptions]="{standalone: true}" />
             
              </div>
            </div>




          </div>
        </form>
      </div>


      <div class="modal-footer">
        <button type="button" class="btn btn-primary btn-sm" (click)="Close()">Close</button>
        <button type="button" class="btn btn-primary btn-sm" (click)="SaveNewRole(addTranAmt,orderNumber)">Save</button>
      </div>

    </div>
  </div>
</div>


<div id="deleteData" class="modal fade">
  <div class="modal-dialog">
      <div class="modal-content">

          <div class="modal-body">
              <form>

                  <div class="row" style="margin-top: 5%;margin-bottom: 3%;margin-left: 9%;">
                      <div class="form-group col-md-12">
                          <h3 class="text-center"> Are you sure want to delete? </h3>
                      </div>
                  </div>

                  <div class="row" style="margin-left: 10%;margin-bottom: 3%;">                      

                      <div class="col-md-5 col-sm-6">
                          <button type="button" class="btn btn-block btn-danger" (click) = "closeTran()" [routerLink] = "['/feedbackandfaq/faqtype']"> Cancel </button>
                      </div>
                      <div class="col-md-5 col-sm-6" id="deleteBtn">
                        <button type="button" class="btn btn-block btn-success" (click)="deletTran()"> Confirm </button>
                    </div>
                  </div>

              </form>
          </div>

      </div>
  </div>
</div>




<div id="anotherlogin" class="modal fade">
  <div class="modal-dialog">
      <div class="modal-content">

          <div class="modal-body">
              <form>

                  <div class="row" style="margin-top: 5%;margin-bottom: 3%;margin-left: 9%;">
                      <div class="form-group col-md-12">
                          <h5> This Account is used by another device. 
                              Please Sign Out. Login Again. </h5>
                      </div>
                  </div>

                  <div class="row" style="margin-left: 40%;margin-bottom: 3%;">
                      <div class="col-md-5 col-sm-6" id="deleteBtn">
                          <button type="button" class="btn btn-block btn-success" (click)="OkLogout()"> Ok </button>
                      </div>
                  </div>
              </form>
          </div>

      </div>
  </div>
</div>