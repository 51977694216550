import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpClient, HttpHeaders, HttpParams,HttpErrorResponse } from '@angular/common/http';
import { LocalStorageService } from 'ngx-webstorage';
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from 'ngx-toastr';
import { DtoService } from '../../service/dto.service';
import { FunctService } from '../../service/funct.service';
import { DatePipe } from '@angular/common';
import { ɵangular_packages_platform_browser_animations_animations_f } from '@angular/platform-browser/animations';
declare var $: any;
import { catchError, retry } from 'rxjs/operators';
import { throwError } from 'rxjs';

@Component({
  selector: 'app-winner-input-detail',
  templateUrl: './winner-input-detail.component.html',
  styleUrls: ['./winner-input-detail.component.css'],
  providers: [DatePipe]
})
export class WinnerInputDetailComponent implements OnInit {

  widetail: any;
  token: any;
  widID: any;
  type: any;
  //amk
  imagePath: any;
  message: string = '';
  filePath : any;
  imgURL: any;
  gameDTO: any;
  cashIdid:any;
  
  constructor(public datePipe: DatePipe,private storage: LocalStorageService, private route: ActivatedRoute, private http: HttpClient, private dto: DtoService, private spinner: NgxSpinnerService, private toastr: ToastrService,
              private router: Router, private funct: FunctService) {

                this.storage.store('isNotiSong', "");
    if (!this.storage.retrieve('loadFlag')) {
      this.storage.store('loadFlag', 'noLoad');
      setTimeout(function () {
        location.reload();
      }, 1);
    }
    else {
      this.storage.clear('loadFlag');
    }

    this.widID = this.route.snapshot.paramMap.get("id");
    this.type = this.route.snapshot.paramMap.get("type");
    
    this.dto.isType = this.type;

    if (this.widID != null) {
      this.widetail = {
        id: 0,
        name: '',
        betAmount: 0,
        phoneNo: '',
        odd: 0,
        status: 'Active',
        winAmount: 0,
        createdDate : '',
        createdBy: '',
        updatedDate: '',
        updatedBy : '',
        //amk
        imageUrl:'',
        fromPage:''
      };
      this.GetWinnerInputDetailById();
    }

  }

  ngOnInit(): void {
    
  }

  handleError(error: HttpErrorResponse){
    this.spinner.hide();
    if(error.status == 423)
    {
      this.spinner.hide();
      $("#deleteData").modal("show");
    }
    if(error.status == 403)
    {
      this.spinner.hide();
      this.toastr.error("Limited Access.", 'Invalid!', {
        timeOut: 3000,
        positionClass: 'toast-top-right',
        });
    }
    return throwError(error);
  }

  OkLogout()
  {
    window.location.href ="./ad-login";
  } 

  preview(files) {
    if (files.length === 0)
      return;
    var mimeType = files[0].type;
    if (mimeType.match(/image\/*/) == null) {
      this.message = "Only images are supported.";
      return;
    }
    var reader = new FileReader();
    this.imagePath = files;
    this.filePath = files[0];
    reader.readAsDataURL(files[0]);
    reader.onload = (_event) => {
      this.imgURL = reader.result;
     console.log("imgURL>>>" + JSON.stringify(this.imgURL));
    }
  }

  GetWinnerInputDetailById() {
    
    this.token = this.storage.retrieve('token');
    let headers = new HttpHeaders();
    headers = headers.set('Authorization',  this.token);
    let params = new HttpParams();
    params = params.set('widID', this.widID);
    this.http.get(this.funct.ipaddress + 'winnerInput/GetWinnerInputDetailById', { params: params, headers: headers }) 
    .pipe(
      catchError(this.handleError.bind(this))
    )
    .subscribe(
      result => {
        this.dto.Response = {};
        this.dto.Response = result;
        this.widetail = this.dto.Response;
        //amk
        this.widetail = this.dto.Response;
        if (this.widetail.imageUrl != null || this.widetail.imageUrl != '') {
          this.imgURL = this.widetail.imageUrl;
         
        }
        //
     
      });
  }
  edit() {
    this.spinner.show();
    this.token = this.storage.retrieve('token');
    let headers = new HttpHeaders();        
    headers = headers.set('Authorization', this.token); 
    console.log("Image Url new :" + this.imgURL);
    //amk      
    if (this.imgURL != '' && this.imgURL != null && this.imgURL != undefined) 
    {
      if(this.imgURL.includes('data:image/jpeg;base64,'))
         this.widetail.imageUrl = this.imgURL.replace("data:image/jpeg;base64,","");
      if(this.imgURL.includes('data:image/png;base64,'))
         this.widetail.imageUrl = this.imgURL.replace("data:image/png;base64,","");
    }  
    else
    {
      this.widetail.imageUrl="";    
    }
    this.widetail.fromPage = this.type;
    if(this.type =="2D")
    {
      this.widetail.fromPage ="2D-Winner";
    }
    else{
      this.widetail.fromPage ="3D-Winner";
    }
    // this.widetail.fromPage ="2D-Winner";
    //
    this.http.post(this.funct.ipaddress + 'winnerInput/EditWinnerInput',this.widetail,{headers: headers})
    .pipe(
      catchError(this.handleError.bind(this))
     )
    .subscribe(
      result => {
        this.dto.Response = {};
        this.dto.Response = result;
        if (this.dto.Response.status == 'Success')
         {
          this.spinner.hide();        
          //  '2DWinnerList'
          if(this.type == '2D')
          {
            this.type ="2DWinnerList";
          }
          else 
          {
            this.type ="3DWinnerList";
          }
          this.router.navigate(['/winner-input', this.type]).then(() => {
            this.toastr.success(this.dto.Response.message, 'Success!', {
              timeOut: 3000,
              positionClass: 'toast-top-right'
            });
          })
        }
        else {
          this.spinner.hide();
          this.toastr.error(this.dto.Response.message, 'Invalid!', {
            timeOut: 3000,
            positionClass: 'toast-top-right',
          });
        }
      });
  }

  
 onDelete(id)
 {
      this.imgURL ='';
      this.filePath ='';
      this.toastr.success('Delete ', 'Success!', {
        timeOut: 3000,
        positionClass: 'toast-top-right'
      });
      //this.spinner.show();
      // this.token = this.storage.retrieve('token');
      // let headers = new HttpHeaders();
      // headers = headers.set('Authorization',  this.token);
      // let formData= new FormData();
      // this.cashIdid = id;
      // formData.append("id",this.cashIdid);
      // this.http.post(this.funct.ipaddress + 'payment/deletQRImageForWinner', formData, {headers: headers})
      // .pipe(
      //   catchError(this.handleError.bind(this))
      // )
      // .subscribe(
      //   result => {
      //     this.dto.Response = {};
      //     this.dto.Response = result;
      //     if (this.dto.Response.status == 'Success') {
      //       this.spinner.hide();
      //     this.router.navigate(['/winner-input', this.type]).then(() => {
      //       this.toastr.success(this.dto.Response.message, 'Success!', {
      //         timeOut: 3000,
      //         positionClass: 'toast-top-right'
      //       });
      //     })
          
      //     }
      //     else {
      //       this.spinner.hide();
      //       this.toastr.error(this.dto.Response.message, 'Invalid!', {
      //         timeOut: 3000,
      //         positionClass: 'toast-top-right',
      //       });
      //     }
      //   });
 

 }
  calculatedWinAmount(){ 
    this.widetail.winAmount = this.widetail.odd * Number(this.widetail.betAmount);
  } 
}
