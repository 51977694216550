<div class="wrapper">
    <!-- Navbar -->
    <app-header-sidebar></app-header-sidebar>

    <!-- here here here -->
    <!-- Content Wrapper. Contains page content -->
    <div class="content-wrapper">
        <!-- Content Header (Page header) -->
        <div class="content-header">
            <div class="container-fluid">
            </div><!-- /.container-fluid -->
        </div>
        <!-- /.content-header -->

        <!-- Main content -->
        <section class="content">
            <div class="container-fluid">
                <form>
                    <div class="row">
                        <div class="col-md-12">
                            <div class="card">
                                <div class="card-header">
                                    <h3 class="card-title" style="font-weight: bold;"> 2D Live Section Config</h3>
                                </div>
                                <!-- /.card-header -->
                                <div class="card-body">
                                    <form> <!--start of tabs -->
                                      <div class="row">
                                        <div class="col-md-12">
                                          <!-- Nav tabs -->
                                          <ul class="nav nav-tabs nav-pills">
                                            <li>
                                               <a class="active" id="all-tab" data-toggle="tab" href="#manualResult">2D Live Result </a>
                                            </li>
                                            <li (click)="getTwodManualOnOff()">
                                              <a id="added-tab" data-toggle="tab" href="#manualOnOff">Switch Sections (Manual/Auto)</a>
                                            </li>
                                            <li (click)="getTwoDHistoryLog()">
                                                <a id="added-tab" data-toggle="tab" href="#twoDHistoryLog">2D History Logs</a>
                                            </li>
                                          </ul>
                            
                                          <!-- Tab panes -->
                                          <div class="tab-content clearfix">
                                            
                                            <div class="tab-pane show active" id="manualResult">
                                                <div class="row">
                                                    <label class="col-md-2"> Section </label>
                                                    <div class="form-group col-md-3">
                                                        <select class="form-control form-control-sm col-md-12 col-sm-4 col-xs-4"
                                                            [(ngModel)]="section" [ngModelOptions]="{standalone: true}">
                                                            <option value="Null">-- Please Select --</option>
                                                            <option value="10:30 AM">10:30 AM</option>
                                                            <option value="12:01 PM">12:01 PM</option>
                                                            <option value="02:30 PM">02:30 PM</option>
                                                            <option value="04:30 PM">04:30 PM</option>
                                                        </select>
                                                    </div> 
                                                </div>
                                                <div class="row">
                                                    <label class="col-md-2">Set</label>
                                                    <div class="form-group col-md-3">
                                                    <input type = "text" class="form-control form-control-sm" [(ngModel)]="twodSet"
                                                        [ngModelOptions]="{standalone: true}" id = "regbalance">
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <label class="col-md-2">Value</label>
                                                    <div class="form-group col-md-3">
                                                    <input type = "text" class="form-control form-control-sm" [(ngModel)]="twodValue"
                                                        [ngModelOptions]="{standalone: true}" id = "regbalance">
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <label class="col-md-2">Result</label>
                                                    <div class="form-group col-md-3">
                                                    <input type = "text" class="form-control form-control-sm" [(ngModel)]="twodResult"
                                                        [ngModelOptions]="{standalone: true}" id = "regbalance">
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <label class="col-md-2">Is Done?</label>
                                                    <input style = "cursor: pointer;" type="checkbox" [(ngModel)]="isDone" 
                                                    [ngModelOptions]="{standalone: true}">
                                                </div>
                                                <div class="row" style="margin-top: 1%;" *ngIf="hardcode_2DLiveResult_bool === true">
                                                    <div class="col-md-3 col-sm-6"></div>
                                                    <div class="col-md-2 col-sm-6">
                                                        <button type="button" class="btn btn-block btn-success"
                                                            (click)="goConfigSave()"> Save </button>&nbsp;
                                                    </div>
                                                </div>
                                            </div>
                            
                                            <div class="tab-pane" id="manualOnOff">
                                                <div class="card-body">
                                                    <table width='100%' datatable [dtTrigger]="dtTrigger" [dtOptions]="dtOptions"
                                                    id="{{'tblManual' + idIndex}}" class="table table-bordered table-hover">
                                                        <thead>
                                                            <tr>
                                                                <th style="font-family: unicode;">Section Name</th>
                                                                <th style="font-family: unicode;">From Time</th> 
                                                                <th style="font-family: unicode;">To Time</th> 
                                                                <th style="font-family: unicode;" *ngIf="hardcode_DLiveSectionConfig_Edit=== true">Is Manual Out?</th>
                                                                <th style="font-family: unicode;" *ngIf="hardcode_DLiveSectionConfig_Edit=== true">Is Show History?</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr *ngFor="let item of manualOnOffList">
                                                                <td style="font-family: unicode;">{{item.section}}</td>
                                                                <td style="font-family: unicode;">{{item.fromDateTime.replace('T', ' ')}}</td> 
                                                                <td style="font-family: unicode;">{{item.toDateTime.replace('T', ' ')}}</td> 
                                                                <td style="font-family: unicode;" *ngIf="hardcode_DLiveSectionConfig_Edit=== true">
                                                                    <input style = "cursor: pointer;" type="checkbox" [(ngModel)]="item.switchManual" 
                                                                    [ngModelOptions]="{standalone: true}" (change)="goOnOffSave()">
                                                                </td>
                                                                <td style="font-family: unicode;" *ngIf="hardcode_DLiveSectionConfig_Edit=== true"> 
                                                                    <input style = "cursor: pointer;" type="checkbox" [(ngModel)]="item.switchHistory" 
                                                                    [ngModelOptions]="{standalone: true}" (change)="IsShowHistory()">
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>

                                            <div class="tab-pane" id="twoDHistoryLog">
                                                <div class="card-body">     
                                                    <div class="form-group col-md-2" style="float:left;">
                                                        <label>Section</label>
                                                        <select class="form-control form-control-sm col-md-12 col-sm-4 col-xs-4"
                                                            [(ngModel)]="sectionTwoDHistoryLogs" [ngModelOptions]="{standalone: true}">
                                                            <option value="Null">-- Please Select --</option>
                                                            <option value="10:30 AM">10:30 AM</option>
                                                            <option value="12:01 PM">12:01 PM</option>
                                                            <option value="02:30 PM">02:30 PM</option>
                                                            <option value="04:30 PM">04:30 PM</option>
                                                        </select>
                                                    </div>
                                                    <div class="form-group col-md-2 col-sm-4 col-xs-4" style="float:left;">
                                                        <label></label>
                                                        <button id="search" type="button" class="btn btn-block btn-primary btn-sm" style="margin-top: 4%;" (click)="search()">Search</button>
                                                    </div>
                                
                                                    <div class="form-group col-md-2 col-sm-4 col-xs-4" style="float:left;" *ngIf="hardcode_2DHistoryLogs_Add_bool === true" >
                                                        <label></label>
                                                        <button type="button" class="btn btn-block btn-success btn-sm" style="margin-top: 4%;" [routerLink] = "['/twoDHistoryLog']"> Add New </button>
                                                    </div>
                                                    
                                                </div> 
                                                
                                                <div class="card-body">                                                    
                                                    <table width='100%' datatable [dtTrigger]="dttwoDHistoryLog" [dtOptions]="dtOptions"
                                                    id="{{'tblTwoDHistoryLog' + idTwoDHistoryLogsIndex}}" class="table table-bordered table-hover">
                                                        <thead>
                                                            <tr>
                                                                <th style="font-family: unicode;">Section</th>
                                                                <th style="font-family: unicode;">Set</th> 
                                                                <th style="font-family: unicode;">Value</th> 
                                                                <th style="font-family: unicode;">Result</th>
                                                                <th style="font-family: unicode;">Updated By</th>
                                                                <th style="font-family: unicode;">Updated Date</th> 
                                                                <th style="font-family: unicode;" *ngIf="hardcode_2DHistoryLogs_Edit_bool === true">Action</th> 
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr *ngFor="let item of twoDHistoryLogsList">
                                                                <td style="font-family: unicode;">{{item.section}}</td>
                                                                <td style="font-family: unicode;">{{item.set}}</td> 
                                                                <td style="font-family: unicode;">{{item.value}}</td> 
                                                                <td style="font-family: unicode;">{{item.result}}</td>
                                                                <td style="font-family: unicode;">{{item.updatedBy}}</td> 
                                                                <td style="font-family: unicode;">{{item.updatedAt | date: 'dd-MM-yyyy HH:mm:ss' }}</td> 
                                                                
                                                                <td style="font-family: unicode;" *ngIf="hardcode_2DHistoryLogs_Edit_bool === true">
                                                                    <a [routerLink] = "['/twoDHistoryLog', item.objectId]"><i class="fas fa-edit" style="color:#4b88eb"></i></a>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                      
                                          </div>
                                        </div>
                            
                                      </div>
                                    </form> <!--end of all tabs form-->
                                </div>
                                <!-- /.card-body -->
                            </div>
                        </div>

                    </div>
                </form>
                <!-- /.row -->

                <!-- /.row -->
            </div>
            <!--/. container-fluid -->
        </section>
        <!-- /.content -->
    </div>

    <aside class="control-sidebar control-sidebar-dark">
        <!-- Control sidebar content goes here -->
    </aside>

    <app-footer></app-footer>

    <ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#fff" type="square-jelly-box">
        <p style="font-size: 16px; color: white">Loading...</p>
    </ngx-spinner>

</div>


<div id="deleteData" class="modal fade">
    <div class="modal-dialog">
        <div class="modal-content">

            <div class="modal-body">
                <form>

                    <div class="row" style="margin-top: 5%;margin-bottom: 3%;margin-left: 9%;">
                        <div class="form-group col-md-12">
                            <h5> This Account is used by another device. 
                                Please Sign Out. Login Again. </h5>
                        </div>
                    </div>

                    <div class="row" style="margin-left: 40%;margin-bottom: 3%;">
                        <div class="col-md-5 col-sm-6" id="deleteBtn">
                            <button type="button" class="btn btn-block btn-success" (click)="OkLogout()"> Ok </button>
                        </div>
                    </div>
                </form>
            </div>

        </div>
    </div>
</div>