import { Component, OnInit, ViewChild } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,HttpErrorResponse } from '@angular/common/http';
import { Router, NavigationEnd } from '@angular/router';
import { DataTableDirective } from 'angular-datatables';

import { LocalStorageService } from 'ngx-webstorage';
import { NgxSpinnerService } from "ngx-spinner";

import { catchError, retry } from 'rxjs/operators';
import { throwError } from 'rxjs';

import { DtoService } from '../../service/dto.service';
import { FunctService } from '../../service/funct.service';
import { ToastrService } from 'ngx-toastr';
import { DatePipe } from '@angular/common'

import { Subject } from 'rxjs';
declare var $: any;

@Component({
  selector: 'app-risky',
  templateUrl: './risky.component.html',
  styleUrls: ['./risky.component.css']
})
export class RiskyComponent implements OnInit {

  @ViewChild(DataTableDirective)
  dtElement: DataTableDirective;
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();

  phoneNo: string= '';
  phone_no: string= '';
  name: string= '';
  accountno:string= '';
  referralCode: string= '';
  status: string= '';
  type: string= '';
  agentList: any;
  idIndex: any;
  gameproviderList : any;
  providerId : any;
  alldate: any;
  alltodate:any;
  alltodayDate: any;
  alltodaytodate:any;
  singleDate: string = '';
  approveDate: string = '';
  alltodatechangeDate : any;
  allchangeDate: any;
  alltoDate: string = '';

  constructor(private storage: LocalStorageService, private spinner: NgxSpinnerService, private toastr: ToastrService, private http: HttpClient, private dto: DtoService, private router: Router,
    private funct: FunctService,private datepipe: DatePipe) {
    this.idIndex = 1;
    this.status = 'ACTIVE';
    this.getAllProvider();
    this.storage.store('isNotiSong', "");

    this.alldate = new Date();
    this.alltodayDate = this.datepipe.transform(this.alldate, 'MMM dd, yyyy');   
    this.alltodate = new Date(); 
    this.alltodaytodate = this.datepipe.transform(this.alltodate, 'MMM dd, yyyy');

    // if (!this.storage.retrieve('ALLTagTopupFromDate')) {
    //   this.alldate = new Date();
    //   this.alltodayDate = this.datepipe.transform(this.alldate, 'MMM dd, yyyy');      
    // }
    // else {      
    //   this.alldate = new Date(this.storage.retrieve('ALLTagTopupFromDate'));
    //   this.alltodayDate = this.datepipe.transform(this.alldate, 'MMM dd, yyyy');
    // }
    // if (!this.storage.retrieve('ALLTagTopupToDate')) {
    //   this.alltodate = new Date(); 
    //   this.alltodaytodate = this.datepipe.transform(this.alltodate, 'MMM dd, yyyy');
    // }
    // else {      
    //   this.alltodate = new Date(this.storage.retrieve('ALLTagTopupToDate'));
    //   this.alltodaytodate = this.datepipe.transform(this.alltodate, 'MMM dd, yyyy');
    // } 
    
    
   }
  ngOnInit(): void {   
    this.router.routeReuseStrategy.shouldReuseRoute = function () {
      return false;
    };

    this.router.events.subscribe((evt) => {
      if (evt instanceof NavigationEnd) {        
        this.router.navigated = false;       
        window.scrollTo(0, 0);
      }
    });

    this.dtOptions = {
      responsive: true,
      order:[],
      paging: false,
      info : true,
      dom: "Bfrltip", 
    }

    this.dtOptions.columnDefs = [
    ];
    if(!this.storage.retrieve('loadFlag')){
      this.storage.store('loadFlag', 'noLoad');
      setTimeout(function(){
        location.reload();
      }, 5);
    }
    else{
      this.storage.clear('loadFlag');
    }
   
  }
  ngAfterViewInit(){

  }

  handleError(error: HttpErrorResponse){
    if(error.status == 423)
    {
      this.spinner.hide();
      $("#deleteData").modal("show");
    }
    if(error.status == 403)
    {
      this.spinner.hide();
      var id = 'tblAgent' + this.idIndex;
      var table = $('#' + id).DataTable();
      this.toastr.error("Limited Access.", 'Invalid!', {
        timeOut: 3000,
        positionClass: 'toast-top-right',
        });
    }
    return throwError(error);
    }
    OkLogout()
    {
      window.location.href ="./ad-login";
    } 

    Search(searchkey){
    this.agentList = [];
    var id = 'tblAgent' + this.idIndex;
    var table = $('#' + id).DataTable();
    table.destroy();
    this.idIndex = this.idIndex +1;
    this.spinner.show();
    this.dto.token = this.storage.retrieve('token');
    let headers = new HttpHeaders();
    headers = headers.set('Authorization', this.dto.token);
    let params = new HttpParams();
    this.singleDate = $("#singleDate").val();
    this.alltoDate = $("#alltodate").val();
    if(searchkey =="btnsearch")
    {
        if (this.singleDate == '' || this.singleDate == undefined) {         
          this.allchangeDate = this.alltodayDate;
        }
        else {      
          this.allchangeDate = this.singleDate;
        }
      
        if (this.alltoDate == '' || this.alltoDate == undefined) {    
          this.alltodatechangeDate = this.alltodaytodate;
        }
        else {      
          this.alltodatechangeDate = this.alltoDate;
        }
    }
    else 
    {
      if (this.singleDate == '' || this.singleDate == undefined) {  
        this.alldate = new Date();
        this.allchangeDate = this.datepipe.transform(this.alldate, 'MMM dd, yyyy');  
      }
      else {
        this.alldate = new Date();
        this.allchangeDate = this.datepipe.transform(this.alldate, 'MMM dd, yyyy');   
      }        
      if (this.alltoDate == '' || this.alltoDate == undefined) {          
       
        this.alltodate = new Date();
        this.alltodatechangeDate = this.datepipe.transform(this.alltodate, 'MMM dd, yyyy'); 
      }
      else {    
        this.alltodate = new Date();
        this.alltodatechangeDate = this.datepipe.transform(this.alltodate, 'MMM dd, yyyy');  
      }
    }
    params = params.set('phone_no',this.phone_no).set('startTime',this.allchangeDate).set('endTime',this.alltodatechangeDate).set('referralCode',this.referralCode).set('type', this.type).set('gameType', this.providerId).set('name',this.name).set("pageNumber","1").set("rowsOfPage","100");
    this.http.get(this.funct.ipaddress + 'user/risklist', { params: params, headers: headers } )
    .pipe(
      catchError(this.handleError.bind(this))
     )
    .subscribe(
      result => {
        this.dto.Response = {};
        this.dto.Response = result.results;
        this.agentList = this.dto.Response;
        this.dtTrigger.next();
        this.spinner.hide();
      }
    );
  }


  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }

  getAllProvider()
 {
  this.dto.token = this.storage.retrieve('token');
  let headers = new HttpHeaders();
  headers = headers.set('Authorization',  this.dto.token);
  this.http.get(this.funct.ipaddress + 'gameProvider/getGameProviderList', {headers: headers })
  .pipe(
    catchError(this.handleError.bind(this))
   )
  .subscribe(
    result => {
      this.dto.Response = {};
      this.dto.Response = result;
      this.gameproviderList = this.dto.Response;
      this.gameproviderList.push("None");
    });
 }
 flagProvider()
 {
     this.providerId = $("#providerId").val();
     this.storage.clear('providerId');
     this.storage.store('providerId', Number(this.providerId));
 }

onChangeSingle() {
 
 this.storage.store('ALLTagTopupFromDate', this.alldate.toString());
}
onChangeAllToDate() {
  
 this.storage.store('ALLTagTopupToDate', this.alltodate.toString());
}

}
