import { Component, OnInit } from '@angular/core';
@Component({
  selector: 'app-bet-history',
  templateUrl: './bet-history.component.html',
  styleUrls: ['./bet-history.component.css']
})
export class BetHistoryComponent implements OnInit {
 constructor() { 
 
  }

  ngOnInit(): void {
  }


}
