<div class="wrapper">
    <app-header-sidebar></app-header-sidebar>
    <div class="content-wrapper">
        <div class="content-header">
            <div class="container-fluid">
            </div>
        </div>

        <section class="content">
            <div class="container-fluid">
                <form>
                    <div class="row">
                        <div class="col-md-12">
                            <div class="card">
                                <div class="card-header">
                                    <h3 class="card-title" style="font-weight: bold;"> Modern Internet  </h3>
                                </div>
                                <div class="card-body">
                                    <form>
                                      <div class="row">
                                        <div class="col-md-12">                                    
                                            <div class="row">
                                                <label class="col-md-2"> Modern 09:30 </label>
                                                <div class="form-group col-md-3">
                                                    <input type = "text" class="form-control form-control-sm" [(ngModel)]="Modern0930"  [ngModelOptions]="{standalone: true}" id = "Modern0930">
                                                </div> 

                                                <label class="col-md-2"> Internet 09:30 </label>
                                                <div class="form-group col-md-3">
                                                    <input type = "text" class="form-control form-control-sm" [(ngModel)]="Internet0930"  [ngModelOptions]="{standalone: true}" id = "Internet0930">
                                                </div> 
                                            </div>
                                            <div class="row">
                                                <label class="col-md-2"> Modern 02:30 </label>
                                                <div class="form-group col-md-3">
                                                    <input type = "text" class="form-control form-control-sm" [(ngModel)]="Modern0230"  [ngModelOptions]="{standalone: true}" id = "Modern0230">
                                                </div> 

                                                <label class="col-md-2"> Internet 02:30 </label>
                                                <div class="form-group col-md-3">
                                                    <input type = "text" class="form-control form-control-sm" [(ngModel)]="Internet0230"  [ngModelOptions]="{standalone: true}" id = "Internet0230">
                                                </div> 
                                            </div>

                                            <div class="row" style="margin-top: 1%;" *ngIf="hardcode_edit_bool===true">
                                                <div class="col-md-8 col-sm-6"></div>
                                                <div class="col-md-2 col-sm-6">
                                                    <button type="button" class="btn btn-block btn-success"  (click)="save()"> Save </button>&nbsp;
                                                </div>
                                            </div>                                      
                                        </div>                            
                                      </div>
                                    </form> 
                                </div>                                
                            </div>
                        </div>
                    </div>
                </form>              
            </div>          
        </section>
    </div>

    <aside class="control-sidebar control-sidebar-dark">
        <!-- Control sidebar content goes here -->
    </aside>

    <app-footer></app-footer>

    <ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#fff" type="square-jelly-box">
        <p style="font-size: 16px; color: white">Loading...</p>
    </ngx-spinner>

</div>


<div id="deleteData" class="modal fade">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-body">
                <form>
                    <div class="row" style="margin-top: 5%;margin-bottom: 3%;margin-left: 9%;">
                        <div class="form-group col-md-12">
                            <h5> This Account is used by another device. 
                                Please Sign Out. Login Again. </h5>
                        </div>
                    </div>

                    <div class="row" style="margin-left: 40%;margin-bottom: 3%;">
                        <div class="col-md-5 col-sm-6" id="deleteBtn">
                            <button type="button" class="btn btn-block btn-success" (click)="OkLogout()"> Ok </button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>