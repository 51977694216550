<div class="wrapper">
  <!-- Navbar -->
  <app-header-sidebar></app-header-sidebar>
  <!-- Content Wrapper. Contains page content -->
  <div class="content-wrapper">
      <!-- Content Header (Page header) -->
      <div class="content-header">
          <div class="container-fluid">           
          </div><!-- /.container-fluid -->
      </div>
      <!-- /.content-header -->

      <!-- Main content -->
      <section class="content">
          <div class="container-fluid">
              <form>
                  <div class="row">
                      <div class="col-md-12">
                          <div class="card">
                              <div class="card-header">
                                  <h3 class="card-title" style="font-weight: bold;">{{type}} Winner Input Detail</h3>
                              </div>
                              <!-- /.card-header -->
                              <div class="card-body">
                                  <form>
                                      <div class="row">
                                          <label class="col-md-2">Name</label>                                
                                          <div class="form-group col-md-3">
                                              <input type = "text" id="name" class="form-control form-control-sm" [(ngModel)]="widetail.name" [ngModelOptions]="{standalone: true}">
                                          </div>
                               
                                          <div class="col-md-1"></div>
                                          <label class="col-md-2">Bet Amount</label>
                                          <div class="form-group col-md-3">
                                              <input id="betAmount" type="text" class="form-control form-control-sm" [(ngModel)]="widetail.betAmount" [ngModelOptions]="{standalone: true}" (keyup) = "calculatedWinAmount()" required >
                                          </div>
                                      </div>

                                      <div class="row">
                                        <label class="col-md-2">Phone No</label>                                
                                        <div class="form-group col-md-3">
                                            <input type = "text" id="phoneNo" class="form-control form-control-sm" [(ngModel)]="widetail.phoneNo" [ngModelOptions]="{standalone: true}">
                                        </div>
                             
                                        <div class="col-md-1"></div>
                                        <label class="col-md-2">Odd</label>
                                        <div class="form-group col-md-3">
                                            <input id="odd" type="text" class="form-control form-control-sm" [(ngModel)]="widetail.odd" [ngModelOptions]="{standalone: true}" disabled>
                                        </div>
                                      </div>

                                    <div class="row">
                                        <label class="col-md-2">Status</label>                                
                                        <div class="form-group col-md-3 radio">
                                          <div class="form-group clearfix">
                                            <div class="icheck-success d-inline">
                                              <input type="radio" name="status" checked id="statusActive" value="Active" [(ngModel)]="widetail.status" [ngModelOptions]="{standalone: true}">
                                              <label for="statusActive"> Active &nbsp;&nbsp; </label>
                                            </div>
                                            <div class="icheck-success d-inline">
                                              <input type="radio" name="status" id="statusInactive" value="Inactive" [(ngModel)]="widetail.status" [ngModelOptions]="{standalone: true}">
                                              <label for="statusInactive"> Inactive </label>
                                            </div>
                                          </div>
                                        </div>
                             
                                        <div class="col-md-1"></div>
                                        <label class="col-md-2">Win Amount</label>
                                        <div class="form-group col-md-3">
                                            <input id="winAmount" type="text" class="form-control form-control-sm" [(ngModel)]="widetail.winAmount" [ngModelOptions]="{standalone: true}" >
                                        </div>
                                    </div>
                                    <!--amk-->
                                    <div class="row">
                                        <label class="col-md-2"> Image </label>

                                        <div class="form-group col-md-2">
                                            <input #file type="file" accept='image/*'
                                                (change)="preview(file.files)" /> 
                                                
                                                <img [src]="imgURL" height="200" width="255" *ngIf="imgURL"
                                                style="margin-top: 5%;">
                                        </div>

                                        <div class="form-group col-md-2">
                                            <button (click)="onDelete(widetail.id)">
                                                <span title = "Remove this image" style = "color : red;cursor: pointer;"  class="fas fa-trash-alt"  ></span>
                                              </button>
                                            </div>

                                    </div>

                                    <div class="row" style="margin-top: 1%;">
                                        <div class="col-md-2 col-sm-6">
                                          <button type="button" id="idsave" class="btn btn-block btn-success" (click)="edit()">Save</button>&nbsp;
                                        </div>                                        
                          
                                        <div class="col-md-2 col-sm-6">
                                          <button type="button" class="btn btn-block btn-danger" [routerLink] = "['/winner-input', type]">Cancel</button>
                                        </div>  
                                    </div>

                                  </form>
                              </div>
                              <!-- /.card-body -->
                          </div>
                      </div>
                  </div>

                  <div class="row">
                      <div class="col-md-12">
                          <div class="card">
                              <div class="card-body">
                                  <form>
                                      <div class="row" style="margin-top: 1.7%;">
                                          <label class="col-md-2"> Created Date </label>
                                          <div class="form-group col-md-3">
                                              <input class="form-control form-control-sm" [(ngModel)]="widetail.createdDate_Str" [ngModelOptions]="{standalone: true}" disabled>
                                          </div>
                                          <div class="col-md-1"></div>
                                          <label class="col-md-2"> Created By</label>
                                          <div class="col-md-3">
                                              <input class="form-control form-control-sm" [(ngModel)]="widetail.createdBy" [ngModelOptions]="{standalone: true}" disabled>
                                          </div>
                                      </div>

                                      <div class="row" style="margin-top: 1%;"> 
                                          <label class="col-md-2"> Updated Date </label>
                                          <div class="form-group col-md-3">
                                              <input class="form-control form-control-sm" [(ngModel)]="widetail.updatedDate_Str" [ngModelOptions]="{standalone: true}" disabled>
                                          </div>
                                          <div class="col-md-1"></div>
                                          <label class="col-md-2"> Updated By </label>
                                          <div class="col-md-3">
                                              <input class="form-control form-control-sm" [(ngModel)]="widetail.updatedBy" [ngModelOptions]="{standalone: true}" disabled>
                                          </div>
                                      </div>

                                  </form>
                              </div>
                              <!-- /.card-body -->
                          </div>
                      </div>
                  </div>
                  
              </form>
              <!-- /.row -->

              <!-- /.row -->
          </div>
          <!--/. container-fluid -->
      </section>
      <!-- /.content -->
  </div>

  <aside class="control-sidebar control-sidebar-dark">
      <!-- Control sidebar content goes here -->
  </aside>

  <app-footer></app-footer>

  <ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#fff" type="square-jelly-box">
      <p style="font-size: 16px; color: white">Loading...</p>
  </ngx-spinner>

</div>

<div id="deleteData" class="modal fade">
  <div class="modal-dialog">
      <div class="modal-content">
          <div class="modal-body">
              <form>
                  <div class="row" style="margin-top: 5%;margin-bottom: 3%;margin-left: 9%;">
                      <div class="form-group col-md-12">
                          <h5> This Account is used by another device. 
                              Please Sign Out. Login Again. </h5>
                      </div>
                  </div>

                  <div class="row" style="margin-left: 40%;margin-bottom: 3%;">
                      <div class="col-md-5 col-sm-6" id="deleteBtn">
                          <button type="button" class="btn btn-block btn-success" (click)="OkLogout()"> Ok </button>
                      </div>
                  </div>
              </form>
          </div>

      </div>
  </div>
</div>

