<div class="wrapper">
  <app-header-sidebar></app-header-sidebar>

  <div class="content-wrapper">
    <!-- Content Header (Page header) -->
    <div class="content-header">
      <div class="container-fluid">
        <div class="row mb-2" style="border: 1px solid #ced4da;border-radius: 0.25rem;">
          <div class="col-sm-8">
            <h3 class="m-0 text-dark" style="font-weight: bold;padding: 3px 0px 3px 5px;"> Risky Player </h3>
          </div>
        </div><!-- /.row -->
      </div><!-- /.container-fluid -->
    </div>
    <!-- /.content-header -->

    <!-- Main content -->
    <section class="content">
      <div class="container-fluid">
        <form>
          <div class="row">

            <div class="form-group col-md-2">
              <label>Account No</label>
              <input class="form-control form-control-sm" [(ngModel)]="phone_no" [ngModelOptions]="{standalone: true}">
            </div>


            <div class="form-group col-md-2">
              <label>From Date</label>
              <input id="singleDate" readonly class="form-control form-control-sm" placeholder="Select Date"
                [ngxDatePicker]="dateInstanceSingle"  [(ngModel)]="alldate"
                [ngModelOptions]="{standalone: true}" (ngModelChange)="onChangeSingle()">
              <ngx-date-picker #dateInstanceSingle></ngx-date-picker>
            </div>

            <div class="form-group col-md-2">
              <label>To Date</label>
              <input id="alltodate" readonly class="form-control form-control-sm" placeholder="Select Date"
                [ngxDatePicker]="dateInstanceSingle1"  [(ngModel)]="alltodate"
                [ngModelOptions]="{standalone: true}" (ngModelChange)="onChangeAllToDate()">
              <ngx-date-picker #dateInstanceSingle1></ngx-date-picker>
            </div>


            <div class="form-group col-md-2">
              <label>Type</label>
              <select class="status form-control form-control-sm col-md-12 col-sm-4 col-xs-4" [(ngModel)]="type"
                [ngModelOptions]="{standalone: true}">
                <option value="" selected>All</option>                
                <option value="Fund in/out">Fund in/out</option>               
              </select>
            </div>

            <div class = "form-group col-md-2">
              <label>Game Provider</label>               
                <select (change) = "flagProvider()" id = "providerId" class="form-control form-control-sm col-md-12 col-sm-4 col-xs-4"
                 [(ngModel)]="providerId" [ngModelOptions]="{standalone: true}">
                  <option *ngFor="let provider of gameproviderList" value = "{{provider.provider_id}}" >
                    {{provider.display_name}}
                  </option>
               </select>
             
          </div>

            <div class="form-group col-md-2 col-sm-4 col-xs-4">
              <label></label>
              <button id="search" type="button" class="btn btn-block btn-primary btn-sm" style="margin-top: 4%;"
                 (click)="Search('btnsearch')">Search</button> 
               
            </div>
            <div class="col-md-12">
              <div class="card">
                <div class="card-header">
                  <h3 class="card-title" style="font-weight: bold;"> Risky Player List </h3>
                </div>       
                <div class="card-body">
                  <table width='100%' id="{{'tblAgent' + idIndex}}" datatable [dtTrigger]="dtTrigger"
                    [dtOptions]="dtOptions" class="table table-bordered table-hover">
                    <thead>
                      <tr>
                        <th style="font-family: unicode;">Name</th>
                        <th style="font-family: unicode;" class="center-text">Account No</th>                       
                        <th style="font-family: unicode;" class="center-text">TranNum</th>
                        <th style="font-family: unicode;" class="center-text">TotalAmount</th>
                        <th style="font-family: unicode;" class="center-text">DifferentAmount</th>                 
                        <th style="font-family: unicode;" class="center-text">StartTime</th>
                        <th style="font-family: unicode;" class="center-text">EndTime</th>
                        <th style="font-family: unicode;" class="center-text">Duration</th>
                        <th style="font-family: unicode;" class="center-text">Games</th>
                        <th style="font-family: unicode;" class="center-text">FrequentTrans</th>                       
                        <th style="font-family: unicode;" class="center-text">type</th>
                        <th style="font-family: unicode;" class="center-text">IP</th>                 
                        <th style="font-family: unicode;" class="center-text">Last Login Time</th>
                        <th style="font-family: unicode;" class="center-text">Device ID</th>
                        
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let agent of agentList">
                        <td style="font-family: unicode;" class="center-text">{{agent.name}}</td>
                 
                        <td style="font-family: unicode;" class="center-text">
                          <a [routerLink] = "['/user-detail', agent.userid]" style="text-decoration: underline; cursor: pointer;">{{agent.accountNo}}
                        </a></td>
                        <td style="font-family: unicode;" class="center-text" >{{agent.tranNum}}</td>
                        <td style="font-family: unicode;" class="center-text">{{agent.totalAmount | number: '1.0-0' }}</td>
                        <td style="font-family: unicode;" class="center-text">{{agent.differentAmount | number: '1.0-0' }}</td>                       
                        <td style="font-family: unicode;" class="center-text">{{agent.startTimeStr}}</td>
                        <td style="font-family: unicode;" class="center-text">{{agent.endTimeStr}} </td>
                        <td style="font-family: unicode;" class="center-text">{{agent.duration_str }}   </td>
                        <td style="font-family: unicode;" class="center-text">{{agent.games}}</td>                     
                        <td style="font-family: unicode;" class="center-text"> {{agent.frequentTrans_str}} </td>
                        <td style="font-family: unicode;" class="center-text">{{agent.type}}</td>
                        <td style="font-family: unicode;" class="center-text">{{agent.ipAddress}}</td>
                        <td style="font-family: unicode;" class="center-text">{{agent.last_used_time}}</td>
                        <td style="font-family: unicode;" class="center-text">{{agent.deviceID}}</td>
                        
                      </tr>                      
                    </tbody>
                  </table>
                </div>              
              </div>
            </div>
          </div>
        </form>
      </div>      
    </section>
  </div>

  <aside class="control-sidebar control-sidebar-dark">   
  </aside>

  <app-footer></app-footer>
  <ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#fff" type="square-jelly-box">
    <p style="font-size: 16px; color: white">Loading...</p>
  </ngx-spinner>
</div>

<div id="deleteData" class="modal fade">
  <div class="modal-dialog">
      <div class="modal-content">

          <div class="modal-body">
              <form>

                  <div class="row" style="margin-top: 5%;margin-bottom: 3%;margin-left: 9%;">
                      <div class="form-group col-md-12">
                          <h5> This Account is used by another device. 
                              Please Sign Out. Login Again. </h5>
                      </div>
                  </div>

                  <div class="row" style="margin-left: 40%;margin-bottom: 3%;">
                      <div class="col-md-5 col-sm-6" id="deleteBtn">
                          <button type="button" class="btn btn-block btn-success" (click)="OkLogout()"> Ok </button>
                      </div>
                  </div>
              </form>
          </div>

      </div>
  </div>
</div>


