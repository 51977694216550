
     
                    <div class="row">

                        <div class="form-group col-md-2">
                            <label> From Date </label>
                            <input id="fromDate" readonly class="form-control form-control-sm" placeholder="Select Date"
                                [ngxDatePicker]="fromdateInstanceSingle" [value]="fromDate" [(ngModel)] = "date"  [ngModelOptions]="{standalone: true}"
                                (valueChange)="onChangeFromDate()">
                            <ngx-date-picker #fromdateInstanceSingle></ngx-date-picker>
                        </div>

                        <div class="form-group col-md-2">
                            <label> To Date </label>
                            <input id="toDate" readonly class="form-control form-control-sm"
                                placeholder="Select To Date" [ngxDatePicker]="todateInstanceSingle" [value]="toDate"
                                [(ngModel)] = "todate" [ngModelOptions]="{standalone: true}"
                                (valueChange)="onChangeToDate()">
                            <ngx-date-picker #todateInstanceSingle></ngx-date-picker>
                        </div>

                        <div class="form-group col-md-2">
                            <label>Status</label>
                            <select class="status form-control form-control-sm col-md-12 col-sm-4 col-xs-4"
                                [(ngModel)]="status" [ngModelOptions]="{standalone: true}">
                                <option value="ACTIVE" selected>Active</option>
                                <option value="INACTIVE">Inactive</option>
                            </select>
                        </div>

                        <div class="col-md-2">
                        </div>

                        <div class="form-group col-md-2 col-sm-4 col-xs-4">
                            <label></label>
                            <button id="search" type="button" class="btn btn-block btn-primary btn-sm"
                                style="margin-top: 4%;" (click)="search()">Search</button>
                        </div>

                        <div class="col-md-2 col-sm-6 col-xs-6" *ngIf="hardcode_add_bool === true">
                            <label></label>
                            <button type="button" class="btn btn-block btn-success btn-sm" style="margin-top: 4%;"
                                [routerLink]="['/threed-result-configuration-detail']">
                                Add New
                            </button>
                        </div>

                        <div class="col-md-12">
                            <div class="card">
                                <div class="card-header">
                                    <h3 class="card-title" style="font-weight: bold;"> 3D Result Configuration</h3>
                                </div>
                                <!-- /.card-header -->
                                <div class="card-body">
                                    <table width='100%' id="{{'tblThreeDResultConfig' + idIndex}}" datatable [dtTrigger]="dtTrigger"
                                        [dtOptions]="dtOptions" class="table table-bordered table-hover">
                                        <thead>
                                            <tr>
                                                <th style="font-family: unicode;">From Date</th>
                                                <th style="font-family: unicode;">To Date(Result Date)</th>
                                                <th style="font-family: unicode;">Status</th>
                                                <th style="font-family: unicode;">Created Date</th>
                                                <th style="font-family: unicode;">Created By</th>
                                                <th style="font-family: unicode;">Updated Date</th>
                                                <th style="font-family: unicode;">Updated By</th>
                                                <th style="font-family: unicode;" *ngIf="hardcode_edit_bool === true">Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr *ngFor="let threedresult of threedResultConfiguration">
                                                <td style="font-family: unicode;">{{threedresult.from_date_Str}}</td>
                                                <td style="font-family: unicode;">{{threedresult.to_date_Str}}</td>
                                                <td style="font-family: unicode;">{{threedresult.status}}</td>
                                                <td style="font-family: unicode;">{{threedresult.created_date_Str}}</td>
                                                <td style="font-family: unicode;">{{threedresult.created_by_name}}</td>
                                                <td style="font-family: unicode;">{{threedresult.updated_date_Str}}</td>
                                                <td style="font-family: unicode;">{{threedresult.updated_by_name}}</td>
                                                <td style="font-family: unicode;" *ngIf="hardcode_edit_bool === true"><a
                                                        [routerLink]="['/threed-result-configuration-detail', threedresult.id]"><i
                                                    class="fas fa-edit" style="color:#4b88eb"></i></a>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <!-- /.card-body -->
                            </div>
                        </div>

                    </div>
           

    <ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#fff" type="square-jelly-box">
        <p style="font-size: 16px; color: white">Loading...</p>
    </ngx-spinner>



<div id="deleteData" class="modal fade">
    <div class="modal-dialog">
        <div class="modal-content">

            <div class="modal-body">
                <form>

                    <div class="row" style="margin-top: 5%;margin-bottom: 3%;margin-left: 9%;">
                        <div class="form-group col-md-12">
                            <h5> This Account is used by another device. 
                                Please Sign Out. Login Again. </h5>
                        </div>
                    </div>

                    <div class="row" style="margin-left: 40%;margin-bottom: 3%;">
                        <div class="col-md-5 col-sm-6" id="deleteBtn">
                            <button type="button" class="btn btn-block btn-success" (click)="OkLogout()"> Ok </button>
                        </div>
                    </div>
                </form>
            </div>

        </div>
    </div>
</div>