import { Component, OnInit, ViewChild } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,HttpErrorResponse } from '@angular/common/http';

import { DataTableDirective } from 'angular-datatables';
import { DatePipe } from '@angular/common'

import { catchError, retry } from 'rxjs/operators';
import { throwError } from 'rxjs';

import { LocalStorageService } from 'ngx-webstorage';
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from 'ngx-toastr';

import { DtoService } from '../../service/dto.service';
import { FunctService } from '../../service/funct.service';

import { Subject } from 'rxjs';


// import { Router, ActivatedRoute   } from '@angular/router';
// import { Router, NavigationEnd } from '@angular/router';

import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';



declare var $: any;

@Component({
  selector: 'app-twod-winners',
  templateUrl: './twod-winners.component.html',
  styleUrls: ['./twod-winners.component.css']
})
export class TwodWinnersComponent implements OnInit {

  @ViewChild(DataTableDirective)
  dtElement: DataTableDirective;
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();

  idIndex: any;
  token: any;
  twodwinnerId: any;
  confrimId: any;
  phoneNo: string = '';
  name: string = '';
  singleDate: string = '';
  time: string = '';
  status: string = '';
  twodwinnerList: any;
  confirmAllCheck: any = false;
  confirmallArr: any;
  isChecked: any = false;
  winnerDate: any;
  winnertodayDate: any;
  winnerchangeDate: any;
  uiDisable: any = false;
  confirm_by : '';
  todate: any; //add the following
  toDate : any;
  todatetodaydate : any;
  todatechangedate : any;
 

  alltodaytodate : any;
  alldate: any;
  alltodayDate : any;
  alltoDate: any;
  alltodate : any;
  alltodatechangeDate: any;
  //twodWinnerDTO: any;
  tbet_amount : any;
  twin_amount : any;
  sectionList : any;
  packageList: any;
  packageType:any;

  hardcode_edit :any;
  hardcode_add :any;
  hardcode_view: any;
  hardcode_view_bool : any;
  hardcode_add_bool : any;
  hardcode_edit_bool : any;
  detail :any;

  constructor(private storage: LocalStorageService, private spinner: NgxSpinnerService, private http: HttpClient, private dto: DtoService,
    private router: Router,private route: ActivatedRoute, private funct: FunctService, private datepipe: DatePipe, private toastr: ToastrService,) 
    { 
      this.storage.store('isNotiSong', "");
    this.hardcode_view = "Winners_View";
    this.hardcode_edit = "Winners_Edit";

    this.dto.menuCodes = this.storage.retrieve('menucodes');

    const filteredMenuCodes_view = [];  
    const filteredMenuCodeds_edit =[];
    this.dto.menuCodes.forEach(menuCode => {
      if (menuCode === this.hardcode_view) {
        filteredMenuCodes_view.push(menuCode);
      }    
      if (menuCode === this.hardcode_edit) {
        filteredMenuCodeds_edit.push(menuCode);
      }
    });
    if(filteredMenuCodes_view.length != 0)
    {
          this.hardcode_view_bool = true;
    } 
    else
    {
        this.hardcode_view_bool = false;
    } 
    if(filteredMenuCodeds_edit.length != 0)
    {
          this.hardcode_edit_bool = true;
    }
    else 
    {
      this.hardcode_edit_bool = false;
    }

    this.idIndex = 1;

    this.winnerDate = new Date();
    console.log("this.approvedate>> " + this.winnerDate);
    this.winnertodayDate = this.datepipe.transform(this.winnerDate, 'MMM dd, yyyy');
    console.log("this.todayDate>> " + this.winnertodayDate);

    this.alltodate = new Date();
    console.log("this.alldate>> " + this.alltodate);
    this.alltodayDate = this.datepipe.transform(this.alltodate, 'MMM dd, yyyy');
    console.log("this.alltodayDate>> " + this.alltodayDate);
    this.status = "PENDING";
    $(".total").hide();
    this.getAllSection();
    this.getPackageList();
    this.time="null";
    this.packageType="null";
    this.search();
  }
  ngOnInit(): void {

    this.router.routeReuseStrategy.shouldReuseRoute = function () {
      return false;
    };

 

  this.detail = this.route.snapshot.paramMap.get("detail");
  
  
  // if(this.detail =="detail")
  // {
  //   if (!this.storage.retrieve('status')) {
  //     this.status = '';
  //   }
  //   else {
  //     this.status = this.storage.retrieve('status');
      
  //   }
  // }


    this.router.events.subscribe((evt) => {
      if (evt instanceof NavigationEnd) {
        // trick the Router into believing it's last link wasn't previously loaded
        this.router.navigated = false;
        // if you need to scroll back to top, here is the right place
        window.scrollTo(0, 0);
      }
    });

    this.dtOptions = {
      responsive: true,
      order: []
    }

    this.dtOptions.columnDefs = [
      { targets: [0], orderable: false },
      { targets: [7], orderable: false }
    ];

    if (!this.storage.retrieve('loadFlag')) {
      this.storage.store('loadFlag', 'noLoad');
      setTimeout(function () {
        location.reload();
      }, 5);
    }
    else {
      this.storage.clear('loadFlag');
    }
  }

  handleError(error: HttpErrorResponse){
    if(error.status == 423)
    {
      this.spinner.hide();
      $("#deleteData").modal("show");
    }
    if(error.status == 201)
    {
      this.spinner.hide();
      this.router.navigate(['/winhistory/twod']).then(() => {
        this.toastr.error('Please Select Winner', 'Invalid!', {
          timeOut: 3000,
          positionClass: 'toast-top-right'
        });
      })
    }
    if(error.status == 403)
    {
      this.spinner.hide();
      var id = 'tblWinners' + this.idIndex;
      var table = $('#' + id).DataTable();
      this.toastr.error("Limited Access.", 'Invalid!', {
        timeOut: 3000,
        positionClass: 'toast-top-right',
        });
    }
    return throwError(error);
    }
    OkLogout()
    {
      window.location.href ="./ad-login";
    } 

  search() {
    this.twodwinnerList = [];
    var id = 'tblWinners' + this.idIndex;
    var table = $('#' + id).DataTable();
    table.destroy();
    this.idIndex = this.idIndex + 1;
    this.spinner.show();
    this.dto.token = this.storage.retrieve('token');
    this.singleDate = $("#singleDate").val();
    this.alltoDate = $("#alltodate").val();
    var total_bet_amout = 0, total_win_amount = 0;
    if (this.singleDate == '' || this.singleDate == undefined) {
      this.winnerchangeDate = this.winnertodayDate;
    }
    else {
      this.winnerchangeDate = this.singleDate;
    }

    if (this.alltoDate == '' || this.alltoDate == undefined) {
      this.alltodatechangeDate = this.alltodayDate;
    }
    else {
      this.alltodatechangeDate = this.alltoDate;
    }

    this.storage.store('status', this.status.toString());   
    this.dto.status = this.storage.retrieve('status');

    let headers = new HttpHeaders();
    headers = headers.set('Authorization', this.dto.token);
    let params = new HttpParams();
    params = params.set('phoneNo', this.phoneNo).set('name', this.name).set('fromDate', this.winnerchangeDate)
    .set('toDate',this.alltodatechangeDate).set('time', this.time).set('status', this.status).set('packageType',this.packageType);
    console.log("params>> " + JSON.stringify(params));
    this.http.get(this.funct.ipaddress + 'winner/twod-by-params', { params: params, headers: headers })
    .pipe(
      catchError(this.handleError.bind(this))
     )
    .subscribe(
      result => {
        this.dto.Response = {};
        this.dto.Response = result;
        this.twodwinnerList = this.dto.Response;
        if(this.twodwinnerList != undefined){
         
          for (let i = 0; i < this.twodwinnerList.length; i++) {
            total_bet_amout = total_bet_amout + parseInt(this.twodwinnerList[i].total_bet_amout);
            this.tbet_amount = total_bet_amout;
            if (this.twodwinnerList[i].status == "CONFIRMED") {
              this.twodwinnerList[i].uiDisable = true;
             }
             else if(this.twodwinnerList[i].status == "PENDING"){
              this.twodwinnerList[i].uiDisable = false;
            }
            if(this.twodwinnerList[i].status == "PENDING")
            {
              this.twodwinnerList[i].created_by = '';
            }
          }
        }
        this.dtTrigger.next();
        this.spinner.hide();
      });
  }

  checkuncheckall() {
    if (this.isChecked == true) {
      this.isChecked = false;
    }
    else {
      this.isChecked = true;
    }
  }


  changeSelection(ev, id) {
    console.log("id..... " + id);
    var target = ev.target;
    this.confirmallArr = [];

    if (target.checked && id == 0) {
      for (let i = 0; i < this.twodwinnerList.length; i++) {
        this.twodwinnerList[i].status = "check";
      }
    }
    else if (target.checked && id != 0) {
      for (let i = 0; i < this.twodwinnerList.length; i++) {
        if (this.twodwinnerList[i].id == id) {
          this.twodwinnerList[i].status = "check";
        }
      }
    }
    else if (!target.checked && id == 0) {
      for (let i = 0; i < this.twodwinnerList.length; i++) {
        this.twodwinnerList[i].status = "uncheck";
      }
    }
    else if (!target.checked && id != 0) {
      for (let i = 0; i < this.twodwinnerList.length; i++) {
        if (this.twodwinnerList[i].id == id) {
          this.twodwinnerList[i].status = "uncheck";
        }
      }
    }
    this.checkedData();
  }

  checkedData() {
    for (let i = 0; i < this.twodwinnerList.length; i++) {
      if (this.twodwinnerList[i].status == "check") {
        this.confirmallArr.push(this.twodwinnerList[i].id);
      }
    }
    console.log("this.confirmallArr >>" + JSON.stringify(this.confirmallArr));
  }

  onChangeSingle() {
    $(document).ready(function () {
      this.singleDate = $("#singleDate").val();
    });
  }

  onChangeAllToDate() {
    $(document).ready(function () {
      this.alltoDate = $("#alltodate").val();
    });
  }

  confirmAll() {
    this.spinner.show();
    this.dto.token = this.storage.retrieve('token');
    let headers = new HttpHeaders();
    headers = headers.set('Authorization', this.dto.token);
    let formData = new FormData();
    formData.append("ids", this.confirmallArr);
    console.log(this.confirmallArr)
    this.http.post(this.funct.ipaddress + 'winner/twodConfirms', formData, { headers: headers })
    .pipe(
      catchError(this.handleError.bind(this))
     )
    .subscribe(
      result => {
        this.dto.Response = {};
        this.dto.Response = result;
        if (this.dto.Response.status == 'Success') {
          this.spinner.hide();
          this.router.navigate(['/winhistory/twod']).then(() => {
            this.toastr.success(this.dto.Response.message, 'Success!', {
              timeOut: 3000,
              positionClass: 'toast-top-right'
            });
          })
          this.twodwinnerList = this.dto.Response.twodwinnerListDTO
          for (let i = 0; i < this.twodwinnerList.length; i++) {
            if (this.twodwinnerList[i].status == "CONFIRMED") {
              this.twodwinnerList[i].uiDisable = true;
             }
             else if(this.twodwinnerList[i].status == "PENDING"){
              this.twodwinnerList[i].uiDisable = false;
              this.twodwinnerList[i].confirm_by = '';
            }
          }
        }
        else {
          this.spinner.hide();
          this.toastr.error(this.dto.Response.message, 'Invalid!', {
            timeOut: 3000,
            positionClass: 'toast-top-right',
          });
        }
        this.spinner.hide();
      });
  }

  confirmOne(id) {
    console.log("confirm id>>> " + id);
    this.confrimId = id;
    this.confirmallArr = [];
    this.confirmallArr.push(this.confrimId);
    console.log("this.confirmallArr>>> " + JSON.stringify(this.confirmallArr));
    this.spinner.show();
    this.dto.token = this.storage.retrieve('token');
    let headers = new HttpHeaders();
    headers = headers.set('Authorization', this.dto.token);
    let formData = new FormData();
    formData.append("ids",this.confirmallArr);
    this.http.post(this.funct.ipaddress + 'winner/twodConfirms', formData, {headers: headers })
    .pipe(
      catchError(this.handleError.bind(this))
     )
    .subscribe(
      result => {
        this.dto.Response = {};
        this.dto.Response = result;
        console.log("this.dto.Response>> " +JSON.stringify( this.dto.Response));
        if (this.dto.Response.status == 'Success') {
          this.spinner.hide();
          this.router.navigate(['/winhistory/twod']).then(() => {
            this.toastr.success(this.dto.Response.message, 'Success!', {
              timeOut: 3000,
              positionClass: 'toast-top-right'
            });
          })
          this.twodwinnerList = this.dto.Response.twodwinnerListDTO
          for (let i = 0; i < this.twodwinnerList.length; i++) {
            if (this.twodwinnerList[i].status == "CONFIRMED") {
              this.twodwinnerList[i].uiDisable = true;
             }
             else if(this.twodwinnerList[i].status == "PENDING"){
              this.twodwinnerList[i].uiDisable = false;
              this.twodwinnerList[i].confirm_by = '';
            }
          }
        }
        else {
          this.spinner.hide();
          this.toastr.error(this.dto.Response.message, 'Invalid!', {
            timeOut: 3000,
            positionClass: 'toast-top-right',
          });
        }
        this.spinner.hide();
      });

   
  }
  getAllSection()
  {
    this.http.get(this.funct.ipaddress + 'twodsection/getTwodSectionList').subscribe( 
      result => {
        this.dto.Response = {};
        this.dto.Response = result;
        this.sectionList = this.dto.Response;
      }
    );
  }
  
  onInput(event: InputEvent) {
    const myanmarCharactersRegex = new RegExp('^[\u1000-\u109F\uAA60-\uAA7F\uA9E0-\uA9FF]+$');
    const inputValue = (event.target as HTMLInputElement).value; 
    if(event.data!=null)
    {
      if (myanmarCharactersRegex.test(inputValue)) 
      {        
        this.spinner.hide();
        this.toastr.error( "Myanmar typing detected!", '', {
        timeOut: 5000,
        positionClass: 'toast-top-right',
        });  
      }
    }   
  }
  getPackageList()
  {
    this.dto.token = this.storage.retrieve('token');
    let headers = new HttpHeaders();
    headers = headers.set('Authorization',  this.dto.token);
    this.http.get(this.funct.ipaddress + 'twodpackage/GetList', { headers: headers })
            .pipe(
              catchError(this.handleError.bind(this))
            )
            .subscribe(
              result => {
                this.dto.Response = {};
                this.dto.Response = result;
                this.packageList = this.dto.Response;
              });
  }

  // onChangeTagSection() {     
  //   this.storage.store('status', this.status.toString());  
    
  // }

  // onChangeTagSection() {
  //   if (this.status != null) {
  //     this.storage.store('status', this.status.toString());
  //   } else {
  //     console.error('Status is null or undefined');
  //   }
  // }
  
}
