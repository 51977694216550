<div class="wrapper">
    <app-header-sidebar></app-header-sidebar>
  
    <div class="content-wrapper">

      <div class="content-header">
        <div class="container-fluid">
          <div class="row mb-2" style="border: 1px solid #ced4da;border-radius: 0.25rem;">
            <div class="col-sm-8">
              <h3 class="m-0 text-dark" style="font-weight: bold;padding: 3px 0px 3px 5px;"> 3D Live Setting </h3>
            </div>
          </div>
        </div>
      </div>
     
      <section class="content">
        <div class="container-fluid">
          <form>
            <div class="row">
  
              <div class="form-group col-md-2">
                <label>Live Link </label>
                <input class="form-control form-control-sm" [(ngModel)]="addlivelink" [ngModelOptions]="{standalone: true}">
              </div>

            
  
              <div class="form-group col-md-2 col-sm-4 col-xs-4">
                <label></label>
                <button id="search" type="button" class="btn btn-block btn-primary btn-sm" style="margin-top: 4%;"
                  (click)="search()">Search</button>
              </div>
             


            <div class="form-group col-md-2 col-sm-4 col-xs-4"  *ngIf="hardcode_add_bool === true">
              <label></label>                    
              <button type="button" class="btn btn-block btn-success btn-sm" style="margin-top: 4%;"
                (click)="showAddTranModelNew()">
                <b> Add New </b>
              </button>
            </div>
  
              <div class="col-md-12">
                <div class="card">
                  <div class="card-header">
                    <h3 class="card-title" style="font-weight: bold;"> Setting List </h3>
                  </div>
            
                  <div class="card-body">
                    <table  width='100%' id="{{'tblAgent' + idIndex}}" datatable [dtTrigger]="dtTrigger"
                      [dtOptions]="dtOptions" class="table table-bordered table-hover">
                      <thead>
                        <tr>
                      
                          <th>Live Link</th>
                          <th style="font-family: unicode;">Created Date</th>
                          <th style="font-family: unicode;">Created By</th>
                          <th style="font-family: unicode;">Updated Date</th>
                          <th style="font-family: unicode;">Updated By</th>
                          <th style="font-family: unicode;" *ngIf="hardcode_double === true">Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr *ngFor="let agent of agentList">
                       
                          <td target="_blank">
                            {{agent.liveLink}}
                          </td>
                          <td style="font-family: unicode;">{{agent.created_date}} </td>
                          <td style="font-family: unicode;">{{agent.created_by_name}} </td>
                          <td style="font-family: unicode;">{{agent.updated_date}}</td>
                          <td style="font-family: unicode;">{{agent.updated_by_name}}</td>
                          <td style="font-family: unicode;"  *ngIf="hardcode_double === true">
                            <a  *ngIf="hardcode_edit_bool === true" (click)="showEditRolesModel(agent.id)" class="fas fa-edit" style="color:#4b88eb"></a>
 
                           <!-- <a  *ngIf="hardcode_edit_bool === true" (click)="addStepAndNavigate(agent)" class="fas fa-edit" style="color:#4b88eb"></a> -->

                            &nbsp;&nbsp;
                            <a  *ngIf="hardcode_delete_bool === true">
                              <i class="fas fa-trash" title="Delete" (click)="delete(agent.id)" style="color:#dc3545;cursor: pointer;"></i>
                            </a>
                            
                          </td>                      

                      </tr>
                      </tbody>
                    </table>

                  </div>
          
                </div>
              </div>
  
            </div>
          </form>
        
        </div>
     
      </section>
   
    </div>
  
    <aside class="control-sidebar control-sidebar-dark">

    </aside>
  
    <app-footer></app-footer>
    <ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#fff" type="square-jelly-box">
      <p style="font-size: 16px; color: white">Loading...</p>
    </ngx-spinner>
  </div>
  
  <div id="deleteData" class="modal fade">
    <div class="modal-dialog">
        <div class="modal-content">
  
            <div class="modal-body">
                <form>
  
                    <div class="row" style="margin-top: 5%;margin-bottom: 3%;margin-left: 9%;">
                        <div class="form-group col-md-12">
                            <h5> This Account is used by another device. 
                                Please Sign Out. Login Again. </h5>
                        </div>
                    </div>
  
                    <div class="row" style="margin-left: 40%;margin-bottom: 3%;">
                        <div class="col-md-5 col-sm-6" id="deleteBtn">
                            <button type="button" class="btn btn-block btn-success" (click)="OkLogout()"> Ok </button>
                        </div>
                    </div>
                </form>
            </div>
  
        </div>
    </div>
  </div>

  <div id="browseAccountData9" class="modal fade">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" style="font-weight: bold;font-size: 1rem;"> Edit Link </h5>
          <button type="button" class="close" data-dismiss="modal" aria-hidden="true"> x </button>
        </div>

        <div class="modal-body">
          <form>
            <div class="col-md-12">
              <div class="col-md-12">
                  <div class="card-body">
                    <label>Link   </label>
                   
                    <input placeholder="Type Here.." class="form-control form-control-sm" [(ngModel)]="adsDTO.livelink" [ngModelOptions]="{standalone: true}">
            

                  </div>
              </div>
            </div>
          </form>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-primary btn-sm"
          (click)="Close()">Cancel</button>
          <button type="button" class="btn btn-primary btn-sm"
          (click)="addTran(adsDTO.id,adsDTO.livelink)">Save Changes</button>
        </div>
      </div>
    </div>
  </div>


  <div id="browseAccountData10" class="modal fade">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" style="font-weight: bold;font-size: 1rem;"> Add New Link </h5>
          <button type="button" class="close" data-dismiss="modal" aria-hidden="true"> x </button>
        </div>
        <div class="modal-body">
          <form>
            <div class="col-md-12">
              <div class="col-md-12">
                  <div class="card-body">            
                    <label> Link </label>
                    <input placeholder="Type Here.." class="form-control form-control-sm" [(ngModel)]="addlink" [ngModelOptions]="{standalone: true}">

                  </div>
              </div>
            </div>
          </form>
        </div>

       
          <div class="modal-footer">   
            <button type="button" class="btn btn-primary btn-sm"
            (click)="Close()">Close</button>
            <button type="button" class="btn btn-primary btn-sm"
            (click)="SaveNewRole(addlink)">Save</button>

          </div>          
      
      </div>
    </div>
  </div>


  

<div id="deleteDataForTutorial" class="modal fade">
  <div class="modal-dialog">
      <div class="modal-content">

          <div class="modal-body">
              <form>

                  <div class="row" style="margin-top: 5%;margin-bottom: 3%;margin-left: 9%;">
                      <div class="form-group col-md-12" style="text-align: center;">
                          <h3 class="text-center"> Are you sure want to delete? </h3>
                      </div>
                  </div>

                  <div class="row" style="margin-left: 10%;margin-bottom: 3%;">
                      
                      <div class="col-md-5">
                          <button type="button" class="btn btn-block btn-danger" (click) = "closeTran()" [routerLink] = "['/3D-live-list']"> Cancel </button>
                      </div>

                      <div class="col-md-5" id="deleteBtn">
                        <button type="button" class="btn btn-block btn-success" (click)="deleteLive()"> Confirm </button>
                    </div>

                  </div>

              </form>
          </div>

      </div>
  </div>
</div>