
       
      
               
                    <div class="row">

                        <div class="col-md-12">
                            <div class="card">
                            
                                <div class="row">
                                  <div class="form-group col-md-2">
                                    <div class="card-header">
                                      <h3 class="card-title" style="font-weight: bold;"> FAQ Steps Detail</h3>
                                    </div>
                          
                                  </div>                          
                          
                                   <div class="form-group col-md-2"> </div>
                          
                                  <div class="form-group col-md-2"> </div>                        
                          
                          
                                 <div class="form-group col-md-2">
                                    <div class="card-header">
                                      <button type="button" class="btn btn-block btn-success btn-sm" style="margin-top: 4%;"
                                      onclick="window.location.href='/feedbackandfaq/faq-list'">
                                        <b>Go Back </b>
                                      </button>
                                    </div> 
                                </div> 
                                </div>


                              
                                <div class="card-body">
                                    <table width='100%' id="{{'tblFeedback' + idIndex}}" datatable [dtTrigger]="dtTrigger"
                                        [dtOptions]="dtOptions" class="table table-bordered table-hover">
                                        <thead>
                                            <tr>
                                                <th style="font-family: unicode;">Step No </th>                                           
                                                <th style="font-family: unicode; width: 80px;">Image</th>
                                                <th style="font-family: unicode; width: 80px;">Image Type</th>
                                                <th style="font-family: unicode; width: 80px;">Description</th>                                          
                                                <th style="font-family: unicode; width: 80px;">Created Date</th>                                          
                                                <th style="font-family: unicode;">Created By</th>
                                                <th style="font-family: unicode;" >Updated Date</th>                                               
                                                <th style="font-family: unicode;" >Updated By</th>
                                                <th style="font-family: unicode;" >Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr *ngFor="let feed of feedBack">
                                                <td style="font-family: unicode;">{{feed.stepNo}}</td>                                               
                                                <td style="font-family: unicode; width: 80px;">{{feed.image}}</td>
                                                <td style="font-family: unicode; width: 80px;">{{feed.imageType}}</td>
                                                <td style="font-family: unicode; width: 80px;">{{feed.describe}}</td>                                               
                                                <td style="font-family: unicode;">{{feed.created_date_Str}}</td>
                                                <td style="font-family: unicode;">{{feed.created_by_name}}</td>
                                                <td style="font-family: unicode;">{{feed.updated_time_Str}}</td>
                                                <td style="font-family: unicode;">{{feed.updated_by_name}}</td>                                              
                                           
                                              <td style="font-family: unicode;" class="ng-star-inserted">
                                                <a (click)="!isTyping ? showEditRolesModel(feed.id ,isTyping) : null">
                                                  <i class="fas fa-edit" style="color: #4b88eb;">
                                                  </i>
                                                </a> &nbsp;&nbsp;
                                                <a (click)="delete(feed.id)">
                                                  <i class="fa fa-trash" style="color: red;">
                                                  </i>
                                                </a>
                                              </td>
                                              

                                            </tr>
                                        </tbody>
                                    </table>


                                    <!-- <tr *ngFor="let feed of feedBack | paginate
                                    : {
                                        itemsPerPage: itemsPerPage,
                                        currentPage: page,
                                        totalItems: totalItems
                                      }" 
                                      >
                                  </tr> 
                                <div class = "row">
                                  <div class = "col-md-6"></div>
                                  <div class = "col-md-6">
                                    <pagination-controls
                                      class="pagi"
                                      (pageChange)="gty((page = $event))"
                                    ></pagination-controls>
                                </div>
                                </div> -->



                                </div>
                             
                            </div>
                        </div>

                    </div>
            
           
   

    <aside class="control-sidebar control-sidebar-dark">
      
    </aside>


   

    <app-footer></app-footer>
    <ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#fff" type="square-jelly-box">
        <p style="font-size: 16px; color: white">Loading...</p>
    </ngx-spinner>


  
    <div id="browseAccountData9" class="modal fade">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" style="font-weight: bold;font-size: 1rem;"> Edit FAQ Step </h5>
            <button type="button" class="close" data-dismiss="modal" aria-hidden="true"> x </button>
          </div>
    
          <div class="modal-body">
            <form>
              <div class="col-md-12">


                 <div class="col-md-12">
                    <div class="card-body">
                      <label>Step No </label>                   
                      <input placeholder="Type Here.." class="form-control form-control-sm" [(ngModel)]="stepNo" [ngModelOptions]="{standalone: true}">
                    </div>
                </div>

                <div class="col-md-12">
                  <div class="card-body">
                    <label>Image</label>                   
                    <div class="form-group col-md-3">
                      <input #file2 type="file" accept='image/*'
                          (change)="preview2(file2.files)" /> 

                          <!-- <img [src]="imgURL2" height="250" width="355" *ngIf="imgURL2"
                          style="margin-top: 5%;"> -->

                          <img [src]="imgURL2" style="width: 300px; height: 255px; margin-top: 5%;" *ngIf="imgURL2">

                  </div>
                  </div>
                </div>



                <div class="col-md-12">
                  <div class="card-body">
                    <label for="imageType">Image Type</label>                   
                    <select class="type form-control form-control-sm col-md-12 col-sm-4 col-xs-4"
                                [(ngModel)]="imageType" [ngModelOptions]="{standalone: true}">
                                <option value=".png" selected>png</option>
                                <option value=".jpg">jpg</option>                                
                                <option value=".jpeg">jpeg</option>
                            </select>

                         
                  </div>
                </div>

                <div class="col-md-12">
                  <div class="card-body">
                    <div class="form-group">
                      <label>Description</label>
                      <textarea placeholder="Enter describe.." class="form-control form-control-sm" [(ngModel)]="describe" [ngModelOptions]="{standalone: true}"></textarea>
                    </div>
                  </div>
                </div>

              

              </div> 
            </form>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-primary btn-sm"
            (click)="Close()">Cancel</button>
            <button type="button" class="btn btn-primary btn-sm"
            (click)="addTran(adsDTO.id,adsDTO.faqId,stepNo,imgURL2,imageType,describe)">Save Changes</button>
          </div>
        </div>
      </div>
    </div>   



<!--     
<div id="browseAccountData10" class="modal fade">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
     
        <button type="button" class="close" data-dismiss="modal" aria-hidden="true"> x </button>
      </div>
      <div class="modal-body">
        <form>
          <div class="col-md-12">
            <div class="col-md-12">
                <div class="card-body">             

                  <label> Type   </label>
                  <input placeholder="Type Here.." class="form-control form-control-sm" [(ngModel)]="addTranAmt" [ngModelOptions]="{standalone: true}">

                </div>
            </div>
          </div>
        </form>
      </div>

     
        <div class="modal-footer">   
          <button type="button" class="btn btn-primary btn-sm"
          (click)="Close()">Close</button>
          <button type="button" class="btn btn-primary btn-sm"
          (click)="SaveNewRole(addTranAmt)">Save</button>
        </div>          
    
    </div>
  </div>
</div> -->


<div id="browseAccountData10" class="modal fade">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <button type="button" class="close" data-dismiss="modal" aria-hidden="true"> x </button>
      </div>
      <div class="modal-body">
        <form>
          <div class="col-md-12">
            <div class="card-body">

              <div class="form-group">
                <label for="faqType">Type</label>
                <select  id = "faqType" class="form-control form-control-sm col-md-12 col-sm-4 col-xs-4"
                [(ngModel)]="faqType" [ngModelOptions]="{standalone: true}">
                 <option *ngFor="let payment of paymentList" value = "{{payment.id}}" >
                   {{payment.name}}
                 </option>
              </select>
              </div>

              <div class="form-group">
                <label>Question</label>             
                <textarea placeholder="Enter question.." class="form-control form-control-sm" [(ngModel)]="faqquestion" [ngModelOptions]="{standalone: true}"></textarea>
              </div>

              <div class="form-group">
                <label>Answer</label>
                <textarea placeholder="Enter answer.." class="form-control form-control-sm" [(ngModel)]="answer" [ngModelOptions]="{standalone: true}"></textarea>
              </div>

              <div class="form-group">
                <label for="has">Has Guide?</label>
                <select class="form-control form-control-sm" id="has" [(ngModel)]="has" [ngModelOptions]="{standalone: true}">
                  <option value=true>Yes</option>
                  <option value=false>No</option>
                 
                </select>
              </div>
              <div class="form-group">
                <label>Order Number</label>
                <div class="input-group">
                    
                    <input type="number" class="form-control form-control-sm" [(ngModel)]="orderNumber" [ngModelOptions]="{standalone: true}" />
                   
                </div>
            </div>
            

            </div>
          </div>
        </form>
      </div>

      <div class="modal-footer">   
        <button type="button" class="btn btn-primary btn-sm" (click)="close()">Close</button>
        <button type="button" class="btn btn-primary btn-sm" (click)="saveNewRole(faqType,faqquestion,answer,has,orderNumber)">Save</button>
      </div>

    </div>
  </div>
</div>





   
 


<div id="deleteData" class="modal fade">
    <div class="modal-dialog">
        <div class="modal-content">

            <div class="modal-body">
                <form>

                    <div class="row" style="margin-top: 5%;margin-bottom: 3%;margin-left: 9%;">
                        <div class="form-group col-md-12">
                            <h5> This Account is used by another device. 
                                Please Sign Out. Login Again. </h5>
                        </div>
                    </div>

                    <div class="row" style="margin-left: 40%;margin-bottom: 3%;">
                        <div class="col-md-5 col-sm-6" id="deleteBtn">
                            <button type="button" class="btn btn-block btn-success" (click)="OkLogout()"> Ok </button>
                        </div>
                    </div>
                </form>
            </div>

        </div>
    </div>
</div>






<div id="anotherlogin" class="modal fade">
  <div class="modal-dialog">
      <div class="modal-content">

          <div class="modal-body">
              <form>

                  <div class="row" style="margin-top: 5%;margin-bottom: 3%;margin-left: 9%;">
                      <div class="form-group col-md-12">
                          <h5> This Account is used by another device. 
                              Please Sign Out. Login Again. </h5>
                      </div>
                  </div>

                  <div class="row" style="margin-left: 40%;margin-bottom: 3%;">
                      <div class="col-md-5 col-sm-6" id="deleteBtn">
                          <button type="button" class="btn btn-block btn-success" (click)="OkLogout()"> Ok </button>
                      </div>
                  </div>
              </form>
          </div>

      </div>
  </div>
</div>

