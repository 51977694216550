

          <div class="row">
		  
            <div class="form-group col-md-2">
                <label>Phone No</label>
                <input id='phoneNo' type="text" list="users" class="form-control form-control-sm" [(ngModel)]="phoneNo"
                  [ngModelOptions]="{standalone: true}" (input)="onInput($event)">
                <datalist id="users">
                  <option *ngFor="let user of userList" value={{user.phoneNo}}>
                </datalist>
              </div>
        
              <div class="form-group col-md-2">
                <label>Bet Date</label>
                <input id="singleDate" readonly class="form-control form-control-sm" placeholder="Select Date"
                  [ngxDatePicker]="dateInstanceSingle" [value]="singleDate"  [(ngModel)] ="date"
                  [ngModelOptions]="{standalone: true}"
                  (valueChange)="onChangeSingle()">
                <ngx-date-picker #dateInstanceSingle></ngx-date-picker>
              </div>
  
              <div class="form-group col-md-2">
                <label>Time</label>
                <select class="time form-control form-control-sm col-md-12 col-sm-4 col-xs-4" [(ngModel)]="time"
                    [ngModelOptions]="{standalone: true}">
                    <option value="null">ALL</option>
                    <option *ngFor="let objSection of sectionList" value = "{{objSection.sectionName}}" >
                      {{objSection.sectionName}}
                    </option>
                </select>
              </div>
  
              
  
              <div class="form-group col-md-2">
                <label>Package Type</label>
                <select class="time form-control form-control-sm col-md-12 col-sm-4 col-xs-4" [(ngModel)]="packageType"
                    [ngModelOptions]="{standalone: true}">
                    <option value="null">ALL</option>
                    <option *ngFor="let objSection of packageList" value = "{{objSection.id}}" >
                      {{objSection.package_name}}
                    </option>
                    <option value="0">Normal</option>
                </select>
              </div>
  
  
              <div class="form-group col-md-2">
                <label>Order By</label>
                <select class="orderby form-control form-control-sm col-md-12 col-sm-4 col-xs-4" [(ngModel)]="orderby"
                    [ngModelOptions]="{standalone: true}">
                    <option value="name">Name</option>
                    <option value="phone_no">Phone Number</option>
                    <option value="total_amount">Bet Amount</option>
                    <!-- <option value="created_date">Created Date</option> -->
                </select>
              </div>
  
              <div class="form-group col-md-2">
                <label>Order Method</label>
                <select class="ordermethod form-control form-control-sm col-md-12 col-sm-4 col-xs-4" [(ngModel)]="ordermethod"
                    [ngModelOptions]="{standalone: true}">
                    <option value="asc">Ascending</option>
                    <option value="desc">Descending</option>
                </select>
              </div>
  
  
              <div class="form-group col-md-2 col-sm-4 col-xs-4" *ngIf="hardcode_view_bool === true">
                <label></label>
                <button id="search" type="button" class="btn btn-block btn-primary btn-sm" style="margin-top: 4%;"
                  (click)="search()">Search</button>
              </div>
        
  
  
              <div class="col-md-12">
                <div class="card">
                  <div class="card-header">
                    <h3 class="card-title" style="font-weight: bold;"> 2D Bet Cancel History List </h3>
                  </div>
               
                  <div class="card-body">
                    <table width='100%' id="{{'tbltwodbet' + idIndex}}" datatable [dtTrigger]="dtTrigger"
                      [dtOptions]="dtOptions" class="table table-bordered table-hover">
                      <thead>
                        <tr>
                          <th style="font-family: unicode;">Phone No</th>
                          <th style="font-family: unicode;">Name</th>
                          <th style="font-family: unicode;">Total Amount</th>
                          <th style="font-family: unicode;">Discount Amount</th>
                          <th style="font-family: unicode;">Discount Percentage</th>
                          <th style="font-family: unicode;">Package Type</th>
                          <th style="font-family: unicode;">Created Date</th>                        
                          <th style="font-family: unicode;">Section</th>
                          <th style="font-family: unicode;">Update Date</th>
                          <th style="font-family: unicode;">Update By</th>
                          <th style="font-family: unicode;" *ngIf="hardcode_delete_bool === true">Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr *ngFor="let twodbet of twodbetList">                    
                          <td style="font-family: unicode;">
                            <a [routerLink]="['/user-detail', twodbet.user_id]" [queryParams]="{ type: 'twodbetrecord' }">{{twodbet.phone_no}}</a>
                          </td>
  
                          <td style="font-family: unicode;">{{twodbet.name}}</td>
                          <td style="font-family: unicode;" class="text-right" width="10%">{{twodbet.total_amount_Str}}</td>
                          <td style="font-family: unicode;" class="text-right" width="10%">{{twodbet.discountAmt_Str}}</td>
                          <td style="font-family: unicode;" class="text-right" width="10%">{{twodbet.discountPercent}}</td>
                          <td style="font-family: unicode;" class="text-right" width="10%">{{twodbet.package_name}}</td>
                          <td style="font-family: unicode;">{{twodbet.created_date_Str}}</td>                        
                          <td style="font-family: unicode;">{{twodbet.bet_date | date : 'yyyy-MM-dd'}} {{twodbet.bet_time}}</td>
                          <td style="font-family: unicode;">{{twodbet.cancel_date_Str}}</td>
                          <td style="font-family: unicode;">{{twodbet.canceller_name}}</td>
                          <td style="font-family: unicode;" *ngIf="hardcode_delete_bool === true">
                            <i class="far fa-eye" style="color:#4b88eb; cursor: pointer;"
                              (click)="goModal(twodbet.id,twodbet.phone_no,twodbet.name,twodbet.total_amount_Str,twodbet.created_date_Str,twodbet.bet_time)"></i>
                          </td>
                        </tr>
                      </tbody>
                    </table>
  
                    <tr *ngFor="let twodbet of twodbetList | paginate
                            : {
                                itemsPerPage: itemsPerPage,
                                currentPage: page,
                                totalItems: totalItems
                              }" 
                              >
                          </tr> 
                        <div class = "row">
                          <div class = "col-md-6"></div>
                          <div class = "col-md-6">
                            <pagination-controls
                              class="pagi"
                              (pageChange)="qty((page = $event))"
                            ></pagination-controls>
                        </div>
                        </div>
  
  
                  </div>
                 
                </div>
              </div>
            </div>
  
      
     
  
   
  
  
            <ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#fff" type="square-jelly-box">
              <p style="font-size: 16px; color: white">Loading...</p>
            </ngx-spinner>
  
  
  
  <div id="browseAccountData" class="modal fade">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h3 class="modal-title" style="font-weight: bold;font-size: 1.1rem;"> 2D Bet Cancel History Detail </h3>
          <button type="button" class="close" data-dismiss="modal" aria-hidden="true"> x </button>
        </div>
  
        <div class="modal-body">
          <form>
            <div class="col-md-12">
              <div class="row" style="margin-left: 0.1%;">
                <label class="col-md-2"> PhoneNo </label>
                <div class="form-group col-md-3">
                  <input class="form-control form-control-sm" [(ngModel)]="phoneNo2" [ngModelOptions]="{standalone: true}"
                    disabled>
                </div>
                <div class="col-md-1"></div>
                <label class="col-md-2">Total</label>
                <div class="form-group col-md-3">
                  <input class="form-control form-control-sm" [(ngModel)]="totalAmount"
                    [ngModelOptions]="{standalone: true}" disabled>
                </div>
              </div>
  
              <div class="row" style="margin-left: 0.1%;">
                <label class="col-md-2">Name</label>
                <div class="form-group col-md-3">
                  <input class="form-control form-control-sm" [(ngModel)]="userName" [ngModelOptions]="{standalone: true}"
                    disabled>
                </div>
                <div class="col-md-1"></div>
                <label class="col-md-2">Created Date</label>
                <div class="form-group col-md-3">
                  <input class="form-control form-control-sm" [(ngModel)]="createdDate"
                    [ngModelOptions]="{standalone: true}" disabled>
                </div>
              </div>
  
              <div class="row" style="margin-left: 0.1%;">
                <label class="col-md-6"></label>
                <label class="col-md-2">Section Time</label>
                <div class="form-group col-md-3">
                  <input class="form-control form-control-sm" [(ngModel)]="bet_time"
                    [ngModelOptions]="{standalone: true}" disabled>
                </div>
              </div>
              <div class="col-md-12" style="margin-top: 0.5%;">
                <div class="card">
                  <div class="card-body">
                    <table width='100%' id="{{'tbltwodbetdetail' + idIndex2}}" datatable [dtTrigger]="dtTrigger2"
                      [dtOptions]="dtOptions2" class="table table-bordered table-hover">
                      <thead>
                        <tr>
                          <th style="font-family: unicode;">No</th>
                          <th style="font-family: unicode;">Bet Number</th>
                          <th style="font-family: unicode;">Amount</th>
                          <!-- <th style="font-family: unicode;">Result</th> -->
                        </tr>
                      </thead>
                      <tbody>
                        <tr *ngFor="let twodbet of twoDBetDetailDTOList, let i = index">
                          <td style="font-family: unicode; padding: 0.35rem 0.75rem 0.30rem 0.75rem;">{{ i + 1 }}</td>
                          <td style="font-family: unicode; padding: 0.35rem 0.75rem 0.30rem 0.75rem;">{{twodbet.number}}
                          </td>
                          <td style="font-family: unicode; padding: 0.35rem 0.75rem 0.30rem 0.75rem;">{{twodbet.amount}}
                          </td>
                          <!-- <td style="font-family: unicode; padding: 0.35rem 0.75rem 0.30rem 0.75rem;">{{twodbet.result_number}}
                          </td> -->
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
  
            </div>
  
          </form>
        </div>
  
      </div>
    </div>
  
  
  
  <div id="deleteData" class="modal fade">
    <div class="modal-dialog">
        <div class="modal-content">
  
            <div class="modal-body">
                <form>
  
                    <div class="row" style="margin-top: 5%;margin-bottom: 3%;margin-left: 9%;">
                        <div class="form-group col-md-12">
                            <h5> This Account is used by another device. 
                                Please Sign Out. Login Again. </h5>
                        </div>
                    </div>
  
                    <div class="row" style="margin-left: 40%;margin-bottom: 3%;">
                        <div class="col-md-5 col-sm-6" id="deleteBtn">
                            <button type="button" class="btn btn-block btn-success" (click)="OkLogout()"> Ok </button>
                        </div>
                    </div>
                </form>
            </div>
  
        </div>
    </div>
  </div>