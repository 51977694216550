import { Component, OnInit, ViewChild } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,HttpErrorResponse } from '@angular/common/http';
import { Router, ActivatedRoute } from '@angular/router';
import { FormGroup, FormControl, Validator, Validators } from "@angular/forms";

import { LocalStorageService } from 'ngx-webstorage';
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from 'ngx-toastr';

import { DtoService } from '../../service/dto.service';
import { FunctService } from '../../service/funct.service';
import { Subject } from 'rxjs';
import { DatePipe } from '@angular/common';
import * as XLSX from 'xlsx'; 
import * as fs from 'file-saver';
import { Workbook } from 'exceljs';

import { catchError, retry } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { jsPDF } from "jspdf";
import 'jspdf-autotable'
import autoTable from 'jspdf-autotable'
import { IMultiSelectOption,IMultiSelectSettings,IMultiSelectTexts } from 'ngx-bootstrap-multiselect';

declare const getCurrentDate: any;
declare var $: any;

export class AppModule {
}
@Component({
  selector: 'app-user',
  templateUrl: './firsttopup-report-daily.component.html',
  styleUrls: ['./firsttopup-report-daily.component.css'],
  providers: [DatePipe]

})
export class FirstTopupReportDailyComponent implements OnInit {
  @ViewChild('multiSelect') multiSelect;
  fromdate: string ='';
  // todate: string = '';
  section: string= '';
  report_option : string ='';
  choose_msg : string = '';
  isActive : boolean = false;
  ispdfActive : boolean = false;
  iscsvActive : boolean = false;
  dtTrigger: Subject<any> = new Subject();
  clickkbzpay: any = true;
  clickwavepay: any = false;
  csvrep: any = false;
  filename : string = "";
  filename_date :any ;
  grand_total : any;
  promoterRptList = [];
  promoterPdfRptList =[];
  activeagentList = [];
  activeagentList1 = [];
  myDate = new Date();
  adminObj : any;
  ad_name : any;
  agentId : any;
  fordate : any;
  todate : any;
  myOptions: IMultiSelectOption[];
  optionsModel: number[];
  mySettings: IMultiSelectSettings;
  myTexts: IMultiSelectTexts;

      
  hardcode_edit :any;
  hardcode_add :any;
  hardcode_view: any;
  hardcode_delete:any;
  hardcode_view_bool : any;
  hardcode_add_bool : any;
  hardcode_edit_bool : any;
  hardcode_delete_bool:any;
  hardcode_double_bool:any;

  constructor(private toastr: ToastrService,private datePipe: DatePipe,private storage: LocalStorageService, private spinner: NgxSpinnerService, private http: HttpClient, private dto: DtoService, private router: Router, private funct: FunctService,) {
    this.storage.store('isNotiSong', "");
    this.hardcode_add = "FirstTopupReportDaily_Add";     
    this.dto.menuCodes = this.storage.retrieve('menucodes');

    const filteredMenuCodeds_add =[];

    this.dto.menuCodes.forEach(menuCode => {
   
      if (menuCode === this.hardcode_add) {
        filteredMenuCodeds_add.push(menuCode);
      }   
      
    });
  
    if(filteredMenuCodeds_add.length != 0)
    {
          this.hardcode_add_bool = true;
    }
 

    this.filename_date = this.datePipe.transform(this.myDate,'dd-MM-yyyy');
  }
  ngOnInit(): void {
    this.getCurrentAdmin();
    this.getActiveAgents();

    // Settings configuration
    this.mySettings = {
      enableSearch: true,
      checkedStyle: 'checkboxes',
      buttonClasses: 'btn btn-default btn-block',
      dynamicTitleMaxItems: 3,
      displayAllSelectedText: true,
      showCheckAll : true,
      showUncheckAll : true,
      autoUnselect : true,
      pullRight : false,
      closeOnSelect : false
    };

    // Text configuration
    this.myTexts = {
      checkAll: 'Select all',
      uncheckAll: 'Unselect all',
      checked: 'item selected',
      checkedPlural: 'items selected',
      searchPlaceholder: 'Find',
      searchEmptyResult: 'Nothing found...',
      searchNoRenderText: 'Type in search box to see results...',
      defaultTitle: 'Select',
      allSelected: 'All selected',
    };

  }

  getActiveAgents() {
    this.dto.token = this.storage.retrieve('token');
    let headers = new HttpHeaders();
    this.activeagentList = [];
    headers = headers.set('Authorization',  this.dto.token );
    this.http.get(this.funct.ipaddress + 'agent/GetActiveAgents', { headers: headers }).subscribe(
      result => {
        this.dto.Response = {};
        this.dto.Response = result;
        this.activeagentList = this.dto.Response;
        for( var i=this.activeagentList.length - 1; i>=0; i--){
          this.activeagentList[i].id = this.activeagentList[i].id;
          this.activeagentList[i].name =this.activeagentList[i].referral_code;
        }
        this.myOptions = this.activeagentList;
        this.myOptions.push({
          id:0,
          name:'No Code'
        })
      }
    );
  }
  onChange() {
    console.log("in onchange "+ this.optionsModel);
 }

  flagAgent(){
    this.agentId = $("#agentId").val();
  }

  getCurrentAdmin() {
    this.dto.token = this.storage.retrieve('token');
    let headers = new HttpHeaders();
    headers = headers.set('Authorization',this.dto.token);
    this.http.get(this.funct.ipaddress + 'admin/GetCurrentAdminDetail', { headers: headers }).subscribe(
      result => {
        this.dto.Response = {};
        this.dto.Response = result;
        this.adminObj = this.dto.Response;
        this.ad_name = this.adminObj.name;
      }
    );
  }

  handleError(error: HttpErrorResponse){
    if(error.status == 423)
    {
      this.spinner.hide();
      $("#deleteData").modal("show");
    }
    if(error.status == 403)
    {
      this.spinner.hide();
      this.toastr.error("Limited Access.", 'Invalid!', {
        timeOut: 3000,
        positionClass: 'toast-top-right',
        });
    }
    return throwError(error);
    }
    OkLogout()
    {
      window.location.href ="./ad-login";
    } 

  getReportOption(options)
  {
    this.report_option = options;
    this.choose_msg = "You choose "+this.report_option;
    if(options == "excel")
    {
       this.clickkbzpay =true;
       this.clickwavepay = false;
       this.csvrep =false;
    }
    if(options == "pdf")
    {
       this.clickkbzpay =false;
       this.clickwavepay = true;
       this.csvrep =false;
    }
    if(options == "csv")
       {
        this.clickkbzpay =false;
        this.clickwavepay = false;
        this.csvrep =true;
       }
   }

/*name of the excel-file which will be downloaded. */ 
fileName= 'ExcelSheet.xlsx';  
exportexcel(): void 
    {
       /* table id is passed over here */   
       let element = document.getElementById('excel-table'); 
       const ws: XLSX.WorkSheet =XLSX.utils.table_to_sheet(element);
       /* generate workbook and add the worksheet */
       const wb: XLSX.WorkBook = XLSX.utils.book_new();
       XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
       /* save to file */
       XLSX.writeFile(wb, this.filename);
    }

    firstTopupReport(){
    var repType = '';
    var grand_total = 0;
    this.spinner.show();
    this.promoterRptList = [];
    this.promoterPdfRptList =[];
    this.dto.token = this.storage.retrieve('token');
    if(this.report_option =='')
    {
      this.report_option = 'excel';
    }
    this.filename = "firsttopup_daily"+this.filename_date;
    let headers = new HttpHeaders();
    headers = headers.set('Authorization', this.dto.token);
    let params = new HttpParams();
    console.log(this.fordate)
    var formatFromDate = new Date(this.fordate),
    fmnth = ("0" + (formatFromDate.getMonth() + 1)).slice(-2),
    fday = ("0" + formatFromDate.getDate()).slice(-2);
    var formData = new FormData();
    formData.append("fordate", this.fordate);
    formData.append("todate", this.todate);
    if(this.optionsModel.length > 0)
    {
       this.agentId = this.optionsModel;
    }
    else
    {
        this.toastr.error('Please select invite code', 'Invalid!', {
          timeOut: 3000,
          positionClass: 'toast-top-right',
        });
      this.spinner.hide();
      return;
    }
    formData.append("agentId", this.agentId);
     if(this.report_option == 'excel')
        {
           repType = ".xlsx";
        }
        if(this.report_option == 'pdf')
        {
            repType = ".pdf";
        }
        if(this.report_option == "csv")
        {
          repType = ".csv";
        }
      this.http.post(this.funct.ipaddress + 'userfinancialreport/userfirsttopupReportDate', formData, { headers: headers})
      .pipe(
        catchError(this.handleError.bind(this))
       )
      .subscribe(data => {
        this.spinner.hide();
        this.dto.Response = {};
        this.dto.Response = data;
        this.dto.Response.forEach((row: any) => {
      
          this.promoterRptList.push(Object.values(row))
          this.promoterPdfRptList.push(Object.values(row));
          var result = row.amount_Str.toString().replace(/,/g, "");
          grand_total = grand_total + parseInt(result) ;
        })
        if(this.report_option == "pdf")
        {
          var pdfname = this.filename + repType;
          var totalList = ['', '', '','', '', '', 'Grand Total',grand_total.toLocaleString()];
          this.promoterPdfRptList.push(totalList);
          this.convert(this.promoterPdfRptList,pdfname);
        }
        if(this.report_option == "excel")
        {
                var title = '';
                   title = 'First Topup Report Daily ('+this.fordate+' to '+this.todate+')';
                const header = ['No', 'Name', 'Phone Number', 'Referral Code','Referral Date', 'Register Date' , 'Type','Approved Date/Time','Amount'];
                const workbook = new Workbook();
                const worksheet = workbook.addWorksheet('First Topup Report');
                const titleRow = worksheet.addRow([title]);
                titleRow.font = { name: 'Corbel', family: 4, size: 16, underline: 'double', bold: true };
                worksheet.addRow([]);
                const subTitleRow = worksheet.addRow(['Printed Date : '+this.filename_date]);
                worksheet.mergeCells('A3:E3');
                worksheet.mergeCells('A1:E2');
              
                worksheet.addRow(['Printed By : '+this.ad_name]);
                worksheet.mergeCells('A4:E4');
           
                const headerRow = worksheet.addRow(header);
           
                  headerRow.eachCell((cell, number) => {
                  cell.fill = {
                  type: 'gradient',
                  gradient: 'angle',
                  degree: 0,
                  stops: [
                    {position:0, color:{argb:'ff095108'}},
                    {position:0.5, color:{argb:'ff095108'}},
                    {position:1, color:{argb:'ff095108'}}
                  ],
                  };
                  cell.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
                  cell.alignment = {horizontal:'center' };
                  cell.font= {color:{argb:'FFFFFF'}};  
                  });
                  var tot = 0;
                  this.promoterRptList.forEach(d => {
                  const row = worksheet.addRow(d);
                 
                //  row.alignment = {horizontal :'left',wrapText: true}
                row.getCell(1).alignment = {horizontal :'center',wrapText: true}
                row.getCell(2).alignment = {horizontal :'left',wrapText: true}
                row.getCell(4).alignment = {horizontal :'center',wrapText: true}
                row.getCell(5).alignment = {horizontal :'center',wrapText: true}
                row.getCell(6).alignment = {horizontal :'center',wrapText: true}
                row.getCell(7).alignment = {horizontal :'center',wrapText: true}
                row.getCell(8).alignment = {horizontal :'center',wrapText: true}
                row.getCell(9).alignment = {horizontal :'right',wrapText: true}



                  // const cell2 = row.getCell(2);
                  // cell2.alignment = { horizontal: 'left', wrapText: true };
                  // const cell9 = row.getCell(9);
                  // cell9.alignment = { horizontal: 'right', wrapText: true };

              
              
                
                  }
              );
              worksheet.getColumn(2).width = 25;
              worksheet.getColumn(3).width = 15;
              worksheet.getColumn(4).width = 15;
              worksheet.getColumn(5).width = 20;
              worksheet.getColumn(6).width = 20;
              worksheet.getColumn(7).width = 15;
              worksheet.getColumn(8).width = 20;
              worksheet.getColumn(9).width = 15;
              // worksheet.getColumn(9).alignment = { horizontal: 'center', wrapText: true };
              // worksheet.getColumn(2).alignment = { horizontal: 'center', wrapText: true };

              // Footer Row
              const footerRow = worksheet.addRow([]);
              footerRow.getCell(8).value = 'Grand Total';
              footerRow.getCell(9).value = grand_total.toLocaleString();
              footerRow.getCell(7).fill = {
              type: 'pattern',
              pattern: 'solid',
              fgColor: { argb: 'FFCCFFE5' }
              };
              footerRow.getCell(8).border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
              footerRow.getCell(8).fill = {
              type: 'pattern',
              pattern: 'solid',
              fgColor: { argb: 'FFCCFFE5' }
              };
              footerRow.getCell(9).border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
              
              footerRow.getCell(9).alignment ={horizontal: 'right', wrapText: true};
              workbook.xlsx.writeBuffer().then((data: any) => {
              const blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
              fs.saveAs(blob, this.filename + repType);
              });
        }
        if(this.report_option == 'csv')
        {
           this.downloadFile(data, this.filename+repType)
        }
          this.toastr.success('First Topup Report', 'Success!', {
            timeOut: 3000,
            positionClass: 'toast-top-right',
          });
        this.spinner.hide();
        });
  }

  convert(list, pdfname){
      var doc = new jsPDF('p', 'pt','a4');
      const head = [['No', 'Name', 'Phone Number', 'Referral Code','Referral Date' ,'Register Date', 'Type','Approved Date/Time','Amount']];
      doc.setFontSize(12);
      doc.setTextColor(0);
      var tot = 0;
      var title = '';
    
      title = 'First Topup Report Daily ('+this.fordate+' to '+this.todate+')';
      doc.text(title, 40, 20);
      doc.text('Printed By : '+this.ad_name+' - Printed Date : '+this.filename_date,40,40);
      autoTable(doc,
      {
      head: head,
      body: list,
      startY: 50,
      styles: { overflow: "linebreak" },
      didDrawCell: (list) => {
      },
      didParseCell:(list)=>{
        var rows = list.table.body;
        if (list.row.index === rows.length - 1) {
           // list.cell.styles.fillColor = [239, 154, 154];
        }
      }
      })
      doc.save(pdfname);
      }
      downloadFile(data, filename) {
        let csvData = this.ConvertToCSV(data, ['sr_no','name', 'phone_no','referral_code','referral_date', 'register_date_Str','type','created_date_Str','amount_Str']);
        console.log(csvData)//'\ufeff' +
        let blob = new Blob([csvData], { type: 'text/csv;charset=utf-8;' });
        let dwldLink = document.createElement("a");
        let url = URL.createObjectURL(blob);
        let isSafariBrowser = navigator.userAgent.indexOf('Safari') != -1 && navigator.userAgent.indexOf('Chrome') == -1;
        if (isSafariBrowser) {  
            dwldLink.setAttribute("target", "_blank");
        }
        dwldLink.setAttribute("href", url);
        dwldLink.setAttribute("download", filename + ".csv");
        dwldLink.style.visibility = "hidden";
        document.body.appendChild(dwldLink);
        dwldLink.click();
        document.body.removeChild(dwldLink);
    }
    ConvertToCSV(objArray, headerList) {
      let array = typeof objArray != 'object' ? JSON.parse(objArray) : objArray;
      let str = '';
      let row = '';
      for (let index in headerList) {
          row += headerList[index] + ',';
      }
      row = row.slice(0, -1);
      str += row + '\r\n';
      for (let i = 0; i < array.length; i++) {
          let line = '';
          for (let index in headerList) {
             let head = headerList[index];
             var d = array[i][head];
             if(d != null)
             {
             if(d.toString().includes(","))
                d = d.toString().replace(/,/g, "");
             }
             line +=  d + ",";
          }
          line = line.slice(0,-1);
          str += line + '\r\n';
      }
    return str;
    }
}
