import { Component, OnInit, ViewChild } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,HttpErrorResponse } from '@angular/common/http';
import { DataTableDirective } from 'angular-datatables';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { DatePipe } from '@angular/common'
import { LocalStorageService } from 'ngx-webstorage';
import { NgxSpinnerService } from "ngx-spinner";
import { DtoService } from '../../service/dto.service';
import { FunctService } from '../../service/funct.service';
import { catchError, retry } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
declare var $: any;

@Component({
  selector: 'app-winner-input',
  templateUrl: './winner-input.component.html',
  styleUrls: ['./winner-input.component.css']
})
export class WinnerInputComponent implements OnInit {

  @ViewChild(DataTableDirective)
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();

  dtOptions1: DataTables.Settings = {};
  dtTrigger1: Subject<any> = new Subject();

  dtOptions2: DataTables.Settings = {};
  dtTrigger2: Subject<any> = new Subject();

  dtOptions3: DataTables.Settings = {};
  dtTrigger3: Subject<any> = new Subject();

  section : string = '10:30 AM'; 

  TDWLPhoneNo: string = '';
  TDWLBetAmount: string = '';
  TDWLWinAmount: string = '';
  TDWLsection: string = '';
  TDWLStatus: string = '';
  TDWLImgUrl: string ='';

  ThDWLPhoneNo: string = '';
  ThDWLBetAmount: string = '';
  ThDWLWinAmount: string = '';
  ThDWLStatus: string = '';

  TDWList: any;
  ThDWList: any;
  twoDWinnerInputList =[];
  threeDWinnerInputList =[];

  idIndex: any;
  idIndexTD: any;
  idIndexTDWL: any;
  idIndexThDWL: any;

  threeDOdd:any;
  odd:any;

  itemsPerPage =  10;
  itemsPerPage1 =  10;

  totalItems : any;  
  totalItems1 : any; 

  page = 1;
  page1 = 1;
  imagePath: any;
  filePath : any;
  imageURL: any;
  imgURL:any
  message:any;
  phoneNumber:any;
  selectedImages: File[] = [];


  type: any;

  hardcode_edit :any;
  hardcode_add :any;
  hardcode_view: any;
  hardcode_view_bool : any; 
  hardcode_edit_bool : any;
  hardcode_add_bool : any;

  winAmounts: number[] = [];
  TDWLName :any;
  ThDWLName:any;

  constructor(private storage: LocalStorageService,private spinner: NgxSpinnerService, private dto: DtoService, private http: HttpClient, private router: Router,
    private route: ActivatedRoute, private funct: FunctService,private toastr: ToastrService, private datepipe: DatePipe) { 
    this.idIndex = 1;
    this.idIndexTD = 1;
    this.idIndexTDWL = 1;
    this.idIndexThDWL = 1;
    this.storage.store('isNotiSong', "");

      this.hardcode_view = "WinnerInput_View";
      this.hardcode_edit = "WinnerInput_Edit";
      this.hardcode_add = "WinnerInput_Add";
      this.dto.menuCodes = this.storage.retrieve('menucodes');

      const filteredMenuCodes_view = [];    
      const filteredMenuCodeds_edit =[];
      const filteredMenuCodeds_add =[];
      this.dto.menuCodes.forEach(menuCode => {
        if (menuCode === this.hardcode_view) {
          filteredMenuCodes_view.push(menuCode);
        }     
        if (menuCode === this.hardcode_edit) {
          filteredMenuCodeds_edit.push(menuCode);
        }
        if (menuCode === this.hardcode_add) {
          filteredMenuCodeds_add.push(menuCode);
        }
      });
          if(filteredMenuCodes_view.length != 0)
          {
              this.hardcode_view_bool = true;
          }       
          if(filteredMenuCodeds_edit.length != 0)
          {
              this.hardcode_edit_bool = true;
          }
          if(filteredMenuCodeds_add.length != 0)
          {
              this.hardcode_add_bool = true;
          }

    if (!this.storage.retrieve('TDWLTagsection')) {
      this.TDWLsection = '10:30 AM';
    }
    else {
      this.TDWLsection = this.storage.retrieve('TDWLTagsection');
    }  

    this.twoDWinnerInputList.push({name:'',phoneNumber:'',imgURL:'',betAmount:0,winAmount:0,section:'',status:'Active',odd:0});
    this.threeDWinnerInputList.push({threeDName:'',threeDPhoneNumber:'',threeDImgUrl:'',threeDBetAmount:0,threeDWinAmount:0,threeDStatus:'Active',ThreeDOdd:0});

   


  }

  ngOnInit(): void {   

    //amk added 2024-06-10

    document.addEventListener('DOMContentLoaded', (event) => {
      document.getElementById('twoD-tab').addEventListener('click', function() {
          window.location.href = '/winner-input/Main';
      });
  
      document.getElementById('twoDWinnerList-tab').addEventListener('click', function() {
          window.location.href = '/winner-input/2DWinnerList';
      });
  
      document.getElementById('threeD-tab').addEventListener('click', function() {
          window.location.href = '/winner-input/3D';
      });
  
      document.getElementById('threeDWinnerList-tab').addEventListener('click', function() {
          window.location.href = '/winner-input/3DWinnerList';
      });
  });
  

    //



    this.dtOptions = {
      responsive: true,
      stateSave: true,
      order: []
    }

    this.dtOptions1 = {
      responsive: true,
      stateSave: true,
      order: [],
      paging: false,
      info : true,
      dom: "Bfrltip"
    }

    this.dtOptions2 = {
      responsive: true,
      stateSave:true,
      order: []
    }

    this.dtOptions3 = {
      responsive: true,
      stateSave: true,
      order: [],
      paging: false,
      info : true,
      dom: "Bfrltip"
    }

    if (!this.storage.retrieve('loadFlag')) {
      this.storage.store('loadFlag', 'noLoad');
      setTimeout(function () {
        location.reload();
      }, 5);
    }
    else {
      this.storage.clear('loadFlag');
    }    

    this.twoDOddSearch();
    this.type = this.route.snapshot.paramMap.get("type");
    if(this.type == 'Main'){
      $('#twoD-tab').tab('show');
    }
    else if(this.type == '2DWinnerList'){
      this.TDWLSearch();
      $('#twoDWinnerList-tab').tab('show');
    }
    else if(this.type == '3D'){
      this.ThDWLSearch();
      $('#threeD-tab').tab('show');
    }
    else if(this.type == '3DWinnerList'){
      this.ThDWLSearch();
      $('#threeDWinnerList-tab').tab('show');
    }
    
  }

  handleError(error: HttpErrorResponse){
    if(error.status == 423)
    {
      this.spinner.hide();
      $("#deleteData").modal("show");
    }
    if(error.status == 500)
    {
      this.spinner.hide();
      this.toastr.error('Please check connection', 'Invalid!', {
        timeOut: 3000,
        positionClass: 'toast-top-right',
      });
    }
    if(error.status == 403)
    {
      this.spinner.hide();
      var id1 = 'tbltwoD' + this.idIndex;
      var table1 = $('#' + id1).DataTable();

      var id2 = 'tblthreeD' + this.idIndexTD;
      var table2 = $('#' + id2).DataTable();

      var id3 = 'tblTDWL' + this.idIndexTDWL;
      var table3 = $('#' + id3).DataTable();

      var id4 = 'tblThDWL' + this.idIndexThDWL;
      var table4 = $('#' + id4).DataTable();

      this.toastr.error("Limited Access.", 'Invalid!', {
        timeOut: 3000,
        positionClass: 'toast-top-right',
        });
    }
    if(error.status == 406)
    {
      this.spinner.hide();
      this.toastr.error('Failed!', 'Invalid!', {
        timeOut: 3000,
        positionClass: 'toast-top-right',
      });
    }
    return throwError(error);
  }

  OkLogout()
  {
    window.location.href ="./ad-login";
  }   
  TDWLSearch(){
    this.TDWList = [];
    var id = 'tblTDWL' + this.idIndexTDWL;
    var table = $('#' + id).DataTable();
    table.destroy();
    this.idIndexTDWL = this.idIndexTDWL +1;
    this.spinner.show();
    this.dto.token = this.storage.retrieve('token');
    
    let headers = new HttpHeaders();    
    headers = headers.set('Authorization', this.dto.token);

    let params = new HttpParams();
    // params = params.set('TDWLPhoneNo',this.TDWLPhoneNo).set('TDWLBetAmount',this.TDWLBetAmount).set('TDWLImgUrl',this.TDWLImgUrl).set('TDWLWinAmount', this.TDWLWinAmount).set('TDWLsection', this.TDWLsection).set('TDWLStatus', this.TDWLStatus).set("pageNumber","1").set("rowsOfPage","10");  
    if(this.TDWLName == undefined)
    {
      this.TDWLName ="";
    } 

    params = params.set('TDWLBetAmount',this.TDWLBetAmount).set('TDWLImgUrl',this.TDWLImgUrl).set('TDWLWinAmount', this.TDWLWinAmount).set('TDWLsection', this.TDWLsection).set('TDWLStatus', this.TDWLStatus).set("pageNumber","1").set("rowsOfPage","10").set('TDWLName',this.TDWLName);   
    
    this.http.get(this.funct.ipaddress + 'winnerInput/GetTwoDWinnerLists',{ params: params,headers:headers})
    .pipe(
      catchError(this.handleError.bind(this))
     )
    .subscribe(
      result => {
        this.dto.Response = {};
        this.dto.Response = result.results;
        this.TDWList = this.dto.Response;
        this.totalItems = result.totalRows;
        this.dtTrigger1.next();
        this.spinner.hide();
      }
    );
  }

  TDWLSearchTabClick(){
    this.TDWLPhoneNo = '';
    this.TDWLBetAmount = '';
    this.TDWLWinAmount = '';
    // this.TDWLsection = '10:30 AM';
    this.TDWLStatus = '';
    this.TDWLImgUrl = '';
    this.type ="2D";//amk added

    this.TDWList = [];
    var id = 'tblTDWL' + this.idIndexTDWL;
    var table = $('#' + id).DataTable();
    table.destroy();
    this.idIndexTDWL = this.idIndexTDWL +1;
    this.spinner.show();
    this.dto.token = this.storage.retrieve('token');
    
    let headers = new HttpHeaders();    
    headers = headers.set('Authorization', this.dto.token);

    let params = new HttpParams();
    // params = params.set('TDWLPhoneNo',this.TDWLPhoneNo).set('TDWLBetAmount',this.TDWLBetAmount).set('TWDLImgUrl',this.TDWLImgUrl).set('TDWLWinAmount', this.TDWLWinAmount).set('TDWLsection', this.TDWLsection).set('TDWLStatus', this.TDWLStatus).set("pageNumber","1").set("rowsOfPage","10");
    
    if(this.TDWLName == undefined)
    {
      this.TDWLName ="";
    }
    
    params = params.set('TDWLBetAmount',this.TDWLBetAmount).set('TWDLImgUrl',this.TDWLImgUrl).set('TDWLWinAmount', this.TDWLWinAmount).set('TDWLsection', this.TDWLsection).set('TDWLStatus', this.TDWLStatus).set("pageNumber","1").set("rowsOfPage","10").set('TDWLName',this.TDWLName);
    
    this.http.get(this.funct.ipaddress + 'winnerInput/GetTwoDWinnerLists',{ params: params,headers:headers})
    .pipe(
      catchError(this.handleError.bind(this))
     )
    .subscribe(
      result => {
        this.dto.Response = {};
        this.dto.Response = result.results;
        this.TDWList = this.dto.Response;
        this.totalItems = result.totalRows;
        this.dtTrigger1.next();
        this.spinner.hide();
      }
    );
  }

  ThDWLSearch(){
    this.ThDWList = [];
    var id = 'tblThDWL' + this.idIndexThDWL;
    var table = $('#' + id).DataTable();
    table.destroy();
    this.idIndexThDWL = this.idIndexThDWL +1;
    this.spinner.show();
    this.dto.token = this.storage.retrieve('token');
    
    let headers = new HttpHeaders();    
    headers = headers.set('Authorization', this.dto.token);

    let params = new HttpParams();
    // params = params.set('ThDWLPhoneNo',this.ThDWLPhoneNo).set('ThDWLBetAmount',this.ThDWLBetAmount).set('ThDWLWinAmount', this.ThDWLWinAmount).set('ThDWLStatus', this.ThDWLStatus).set("ThDWLPageNumber","1").set("ThDWLRowsOfPage","10");
    if(this.ThDWLName ==undefined)
    {
      this.ThDWLName ="";
    }

    params = params.set('ThDWLName',this.ThDWLName).set('ThDWLBetAmount',this.ThDWLBetAmount).set('ThDWLWinAmount', this.ThDWLWinAmount).set('ThDWLStatus', this.ThDWLStatus).set("ThDWLPageNumber","1").set("ThDWLRowsOfPage","10");
    
    this.http.get(this.funct.ipaddress + 'winnerInput/GetThreeDWinnerLists',{ params: params,headers:headers})
    .pipe(
      catchError(this.handleError.bind(this))
     )
    .subscribe(
      result => {
        this.dto.Response = {};
        this.dto.Response = result.results;
        this.ThDWList = this.dto.Response;
        this.totalItems1 = result.totalRows;
        this.dtTrigger3.next();
        this.spinner.hide();
      }
    );
  }

  ThDWLSearchTabClick(){
    this.ThDWLPhoneNo = '';
    this.ThDWLBetAmount = '';
    this.ThDWLWinAmount = '';
    this.ThDWLStatus = '';

    this.ThDWList = [];
    var id = 'tblThDWL' + this.idIndexThDWL;
    var table = $('#' + id).DataTable();
    table.destroy();
    this.idIndexThDWL = this.idIndexThDWL +1;
    this.spinner.show();
    this.dto.token = this.storage.retrieve('token');
    
    let headers = new HttpHeaders();    
    headers = headers.set('Authorization', this.dto.token);

    let params = new HttpParams();

    if(this.ThDWLName ==undefined)
    {
      this.ThDWLName ="";
    }
    
    params = params.set('ThDWLName',this.ThDWLName).set('ThDWLBetAmount',this.ThDWLBetAmount).set('ThDWLWinAmount', this.ThDWLWinAmount).set('ThDWLStatus', this.ThDWLStatus).set("ThDWLPageNumber","1").set("ThDWLRowsOfPage","10");
    
    this.http.get(this.funct.ipaddress + 'winnerInput/GetThreeDWinnerLists',{ params: params,headers:headers})
    .pipe(
      catchError(this.handleError.bind(this))
     )
    .subscribe(
      result => {
        this.dto.Response = {};
        this.dto.Response = result.results;
        this.ThDWList = this.dto.Response;
        this.totalItems1 = result.totalRows;
        this.dtTrigger3.next();
        this.spinner.hide();
      }
    );
  }

  addBtn(){
    if(this.twoDWinnerInputList.length >= 10)
    {
      this.toastr.error("You can save maximum 10 users for each time!", 'Invalid!', {
        timeOut: 3000,
        positionClass: 'toast-top-right'
      });
      return;
    }
    else
    {
      this.twoDWinnerInputList.push({name:'',phoneNumber:'',imgURL:'',betAmount:0,winAmount:0,section:'',status:'Active',odd:0});
    }


  }

  threeDAddBtn(){
    if(this.threeDWinnerInputList.length >= 10)
    {
      this.toastr.error("You can save maximum 10 users for each time!", 'Invalid!', {
        timeOut: 3000,
        positionClass: 'toast-top-right'
      });
      return;
    }
    else
    {
      this.threeDWinnerInputList.push({threeDName:'',threeDPhoneNumber:'',threeDImgUrl:'',threeDBetAmount:0,threeDWinAmount:0,threeDStatus:'Active',ThreeDOdd:0});
    }
  }

  threeDOddSearch()
  {
    this.spinner.show();
    this.dto.token = this.storage.retrieve('token');
   
    let headers = new HttpHeaders();    
    headers = headers.set('Authorization', this.dto.token);
    
    this.http.get(this.funct.ipaddress + 'winnerInput/GetThreeDOdd',{ headers:headers})
    .pipe(
      catchError(this.handleError.bind(this))
     )
    .subscribe(
      result => {
        this.dto.Response = {};
        this.dto.Response = result;
        this.threeDOdd = this.dto.Response;
        this.spinner.hide();
      }
    );
  } 

  twoDOddSearch()
  {
    this.spinner.show();
    this.dto.token = this.storage.retrieve('token');
   
    let headers = new HttpHeaders();    
    headers = headers.set('Authorization', this.dto.token);

    let params = new HttpParams();
    params = params.set("section", this.section);
    
    this.http.get(this.funct.ipaddress + 'winnerInput/GetTwoDOdd',{ params:params, headers:headers})
    .pipe(
      catchError(this.handleError.bind(this))
     )
    .subscribe(
      result => {
        this.dto.Response = {};
        this.dto.Response = result;
        this.odd = this.dto.Response;
        this.spinner.hide();
      }
    );
  } 


  calculatedWinAmount(i: number): void {
    const betAmountInput = document.getElementById(`threeDBetAmount${i}`) as HTMLInputElement;
    const oddInput = document.getElementById(`ThreeDOdd${i}`) as HTMLInputElement;
    const winAmountInput = document.getElementById(`threeDWinAmount${i}`) as HTMLInputElement;

    const betAmount = parseFloat(betAmountInput.value);
    const odd = parseFloat(oddInput.value);

    const calculatedWin = betAmount * odd;
  
    winAmountInput.value = isNaN(calculatedWin) ? '' : calculatedWin.toString();
  }


  // calculatedWinAmount(index){
  //   if(this.odd == undefined || this.odd == '' || this.odd == null )
  //   {
  //     this.toastr.error("Please choose section!", 'Invalid!', {
  //       timeOut: 3000,
  //       positionClass: 'toast-top-right'
  //     });
  //     return;
  //   }
  //   var calWinAmount = this.odd * Number($("#betAmount"+index).val());
  //   var threeDWinAmount = this.odd * Number($("#betAmount"+index).val());
  //   $("#winAmount"+index).val(calWinAmount);

   
  // } 

  threeDCalculatedWinAmount(index){
    var calThreeDWinAmount = this.threeDOdd * Number($("#threeDBetAmount"+index).val());
    $("#threeDWinAmount"+index).val(calThreeDWinAmount);
  } 

  cancel(){
    this.twoDWinnerInputList = [];
    this.twoDWinnerInputList.push({name:'',phoneNumber:'',imgURL:'',betAmount:0,winAmount:0,section:'',status:'Active',odd:0});
  }

  threeDCancel(){
    this.threeDWinnerInputList = [];
    this.threeDWinnerInputList.push({threeDName:'',threeDPhoneNumber:'',threeDImgUrl:'',threeDBetAmount:0,threeDWinAmount:0,threeDStatus:'Active',ThreeDOdd:0});
  }   
  preview(files ,index) {
    if (files.length === 0)
      return;
    var mimeType = files[0].type;
    if (mimeType.match(/image\/*/) == null) {
      this.message = "Only images are supported.";
      return;
    }
    var reader = new FileReader();
    this.imagePath = files;
    this.filePath = files[0];
    reader.readAsDataURL(files[0]);
    reader.onload = (_event) => {
      this.imageURL = reader.result;
     console.log("imgURL>>>" + JSON.stringify(this.imageURL));
    }
  }

  // preview(files: FileList | null, index: number) {
  //   if (files && files.length > 0) {
  //     const reader = new FileReader();
  //     reader.onload = (e: any) => {
  //       // this.imgURL[index] = e.target.result;
  //       this.imageURL[index] = e.target.result;
  //     };
  //     reader.readAsDataURL(files[0]);
  //   }
  // }

  save(){   
      this.spinner.show();
      this.dto.token = this.storage.retrieve('token');
      let headers = new HttpHeaders();
      headers = headers.set('Authorization',  this.dto.token);  
      //amk
      
      //
     for(var i = 0 ; i<this.twoDWinnerInputList.length ;i++)
     {
          this.twoDWinnerInputList[i]["name"] = $('#name'+i).val();
          if (this.twoDWinnerInputList[i]["name"] =="") 
          {          
             this.spinner.hide();
             this.toastr.error(" Name ", 'Invalid!', {
             timeOut: 3000,
             positionClass: 'toast-top-right',
             });
             return;
          }
          this.twoDWinnerInputList[i]["phoneNumber"] = $('#phoneNumber'+i).val(); 
          if (this.twoDWinnerInputList[i]["phoneNumber"] =="") 
          {          
             this.spinner.hide();
             this.toastr.error(" Phone Number ", 'Invalid!', {
             timeOut: 3000,
             positionClass: 'toast-top-right',
             });
             return;
          }
          else if(this.twoDWinnerInputList[i]["phoneNumber"] !="")
          {
              this.phoneNumber = this.twoDWinnerInputList[i]["phoneNumber"];
              const myanmarCharactersRegex = new RegExp('^[\u1000-\u109F\uAA60-\uAA7F\uA9E0-\uA9FF]+$');
              if (myanmarCharactersRegex.test(this.phoneNumber)) 
              {        
                  this.spinner.hide();
                  this.toastr.error( "Myanmar typing detected!", '', {
                  timeOut: 5000,
                  positionClass: 'toast-top-right',
                  });   
                  return;   
              }
          }       
          this.twoDWinnerInputList[i]["betAmount"] = Number($('#betAmount'+i).val());
          if (this.twoDWinnerInputList[i]["betAmount"] =="") 
          {          
            this.spinner.hide();
            this.toastr.error("Bet Amount ", 'Invalid!', {
            timeOut: 3000,
            positionClass: 'toast-top-right',
            });
            return;
          }
          this.twoDWinnerInputList[i]["section"] = this.section;        
          this.twoDWinnerInputList[i]["status"] = $("input[type='radio'][name=r"+i+"]:checked").val();      
          if (this.imageURL != '' && this.imageURL != null && this.imageURL != undefined)
          {
              if (this.imageURL.includes('data:image/jpeg;base64,')) 
              {
                this.imageURL = this.imageURL.replace("data:image/jpeg;base64,", "");
              }
              if (this.imageURL.includes('data:image/png;base64,'))
              {
                  this.imageURL = this.imageURL.replace("data:image/png;base64,", "");
              }
         } 
          else 
          {
              this.imageURL = "";
          } 
          this.twoDWinnerInputList[i]["imgURL"] = this.imageURL;    
      }    
      this.http.post(this.funct.ipaddress + 'winnerInput/SaveTwoDWinnerInput', this.twoDWinnerInputList, {headers: headers})
      .pipe(
        catchError(this.handleError.bind(this))
       )
      .subscribe(
        result => {
          this.dto.Response = {};
          this.dto.Response = result;
          if (this.dto.Response.status == 'Success') {
            this.spinner.hide();   
            this.twoDWinnerInputList = [];
            this.twoDWinnerInputList.push({name:'',phoneNumber:'',betAmount:0,winAmount:0,section:this.section,status:'Active',odd:this.odd});           
            this.toastr.success(this.dto.Response.message, 'Success!', {
              timeOut: 3000,
              positionClass: 'toast-top-right'
            });          
          }
          else{
            this.spinner.hide();
            this.toastr.error(this.dto.Response.message, 'Invalid!', {
            timeOut: 3000,
            positionClass: 'toast-top-right',
            });
          }
        });
  }


  save3D(){   
    this.spinner.show();
    this.dto.token = this.storage.retrieve('token');
    let headers = new HttpHeaders();
    headers = headers.set('Authorization',  this.dto.token);  
  
   for(var i = 0 ; i<this.threeDWinnerInputList.length ;i++)
   {
        this.twoDWinnerInputList[i]["name3D"] = $('#name3D'+i).val();
        if (this.twoDWinnerInputList[i]["name3D"] =="") 
        {          
           this.spinner.hide();
           this.toastr.error(" Name ", 'Invalid!', {
           timeOut: 3000,
           positionClass: 'toast-top-right',
           });
           return;
        }
        this.twoDWinnerInputList[i]["phoneNumber3D"] = $('#phoneNumber3D'+i).val(); 
        if (this.twoDWinnerInputList[i]["phoneNumber3D"] =="") 
        {          
           this.spinner.hide();
           this.toastr.error(" Phone Number ", 'Invalid!', {
           timeOut: 3000,
           positionClass: 'toast-top-right',
           });
           return;
        }
        else if(this.twoDWinnerInputList[i]["phoneNumber3D"] !="")
        {
            this.phoneNumber = this.twoDWinnerInputList[i]["phoneNumber3D"];
            const myanmarCharactersRegex = new RegExp('^[\u1000-\u109F\uAA60-\uAA7F\uA9E0-\uA9FF]+$');
            if (myanmarCharactersRegex.test(this.phoneNumber)) 
            {        
                this.spinner.hide();
                this.toastr.error( "Myanmar typing detected!", '', {
                timeOut: 5000,
                positionClass: 'toast-top-right',
                });   
                return;   
            }
        }       
        this.twoDWinnerInputList[i]["betAmount"] = Number($('#betAmount'+i).val());
        if (this.twoDWinnerInputList[i]["betAmount"] =="") 
        {          
          this.spinner.hide();
          this.toastr.error("Bet Amount ", 'Invalid!', {
          timeOut: 3000,
          positionClass: 'toast-top-right',
          });
          return;
        }
        this.twoDWinnerInputList[i]["section"] = this.section;        
        this.twoDWinnerInputList[i]["status"] = $("input[type='radio'][name=r"+i+"]:checked").val();      
        if (this.imageURL != '' && this.imageURL != null && this.imageURL != undefined)
        {
            if (this.imageURL.includes('data:image/jpeg;base64,')) 
            {
              this.imageURL = this.imageURL.replace("data:image/jpeg;base64,", "");
            }
            if (this.imageURL.includes('data:image/png;base64,'))
            {
                this.imageURL = this.imageURL.replace("data:image/png;base64,", "");
            }
       } 
        else 
        {
            this.imageURL = "";
        } 
        this.twoDWinnerInputList[i]["imgURL"] = this.imageURL;    
    }    
    this.http.post(this.funct.ipaddress + 'winnerInput/SaveTwoDWinnerInput', this.twoDWinnerInputList, {headers: headers})
    .pipe(
      catchError(this.handleError.bind(this))
     )
    .subscribe(
      result => {
        this.dto.Response = {};
        this.dto.Response = result;
        if (this.dto.Response.status == 'Success') {
          this.spinner.hide();   
          this.twoDWinnerInputList = [];
          this.twoDWinnerInputList.push({name:'',phoneNumber:'',betAmount:0,winAmount:0,section:this.section,status:'Active',odd:this.odd});           
          this.toastr.success(this.dto.Response.message, 'Success!', {
            timeOut: 3000,
            positionClass: 'toast-top-right'
          });          
        }
        else{
          this.spinner.hide();
          this.toastr.error(this.dto.Response.message, 'Invalid!', {
          timeOut: 3000,
          positionClass: 'toast-top-right',
          });
        }
      });
}



  threeDSave(){
    this.spinner.show();
    this.dto.token = this.storage.retrieve('token');
    let headers = new HttpHeaders();
    headers = headers.set('Authorization',  this.dto.token);
    for(var i = 0 ; i<this.threeDWinnerInputList.length ;i++)
    {
          this.threeDWinnerInputList[i]["threeDName"] = $('#threeDName'+i).val();
          if (this.threeDWinnerInputList[i]["threeDName"] =="") 
          {          
             this.spinner.hide();
             this.toastr.error(" Name ", 'Invalid!', {
             timeOut: 3000,
             positionClass: 'toast-top-right',
             });
             return;
          }
          this.threeDWinnerInputList[i]["threeDPhoneNumber"] = $('#threeDPhoneNumber'+i).val();
          if ( this.threeDWinnerInputList[i]["threeDPhoneNumber"] =="") 
          {          
             this.spinner.hide();
             this.toastr.error(" Phone Number ", 'Invalid!', {
             timeOut: 3000,
             positionClass: 'toast-top-right',
             });
             return;
          }
          else if( this.threeDWinnerInputList[i]["threeDPhoneNumber"] !="")
          {
              this.phoneNumber =  this.threeDWinnerInputList[i]["threeDPhoneNumber"];
              const myanmarCharactersRegex = new RegExp('^[\u1000-\u109F\uAA60-\uAA7F\uA9E0-\uA9FF]+$');
              if (myanmarCharactersRegex.test(this.phoneNumber)) 
              {        
                  this.spinner.hide();
                  this.toastr.error( "Myanmar typing detected!", '', {
                  timeOut: 5000,
                  positionClass: 'toast-top-right',
                  });   
                  return;   
              }
          }       
          this.threeDWinnerInputList[i]["threeDBetAmount"] = Number($('#threeDBetAmount'+i).val());
          this.threeDWinnerInputList[i]["ThreeDOdd"] = Number($('#ThreeDOdd'+i).val());
          this.threeDWinnerInputList[i]["threeDWinAmount"] = Number($('#threeDWinAmount'+i).val());
          if (this.threeDWinnerInputList[i]["threeDBetAmount"] =="") 
         {          
            this.spinner.hide();
            this.toastr.error("Bet Amount ", 'Invalid!', {
            timeOut: 3000,
            positionClass: 'toast-top-right',
            });
            return;
         }
         if (this.threeDWinnerInputList[i]["ThreeDOdd"] =="") 
         {          
            this.spinner.hide();
            this.toastr.error("ThreeD Odd ", 'Invalid!', {
            timeOut: 3000,
            positionClass: 'toast-top-right',
            });
            return;
         }
         if (this.threeDWinnerInputList[i]["threeDWinAmount"] =="") 
         {          
            this.spinner.hide();
            this.toastr.error("ThreeD Win Amount ", 'Invalid!', {
            timeOut: 3000,
            positionClass: 'toast-top-right',
            });
            return;
         }
          // this.threeDWinnerInputList[i]["threeDWinAmount"] = Number($('#threeDWinAmount'+i).val());
          // this.threeDWinnerInputList[i]["ThreeDOdd"] = this.threeDOdd;
          this.threeDWinnerInputList[i]["threeDStatus"] = $("input[type='radio'][name=rthreeD"+i+"]:checked").val();
          //threeDImgUrl
 
          if (this.imageURL !== '' && this.imageURL !== null && this.imageURL!== undefined)
          {
              if(this.imageURL.includes('data:image/jpeg;base64,'))
              this.threeDWinnerInputList[i]["threeDImgUrl"] = this.imageURL.replace("data:image/jpeg;base64,","");
              if(this.imageURL.includes('data:image/png;base64,'))
              this.threeDWinnerInputList[i]["threeDImgUrl"] = this.imageURL.replace("data:image/png;base64,","");
          }  
          else
          {
              this.threeDWinnerInputList[i]["threeDImgUrl"]="";    
          }
    }

    this.http.post(this.funct.ipaddress + 'winnerInput/SaveThreeDWinnerInput', this.threeDWinnerInputList, {headers: headers})
    .pipe(
      catchError(this.handleError.bind(this))
     )
    .subscribe(
      result => {
        this.dto.Response = {};
        this.dto.Response = result;
        if (this.dto.Response.status == 'Success') {
          this.spinner.hide();   
          this.threeDWinnerInputList = [];
          this.threeDWinnerInputList.push({threeDName:'',threeDPhoneNumber:'',threeDImgUrl:'',threeDBetAmount:0,threeDWinAmount:0,threeDStatus:'Active',ThreeDOdd:this.threeDOdd});        
          this.toastr.success(this.dto.Response.message, 'Success!', {
            timeOut: 3000,
            positionClass: 'toast-top-right'
          });          
        }
        else{
          this.spinner.hide();
          this.toastr.error(this.dto.Response.message, 'Invalid!', {
          timeOut: 3000,
          positionClass: 'toast-top-right',
          });
        }
      });
  }

  ngOnDestroy(): void{
    this.dtTrigger1.unsubscribe();
    this.dtTrigger3.unsubscribe();
  }

  gty(page: any){
    this.TDWList = [];
    var id = 'tblTDWL' + this.idIndexTDWL;
    var table = $('#' + id).DataTable();
    table.destroy();
    this.idIndexTDWL = this.idIndexTDWL +1;
    this.spinner.show();
    this.dto.token = this.storage.retrieve('token');
    
    let headers = new HttpHeaders();    
    headers = headers.set('Authorization', this.dto.token);
    if(this.TDWLName == undefined)
    {
      this.TDWLName ="";
    }

    let params = new HttpParams();
    params = params.set('TDWLBetAmount',this.TDWLBetAmount).set('TDWLImgUrl',this.TDWLImgUrl).set('TDWLWinAmount', this.TDWLWinAmount).set('TDWLsection', this.TDWLsection).set('TDWLStatus', this.TDWLStatus).set("pageNumber",page).set("rowsOfPage","10").set('TDWLName',this.TDWLName);
    
    this.http.get(this.funct.ipaddress + 'winnerInput/GetTwoDWinnerLists',{ params: params,headers:headers})
    .pipe(
      catchError(this.handleError.bind(this))
     )
    .subscribe(
      result => {
        this.dto.Response = {};
        this.dto.Response = result.results;
        this.TDWList = this.dto.Response;
        this.totalItems = result.totalRows;
        this.dtTrigger1.next();
        this.spinner.hide();
      }
    );
  }

  gty1(page: any){
    this.ThDWList = [];
    var id = 'tblThDWL' + this.idIndexThDWL;
    var table = $('#' + id).DataTable();
    table.destroy();
    this.idIndexThDWL = this.idIndexThDWL +1;
    this.spinner.show();
    this.dto.token = this.storage.retrieve('token');
    
    let headers = new HttpHeaders();    
    headers = headers.set('Authorization', this.dto.token);
  
    let params = new HttpParams();

    if(this.ThDWLName ==undefined)
    {
      this.ThDWLName ="";
    }

    params = params.set('ThDWLName',this.ThDWLName).set('ThDWLBetAmount',this.ThDWLBetAmount).set('ThDWLWinAmount', this.ThDWLWinAmount).set('ThDWLStatus', this.ThDWLStatus).set("ThDWLPageNumber",page).set("ThDWLRowsOfPage","10");
    
    this.http.get(this.funct.ipaddress + 'winnerInput/GetThreeDWinnerLists',{ params: params,headers:headers})
    .pipe(
      catchError(this.handleError.bind(this))
     )
    .subscribe(
      result => {
        this.dto.Response = {};
        this.dto.Response = result.results;
        this.ThDWList = this.dto.Response;
        this.totalItems1 = result.totalRows;
        this.dtTrigger3.next();
        this.spinner.hide();
      }
    );
  }

  onChangeTDWLTagSection() {     
    this.storage.store('TDWLTagsection', this.TDWLsection.toString());  
  }

}
