
<div class="wrapper" ng-app="" >
    <app-header-sidebar></app-header-sidebar>
  
        <div class="content-wrapper">
          <!-- Content Header (Page header) -->
          <div class="content-header">
            <div class="container-fluid">
              <!--<div class="row mb-2" style="border: 1px solid #ced4da;border-radius: 0.25rem;">
                <div class="col-sm-8">
                  <h3 class="m-0 text-dark" style="font-weight: bold;padding: 3px 0px 3px 5px;"> 2D Bet History Report </h3>
                </div>
              </div>-->
            </div><!-- /.container-fluid -->
          </div>
          <!-- /.content-header -->
      
          <!-- Main content -->
          <section class="content">
            <div class="container-fluid">
             
              <div class="card">
                <div class="card-header">
                    <h3 class="card-title" style="font-weight: bold;"> User Registration Report  </h3>
                </div>
                <!-- /.card-header -->
                <div class="card-body">
              <form>
                <div class="row">
                  <div class="form-group col-md-2">
                      <label>Registration From Date </label><span class ="req">*</span>
                  </div>
                  <div class="form-group col-md-2">
                    <input type = "date" style="width:250px;"   [(ngModel)]="fromdate" name = "fromdate" class="form-control form-control-sm" id = "fdate">
                  </div>
              </div>
  
              <div class="row" >
                  <div class="form-group col-md-2">
                      <label>Registration To Date</label><span class ="req">*</span>
                  </div>
                  <div class="form-group col-md-2">
                    <input type = "date" style="width:250px;"  name = "todate" [(ngModel)]="todate" [ngModelOptions]="{standalone: true}" min="{{fromdate | date:'yyyy-MM-dd'}}" class="form-control form-control-sm" id = "tdate">
                  </div>
              </div>
              
              <div class="row">
                <div class="form-group col-md-2">
                  <label>Choose User Type</label><span class ="req">*</span>
                </div>
                <div class = "form-group col-md-2">
                  <select style="width:250px;" id="foo_bar" class="status form-control form-control-sm" [(ngModel)]="ftdate" [ngModelOptions]="{standalone: true}">
                    <option value="checked" selected>Never Top-up User</option>
                    <option value="unchecked">Already Top-up User</option>
                    <option value="all">Already And Never Top-up User</option>
                  </select>
                </div>
                </div>
                <div class="row">
                <div class="form-group col-md-2">
                  <label>Choose Agent Referral Code</label>
                </div>
                <div class = "form-group col-md-2">
                    <!-- <select style="width:250px;" (change) = "flagAgent()" id = "agentId" class="form-control form-control-sm"
                     [(ngModel)]="agentId" [ngModelOptions]="{standalone: true}">
                      <option *ngFor="let agent of activeagentList" value = "{{agent.id}}" >
                        {{agent.name}} - {{agent.referral_code}}
                      </option>
                   </select> -->
                    <ngx-bootstrap-multiselect [options]="myOptions" [(ngModel)]="optionsModel" (ngModelChange)="onChange()"
                      [ngModelOptions]="{standalone: true}" class = "form-control-sm" style="margin-left : -7px" [texts]="myTexts" [settings]="mySettings">
                    </ngx-bootstrap-multiselect>

                 </div>
               </div>
               <!-- <div class="row">
                <div class="form-group col-md-2">
                   <label>Choose App Version</label>
               </div>
               <div class = "form-group col-md-2">
                      <select style="width:250px;" class="form-control form-control-sm"
                          [(ngModel)]="appVersion"
                          [ngModelOptions]="{standalone: true}" id="appVersion">
                          <option *ngFor="let appVersion of appVersionList" value={{appVersion.app_version}}>
                              {{appVersion.app_version}}
                      </select>
               </div>
             </div> -->
                  <div class="row">
                    <div class = "col-lg-2">
                      <label> Choose Option</label>
                     </div>
                     <div class="form-group col-md-5" style="cursor: pointer;">
                      <img *ngIf="!clickkbzpay" src="assets/image/excel_pm.png" (click) = "getReportOption('excel')"
                        style="opacity: 0.3; height : 60px ;width: 60px;object-fit: cover;padding-left : 0px;border: 3px solid #ffffff;border-radius: 10px;border-color: #ffffff;">
                      <img *ngIf="clickkbzpay" src="assets/image/excel_pm.png" (click) = "getReportOption('excel')"
                       style="opacity: 1; height : 60px ; width: 60px; object-fit: cover;border: 3px sol;border-radius: 10px;padding-left : 0px;">
                       &nbsp;&nbsp;
      
                      <img *ngIf="!clickwavepay" src="assets/image/pdf3.png" (click) = "getReportOption('pdf')"
                        style="opacity: 0.3; margin-left : 2px; height : 60px ;width: 60px;object-fit: cover;border: 3px solid #ffffff;border-radius: 10px;border-color: #ffffff;">
                      <img *ngIf="clickwavepay" src="assets/image/pdf3.png" (click) = "getReportOption('pdf')"
                       style="opacity: 1; height : 60px ; margin-left : 2px; width: 60px;object-fit: cover;border: 3px sol;border-radius: 10px">
      
                       &nbsp;&nbsp;
      
                      <img *ngIf="!csvrep" src="assets/image/csv3.png" (click) = "getReportOption('csv')"
                        style="opacity: 0.3; padding-left :0px; height : 60px ;width: 60px;object-fit: cover;border: 3px solid #ffffff;border-radius: 10px;border-color: #ffffff;">
                      <img *ngIf="csvrep" src="assets/image/csv3.png" (click) = "getReportOption('csv')"
                       style="opacity: 1; height : 60px ; padding-left : 0px; width: 60px;object-fit: cover;border: 3px sol;border-radius: 10px">
                      </div> 
                  </div>
                  <div class="row">
                    <div class="form-group col-md-2 col-sm-4 col-xs-4">
                    </div>
                  <div class="form-group col-md-2 col-sm-4 col-xs-4" *ngIf="hardcode_add_bool === true">
                      <label></label>
                      <button id="generaterep" type="button" class="btn btn-block btn-primary btn-sm" style="margin-top: 4%;" (click)="userregistrationReport()">Generate Report</button>
                  </div>
                  </div>
              </form>
              </div><!--card body-->
              </div><!--card-->
              <!-- /.row -->
            </div><!--/. container-fluid -->
          </section>
          <!-- /.content -->
        </div>
  
        <aside class="control-sidebar control-sidebar-dark">
          <!-- Control sidebar content goes here -->
        </aside>
      
   <app-footer></app-footer>
       <ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#fff" type="square-jelly-box">
        <p style="font-size: 16px; color: white">Loading...</p>
      </ngx-spinner> 
  </div>
  
  