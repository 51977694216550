



          <div class="row">

            <div class="form-group col-md-2">
              <label>Number</label>
              <input type="text" onKeyPress="if(this.value.length==2) return false;"
                class="form-control form-control-sm" [(ngModel)]="number" [ngModelOptions]="{standalone: true}"
                (keypress)="numericOnly($event)">
            </div>

            <div class="form-group col-md-2">
              <label>Date</label>
              <input id="singleDate" readonly class="form-control form-control-sm" placeholder="Select Date"
                [ngxDatePicker]="dateInstanceSingle" [value]="singleDate" [(ngModel)] = "date"
                [ngModelOptions]="{standalone: true}"
                (valueChange)="onChangeSingle()">
              <ngx-date-picker #dateInstanceSingle></ngx-date-picker>
            </div>

            <div class="form-group col-md-2">
              <label>Time</label>
              <select class="time form-control form-control-sm col-md-12 col-sm-4 col-xs-4" [(ngModel)]="time"
                  [ngModelOptions]="{standalone: true}">
                  <option value="null">ALL</option>
                  <option *ngFor="let objSection of sectionList" value = "{{objSection.sectionName}}" >
                    {{objSection.sectionName}}
                  </option>
              </select>
            </div>

            <div class="col-md-2"></div>

            <div class="form-group col-md-2 col-sm-4 col-xs-4">
              <label></label>
              <button id="search" type="button" class="btn btn-block btn-primary btn-sm" style="margin-top: 4%;"
                (click)="search()">Search</button>
            </div>

            <div class="form-group col-md-2 col-sm-4 col-xs-4" *ngIf="hardcode_add_bool === true">
              <label></label>
              <button type="button" class="btn btn-block btn-success btn-sm" style="margin-top: 4%;"
                [routerLink]="['/twod-detail','a']">
                Add 2D Result
              </button>
            </div>

            <div class="col-md-12">
              <div class="card">
                <div class="card-header">
                  <h3 class="card-title" style="font-weight: bold;"> 2D Result List </h3>
                </div>
          
                <div class="card-body">
                  <table width='100%' id="{{'tblTwoD' + idIndex}}" datatable [dtTrigger]="dtTrigger"
                    [dtOptions]="dtOptions" class="table table-bordered table-hover">
                    <thead>
                      <tr>
                        <th style="font-family: unicode;">Number</th>
                        <th style="font-family: unicode;">Date</th>
                        <th style="font-family: unicode;">Time</th>
                        <th style="font-family: unicode;">Created Date</th>
                        <th style="font-family: unicode;">Created By</th>
                        <th style="font-family: unicode;" *ngIf="hardcode_edit_bool === true">Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let twod of twodList">
                        <td style="font-family: unicode;">{{twod.number}}</td>
                        <td style="font-family: unicode;">{{twod.for_date_time_Str}}</td>
                        <td style="font-family: unicode;">{{twod.for_time}}</td>
                        <td style="font-family: unicode;">{{twod.created_date_Str}}</td>
                        <td style="font-family: unicode;">{{twod.created_by}}</td>
                        <td style="font-family: unicode;" *ngIf="hardcode_edit_bool === true"><a [routerLink]="['/twod-detail', twod.id]"><i
                              class="fas fa-edit" style="color:#4b88eb"></i></a>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
          
              </div>
            </div>

          </div>
    
    



  <app-footer></app-footer>
  <ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#fff" type="square-jelly-box">
    <p style="font-size: 16px; color: white">Loading...</p>
  </ngx-spinner>



<div id="deleteData" class="modal fade">
  <div class="modal-dialog">
      <div class="modal-content">

          <div class="modal-body">
              <form>

                  <div class="row" style="margin-top: 5%;margin-bottom: 3%;margin-left: 9%;">
                      <div class="form-group col-md-12">
                          <h5> This Account is used by another device. 
                              Please Sign Out. Login Again. </h5>
                      </div>
                  </div>

                  <div class="row" style="margin-left: 40%;margin-bottom: 3%;">
                      <div class="col-md-5 col-sm-6" id="deleteBtn">
                          <button type="button" class="btn btn-block btn-success" (click)="OkLogout()"> Ok </button>
                      </div>
                  </div>
              </form>
          </div>

      </div>
  </div>
</div>