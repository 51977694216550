<div class="wrapper">
    <app-header-sidebar></app-header-sidebar>
    
    <div class="content-wrapper">      
        <div class="content-header">
          <div class="container-fluid">
            <div class="row mb-2" style="border: 1px solid #ced4da;border-radius: 0.25rem;">
              <div class="col-sm-8">
                <h3 class="m-0 text-dark" style="font-weight: bold;padding: 3px 0px 3px 5px;"> SKM Lock User List </h3>
              </div>
            </div>
          </div>
        </div>
      
        <section class="content">
          <div class="container-fluid">
            <form>
              

              <div class="row">

                <div class="form-group col-md-2">
                  <label>Phone No</label>
                  <input class="form-control form-control-sm" [(ngModel)]="phoneNo" [ngModelOptions]="{standalone: true}" (input)="onInput($event)">
                  </div>

                <div class="form-group col-md-2 col-sm-2 col-xs-2">
                  <label>&nbsp;&nbsp;</label>
                  <button id="search" type="button" class="btn btn-block btn-primary btn-sm" (click)="getAllData()">Search</button>
                </div>
                
              </div>

                <div class="col-md-12">
                  <div class="card">
                    <div class="card-header">
                      <h3 class="card-title" style="font-weight: bold;"> SKM Lock User List </h3>
                    </div>
                    
                    <div class="card-body">
                      <table width='100%' id="{{'tblUser' + idIndex}}" datatable [dtTrigger]="dtTrigger" [dtOptions]="dtOptions" class="table table-bordered table-hover">
                        <thead>
                        <tr>
                          <th style="font-family: unicode;">Name</th>
                          <th style="font-family: unicode;">Phone No</th>                
                          <th style="font-family: unicode;">Wallet</th>
                          <th style="font-family: unicode;">Total Topup</th>                       
                          <th style="font-family: unicode;">Total Withdraw</th>
                          <th style="font-family: unicode;">Bet Amount</th>
                          <th style="font-family: unicode;">Win Amount</th>
                          <th style="font-family: unicode;">Total Game Deposit</th>
                          <th style="font-family: unicode;">Total Game Withdraw</th>
                          <th style="font-family: unicode;">Status</th>
                          <th style="font-family: unicode;">Register Date</th>
                          <th style="font-family: unicode;">Last Login Date/Time</th>
                          <th style="font-family: unicode;">Device Type</th>
                          <th style="font-family: unicode;">Action</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr *ngFor="let user of userList"
                          >
                          <td style="font-family: unicode;width: 150px;">{{user.name}}</td>
                          <!-- <td style="font-family: unicode;"><a [routerLink] = "['/user-detail', user.id]">{{user.phone_no}}</a></td>                    -->
                          <td style="font-family: unicode;">
                            <a [routerLink]="['/user-detail', user.id]" [queryParams]="{ type: 'skmlock' }">{{user.phone_no}}</a>
                          </td>  
                          <td style="font-family: unicode;" class="text-right">{{user.balance_Str}}</td>
                          <td style="font-family: unicode;" class="text-right">{{user.topup_balance_Str}} </td>                        
                          <td style="font-family: unicode;" class="text-right">{{user.withdraw_balance_Str}} </td>
                          <td style="font-family: unicode;" class="text-right">{{user.bet_amount_Str}} </td>
                          <td style="font-family: unicode;" class="text-right">{{user.win_amount_Str}} </td>
                          <td style="font-family: unicode;" class="text-right">{{user.win_amount_Str}} </td>
                          <td style="font-family: unicode;" class="text-right">{{user.win_amount_Str}} </td>
                          <td style="font-family: unicode;">{{user.status}}</td>
                          <td style="font-family: unicode;">{{user.created_date_Str}}</td>
                          <td style="font-family: unicode;">{{user.last_used_time_str}}</td>
                          <td style="font-family: unicode;">{{user.deviceos}}</td>
                          <td style="font-family: unicode;">                       
                            <button class="btn btn-primary custom-button" 
                            (click)="showEditRolesModel(user.phone_no)">
                            Unlock
                          </button>
                           
                           
                        </td>  
                        </tr>                        
                        </tbody>
                      </table>
                     
                
                        <tr *ngFor="let user of userList | paginate
                        : {
                            itemsPerPage: itemsPerPage,
                            currentPage: page,
                            totalItems: totalItems
                          }" 
                          >
                      </tr> 

                    

                    <div class = "row">
                      <div class = "col-md-6"></div>
                      <div class = "col-md-6">
                        <pagination-controls
                          class="pagi"
                          (pageChange)="gty((page = $event))"
                          
                        ></pagination-controls>
                    </div> 
              
                    </div>
                  </div>
                   
                  </div>
                </div>

            </form>         
          </div>
        </section>
       
      </div>




      <aside class="control-sidebar control-sidebar-dark">
  
      </aside>
    
    <app-footer></app-footer>
     <ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#fff" type="square-jelly-box">
      <p style="font-size: 16px; color: white">Loading...</p>
    </ngx-spinner>
</div>

<div id="deleteData" class="modal fade">
  <div class="modal-dialog">
      <div class="modal-content">

          <div class="modal-body">
              <form>

                  <div class="row" style="margin-top: 5%;margin-bottom: 3%;margin-left: 9%;">
                      <div class="form-group col-md-12">
                          <h5> This Account is used by another device. 
                              Please Sign Out. Login Again. </h5>
                      </div>
                  </div>

                  <div class="row" style="margin-left: 40%;margin-bottom: 3%;">
                      <div class="col-md-5 col-sm-6" id="deleteBtn">
                          <button type="button" class="btn btn-block btn-success" (click)="OkLogout()"> Ok </button>
                      </div>
                  </div>
              </form>
          </div>

      </div>
  </div>
</div>




<div id="deleteDataForTutorial" class="modal fade">
  <div class="modal-dialog">
      <div class="modal-content">

          <div class="modal-body">
              <form>

                  <div class="row" style="margin-top: 5%;margin-bottom: 3%;margin-left: 9%;">
                      <div class="form-group col-md-12" style="text-align: center;">
                          <h3 class="text-center"> Are you sure want to unlock? </h3>
                      </div>
                  </div>

                  <div class="row" style="margin-left: 10%;margin-bottom: 3%;">
                      
                      <div class="col-md-5">
                          <button type="button" class="btn btn-block btn-danger" (click) = "closeTran()" [routerLink] = "['/skmlockuserlist']"> Cancel </button>
                      </div>

                      <div class="col-md-5" id="deleteBtn">
                        <button type="button" class="btn btn-block btn-success" (click)="showEditRolesModels()"> Confirm </button>
                    </div>

                  </div>

              </form>
          </div>

      </div>
  </div>
</div>

