import { Component, OnInit, ViewChild } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,HttpErrorResponse } from '@angular/common/http';
import { DataTableDirective } from 'angular-datatables';
import { Router, NavigationEnd } from '@angular/router';
import { DatePipe } from '@angular/common'

import { LocalStorageService } from 'ngx-webstorage';
import { NgxSpinnerService } from "ngx-spinner";

import { catchError, retry } from 'rxjs/operators';
import { throwError } from 'rxjs';

import { DtoService } from '../../service/dto.service';
import { FunctService } from '../../service/funct.service';
import { ToastrService } from 'ngx-toastr';

import { Subject } from 'rxjs';
declare var $: any;

@Component({
  selector: 'app-first-topup',
  templateUrl: './first-topup.component.html',
  styleUrls: ['./first-topup.component.css']
})
export class FirstTopupComponent implements OnInit {

  @ViewChild(DataTableDirective)
  dtElement: DataTableDirective;
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();

  alltoDate: string = ''; //add this
  alltodate : any;
  alldate: any;
  singleDate: string = '';
  commentmodel:string ='';
  name:any;
  phone_no:any;
  referral_code:any;
  allchangeDate: any;
  alltodayDate: any;
  alltodaytodate : any;
  alltodatechangeDate : any; //add this

  idIndex: any;
  title: string = '';
  phoneNo: string = '';

  comment: any;
  commentname:any;

  hardcode_edit :any;
  hardcode_add :any;
  hardcode_view: any;
  hardcode_delete:any;
  hardcode_view_bool : any;
  hardcode_add_bool : any;
  hardcode_edit_bool : any;
  hardcode_delete_bool : any;  

  itemsPerPage =  10;
  totalItems : any; 
  page = 1;

  constructor(private toastr: ToastrService,private storage: LocalStorageService, private spinner: NgxSpinnerService, 
    private dto: DtoService, private http: HttpClient, private funct: FunctService, private router: Router,private datepipe: DatePipe) {
      this.storage.store('isNotiSong', "");
    this.idIndex = 1;
    this.alldate = new Date();
    console.log("alldate >>"+this.alldate);
    this.alltodate = new Date();  
    console.log("alltodate >>"+this.alltodate);
    this.alltodayDate = this.datepipe.transform(this.alldate, 'MMM dd, yyyy');
    this.alltodaytodate = this.datepipe.transform(this.alltodate, 'MMM dd, yyyy');


    const filteredMenuCodes_view = [];
    const filteredMenuCodes_add = [];
    const filteredMenuCodes_edit = []; 
    this.dto.menuCodes = this.storage.retrieve('menucodes');
    this.hardcode_view = "FirstTopup_View";

    this.dto.menuCodes.forEach(menuCode => {
      if (menuCode === this.hardcode_view) {
        filteredMenuCodes_view.push(menuCode);
      }    
      
    });
    
    if(filteredMenuCodes_view.length != 0)
    {
        this.hardcode_view_bool = true;
    } 

   this.search();
   }

   ngOnInit(): void {

    this.router.routeReuseStrategy.shouldReuseRoute = function () {
      return false;
    };

    this.router.events.subscribe((evt) => {
      if (evt instanceof NavigationEnd)
      {        
        this.router.navigated = false;      
        window.scrollTo(0, 0);
      }
    });

    this.dtOptions = {
      responsive: true,     
      order:[],
      paging: false,
      info : true,
    }

    this.dtOptions.columnDefs = [
      { targets: [5], orderable: false }
    ];

    if (!this.storage.retrieve('loadFlag')) {
      this.storage.store('loadFlag', 'noLoad');
      setTimeout(function () {
        location.reload();
      }, 5);
    }
    else {
      this.storage.clear('loadFlag');
    }
  }



  onChangeSingle() {
    $(document).ready(function () {
      this.singleDate = $("#singleDate").val();
    });
  }
   
  onChangeAllToDate() {
    $(document).ready(function () {
      this.alltoDate = $("#alltodate").val();
    });
  }
  handleError(error: HttpErrorResponse){
    if(error.status == 423)
    {
      this.spinner.hide();
      $("#deleteData").modal("show");
    }
    if(error.status == 403)
    {
      this.spinner.hide();
      var id = 'tblComment' + this.idIndex;
      var table = $('#' + id).DataTable();
      this.toastr.error("Limited Access.", 'Invalid!', {
        timeOut: 3000,
        positionClass: 'toast-top-right',
        });
    }
    return throwError(error);
    }
    OkLogout()
    {
      window.location.href ="./ad-login";
    } 
  
    gty(page:any)
   {

        
    this.comment = [];
    var id = 'tblComment' + this.idIndex;
    var table = $('#' + id).DataTable();
    table.destroy();
    this.idIndex = this.idIndex + 1;
    this.spinner.show();      
    this.dto.token = this.storage.retrieve('token');
    this.singleDate = $("#singleDate").val();
    this.alltoDate = $("#alltodate").val();     
    if(this.name == undefined)
    {
      this.name ="";
    } 
    if(this.phone_no == undefined)
    {
      this.phone_no = "";
    } 
    if(this.referral_code == undefined)
    {
      this.referral_code = "";
    } 
    this.alltodatechangeDate = this.alltoDate || this.alltodaytodate;
    let headers = new HttpHeaders().set('Authorization', this.dto.token);
    let params = new HttpParams()
        .set('name', this.name)
        .set("phone_no", this.phone_no)
        .set('forDate', this.alltodatechangeDate)
        .set('ReferralCode', this.referral_code)
        .set('pageNumber', page.toString())
        .set("rowsOfPage", this.itemsPerPage.toString());  
    this.http.get(this.funct.ipaddress + 'transaction/getFirstTopUp', { params, headers })
    .pipe(
      catchError(this.handleError.bind(this))
    )
    .subscribe( 
      result => {
        this.dto.Response = result.results;       
        this.comment = this.dto.Response;
        this.totalItems = result.totalRows;
        this.dtTrigger.next();
        this.spinner.hide();
        //return;
      }
    
    );

}

  search() {
        this.comment = [];
        var id = 'tblComment' + this.idIndex;
        var table = $('#' + id).DataTable();
        table.destroy();
        this.idIndex = this.idIndex + 1;
        this.spinner.show();      
        this.dto.token = this.storage.retrieve('token');
        this.singleDate = $("#singleDate").val();
        this.alltoDate = $("#alltodate").val();     
        if(this.name == undefined)
        {
           this.name ="";
        } 
        if(this.phone_no == undefined)
        {
          this.phone_no = "";
        } 
        if(this.referral_code == undefined)
        {
          this.referral_code = "";
        } 
        this.alltodatechangeDate = this.alltoDate || this.alltodaytodate;
        let headers = new HttpHeaders().set('Authorization', this.dto.token);
        let params = new HttpParams()
            .set('name', this.name)
            .set("phone_no", this.phone_no)
            .set('forDate', this.alltodatechangeDate)
            .set('ReferralCode', this.referral_code)
            .set('pageNumber', "1")
            .set("rowsOfPage", "10");
        this.http.get(this.funct.ipaddress + 'transaction/getFirstTopUp', { params, headers })
        .pipe(
          catchError(this.handleError.bind(this))
        )
        .subscribe( 
          result => {
            this.dto.Response = result.results;       
            this.comment = this.dto.Response;
            this.totalItems = result.totalRows;
            this.dtTrigger.next();
            this.spinner.hide();
           // return;
          }
         
        );
    }




}
