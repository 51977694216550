
import { Component, OnInit, ViewChild } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,HttpErrorResponse } from '@angular/common/http';
import { DataTableDirective } from 'angular-datatables';
import { DatePipe } from '@angular/common'
import { Router, NavigationEnd } from '@angular/router';
import { ToastrService } from 'ngx-toastr';

import { LocalStorageService } from 'ngx-webstorage';
import { NgxSpinnerService } from "ngx-spinner";

import { catchError, retry } from 'rxjs/operators';
import { throwError } from 'rxjs';

import { DtoService } from '../../service/dto.service';
import { FunctService } from '../../service/funct.service';

import { Subject } from 'rxjs';
declare var $: any;

@Component({
  selector: 'app-lockgscapis',
  templateUrl: './lockgscapis.component.html',
  styleUrls: ['./lockgscapis.component.css']
})
export class LockgscapisComponent implements OnInit {
  @ViewChild(DataTableDirective)
  dtElement: DataTableDirective;
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();

  status: string = '';
  adsList: any;
  idIndex: any;
  token: any;
  singleDate : any;
  name : any;
  allchangeDate : any;
  alldate: any;
  alltodayDate : any;
  providerId :any;
  gameproviderList : any;
  deleteId:any;
  deletTranDto:any;

  hardcode_edit :any;
  hardcode_add :any;
  hardcode_view: any;
  hardcode_delete:any;
  hardcode_view_bool : any;
  hardcode_add_bool : any;
  hardcode_edit_bool : any;
  hardcode_delete_bool:any;
  hardcode_double_bool:any;

  constructor(private storage: LocalStorageService, private spinner: NgxSpinnerService, private http: HttpClient, private dto: DtoService,
    private router: Router, private funct: FunctService, private toastr: ToastrService,private datepipe: DatePipe) {
    this.idIndex = 1;
    this.storage.store('isNotiSong', "");
    this.hardcode_view = "GameAPIsList_View";
    this.hardcode_edit = "GameAPIsList_Edit";
    this.hardcode_add = "GameAPIsList_Add";
    this.hardcode_delete="GameAPIsList_Del"; 
    
    this.dto.menuCodes = this.storage.retrieve('menucodes');

    const filteredMenuCodes_view = [];
    const filteredMenuCodeds_add =[];
    const filteredMenuCodeds_edit =[];
    const filteredMenuCodes_delete =[];
    this.dto.menuCodes.forEach(menuCode => {
      if (menuCode === this.hardcode_view) {
        filteredMenuCodes_view.push(menuCode);
      }
      if (menuCode === this.hardcode_add) {
        filteredMenuCodeds_add.push(menuCode);
      }
      if (menuCode === this.hardcode_edit) {
        filteredMenuCodeds_edit.push(menuCode);
      }
      if (menuCode === this.hardcode_delete) {
        filteredMenuCodes_delete.push(menuCode);
      }
      
    });
    if(filteredMenuCodes_view.length != 0)
    {
          this.hardcode_view_bool = true;
    }
    if(filteredMenuCodeds_add.length != 0)
    {
          this.hardcode_add_bool = true;
    }
    if(filteredMenuCodeds_edit.length != 0)
    {
          this.hardcode_edit_bool = true;
    }
    if(filteredMenuCodes_delete.length != 0)
    {
          this.hardcode_delete_bool = true;
    }


    this.alldate = new Date();
    console.log("this.alldate>> " + this.alldate);
    this.alltodayDate = this.datepipe.transform(this.alldate, 'MMM dd, yyyy');
    console.log("this.alltodayDate>> " + this.alltodayDate);
    this.getAllProvider();
    this.search();
  }

  ngOnInit(): void {
    this.router.routeReuseStrategy.shouldReuseRoute = function () {
      return false;
    };

    this.router.events.subscribe((evt) => {
      if (evt instanceof NavigationEnd) {        
        this.router.navigated = false;        
        window.scrollTo(0, 0);
      }
    });

    this.deletTranDto = {  
      tranId: 0
    };

    this.dtOptions = {
      responsive: true,
      order: []
    }

    this.dtOptions.columnDefs = [
      { targets: [4], orderable: false }
    ];

    if (!this.storage.retrieve('loadFlag')) {
      this.storage.store('loadFlag', 'noLoad');
      setTimeout(function () {
        location.reload();
      }, 5);
    }
    else {
      this.storage.clear('loadFlag');
    }
  }

  onChangeSingle() {
    $(document).ready(function () {
      this.singleDate = $("#singleDate").val();
    });
  }

  getAllProvider()
  {
   this.dto.token = this.storage.retrieve('token');
   let headers = new HttpHeaders();
   headers = headers.set('Authorization',  this.dto.token);
   this.http.get(this.funct.ipaddress + 'gameProvider/getGameProviderList', {headers: headers })
   .pipe(
     catchError(this.handleError.bind(this))
    )
   .subscribe(
     result => {
       this.dto.Response = {};
       this.dto.Response = result;
       this.gameproviderList = this.dto.Response;
       
     });
  }

  delete(deleteId)
  {
    $("#deleteData").modal("show");    
    this.deleteId = deleteId;     
  }  

  handleError(error: HttpErrorResponse){
    if(error.status == 423)
    {
      this.spinner.hide();
      $("#deleteData").modal("show");
    }
    if(error.status == 403)
    {
      this.spinner.hide();
      var id = 'tblads' + this.idIndex;
     var table = $('#' + id).DataTable();
      this.toastr.error("Limited Access.", 'Invalid!', {
        timeOut: 3000,
        positionClass: 'toast-top-right',
        });
    }
    return throwError(error);
    }
    OkLogout()
    {
      window.location.href ="./ad-login";
    } 

    flagProvider()
  {
      this.providerId = $("#providerId").val();
  }

  deletTran()//id, tranType
  {
    this.token = this.storage.retrieve('token');
    let headers = new HttpHeaders();
    headers = headers.set('Authorization', this.token);
    this.deletTranDto.tranId = this.deleteId;
    this.http.post(this.funct.ipaddress + 'topupWithdrawalAdjust/DeleteGSPApis', this.deletTranDto,{ headers: headers })
    .pipe(
      catchError(this.handleError.bind(this))
     )
    .subscribe(
      result => {
        this.dto.Response = {};
        this.dto.Response = result;
        if(this.dto.Response.status == 'Success')
        {       
          this.router.navigate(['/lockgscapis']).then(() => {
          this.toastr.success(this.dto.Response.message, 'Success!', {
            timeOut: 3000,
            positionClass: 'toast -top-right'
          });
        })
         }
      }
    );
  }
  //

  closeTran()
  {
      $('#deleteData').modal("hide");
      this.router.navigate(['/lockgscapis']).then(() => {
      this.toastr.error("Manage GSPapis ", 'Cancel!', {
        timeOut: 3000,
        positionClass: 'toast-top-right',
      });
    });
  }

  search() {
    this.adsList = [];
    var id = 'tblads' + this.idIndex;
    var table = $('#' + id).DataTable();
    table.destroy();
    this.idIndex = this.idIndex + 1;
    this.spinner.show();
    this.dto.token = this.storage.retrieve('token');
    
    this.singleDate = $("#singleDate").val();
    let headers = new HttpHeaders();
    headers = headers.set('Authorization', this.dto.token);
    let params = new HttpParams();
    
    if(this.providerId == undefined || this.providerId =="" || this.providerId == null)
    {
      this.providerId = "";
    }
    else
    {
      this.providerId = this.providerId;
    }
    params = params.set("gameProviderCode",this.providerId);
    this.http.get(this.funct.ipaddress + 'ads/lockgscapisByparams', { params: params, headers: headers })
    .pipe(
      catchError(this.handleError.bind(this))
     )
    .subscribe(
      result => {
        this.dto.Response = {};
        this.dto.Response = result;
        this.adsList = this.dto.Response;
        this.dtTrigger.next();
        this.spinner.hide();
      }
    );
  }
}

