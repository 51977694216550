
import { HttpClient, HttpHeaders, HttpParams ,HttpErrorResponse} from '@angular/common/http';
import { Router, NavigationEnd } from '@angular/router';
import { DataTableDirective } from 'angular-datatables';
import { ToastrService } from 'ngx-toastr';

import { LocalStorageService } from 'ngx-webstorage';
import { NgxSpinnerService } from "ngx-spinner";

import { catchError, retry } from 'rxjs/operators';
import { throwError } from 'rxjs';

import { DtoService } from '../../service/dto.service';
import { FunctService } from '../../service/funct.service';

import { Subject } from 'rxjs';
import { Component, OnInit, ChangeDetectorRef, ViewChild } from '@angular/core';
import { AttributeMarker } from '@angular/compiler/src/core';

import { IMultiSelectOption  } from 'ngx-bootstrap-multiselect';
import {IMultiSelectSettings } from 'ngx-bootstrap-multiselect';
import {IMultiSelectTexts} from 'ngx-bootstrap-multiselect';


declare var $: any;

@Component({
  selector: 'app-admin-logs',
  templateUrl: './admin-logs.component.html',
  styleUrls: ['./admin-logs.component.css']
})
export class AdminLogsComponent implements OnInit 
{

  @ViewChild(DataTableDirective)
  dtElement: DataTableDirective;
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();

  phoneNo: string = '';
  name: string = '';
  referralCode: string = '';
  status: string = '';
  adminList: any;
  filteredAdminList :any;
  idIndex: any;
  hardcode_edit :any;
  hardcode_add :any;
  hardcode_view: any;
  hardcode_delete_bool : any;
  hardcode_add_bool : any;
  hardcode_edit_bool : any;
  hardcode_view_bool : any;
  selectedRange: any;
  fromdate: string;
  todate: string;

  startDate: string;
  defaultTodayDate: string;
  isDateRange : Date;
  endDate: string;
  selectedPreset: string;
  menulist:any;
  path:string ='';
  admin_nameList:any;
  menu:any;
  admin:any;
  admin_origin:any;
  menu_origin:any;
  subCategory:any;
  menuCategories:any;
  admin_list:any;
  alldate:any;
  alltodate:any;
  alltoDate:any;
  singleDate:any;
  mySettings: IMultiSelectSettings;
  myTexts: IMultiSelectTexts;
  //showDropdown: boolean = false;    
  filteredMenuCategories: any = {};
    
  dropdownMouseDown: boolean = false;
  hideTimeout: any;
  activeagentList = [];
  myOptions: IMultiSelectOption[];
  optionsModel: number[];
  dropdownMouseDown_admin: boolean = false;
  showDropdown_admin: boolean = false;    
  subCategoryInput:any;
  filteredAdmins:any;
  filteredAdminCategories: any = {};
  adminName:any = '';
  dropdownVisible =false;
  dropdownVisible1 = false;
  menuname:any ="";
  filteredMenuMap: any;
  allMenuList: any;
  menuMap = new Map<string, Map<string, Set<string>>>();

  constructor(private storage: LocalStorageService, private spinner: NgxSpinnerService,private toastr: ToastrService, private http: HttpClient, private dto: DtoService, private router: Router, private funct: FunctService,private cdr: ChangeDetectorRef) 
  {
    this.storage.store('isNotiSong', "");
    this.idIndex = 1;
    this.status = 'ACTIVE';
    this.subCategory ="";
    this.selectedPreset="today";
    this.hardcode_view = "Admin_View";
    this.hardcode_edit = "Admin_Edit";
    this.hardcode_add = "Admin_Add";
    this.dto.menuCodes = this.storage.retrieve('menucodes');
    this.subCategoryInput ="All";
    this.admin="All";
//amk-hnin
    this.getAllMenuPath();
    this.getAllAdmins();


    // this.GetMenuName();
    // this.GetAdminName();
    // this.getAdminName(); 
  }

  ngOnInit(): void
   {  
    this.subCategoryInput ="All";
    this.admin ="All";
    //amk
    this.mySettings = {
      enableSearch: true,
      checkedStyle: 'checkboxes',
      buttonClasses: 'btn btn-default btn-block',
      dynamicTitleMaxItems: 3,
      displayAllSelectedText: true,
      showCheckAll : true,
      showUncheckAll : true,
      autoUnselect : true,
      pullRight : false,
      closeOnSelect : false
    };
    // Text configuration
    this.myTexts = {
      checkAll: 'Select all',
      uncheckAll: 'Unselect all',
      checked: 'item selected',
      checkedPlural: 'items selected',
      searchPlaceholder: 'Find',
      searchEmptyResult: 'Nothing found...',
      searchNoRenderText: 'Type in search box to see results...',
      defaultTitle: 'Select',
      allSelected: 'All selected',
    };
    //
    this.onDateRangeChange() ;    
    console.log("ngOnInit");
    this.router.routeReuseStrategy.shouldReuseRoute = function () {
      return false;
    };

    this.router.events.subscribe((evt) => {
      if (evt instanceof NavigationEnd) {    
        this.router.navigated = false;
        window.scrollTo(0, 0);
      }
    });

    this.dtOptions = {
      responsive: true,
      stateSave : true,
      order: []
    }

    this.dtOptions.columnDefs = [
      { targets: [6], orderable: false }
    ];

    if (!this.storage.retrieve('loadFlag')) {
      this.storage.store('loadFlag', 'noLoad');
      setTimeout(function () {
        location.reload();
      }, 5);
    }
    else {
      this.storage.clear('loadFlag');
    }
    this.search();   
  }
  ngAfterViewInit()
   {
  }

  handleError(error: HttpErrorResponse)
  {
    if(error.status == 423)
    {
      this.spinner.hide();
      $("#deleteData").modal("show");
    }
    if(error.status == 403)
    {
      this.spinner.hide();
      var id = 'tblAdmin' + this.idIndex;
      var table = $('#' + id).DataTable();
      this.toastr.error("Limited Access.", 'Invalid!', {
        timeOut: 3000,
        positionClass: 'toast-top-right',
        });
    }
    return throwError(error);
    }
    OkLogout()
    {
      window.location.href ="./ad-login";
    } 
 
  onDateRangeChange() 
  {
    const today = new Date();    
    const formatDate = (date) => {    
      const options = { month: 'long', day: 'numeric', year: 'numeric' };
      return date.toLocaleDateString('en-US', options); 
    };
  
    this.defaultTodayDate = formatDate(today);
  
    switch (this.selectedPreset) {
      case 'today':
        this.startDate = this.defaultTodayDate;
        this.endDate = this.startDate;
        break;
  
      case 'yesterday':
        const yesterday = new Date(today);
        yesterday.setDate(today.getDate() - 1);
        this.startDate = formatDate(yesterday);
        this.endDate = this.startDate;
      
        break;
  
      case 'last7days':
        const last7days = new Date(today);
        last7days.setDate(today.getDate() - 6);
        this.startDate = formatDate(last7days);
        this.endDate = this.defaultTodayDate;
        break;
  
      case 'last30days':
        const last30days = new Date(today);
        last30days.setDate(today.getDate() - 29);
        this.startDate = formatDate(last30days);
        this.endDate = this.defaultTodayDate;
        break;
  
      case 'thisweek':
        const startOfWeek = new Date(today);
        startOfWeek.setDate(today.getDate() - today.getDay() + 1); 
        const endOfWeek = new Date(startOfWeek);
        endOfWeek.setDate(startOfWeek.getDate() + 6);
        this.startDate = formatDate(startOfWeek);
        this.endDate = formatDate(endOfWeek);
        break;
  
      case 'lastweek':
        const startOfLastWeek = new Date(today);
        startOfLastWeek.setDate(today.getDate() - today.getDay() - 6); 
        const endOfLastWeek = new Date(startOfLastWeek);
        endOfLastWeek.setDate(startOfLastWeek.getDate() + 6);
        this.startDate = formatDate(startOfLastWeek);
        this.endDate = formatDate(endOfLastWeek);
        break;
  
      case 'thismonth':
        const startOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);
        const endOfMonth = new Date(today.getFullYear(), today.getMonth() + 1, 0);
        this.startDate = formatDate(startOfMonth);
        this.endDate = formatDate(endOfMonth);
        break;
  
      case 'lastmonth':
        const startOfLastMonth = new Date(today.getFullYear(), today.getMonth() - 1, 1);
        const endOfLastMonth = new Date(today.getFullYear(), today.getMonth(), 0);
        this.startDate = formatDate(startOfLastMonth);
        this.endDate = formatDate(endOfLastMonth);
        break;
    }
  
    $("#startDate").val(this.startDate);
    $("#endDate").val(this.endDate);
  }
  
  onChangeSingle() {
    this.startDate = $("#startDate").val();
  }
  
  onChangeAllToDate() {
    this.endDate = $("#endDate").val();
  }  

GetMenuName()
{   
    this.dto.token = this.storage.retrieve('token');
    let headers = new HttpHeaders();
    headers = headers.set('Authorization',  this.dto.token);
    let params = new HttpParams();
    params = params.set('path', this.path);
    this.http.get(this.funct.ipaddress + 'admin/GetAllMenuPath', { params: params, headers: headers })
    .pipe(
      catchError(this.handleError.bind(this))
     )
    .subscribe( 
      result => {
        this.dto.Response = {};
        this.dto.Response = result;
        this.menulist = this.dto.Response;  
        this.menulist.push("Users Management->All");  
        this.menuCategories = this.categorizeMenuList(this.menulist);  
        this.filteredMenuCategories = this.menuCategories;      
        //this.menulist = this.dto.Response.filter(item => item.startsWith('Tools->'));
      });     
    
} 

GetAdminName() 
{
  this.dto.token = this.storage.retrieve('token');
  let headers = new HttpHeaders();
  console.log(this.dto.token);

  headers = headers.set('Authorization', this.dto.token).set("Roles","SUPER_ADMIN");
  this.admin_list=[];
 
    this.http.get(this.funct.ipaddress + 'admin/GetAllAdminName', { headers: headers }).subscribe(
      result => {
      this.dto.Response = {};       
      this.dto.Response = result;     
      this.admin_list = this.dto.Response;  
      this.admin_list.push({ name: "All", id: 0 }); 
      this.filteredAdmins = this.admin_list;  
      this.dtTrigger.next();      
      this.spinner.hide();
    
    }
  );
}




//remove the same menu name
categorizeMenuList(menuPaths: string[]): any 
{
  let categories = {};

  menuPaths.forEach(path => {
    let [mainCategory, subCategory] = path.split('->');
    if (!subCategory) {
      [subCategory] = mainCategory.split('=>');
    }

    if (!categories[mainCategory]) {
      categories[mainCategory] = new Set();  
    }
    categories[mainCategory].add(subCategory);  
  });


  for (let key in categories) {
    if (categories.hasOwnProperty(key)) {
      categories[key] = Array.from(categories[key]);
    }
  }

  return categories;
}

processMenuList(menuPaths: string[]): string[] {
  let categories = new Set<string>();
  
  menuPaths.forEach(path => {
    let [mainCategory, subCategory] = path.split('->');
    if (!subCategory) {
      [subCategory] = mainCategory.split('=>');
    }
    categories.add(mainCategory);
  });

  return Array.from(categories);
}
//amk
// filterOptions() 
// {
//   const query = this.subCategory.toLowerCase();
//   this.filteredMenuCategories = {};

//   for (let key in this.menuCategories) {
//     if (this.menuCategories.hasOwnProperty(key)) {
//       let filteredSubCategories = this.menuCategories[key].filter((subCategory: string) =>
//         subCategory.toLowerCase().includes(query)
//       );
//       if (filteredSubCategories.length > 0) {
//         this.filteredMenuCategories[key] = filteredSubCategories;
//       }
//     }
//   }
//   this.showDropdown = true;
// }

// toggleDropdown(show: boolean)
//  {
//   this.showDropdown = show;
// }


// hideDropdown_old() {
//   setTimeout(() => this.showDropdown = false, 200);
//   console.log("subCatelogy" + this.subCategoryInput + this.subCategory);
//   this.subCategoryInput = this.subCategory;
 
  
// }


// onBlur() {
//   setTimeout(() => {
//     this.showDropdown = false;
//   }, 200);
// }


   
  search() 
  {
    this.admin_nameList = [];
    var id = 'tblAdmin' + this.idIndex;
    var table = $('#' + id).DataTable();
    table.destroy();
    this.idIndex = this.idIndex + 1;
    this.spinner.show();
    this.dto.token = this.storage.retrieve('token');
    let headers = new HttpHeaders();
    console.log(this.dto.token);
    headers = headers.set('Authorization', this.dto.token);
    let params = new HttpParams();    
    // if(this.subCategory == "Users Management->All")  
    // {
    //     this.subCategory ="";
    // }
    // if(this.subCategoryInput =="All")
    // {
    //    this.subCategory ="";
    // } 
    // else 
    // {
    //   this.subCategory = this.subCategoryInput;
    // }
    // if(this.admin =="All")
    // {
    //    this.admin_origin = "";
    // }
    // else{
    //   this.admin_origin = this.admin;
    // }
    if(this.adminName == "All")
    {
      this.admin_origin ="";
    }
    else{
       this.admin_origin = this.adminName;
    }
    if(this.menuname == "All")
    {
      this.menu_origin ="";
    }
    else 
    {
      this.menu_origin = this.menuname;
    }
   
    
    params = params.set('phoneNo', "").set('name', this.admin_origin).set('fromDate', this.startDate).set('toDate', this.endDate).set('fromip', "").set('menupath', this.menu_origin);
    this.http.get(this.funct.ipaddress + 'admin/AdminOperaRecords', { params: params, headers: headers }).
    pipe(
      catchError(this.handleError.bind(this))
     )
    .subscribe(
      result => {
        this.dto.Response = {};       
        this.dto.Response = result;        
        this.admin_nameList = this.dto.Response;
        this.dtTrigger.next();
        this.spinner.hide();      
      }
    );

  }

  filterMenuOptions() {
    const searchTerm = this.subCategory.toLowerCase();
    this.filteredMenuCategories = {};

    Object.keys(this.menuCategories).forEach(key => 
      {
      const filteredSubCategories = this.menuCategories[key].filter(
        subCategory =>
        subCategory.toLowerCase().includes(searchTerm)
      );
      if (filteredSubCategories.length) 
      {
        this.filteredMenuCategories[key] = filteredSubCategories;
      }
    });

          //amk
      const subCategory = searchTerm.split('->')[1] || searchTerm; 
       this.subCategory = searchTerm; 

  }

  filterMenuOptions_1() {
    const searchTerm = this.subCategoryInput.toLowerCase();
    this.filteredMenuCategories = {};

    Object.keys(this.menuCategories).forEach(key => 
      {
      const filteredSubCategories = this.menuCategories[key].filter(
        subCategoryInput =>
        subCategoryInput.toLowerCase().includes(searchTerm)
      );
      if (filteredSubCategories.length) 
      {
        this.filteredMenuCategories[key] = filteredSubCategories;
      }
    });

          //amk
          const subCategoryInput = searchTerm.split('->')[1] || searchTerm; 
          this.subCategoryInput = searchTerm;
      
  }

  getSelectedSubCategory() {
    const splitValue = this.subCategory.split('->');
    return splitValue.length > 1 ? splitValue[1] : this.subCategory;
  }


  // initializeDropdown() {
  //   this.filteredMenuCategories = { ...this.menuCategories };
  //   this.showDropdown = true;
  // }


  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }



  getLastPart(actionOrMenu: string): string {
    const parts = actionOrMenu.split('->');
    return parts[parts.length - 1];
  }


  // selectSubCategory(category: string, subCategory: string,event: MouseEvent) 
  // {    
  //   event.preventDefault();
  //   this.subCategoryInput = `${category}->${subCategory}`;
  //   this.subCategoryInput = `${subCategory}`;   
  //   this.showDropdown = false;
  //   this.dropdownMouseDown = true;
  //   (document.getElementById('menuName') as HTMLInputElement).blur();
  //   clearTimeout(this.hideTimeout);
    
  // }


    filterAdmins() {
    const query = this.admin.toLowerCase();
    this.filteredAdmins = this.admin_list.filter(admin =>admin.name.toLowerCase().includes(query));
  }

  // selectAdmin(admin: { name: string }) {
  //   this.admin = admin.name;
  //   this.showDropdown = false;
  // }

  selectAdmin(adminName: string) 
  {
    this.admin = adminName; 
    this.showDropdown_admin = false; 
    this.dropdownMouseDown = true;
    (document.getElementById('adminName') as HTMLInputElement).blur();
    clearTimeout(this.hideTimeout);
  }

  // hideDropdown_Admin() 
  // {    
  //   setTimeout(() => this.showDropdown = false, 200);
  // }

  //‌admin name
  filterMenuOptions_AdminName() 
  {
    const searchTermAdmin = this.admin.toLowerCase();
    this.filteredAdminCategories = {};

    Object.keys(this.menuCategories).forEach(key => 
      {
      const filteredAdminCategories = this.menuCategories[key].filter(
        admin =>
        admin.toLowerCase().includes(searchTermAdmin)
      );
      if (filteredAdminCategories.length) 
      {
        this.filteredAdminCategories[key] = filteredAdminCategories;
      }
    });
    const admin = searchTermAdmin.split('->')[1] || searchTermAdmin; 
    this.admin = searchTermAdmin;
          
  }
  // initializeDropdown_Admin() {
  //   this.showDropdown = true;
  // }


  onDropdownMouseDown(event: MouseEvent) {
    this.dropdownMouseDown = true;
    event.preventDefault();
    setTimeout(() => this.dropdownMouseDown = false, 100);
  }

  // keepDropdownVisible() 
  // {
  //   clearTimeout(this.hideTimeout);
  //   this.showDropdown = true; 
  // }
  
  // hideDropdownWithDelay() {
  //   this.hideTimeout = setTimeout(() => 
  //   {
  //     if (!this.dropdownMouseDown) {
  //       this.showDropdown = false;
  //     }
  //   }, 300);
   
  // }

// onInputBlur() {
//   if (!this.dropdownMouseDown) {
//     this.hideDropdown();
//   }
// }
// hideDropdown() 
// {

//   if (!this.dropdownMouseDown) 
//   {
//     this.showDropdown = false;
//   }

// }

getAdminName() {
  this.dto.token = this.storage.retrieve('token');
  let headers = new HttpHeaders();
  this.activeagentList = [];
  headers = headers.set('Authorization', this.dto.token).set("Roles", "SUPER_ADMIN");
  this.http.get(this.funct.ipaddress + 'admin/GetAllAdminName', { headers: headers }).subscribe(
    result => {
      this.dto.Response = result;
      this.activeagentList = this.dto.Response;
      this.myOptions = this.activeagentList.map(agent => ({ id: agent.id, name: agent.name }));
      this.myOptions.push({ id: 0, name: 'No Code' });
      this.dtTrigger.next();      
      this.spinner.hide();
    }
  );
}


onChange() {
  this.getAdminNames();
  console.log("in onchange "+ this.optionsModel);
}

getAdminNames() {
  const idToNameMap = new Map(this.myOptions.map(option => [option.id, option.name]));
  this.admin = this.optionsModel.map(id => idToNameMap.get(id) || 'Unknown');
}

filterMenuOptions_admin() {
  const searchTerm = this.admin.toLowerCase();
  this.filteredAdmins = this.admin_list.filter(admin =>
    admin.name.toLowerCase().includes(searchTerm)
  );
}

initializeDropdown_admin() {
  this.showDropdown_admin = true;
}
onInputBlur_admin() {
  if (!this.dropdownMouseDown_admin) {
    this.hideDropdown_admin();
  }
}

onDropdownMouseDown_admin(event: MouseEvent) {
  this.dropdownMouseDown_admin = true;
  event.preventDefault();
  setTimeout(() => this.dropdownMouseDown_admin = false, 100);
}


keepDropdownVisible_admin() 
{
  clearTimeout(this.hideTimeout);
  this.showDropdown_admin = true; 
}

hideDropdownWithDelay_admin()
 {
  this.hideTimeout = setTimeout(() => 
  {
    if (!this.dropdownMouseDown_admin) {
      this.showDropdown_admin = false;
    }
  }, 300);
 
}
hideDropdown_admin() 
{
  if (!this.dropdownMouseDown_admin) 
  {
    this.showDropdown_admin = false;
  }

}

// ‌amk-hnin

findOption(optionToFind: string) {
  this.filteredMenuMap = new Map();
  this.menuMap.forEach((secondLevelMap, firstLevel) => {
    const filteredSecondLevelMap = new Map();
    secondLevelMap.forEach((thirdLevelSet, secondLevel) => {  
      const filteredThirdLevelSet = new Set(
        Array.from(thirdLevelSet).filter(thirdLevel => thirdLevel.toLowerCase().includes(optionToFind.toLowerCase()))
      );
         if (secondLevel.toLowerCase().includes(optionToFind.toLowerCase()) || filteredThirdLevelSet.size > 0) {
        filteredSecondLevelMap.set(secondLevel, filteredThirdLevelSet.size > 0 ? filteredThirdLevelSet : new Set(thirdLevelSet));
      }
    });
    if (filteredSecondLevelMap.size > 0) {
      this.filteredMenuMap.set(firstLevel, filteredSecondLevelMap);
    }
  });
}

hideDropdown1()
{
  this.dropdownVisible1 = false;
}
showDropdown()
{
  this.dropdownVisible = true;
}

getAllMenuPath()
  {
    this.spinner.show();
    this.dto.token = this.storage.retrieve('token');
    let headers = new HttpHeaders();
    headers = headers.set('Authorization',  this.dto.token );
    this.http.get(this.funct.ipaddress + 'admin/GetAllMenuPath', { headers: headers }).subscribe(
      result => {
        this.dto.Response = {};
        this.dto.Response = result;
        this.allMenuList = this.dto.Response;
        this.allMenuList.forEach(item => {
          const levels = item.split('->');
          const [firstLevel, secondLevel, thirdLevel] = levels;
          if (!this.menuMap.has(firstLevel)) {
            this.menuMap.set(firstLevel, new Map());
          }
          if (secondLevel) {
            const secondLevelMap = this.menuMap.get(firstLevel);
            if (!secondLevelMap.has(secondLevel)) {
              secondLevelMap.set(secondLevel, new Set());
            }
            if (thirdLevel) {
              secondLevelMap.get(secondLevel).add(thirdLevel);
            }
          }
        });
        this.filteredMenuMap = this.menuMap;
       // this.filteredMenuMap.set('All',"");
        this.spinner.hide();
          }
        );
  }

  getAllAdmins()
  {
    this.dto.token = this.storage.retrieve('token');
    let headers = new HttpHeaders();
    headers = headers.set('Authorization',  this.dto.token );
    this.http.get(this.funct.ipaddress + 'admin/GetAllAdmin', { headers: headers }).subscribe(
      result => {
        this.dto.Response = {};
        this.dto.Response = result;
        this.adminList = this.dto.Response;
        this.storage.store("adminList",this.adminList);
        this.filteredAdminList = this.adminList;
      }
    );
  }
  showDropdown1()
  {
    this.dropdownVisible1 = true;
  }
  hideDropdown()
  {
    this.dropdownVisible = false;
  }
  filterOptions() {
    const term = this.adminName.toLowerCase();
    if (!term) {
      this.filteredAdminList = this.adminList;
    } else {
      this.filteredAdminList = this.adminList.filter(admin =>
        admin.name.toLowerCase().includes(term)
      );
    }
  }

  selectOption(option: string) {
    this.dropdownVisible = false;
    if(option == '')
    {
      this.adminName = 'All';
    }
    else
    this.adminName = option;
  }

  selectOption1(option: string) {
    this.dropdownVisible1 = false;
    if(option == '')
    {
      this.menuname = 'All';
    }
    else
    this.menuname = option;
  }
}