<div class="wrapper">
    <!-- Navbar -->
    <app-header-sidebar></app-header-sidebar>

    <!-- here here here -->
    <!-- Content Wrapper. Contains page content -->
    <div class="content-wrapper">
        <!-- Content Header (Page header) -->
        <div class="content-header">
            <div class="container-fluid">
            </div><!-- /.container-fluid -->
        </div>
        <!-- /.content-header -->

        <!-- Main content -->
        <section class="content">
            <div class="container-fluid">
                <form>
                    <div class="row">
                        <div class="col-md-12">
                            <div class="card">
                                <div class="card-header">
                                    <h3 class="card-title" style="font-weight: bold;"> Commission Calculation Detail </h3>
                                </div>
                                <!-- /.card-header -->
                                <div class="card-body">
                                    <form>

                                    <div class="row">
                                        <label class="col-md-2"> From Date </label>
                                        <div class="form-group col-md-2">
                                            <input  id="fromDate" readonly class="form-control form-control-sm"
                                                placeholder="Select Date" [ngxDatePicker]="fromdateInstanceSingle"
                                                 [value]="comissionCalculationDTO.form_date"
                                                [(ngModel)]="comissionCalculationDTO.from_date" [ngModelOptions]="{standalone: true}">
                                            <ngx-date-picker #fromdateInstanceSingle></ngx-date-picker>
                                        </div>
                                    </div>

                                    <div class="row">
                                        <label class="col-md-2"> To Date </label>
                                        <div class="form-group col-md-2">
                                            <input id="toDate" readonly class="form-control form-control-sm"
                                            placeholder="Select Date" [ngxDatePicker]="todateInstanceSingle" [value]="comissionCalculationDTO.to_date"
                                            [(ngModel)]="comissionCalculationDTO.to_date" [ngModelOptions]="{standalone: true}">
                                        <ngx-date-picker #todateInstanceSingle></ngx-date-picker>
                                        </div>
                                    </div>

                                          <div class="row">
                                            <label class="col-md-2"> For </label>
                                            <div class="form-group col-md-3 radio">
                                                <div class="form-group clearfix">
                                                    <div class="icheck-success d-inline">
                                                        <input type="radio" name="r3"  id="radioSuccess1"
                                                            value="4" [(ngModel)]="comissionCalculationDTO.foruser"
                                                            [ngModelOptions]="{standalone: true}">
                                                        <label for="radioSuccess1">Agent &nbsp;&nbsp;
                                                        </label>
                                                    </div>
                                                    <div class="icheck-success d-inline">
                                                        <input type="radio" name="r3" id="radioSuccess2"
                                                            value="5" [(ngModel)]="comissionCalculationDTO.foruser"
                                                            [ngModelOptions]="{standalone: true}">
                                                        <label for="radioSuccess2">Sale Promoter
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>


                                        <div class="row" style="margin-top: 1%;">
                                            <div class="col-md-2 col-sm-6">
                                                <button type="button" class="btn btn-block btn-success"
                                                    (click)="goCommissionCalculationSave()"> Save </button>&nbsp;
                                            </div>
                                            <div class="col-md-2 col-sm-6">
                                                <button type="button" class="btn btn-block btn-danger"
                                                [routerLink] = "['/commission']"> Cancel </button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                                <!-- /.card-body -->
                            </div>
                        </div>

                    </div>
                </form>
                <!-- /.row -->

                <!-- /.row -->
            </div>
            <!--/. container-fluid -->
        </section>
        <!-- /.content -->
    </div>

    <aside class="control-sidebar control-sidebar-dark">
        <!-- Control sidebar content goes here -->
    </aside>

    <app-footer></app-footer>

    <ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#fff" type="square-jelly-box">
        <p style="font-size: 16px; color: white">Loading...</p>
    </ngx-spinner>

</div>