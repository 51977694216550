import { Component, OnInit, ViewChild } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams  ,HttpErrorResponse} from '@angular/common/http';
import { DataTableDirective } from 'angular-datatables';
import { Router, NavigationEnd } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { DatePipe } from '@angular/common'

import { LocalStorageService } from 'ngx-webstorage';
import { NgxSpinnerService } from "ngx-spinner";
import { catchError, retry } from 'rxjs/operators';

import { DtoService } from '../../service/dto.service';
import { FunctService } from '../../service/funct.service';

import * as XLSX from 'xlsx'; 
import * as fs from 'file-saver';
import { Workbook } from 'exceljs';
import { jsPDF } from "jspdf";
import 'jspdf-autotable'
import autoTable from 'jspdf-autotable'

import { Subject } from 'rxjs';
declare var $: any;


@Component({
  selector: 'app-threed-bet-amount-limitation',
  templateUrl: './threed-bet-amount-limitation.component.html',
  styleUrls: ['./threed-bet-amount-limitation.component.css']
})
export class ThreedBetAmountLimitationComponent implements OnInit {

  @ViewChild(DataTableDirective)
  dtElement: DataTableDirective;
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();

  number: string = '';
  amount: string = '';
  fromDate: string = '';
  toDate: string = '';
  threedbetamountlimitList: any;
  idIndex: any;

  todate: any;
  date : any;
  fromtodaydate : any;
  totodaydate: any;

  threedbetId : any;
  singleDate: string = '';
  time: string = '';
  confirmallArr: any;
  isChecked: any = false;
  editAmt : any;

  hardcode_edit :any;
  hardcode_delete :any;
  hardcode_add:any;
  hardcode_view: any;
  hardcode_view_bool : any;
  hardcode_delete_bool : any;
  hardcode_edit_bool : any;
  hardcode_double:any;
  hardcode_add_bool :any;

  total_bet_limit_amount:any;
  total_percentage :any;
  total_bet_amount_Str:any;
  fromdate: string ='';
  report_option : string ='';
  choose_msg : string = '';
  isActive : boolean = false;
  ispdfActive : boolean = false;
  iscsvActive : boolean = false;
  clickkbzpay: any = true;
  clickwavepay: any = false;
  csvrep: any = false;
  filename : string = "";
  filename_date :any ;
  grand_total : any;
  twodBetRptList = [];
  twodBetPdfRptList =[];
  myDate = new Date();
  adminObj : any;
  ad_name : any; 
  hardcode_double_bool:any; 
  total_limit_amount:any;
  total_bet_amount:any;

  constructor(private storage: LocalStorageService, private spinner: NgxSpinnerService, private dto: DtoService,private datePipe: DatePipe,
     private http: HttpClient, private funct: FunctService, private router: Router, private toastr: ToastrService,private datepipe: DatePipe) {
      this.idIndex = 1;
      this.storage.store('isNotiSong', "");
    this.hardcode_view ="3DBetAmountLimitation_View";
    this.hardcode_edit = "3DBetAmountLimitation_Edit";
    this.hardcode_delete = "3DBetAmountLimitation_Del";
    this.hardcode_add = "3DBetAmountLimitation_Add";
    this.dto.menuCodes = this.storage.retrieve('menucodes');

    const filteredMenuCodeds_delete =[];
    const filteredMenuCodeds_edit =[];
    const filteredMenuCodeds_add =[];
    const filteredMenuCodeds_view =[];
    this.dto.menuCodes.forEach(menuCode => {
     
      if (menuCode === this.hardcode_delete) {
        filteredMenuCodeds_delete.push(menuCode);
      }
      if (menuCode === this.hardcode_edit) {
        filteredMenuCodeds_edit.push(menuCode);
      }
      if (menuCode === this.hardcode_add) {
        filteredMenuCodeds_add.push(menuCode);
      }
      if (menuCode === this.hardcode_view) {
        filteredMenuCodeds_view.push(menuCode);
      }
    });

    if(filteredMenuCodeds_view.length != 0)
      {
          this.hardcode_view_bool = true;
      }
      else
      {
        this.hardcode_view_bool = false;
      }
     
      if(filteredMenuCodeds_delete.length != 0)
      {
          this.hardcode_delete_bool = true;
      }
      else
      {
        this.hardcode_delete_bool = false;
      }
      if(filteredMenuCodeds_edit.length != 0)
      {
          this.hardcode_edit_bool = true;
      }
      else
      {
        this.hardcode_edit_bool = false;
      }
      if(filteredMenuCodeds_add.length != 0)
      {
          this.hardcode_add_bool = true;
      }
      else
      {
        this.hardcode_add_bool = false;
      }
      if(this.hardcode_edit_bool == false && this.hardcode_delete_bool == false)
      {
         this.hardcode_double = false;
      }
      else
      {
         this.hardcode_double = true;
      }
      this.getCurrentAdmin();
      this.search()
   }

  ngOnInit(): void {
    this.router.routeReuseStrategy.shouldReuseRoute = function () {
      return false;
    };
    this.router.events.subscribe((evt) => {
      if (evt instanceof NavigationEnd) {
        this.router.navigated = false;
        window.scrollTo(0, 0);
      }
    });
    
    this.dtOptions = {
      responsive: true,
      order: []
    }

    this.dtOptions.columnDefs = [
      { targets: [0], orderable: false },
    ];

    if (!this.storage.retrieve('loadFlag')) {
      this.storage.store('loadFlag', 'noLoad');
      setTimeout(function () {
        location.reload();
      }, 5);
    }
    else {
      this.storage.clear('loadFlag');
    }
  }

  onChangeFromDate() {
    $(document).ready(function () {
      this.fromDate = $("#fromDate").val();
    });
  }

  onChangeToDate() {
    $(document).ready(function () {
      this.toDate = $("#toDate").val();
    });
  }

  checkuncheckall() {
    if (this.isChecked == true) {
      this.isChecked = false;
    }
    else {
      this.isChecked = true;
    }
  }

  changeSelection(ev, id) {
    var target = ev.target;
    this.confirmallArr = [];
    if (target.checked && id == 0) {
      for (let i = 0; i < this.threedbetamountlimitList.length; i++) {
        this.threedbetamountlimitList[i].status1 = "check";
      }
    }
    else if (target.checked && id != 0) {
      for (let i = 0; i < this.threedbetamountlimitList.length; i++) {
        if (this.threedbetamountlimitList[i].id == id) {
          this.threedbetamountlimitList[i].status1 = "check";
        }
      }
    }
    else if (!target.checked && id == 0) {
      for (let i = 0; i < this.threedbetamountlimitList.length; i++) {
        this.threedbetamountlimitList[i].status1 = "uncheck";
      }
    }
    else if (!target.checked && id != 0) {
      for (let i = 0; i < this.threedbetamountlimitList.length; i++) {
        if (this.threedbetamountlimitList[i].id == id) {
          this.threedbetamountlimitList[i].status1 = "uncheck";
        }
      }
    }
    this.checkedData();
  }

  checkedData() {
    for (let i = 0; i < this.threedbetamountlimitList.length; i++) {
      if (this.threedbetamountlimitList[i].status1 == "check") {
        this.confirmallArr.push(this.threedbetamountlimitList[i].id);
      }
    }
  }

  handleError(error: HttpErrorResponse){
    if(error.status == 423)
    {
      this.spinner.hide();
    }
    if(error.status == 500)
    {
      this.spinner.hide();
      this.toastr.error('Please check connection', 'Invalid!', {
        timeOut: 3000,
        positionClass: 'toast-top-right',
      });
    }
    if(error.status == 403)
    {
      this.spinner.hide();
      var id = 'tblThreeD' + this.idIndex;
      var table = $('#' + id).DataTable()
      this.toastr.error("Limited Access.", 'Invalid!', {
        timeOut: 3000,
        positionClass: 'toast-top-right',
        });
    }
  }
  
  search() {
    var id = 'tblThreeD' + this.idIndex;
    var table = $('#' + id).DataTable();
    table.destroy();
    this.idIndex = this.idIndex + 1;
    this.spinner.show();
    this.dto.token = this.storage.retrieve('token');    
    this.fromDate = $("#fromDate").val();
    this.toDate = $("#toDate").val();
    let headers = new HttpHeaders();
    headers = headers.set('Authorization',  this.dto.token);
    let params = new HttpParams();
    this.threedbetamountlimitList = [];
    params = params.set('number', this.number).set('amount', this.amount);
    
    this.http.get(this.funct.ipaddress + 'betamountLimitation/threedbetamountLimitation-by-params', { params: params, headers: headers })
    .pipe(
      catchError(this.handleError.bind(this))
     )
    .subscribe( 
      result => {
        this.dto.Response = {};
        this.dto.Response = result.list;
        this.total_bet_limit_amount = result.max_amt_Str;
        if(this.total_bet_limit_amount == undefined || this.total_bet_limit_amount == null || this.total_bet_limit_amount == "")
        {
          this.total_bet_limit_amount = 0;
        }
        this.total_percentage = result.percentage;
        if(this.total_percentage == undefined || this.total_percentage == null || this.total_percentage == "")
        {
          this.total_percentage = 0.00;
        }
        this.total_bet_amount_Str = result.total_bet_amount_Str;   
        if(this.total_bet_amount_Str == undefined || this.total_bet_amount_Str == null || this.total_bet_amount_Str =="")
        {
          this.total_bet_amount_Str = 0;
        }   
        if(this.dto.Response == null)
        {
          this.threedbetamountlimitList.diffAmt = 0;
          this.dtTrigger.next();
          this.spinner.hide();
          return;
        }

        this.threedbetamountlimitList = this.dto.Response;
        for (let i = 0; i < this.threedbetamountlimitList.length; i++) {
          var limitAmt =this.threedbetamountlimitList[i].max_amt_Str.toString().replace(/,/g, "");
          var totalBetAmt =this.threedbetamountlimitList[i].total_bet_amount_Str.toString().replace(/,/g, "");
          this.threedbetamountlimitList[i].diffAmt = parseInt(limitAmt) - parseInt(totalBetAmt);
       }
        this.dtTrigger.next();
        this.spinner.hide();
      });
  }

  
  exportExcel()
  {
    this.search();
    var repType = '';
    var grand_total = 0;
    var id = 'tblThreeD' + this.idIndex;
    var table = $('#' + id).DataTable();
    table.destroy();
    this.idIndex = this.idIndex + 1;
    this.spinner.show();
    this.dto.token = this.storage.retrieve('token');    
    this.fromDate = $("#fromDate").val();
    this.toDate = $("#toDate").val();
    let headers = new HttpHeaders();
    headers = headers.set('Authorization',  this.dto.token);
    let params = new HttpParams();
    this.threedbetamountlimitList = [];
    params = params.set('number', this.number).set('amount', this.amount).set('frompage',"Export");
    this.http.get(this.funct.ipaddress + 'betamountLimitation/threedbetamountLimitation-by-params', { params: params, headers: headers })
    .pipe(
      catchError(this.handleError.bind(this))
     )
      .subscribe(data => {  
        this.filename_date = this.datePipe.transform( new Date(),'dd-MM-yyyy');   
        this.filename = "ThreeDBetAmountLimitationReport";
        this.spinner.hide();
        this.dto.Response = {};
        this.dto.Response = data.list;
        this.total_percentage = data.percentage;
        this.total_bet_amount = data.total_bet_amount;
        this.dto.Response.forEach((row: any) => 
        {    
          var result = row.total_bet_amount.toString().replace(/,/g, "");
          grand_total = grand_total + parseInt(result);
          const selectedFields = {
            number: row.number,
            max_amt: row.max_amt,
            total_bet_amount: row.total_bet_amount,
            percentage: row.percentage,               
            different: row.max_amt - row.total_bet_amount,  
          };
		      this.twodBetRptList.push(Object.values(selectedFields));
          this.twodBetPdfRptList.push(Object.values(selectedFields));
     });

     var title = '';
     if(this.time == 'null'){
        title = 'ThreeD Bet Amount Limited Report (All)';
     }
     else
        title = 'ThreeD Bet Amount Limited Report';
     const header = ['3D Number', 'Limited Amount', 'Total Bet Amount', '%','Different Amount'];              
     const workbook = new Workbook();
     const worksheet = workbook.addWorksheet('ThreeDBetAmountLimitedReport');          
     const titleRow = worksheet.addRow([title]);
     titleRow.font = { name: 'Corbel', family: 4, size: 16, underline: 'double', bold: true};
     worksheet.addRow([]);
     titleRow.eachCell(cell => {
       cell.alignment = { horizontal: 'center' };
     }); 
     const subTitleRow = worksheet.addRow(['Printed Date/Time : '+this.filename_date]);
     worksheet.mergeCells('A3:D3');
     worksheet.mergeCells('A1:D2');
     subTitleRow.eachCell(cell => {
       cell.alignment = { horizontal: 'center' };
     });                
     const subTitleRow_print = worksheet.addRow(['Printed By : '+this.ad_name]);
     worksheet.mergeCells('A4:D4');
     subTitleRow_print.eachCell(cell => {
       cell.alignment = { horizontal: 'center' };
     });                
     const headerRow = worksheet.addRow(header);                 
       headerRow.eachCell((cell, number) => {
       cell.fill = {
       type: 'gradient',
       gradient: 'angle',                
       degree: 0,                  
       stops: [                    
         {position:0, color:{argb:'ff095108'}},  
         {position:0.5, color:{argb:'ff095108'}},
         {position:1, color:{argb:'ff095108'}}
       ],
       };
       cell.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
       cell.alignment = {horizontal:'center' };
       cell.font= {color:{argb:'FFFFFF'}};              
       });      
    
       var tot1 = 0;
       var tot2 = 0;
       var tot3 = 0 ;
       var tot4 = 0;
       this.twodBetRptList.forEach(d => 
       {                        
         const row = worksheet.addRow(d);
         const qty1 = row.getCell(2).value;
         var result1 = qty1.toString().replace(/,/g, "");
         tot1 = parseInt(result1) + tot1;
         this.total_limit_amount = tot1;
         row.alignment = {horizontal :'center',wrapText: true}

         tot2 = this.total_bet_amount;
         row.alignment = {horizontal :'center',wrapText: true}
         
         tot3 = this.total_percentage;
         row.alignment = {horizontal :'center',wrapText: true}

         const qty4 = row.getCell(5).value;
         var result4 = qty4.toString().replace(/,/g, "");
         tot4 = parseInt(result4) + tot4;
         row.alignment = {horizontal :'center',wrapText: true}  
       }
   );
   this.twodBetRptList = [];
   worksheet.getColumn(1).width = 15;
   worksheet.getColumn(1).alignment = { horizontal: 'center', wrapText: true };
   worksheet.getColumn(2).width = 19;
   worksheet.getColumn(2).alignment = { horizontal: 'center', wrapText: true };
   worksheet.getColumn(3).width = 19;
   worksheet.getColumn(3).alignment = { horizontal: 'center', wrapText: true };
   worksheet.getColumn(4).width = 14;
   worksheet.getColumn(4).alignment = { horizontal: 'center', wrapText: true };
   worksheet.getColumn(5).width = 19;
   worksheet.getColumn(5).alignment = { horizontal: 'right', wrapText: true };


   const footerRow = worksheet.addRow([]);
   footerRow.getCell(1).value = 'Grand Total';
   footerRow.getCell(2).value = tot1.toLocaleString();     
   footerRow.getCell(3).value = tot2.toLocaleString();
   footerRow.getCell(4).value = tot3.toLocaleString();
   footerRow.getCell(5).value = tot4.toLocaleString();
   footerRow.getCell(1).fill = {
   type: 'pattern',
   pattern: 'solid',
   fgColor: { argb: 'FFCCFFE5' }
   };
   
   footerRow.getCell(1).border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
   footerRow.getCell(2).fill = {
   type: 'pattern',
   pattern: 'solid',
   fgColor: { argb: 'FFCCFFE5' }
   };
   footerRow.getCell(2).border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } }; 


   footerRow.getCell(1).border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
   footerRow.getCell(3).fill = {
   type: 'pattern',
   pattern: 'solid',
   fgColor: { argb: 'FFCCFFE5' }
   };
   footerRow.getCell(3).border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };  


   footerRow.getCell(1).border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
   footerRow.getCell(4).fill = {
   type: 'pattern',
   pattern: 'solid',
   fgColor: { argb: 'FFCCFFE5' }
   };
   footerRow.getCell(4).border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };

   
   footerRow.getCell(1).border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
   footerRow.getCell(5).fill = {
   type: 'pattern',
   pattern: 'solid',
   fgColor: { argb: 'FFCCFFE5' }
   };
   footerRow.getCell(5).border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };

   workbook.xlsx.writeBuffer().then((data: any) => {
   const blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
   fs.saveAs(blob, this.filename + repType);
   });
  
       this.toastr.success('ThreeD Bet Amount Limited Report', 'Success!', {
         timeOut: 3000,
         positionClass: 'toast-top-right',
       });
     this.spinner.hide();
     });
}

  convert(list, pdfname)
  {
        var doc = new jsPDF('p', 'pt','a4');
        const head = [['3D Number', 'Limited Amount', 'Total Bet Amount', '%','Different Amount']];
        doc.setFontSize(12);
        doc.setTextColor(0);
        var tot = 0;
        var rows1 = [];
        rows1.push("Total = "+40);
        var title = '';
        if(this.time == 'null'){
          title = '3D Bet Amount Limitation Report (All)';
        }
        else
          title = '3D Bet Amount Limitation Report ('+this.time+')';
        doc.text(title, 40, 20);
        doc.text('Printed By : '+this.ad_name+' - Printed Date : '+this.filename_date,40,40);
        autoTable(doc,
        {
        head: head,
        body: list,
        startY: 50,
        styles: { overflow: "linebreak" },
        didDrawCell: (list) => {
        },
        didParseCell:(list)=>{
          var rows = list.table.body;
          if (list.row.index === rows.length - 1) {     
              list.cell.styles.fillColor = [239, 154, 154];       
          }
        }
        })
        doc.save(pdfname);
    }

    
  getCurrentAdmin() {
    this.dto.token = this.storage.retrieve('token');
    let headers = new HttpHeaders();
    headers = headers.set('Authorization',this.dto.token);
    this.http.get(this.funct.ipaddress + 'admin/GetCurrentAdminDetail', { headers: headers }).subscribe(
      result => {
        this.dto.Response = {};
        this.dto.Response = result;
        this.adminObj = this.dto.Response;
        this.ad_name = this.adminObj.name;
      }
    );
  }
  ConvertToCSV(objArray, headerList) 
    {
      let array = typeof objArray != 'object' ? JSON.parse(objArray) : objArray;
      let str = '';
      let row = '';
      for (let index in headerList) {
          row += headerList[index] + ',';
      }
      row = row.slice(0, -1);
      str += row + '\r\n';
      for (let i = 0; i < array.length; i++) {
          let line = '';
          for (let index in headerList) {
             let head = headerList[index];
             var d = array[i][head];
             if(d != null)
             {
             if(d.toString().includes(","))
                d = d.toString().replace(/,/g, "");
             }
             line +=  d + ",";
          }
          line = line.slice(0,-1);
          str += line + '\r\n';
      }
    return str;
    }

  numericOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode == 101 || charCode == 69 || charCode == 45 || charCode == 43 || charCode == 46) {
      return false;
    }
    return true;
  }

  threeDbetdelete(threedbetId) {
    this.dto.token  = this.storage.retrieve('token');
    let headers = new HttpHeaders();
    headers = headers.set('Authorization', this.dto.token );
    let params = new HttpParams();
    this.threedbetId = threedbetId;
    if(this.threedbetId == null || this.threedbetId =='' || this.threedbetId == 'undefined')
    {
      this.toastr.error("Invalid Id", 'Invalid!', {
        timeOut: 3000,
        positionClass: 'toast-top-right',
      });
      return; 
    }
    params = params.set('id', this.threedbetId);
    var formData = new FormData();
    formData.append("id",this.threedbetId);
    this.http.post(this.funct.ipaddress + 'betamountLimitation/threeDdelete', formData, { headers: headers }).subscribe( 
      result => {
        this.dto.Response = {};
        this.dto.Response = result;
        if (this.dto.Response.status == 'Success') {
          this.spinner.hide();
          this.router.navigate(['/threed-bet-amount-limitation-list']).then(() => {
            this.toastr.success(this.dto.Response.message, 'Success!', {
              timeOut: 3000,
              positionClass: 'toast-top-right'
            });
          })
        }
        else {
          this.spinner.hide();
          this.toastr.error(this.dto.Response.message, 'Invalid!', {
            timeOut: 3000,
            positionClass: 'toast-top-right',
          });
        }
      }
    );
  }

  delAll()
  {
    this.dto.token = this.storage.retrieve('token');
    let headers = new HttpHeaders();
    headers = headers.set('Authorization',   this.dto.token);
    let params = new HttpParams();

    if(this.confirmallArr == null)
    {
      this.toastr.error("Please check at least one check box", 'Invalid!', {
        timeOut: 3000,
        positionClass: 'toast-top-right',
      });
      return; 
    }
    this.threedbetId = this.confirmallArr.toString();
    params = params.set('id', this.threedbetId);
    var formData = new FormData();
    formData.append("id",this.threedbetId);
   
    this.http.post(this.funct.ipaddress + 'betamountLimitation/threeDdelete', formData, { headers: headers }).subscribe( 
      result => {
        this.dto.Response = {};
        this.dto.Response = result;
        if (this.dto.Response.status == 'Success') {
          this.spinner.hide();
          this.router.navigate(['/threed-bet-amount-limitation-list']).then(() => {
            this.toastr.success(this.dto.Response.message, 'Success!', {
              timeOut: 3000,
              positionClass: 'toast-top-right'
            });
          })
          this.spinner.hide();
        }
        else {
          this.spinner.hide();
          this.toastr.error(this.dto.Response.message, 'Invalid!', {
            timeOut: 3000,
            positionClass: 'toast-top-right',
          });
          this.spinner.hide();
        }
      }
    );
  }

  onChangeSingle() {
    $(document).ready(function () {
      this.singleDate = $("#singleDate").val();
    });
  }

  showeditAllModal()
  {
    if(this.confirmallArr == null)
    {
      this.toastr.error("Please select at least one record", 'Invalid!', {
        timeOut: 3000,
        positionClass: 'toast-top-right',
      });
      return;
    }
    $('#showeditModal').modal("show");
  }

  editAll()
  {
    this.dto.token = this.storage.retrieve('token');
    let headers = new HttpHeaders();
    headers = headers.set('Authorization',  this.dto.token);
    this.threedbetId = this.confirmallArr.toString();
    var formData = new FormData();
    formData.append("id",this.threedbetId);
    if(this.editAmt == null || this.editAmt == 0 || this.editAmt == '' || this.editAmt == undefined)
    {
      this.toastr.error("Invalid amount", 'Invalid!', {
        timeOut: 3000,
        positionClass: 'toast-top-right',
      });
      return;
    }
    formData.append("amount",this.editAmt);
    this.http.post(this.funct.ipaddress + 'betamountLimitation/threebetLimitupdateAll', formData, { headers: headers }).subscribe( 
      result => {
        this.dto.Response = {};
        this.dto.Response = result;
        if (this.dto.Response.status == 'Success') {
          this.spinner.hide();
          this.router.navigate(['/threed-bet-amount-limitation-list']).then(() => {
            this.toastr.success(this.dto.Response.message, 'Success!', {
              timeOut: 3000,
              positionClass: 'toast-top-right'
            });
          })
        }
        else {
          this.spinner.hide();
          this.toastr.error(this.dto.Response.message, 'Invalid!', {
            timeOut: 3000,
            positionClass: 'toast-top-right',
          });
        }
      }
    );
  }

}
