import { Component, ComponentFactoryResolver, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,HttpErrorResponse } from '@angular/common/http';
import { Router, ActivatedRoute } from '@angular/router';

import { LocalStorageService } from 'ngx-webstorage';
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from 'ngx-toastr';

import { catchError, retry } from 'rxjs/operators';
import { from, throwError } from 'rxjs';

import { DtoService } from '../../service/dto.service';
import { FunctService } from '../../service/funct.service';
import { isNull } from '@angular/compiler/src/output/output_ast';
import { DatePipe } from '@angular/common';

declare var $: any;

@Component({
  selector: 'app-gs-provider-detail',
  templateUrl: './provider-detail.component.html',
  styleUrls: ['./provider-detail.component.css']
})
export class GameProviderDetailComponent implements OnInit {

  gameDTO: any;
  gamPassDTO: any;
  token: any;
  gameId: any;
  imagePath: any;
  imgURL: any;
  imgURL1: any;
  imgURL2:any;
  message: string = '';
  count = 0;
  filePath : any;
  displayAcc : any;
  disableAccId : any;
  isMaintenance :boolean;
  filePath1 :any;
  message1: string = '';
  imagePath1: string = '';
  fromhours:any;
  fromminutes:any;
  tohours:any;
  tominutes:any;
  imagePath2: string = '';
  message2: string = '';
  filePath2 :any;
  
  singleDate: any;
  alldate: any;
  alltoDate: any;
  alltodate:any;
  alltodayDate: any;  
  alltodaytodate: any;
  originalAdminDTO:any;
  gameprovider_originalname :any;


  constructor(private storage: LocalStorageService, private spinner: NgxSpinnerService, private toastr: ToastrService, private http: HttpClient, private dto: DtoService, private router: Router,
    private route: ActivatedRoute, private funct: FunctService, private datepipe: DatePipe) {    
      this.storage.store('isNotiSong', "");
     }
  ngOnInit(): void {  

    if (!this.storage.retrieve('loadFlag')) {
      this.storage.store('loadFlag', 'noLoad');
      setTimeout(function () {
        location.reload();
      }, 1);
    }
    else {
      this.storage.clear('loadFlag');
    }

    const adminDataString = localStorage.getItem('gameData');
    this.originalAdminDTO = JSON.parse(adminDataString);


    this.gameId = this.route.snapshot.paramMap.get("id");
    if (this.gameId == null) {
      $(document).ready(function () {
      });
      this.gameDTO = {
        id: 0,
        name: '',
        imageUrl: '',
        status: '',
        isMaintenance:false,
        fromTime:'',
        toTime:'',
        imageUrl2:'',
        mimageUrl:'',
        name_my :'',  
        name_th  :'',  
        name_zh : '',
        name_en : '',
        decision:'',
        notSend:''

      };
    }
    else {
      $(document).ready(function () {
      });
      this.gameDTO = {
        id: 0,
        name: '',
        imageUrl: '',
        status: '',
        isMaintenance:false,
        fromTime:'',
        toTime:'',
        imageUrl2:'',
        mimageUrl:'',
        name_my :'',  
        name_th  :'',  
        name_zh : '',
        name_en : '',
        decision:'',
        notSend:''
      };
      this.getGameById();
    }
  }

  handleError(error: HttpErrorResponse){
    if(error.status == 423)
    {
      this.spinner.hide();
      $("#deleteData").modal("show");
    }
    if(error.status == 403)
    {
      this.spinner.hide();
      this.toastr.error("Limited Access.", 'Invalid!', {
        timeOut: 3000,
        positionClass: 'toast-top-right',
        });
    }
    return throwError(error);
    }
    OkLogout()
    {
      window.location.href ="./ad-login";
    }   
    
    numericOnly(event: any): boolean { 
      const charCode = (event.which) ? event.which : event.keyCode;
     
      if (charCode == 101 || charCode == 69 || charCode == 45 || charCode == 43 || charCode == 46  ) {
        return false;
      }
      return true;  
    }
    getGameById() {
    this.token = this.storage.retrieve('token');
    let headers = new HttpHeaders();
    headers = headers.set('Authorization',  this.token);
    let params = new HttpParams();
    params = params.set('id', this.gameId);
    this.http.get(this.funct.ipaddress + 'gameProvider/GetDetailList', { params: params, headers: headers })
    .pipe(
      catchError(this.handleError.bind(this))
     )
    .subscribe( 
      result => {
        this.dto.Response = {};
        this.dto.Response = result;
        console.log("this.dto.Response>> " + JSON.stringify(this.dto.Response));
        this.gameDTO = this.dto.Response;
        if (this.gameDTO.imageUrl != null || this.gameDTO.imageUrl != '') {
          this.imgURL = this.gameDTO.imageUrl;
          console.log("imgURL getResultById>>>" + this.imgURL);
        }
        if (this.gameDTO.titleimageUrl != null || this.gameDTO.titleimageUrl != '') {
          this.imgURL1 = this.gameDTO.titleimageUrl;
          console.log("imgURL getResultById>>>" + this.imgURL1);
        }
        if (this.gameDTO.mimageUrl != null || this.gameDTO.mimageUrl != '') {
          this.imgURL2 = this.gameDTO.mimageUrl;
          console.log("imgURL getResultById>>>" + this.imgURL2);
        }
        if(this.gameDTO.fromTime =='' || this.gameDTO.fromTime ==undefined || this.gameDTO.fromTime ==null)
        {
          this.alldate = new Date();
          this.alltodate = new Date();          
        }
        else
        {
          this.alldate = this.gameDTO.fromTime.split(" ")[0];
          //this.alldate = new Date(this.alldate); 
          this.alldate = new Date();         
          this.fromhours = this.gameDTO.fromTime.split(" ")[1].split(":")[0];
          this.fromminutes = this.gameDTO.fromTime.split(" ")[1].split(":")[1];
          this.alltodate = this.gameDTO.toTime.split(" ")[0];
          //this.alltodate = new Date(this.alltodate); 
          this.alltodate = new Date();            
          this.tohours = this.gameDTO.toTime.split(" ")[1].split(":")[0];
          this.tominutes = this.gameDTO.toTime.split(" ")[1].split(":")[1];         
        }      
        // this.fromhours = this.gameDTO.fromTime.split(":")[0];
        // this.fromminutes = this.gameDTO.fromTime.split(":")[1];
        // this.tohours = this.gameDTO.toTime.split(":")[0];
        // this.tominutes = this.gameDTO.toTime.split(":")[1];
     
        if(this.gameDTO.notSend == "true")
        {
          this.originalAdminDTO.notSend = this.gameDTO.notSend;
        }
        else
        {
            this.originalAdminDTO.notSend = "false";
        }
        this.gameprovider_originalname = this.gameDTO.display_name;
        
      });
  }

  preview(files) {
    if (files.length === 0)
      return;
    var mimeType = files[0].type;
    if (mimeType.match(/image\/*/) == null) {
      this.message = "Only images are supported.";
      return;
    }
    var reader = new FileReader();
    this.imagePath = files;
    this.filePath = files[0];
    reader.readAsDataURL(files[0]);
    reader.onload = (_event) => {
      this.imgURL = reader.result;
      console.log("imgURL>>>" + JSON.stringify(this.imgURL));
    }
  }

  preview1(files) {
    if (files.length === 0)
      return;
    var mimeType1 = files[0].type;
    if (mimeType1.match(/image\/*/) == null) {
      this.message1 = "Only images are supported.";
      return;
    }
    var reader = new FileReader();
    this.imagePath1 = files;
    this.filePath1 = files[0];
    reader.readAsDataURL(files[0]);
    reader.onload = (_event) => {
      this.imgURL1 = reader.result;
      console.log("imgURL1111>>>" + JSON.stringify(this.imgURL1));
    }
  }  
  preview2(files) {
    if (files.length === 0)
      return;
    var mimeType2 = files[0].type;
    if (mimeType2.match(/image\/*/) == null) {
      this.message2 = "Only images are supported.";
      return;
    }
    var reader = new FileReader();
    this.imagePath2 = files;
    this.filePath2 = files[0];
    reader.readAsDataURL(files[0]);
    reader.onload = (_event) => {
      this.imgURL2 = reader.result;
      console.log("imgURL>>>" + JSON.stringify(this.imgURL2));
    }
  }

  goSave() {
    if (this.gameId == null) {
      this.save();
    }
    else {
      this.edit();
    }
  }
   save() 
   {
         this.spinner.show();
          this.token = this.storage.retrieve('token');
          let headers = new HttpHeaders();
          headers = headers.set('Authorization', this.token);
          if (this.imgURL != '' || this.imgURL != null || this.imgURL != undefined) {
            if(this.imgURL.includes('data:image/jpeg;base64,'))
               this.gamPassDTO.imgUrl = this.imgURL.replace("data:image/jpeg;base64,","");
            if(this.imgURL.includes('data:image/png;base64,'))
               this.gamPassDTO.image_url = this.imgURL.replace("data:image/png;base64,","");
          }
          console.log("request game/save " + JSON.stringify(this.gamPassDTO));
          this.http.post(this.funct.ipaddress + 'game/insert', this.gamPassDTO, { headers: headers })
          .pipe(
            catchError(this.handleError.bind(this))
           )
          .subscribe( //change
            result => {
              this.dto.Response = {};
              this.dto.Response = result;
              if (this.dto.Response.status == 'Success') {
                this.spinner.hide();
                this.router.navigate(['/game-list']).then(() => {
                  this.toastr.success(this.dto.Response.message, 'Success!', {
                    timeOut: 3000,
                    positionClass: 'toast-top-right'
                  });
                })
              }
              else {
                this.spinner.hide();
                this.toastr.error(this.dto.Response.message, 'Invalid!', {
                  timeOut: 3000,
                  positionClass: 'toast-top-right',
                });
              }
            });
  }

  edit() {   
        this.spinner.show();
          // this.token = this.storage.retrieve('token');
          // let headers = new HttpHeaders();
          
          let editedArray = this.getEditedArray();   
          let headers = new HttpHeaders();

          if(editedArray.length > 0)
          {
            headers = headers.set('Authorization', this.token)
            .set('editedArray', editedArray); 
          }
          else{
            headers = headers.set('Authorization',  this.token);
          } 


          headers = headers.set('Authorization',  this.token);
          if (this.imgURL != null || this.imgURL != ''  || this.imgURL != undefined) {
              if(this.imgURL.includes('data:image/jpeg;base64,'))
                this.gameDTO.imageUrl = this.imgURL.replace("data:image/jpeg;base64,","");
              if(this.imgURL.includes('data:image/png;base64,'))
              this.gameDTO.imageUrl = this.imgURL.replace("data:image/png;base64,","");
            }
          else
          {
              console.log("In else Image url "+this.gameDTO.imageUrl)
              this.gameDTO.imageUrl = this.gameDTO.imageUrl;
          }
        if(this.imgURL1 != null)
        if ( this.imgURL1 != null  || this.imgURL1 != '' || this.imgURL1 != undefined) {
              if(this.imgURL1.includes('data:image/jpeg;base64,'))
                this.gameDTO.titleimageUrl = this.imgURL1.replace("data:image/jpeg;base64,","");
              if(this.imgURL1.includes('data:image/png;base64,'))
                this.gameDTO.titleimageUrl = this.imgURL1.replace("data:image/png;base64,","");
            }
          else
          {
              console.log("In else Image url "+this.gameDTO.titleimageUrl)
              this.gameDTO.titleimageUrl = this.gameDTO.titleimageUrl;
          }
          if(this.imgURL2 != null)
          if ( this.imgURL2 != null  || this.imgURL2 != '' || this.imgURL2 != undefined) 
          {
              if(this.imgURL2.includes('data:image/jpeg;base64,'))
                this.gameDTO.mimageUrl = this.imgURL2.replace("data:image/jpeg;base64,","");
              if(this.imgURL2.includes('data:image/png;base64,'))
                this.gameDTO.mimageUrl = this.imgURL2.replace("data:image/png;base64,","");
          }
          else
          {
              console.log("In else Image url "+this.gameDTO.mimageUrl)
              this.gameDTO.mimageUrl = this.gameDTO.mimageUrl;
          }      
          this.gameDTO.name = this.gameDTO.display_name;
          this.gameDTO.isMaintenance = this.gameDTO.isMaintenance;
          this.gameDTO.status = this.gameDTO.status;
          this.gameDTO.id = this.gameId;    
          this.gameDTO.name_my = this.gameDTO.name_my;  
          this.gameDTO.name_th = this.gameDTO.name_th; 
          this.gameDTO.name_zh = this.gameDTO.name_zh; 
          this.gameDTO.name_en = this.gameDTO.name_en; 
          this.gameDTO.decision = this.gameDTO.decision; 
          this.gameDTO.NotSend = this.gameDTO.NotSend; 

          if( this.alldate == 'undefined' || this.alldate == null)
          {
            this.alldate = this.alltodayDate;
            var fdate = new Date(this.alldate),
            mnth = ("0" + (fdate.getMonth() + 1)).slice(-2),
            day = ("0" + fdate.getDate()).slice(-2);     
            var fromDateTime = '';             
            this.alldate =  [day, mnth, fdate.getFullYear()].join("/"); 
                     
          }
         else
         {                      
            var fdate = new Date(this.alldate),
            mnth = ("0" + (fdate.getMonth() + 1)).slice(-2),
            day = ("0" + fdate.getDate()).slice(-2);     
            var fromDateTime = '';
            this.alldate =  [fdate.getFullYear(), mnth, day].join("/");            
         }          
          if(this.alltodate == 'undefined' || this.alltodate == null)
          {
            this.alltodate = this.alltodayDate;          
            var tdate = new Date(this.alltodate),
            mnth = ("0" + (tdate.getMonth() + 1)).slice(-2),
            day = ("0" + tdate.getDate()).slice(-2);     
            var toDateTime = '';
            this.alltodate  =  [fdate.getFullYear(), mnth, day].join("/");             
          }
         else
         {                          
            var tdate = new Date(this.alltodate),
            mnth = ("0" + (tdate.getMonth() + 1)).slice(-2),
            day = ("0" + tdate.getDate()).slice(-2);     
            var toDateTime = '';
            this.alltodate =  [tdate.getFullYear(), mnth, day].join("/");            
         }
         if(this.fromhours == undefined || this.fromhours =="")
         {
             this.fromhours = "00"; 
         }
         if(this.fromminutes == undefined || this.fromminutes =="")
         {
             this.fromminutes = "00"; 
         }
         if(this.tohours == undefined || this.tohours =="")
         {
             this.tohours = "00"; 
         }
         if(this.tominutes == undefined || this.tominutes =="")
         {
             this.tominutes = "00"; 
         }
        this.gameDTO.fromTime = this.alldate + ' ' + this.fromhours+':'+this.fromminutes,
        this.gameDTO.toTime = this.alltodate + ' ' +  this.tohours+':'+this.tominutes,
        this.http.post(this.funct.ipaddress + 'gameProvider/update_new', this.gameDTO, { headers: headers })//gameProvider/update  gameProvider/update_new
          .pipe(
            catchError(this.handleError.bind(this))
          )
          .subscribe(  
            result => {
              this.dto.Response = {};
              this.dto.Response = result;
              if (this.dto.Response.status == 'Success') {
                this.spinner.hide();
                this.router.navigate(['/game-provider']).then(() => {
                  this.toastr.success(this.dto.Response.message, 'Success!', {
                    timeOut: 3000,
                    positionClass: 'toast-top-right'
                  });
                })
              }
              else {
                
              }
            });     
  }

onChangeSingle() {
  $(document).ready(function () { 
    this.alldate = $("#alldate").val();
  });
}
onChangeAllToDate() {
  $(document).ready(function () {
    this.alltodate = $("#alltodate").val();
  });
}

  checkUncheck()
  {
    if($("#foo_bar").is(':checked'))
     {
      this.isMaintenance = true;
     }
    else
    {
      this.isMaintenance = false;
    }
      
  }

  
  getEditedArray() {
    let editedArray = '';
  
    if (this.gameDTO.status !== this.originalAdminDTO.status) {
      editedArray += 'Status : ' + this.originalAdminDTO.status + ' -> ' + this.gameDTO.status + ', ';
    }
    if (this.gameDTO.isMaintenance !== this.originalAdminDTO.isMaintenance) {
      editedArray +=  this.gameprovider_originalname +'->'+ 'IsMaintenance : ' + this.originalAdminDTO.isMaintenance + ' -> ' + this.gameDTO.isMaintenance + ', ';
    }
    if(this.originalAdminDTO.notSend !== undefined || this.gameDTO.notSend !== undefined)
    {
      if (this.gameDTO.notSend !== this.originalAdminDTO.notSend) 
      {
        editedArray += 'Noti Send : ' + this.originalAdminDTO.notSend + ' -> ' + this.gameDTO.notSend + ', ';
      }
    }
  
 
    if (editedArray.endsWith(', ')) {
      editedArray = editedArray.slice(0, -2);
    }
    

    return editedArray;
  }
}
