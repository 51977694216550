<div class="wrapper">
    <!-- Navbar -->
    <app-header-sidebar></app-header-sidebar>


    <div class="content-wrapper">
    
        <div class="content-header">
            <div class="container-fluid">
               
            </div>
        </div>
       
        <section class="content">
            <div class="container-fluid">
                <form>

                    <div class="row">
                        <div class="col-md-12">

                            <div class="card">
                                <div class="card-header">
                                    <h3 class="card-title" style="font-weight: bold;">Transaction Tutorial Detail </h3>
                                </div>
                             
                                <div class="card-body">
                                    <form>
                                        <div class="row">                                           
                                            <label class="col-md-2"> Video Link </label>
                                            <div class="form-group col-md-3">
                                                <input class="form-control form-control-sm"
                                                    [(ngModel)]="gameDTO.liveLink"
                                                    [ngModelOptions]="{standalone: true}">
                                            </div>
                                           
                                            <label class="col-md-2">Description (Myanmar)</label>
                                            <div class="form-group col-md-3">
                                                <input class="form-control form-control-sm"
                                                    [(ngModel)]="gameDTO.description_my"
                                                    [ngModelOptions]="{standalone: true}">
                                            </div>

                                        
                                        </div>
                                                <div class="row">
                                                    <label class="col-md-2">Bank Type</label>
                                                    <div class="form-group col-md-3">                                             
                                                        <select class="status form-control form-control-sm col-md-12 col-sm-4 col-xs-4"
                                                        [(ngModel)]="gameDTO.bankType" [ngModelOptions]="{standalone: true}"
                                                        (ngModelChange) = "onChangeTagSection()" >
                                                        
                                                        <option *ngFor="let bt of bankTypeList" value = "{{bt.bankType}}" >
                                                            {{bt.bankType}}
                                                        </option>
                                                    </select>



                                                    </div>

                                                    <label class="col-md-2">Description (English)</label>
                                                     <div class="form-group col-md-3">
                                                    <input class="form-control form-control-sm"
                                                    [(ngModel)]="gameDTO.description_en"
                                                    [ngModelOptions]="{standalone: true}">
                                                   </div>

                                                </div>   
                                                
                                                <div class="row">                                                   

                                                    <label class="col-md-2"> Transaction Type </label>
                                                    <div class="form-group col-md-3">
                                                        <select class="transactiontype form-control form-control-sm col-md-12 col-sm-4 col-xs-4" [(ngModel)]="gameDTO.transType" [ngModelOptions]="{standalone: true}">                  
                                                            <option value="Topup" selected>TOPUP</option>
                                                            <option value="Withdrawal">WITHDRAWAL</option> 
                                                            <option value="TRANSFER">TRANSFER</option> 
                                                          </select>
                                                    </div>
                                                    
                                                     
                                                    <label class="col-md-2">Description (Thailand)</label>
                                                    <div class="form-group col-md-3">
                                                   <input class="form-control form-control-sm"
                                                   [(ngModel)]="gameDTO.description_th"
                                                   [ngModelOptions]="{standalone: true}">
                                                  </div>

                                                </div>                                               


                                                <div class="row">
                                                   

                                                   <!-- amk -->

                                                   <label class="col-md-2">Sort</label>
                                                   <div class="form-group col-md-3">
                                                       <input class="form-control form-control-sm"
                                                           [(ngModel)]="gameDTO.orderNumber"
                                                           [ngModelOptions]="{standalone: true}">
                                                   </div>



                                                    <label class="col-md-2">Description (Chinese)</label>
                                                    <div class="form-group col-md-3">
                                                   <input class="form-control form-control-sm"
                                                   [(ngModel)]="gameDTO.description_zh"
                                                   [ngModelOptions]="{standalone: true}">
                                                  </div>
                                                </div>



                                        <div class="row" style="margin-top: 1%;">
                                            <div class="col-md-2 col-sm-6">
                                                <button type="button" class="btn btn-block btn-success"
                                                    (click)="goSave()">Save</button>&nbsp;</div>

                                                    <!-- <div class="col-md-2 col-sm-6" *ngIf="hardcode_delete_bool === true">
                                                        <button type="button" class="btn btn-block btn-danger"
                                                        (click)="delete()"> Delete  &nbsp;</button>
                                                    </div> -->
                                            <div class="col-md-2 col-sm-6">
                                                <button type="button" class="btn btn-block btn-danger"
                                                    [routerLink]="['/transactiontutorial']"> Cancel</button>
                                            </div>

                                         

                                        </div>



                                       


                                    


                              
                                    </form>

                                </div>
                            </div>
                        </div>

                    </div>
                    
                 
                      
                </form>
               
            </div>
           
        </section>
       
    </div>

    <aside class="control-sidebar control-sidebar-dark">
        <!-- Control sidebar content goes here -->
    </aside>

    <app-footer></app-footer>

    <ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#fff" type="square-jelly-box">
        <p style="font-size: 16px; color: white">Loading...</p>
    </ngx-spinner>

</div>


<div id="deleteData" class="modal fade">
    <div class="modal-dialog">
        <div class="modal-content">

            <div class="modal-body">
                <form>

                    <div class="row" style="margin-top: 5%;margin-bottom: 3%;margin-left: 9%;">
                        <div class="form-group col-md-12">
                            <h5> This Account is used by another device. 
                                Please Sign Out. Login Again. </h5>
                        </div>
                    </div>

                    <div class="row" style="margin-left: 40%;margin-bottom: 3%;">
                        <div class="col-md-5 col-sm-6" id="deleteBtn">
                            <button type="button" class="btn btn-block btn-success" (click)="OkLogout()"> Ok </button>
                        </div>
                    </div>
                </form>
            </div>

        </div>
    </div>
</div>


