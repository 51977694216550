<div class="wrapper">
    <!-- Navbar -->
    <app-header-sidebar></app-header-sidebar>
  
    <!-- here here here -->
    <!-- Content Wrapper. Contains page content -->
    <div class="content-wrapper">
      <!-- Content Header (Page header) -->
      <div class="content-header">
        <div class="container-fluid">
        </div><!-- /.container-fluid -->
      </div>
      <!-- /.content-header -->
  
      <!-- Main content -->
      <section class="content">
        <div class="container-fluid">
          <form>
  
            <div class="row">
              <div class="col-md-12">
                <div class="card">
                  <div class="card-header">
                    <h3 class="card-title" style="font-weight: bold;"> Advertising Team Detail </h3>
                  </div>
                  <!-- /.card-header -->
                  <div class="card-body">
                    <form>
                      <div class="row">
                        <label class="col-md-2"> Name </label>
                        <div class="form-group col-md-3">
                          <input class="form-control form-control-sm" [(ngModel)]="agentDTO.name"
                            [ngModelOptions]="{standalone: true}">
                        </div>
                        <div class="col-md-1"></div>
                        <label class="col-md-2 wallet"> Wallet </label>
                        <div class="col-md-3">
                          <input type="number" class="form-control form-control-sm wallet" [(ngModel)]="agentDTO.balance_Str"
                            [ngModelOptions]="{standalone: true}" step="500" min="0" disabled>
                        </div>
                      </div>
                     
                    <!--  <div class="row">
                        <label class="col-md-2">Role</label>
                        <div class="form-group col-md-3">
                        <select class="time form-control form-control-sm col-md-12 col-sm-4 col-xs-4" id = "roleName" [(ngModel)]="agentDTO.role_id"
                          [ngModelOptions]="{standalone: true}">
                          <option value="4" selected>AGENT</option>
                          <option value="5">SALE_PROMOTER</option>
                        </select>
                      </div>-->
  
                      <div class="row">
                        <label class="col-md-2">Role</label>
                          <div class="form-group col-md-3">
                          <select (change)="onRoleChange($event)" id = "roleName" class="form-control form-control-sm col-md-12 col-sm-4 col-xs-4"
                           [(ngModel)]="agentDTO.role_id" [ngModelOptions]="{standalone: true}">
                            <option *ngFor="let role of roleList" value = "{{role.id}}" >
                              {{role.name}}
                            </option>
                         </select>
                        </div> 
                       
                      <div class="col-md-1"></div>
                        <label class="col-md-2 tcommission"> Total Commission </label>
                        <div class="form-group col-md-3">
                          <input type="number" class="form-control form-control-sm tcommission"
                            [(ngModel)]="agentDTO.commission_balance_Str" [ngModelOptions]="{standalone: true}" step="500" disabled
                            min="0">
                        </div>
                    </div>
  
                    <div class="row">
                     
                     <label class="col-md-2" style="display: none;">Parent Customer Service</label>
                        <div class="form-group col-md-3" style="display: none;">
                        <select  style="display: none;" (change) = "flagAgent()" id = "parent_id" class="form-control form-control-sm col-md-12 col-sm-4 col-xs-4"
                         [(ngModel)]="agentDTO.parent_id" [ngModelOptions]="{standalone: true}">
                          <option *ngFor="let agent of activeagentList" value = "{{agent.id}}" >
                            {{agent.name}}
                          </option>
                       </select>
                      </div> 
                   
  
                     <!-- <div class="col-md-1"></div>-->
                        <label class="col-md-2 total_withdraw"> Total Withdraw </label>
                        <div class="form-group col-md-3">
                          <input type="number" class="form-control form-control-sm total_withdraw" [(ngModel)]="agentDTO.withdrawal_balance_Str"
                            [ngModelOptions]="{standalone: true}" step="500" min="0" disabled>
                        </div>
  
                  </div>
  
                      <div class="row">
                        <label class="col-md-2"> Phone No </label>
                        <div class="form-group col-md-3">
                          <input class="form-control form-control-sm" [(ngModel)]="agentDTO.phone_no"
                            [ngModelOptions]="{standalone: true}" required>
                        </div>
                        <div class="col-md-1"></div>
                        <label class="col-md-2 withdrawbalance" style = "display: none;"> Withdraw Balance </label>
                        <div class="form-group col-md-3" style = "display: none;">
                          <input type="text" class="form-control form-control-sm withdrawbalance" [(ngModel)]="agentDTO.withdraw_balance"
                            [ngModelOptions]="{standalone: true}" step="500" min="0" disabled>
                        </div>
                      </div>
  
                      <div class="row" id="password">
                        <label class="col-md-2"> Password </label>
                        <div class="input-group form-group col-md-3">
                          <input class="form-control form-control-sm" autocomplete="false" [(ngModel)]="agentDTO.password"
                            [ngModelOptions]="{standalone: true}" [type]="fieldTextType ? 'text' : 'password'">
                          <div class="input-group-append">
                            <span class="input-group-text">
                              <i class="fa" [ngClass]="{'fa-eye-slash': !fieldTextType,'fa-eye': fieldTextType}"
                                (click)="toggleFieldTextType()" style="color: #007bff;"></i>
                            </span>
                          </div>
                        </div>
                      </div>
  
                      <!--<div class="row" id="confirmPassword">
                        <label class="col-md-2"> Confirm Password </label>
                        <div class="form-group col-md-3">
                          <input type="password" class="form-control form-control-sm"
                            [(ngModel)]="agentDTO.confirmPassword" [ngModelOptions]="{standalone: true}">
                        </div>
                      </div>
   -->
                      <div class="row" id="confirmPassword">
                        <label class="col-md-2"> Confirm Password </label>
                        <div class="input-group form-group col-md-3">
                          <input class="form-control form-control-sm" [(ngModel)]="agentDTO.confirmPassword"
                            [ngModelOptions]="{standalone: true}" [type]="confrimFieldTextType ? 'text' : 'password'">
                          <div class="input-group-append">
                            <span class="input-group-text">
                              <i class="fa"
                                [ngClass]="{'fa-eye-slash': !confrimFieldTextType,'fa-eye': confrimFieldTextType}"
                                (click)="toggleConfrimFieldTextType()" style="color: #007bff;"></i>
                            </span>
                          </div>
                        </div>
                      </div>
  
                      <div class="row" id="referralCode">
                        <label class="col-md-2"> Referral Code </label>
                        <div class="form-group col-md-3">
                          <input disabled class="form-control form-control-sm" [(ngModel)]="agentDTO.referral_code"
                            [ngModelOptions]="{standalone: true}">
                        </div>
  
                        <div class="col-md-1"></div>
                        <label class="col-md-2 commbalance" style = "display: none;"> Commission Balance </label>
                        <div class="form-group col-md-3 commbalance" style = "display: none;">
                          <input type="text" autocomplete="false" class="form-control form-control-sm" [(ngModel)]="agentDTO.commission_balance_Str"
                            [ngModelOptions]="{standalone: true}" step="500" min="0" disabled>
                        </div>
                      </div>
  
                      <div class="row">
                        <label class="col-md-2"> Status </label>
                        <div class="form-group col-md-3 radio">
                          <div class="form-group clearfix">
                            <div class="icheck-success d-inline">
                              <input type="radio" name="r3" checked id="radioSuccess1" value="ACTIVE"
                                [(ngModel)]="agentDTO.status" [ngModelOptions]="{standalone: true}">
                              <label for="radioSuccess1"> Active &nbsp;&nbsp; </label>
                            </div>
                            <div class="icheck-success d-inline">
                              <input type="radio" name="r3" id="radioSuccess2" value="INACTIVE"
                                [(ngModel)]="agentDTO.status" [ngModelOptions]="{standalone: true}">
                              <label for="radioSuccess2"> Inactive </label>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-2 col-sm-6">
                          <button type="button" class="btn btn-block btn-success" (click)="goSave()">Save</button>&nbsp;
                        </div>
                        <div class="col-md-2 col-sm-6">
                          <button type="button" class="btn btn-block btn-danger"
                            [routerLink]="['/advertise-team-list']">Cancel</button>
                        </div>
                      </div>
                    </form>
                  </div>
                  <!-- /.card-body -->
                </div>
              </div>
  
            </div>
  
            <div class="row" id="updatedata">
              <div class="col-md-12">
                <div class="card">
                  <div class="card-body">
                    <form>
                      <div class="row" style="margin-top: 2%;">
                        <label class="col-md-2"> Created Date </label>
                        <div class="form-group col-md-3">
                          <input class="form-control form-control-sm" [(ngModel)]="agentDTO.created_date_Str"
                            [ngModelOptions]="{standalone: true}" disabled>
                        </div>
                        <div class="col-md-1"></div>
                        <label class="col-md-2"> Created By</label>
                        <div class="col-md-3">
                          <input class="form-control form-control-sm" [(ngModel)]="agentDTO.created_by_name"
                            [ngModelOptions]="{standalone: true}" disabled>
                        </div>
                      </div>
  
                      <div class="row" style="margin-top: 1%;">
                        <label class="col-md-2"> Updated Date </label>
                        <div class="form-group col-md-3">
                          <input class="form-control form-control-sm" [(ngModel)]="agentDTO.updated_date_Str"
                            [ngModelOptions]="{standalone: true}" disabled>
                        </div>
                        <div class="col-md-1"></div>
                        <label class="col-md-2"> Updated By </label>
                        <div class="col-md-3">
                          <input class="form-control form-control-sm" [(ngModel)]="agentDTO.updated_by_name"
                            [ngModelOptions]="{standalone: true}" disabled>
                        </div>
                      </div>
  
                    </form>
                  </div>
                  <!-- /.card-body -->
                </div>
              </div>
            </div>
  
          </form>
          <!-- /.row -->
  
          <!-- /.row -->
        </div>
        <!--/. container-fluid -->
      </section>
      <!-- /.content -->
    </div>
  
    <aside class="control-sidebar control-sidebar-dark">
      <!-- Control sidebar content goes here -->
    </aside>
  
    <app-footer></app-footer>
  
    <ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#fff" type="square-jelly-box">
      <p style="font-size: 16px; color: white">Loading...</p>
    </ngx-spinner>
  
  </div>
  
  
  <div id="deleteData" class="modal fade">
    <div class="modal-dialog">
        <div class="modal-content">
  
            <div class="modal-body">
                <form>
  
                    <div class="row" style="margin-top: 5%;margin-bottom: 3%;margin-left: 9%;">
                        <div class="form-group col-md-12">
                            <h5> This Account is used by another device. 
                                Please Sign Out. Login Again. </h5>
                        </div>
                    </div>
  
                    <div class="row" style="margin-left: 40%;margin-bottom: 3%;">
                        <div class="col-md-5 col-sm-6" id="deleteBtn">
                            <button type="button" class="btn btn-block btn-success" (click)="OkLogout()"> Ok </button>
                        </div>
                    </div>
                </form>
            </div>
  
        </div>
    </div>
  </div>