<div class="wrapper">
    <!-- Navbar -->
    <app-header-sidebar></app-header-sidebar>

    <!-- here here here -->
    <!-- Content Wrapper. Contains page content -->
    <div class="content-wrapper">
        <!-- Content Header (Page header) -->
        <div class="content-header">
            <div class="container-fluid">
                <!--  <div class="row mb-2">
                    <div class="col-sm-6">
                        <h1 class="m-0 text-dark"> Promotion and News </h1>
                    </div>
                </div> -->
                <!-- /.row -->
            </div><!-- /.container-fluid -->
        </div>
        <!-- /.content-header -->

        <!-- Main content -->
        <section class="content">
            <div class="container-fluid">
                <form>

                    <div class="row">
                        <div class="col-md-12">

                            <div class="card">
                                <div class="card-header">
                                    <h3 class="card-title" style="font-weight: bold;">Need Help Detail </h3>
                                </div>
                                <!-- /.card-header -->
                                <div class="card-body">
                                    <form>

                                        <div class="row">
                                            <label class="col-md-2"> Title (English)</label>
                                            <div class="form-group col-md-3">
                                                <textarea class="form-control form-control-sm"
                                                    [(ngModel)]="needHelpDTO.title_en"
                                                    [ngModelOptions]="{standalone: true}"></textarea>
                                            </div>
                                       
                                            <div class = "col-md-1"></div>
                                            <label class="col-md-2"> Title (Burmese)</label>
                                            <div class="form-group col-md-3">
                                                <textarea class="form-control form-control-sm"
                                                    [(ngModel)]="needHelpDTO.title_my"
                                                    [ngModelOptions]="{standalone: true}"></textarea>
                                            </div>
                                        </div>

                                        <div class="row">
                                            <label class="col-md-2"> Title (Thailand)</label>
                                            <div class="form-group col-md-3">
                                                <textarea class="form-control form-control-sm"
                                                    [(ngModel)]="needHelpDTO.title_th"
                                                    [ngModelOptions]="{standalone: true}"></textarea>
                                            </div>
                                            <div class = "col-md-1"></div>
                                            <label class="col-md-2"> Title (China)</label>
                                            <div class="form-group col-md-3">
                                                <textarea class="form-control form-control-sm"
                                                    [(ngModel)]="needHelpDTO.title_zh"
                                                    [ngModelOptions]="{standalone: true}"></textarea>
                                            </div>
                                        </div>

                                        <div class="row">
                                            <label class="col-md-2"> Description (English)</label>
                                            <div class="form-group col-md-3">
                                                <textarea class="form-control form-control-sm"
                                                    [(ngModel)]="needHelpDTO.description_en"
                                                    [ngModelOptions]="{standalone: true}"></textarea>
                                            </div>
                                       
                                            <div class = "col-md-1"></div>
                                            <label class="col-md-2"> Description (Burmese)</label>
                                            <div class="form-group col-md-3">
                                                <textarea class="form-control form-control-sm"
                                                    [(ngModel)]="needHelpDTO.description_my"
                                                    [ngModelOptions]="{standalone: true}"></textarea>
                                            </div>
                                        </div>

                                        <div class="row">
                                            <label class="col-md-2"> Description (Thailand)</label>
                                            <div class="form-group col-md-3">
                                                <textarea class="form-control form-control-sm"
                                                    [(ngModel)]="needHelpDTO.description_th"
                                                    [ngModelOptions]="{standalone: true}"></textarea>
                                            </div>
                                            <div class = "col-md-1"></div>
                                            <label class="col-md-2"> Description (China)</label>
                                            <div class="form-group col-md-3">
                                                <textarea class="form-control form-control-sm"
                                                    [(ngModel)]="needHelpDTO.description_zh"
                                                    [ngModelOptions]="{standalone: true}"></textarea>
                                            </div>
                                        </div>
                                                
                                                <div class="row">
                                                    <label class="col-md-2"> Website Link </label>
                                                    <div class="form-group col-md-3">
                                                        <textarea class="form-control form-control-sm"
                                                            [(ngModel)]="needHelpDTO.websiteLink"
                                                            [ngModelOptions]="{standalone: true}"></textarea>
                                                    </div>
                                                </div>

                                        <div class="row">
                                            <label class="col-md-2"> Status </label>
                                            <div class="form-group col-md-3 radio">
                                                <div class="form-group clearfix">
                                                    <div class="icheck-success d-inline">
                                                        <input type="radio" name="r3" checked id="radioSuccess1"
                                                            value="ACTIVE" [(ngModel)]="needHelpDTO.status"
                                                            [ngModelOptions]="{standalone: true}">
                                                        <label for="radioSuccess1">Active &nbsp;&nbsp;
                                                        </label>
                                                    </div>
                                                    <div class="icheck-success d-inline">
                                                        <input type="radio" name="r3" id="radioSuccess2"
                                                            value="INACTIVE" [(ngModel)]="needHelpDTO.status"
                                                            [ngModelOptions]="{standalone: true}">
                                                        <label for="radioSuccess2">Inactive
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="row" style="margin-top: 1%;">
                                            <div class="col-md-2 col-sm-6">
                                                <button type="button" class="btn btn-block btn-success"
                                                    (click)="goSave()">Save</button>&nbsp;</div>
                                            <div class="col-md-2 col-sm-6">
                                                <button type="button" class="btn btn-block btn-danger"
                                                    [routerLink]="['/needhelp']"> Cancel </button></div>
                                        </div>
                                    </form>

                                </div>
                            </div>
                        </div>

                    </div>
                      
                </form>
                <!-- /.row -->

                <!-- /.row -->
            </div>
            <!--/. container-fluid -->
        </section>
        <!-- /.content -->
    </div>

    <aside class="control-sidebar control-sidebar-dark">
        <!-- Control sidebar content goes here -->
    </aside>

    <app-footer></app-footer>

    <ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#fff" type="square-jelly-box">
        <p style="font-size: 16px; color: white">Loading...</p>
    </ngx-spinner>

</div>


<div id="deleteData" class="modal fade">
    <div class="modal-dialog">
        <div class="modal-content">

            <div class="modal-body">
                <form>

                    <div class="row" style="margin-top: 5%;margin-bottom: 3%;margin-left: 9%;">
                        <div class="form-group col-md-12">
                            <h5> This Account is used by another device. 
                                Please Sign Out. Login Again. </h5>
                        </div>
                    </div>

                    <div class="row" style="margin-left: 40%;margin-bottom: 3%;">
                        <div class="col-md-5 col-sm-6" id="deleteBtn">
                            <button type="button" class="btn btn-block btn-success" (click)="OkLogout()"> Ok </button>
                        </div>
                    </div>
                </form>
            </div>

        </div>
    </div>
</div>


