import { Component, OnInit } from '@angular/core';
@Component({
  selector: 'app-feedback-history',
  templateUrl: './feedback-history.component.html',
  styleUrls: ['./feedback-history.component.css']
})
export class FeedBackHistoryComponent implements OnInit {
 constructor() { 
  }

  ngOnInit(): void {
  }


}
