import { Component, OnInit, ViewChild } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, HttpErrorResponse } from '@angular/common/http';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { DataTableDirective } from 'angular-datatables';

import { catchError, retry } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { ToastrService } from 'ngx-toastr';

import { LocalStorageService } from 'ngx-webstorage';
import { NgxSpinnerService } from "ngx-spinner";
import { FunctService } from '../../service/funct.service';
import { DtoService } from '../../service/dto.service';
import { DatePipe } from '@angular/common'

import { Subject } from 'rxjs';
import { DateRange } from '@ngx-tiny/date-picker';
import { ThreeDLiveListComponent } from '../3D-live-setting/3D-live-setting.component';
declare var $: any;


@Component({
  selector: 'app-user-inactive-byadmin',
  templateUrl: './user-inactive-byadmin.component.html',
  styleUrls: ['./user-inactive-byadmin.component.css'],
  providers: [DatePipe]//amk
})

export class UserInactiveByAdminComponent implements OnInit {

  @ViewChild(DataTableDirective)
  dtElement: DataTableDirective;
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();

  phoneNo: string = '';
  name: string = '';
  referralCode: string = '';
  status: string = '';
  userList: any;
  idIndex: any;
  approvedate: any;
  approvetodayDate: any;
  approveDate: any;
  allchangedate: any;

  approvetodate: any;
  allchangetodate: any;
  approvetodayToDate: any;
  approvetoDate: any;

  config: any;
  collection = [];
  page = 1;
  passenger: any;
  itemsPerPage = 10;
  totalItems: any;
  itemsPerPageFront = 10;
  appVersionList: [];
  appVersion: any;

  newTotalItems: any;

  startDate: string;
  defaultTodayDate: string;
  isDateRange: Date;
  endDate: string;
  selectedPreset: string;
  isShowAll = false;
  isdate = false;
  isEnableSearch = true;


  constructor(private storage: LocalStorageService, private datepipe: DatePipe, private toastr: ToastrService, private spinner: NgxSpinnerService, private http: HttpClient, private dto: DtoService, private router: Router,
    private route: ActivatedRoute, private funct: FunctService,) {
    this.idIndex = 1;
    this.storage.store('isNotiSong', "");
    // this.approvedate = new Date();   
    // this.approvetodayDate = this.datepipe.transform(this.approvedate, 'MMM dd, yyyy');
    // this.approvetodate = new Date();
    // this.approvetodayToDate = this.datepipe.transform(this.approvetodate, 'MMM dd, yyyy');   


    this.getAllAppVersion();

    this.selectedPreset = "today";

    this.dto.isSearchMember = "d";
    this.dto.isGameTransaction = "d";
    this.dto.isForgotPassword = "d";
    this.dto.isAccMember = "d";
    this.dto.isUseSearch = false;

    this.search();

  }

  ngOnInit(): void {
    this.router.routeReuseStrategy.shouldReuseRoute = function () {
      return false;
    };

    this.router.events.subscribe((evt) => {
      if (evt instanceof NavigationEnd) {
        this.router.navigated = false;
        window.scrollTo(0, 0);
      }
    });

    this.dtOptions = {
      responsive: true,
      order: [],
      paging: false,
      info: true,
      dom: "Bfrltip",
    }

    this.dtOptions.columnDefs = [
      { targets: [12], orderable: true }

    ];

    if (!this.storage.retrieve('loadFlag')) {
      this.storage.store('loadFlag', 'noLoad');
      setTimeout(function () {
        location.reload();
      }, 5);
    }
    else {
      this.storage.clear('loadFlag');
    }
  }
  onInput(event: InputEvent) {
    const myanmarCharactersRegex = new RegExp('^[\u1000-\u109F\uAA60-\uAA7F\uA9E0-\uA9FF]+$');
    const inputValue = (event.target as HTMLInputElement).value;
    if (event.data != null) {
      if (myanmarCharactersRegex.test(inputValue)) {
        this.spinner.hide();
        this.toastr.error("Myanmar typing detected!", '', {
          timeOut: 5000,
          positionClass: 'toast-top-right',
        });
      }
    }

  }




  ngAfterViewInit() {
  }

  handleError(error: HttpErrorResponse) {
    if (error.status == 423) {
      this.spinner.hide();
      $("#deleteData").modal("show");
    }
    if (error.status == 403) {
      this.spinner.hide();
      var id = 'tblUser' + this.idIndex;
      var table = $('#' + id).DataTable();
      this.toastr.error("Limited Access.", 'Invalid!', {
        timeOut: 3000,
        positionClass: 'toast-top-right',
      });
    }
    return throwError(error);
  }
  OkLogout() {
    window.location.href = "./ad-login";
  }


  gty(page: any) {
    this.userList = [];
    var id = 'tblUser' + this.idIndex;
    var table = $('#' + id).DataTable();

    table.destroy();
    this.idIndex = this.idIndex + 1;
    this.spinner.show();
    let headers = new HttpHeaders();
    headers = headers.set('Authorization', this.dto.token);
    let params = new HttpParams();
    this.approveDate = $("#approveDate").val();
    this.approvetoDate = $("#approvetoDate").val();
    if (this.approveDate == '' || this.approveDate == undefined) {
      this.allchangedate = this.approvetodayDate;
    }
    else {
      this.allchangedate = this.approveDate;
    }

    if (this.approvetoDate == '' || this.approvetoDate == undefined) {
      this.allchangetodate = this.approvetodayToDate;
    }
    else {
      this.allchangetodate = this.approvetoDate;
    }
    this.status = "INACTIVE";

    if (this.approveDate == '' || this.approveDate == undefined) {
      params = params.set('app_version', this.appVersion).set('phoneNo', this.phoneNo).set('name', this.name).set('referralCode', this.referralCode).set('status', this.status).set("pageNumber",page).set("rowsOfPage",this.itemsPerPage.toString());;
      this.http.get(this.funct.ipaddress + 'user/inactive_by_admin_list', { params: params, headers: headers })
        .pipe(
          catchError(this.handleError.bind(this))
        )
        .subscribe(
          result => {
            this.dto.Response = {};
            this.dto.Response = result.results;
            this.userList = this.dto.Response;
            this.totalItems = result.totalRows;  
            this.dtTrigger.next();
            this.spinner.hide();

          }
        );
    }
    else {
      params = params.set('app_version', this.appVersion).set('phoneNo', this.phoneNo).set('name', this.name).set('referralCode', this.referralCode).set('status', this.status)
        .set('register_date', this.allchangedate).set('register_todate', this.allchangetodate).set("pageNumber",page).set("rowsOfPage",this.itemsPerPage.toString());;
      this.http.get(this.funct.ipaddress + 'user/inactive_by_admin_list', { params: params, headers: headers })
        .pipe(
          catchError(this.handleError.bind(this))
        )
        .subscribe(
          result => {
            this.dto.Response = {};
            this.dto.Response = result.results;
            this.userList = this.dto.Response;
            this.totalItems = result.totalRows;  
            this.dtTrigger.next();
            this.spinner.hide();

          }
        );

    }


  }

  getAllAppVersion() {
    this.dto.token = this.storage.retrieve('token');
    let headers = new HttpHeaders();
    headers = headers.set('Authorization', this.dto.token);
    let params = new HttpParams();
    this.http.get(this.funct.ipaddress + 'user/getAllAppVersion', { headers: headers }).subscribe(
      result => {
        this.dto.Response = {};
        this.dto.Response = result;
        this.appVersionList = this.dto.Response;
      }
    );
  }

  toggleCheckbox() {
    if (this.isShowAll) {
      this.isEnableSearch = false;
      const approveDateInput = document.getElementById('approveDate') as HTMLInputElement;
      const approvetoDateInput = document.getElementById('approvetoDate') as HTMLInputElement;

      if (approveDateInput && approvetoDateInput) {
        approveDateInput.value = '';
        approvetoDateInput.value = '';
      }
    }

  }

  search() {
    this.spinner.show();
    this.userList = [];
    var id = 'tblUser' + this.idIndex;
    var table = $('#' + id).DataTable();
    table.destroy();
    this.idIndex = this.idIndex + 1;
    this.dto.token = this.storage.retrieve('token');
    let headers = new HttpHeaders();
    headers = headers.set('Authorization', this.dto.token);
    let params = new HttpParams();
    this.approveDate = $("#approveDate").val();
    this.approvetoDate = $("#approvetoDate").val();  

    this.status = "INACTIVE";
    if (this.approveDate == '' || this.approveDate == undefined) {
      params = params.set('app_version', this.appVersion).set('phoneNo', this.phoneNo).set('name', this.name).set('referralCode', this.referralCode).set('status', this.status).set("pageNumber","1").set("rowsOfPage","10");
      this.http.get(this.funct.ipaddress + 'user/inactive_by_admin_list', { params: params, headers: headers })
        .pipe(
          catchError(this.handleError.bind(this))
        )
        .subscribe(
          result => {
            this.dto.Response = {};
            this.dto.Response = result.results;
            this.userList = this.dto.Response;
            this.totalItems = result.totalRows;  
            this.dtTrigger.next();
            this.spinner.hide();

          }
        );
    }
    else {

          if (this.approveDate == '' || this.approveDate == undefined) {
          this.allchangedate = this.approvetodayDate;
        }
        else {
          this.allchangedate = this.approveDate;
        }

        if (this.approvetoDate == '' || this.approvetoDate == undefined) {
          this.allchangetodate = this.approvetodayToDate;
        }
        else {
          this.allchangetodate = this.approvetoDate;
        }


      params = params.set('app_version', this.appVersion).set('phoneNo', this.phoneNo).set('name', this.name).set('referralCode', this.referralCode).set('status', this.status)
        .set('register_date', this.allchangedate).set('register_todate', this.allchangetodate).set("pageNumber","1").set("rowsOfPage","10");
      this.http.get(this.funct.ipaddress + 'user/inactive_by_admin_list', { params: params, headers: headers })
        .pipe(
          catchError(this.handleError.bind(this))
        )
        .subscribe(
          result => {
            this.dto.Response = {};
            this.dto.Response = result.results;
            this.userList = this.dto.Response;  
            this.totalItems = result.totalRows;         
            this.dtTrigger.next();
            this.spinner.hide();

          }
        );

    }


  }

  handleShowAll(checkbox) {

    var checkboxes = document.querySelectorAll('.row input[type="checkbox"]');

    checkboxes.forEach(function (box) {
      // box.checked = checkbox.checked;
      if (checkbox.checked) {
        box.setAttribute('checked', 'checked');
      } else {
        box.removeAttribute('checked');
      }
    });
  }


  onChangeApprove() {
    $(document).ready(function () {
      this.approveDate = $("#approveDate").val();

    });
  
   
  }

  onChangeApproveTo() {
    $(document).ready(function () {
      this.approvetoDate = $("#approvetoDate").val();

    });
  
   
  }

  isValidDate() {
    if ((this.approveDate != '' && this.approveDate != undefined)
      && (this.approvetoDate != '' && this.approvetoDate != undefined)) {
        this.isEnableSearch = false;
    }
  }

  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }

  pageChange(newPage: number) {
    this.router.navigate(['/user-inactive-list'], { queryParams: { page: newPage } });
  }


}
