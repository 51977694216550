import { Component, OnInit, ViewChild } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams ,HttpErrorResponse} from '@angular/common/http';
import { Router, NavigationEnd } from '@angular/router';
import { DataTableDirective } from 'angular-datatables';
import { ToastrService } from 'ngx-toastr';
import Responsive from 'datatables.net-responsive';
import { LocalStorageService } from 'ngx-webstorage';
import { NgxSpinnerService } from "ngx-spinner";

import { catchError, retry } from 'rxjs/operators';
import { throwError } from 'rxjs';

import { DtoService } from '../../service/dto.service';
import { FunctService } from '../../service/funct.service';

import { Subject } from 'rxjs';
declare var $: any;

@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.css']
})
export class AdminComponent implements OnInit {

  @ViewChild(DataTableDirective)
  dtElement: DataTableDirective;
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();

  phoneNo: string = '';
  name: string = '';
  referralCode: string = '';
  status: string = '';
  adminList: any;
  idIndex: any;
  hardcode_edit :any;
  hardcode_add :any;
  hardcode_view: any;
  hardcode_delete_bool : any;
  hardcode_add_bool : any;
  hardcode_edit_bool : any;
  hardcode_view_bool : any;

  constructor(private storage: LocalStorageService, private spinner: NgxSpinnerService,private toastr: ToastrService, private http: HttpClient, private dto: DtoService, private router: Router, private funct: FunctService) {
    this.storage.store('isNotiSong', "");
    this.idIndex = 1;
    this.status = 'ACTIVE';
    this.hardcode_view = "Admin_View";
    this.hardcode_edit = "Admin_ResetPwd";//Admin_Edit    
    this.hardcode_add = "Admin_Add";
    this.dto.menuCodes = this.storage.retrieve('menucodes');

    const filteredMenuCodes_view = [];
    const filteredMenuCodeds_add =[];
    const filteredMenuCodeds_edit =[];
    this.dto.menuCodes.forEach(menuCode => {
      if (menuCode === this.hardcode_view) {
        filteredMenuCodes_view.push(menuCode);
      }
      if (menuCode === this.hardcode_add) {
        filteredMenuCodeds_add.push(menuCode);
      }
      if (menuCode === this.hardcode_edit) {
        filteredMenuCodeds_edit.push(menuCode);
      }
    });
    if(filteredMenuCodes_view.length != 0)
    {
        this.hardcode_view_bool = true;
    }
    if(filteredMenuCodeds_add.length != 0)
    {
         this.hardcode_add_bool = true;
    }
    if(filteredMenuCodeds_edit.length != 0)
    {
         this.hardcode_edit_bool = true;
    }

    this.search();
    
  }

  ngOnInit(): void 
  {
    console.log("ngOnInit");
    this.router.routeReuseStrategy.shouldReuseRoute = function () {
      return false;
    };

    this.router.events.subscribe((evt) => {
      if (evt instanceof NavigationEnd) {    
        this.router.navigated = false;
        window.scrollTo(0, 0);
      }
    });

    this.dtOptions = 
    {
      responsive: 
      {
        details: {
            renderer: Responsive.renderer.listHiddenNodes()
        }
       },
      stateSave : true,
      order: [],
      paging: true,
      info:true,
      destroy : true     
    }

    // this.dtOptions = {
    //   responsive: {
    //     details: {
    //         renderer: Responsive.renderer.listHiddenNodes()
    //     }
    //   },
    //   order:[],
    //   paging: true,
    //   info : true,
    //   dom: "Bfrltip",
    // }
    this.dtOptions.columnDefs = [
      { targets: [5], orderable: false }//6
    ];

    if (!this.storage.retrieve('loadFlag')) {
      this.storage.store('loadFlag', 'noLoad');
      setTimeout(function () {
        location.reload();
      }, 5);
    }
    else {
      this.storage.clear('loadFlag');
    }
  }

  ngAfterViewInit() {
  }

  handleError(error: HttpErrorResponse){
    if(error.status == 423)
    {
      this.spinner.hide();
      $("#deleteData").modal("show");
    }
    if(error.status == 403)
    {
      this.spinner.hide();
      var id = 'tblAdmin' + this.idIndex;
      var table = $('#' + id).DataTable();
      this.toastr.error("Limited Access.", 'Invalid!', {
        timeOut: 3000,
        positionClass: 'toast-top-right',
        });
    }
    return throwError(error);
    }
    OkLogout()
    {
      window.location.href ="./ad-login";
    } 

  search() {
    this.adminList = [];
    var id = 'tblAdmin' + this.idIndex;
    var table = $('#' + id).DataTable();
    table.destroy();
    this.idIndex = this.idIndex + 1;
    this.spinner.show();
    this.dto.token = this.storage.retrieve('token');
    let headers = new HttpHeaders();
    console.log(this.dto.token);
    headers = headers.set('Authorization', this.dto.token).set("Roles","SUPER_ADMIN");
    let params = new HttpParams();
    params = params.set('phoneNo', this.phoneNo).set('name', this.name).set('referralCode', this.referralCode).set('status', this.status);
    this.http.get(this.funct.ipaddress + 'admin/adminsByparams', { params: params, headers: headers }).
    pipe(
      catchError(this.handleError.bind(this))
     )
    .subscribe(
      result => {
        this.dto.Response = {};       
        this.dto.Response = result;
        console.log("Ress" +this.dto.Response.status)
        this.adminList = this.dto.Response;
        this.dtTrigger.next();
        this.spinner.hide();
      
      }
    );
  }


  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }

  addStepAndNavigate(admin: any): void
  { 
   localStorage.setItem('adminData', JSON.stringify({
     id: admin.id,
     name: admin.name,
     phone_no: admin.phone_no,
     role_name: admin.role_name,
     role_id:admin.role_id,
     status :admin.status,
     updated_date_string:admin.updated_date_string,
     updated_by_name :admin.updated_date_string
   }));

   this.router.navigate(['/admin-detail', admin.id]);
   
 }
}