
       
      
               
                    <div class="row">             
                    <div class="form-group col-md-2">
                      <label> Type </label>            
                      <select  id = "type" class="form-control form-control-sm col-md-12 col-sm-4 col-xs-4"
                           [(ngModel)]="type" [ngModelOptions]="{standalone: true}">                         
                            <option *ngFor="let payment of paymentList" value = "{{payment.id}}" >
                              {{payment.name}}
                            </option>
                            <option value="0" selected>All</option>
                         </select>
                  </div> 


                        <div class="form-group col-md-2">
                            <label> Question </label>
                            <input class="form-control form-control-sm" [(ngModel)]="question"
                                [ngModelOptions]="{standalone: true}">
                        </div>


                        <div class="form-group col-md-2">
                            <label></label>
                            <button id="search" type="button" class="btn btn-block btn-primary btn-sm"
                                style="margin-top: 4%;" (click)="search()"> Search </button>
                        </div>

                        <div class="form-group col-md-2" *ngIf="hardcode_add_bool === true">
                            <label></label>
                            <button type="button" class="btn btn-block btn-success btn-sm" style="margin-top: 4%;"
                            (click)="showAddTranModelNew()">
                            <b> Add New </b>
                          </button>
                        </div>

                        <div class="col-md-12">                         
                            <div class="card">
                                <div class="card-header">
                                    <h3 class="card-title" style="font-weight: bold;"> FAQ List </h3>
                                </div>
                              
                                <div class="card-body"  >
                                  <!-- style = "width : 100%" -->
                                    <table width='100%' id="{{'tblFeedback' + idIndex}}" datatable [dtTrigger]="dtTrigger"
                                        [dtOptions]="dtOptions" class="table table-bordered table-hover">
                                        <thead>
                                            <tr>
                                                <th style="font-family: unicode; width:100px;">Type </th>                                           
                                                <th style="font-family: unicode; width:100px; ">Order Number</th>
                                                <th style="font-family: unicode; width:120px; ">Question</th>
                                                <th style="font-family: unicode; width:120px;">Answer</th>                                          
                                                <th style="font-family: unicode; width:100px;">Has Guide?</th>
                                                <th style="font-family: unicode;width:100px; ">Created Date</th>
                                                <th style="font-family: unicode; width:100px;">Created By</th>
                                                <th style="font-family: unicode; width:100px;" >Updated Date</th>                                               
                                                <th style="font-family: unicode; width:100px;" >Updated By</th>
                                                <th style="font-family: unicode; width:100px;" *ngIf="hardcode_double === true">Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr *ngFor="let feed of feedBack">
                                                <td style="font-family: unicode; ">{{feed.type_name}}</td>                                               
                                                <td style="font-family: unicode; ">{{feed.orderNum}}</td>
                                                <td style="font-family: unicode; ">{{feed.question}}</td>
                                                <td style="font-family: unicode; ">{{feed.answer}}</td>
                                                <td style="font-family: unicode; ">{{feed.hasGuide}}</td>
                                                <td style="font-family: unicode; ">{{feed.created_date_Str}}</td>
                                                <td style="font-family: unicode; ">{{feed.created_by_name}}</td>
                                                <td style="font-family: unicode; ">{{feed.updated_time_Str}}</td>
                                                <td style="font-family: unicode; ">{{feed.updated_by_name}}</td>                                              
                              
                                              <td style="font-family: unicode; " *ngIf="hardcode_double === true" class="ng-star-inserted">
                                                <a  *ngIf="hardcode_edit_bool === true" (click)="!isTyping ? showEditRolesModel(feed.id ,isTyping) : null">
                                                  <i class="fas fa-edit" style="color: #4b88eb;">
                                                  </i>
                                                </a> 
                                                &nbsp;&nbsp;

                                                <a  *ngIf="hardcode_delete_bool === true"  (click)="delete(feed.id)">
                                                  <i class="fa fa-trash" style="color: red;">
                                                  </i>
                                                </a>
                                                &nbsp;&nbsp;
                                    

                                              <a *ngIf="hardcode_edit_bool === true" 
                                              title="Add Steps" 
                                              (click)="addStepAndNavigate(feed)"
                                              style="cursor: pointer;">
                                                <i class="fa fa-plus" style="color: #4b88eb;"></i>
                                            </a>


                                              </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <tr *ngFor="let feed of feedBack | paginate
                                    : {
                                        itemsPerPage: itemsPerPage,
                                        currentPage: page,
                                        totalItems: totalItems
                                      }" 
                                      >
                                  </tr> 
                                <div class = "row">
                                  <div class = "col-md-6"></div>
                                  <div class = "col-md-6">
                                    <pagination-controls
                                      class="pagi"
                                      (pageChange)="gty((page = $event))"
                                    ></pagination-controls>
                                </div>
                                </div>



                                </div>
                             
                            </div>
                        </div>

                    </div>
            
           
   

    <aside class="control-sidebar control-sidebar-dark">
      
    </aside>


    <app-footer></app-footer>
    <ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#fff" type="square-jelly-box">
        <p style="font-size: 16px; color: white">Loading...</p>
    </ngx-spinner>

  
 



<div id="browseAccountData9" class="modal fade">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" style="font-weight: bold;font-size: 1rem;"> Edit FAQ Type </h5>
        <button type="button" class="close" data-dismiss="modal" aria-hidden="true"> x </button>
      </div>

      <div class="modal-body">
        <form>
          <div class="col-md-12">
            <div class="col-md-12">
         

                <div class="card-body">

                  <div class="form-group">
                    <label for="faqsType">Type</label>
                    <select  id = "faqsType" class="form-control form-control-sm col-md-12 col-sm-4 col-xs-4"
                    [(ngModel)]="faqType" [ngModelOptions]="{standalone: true}">
                     <option *ngFor="let payment of paymentList" value = "{{payment.id}}" >
                       {{payment.name}}
                     </option>
                  </select>
                  </div>
    
                  <div class="form-group">
                    <label>Question</label>             
                    <textarea placeholder="Enter question.." class="form-control form-control-sm" [(ngModel)]="faqquestion" [ngModelOptions]="{standalone: true}"></textarea>
                  </div>
    
                  <div class="form-group">
                    <label>Answer</label>
                    <textarea placeholder="Enter answer.." class="form-control form-control-sm" [(ngModel)]="answer" [ngModelOptions]="{standalone: true}"></textarea>
                  </div>
    
                  <div class="form-group">
                    <label for="has">Has Guide?</label>
                    <select class="form-control form-control-sm" id="has" [(ngModel)]="has" [ngModelOptions]="{standalone: true}">
                      <option value=true>Yes</option>
                      <option value=false>No</option>
                     
                    </select>
                  </div>
                  <div class="form-group">
                    <label>Order Number</label>
                    <div class="input-group">
                        
                        <input type="number" class="form-control form-control-sm" [(ngModel)]="orderNumber" [ngModelOptions]="{standalone: true}" />
                       
                    </div>
                </div>
              </div>



            </div>
          </div>
        </form>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-primary btn-sm"
        (click)="close()">Cancel</button>
        <button type="button" class="btn btn-primary btn-sm"
        (click)="addTran(adsDTO.id,faqType,faqquestion,answer,has,orderNumber)">Save Changes</button>
      </div>
    </div>
  </div>
</div>  


<div id="browseAccountData10" class="modal fade">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <button type="button" class="close" data-dismiss="modal" aria-hidden="true"> x </button>
      </div>
      <div class="modal-body">
        <form>
          <div class="col-md-12">
            <div class="card-body">

              <div class="form-group">
                <label for="faqsType">Type</label>
                <select  id = "faqsType" class="form-control form-control-sm col-md-12 col-sm-4 col-xs-4"
                [(ngModel)]="faqsType" [ngModelOptions]="{standalone: true}">
                 <option *ngFor="let payment of paymentList" value = "{{payment.id}}" >
                   {{payment.name}}
                 </option>
              </select>
              </div>

              <div class="form-group">
                <label>Question</label>             
                <textarea placeholder="Enter question.." class="form-control form-control-sm" [(ngModel)]="faqquestions" [ngModelOptions]="{standalone: true}"></textarea>
              </div>

              <div class="form-group">
                <label>Answer</label>
                <textarea placeholder="Enter answer.." class="form-control form-control-sm" [(ngModel)]="answers" [ngModelOptions]="{standalone: true}"></textarea>
              </div>

              <div class="form-group">
                <label for="hass">Has Guide?</label>
                <select class="form-control form-control-sm" id="hass" [(ngModel)]="hass" [ngModelOptions]="{standalone: true}">
                  <option value=true>Yes</option>
                  <option value=false>No</option>
                 
                </select>
              </div>
              <div class="form-group">
                <label>Order Number</label>
                <div class="input-group">
                    
                    <input type="number" class="form-control form-control-sm" [(ngModel)]="orderNumbers" [ngModelOptions]="{standalone: true}" />
                   
                </div>
            </div>
            

            </div>
          </div>
        </form>
      </div>

      <div class="modal-footer">   
        <button type="button" class="btn btn-primary btn-sm" (click)="close()">Close</button>
        <button type="button" class="btn btn-primary btn-sm" (click)="saveNewRole(faqsType,faqquestions,answers,hass,orderNumbers)">Save</button>
      </div>

    </div>
  </div>
</div>



<div id="deleteData" class="modal fade">
  <div class="modal-dialog">
      <div class="modal-content">

          <div class="modal-body">
              <form>

                  <div class="row" style="margin-top: 5%;margin-bottom: 3%;margin-left: 9%;">
                      <div class="form-group col-md-12" style="text-align: center;">
                          <h3 class="text-center"> Are you sure want to delete? </h3>
                      </div>
                  </div>

                  <div class="row" style="margin-left: 10%;margin-bottom: 3%;">                     

                      <div class="col-md-5 col-sm-6">
                          <button type="button" class="btn btn-block btn-danger" (click) = "closeTran()" [routerLink] = "['/game-transaction-list']"> Cancel </button>
                      </div>
                      <div class="col-md-5 col-sm-6" id="deleteBtn">
                        <button type="button" class="btn btn-block btn-success" (click)="deleteByTransactionNumber()"> Confirm </button>
                    </div>
                  </div>

              </form>
          </div>

      </div>
  </div>
</div>




<div id="anotherlogin" class="modal fade">
  <div class="modal-dialog">
      <div class="modal-content">

          <div class="modal-body">
              <form>

                  <div class="row" style="margin-top: 5%;margin-bottom: 3%;margin-left: 9%;">
                      <div class="form-group col-md-12">
                          <h5> This Account is used by another device. 
                              Please Sign Out. Login Again. </h5>
                      </div>
                  </div>

                  <div class="row" style="margin-left: 40%;margin-bottom: 3%;">
                      <div class="col-md-5 col-sm-6" id="deleteBtn">
                          <button type="button" class="btn btn-block btn-success" (click)="OkLogout()"> Ok </button>
                      </div>
                  </div>
              </form>
          </div>

      </div>
  </div>
</div>


