
import { DataTableDirective } from 'angular-datatables';
import { DatePipe } from '@angular/common'
import { Subject } from 'rxjs';
import { Component, OnInit ,ViewChild} from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams ,HttpErrorResponse } from '@angular/common/http';
import { Router, ActivatedRoute } from '@angular/router';

import { LocalStorageService } from 'ngx-webstorage';
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from 'ngx-toastr';
import { IMultiSelectOption } from 'ngx-bootstrap-multiselect';

import { catchError, retry } from 'rxjs/operators';
import { throwError } from 'rxjs';

import { DtoService } from '../../service/dto.service';
import { FunctService } from '../../service/funct.service';
import { ThrowStmt } from '@angular/compiler';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
declare var $: any;

@Component({
  selector: 'app-payment-info-new-history',
  templateUrl: './payment-info-new-history.component.html',
  styleUrls: ['./payment-info-new-history.component.css']
})
export class PaymentInfoNewHistoryComponent implements OnInit {

  @ViewChild(DataTableDirective)
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();

  dtOptions1: DataTables.Settings = {};
  dtTrigger1: Subject<any> = new Subject();

  dtOptions2: DataTables.Settings = {};
  dtTrigger2: Subject<any> = new Subject();

  dtOptions3: DataTables.Settings = {};
  dtTrigger3: Subject<any> = new Subject();

  // amk topup   
  paymentDTO: any;
  paymentDTOWithdrawal:any
  token: any;
  paymentdetailId: any;
  paymentId : any;
  paymentwithdrawalId:any;
  imagePath: any;
  imgURL: any;
  message: string = '';
  paymentList : any;
  clickId = [] ;
  clickwithdrawalId =[];
  adminList = [];
  adminListWithdrawal = [];

  myOptions: IMultiSelectOption[];
  myOptionsWithdrawal: IMultiSelectOption[];
  myUpdOptions: IMultiSelectOption[];
  optionsModel: number[];
  optionsModelWithdrawal:number[];
  adminIdEditList = [];
  cashInList =[];
  cashIdid : any;
  cadmin : any;
  status:any;
  isShowAll:any;
  filePath : any;

  hardcode_edit :any;
  hardcode_add :any;
  hardcode_view: any;
  hardcode_add_Account_Number:any;
  hardcode_add_Account_Name:any;
  hardcode_add_Code_Number:any;
  hardcode_view_bool : any;
  hardcode_add_bool : any;
  hardcode_edit_bool : any;
  originalAdminDTO:any;
  paymenttype_name:any;
  paymenttype_newname :any;
  type:any;

  paymentListwithdrawal:any;

  constructor(private storage: LocalStorageService,private spinner: NgxSpinnerService, private dto: DtoService, private http: HttpClient, private router: Router,
    private route: ActivatedRoute, private funct: FunctService,private toastr: ToastrService, private datepipe: DatePipe) 
    { 
      this.storage.store('isNotiSong', "");
      this.type ="";
    this.hardcode_view = "BankAccount_View";
    this.hardcode_edit = "BankAccount_Edit";
    this.hardcode_add = "BankAccount_Add";
    this.dto.menuCodes = this.storage.retrieve('menucodes');

    const filteredMenuCodes_view = [];
    const filteredMenuCodeds_add =[];
    const filteredMenuCodeds_edit =[];
    this.dto.menuCodes.forEach(menuCode => {
      if (menuCode === this.hardcode_view) {
        filteredMenuCodes_view.push(menuCode);
      }
      if (menuCode === this.hardcode_add) {
        filteredMenuCodeds_add.push(menuCode);
      }
      if (menuCode === this.hardcode_edit) {
        filteredMenuCodeds_edit.push(menuCode);
      }
    });
    if(filteredMenuCodes_view.length != 0)
    {
         this.hardcode_view_bool = true;
    }
    if(filteredMenuCodeds_add.length != 0)
    {
         this.hardcode_add_bool = true;
    }
    if(filteredMenuCodeds_edit.length != 0)
    {
         this.hardcode_edit_bool = true;
    }
      //this.getAssignedPayments();
      // if(this.type =="topup" || this.type =='TOPUP')
      // {
      //   this.getAssignedPayments();
      // }
      // else
      // {
      //   this.getAssignedPaymentsForWithdrawal();
      // }
      // this.getAllAdmins();

  }

  ngOnInit(): void 
  {  
    

    if (!this.storage.retrieve('loadFlag')) {
      this.storage.store('loadFlag', 'noLoad');
      setTimeout(function () {
        location.reload();
      }, 1);
    }
        else {
          this.storage.clear('loadFlag');
        }
      this.cadmin ={
        id: 0,
        admin_id :0,
        payment_detail_id : 0,
        amount : 0,
        admin_name :''
      };

      // const adminDataString = localStorage.getItem('paymentData');
      // if(adminDataString !== null || adminDataString !=="null")
      // {
      //   this.originalAdminDTO = JSON.parse(adminDataString);
      //   this.type = this.originalAdminDTO.type ;
      // }
      // else 
      // {
      //    this.type ="";
      // }

      // let adminDataString = localStorage.getItem('paymentData');

      // if (adminDataString === "null") {
      //   adminDataString = null;
      // }

      // if (adminDataString !== null) {
      //   this.originalAdminDTO = JSON.parse(adminDataString);
      //   this.type = this.originalAdminDTO.type;
      // } else {
      //   this.type = "";
      // }


      this.paymentdetailId = this.route.snapshot.paramMap.get("id");

      if (this.paymentdetailId == null) {
        $(document).ready(function () {
          $('#updatedata').hide();
          $("#updateAdmin").hide();
          $('#updatedataWithdrawal').hide();
          $("#updateAdminWithdrawal").hide();
         
        });
     
        this.paymentDTO = {
          id: 0,
          accountNo: "",
          order_id : 0,
          type: '',
          status: 'ACTIVE',
          image: '',
          createdDate: '',
          createdBy: '',
          updatedDate: '',
          updatedBy: '',
          min_amount:0,
         max_amount:0,
         maxAccountCount:0,
        limited_amt :0,

        today_reach_amt:0,
  
        handle_admin:'',
        imageUrl:'',
        isShowAll:0,
        };

        this.paymentDTOWithdrawal = {
          id: 0,
          accountNo: "",
          order_id : 0,
          type: '',
          status: 'ACTIVE',
          image: '',
          createdDate: '',
          createdBy: '',
          updatedDate: '',
          updatedBy: '',
          min_amount:0,
         max_amount:0,
         maxAccountCount:0,
        limited_amt :0,

        today_reach_amt:0,
  
        handle_admin:'',
        imageUrl:'',
        isShowAll:0,
        };
      }
      else 
      {
        $(document).ready(function () 
        {
            $("#insertAdmin").hide();
            $("#updateAdmin").hide();
            $("#insertAdminWithdrawal").hide();
            $("#updateAdminWithdrawal").hide();
        });
        this.paymentDTO = {
          id: 0,
          accountNo: 0,
          type: "",
          status: "",
          order_id : 0,
          image: "",
          createdDate: "",
          createdBy: "",
          updatedDate: "",
          updatedBy: "",
          min_amount:0,
          max_amount:0,
          maxAccountCount:0,
          limited_amt :0,   
         today_reach_amt:0,   
         handle_admin:'',
         imageUrl:'',
         isShowAll:0,
        };

        this.paymentDTOWithdrawal = {
          id: 0,
          accountNo: 0,
          type: "",
          status: "",
          order_id : 0,
          image: "",
          createdDate: "",
          createdBy: "",
          updatedDate: "",
          updatedBy: "",
          min_amount:0,
          max_amount:0,
          maxAccountCount:0,
          limited_amt :0,   
         today_reach_amt:0,   
         handle_admin:'',
         imageUrl:'',
         isShowAll:0,
        };
      }
      //this.getPaymentById();
      if(this.type =="" || this.type=='TOPUP')
      {
        this.getPaymentById();
        
      }
      else
      {
        this.getPaymentByIdWithdrawal();
      }
      $('#updatedata').hide();
      $("#updateAdmin").hide();
      $('#updatedataWithdrawal').hide();
      $("#updateAdminWithdrawal").hide();

      //amk added 
      
      if(this.type =='TOPUP' || this.type =="")
      {        
        this.getAssignedPayments();
        this.getAllAdmins();
      }
      else
      {   
        this.getAssignedPaymentsForWithdrawal();
        this.getAllAdminsWithWithdrawal();
      }
      //this.getAllAdmins();

      if(this.type == "" || this.type =='TOPUP')
      {
        $('#topupdetail-tab').tab('show');
      }
      else{
        $('#withdrawaldetail-tab').tab('show');
      }
    
  }


  goToAdminDetail(): void 
  { 
    this.getAssignedPayments();  
    this.getAllAdmins();
    this.type ="TOPUP";
    $('#topupdetail-tab').tab('show');
  }

  resettab(): void 
  {
    this.getAssignedPaymentsForWithdrawal();
    this.getAllAdminsWithWithdrawal();
    this.type ="WITHDRAWAL";
    setTimeout(() =>
    {
      $('#withdrawaldetail-tab').tab('show');
    }, 0);
  }

  getAllAdminsWithWithdrawal()
  {
    this.adminList =[];
    this.dto.token = this.storage.retrieve('token');
    let headers = new HttpHeaders();
    headers = headers.set('Authorization',  this.dto.token );
    this.http.get(this.funct.ipaddress + 'admin/GetWithdrawalAdmin', { headers: headers }).subscribe(
      result => {
        this.dto.Response = {};
        this.dto.Response = result;      
        this.adminList = this.dto.Response;
        this.myOptions = this.adminList;
      }
    );
  }
  
  getAllAdmins()
  {
    this.adminList =[];
    this.token = this.storage.retrieve('token');
    let headers = new HttpHeaders();
    headers = headers.set('Authorization',  this.token);
    this.http.get(this.funct.ipaddress + 'admin/GetAllAdmin', { headers: headers }).subscribe(
      result => {
        this.dto.Response = {};
        this.dto.Response = result;
        this.adminList = this.dto.Response;
        this.myOptions = this.adminList;
      }
    );
  }
  
  handleError(error: HttpErrorResponse){
    this.spinner.hide();
    if(error.status == 423)
    {
      this.spinner.hide();
      this.toastr.error("Transaction is Locked.", 'Invalid!', {
        timeOut: 3000,
        positionClass: 'toast-top-right',
        });
    }
    if(error.status == 403)
    {
      this.spinner.hide();
      this.toastr.error("Limited Access.", 'Invalid!', {
        timeOut: 3000,
        positionClass: 'toast-top-right',
        });
    }
    return throwError(error);
    }
    OkLogout()
    {
      window.location.href ="./ad-login";
    } 

  getAssignedPayments() 
  {
    this.paymentList = [];
    this.token = this.storage.retrieve('token');
    let headers = new HttpHeaders();
    headers = headers.set('Authorization',  this.token);
    let params = new HttpParams();
    params = params.set('type', 'TOPUP');
    this.http.get(this.funct.ipaddress + 'payment/topuplistPayment', { params: params, headers: headers })
    .pipe(
      catchError(this.handleError.bind(this))
     )
    .subscribe(
      result => {
        this.dto.Response = {};
        this.dto.Response = result;
        this.paymentList = this.dto.Response;
      }
    );
  }

  getPaymentById() 
  {
    this.token = this.storage.retrieve('token');
    let headers = new HttpHeaders();
    headers = headers.set('Authorization', this.token);
    let params = new HttpParams();
    params = params.set('id', this.paymentdetailId);
    this.adminIdEditList = [];
    this.spinner.show();
    this.http.get(this.funct.ipaddress + 'payment/GetPaymentDetaiList', { params: params, headers: headers })
    .pipe(
      catchError(this.handleError.bind(this))
     )
    .subscribe(
      result => {
        this.dto.Response = {};
        this.dto.Response = result;
        this.paymentDTO = this.dto.Response;
          this.paymentId = this.paymentDTO.payment_id;
        
          this.originalAdminDTO.isShowAll = this.paymentDTO.isShowAll;
          if(this.originalAdminDTO.isShowAll == '1')
          {
            this.originalAdminDTO.descrition = "Only Account Number";
          }
          else if(this.originalAdminDTO.isShowAll == '2')
          {
            this.originalAdminDTO.descrition = "Only QR Code Image";
          }
          else if(this.originalAdminDTO.isShowAll == '3')
          {
            this.originalAdminDTO.descrition = "Both";
          }  
          this.originalAdminDTO.min_amount = this.paymentDTO.min_amount; 
          this.originalAdminDTO.paymentId = this.paymentDTO.payment_id; 
          this.originalAdminDTO.account_no = this.paymentDTO.account_no;
          this.originalAdminDTO.account_name = this.paymentDTO.account_name;
          this.originalAdminDTO.code_number = this.paymentDTO.code_number;
          this.originalAdminDTO.max_amount = this.paymentDTO.max_amount;
          this.originalAdminDTO.limited_amt= this.paymentDTO.limited_amt;
          this.originalAdminDTO.maxAccountCount = this.paymentDTO.maxAccountCount;
          if(this.paymentDTO.role_name == "ACCOUNTANT_MANAGER")
          {
            $('#idcodenumber').prop('disabled', true);
            $('#idaccno').prop('disabled', true);
            $('#idaccname').prop('disabled', true);

            
            $('#idcodenumberwithdrawal').prop('disabled', true);
            $('#idaccnowithdrawal').prop('disabled', true);
            $('#idaccnamewithdrawal').prop('disabled', true);
          }         
          this.http.get(this.funct.ipaddress + 'admin/GetAllAdmin', { headers: headers })
            .pipe(
              catchError(this.handleError.bind(this))
            )
            .subscribe(
              result1 => {
                this.dto.Response1 = {};
                this.dto.Response1 = result1;
                this.adminList = this.dto.Response1;
                this.myOptions = this.adminList;
              }
            );       
          if(this.adminList == null || this.adminList.length == 0)
          {
            this.adminList = JSON.parse(localStorage.getItem("adminList"));
          }
          if(this.paymentDTO.adminIdList == 0) 
          {
            $("#insertAdmin").show();
            $("#updateAdmin").hide();

            $("#insertAdminWithdrawal").show();
            $("#updateAdminWithdrawal").hide();
          }
        else{
              $('#updateAdmin').show();
              $("#insertAdmin").hide();
              $('#updateAdminWithdrawal').show();
              $("#insertAdminWithdrawal").hide();
          }
          this.paymentDTO.adminIdList.forEach(element => 
            {
            this.cashInList.push(element);
          });

          for( var i=this.adminList.length - 1; i>=0; i--)
          {
            for( var j=0; j<this.paymentDTO.adminIdList.length; j++)
            { 
                if(this.adminList[i] && (this.adminList[i].id === this.paymentDTO.adminIdList[j].admin_id))
                {
                  this.adminList.splice(i, 1);
                }
            }
        }        
          this.myUpdOptions = this.adminList;
          $("#"+this.paymentId).css('filter','grayscale(0%)');
          $("#"+this.paymentwithdrawalId).css('filter','grayscale(0%)');
         
      });
      this.spinner.hide();
  }

  preview(files) {
    if (files.length === 0)
      return;
    var mimeType = files[0].type;
    if (mimeType.match(/image\/*/) == null) {
      this.message = "Only images are supported.";
      return;
    }
    var reader = new FileReader();
    this.imagePath = files;
    this.filePath = files[0];
    reader.readAsDataURL(files[0]);
    reader.onload = (_event) => {
      this.paymentDTO.imageUrl = reader.result;
      console.log("imgURL>>>" + JSON.stringify(this.paymentDTO.imageUrl));
    }
  }
  goSave() {
    if (this.paymentdetailId == null) {
      this.save();
    }
    else {
      this.edit();
    }
  }
  onChange() {
    console.log("in onchange "+ this.optionsModel);
 }  onChangeWithdrawal() {
  console.log("in onchange "+ this.optionsModel);
}
   save() 
   {

    if( this.paymentDTO.account_no == "" || this.paymentDTO.account_no  == undefined ||  this.paymentDTO.account_no  == null)
    {
      this.toastr.error("Please enter account no", 'Invalid!', {
        timeOut: 3000,
        positionClass: 'toast-top-right',
      });
      return;     
   }  

   if( this.paymentDTO.account_name == "" || this.paymentDTO.account_name  == undefined ||  this.paymentDTO.account_name  == null)
   {
     this.toastr.error("Please enter account name", 'Invalid!', {
       timeOut: 3000,
       positionClass: 'toast-top-right',
     });
     return;     
  }  

  if( this.paymentDTO.code_number == "" || this.paymentDTO.code_number  == undefined ||  this.paymentDTO.code_number  == null)
  {
    this.toastr.error("Please enter code number", 'Invalid!', {
      timeOut: 3000,
      positionClass: 'toast-top-right',
    });
    return;     
 }  

 if(this.optionsModel.length > 0)
 {
  this.paymentDTO.handle_admin = this.optionsModel.toString();
 }
if( this.paymentDTO.handle_admin.length == 0 || this.paymentDTO.handle_admin == "" || this.paymentDTO.handle_admin  == undefined ||  this.paymentDTO.handle_admin  == null)
 {
   this.toastr.error("Please choose account holder (Admin)", 'Invalid!', {
     timeOut: 3000,
     positionClass: 'toast-top-right',
   });
   return;
 }

        this.paymentDTO.isShowAll = this.paymentDTO.isShowAll;
           if(this.paymentDTO.isShowAll == "" || this.paymentDTO.isShowAll == undefined)
           {
             this.toastr.error("Please Choose Display Option", 'Invalid!', {
               timeOut: 3000,
               positionClass: 'toast-top-right',
             });
             return;
     
           }
           if( this.paymentDTO.isShowAll == "2" ||  this.paymentDTO.isShowAll == "3")
           {
               if(this.paymentDTO.imageUrl == undefined || this.paymentDTO.imageUrl == "" || this.paymentDTO.imageUrl == "")
               {
                 this.toastr.error("Please add qr image", 'Invalid!', {
                   timeOut: 3000,
                   positionClass: 'toast-top-right',
                 });
                 return;
               }
           }


    let mobNumberPattern =/[0-9]/g;
    this.paymentDTO.payment_id = this.paymentId;

  //   if( this.paymentDTO.max_amount == "" || this.paymentDTO.max_amount  == undefined ||  this.paymentDTO.max_amount  == null)
  //   {
  //     this.toastr.error("Please enter Maximum Amount", 'Invalid!', {
  //       timeOut: 3000,
  //       positionClass: 'toast-top-right',
  //     });
  //     return;     
  //  }  

  //  if( this.paymentDTO.min_amount == "" || this.paymentDTO.min_amount  == undefined ||  this.paymentDTO.min_amount  == null)
  //  {
  //    this.toastr.error("Please enter Minimum  Amount", 'Invalid!', {
  //      timeOut: 3000,
  //      positionClass: 'toast-top-right',
  //    });
  //    return;     
  // } 

    if(this.paymentDTO.max_amount < this.paymentDTO.min_amount)
    {
       this.toastr.error("Maximum amount must be greather than minimum amount", 'Invalid!', {
         timeOut: 3000,
         positionClass: 'toast-top-right',
       });
       return;
    }
    if(this.paymentDTO.limited_amt == 0 || this.paymentDTO.limited_amt == undefined)
    {
       this.toastr.error("Limited amount must not be zero", 'Invalid!', {
         timeOut: 3000,
         positionClass: 'toast-top-right',
       });
       return;
    }

    if(this.paymentDTO.maxAccountCount == 0 || this.paymentDTO.maxAccountCount == undefined)
    {
       this.toastr.error("Please choose Max Account Count", 'Invalid!', {
         timeOut: 3000,
         positionClass: 'toast-top-right',
       });
       return;
    }
   
    if( this.paymentDTO.payment_id == "" || this.paymentDTO.payment_id  == undefined ||  this.paymentDTO.payment_id  == null)
    {
       this.toastr.error("Please choose bank type", 'Invalid!', {
         timeOut: 3000,
         positionClass: 'toast-top-right',
       });
       return;
    }
         

   if (!mobNumberPattern.test(this.paymentDTO.account_no))
   {
     this.toastr.error("Account number must be number", 'Invalid!', {
       timeOut: 3000,
       positionClass: 'toast-top-right',
     });
     return;
   }
            
           
   //amk
   if (this.paymentDTO.imageUrl === null || this.paymentDTO.imageUrl  === ''  || this.paymentDTO.imageUrl  === undefined) 
   {      
        this.paymentDTO.imageUrl = this.paymentDTO.imageUrl;
   }   
   else
   {
       if(this.paymentDTO.imageUrl .includes('data:image/jpeg;base64,'))
       this.paymentDTO.imageUrl = this.paymentDTO.imageUrl.replace("data:image/jpeg;base64,","");
       if(this.paymentDTO.imageUrl .includes('data:image/png;base64,'))
       this.paymentDTO.imageUrl = this.paymentDTO.imageUrl.replace("data:image/png;base64,","");
      
   }         
          
      this.spinner.show();
    if (mobNumberPattern.test(this.paymentDTO.account_no)) {
          this.token = this.storage.retrieve('token');
          let headers = new HttpHeaders();
          headers = headers.set('Authorization', this.token);
          this.http.post(this.funct.ipaddress + 'payment/insertPaymentDetail', this.paymentDTO, { headers: headers })
          .pipe(
            catchError(this.handleError.bind(this))
           )
          .subscribe( 
            result => {
              this.dto.Response = {};
              this.dto.Response = result;
              if (this.dto.Response.status == 'Success') {
                this.spinner.hide();
                this.router.navigate(['/payment-info']).then(() => {
                  this.toastr.success(this.dto.Response.message, 'Success!', {
                    timeOut: 3000,
                    positionClass: 'toast-top-right'
                  });
                })
              }
              else {
                this.spinner.hide();
                this.toastr.error(this.dto.Response.message, 'Invalid!', {
                  timeOut: 3000,
                  positionClass: 'toast-top-right',
                });
              }
            });
      }
    else {
      this.spinner.hide();
      this.toastr.error('Please enter valid account no.', 'Invalid input!', {
        timeOut: 3000,
        positionClass: 'toast-top-right',
      });
    }
  }

  edit() {
    this.token = this.storage.retrieve('token');
    // let headers = new HttpHeaders();
    // headers = headers.set('Authorization',  this.token);

    let editedArray = this.getEditedArray();   
    let headers = new HttpHeaders();
	
    if(editedArray.length > 0)
    {
      headers = headers.set('Authorization', this.token)
      .set('editedArray', editedArray); 
    }
    else{
      headers = headers.set('Authorization',  this.token);
    } 
    
    this.paymentDTO.payment_id = this.paymentId;
    let mobNumberPattern =/[0-9]/g;
    if( this.paymentDTO.payment_id == "" || this.paymentDTO.payment_id  == undefined ||  this.paymentDTO.payment_id  == null)
    {
       this.toastr.error("Please choose bank type", 'Invalid!', {
         timeOut: 3000,
         positionClass: 'toast-top-right',
       });
       return;
    }
    if( this.paymentDTO.account_no == "" || this.paymentDTO.account_no  == undefined ||  this.paymentDTO.account_no  == null)
    {
       this.toastr.error("Please enter account no", 'Invalid!', {
         timeOut: 3000,
         positionClass: 'toast-top-right',
       });
       return;
    }
   if(this.paymentDTO.max_amount < this.paymentDTO.min_amount)
    {
       this.toastr.error("Maximum amount must be greather than minimum amount", 'Invalid!', {
         timeOut: 3000,
         positionClass: 'toast-top-right',
       });
       return;
    }
  
    if(this.paymentDTO.limited_amt == 0)
    {
       this.toastr.error("Limited amount must not be zero", 'Invalid!', {
         timeOut: 3000,
         positionClass: 'toast-top-right',
       });
       return;
    }
    //amk
      if (this.paymentDTO.imageUrl === null || this.paymentDTO.imageUrl  === ''  || this.paymentDTO.imageUrl  === undefined) 
      {      
           this.paymentDTO.imageUrl = this.paymentDTO.imageUrl;
      }   
      else
      {
          if(this.paymentDTO.imageUrl .includes('data:image/jpeg;base64,'))
          this.paymentDTO.imageUrl = this.paymentDTO.imageUrl.replace("data:image/jpeg;base64,","");
          if(this.paymentDTO.imageUrl .includes('data:image/png;base64,'))
          this.paymentDTO.imageUrl = this.paymentDTO.imageUrl.replace("data:image/png;base64,","");
         
      }    
      this.paymentDTO.isShowAll = this.paymentDTO.isShowAll;
      if( this.paymentDTO.isShowAll == "2" ||  this.paymentDTO.isShowAll == "3")
      {
          if(this.paymentDTO.imageUrl == undefined || this.paymentDTO.imageUrl == "" || this.paymentDTO.imageUrl == "")
          {
            this.toastr.error("Please add qr image", 'Invalid!', {
              timeOut: 3000,
              positionClass: 'toast-top-right',
            });
            return;
          }
      }
   //
    if(this.optionsModel.length > 0)
      {
       this.paymentDTO.handle_admin = this.optionsModel.toString();
      }
    this.spinner.show();
    this.http.post(this.funct.ipaddress + 'payment/updatePaymentDetail', this.paymentDTO, { headers: headers }).subscribe( 
      result => {
        this.dto.Response = {};
        this.dto.Response = result;
        if (this.dto.Response.status == 'Success') {
          this.spinner.hide();
          this.router.navigate(['/payment-info']).then(() => {
            this.toastr.success(this.dto.Response.message, 'Success!', {
              timeOut: 3000,
              positionClass: 'toast-top-right'
            });
          })
        }
        else {
          this.spinner.hide();
          this.toastr.error(this.dto.Response.message, 'Invalid!', {
            timeOut: 3000,
            positionClass: 'toast-top-right',
          });
        }
      });
  }


  changeAction(id) 
 {
    if(this.paymentDTO.role_name == "ACCOUNTANT_MANAGER")
    {
    return;
    }
   if(this.clickId.length == 0)
   {
    $("#"+id).css('filter','grayscale(0%)');
      this.clickId.push(id);
      this.paymentId = id;
    
   }
   else
   {
     for(var i = 0 ; i < this.clickId.length; i++)
     {
       if(this.clickId[i] != id)
       {
         console.log("not equal" + this.clickId[i])
          $("#"+this.clickId[i]).css('filter','grayscale(100%)');
          $("#"+this.clickwithdrawalId[i]).css('filter','grayscale(0%)');
          $("#"+id).css('filter','grayscale(0%)');
          this.clickId[i] = id;
          this.paymentId = id;
          
       }
       else
       {
         $("#"+this.clickId[i]).css('filter','grayscale(0%)');
         this.clickId[i] = id;
         this.paymentId = id;
        
         console.log("equal")
       }
     }
   }
 }

 flagAdmin()
 {
   this.paymentDTO.handle_admin = $("#admin_id").val();
 }

 goModal(event)
 {
   this.cashIdid = event.target.id;
  $('#deleteCash').modal("show");
 }

 onDelete(id)
 {
      
  //  this.paymentDTO.imageUrl="";

      this.spinner.show();
      this.token = this.storage.retrieve('token');
      let headers = new HttpHeaders();
      headers = headers.set('Authorization',  this.token);
      let formData= new FormData();
      this.cashIdid = id;
      formData.append("id",this.cashIdid);
      this.http.post(this.funct.ipaddress + 'payment/deletQRImage', formData, {headers: headers})
      .pipe(
        catchError(this.handleError.bind(this))
      )
      .subscribe(
        result => {
          this.dto.Response = {};
          this.dto.Response = result;
          if (this.dto.Response.status == 'Success') {
            this.spinner.hide();
            $('#deleteCash').modal("hide");
            window.location.href = window.location.href;
          
            this.router.navigate(['/payment-info-detail', this.paymentdetailId]).then(() => {
              this.toastr.success(this.dto.Response.message, 'Success!', {
                timeOut: 3000,
                positionClass: 'toast-top-right'
              });
            
            })
          }
          else {
            this.spinner.hide();
            this.toastr.error(this.dto.Response.message, 'Invalid!', {
              timeOut: 3000,
              positionClass: 'toast-top-right',
            });
          }
        });
 

 }

 deleteOk()
 {
  this.spinner.show();
  this.token = this.storage.retrieve('token');
  let headers = new HttpHeaders();
  headers = headers.set('Authorization',  this.token);
  let formData= new FormData();
  formData.append("id",this.cashIdid);
  this.http.post(this.funct.ipaddress + 'payment/deletCashIn', formData, {headers: headers})
  .pipe(
    catchError(this.handleError.bind(this))
   )
  .subscribe(
    result => {
      this.dto.Response = {};
      this.dto.Response = result;
      if (this.dto.Response.status == 'Success') {
        this.spinner.hide();
        $('#deleteCash').modal("hide");
        window.location.href = window.location.href;
       
        this.router.navigate(['/payment-info-detail', this.paymentdetailId]).then(() => {
          this.toastr.success(this.dto.Response.message, 'Success!', {
            timeOut: 3000,
            positionClass: 'toast-top-right'
          });
         
        })
      }
      else {
        this.spinner.hide();
        this.toastr.error(this.dto.Response.message, 'Invalid!', {
          timeOut: 3000,
          positionClass: 'toast-top-right',
        });
      }
    });
 }

 
 deleteOkImage()
 {
  this.spinner.show();
  this.token = this.storage.retrieve('token');
  let headers = new HttpHeaders();
  headers = headers.set('Authorization',  this.token);
  let formData= new FormData();
  formData.append("id",this.cashIdid);
  this.http.post(this.funct.ipaddress + 'payment/deletCashIn', formData, {headers: headers})
  .pipe(
    catchError(this.handleError.bind(this))
   )
  .subscribe(
    result => {
      this.dto.Response = {};
      this.dto.Response = result;
      if (this.dto.Response.status == 'Success') {
        this.spinner.hide();
        $('#deleteCash').modal("hide");
        window.location.href = window.location.href;
       
        this.router.navigate(['/payment-info-detail', this.paymentdetailId]).then(() => {
          this.toastr.success(this.dto.Response.message, 'Success!', {
            timeOut: 3000,
            positionClass: 'toast-top-right'
          });
         
        })
      }
      else {
        this.spinner.hide();
        this.toastr.error(this.dto.Response.message, 'Invalid!', {
          timeOut: 3000,
          positionClass: 'toast-top-right',
        });
      }
    });
 }


 hideModal()
 {
   $('#deleteCash').modal("hide");
 }

 ngAfterViewInit() {
  setTimeout(() => {
   // this.renderWidgetInsideWidgetContainer();
  }, 0);
 }

 
 getEditedArray() 
 {
  let editedArray = '';
  if (this.paymentId !== this.originalAdminDTO.paymentId ) 
  {
    if(this.paymentId == 1 || this.paymentId == 4)
    {
        this.paymenttype_name ="KBZ Pay";
    }
    else if(this.paymentId == 2 || this.paymentId == 5)
    {
      this.paymenttype_name ="Wave Pay";
    }
    else if(this.paymentId == 3 || this.paymentId == 6)
    {
      this.paymenttype_name ="CB Pay";
    }
    else if(this.paymentId == 7 )
    {
      this.paymenttype_name ="Wave Shop";
    }
    else
    {
      this.paymenttype_name ="AYA Pay";
    }


    //original
    if(this.originalAdminDTO.paymentId == 1 || this.originalAdminDTO.paymentId == 4)
    {
        this.paymenttype_newname ="KBZ Pay";
    }
    else if(this.originalAdminDTO.paymentId == 2 || this.originalAdminDTO.paymentId == 5)
    {
      this.paymenttype_newname ="Wave Pay";
    }
    else if(this.originalAdminDTO.paymentId == 3 || this.originalAdminDTO.paymentId == 6)
    {
      this.paymenttype_newname ="CB Pay";
    }
    else if(this.originalAdminDTO.paymentId == 7)
    {
      this.paymenttype_newname ="Wave Shop";
    }
    else
    {
      this.paymenttype_newname ="AYA Pay";
    }
    
    editedArray += 'BankType: ' + this.paymenttype_newname + ' -> ' + this.paymenttype_name + ', ';
  }
  if (this.paymentDTO.account_no !== this.originalAdminDTO.account_no) {
    editedArray += 'AccountNum: ' + this.originalAdminDTO.account_no + ' -> ' + this.paymentDTO.account_no + ', ';
  }
  if (this.paymentDTO.account_name !== this.originalAdminDTO.account_name) {
    editedArray += 'AccountName: ' + this.originalAdminDTO.account_name + ' -> ' + this.paymentDTO.account_name + ', ';
  }
  if (this.paymentDTO.code_number !== this.originalAdminDTO.code_number) {
    editedArray += 'CodeNum: ' + this.originalAdminDTO.code_number + ' -> ' + this.paymentDTO.code_number + ', ';
  }
  if (this.paymentDTO.status !== this.originalAdminDTO.status) {
    editedArray += 'Status: ' + this.originalAdminDTO.status + ' -> ' + this.paymentDTO.status + ', ';
  }
  if (this.paymentDTO.min_amount !== this.originalAdminDTO.min_amount) {
    editedArray += 'MinAmount: ' + this.originalAdminDTO.min_amount + ' -> ' + this.paymentDTO.min_amount + ', ';
  }
  if (this.paymentDTO.max_amount !== this.originalAdminDTO.max_amount) {
    editedArray += 'MaxAmount: ' + this.originalAdminDTO.max_amount + ' -> ' + this.paymentDTO.max_amount + ', ';
  }
  if (this.paymentDTO.limited_amt !== this.originalAdminDTO.limited_amt) {
    editedArray += 'LimitedAmount: ' + this.originalAdminDTO.limited_amt + ' -> ' + this.paymentDTO.limited_amt + ', ';
  }
  if (this.paymentDTO.maxAccountCount !== this.originalAdminDTO.maxAccountCount) {
    editedArray += 'MaxAmountCount: ' + this.originalAdminDTO.maxAccountCount + ' -> ' + this.paymentDTO.maxAccountCount + ', ';
  }


  if(this.paymentDTO.descrition != undefined)
  {
    if (this.paymentDTO.descrition !== this.originalAdminDTO.descrition) {
      editedArray += 'IsShowAll: ' + this.originalAdminDTO.descrition + ' -> ' + this.paymentDTO.descrition + ', ';
    }
  }

  if (editedArray.endsWith(', ')) {
    editedArray = editedArray.slice(0, -2);
  }
  
  return editedArray;
}

onSelectionChange(event: Event): void {
  const selectedValue = (event.target as HTMLSelectElement).value;
  console.log('Selected value:', selectedValue);

  if (selectedValue === '1') 
  {
    this.paymentDTO.descrition = "Only Account Number";
  } 
  else if (selectedValue === '2')
   {
    this.paymentDTO.descrition = "Only QR Code Image";;
  } 
  else if (selectedValue === '3') 
  {
    this.paymentDTO.descrition = "Both";
  }
}


//amk added for withdrawal

changeActionForWithdrawal(id) 
{
   if(this.paymentDTOWithdrawal.role_name == "ACCOUNTANT_MANAGER")
   {
   return;
   }
  if(this.clickwithdrawalId.length == 0)
  {
   $("#"+id).css('filter','grayscale(0%)');
     this.clickwithdrawalId.push(id);
     this.paymentwithdrawalId = id;
    
  }
  else
  {
    for(var i = 0 ; i < this.clickwithdrawalId.length; i++)
    {
      if(this.clickwithdrawalId[i] != id)
      {
        console.log("not equal" + this.clickwithdrawalId[i])
         $("#"+this.clickwithdrawalId[i]).css('filter','grayscale(100%)');
         $("#"+this.clickId[i]).css('filter','grayscale(0%)');
         $("#"+id).css('filter','grayscale(0%)');
         this.clickwithdrawalId[i] = id;
         this.paymentwithdrawalId = id;
        
      }
      else
      {
        $("#"+this.clickwithdrawalId[i]).css('filter','grayscale(0%)');
        this.clickwithdrawalId[i] = id;
        this.paymentwithdrawalId = id;
       
        console.log("equal")
      }
    }
  }
}

isSelected(id: string): boolean {
  return this.clickwithdrawalId.includes(id);
}
goSaveWithdrawal()
{
  if (this.paymentdetailId == null)
   {
    this.SaveWithdrawal();
  }
  else {
    this.editWithdrawal();
  }
}

SaveWithdrawal() {
  let mobNumberPattern =/[0-9]/g;
  this.paymentDTOWithdrawal.payment_id = this.paymentwithdrawalId;
 
  if( this.paymentDTOWithdrawal.payment_id == "" || this.paymentDTOWithdrawal.payment_id  == undefined ||  this.paymentDTOWithdrawal.payment_id  == null)
  {
     this.toastr.error("Please choose bank type", 'Invalid!', {
       timeOut: 3000,
       positionClass: 'toast-top-right',
     });
     return;
  }
         if( this.paymentDTOWithdrawal.account_no == "" || this.paymentDTOWithdrawal.account_no  == undefined ||  this.paymentDTOWithdrawal.account_no  == null)
         {
            this.toastr.error("Please enter account no", 'Invalid!', {
              timeOut: 3000,
              positionClass: 'toast-top-right',
            });
            return;
         }

         if (!mobNumberPattern.test(this.paymentDTOWithdrawal.account_no))
         {
           this.toastr.error("Account number must be number", 'Invalid!', {
             timeOut: 3000,
             positionClass: 'toast-top-right',
           });
           return;
         }   
       
       
    if (this.paymentDTOWithdrawal.imageUrl === null || this.paymentDTOWithdrawal.imageUrl  === ''  || this.paymentDTOWithdrawal.imageUrl  === undefined) 
    {      
         this.paymentDTOWithdrawal.imageUrl = this.paymentDTOWithdrawal.imageUrl;
    }   
    else
    {
        if(this.paymentDTOWithdrawal.imageUrl .includes('data:image/jpeg;base64,'))
        this.paymentDTOWithdrawal.imageUrl = this.paymentDTOWithdrawal.imageUrl.replace("data:image/jpeg;base64,","");
        if(this.paymentDTOWithdrawal.imageUrl .includes('data:image/png;base64,'))
        this.paymentDTOWithdrawal.imageUrl = this.paymentDTOWithdrawal.imageUrl.replace("data:image/png;base64,","");
       
    }    
    this.paymentDTOWithdrawal.isShowAll = this.paymentDTOWithdrawal.isShowAll;

         if(this.optionsModel.length > 0)
         {
          this.paymentDTOWithdrawal.handle_admin = this.optionsModel.toString();
         }
    
    this.spinner.show();
  if (mobNumberPattern.test(this.paymentDTOWithdrawal.account_no)) {
        this.token = this.storage.retrieve('token');
        let headers = new HttpHeaders();
        headers = headers.set('Authorization', this.token);
        this.http.post(this.funct.ipaddress + 'payment/insertPaymentDetail', this.paymentDTOWithdrawal, { headers: headers })
        .pipe(
          catchError(this.handleError.bind(this))
         )
        .subscribe( 
          result => {
            this.dto.Response = {};
            this.dto.Response = result;
            if (this.dto.Response.status == 'Success') {
              this.spinner.hide();
              this.router.navigate(['/payment-info']).then(() => {
                this.toastr.success(this.dto.Response.message, 'Success!', {
                  timeOut: 3000,
                  positionClass: 'toast-top-right'
                });
              })
            }
            else {
              this.spinner.hide();
              this.toastr.error(this.dto.Response.message, 'Invalid!', {
                timeOut: 3000,
                positionClass: 'toast-top-right',
              });
            }
          });
    }
  else {
    this.spinner.hide();
    this.toastr.error('Please enter valid account no.', 'Invalid input!', {
      timeOut: 3000,
      positionClass: 'toast-top-right',
    });
  }
}


editWithdrawal() {
  this.token = this.storage.retrieve('token');

  let editedArray = this.getEditedArray();   
  let headers = new HttpHeaders();

  if(editedArray.length > 0)
  {
    headers = headers.set('Authorization', this.token)
    .set('editedArray', editedArray); 
  }
  else{
    headers = headers.set('Authorization',  this.token);
  } 
  
  this.paymentDTOWithdrawal.payment_id = this.paymentwithdrawalId;
  let mobNumberPattern =/[0-9]/g;
  if( this.paymentDTOWithdrawal.payment_id == "" || this.paymentDTOWithdrawal.payment_id  == undefined ||  this.paymentDTOWithdrawal.payment_id  == null)
  {
     this.toastr.error("Please choose bank type", 'Invalid!', {
       timeOut: 3000,
       positionClass: 'toast-top-right',
     });
     return;
  }

  if( this.paymentDTOWithdrawal.account_no == "" || this.paymentDTOWithdrawal.account_no  == undefined ||  this.paymentDTOWithdrawal.account_no  == null)
  {
     this.toastr.error("Please enter account no", 'Invalid!', {
       timeOut: 3000,
       positionClass: 'toast-top-right',
     });
     return;
  } 



    if (this.paymentDTOWithdrawal.imageUrl === null || this.paymentDTOWithdrawal.imageUrl  === ''  || this.paymentDTOWithdrawal.imageUrl  === undefined) 
    {      
         this.paymentDTOWithdrawal.imageUrl = this.paymentDTOWithdrawal.imageUrl;
    }   
    else
    {
        if(this.paymentDTOWithdrawal.imageUrl .includes('data:image/jpeg;base64,'))
        this.paymentDTOWithdrawal.imageUrl = this.paymentDTOWithdrawal.imageUrl.replace("data:image/jpeg;base64,","");
        if(this.paymentDTOWithdrawal.imageUrl .includes('data:image/png;base64,'))
        this.paymentDTOWithdrawal.imageUrl = this.paymentDTOWithdrawal.imageUrl.replace("data:image/png;base64,","");
       
    }    
    this.paymentDTOWithdrawal.isShowAll = this.paymentDTOWithdrawal.isShowAll;
  if(this.optionsModel.length > 0)
    {
     this.paymentDTOWithdrawal.handle_admin = this.optionsModel.toString();
    }
  this.spinner.show();
  this.http.post(this.funct.ipaddress + 'payment/updatePaymentDetail', this.paymentDTOWithdrawal, { headers: headers }).subscribe( 
    result => {
      this.dto.Response = {};
      this.dto.Response = result;
      if (this.dto.Response.status == 'Success') {
        this.spinner.hide();
        this.router.navigate(['/payment-info']).then(() => {
          this.toastr.success(this.dto.Response.message, 'Success!', {
            timeOut: 3000,
            positionClass: 'toast-top-right'
          });
        })
      }
      else {
        this.spinner.hide();
        this.toastr.error(this.dto.Response.message, 'Invalid!', {
          timeOut: 3000,
          positionClass: 'toast-top-right',
        });
      }
    });
}


getAssignedPaymentsForWithdrawal() {
  this.paymentListwithdrawal = [];
  this.token = this.storage.retrieve('token');
  let headers = new HttpHeaders();
  headers = headers.set('Authorization',  this.token);
  let params = new HttpParams();
  params = params.set('type', 'WITHDRAWAL');
  this.http.get(this.funct.ipaddress + 'payment/withdrawallistPayment', { params: params, headers: headers })
  .pipe(
    catchError(this.handleError.bind(this))
   )
  .subscribe(
    result => {
      this.dto.Response = {};
      this.dto.Response = result;
      this.paymentListwithdrawal = this.dto.Response;
    }
  );
}


getPaymentByIdWithdrawal() 
{
  this.token = this.storage.retrieve('token');
  let headers = new HttpHeaders();
  headers = headers.set('Authorization', this.token);
  let params = new HttpParams();
  params = params.set('id', this.paymentdetailId);
  this.adminIdEditList = [];
  this.spinner.show();
  this.http.get(this.funct.ipaddress + 'payment/GetPaymentDetaiList', { params: params, headers: headers })
  .pipe(
    catchError(this.handleError.bind(this))
   )
  .subscribe(
    result => {
      this.dto.Response = {};
      this.dto.Response = result;
      this.paymentDTOWithdrawal = this.dto.Response;
        // this.paymentId = this.paymentDTOWithdrawal.payment_id;
        this.paymentwithdrawalId = this.paymentDTOWithdrawal.payment_id;//amk for wihtdrawal
        this.originalAdminDTO.isShowAll = this.paymentDTOWithdrawal.isShowAll;
        if(this.originalAdminDTO.isShowAll == '1')
        {
          this.originalAdminDTO.descrition = "Only Account Number";
        }
        else if(this.originalAdminDTO.isShowAll == '2')
        {
          this.originalAdminDTO.descrition = "Only QR Code Image";
        }
        else if(this.originalAdminDTO.isShowAll == '3')
        {
          this.originalAdminDTO.descrition = "Both";
        }  
      
        this.originalAdminDTO.paymentId = this.paymentDTOWithdrawal.payment_id; 
        this.originalAdminDTO.account_no = this.paymentDTOWithdrawal.account_no;
        this.originalAdminDTO.account_name = this.paymentDTOWithdrawal.account_name;
        this.originalAdminDTO.code_number = this.paymentDTOWithdrawal.code_number;
       
        if(this.paymentDTOWithdrawal.role_name == "ACCOUNTANT_MANAGER")
        {
          $('#idcodenumber').prop('disabled', true);
          $('#idaccno').prop('disabled', true);
          $('#idaccname').prop('disabled', true);

          
          $('#idcodenumberwithdrawal').prop('disabled', true);
          $('#idaccnowithdrawal').prop('disabled', true);
          $('#idaccnamewithdrawal').prop('disabled', true);
        }
        this.http.get(this.funct.ipaddress + 'admin/GetWithdrawalAdmin', { headers: headers })
        .pipe(
          catchError(this.handleError.bind(this))
        )
        .subscribe(
          result1 => {
            this.dto.Response1 = {};
            this.dto.Response1 = result1;
            this.adminList = this.dto.Response1;
            this.myOptions = this.adminList;
          }
        ); 
        if(this.adminList == null || this.adminList.length == 0)
        {
          this.adminList = JSON.parse(localStorage.getItem("adminList"));
        }
        if(this.paymentDTOWithdrawal.adminIdList == 0) 
        {
          $("#insertAdmin").show();
          $("#updateAdmin").hide();

          $("#insertAdminWithdrawal").show();
          $("#updateAdminWithdrawal").hide();
        }
      else{
            $('#updateAdmin').show();
            $("#insertAdmin").hide();
            $('#updateAdminWithdrawal').show();
            $("#insertAdminWithdrawal").hide();
        }
        this.paymentDTOWithdrawal.adminIdList.forEach(element => 
          {
          this.cashInList.push(element);
        });

        for( var i=this.adminListWithdrawal.length - 1; i>=0; i--)
        {
          for( var j=0; j<this.paymentDTOWithdrawal.adminIdList.length; j++)
          { 
              if(this.adminListWithdrawal[i] && (this.adminListWithdrawal[i].id === this.paymentDTOWithdrawal.adminIdList[j].admin_id))
              {
                this.adminListWithdrawal.splice(i, 1);
              }
          }
      }
      
        this.myUpdOptions = this.adminListWithdrawal;       
       $("#"+this.paymentwithdrawalId).css('filter','grayscale(0%)');
       
       
    });
    this.spinner.hide();
}

}

