
<section class="content">
  <div class="container-fluid">
   
    <div class="card">
      <div class="card-header">
          <h3 class="card-title" style="font-weight: bold;"> TwoD W/L Report  </h3>
      </div>
    
      <div class="card-body">
    <form>
      <div class="row">
        <div class="form-group col-md-2">
            <label>Registration From Date </label><span class ="req">*</span>
        </div>
        <div class="form-group col-md-2">
          <input type = "date" style="width:250px;"   [(ngModel)]="fromdate" name = "fromdate" class="form-control form-control-sm" id = "fdate">
        </div>
    </div>

    <div class="row" >
        <div class="form-group col-md-2">
            <label>Registration To Date</label><span class ="req">*</span>
        </div>
        <div class="form-group col-md-2">
          <input type = "date" style="width:250px;"  name = "todate" [(ngModel)]="todate" [ngModelOptions]="{standalone: true}" min="{{fromdate | date:'yyyy-MM-dd'}}" class="form-control form-control-sm" id = "tdate">
        </div>
    </div>

   


        <div class="row">
          <div class="form-group col-md-2">
            <label>Select Section</label>
          </div>
          <div class="form-group col-md-3">
            <ngx-bootstrap-multiselect
              [options]="TDWLsectionOptions"
              [(ngModel)]="TDWLsectionModel"                   
              (ngModelChange)="onChangeBankType()"
              [ngModelOptions]="{ standalone: true }"
              [texts]="myTexts"
              [settings]="mySettings">
            </ngx-bootstrap-multiselect>
          </div>
        </div>

    
      <div class="row">
          <div class = "col-lg-2">
            <label> Choose Option</label>
           </div>
           <div class="form-group col-md-5" style="cursor: pointer;">
            <img *ngIf="!clickkbzpay" src="assets/image/excel_pm.png" (click) = "getReportOption('excel')"
              style="opacity: 0.3; height : 60px ;width: 60px;object-fit: cover;padding-left : 0px;border: 3px solid #ffffff;border-radius: 10px;border-color: #ffffff;">
            <img *ngIf="clickkbzpay" src="assets/image/excel_pm.png" (click) = "getReportOption('excel')"
             style="opacity: 1; height : 60px ; width: 60px; object-fit: cover;border: 3px sol;border-radius: 10px;padding-left : 0px;">
             &nbsp;&nbsp;

            <img *ngIf="!clickwavepay" src="assets/image/pdf3.png" (click) = "getReportOption('pdf')"
              style="opacity: 0.3; margin-left : 2px; height : 60px ;width: 60px;object-fit: cover;border: 3px solid #ffffff;border-radius: 10px;border-color: #ffffff;">
            <img *ngIf="clickwavepay" src="assets/image/pdf3.png" (click) = "getReportOption('pdf')"
             style="opacity: 1; height : 60px ; margin-left : 2px; width: 60px;object-fit: cover;border: 3px sol;border-radius: 10px">

             &nbsp;&nbsp;

            <img *ngIf="!csvrep" src="assets/image/csv3.png" (click) = "getReportOption('csv')"
              style="opacity: 0.3; padding-left :0px; height : 60px ;width: 60px;object-fit: cover;border: 3px solid #ffffff;border-radius: 10px;border-color: #ffffff;">
            <img *ngIf="csvrep" src="assets/image/csv3.png" (click) = "getReportOption('csv')"
             style="opacity: 1; height : 60px ; padding-left : 0px; width: 60px;object-fit: cover;border: 3px sol;border-radius: 10px">
            </div> 
        </div>
        <div class="row">
          <div class="form-group col-md-2 col-sm-4 col-xs-4">
          </div>
        <div class="form-group col-md-2 col-sm-4 col-xs-4" *ngIf="hardcode_add_bool === true">
            <label></label>
            <button id="generaterep" type="button" class="btn btn-block btn-primary btn-sm" style="margin-top: 4%;" (click)="userregistrationReport()">Generate Report</button>
        </div>
       
        </div>
    </form>
    </div>
    </div>
 
  </div>
</section>

<aside class="control-sidebar control-sidebar-dark">
       
</aside>
      
      
   <app-footer></app-footer>
       <ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#fff" type="square-jelly-box">
        <p style="font-size: 16px; color: white">Loading...</p>
      </ngx-spinner> 

  
  