<div class="wrapper">
    <!-- Navbar -->
    <app-header-sidebar></app-header-sidebar>

    <!-- here here here -->
    <!-- Content Wrapper. Contains page content -->
    <div class="content-wrapper">
        <!-- Content Header (Page header) -->
        <div class="content-header">
            <div class="container-fluid">
                <!--  <div class="row mb-2">
                    <div class="col-sm-6">
                        <h1 class="m-0 text-dark"> Promotion and News </h1>
                    </div>
                </div> -->
                <!-- /.row -->
            </div><!-- /.container-fluid -->
        </div>
        <!-- /.content-header -->

        <!-- Main content -->
        <section class="content">
            <div class="container-fluid">
                <form>
                    <div class="row">
                        <div class="col-md-12">

                            <div class="card">
                                <div class="card-header">
                                    <h3 class="card-title" style="font-weight: bold;"> Feedback Detail </h3>
                                </div>
                                <!-- /.card-header -->
                                <div class="card-body">
                                    <form>

                                        <div class="row">
                                            <label class="col-md-2"> Subject </label>
                                            <div class="form-group col-md-10">
                                                    <textarea class="form-control" id="subject" rows="2" [(ngModel)]="feedDTO.title"
                                                    [ngModelOptions]="{standalone: true}"></textarea>
                                            </div>
                                        </div>

                                        <div class="row">
                                            <label class="col-md-2"> Description </label>
                                            <div class="form-group col-md-10">
                                                <textarea class="form-control" id="description" rows="5" [(ngModel)]="feedDTO.description"
                                                    [ngModelOptions]="{standalone: true}"></textarea>
                                            </div>
                                        </div>

                                        <div class="row">
                                            <label class="col-md-2"> Phone No </label>
                                            <div class="form-group col-md-3">
                                                <input class="form-control form-control-sm" id="phoneNo"
                                                    [(ngModel)]="feedDTO.phone_no" [ngModelOptions]="{standalone: true}">
                                            </div>
                                        </div>

                                        <div class="row">
                                            <label class="col-md-2"> Created Date </label>
                                            <div class="form-group col-md-3">
                                                <input class="form-control form-control-sm" id="createdDate"
                                                    [(ngModel)]="feedDTO.created_date_Str" [ngModelOptions]="{standalone: true}">
                                            </div>
                                        </div>

                                        <div class="row">
                                            <label class="col-md-2"> Created By </label>
                                            <div class="form-group col-md-3">
                                                <input class="form-control form-control-sm" id="createdBy"
                                                    [(ngModel)]="feedDTO.created_by_name" [ngModelOptions]="{standalone: true}">
                                            </div>
                                        </div>

                                        <div class="row" style="margin-top: 1%;">
                                            <div class="col-md-2 col-sm-6">
                                                <!-- <button type="button" class="btn btn-block btn-danger"
                                                    [routerLink]="['/feedback-list']"> Cancel </button> -->

                                                                 <button type="button" class="btn btn-block btn-danger"
                                                    [routerLink]="['/feedbackandfaq/feedback-list']"> Cancel </button>
                                            </div>
                                        </div>

                                    </form>

                                </div>
                            </div>
                        </div>

                    </div>
                </form>
                <!-- /.row -->

                <!-- /.row -->
            </div>
            <!--/. container-fluid -->
        </section>
        <!-- /.content -->
    </div>

    <aside class="control-sidebar control-sidebar-dark">
        <!-- Control sidebar content goes here -->
    </aside>

    <app-footer></app-footer>

    <ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#fff" type="square-jelly-box">
        <p style="font-size: 16px; color: white">Loading...</p>
    </ngx-spinner>

</div>


<div id="deleteData" class="modal fade">
    <div class="modal-dialog">
        <div class="modal-content">

            <div class="modal-body">
                <form>

                    <div class="row" style="margin-top: 5%;margin-bottom: 3%;margin-left: 9%;">
                        <div class="form-group col-md-12">
                            <h5> This Account is used by another device. 
                                Please Sign Out. Login Again. </h5>
                        </div>
                    </div>

                    <div class="row" style="margin-left: 40%;margin-bottom: 3%;">
                        <div class="col-md-5 col-sm-6" id="deleteBtn">
                            <button type="button" class="btn btn-block btn-success" (click)="OkLogout()"> Ok </button>
                        </div>
                    </div>
                </form>
            </div>

        </div>
    </div>
</div>