import { Injectable } from '@angular/core';
import { AnyMxRecord } from 'dns';

@Injectable({
  providedIn: 'root'
})
export class DtoService {

  AdminDTO: any;
  AdminLoginModel : any ; //Net

  Response: any;
  Response1 : any;// add this
  Message: any;
  Data: any;
  token: any;
  notiCount:any;
  faqstepid:any;
  faqstepguideid:any;
  faqsquestion:any;
  faqsanswer  :any;
  hasGuide:any;
  status:any;
  persentage:any;
  isNotiSong:any;

  isSearchMember: any;
  isSearchBoolean : any;
  isUseSearch:any;
  isType:any;
  menuCodes:any;
  role_list:any;
  adminName:any;
  loginIs :any;
  total_member:any;
  total_active:any;
  total_inactive:any;
  total_count:any;
  
  isGameTransaction: any;
  isGameTransactionBoolean : any;

  isForgotPassword: any;
  isForgotPasswordSecond:any;
  isisForgotPasswordBoolean : any;
  isAccMember :any;
  iisAccMemberBoolean : any;

  tempDateData: any;
  constructor() {

    this.token = '';
    this.menuCodes='';
    this.adminName='';
    this.loginIs='';
    this.tempDateData = '';

    this.total_member = 0;
    this.total_active = 0;
    this.total_inactive = 0;
    this.total_count=0;

    this.Response = {
      message: this.Message,
      data: this.Data,
    }

    this.Message = {
      code: '',
      message: ''
    }

    this.Data = {
      token: '',
      model: this.AdminLoginModel,
      adminDTOList: [],
      menuCodes:[],
      adminName:''
    }  //ADD NEW

    this.AdminLoginModel = {
      id: 0,
      phone_no: '',
      roleId: 0,
      roleName: '',
      name: '',
      password: '',
      confirmPassword: '',
      oldPassword: '',
      newPassword: '',
      balance: 0.00,
      referralCode: '',
      image: '',
      status: ''
    } //add new
     
    this.Data = {
      token: '',
      adminDTO: this.AdminDTO,
      adminDTOList: [],
      menuCodes :[],
      adminName:''
    }

    this.AdminDTO = {
      id: 0,
      phoneNo: '',
      roleId: 0,
      roleName: '',
      name: '',
      password: '',
      confirmPassword: '',
      oldPassword: '',
      newPassword: '',
      balance: 0.00,
      referralCode: '',
      image: '',
      status: ''
    }
  }
}
