import { Component, OnInit, ViewChild } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,HttpErrorResponse } from '@angular/common/http';
import { Router, NavigationEnd } from '@angular/router';
import { NgxSpinnerService } from "ngx-spinner";
import { LocalStorageService } from 'ngx-webstorage';
import { ToastrService } from 'ngx-toastr';
import { DtoService } from '../../service/dto.service';
import { FunctService } from '../../service/funct.service';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { forkJoin } from 'rxjs';


import { DatePipe } from '@angular/common'
import { DomSanitizer } from '@angular/platform-browser'; //for unsafe image
import { truncate } from 'fs';

declare var $: any




@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {
  @ViewChild(DataTableDirective)
  dtElement: DataTableDirective;
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();

  dtOptions1: DataTables.Settings = {};
  dtTrigger1: Subject<any> = new Subject();

  adminDTO;
  token;
  isOwnerLoggedIn;
  twod_number : any;
  twod_time : any;
  threed_number : any;
  resultDTO : any;
  feedbackDTO :any;
  feedback_count : any;
  show_time : any;
  userFinancialTransactionDTO : any;
  total_income : any;
  first_deposit :any;
  first_count_user :any;
  total_outcome : any;
  userDTO : any;
  total_active_users : any;
  total_count :any;
  total_inactive_users : any;
  idIndex: any;
  accountDetailList : any;
  accountDetailLists : any;
  withdrawalDetailList: any;
  total_today_reach_amt : any;
  total_today_reach_amt_withdrawal :any;

  total_today_reach_amt_topup :any;
  total_my_amount_topup :any;
  
  total_withdrawal: any = 0;
  total_qty : any = 0;
  total_my_amount :any;
  total_my_amount_withdrawal :any;

  total_qm_balance : any;
  total_user_balance : any;
  total_freeze_balance:any;
  roleList:any;
  first_deposit_name :any;
  total_deposit_name:any;
  feedback_name:any;
  total_withdrawal_name:any;
  total_member_name:any;
  today_reach_amount_name:any;
  total_user_balance_name :any;
  game_name:any;
  first_deposit_bool = false;
  children_total_deposit_bool:any;
  feedback_bool:any;
  total_withdrawal_bool:any;
  total_member_bool:any;
  today_reach_amount_bool:any;
  total_user_balance_bool:any;
  idpendingIndex:any;
  total_freeze_amount:any;

  hardcode_view: any;
  hardcode_delete_bool : any;
  hardcode_first_deposit_bool :any;
  hardcode_total_deposit_name_bool: any;
  hardcode_feedback_name_bool : any;
  hardcode_total_withdrawal_name_bool : any;
  hardcode_total_member_name_bool : any;
  hardcode_today_reach_amount_name_bool : any;
  hardcode_total_user_balance_name_bool : any;
  hardcode_game_name_bool : any;
  hardcode_total_freeze_amount:any;
  dashboard_bool:any;
   waveTotal :  any = 0;
   kpayTotal :  any = 0;
   ayaPayTotal :  any = 0;
   cbPayTotal :  any = 0;
   type:any;


  constructor(private storage: LocalStorageService,private spinner: NgxSpinnerService, private toastr: ToastrService, private http: HttpClient, private dto: DtoService, 
    private router: Router, private funct: FunctService) 
    {
      this.type="TOPUP";
      this.first_deposit = "First_Deposit";
      this.total_deposit_name = "Total_Deposit";
      this.feedback_name="Feedback";
      this.total_withdrawal_name="Total_Withdrawal";
      this.total_member_name="Total_Memeber";
      this.today_reach_amount_name="Today_Reach_Amount/My Amount";
      this.total_user_balance_name="Total_User_Balance";
      this.total_freeze_amount ="Total_Freeze";
      
      this.game_name="Game (Credit)";
    const filteredMenuCodes_view = [];
    const filteredMenuCodes_first_deposit = [];
    const filteredMenuCodes_first_total_deposit_name = [];
    const filteredMenuCodes_first_feedback_name = [];
    const filteredMenuCodes_first_total_withdrawal_name = [];
    const filteredMenuCodes_first_total_member_name = [];
    const filteredMenuCodes_first_today_reach_amount_name =[];
    const filteredMenuCodes_first_total_user_balance_name = [];
    const filteredMenuCodes_first_game_name =[];
    const filteredMenuCodes_freeze_amount_name =[];

    this.adminDTO = this.storage.retrieve('adminDTO');
    this.token = this.storage.retrieve('token');   
    this.isOwnerLoggedIn = this.storage.retrieve('isOwnerLoggedIn');
    this.idIndex =1;   
    this.idpendingIndex =1;
    
    this.dto.menuCodes = this.storage.retrieve('menucodes');
    this.hardcode_view = "dashboard";
    this.dto.menuCodes.forEach(menuCode => 
      {
      if (menuCode === this.hardcode_view) {
        filteredMenuCodes_view.push(menuCode);
      }  
      if (menuCode === this.first_deposit) {
        filteredMenuCodes_first_deposit.push(menuCode);
      } 
      if (menuCode === this.total_deposit_name) {
        filteredMenuCodes_first_total_deposit_name.push(menuCode);
      } 
      if (menuCode === this.feedback_name) {
        filteredMenuCodes_first_feedback_name.push(menuCode);
      }     
      if (menuCode === this.total_withdrawal_name) {
        filteredMenuCodes_first_total_withdrawal_name.push(menuCode);
      }  
   
      if (menuCode === this.total_member_name) {
        filteredMenuCodes_first_total_member_name.push(menuCode);
      }  
      
      if (menuCode === this.today_reach_amount_name) {
        filteredMenuCodes_first_today_reach_amount_name.push(menuCode);
      }  
      if (menuCode === this.total_user_balance_name) {
        filteredMenuCodes_first_total_user_balance_name.push(menuCode);
      } 
      if (menuCode === this.game_name) {
        filteredMenuCodes_first_game_name.push(menuCode);
      } 
      if (menuCode === this.total_freeze_amount) {
        filteredMenuCodes_freeze_amount_name.push(menuCode);
      } 
    });
    if(filteredMenuCodes_view.length != 0)
    {
         this.hardcode_delete_bool = true;
    }
    else 
    {
      this.hardcode_delete_bool = false;
    }
    if(filteredMenuCodes_first_deposit.length != 0)
    {
         this.hardcode_first_deposit_bool = true;
    }
    else 
    {
      this.hardcode_first_deposit_bool = false;
    }
    if(filteredMenuCodes_first_total_deposit_name.length != 0)
    {
         this.hardcode_total_deposit_name_bool = true;
    }
    else 
    {
        this.hardcode_total_deposit_name_bool = false;
    }
    if(filteredMenuCodes_first_feedback_name.length != 0)
    {
         this.hardcode_feedback_name_bool = true;
    }
    else 
    {
        this.hardcode_feedback_name_bool = false;
    }
    if(filteredMenuCodes_first_total_withdrawal_name.length != 0)
    {
         this.hardcode_total_withdrawal_name_bool = true;
    }
    else 
    {
         this.hardcode_total_withdrawal_name_bool = false;
    }
    if(filteredMenuCodes_first_total_member_name.length != 0)
    {
         this.hardcode_total_member_name_bool = true;
    }
    else 
    {
         this.hardcode_total_member_name_bool = false;
    }
    if(filteredMenuCodes_first_today_reach_amount_name.length != 0)
    {
         this.hardcode_today_reach_amount_name_bool = true;
    }
    else 
    {
         this.hardcode_today_reach_amount_name_bool = false;
    }
    if(filteredMenuCodes_first_total_user_balance_name.length != 0)
    {
         this.hardcode_total_user_balance_name_bool = true;
    }
    else 
    {
         this.hardcode_total_user_balance_name_bool = false;
    }
    if(filteredMenuCodes_first_game_name.length != 0)
    {
         this.hardcode_game_name_bool = true;
    }
    else 
    {
         this.hardcode_game_name_bool = false;
    }
    if(filteredMenuCodes_freeze_amount_name.length != 0)
    {
         this.hardcode_total_freeze_amount = true;
    }
    else 
    {
         this.hardcode_total_freeze_amount = false;
    }
   if(this.hardcode_first_deposit_bool == true || this.hardcode_total_deposit_name_bool == true ||  this.hardcode_feedback_name_bool == true || this.hardcode_total_withdrawal_name_bool == true || this.hardcode_total_member_name_bool == true ||  this.hardcode_today_reach_amount_name_bool == true || this.hardcode_total_user_balance_name_bool == true || this.hardcode_game_name_bool == true || this.hardcode_total_freeze_amount == true) 
   {
        this.dashboard_bool = true;
        localStorage.setItem("dashboard_bool", this.dashboard_bool);

   }
   else{
    this.dashboard_bool = false;
    localStorage.setItem("dashboard_bool", this.dashboard_bool);
   }
    
    this.search();
    this.getFirstDeposit();
  }
  ngOnInit(): void
  {
    if(!this.storage.retrieve('loadFlag')){
      this.storage.store('loadFlag', 'noLoad');
      setTimeout(function(){
        location.reload();
      }, 1);
    }
    else{
      this.storage.clear('loadFlag');
    }
    this.dtOptions = {
      responsive: true,
      order: [],
     
    }
    this.dtOptions1 = {
      responsive: true,
      order: [],
      paging: true,
  
    }

    this.dtOptions.columnDefs = [
    ];
    this.dtOptions1.columnDefs = [
    ];
  
  }

  ngAfterViewInit()
  {
  }
 
  handleError(error: HttpErrorResponse)
  {
    this.spinner.hide();
    if(error.status == 423)
    {
      this.spinner.hide();
      $("#deleteData").modal("show");
    }
    if(error.status == 403)
    {
      this.spinner.hide();
      var id1 = 'tblServiceWithdrawal' + this.idpendingIndex;
      var table1 = $('#' + id1).DataTable();
      var id = 'tblServicePhone' + this.idIndex;
      var table = $('#' + id).DataTable();
      this.toastr.error("Limited Access.", 'Invalid!', {
        timeOut: 3000,
        positionClass: 'toast-top-right',
        });
      
    }
    if(error.status == 400)
      {
        this.spinner.hide();
        this.toastr.error("Bad Request.", 'Invalid!', {
          timeOut: 3000,
          positionClass: 'toast-top-right',
          });
      }
      return throwError(error);
    }

    OkLogout()
    {
      window.location.href ="./ad-login";
    } 

  search() 
  { 
    this.token = this.storage.retrieve('token');
    var headers = new HttpHeaders();
    headers = headers.set('Authorization', this.token);
    this.http.get(this.funct.ipaddress+'result/GetLastResult').subscribe(
      result => {
        this.dto.Response = {};
        this.dto.Response = result;
        this.twod_number = this.dto.Response["number2d"];
        this.twod_time =   this.dto.Response["datetime2d"];
        this.threed_number = this.dto.Response["number3d"];
        if(this.twod_time.includes('PM'))
        {
         this.show_time = "Evening";
        }
        else{
          this.show_time = "Morning";
        }
        this.spinner.hide();
      }
    );
    this.http.get(this.funct.ipaddress + 'feedback/feedback-count').subscribe(
      result => {
        this.dto.Response = {};
        this.dto.Response = result;
        this.feedback_count = this.dto.Response;
        this.spinner.hide();
      }
    );    
    this.http.get(this.funct.ipaddress + 'transaction/getTodayIncomeOutcome', {headers: headers})
    .pipe(
      catchError(this.handleError.bind(this))
     )
    .subscribe(
      result => 
      {
        this.dto.Response = {};
        this.dto.Response = result;
        this.total_income = new Intl.NumberFormat().format(this.dto.Response["todayIncome"]);
        this.total_outcome = new Intl.NumberFormat().format(this.dto.Response["todayOutcome"]);
        this.total_user_balance = new Intl.NumberFormat().format(this.dto.Response["userTotalBalance"]);
        this.total_freeze_balance = new Intl.NumberFormat().format(this.dto.Response["todayFreeze"]);
        this.spinner.hide();
      } );
    
    this.http.get(this.funct.ipaddress + 'payment/GetAccountTotalByAdminID', {headers: headers}).subscribe(
      //GetAccountDetailByAdminID
      result => {
        this.dto.Response = {};
        this.dto.Response = result;
        this.accountDetailLists = this.dto.Response;
      
        // var tot = 0, mytot = 0;
        // var tot1 = 0, mytot1 = 0;
      //   if(this.accountDetailList.length > 0)
      //   {
      //     for (let i = 0; i < this.accountDetailList.length; i++) 
      //     {
      //       if(this.accountDetailList[i].type == "TOPUP")
      //       {
      //         tot = parseInt(this.accountDetailList[i].today_reach_amt) + tot;
      //         mytot = parseInt(this.accountDetailList[i].my_amount) + mytot;
      //       }
      //       else
      //       {
      //         tot1 = parseInt(this.accountDetailList[i].today_reach_amt) + tot1;
      //         mytot1 = parseInt(this.accountDetailList[i].my_amount) + mytot1;
      //       }
        
      //      }
      //   }
      //  this.total_today_reach_amt = new Intl.NumberFormat().format(tot);
      //  this.total_my_amount= new Intl.NumberFormat().format(mytot);

      //  this.total_today_reach_amt_withdrawal = new Intl.NumberFormat().format(tot1);
      //  this.total_my_amount_withdrawal = new Intl.NumberFormat().format(mytot1);
       this.spinner.hide();
      });

    this.http.get(this.funct.ipaddress + 'user/get-total-active-users').subscribe(
      result => {
        this.dto.Response = {};
        this.dto.Response = result;
        this.total_active_users = this.dto.Response;
        this.dto.total_active =this.total_active_users ;             
      this.total_count =  this.dto.total_active + this.dto.total_inactive;
      });
      this.http.get(this.funct.ipaddress + 'user/get-total-inactive-users').subscribe(
        result => {
          this.dto.Response = {};
          this.dto.Response = result;
          this.total_inactive_users = this.dto.Response;
          this.dto.total_inactive = this.total_inactive_users ;        
                  
          this.total_count =  this.total_count + this.dto.total_inactive;         
        });
      this.refreshQMcredit();

  }
  refreshUserBalancecredit()
  {
    this.spinner.show();
    this.token = this.storage.retrieve('token');
    var headers = new HttpHeaders();
    headers = headers.set('Authorization', this.token);
    this.http.get(this.funct.ipaddress + 'transaction/getTodayIncomeOutcome', {headers: headers})
    .pipe(
      catchError(this.handleError.bind(this))
     )
    .subscribe(
      result => {
        this.dto.Response = {};
        this.dto.Response = result;
        this.total_income = new Intl.NumberFormat().format(this.dto.Response["todayIncome"]);
        this.total_outcome = new Intl.NumberFormat().format(this.dto.Response["todayOutcome"]);
        this.total_user_balance = new Intl.NumberFormat().format(this.dto.Response["userTotalBalance"]);
        // this.total_freeze_balance = new Intl.NumberFormat().format(this.dto.Response["FreezeAmount"]);
        this.total_freeze_balance = new Intl.NumberFormat().format(this.dto.Response["todayFreeze"]);
        
        this.spinner.hide();
      }
    );
  }

  refreshQMcredit()
  {
    this.spinner.show();
    this.http.get(this.funct.ipaddress + 'loginGS/checkAgentCredit').subscribe(
      result => {
        this.dto.Response = {};
        this.dto.Response = result;
        this.total_qm_balance = new Intl.NumberFormat().format(this.dto.Response);
        this.spinner.hide();
      }
    );
   
  }

  // goModal(){
  //   // this.type ="TOPUP";
  //   var id = 'tblServicePhone' + this.idIndex;
  //   var table = $('#' + id).DataTable();
  //   table.destroy();
  //   this.idIndex = this.idIndex + 1;
  //   this.accountDetailList = [];
  //   this.token = this.storage.retrieve('token');
  //   let headers = new HttpHeaders();
  //    this.dto.token = this.storage.retrieve('token');
  //    headers = headers.set('Authorization', this.dto.token);

  //    let params = new HttpParams();
  
  //    params = params.set('type',this.type);

  //    this.http.get(this.funct.ipaddress + 'payment/GetAccountDetailByAdminID', {params: params, headers: headers}).subscribe(
  //     result => {
  //       this.dto.Response = {};
  //       this.dto.Response = result;
  //       this.accountDetailList = this.dto.Response;
  //       var tot = 0, mytot = 0;
  //        for (let i = 0; i < this.accountDetailList.length; i++) 
  //        {
  //             if(this.accountDetailList[i].type == "TOPUP")
  //             {
  //               tot = parseInt(this.accountDetailList[i].today_reach_amt) + tot;
  //               mytot = parseInt(this.accountDetailList[i].my_amount) + mytot;
  //             }
  //             else{
  //               tot = parseInt(this.accountDetailList[i].today_reach_amt) + tot;
  //               mytot = parseInt(this.accountDetailList[i].my_amount) + mytot;
  //             }

          
  //         }
  //      this.total_today_reach_amt_topup = new Intl.NumberFormat().format(tot);
  //      this.total_my_amount_topup = new Intl.NumberFormat().format(mytot);
  //      this.dtTrigger.next();      
  //     });
  //   this.spinner.hide();
  //   $('#accountDetailData').modal("show");
      
  
  // }

  goModal_old() 
  {  
    var id = 'tblServicePhone' + this.idIndex;
    const tableSelector = '#' + id;
    if ($.fn.DataTable.isDataTable(tableSelector)) {
        $(tableSelector).DataTable().destroy();
    }
    this.idIndex = this.idIndex + 1;
    this.accountDetailList = [];
    this.token = this.storage.retrieve('token');

    let headers = new HttpHeaders();
    this.dto.token = this.storage.retrieve('token');
    headers = headers.set('Authorization', this.dto.token);

    let params = new HttpParams();
    params = params.set('type', this.type);

    this.http.get(this.funct.ipaddress + 'payment/GetAccountDetailByAdminID', { params: params, headers: headers }).subscribe(result => {
        this.dto.Response = result; 
        this.accountDetailList = this.dto.Response;
        let tot = 0, mytot = 0;
        for (let account of this.accountDetailList) {
            tot += parseInt(account.today_reach_amt, 10) || 0; // Default to 0 if NaN
            mytot += parseInt(account.my_amount, 10) || 0; // Default to 0 if NaN
        }

        this.total_today_reach_amt_topup = new Intl.NumberFormat().format(tot);
        this.total_my_amount_topup = new Intl.NumberFormat().format(mytot);

        this.dtTrigger.next(); 
    }, error => {
        console.error('Error fetching account details:', error);
    });

    this.spinner.hide();
    $('#accountDetailData').modal("show"); 
}


goModal() 
{
  const id = 'tblServicePhone' + this.idIndex;
  const tableSelector = $('#' + id);
  if ($.fn.DataTable.isDataTable(tableSelector)) 
  {
      tableSelector.DataTable().clear().destroy();
  }
  this.idIndex += 1;
  this.accountDetailList = [];
  this.token = this.storage.retrieve('token');
  let headers = new HttpHeaders().set('Authorization', this.token);
  let params = new HttpParams().set('type', this.type);

  this.http.get(this.funct.ipaddress + 'payment/GetAccountDetailByAdminID', { params, headers }).subscribe(result => {
      this.dto.Response = result;
      this.accountDetailList = this.dto.Response;
      let tot = 0, mytot = 0;
      for (let account of this.accountDetailList) {
          tot += parseInt(account.today_reach_amt, 10) || 0;
          mytot += parseInt(account.my_amount, 10) || 0;
      }
      this.total_today_reach_amt_topup = new Intl.NumberFormat().format(tot);
      this.total_my_amount_topup = new Intl.NumberFormat().format(mytot);

      this.initializeDataTable(id);
      this.dtTrigger.next();  
  }, error => {
      console.error('Error fetching account details:', error);
  });

  this.spinner.hide();
  $('#accountDetailData').modal("show");
}

initializeDataTable(id: string) {
  $('#' + id).DataTable({
      paging: true,
      searching: true,
      ordering: true
  });
}




  onTypeChange(value: string) {
    console.log("Selected type:", value);    
    if (value === 'TOPUP') 
    {
       this.type = "TOPUP";
      
    } 
    else if (value === 'WITHDRAWAL') 
    {
      this.type = "WITHDRAWAL";
    }
    this.goModal();
  }

  getFirstDeposit()
  {
    this.spinner.show();
    this.token = this.storage.retrieve('token');
    var headers = new HttpHeaders();
    headers = headers.set('Authorization', this.token);
    this.http.get(this.funct.ipaddress + 'transaction/getDailyFirstTopUp', {headers: headers})
    .pipe(
      catchError(this.handleError.bind(this))
     )
    .subscribe(
      result => {
        this.dto.Response = {};
        this.dto.Response = result;
        this.first_deposit = new Intl.NumberFormat().format(this.dto.Response["amount"]);     
        this.first_count_user = new Intl.NumberFormat().format(this.dto.Response["userCount"]);   
        this.spinner.hide();
      }
    );
  }

  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
    this.dtTrigger1.unsubscribe();
 
  }

  handleButtonClick()
  {
     var id1 = 'tblServiceWithdrawal' + this.idpendingIndex;
     var table1 = $('#' + id1).DataTable();
    table1.destroy();
    this.idpendingIndex = this.idpendingIndex + 1;
    this.accountDetailList = [];
    this.token = this.storage.retrieve('token');
    let headers = new HttpHeaders();
     this.dto.token = this.storage.retrieve('token');
     headers = headers.set('Authorization', this.dto.token);
     this.http.get(this.funct.ipaddress + 'transaction/getAdminOutcome', {headers: headers}).subscribe(
      result => {
        this.dto.Response = {};
        this.dto.Response = result;
        this.accountDetailList = this.dto.Response.list;     
        this.total_withdrawal = this.dto.Response.total;  
        this.total_qty = this.dto.Response.tran_qty; 
         this.waveTotal =0;
         this.kpayTotal =0;
         this.ayaPayTotal =0;
         this.cbPayTotal=0;
        
        for (let account of this.accountDetailList) 
        {
          this.waveTotal += account.wave;
          this.kpayTotal += account.kpay ;
          this.ayaPayTotal += account.ayaPay ;
          this.cbPayTotal += account.cbPay ;
        }      
   
       this.dtTrigger1.next();
      });
    this.spinner.hide();
    $('#accountDetailWithdrawalData').modal("show");
  }

a
// Close()
// {
//   this.router.navigate(['/dashboard']).then(() => 
//   {    
//   })
// }

Close() {
  if(!this.storage.retrieve('loadFlag')){
    this.storage.store('loadFlag', 'noLoad');
    setTimeout(function(){
      location.reload();
    }, 1);
  }
  else{
    this.storage.clear('loadFlag');
  }
}

 

}
