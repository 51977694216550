<div class="wrapper">
    <!-- Navbar -->
    <app-header-sidebar></app-header-sidebar>

    <!-- here here here -->
    <!-- Content Wrapper. Contains page content -->
    <div class="content-wrapper">
        <!-- Content Header (Page header) -->
        <!-- /.content-header -->

        <!-- Main content -->
        <section class="content">
            <div class="container-fluid">
                <form>
                    <div class="row">
                        <div class="form-group col-md-2 col-sm-4 col-xs-4" style="display: none;">
                            <label></label>
                            <button type="button" class="btn btn-block btn-success btn-sm" style="margin-top: 4%;"
                                [routerLink]="['/gs-game-detail']">
                                Add New
                            </button>
                        </div>
                        <div class="col-md-12">
                            <div class="card">
                                <div class="card-header">
                                    <h3 class="card-title" style="font-weight: bold;"> Twod Package List</h3>
                                </div>
                                <!-- /.card-header -->
                                <div class="card-body">
                                    <table width='100%' id="{{'tbl2dpackage' + idIndex}}" datatable
                                        [dtTrigger]="dtTrigger" [dtOptions]="dtOptions"
                                        class="table table-bordered table-hover">
                                        <thead>
                                            <tr>
                                                <th style="font-family: unicode;">Package Name</th>
                                                <th style="font-family: unicode;">Amount</th> 
                                                <th style="font-family: unicode;">Odd</th> 
                                                <th style="font-family: unicode;">User limit</th> 
                                                <th style="font-family: unicode;">Total Number</th> 
                                                <th style="font-family: unicode;" *ngIf="hardcode_edit_bool === true">Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr *ngFor="let pklist of packageList">
                                                <td style="font-family: unicode;">{{pklist.package_name}}</td>
                                                <td style="font-family: unicode;">{{pklist.package_amount}}</td> 
                                                <td style="font-family: unicode;">{{pklist.package_odd}}</td> 
                                                <td style="font-family: unicode;">{{pklist.user_limit}}</td>
                                                <td style="font-family: unicode;">{{pklist.total_number}}</td>
                                                <td style="font-family: unicode;" *ngIf="hardcode_edit_bool === true">
                                                    <a [routerLink]="['/twod-package-detail', pklist.id]">
                                                    <i title = "Edit" class="fas fa-edit" style="color:#4b88eb;cursor: pointer;"></i>
                                                    </a>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <!-- /.card-body -->
                            </div>
                        </div>
                    </div>
                </form>
                <!-- /.row -->
                <!-- /.row -->
            </div>
            <!--/. container-fluid -->
        </section>
        <!-- /.content -->
    </div>

    <aside class="control-sidebar control-sidebar-dark">
        <!-- Control sidebar content goes here -->
    </aside>

    <app-footer></app-footer>

    <ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#fff" type="square-jelly-box">
        <p style="font-size: 16px; color: white">Loading...</p>
    </ngx-spinner>

</div>

  <div id="deleteData" class="modal fade">
    <div class="modal-dialog">
        <div class="modal-content">

            <div class="modal-body">
                <form>

                    <div class="row" style="margin-top: 5%;margin-bottom: 3%;margin-left: 9%;">
                        <div class="form-group col-md-12">
                            <h5> This Account is used by another device. 
                                Please Sign Out. Login Again. </h5>
                        </div>
                    </div>

                    <div class="row" style="margin-left: 40%;margin-bottom: 3%;">
                        <div class="col-md-5 col-sm-6" id="deleteBtn">
                            <button type="button" class="btn btn-block btn-success" (click)="OkLogout()"> Ok </button>
                        </div>
                    </div>
                </form>
            </div>

        </div>
    </div>
</div>