import { Component, OnInit, ViewChild } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams ,HttpErrorResponse} from '@angular/common/http';
import { Router, NavigationEnd } from '@angular/router';
import { DataTableDirective } from 'angular-datatables';

import { LocalStorageService } from 'ngx-webstorage';
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from 'ngx-toastr';

import { DtoService } from '../../service/dto.service';
import { FunctService } from '../../service/funct.service';

import { catchError, retry } from 'rxjs/operators';
import { throwError } from 'rxjs';

import { Subject } from 'rxjs';

declare var $: any;

@Component({
  selector: 'app-changepassword',
  templateUrl: './changepassword.component.html',
  styleUrls: ['./changepassword.component.css']
})
export class ChangepasswordComponent implements OnInit {

  @ViewChild(DataTableDirective)
  dtElement: DataTableDirective;
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();

  phoneNo: string= '';
  name: string= '';
  referralCode: string= '';
  status: string= '';
  adminList: any;
  idIndex: any;

  hardcode_edit :any;
  hardcode_view: any;
  hardcode_view_bool : any;
  hardcode_edit_bool : any;
  hardcode_double_bool:any;

  itemsPerPage =  10;
  totalItems : any; 
  page = 1;

  constructor(private storage: LocalStorageService, private spinner: NgxSpinnerService, private http: HttpClient, 
    private dto: DtoService, private router: Router, private funct: FunctService, private toastr: ToastrService) {
      this.storage.store('isNotiSong', "");
    this.idIndex = 1;
  
    this.hardcode_view = "ChangePassword_View";
    this.hardcode_edit = "ChangePassword_Edit";

    this.dto.menuCodes = this.storage.retrieve('menucodes');

    const filteredMenuCodes_view = [];
    const filteredMenuCodes_change =[];
    this.dto.menuCodes.forEach(menuCode => {
      if (menuCode === this.hardcode_view) {
        filteredMenuCodes_view.push(menuCode);
      }  
      if (menuCode === this.hardcode_edit) {
        filteredMenuCodes_change.push(menuCode);
      }
      
    });
    if(filteredMenuCodes_view.length != 0)
    {
          this.hardcode_view_bool = true;
    }    
    if(filteredMenuCodes_change.length != 0)
    {
          this.hardcode_edit_bool = true;
    }


    this.search();
   }

   ngOnInit(): void {

    this.router.routeReuseStrategy.shouldReuseRoute = function () {
      return false;
    };

    this.router.events.subscribe((evt) => {
      if (evt instanceof NavigationEnd) {
        // trick the Router into believing it's last link wasn't previously loaded
        this.router.navigated = false;
        // if you need to scroll back to top, here is the right place
        window.scrollTo(0, 0);
      }
    });


    // this.dtOptions = {
    //   responsive: true,
    //   order:[]
    // }

    this.dtOptions = {
      responsive: true,
      order: [],
      paging: false,
      info : true,
    }


    this.dtOptions.columnDefs = [
      { targets: [5], orderable: false }

    ];

    if(!this.storage.retrieve('loadFlag')){
      this.storage.store('loadFlag', 'noLoad');
      setTimeout(function(){
        location.reload(true);
      }, 5);
    }
    else{
      this.storage.clear('loadFlag');
    }
  }

  handleError(error: HttpErrorResponse){
    if(error.status == 423)
    {
      this.spinner.hide();
      $("#deleteData").modal("show");
    }
    if(error.status == 403)
    {
      this.spinner.hide();
      var id = 'tblChangePassword' + this.idIndex;
      var table = $('#' + id).DataTable();
      this.toastr.error("Limited Access.", 'Invalid!', {
        timeOut: 3000,
        positionClass: 'toast-top-right',
        });
    }
    if ($.fn.DataTable.isDataTable(`#${id}`)) {
      table.destroy();
    }
  
    return throwError(error);
    }
    OkLogout()
    {
      window.location.href ="./ad-login";
    } 

  search(){
    this.adminList = [];
    var id = 'tblChangePassword' + this.idIndex;
    var table = $('#' + id).DataTable();
    table.destroy();
    this.idIndex = this.idIndex +1;
    this.spinner.show();
    this.dto.token = this.storage.retrieve('token');
    let headers = new HttpHeaders();
    headers = headers.set('Authorization',  this.dto.token);
    let params = new HttpParams();
    params = params.set('phoneNo',this.phoneNo).set('name',this.name).set('referralCode',this.referralCode).set('status', this.status).set("pageNumber","1").set("rowsOfPage","10");  
    this.http.get(this.funct.ipaddress + 'admin/adminsByparamsPaging', { params: params, headers: headers } )
    .pipe(
      catchError(this.handleError.bind(this))
     )
    .subscribe(
      result => {
        this.dto.Response = {};
        this.dto.Response = result.results;
        this.adminList = this.dto.Response;    
        this.totalItems = result.totalRows;
        this.dtTrigger.next();
        this.spinner.hide();
      }
    );
  }

  
  gty(page :any){
    this.adminList = [];
    var id = 'tblChangePassword' + this.idIndex;
    var table = $('#' + id).DataTable();
    table.destroy();
    this.idIndex = this.idIndex +1;
    this.spinner.show();
    this.dto.token = this.storage.retrieve('token');
    let headers = new HttpHeaders();
    headers = headers.set('Authorization',  this.dto.token);
    let params = new HttpParams();
    params = params.set('phoneNo',this.phoneNo).set('name',this.name).set('referralCode',this.referralCode).set('status', this.status).set("pageNumber",page.toString()).set("rowsOfPage",this.itemsPerPage.toString());  
    // this.http.get(this.funct.ipaddress + 'admin/adminsByparams', { params: params, headers: headers } )
    this.http.get(this.funct.ipaddress + 'admin/adminsByparamsPaging', { params: params, headers: headers } )
    
    .pipe(
      catchError(this.handleError.bind(this))
     )
    .subscribe(
      result => {
        this.dto.Response = {};
        this.dto.Response = result.results;
        this.adminList = this.dto.Response; 
        this.totalItems = result.totalRows;   
        this.dtTrigger.next();
        this.spinner.hide();
      }
    );
  }

  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }

}
