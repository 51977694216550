
        <section class="content">
            <div class="container-fluid">
                <form>
                    <div class="row">

                        <div class="form-group col-md-2">
                            <label>Number1 </label>
                            <input class="form-control form-control-sm" [(ngModel)]="number1"
                                [ngModelOptions]="{standalone: true}">
                        </div>

                        <div class="form-group col-md-2">
                            <label>Number2 </label>
                            <input class="form-control form-control-sm" [(ngModel)]="number2"
                                [ngModelOptions]="{standalone: true}">
                        </div>

                        <div class="form-group col-md-2">
                            <label>Status</label>
                            <select class="status form-control form-control-sm col-md-12 col-sm-4 col-xs-4" [(ngModel)]="status" [ngModelOptions]="{standalone: true}">
                              <option value="ACTIVE" selected>Active</option>
                              <option value="INACTIVE">Inactive</option>
                            </select>
                          </div>

                        <div class="form-group col-md-2">
                        </div>

                        <div class="form-group col-md-2 col-sm-4 col-xs-4">
                            <label></label>
                            <button id="search" type="button" class="btn btn-block btn-primary btn-sm"
                                style="margin-top: 4%;" (click)="search()">
                                Search
                            </button>
                        </div>

                        <div class="form-group col-md-2 col-sm-4 col-xs-4" *ngIf="hardcode_add_bool === true">
                            <label></label>
                            <button type="button" class="btn btn-block btn-success btn-sm" style="margin-top: 4%;"
                                [routerLink]="['/twod-dream-book-add-new']">
                                Add New
                            </button>
                        </div>

                        <div class="col-md-12">
                            <div class="card">
                                <div class="card-header">
                                    <h3 class="card-title" style="font-weight: bold;">Twod Dream Book List</h3>
                                </div>
                                <!-- /.card-header -->
                                <div class="card-body">
                                    <table width='100%' id="{{'tbldreambook' + idIndex}}" datatable
                                        [dtTrigger]="dtTrigger" [dtOptions]="dtOptions"
                                        class="table table-bordered table-hover">
                                        <thead>
                                            <tr>
                                                <th style="font-family: unicode;">Number1</th>
                                                <th style="font-family: unicode;">Number2</th>
                                                <th style="font-family: unicode;">Description (English)</th> 
                                                <th style="font-family: unicode;">Description (Myanmar)</th> 
                                                <th style="font-family: unicode;">Description (Thailand)</th> 
                                                <th style="font-family: unicode;">Description (Chinese)</th> 
                                                <th style="font-family: unicode;">Status</th> 
                                                <th style="font-family: unicode;">Updated By</th> 
                                                <th style="font-family: unicode;">Updated Date</th> 
                                                <th style="font-family: unicode;">Created By</th> 
                                                <th style="font-family: unicode;">Created Date</th> 
                                                <th style="font-family: unicode;" *ngIf="hardcode_double_bool === true">Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr *ngFor="let dreamBook of dreambookList">
                                                <td style="font-family: unicode;">{{dreamBook.number1}}</td>
                                                <td style="font-family: unicode;">{{dreamBook.number2}}</td>
                                                <td style="font-family: unicode;">{{dreamBook.description_en}}</td> 
                                                <td style="font-family: unicode;">{{dreamBook.description_my}}</td> 
                                                <td style="font-family: unicode;">{{dreamBook.description_th}}</td>
                                                <td style="font-family: unicode;">{{dreamBook.description_zh}}</td>
                                                <td style="font-family: unicode;">{{dreamBook.status}}</td>
                                                <td style="font-family: unicode;">{{dreamBook.updated_by_name}}</td>
                                                <td style="font-family: unicode;">{{dreamBook.updated_date_Str}}</td>
                                              
                                                <td style="font-family: unicode;">{{dreamBook.created_by_name}}</td>
                                                <td style="font-family: unicode;">{{dreamBook.created_date_Str}}</td>
                                            
                                                <td style="font-family: unicode;" *ngIf="hardcode_double_bool === true">
                                                    
                                                    <a [routerLink]="['/twod-dream-book-detail-update', dreamBook.id]" *ngIf="hardcode_edit_bool === true">
                                                    <i title = "Edit" class="fas fa-edit" style="color:#4b88eb;cursor: pointer;"></i>
                                                    </a>

                                                    &nbsp;&nbsp;
                                                    <a *ngIf="hardcode_delete_bool === true">
                                                        <i class="fas fa-trash"  (click)="deleteDreamBook(dreamBook.id)" style="color:#dc3545;cursor: pointer;"></i>
                                                    </a>

                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <!-- /.card-body -->
                            </div>
                        </div>
                    </div>
                </form>
                <!-- /.row -->
                <!-- /.row -->
            </div>
            <!--/. container-fluid -->
        </section>
     


    <ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#fff" type="square-jelly-box">
        <p style="font-size: 16px; color: white">Loading...</p>
    </ngx-spinner>



  <div id="deleteData" class="modal fade">
    <div class="modal-dialog">
        <div class="modal-content">

            <div class="modal-body">
                <form>

                    <div class="row" style="margin-top: 5%;margin-bottom: 3%;margin-left: 9%;">
                        <div class="form-group col-md-12">
                            <h5> This Account is used by another device. 
                                Please Sign Out. Login Again. </h5>
                        </div>
                    </div>

                    <div class="row" style="margin-left: 40%;margin-bottom: 3%;">
                        <div class="col-md-5 col-sm-6" id="deleteBtn">
                            <button type="button" class="btn btn-block btn-success" (click)="OkLogout()"> Ok </button>
                        </div>
                    </div>
                </form>
            </div>

        </div>
    </div>
</div>


<div id="deleteDataForDream" class="modal fade">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-body">
                <form>
                  
                    <div class="row justify-content-center">
                        <div class="col-md-12">
                            <h3 class="text-center">Are you sure you want to delete?</h3>
                        </div>
                    </div>
  
                    <div class="row" style="margin-left: 10%;margin-bottom: 3%;">                   
  
                        <div class="col-md-5">
                          <button type="button" class="btn btn-block btn-danger" (click)="closeTran()"
                          [routerLink]="['/drmhistory/twod']"> Cancel </button>
                        </div>
  
                        <div class="col-md-5">
                          <button type="button" class="btn btn-block btn-success" (click)="deleteById()"> Confirm </button>
                        </div>
  
                    </div>
                </form>
            </div>
        </div>
    </div>
  </div>