<div class="wrapper">
    <app-header-sidebar></app-header-sidebar>
    <div class="content-wrapper">
      <div class="content-header">
        <div class="container-fluid">
          <div class="row mb-2" style="border: 1px solid #ced4da;border-radius: 0.25rem;">
            <div class="col-sm-6">
              <h3 class="m-0 text-dark" style="font-weight: bold;padding: 3px 0px 3px 5px;">  FAQs </h3>
            </div>
          </div>
        </div>
      </div>
      <section class="content">
        <div class="container-fluid">
          <form>
            <div class="row">
              <div class="col-md-12">
                <ul class="nav nav-tabs nav-pills">
                  <li>
                    <a routerLink="/faqsteps/faqsteps-addnew" routerLinkActive="active" class="nav-link" data-toggle="tab"> Add New </a>
                  </li>
                  <li>
                    <a routerLink="/faqsteps/faqsteps-list" routerLinkActive="active" class="nav-link" data-toggle="tab"> FAQs List </a>
                  </li>
                 
                </ul>
              </div>
            </div>
            <div>
              <router-outlet></router-outlet>
            </div>
          </form>

        </div>
      </section>  
     
</div>

  
   
  