<div class="wrapper">
    <!-- Navbar -->
    <app-header-sidebar></app-header-sidebar>

    <!-- here here here -->
    <!-- Content Wrapper. Contains page content -->
    <div class="content-wrapper">
        <!-- Content Header (Page header) -->
        <div class="content-header">
            <div class="container-fluid">
                <!--  <div class="row mb-2">
                    <div class="col-sm-6">
                        <h1 class="m-0 text-dark"> Promotion and News </h1>
                    </div>
                </div> -->
                <!-- /.row -->
            </div><!-- /.container-fluid -->
        </div>
        <!-- /.content-header -->

        <!-- Main content -->
        <section class="content">
            <div class="container-fluid">
                <form>

                    <div class="row">
                        <div class="col-md-12">

                            <div class="card">
                                <div class="card-header">
                                    <h3 class="card-title" style="font-weight: bold;">Game Provider Detail </h3>
                                </div>
                                <!-- /.card-header -->
                                <div class="card-body">
                                    <form>
                                        <div class="row">                                           
                                            <label class="col-md-2"> Provider Name </label>
                                            <div class="form-group col-md-3">
                                                <input class="form-control form-control-sm"
                                                    [(ngModel)]="gameDTO.display_name"
                                                    [ngModelOptions]="{standalone: true}">
                                            </div>
                                           
                                            <label class="col-md-2">Icon Name (Myanmar)</label>
                                            <div class="form-group col-md-3">
                                                <input class="form-control form-control-sm"
                                                    [(ngModel)]="gameDTO.name_my"
                                                    [ngModelOptions]="{standalone: true}">
                                            </div>

                                        
                                        </div>
                                                <div class="row">
                                                    <label class="col-md-2">Is Maintenance?</label>
                                                    <div class="form-group col-md-3">
                                                        <input style = "cursor: pointer;" type="checkbox" [(ngModel)]="gameDTO.isMaintenance"  [ngModelOptions]="{standalone: true}" name="fcheck" id="foo_bar" (click) = "checkUncheck()">
                                                    </div>

                                                    <label class="col-md-2">Icon Name (English)</label>
                                                     <div class="form-group col-md-3">
                                                    <input class="form-control form-control-sm"
                                                    [(ngModel)]="gameDTO.name_th"
                                                    [ngModelOptions]="{standalone: true}">
                                                   </div>

                                                </div>   
                                                
                                                <div class="row">
                                                    <label class="col-md-2"> Home Game Image </label>
                                                    <div class="form-group col-md-3">
                                                        <input #file type="file" accept='image/*'
                                                            (change)="preview(file.files)" /> 
                                                            
                                                            <img [src]="imgURL" height="200" width="255" *ngIf="imgURL"
                                                            style="margin-top: 5%;">
                                                    </div>

                                                    <label class="col-md-2">Icon Name (Thailand)</label>
                                                    <div class="form-group col-md-3">
                                                   <input class="form-control form-control-sm"
                                                   [(ngModel)]="gameDTO.name_zh"
                                                   [ngModelOptions]="{standalone: true}">
                                                  </div>

                                                </div>                                               


                                                <div class="row">
                                                    <label class="col-md-2"> Game Title Image </label>
                                                    <div class="form-group col-md-3">
                                                        <input #file1 type="file" accept='image/*'
                                                            (change)="preview1(file1.files)" /> 

                                                            <img [src]="imgURL1" height="200" width="255" *ngIf="imgURL1"
                                                            style="margin-top: 5%;">
                                                    </div>

                                                    <label class="col-md-2">Icon Name (Chinese)</label>
                                                    <div class="form-group col-md-3">
                                                   <input class="form-control form-control-sm"
                                                   [(ngModel)]="gameDTO.name_en"
                                                   [ngModelOptions]="{standalone: true}">
                                                  </div>
                                                </div>


                                                <div class="row">
                                                    <label class="col-md-2"> Maintaince Image </label>
                                                    <div class="form-group col-md-3">
                                                        <input #file2 type="file" accept='image/*'
                                                            (change)="preview2(file2.files)" /> 

                                                            <img [src]="imgURL2" height="200" width="255" *ngIf="imgURL2"
                                                            style="margin-top: 5%;">
                                                    </div>
                                                </div>

                                                <div class="row">
                                                    <label class="col-md-2">From Date</label>
                                                    <div class="form-group col-md-3">                                            
                                                        <input id="alldate" readonly class="form-control form-control-sm" placeholder="Select Date"                                              
                                                        [ngxDatePicker]="dateInstanceSingle"  [(ngModel)]="alldate"
                                                        [ngModelOptions]="{standalone: true}" (valueChange)="onChangeSingle()">
                                                         <ngx-date-picker #dateInstanceSingle></ngx-date-picker>
                                                    </div>
                                            
                                                    <div class="col-md-1"></div>
                                                    <label class="col-md-2">From Time(24Hours Format)</label>
                                                    <div class="form-group col-md-1">
                                                        <input onKeyPress="if(this.value.length==2) return false;"
                                                        class="form-control form-control-sm" placeholder="HH" [(ngModel)]="fromhours"
                                                        [ngModelOptions]="{standalone: true}" (keypress)="numericOnly($event)">
                                                    </div>
                                                    <div class="form-group col-md-1">
                                                        <input onKeyPress="if(this.value.length==2) return false;"
                                                          class="form-control form-control-sm" placeholder="MM" [(ngModel)]="fromminutes"
                                                          [ngModelOptions]="{standalone: true}" (keypress)="numericOnly($event)">
                                                      </div>
                                                </div>
        
                                                <div class="row">
                                                    <label class="col-md-2">To  Date</label>
                                                    <div class="form-group col-md-3">
                                                        <input id="alltodate" readonly class="form-control form-control-sm" placeholder="Select Date"
                                                        [ngxDatePicker]="dateInstanceSingle1"  [(ngModel)]="alltodate"
                                                        [ngModelOptions]="{standalone: true}" (valueChange)="onChangeAllToDate()">
                                                      <ngx-date-picker #dateInstanceSingle1></ngx-date-picker>
                                                    </div>
                                            
                                                    <div class="col-md-1"></div>
                                                    <label class="col-md-2">To Time (24Hours Format)</label>
                                                    <div class="form-group col-md-1">
                                                        <input onKeyPress="if(this.value.length==2) return false;" class="form-control form-control-sm" placeholder="HH" [(ngModel)]="tohours"
                                                          [ngModelOptions]="{standalone: true}" (keypress)="numericOnly($event)">
                                                    </div>
                                                    <div class="form-group col-md-1">
                                                        <input onKeyPress="if(this.value.length==2) return false;" class="form-control form-control-sm" placeholder="MM" [(ngModel)]="tominutes"
                                                          [ngModelOptions]="{standalone: true}" (keypress)="numericOnly($event)">
                                                      </div>
                                                </div>

                                               
                                                <!-- <div class="row">
                                                    <label class="col-md-2"> Status </label>
                                                    <div class="form-group col-md-3 radio">
                                                        <div class="form-group clearfix">
                                                            <div class="icheck-success d-inline">
                                                                <input type="radio" name="r3" checked id="radioSuccess1"
                                                                    value="ACTIVE" [(ngModel)]="gameDTO.status"
                                                                    [ngModelOptions]="{standalone: true}">
                                                                <label for="radioSuccess1">Active &nbsp;&nbsp;
                                                                </label>
                                                            </div>
                                                            <div class="icheck-success d-inline">
                                                                <input type="radio" name="r3" id="radioSuccess2"
                                                                    value="INACTIVE" [(ngModel)]="gameDTO.status"
                                                                    [ngModelOptions]="{standalone: true}">
                                                                <label for="radioSuccess2">Inactive
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </div>


                                                    <div class="form-group col-md-4 radio">
                                                        <div class="form-group clearfix">
                                                            <div class="icheck-success d-inline">
                                                                <input type="radio" name="r4" checked id="radioSuccess3"
                                                                    value="ACTIVE" [(ngModel)]="gameDTO.decision"
                                                                    [ngModelOptions]="{standalone: true}">
                                                                <label for="radioSuccess3">is Now? &nbsp;&nbsp;
                                                                </label>
                                                            </div>
                                                            <div class="icheck-success d-inline">
                                                                <input type="radio" name="r4" id="radioSuccess4"
                                                                    value="INACTIVE" [(ngModel)]="gameDTO.decision"
                                                                    [ngModelOptions]="{standalone: true}">
                                                                <label for="radioSuccess4">Advance?
                                                                </label>
                                                            </div>

                                                        </div>
                                                    </div>

                                                </div> -->



                                            <div class="row" >
                                                
                                                <label class="col-md-2">Status</label>
                                                <div class="form-group col-md-3 radio">
                                                    <div class="form-group clearfix">
                                                        <div class="icheck-success d-inline">
                                                            <input type="radio" name="r3" checked id="radioSuccess1"
                                                                value="ACTIVE" [(ngModel)]="gameDTO.status"
                                                                [ngModelOptions]="{standalone: true}">
                                                            <label for="radioSuccess1">Active &nbsp;&nbsp;
                                                            </label>
                                                        </div>
                                                        <div class="icheck-success d-inline">
                                                            <input type="radio" name="r3" id="radioSuccess2"
                                                            value="INACTIVE" [(ngModel)]="gameDTO.status"
                                                            [ngModelOptions]="{standalone: true}">
                                                        <label for="radioSuccess2">Inactive
                                                        </label>
                                                        </div>
                                                    </div>
                                                </div>
                                            
                                                <div class="col-md-1"></div>                                       
                                                
                                                <label class="col-md-2">Notification Option</label>
                                                <div class="form-group col-md-3 radio">
                                                    <div class="form-group clearfix">
                                                        <div class="icheck-success d-inline">
                                                            <input type="radio" name="r4" checked id="radioSuccess3"
                                                                value="ACTIVE" [(ngModel)]="gameDTO.decision"
                                                                [ngModelOptions]="{standalone: true}">
                                                            <label for="radioSuccess3">is Now? &nbsp;&nbsp;
                                                            </label>
                                                        </div>
                                                        <div class="icheck-success d-inline">
                                                            <input type="radio" name="r4" id="radioSuccess4"
                                                                value="INACTIVE" [(ngModel)]="gameDTO.decision"
                                                                [ngModelOptions]="{standalone: true}">
                                                            <label for="radioSuccess4">Advance?
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        


                                        <div class="row">
                                            <label class="col-md-2"> Noti Send </label>
                                            <div class="form-group col-md-3 radio">
                                                <div class="form-group clearfix">
                                                    <div class="icheck-success d-inline">
                                                        <input type="radio" name="r7" checked id="radioSuccess5"
                                                            value="true" [(ngModel)]="gameDTO.notSend"
                                                            [ngModelOptions]="{standalone: true}">
                                                        <label for="radioSuccess5">Yes &nbsp;&nbsp;
                                                        </label>
                                                    </div>
                                                    <div class="icheck-success d-inline">
                                                        <input type="radio" name="r7" id="radioSuccess6"
                                                            value="false" [(ngModel)]="gameDTO.notSend"
                                                            [ngModelOptions]="{standalone: true}">
                                                        <label for="radioSuccess6">No
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>


                                        <div class="row" style="margin-top: 1%;">
                                            <div class="col-md-2 col-sm-6">
                                                <button type="button" class="btn btn-block btn-success"
                                                    (click)="goSave()">Save</button>&nbsp;</div>
                                            <div class="col-md-2 col-sm-6">
                                                <button type="button" class="btn btn-block btn-danger"
                                                    [routerLink]="['/game-provider']"> Cancel </button></div>
                                        </div>

                                        <div class="row" style="margin-top: 1%;">
                                            <div class="col-md-2 col-sm-6">
                                            </div>
                                            <div class="col-md-2 col-sm-6">
                                               </div>
                                        </div>

                                        <div class="row" style="margin-top: 1%;">
                                            <div class="col-md-2 col-sm-6">
                                            </div> 
                                            <div class="col-md-2 col-sm-6">
                                               </div>
                                        </div>

                                        <div class="row" style="margin-top: 1%;">
                                            <div class="col-md-2 col-sm-6">
                                            </div> 
                                            <div class="col-md-2 col-sm-6">
                                               </div>
                                        </div>

                                        <div class="row" style="margin-top: 1%;">
                                            <div class="col-md-2 col-sm-6">
                                            </div> 
                                            <div class="col-md-2 col-sm-6">
                                               </div>
                                        </div>
                                        <div class="row" style="margin-top: 1%;">
                                            <div class="col-md-2 col-sm-6">
                                            </div> 
                                            <div class="col-md-2 col-sm-6">
                                               </div>
                                        </div>
                                        <div class="row" style="margin-top: 1%;">
                                            <div class="col-md-2 col-sm-6">
                                            </div> 
                                            <div class="col-md-2 col-sm-6">
                                               </div>
                                        </div>
                                        <div class="row" style="margin-top: 1%;">
                                            <div class="col-md-2 col-sm-6">
                                            </div> 
                                            <div class="col-md-2 col-sm-6">
                                               </div>
                                        </div>


                                    </form>

                                </div>
                            </div>
                        </div>

                    </div>
                    
                 <!-- <div class="row" id="updatedata">
                        <div class="col-md-12">
                            <div class="card">
                                <div class="card-body">
                                    <form>
                                        <div class="row" style="margin-top: 2%;">
                                            <label class="col-md-2"> Created Date </label>
                                            <div class="form-group col-md-3">
                                                <input class="form-control form-control-sm"
                                                    [(ngModel)]="paymentDTO.created_date"
                                                    [ngModelOptions]="{standalone: true}" disabled>
                                            </div>
                                            <div class="col-md-1"></div>
                                            <label class="col-md-2"> Created By</label>
                                            <div class="col-md-3">
                                                <input class="form-control form-control-sm"
                                                    [(ngModel)]="paymentDTO.created_by_name"
                                                    [ngModelOptions]="{standalone: true}" disabled>
                                            </div>
                                        </div>

                                        <div class="row" style="margin-top: 1%;">
                                            <label class="col-md-2"> Updated Date </label>
                                            <div class="form-group col-md-3">
                                                <input class="form-control form-control-sm"
                                                    [(ngModel)]="paymentDTO.updated_date"
                                                    [ngModelOptions]="{standalone: true}" disabled>
                                            </div>
                                            <div class="col-md-1"></div>
                                            <label class="col-md-2"> Updated By </label>
                                            <div class="col-md-3">
                                                <input class="form-control form-control-sm"
                                                    [(ngModel)]="paymentDTO.updated_by_name"
                                                    [ngModelOptions]="{standalone: true}" disabled>
                                            </div>
                                        </div>

                                    </form>
                                </div>
                                /.card-body 
                            </div>
                        </div>
                    </div> -->
                      
                </form>
                <!-- /.row -->

                <!-- /.row -->
            </div>
            <!--/. container-fluid -->
        </section>
        <!-- /.content -->
    </div>

    <aside class="control-sidebar control-sidebar-dark">
        <!-- Control sidebar content goes here -->
    </aside>

    <app-footer></app-footer>

    <ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#fff" type="square-jelly-box">
        <p style="font-size: 16px; color: white">Loading...</p>
    </ngx-spinner>

</div>


<div id="deleteData" class="modal fade">
    <div class="modal-dialog">
        <div class="modal-content">

            <div class="modal-body">
                <form>

                    <div class="row" style="margin-top: 5%;margin-bottom: 3%;margin-left: 9%;">
                        <div class="form-group col-md-12">
                            <h5> This Account is used by another device. 
                                Please Sign Out. Login Again. </h5>
                        </div>
                    </div>

                    <div class="row" style="margin-left: 40%;margin-bottom: 3%;">
                        <div class="col-md-5 col-sm-6" id="deleteBtn">
                            <button type="button" class="btn btn-block btn-success" (click)="OkLogout()"> Ok </button>
                        </div>
                    </div>
                </form>
            </div>

        </div>
    </div>
</div>


