<div class="wrapper">
    <app-header-sidebar></app-header-sidebar>
  
    <div class="content-wrapper">
      <!-- Content Header (Page header) -->
      <div class="content-header">
        <div class="container-fluid">
          <div class="row mb-2" style="border: 1px solid #ced4da;border-radius: 0.25rem;">
            <div class="col-sm-6">
              <h3 class="m-0 text-dark" style="font-weight: bold;padding: 3px 0px 3px 5px;"> Manage Topup </h3>
            </div>
          </div>
          <!-- /.row -->
        </div><!-- /.container-fluid -->
      </div>
      <!-- /.content-header -->
  
      <!-- Main content -->
      <section class="content">
        <div class="container-fluid">
            <div class="row">
                    <div class="form-group col-md-2">
                    <label>Name</label>
                    <input class="form-control form-control-sm" [(ngModel)]="name" [ngModelOptions]="{standalone: true}">
                    </div>
                    <div class="form-group col-md-2">
                    <label>Phone No</label>
                    <input class="form-control form-control-sm" [(ngModel)]="phone_No_topup" [ngModelOptions]="{standalone: true}"  (ngModelChange)="onChangePhoneNo()"
                    (input)="onInput($event)">
                    </div>
                    <div class="form-group col-md-2 col-sm-2 col-xs-2">
                        <label>&nbsp;&nbsp;</label>
                        <button id="search" type="button" class="btn btn-block btn-primary btn-sm" (click)="getAllData()">Search</button>
                    </div>
            </div>
            
            <div class="col-md-12">
                <div class="card">
                  <div class="card-header">
                    <h3 class="card-title" style="font-weight: bold;"> User List </h3>
                  </div>
                  <!-- /.card-header -->
              <!-- /.card-header -->
              <div class="card-body">
                <table width='100%' id="{{'tblUser' + idIndex}}" datatable [dtTrigger]="dtTrigger" [dtOptions]="dtOptions" class="table table-bordered table-hover">
                  <thead>
                    <tr>
                        <th style="font-family: unicode;">Name</th>
                        <th style="font-family: unicode;">Phone No</th>
                        <th style="font-family: unicode;">Wallet</th>
                        <th style="font-family: unicode;">Before Balance</th>
                        <th style="font-family: unicode;">Updated Balance</th>
                        <th style="font-family: unicode;">Total Topup</th>
                        <th style="font-family: unicode;">Status</th>
                        <!-- <th style="font-family: unicode;">Remark</th> -->
                        <th style="font-family: unicode;">Register Date</th>
                        <th style="font-family: unicode;">Latest Updated Date</th>
                        <th style="font-family: unicode;">Latest Updated By</th>
                        <th style="font-family: unicode;" *ngIf="hardcode_edit_bool === true">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                        <tr *ngFor="let user of userList">
                            <td style="font-family: unicode;width: 150px;">{{user.name}}</td>
                            <!-- <td style="font-family: unicode;"><a [routerLink] = "['/manage-topup-cancel', user.id]">{{user.phone_no}}</a></td> -->
                            <td style="font-family: unicode;">
                              <a [routerLink]="['/user-detail', user.id]" [queryParams]="{ type: 'usermanagetopuplist' }">{{user.phone_no}}</a>
                            </td>
                            <td style="font-family: unicode;" class="text-right">{{user.balance_Str}}</td>
                            <td style="font-family: unicode;" class="text-right">{{user.balanceBak | number: '1.0-0'}}</td>
                            <td style="font-family: unicode;" class="text-right">{{user.balance_Str}}</td>
                            <td style="font-family: unicode;" class="text-right">{{user.topup_balance_Str}} </td>
                            <td style="font-family: unicode;">{{user.status}}</td>                            
                            <td style="font-family: unicode;">{{user.created_date_Str}}</td>
                            <td style="font-family: unicode;">{{user.balance_updated_date | date:'yyyy-MM-dd HH:mm:ss'}}</td>
                            <td style="font-family: unicode;">{{user.updatedwallet_by_name}}</td>
                            <td style="font-family: unicode;" *ngIf="hardcode_edit_bool === true">
                            <a>
                                 <i class="fas fa-edit" style="color:#4b88eb" (click) = "getuserDetail(user.id,user.phone_no,user.balance_Str,user.name,user.topup_balance_Str)">
                                </i>
                            </a>
                            </td>
                        </tr>                        
                  </tbody>
                </table>
                  <tr *ngFor="let user of userList | paginate
                  : {
                      itemsPerPage: itemsPerPage,
                      currentPage: page,
                      totalItems: totalItems
                    }" 
                    >
                </tr> 
              <div class = "row">
                <div class = "col-md-6"></div>
                <div class = "col-md-6">
                  <pagination-controls
                    class="pagi"
                    (pageChange)="gty((page = $event))"
                  ></pagination-controls>
              </div>
              </div>
            </div>
              </div>
              </div>
          <!-- /.row -->
        </div>
        <!--/. container-fluid -->
      </section>
      <!-- /.content -->
    </div>
  
    <aside class="control-sidebar control-sidebar-dark">
      <!-- Control sidebar content goes here -->
    </aside>
  
    <app-footer></app-footer>
    <ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#fff" type="square-jelly-box">
      <p style="font-size: 16px; color: white">Loading...</p>
    </ngx-spinner>
  </div>
  
  <div id="deleteData" class="modal fade">
    <div class="modal-dialog">
        <div class="modal-content">
  
            <div class="modal-body">
                <form>
  
                    <div class="row" style="margin-top: 5%;margin-bottom: 3%;margin-left: 9%;">
                        <div class="form-group col-md-12">
                            <h5> This Account is used by another device. 
                                Please Sign Out. Login Again. </h5>
                        </div>
                    </div>
  
                    <div class="row" style="margin-left: 40%;margin-bottom: 3%;">
                        <div class="col-md-5 col-sm-6" id="deleteBtn">
                            <button type="button" class="btn btn-block btn-success" (click)="OkLogout()"> Ok </button>
                        </div>
                    </div>
                </form>
            </div> 
        </div>
    </div>
  </div> 

  <div id="showeditModal" class="modal fade">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h3 class="modal-title" style="font-weight: bold;font-size: 1.1rem;"> Wallet Detail </h3>
          <button type="button" class="close" data-dismiss="modal" aria-hidden="true"> x </button>
        </div>
        <div class="modal-body">
          <form>
            <div class="col-md-12">
              <div class="col-md-12">
                  <div class="card-body">
                    <table class="table table-bordered">
                      <tr>
                         <th>Name</th><td>{{detialName}}</td>
                       </tr>
                      <tr>
                         <th>Phone Number</th><td>{{detailph}}</td>
                      </tr>
                      <tr>
                         <th>Current Balance</th><td>{{wallet}}</td>
                      </tr>
                      <tr>
                        <th>Current Total Topup</th><td>{{withdrawal}}</td>
                     </tr>
                      <tr>
                        <th>Type New Topup Amount</th>
                        <td>
                          <input class="form-control form-control-sm" [(ngModel)]="editTopup" [ngModelOptions]="{standalone: true}">
                        </td>
                      </tr>

                      <tr>
                        <th>Remark</th>
                        <td>
                          <input class="form-control form-control-sm" [(ngModel)]="remarkTopup" [ngModelOptions]="{standalone: true}">
                        </td>
                      </tr>

                      

                    </table>
                    <div class="row" style="display: none;">
                        <label class="col-md-2">Payment</label>
                        <div class="form-group col-md-12">
                            <div class="row">
                                <div id="paymentId" class="col-md-12" align="left">
                                    <div class = "row">
                                    <a *ngFor = "let item of paymentList">
                                        <div class="col-lg-3">
                                            <img id="{{item.id}}" (click) ="changeAction(item.id)" [src]="item.image_url" width = "80px" hegith = "80px" style="margin-top: 5%;object-fit: cover;border: 3px solid #ffffff;border-radius: 10px;border-color: #ffffff;"
                                           >
                                        </div>
                                    </a>
                                </div>
                                    </div>
                                <div id="paymentId1" class="col-md-6"  align="end">
                                </div>
                            </div>
                        </div>
                    </div>
                  </div>
              </div>
            </div>
          </form>
          <div class="row">
            <div class="col-md-4">
              &nbsp;
            </div>
            <div class="col-md-2">
              <button type="button" class="btn btn-block btn-success" (click)="editUserTopup()">Save</button>&nbsp;
            </div>
            <!-- <div class="col-md-2">
              <button type="button" class="btn btn-block btn-danger"
               data-dismiss="modal">Cancel</button>
            </div> -->

            <div class="col-md-2">
              <div style="display: inline-block;">              
                  <button type="button" class="btn btn-block btn-danger" data-dismiss="modal">Cancel</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
   
  