import { Component, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,HttpErrorResponse } from '@angular/common/http';
import { Router, ActivatedRoute   } from '@angular/router';
import { LocalStorageService } from 'ngx-webstorage';
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from 'ngx-toastr';
import { DtoService } from '../../service/dto.service';
import { FunctService } from '../../service/funct.service';
declare var $: any;
import { catchError, retry } from 'rxjs/operators';
import { throwError } from 'rxjs';

@Component({
  selector: 'app-twoDHistoryLog',
  templateUrl: './twoDHistoryLog.component.html',
  styleUrls: ['./twoDHistoryLog.component.css']
})
export class TwoDHistoryLogComponent implements OnInit {

  historyLog: any;
  token: any;
  objectId: any;
  constructor(private storage: LocalStorageService, private spinner: NgxSpinnerService, private toastr: ToastrService, private http: HttpClient, private dto: DtoService, 
    private router: Router, private route: ActivatedRoute, private funct: FunctService) {  
      this.storage.store('isNotiSong', "");     
  }

  ngOnInit(): void {
    if(!this.storage.retrieve('loadFlag')){
      this.storage.store('loadFlag', 'noLoad');
      setTimeout(function(){
        location.reload();
      }, 1);
    }
    else{
      this.storage.clear('loadFlag');
    }

    this.objectId = this.route.snapshot.paramMap.get("objectId");
    this.historyLog = {
      objectId: '',
      section: '',
      set: '',
      value: '',
      result: ''
    }
    
    if(this.objectId != null){      
      this.getHistoryLogByObjectId();     
    }

  }

  getHistoryLogByObjectId(){
    this.token = this.storage.retrieve('token');
    let headers = new HttpHeaders();
    headers = headers.set('Authorization',  this.token);
    let params = new HttpParams();
    params = params.set('objectId',this.objectId);
    this.http.get(this.funct.ipaddress + 'back4app/GetLive2DLogDataByID', {params: params, headers: headers })
    .pipe(
      catchError(this.handleError.bind(this))
     )
    .subscribe(
      result => {
        this.dto.Response = {};
        this.dto.Response = result;
        this.historyLog = this.dto.Response;
      }
    );
  }

  OkLogout()
  {
    window.location.href ="./ad-login";
  } 

  handleError(error: HttpErrorResponse){
    if(error.status == 423)
    {
      this.spinner.hide();
      $("#deleteData").modal("show");
    }
    if(error.status == 403)
    {
      this.spinner.hide();
      this.toastr.error("Limited Access.", 'Invalid!', {
        timeOut: 3000,
        positionClass: 'toast-top-right',
        });
    }
    if(error.status == 400)
    {
      this.spinner.hide();
      this.toastr.error("Invalid Parameters.", 'Invalid!', {
        timeOut: 3000,
        positionClass: 'toast-top-right',
        });
    }
    return throwError(error);
  }

  goSave(){
    this.SaveOREdit();
  }
  
  SaveOREdit(){
    this.spinner.show();
    this.token = this.storage.retrieve('token');
    let headers = new HttpHeaders();
    headers = headers.set('Authorization',  this.token);
    this.http.post(this.funct.ipaddress + 'back4app/SaveOrEditLive2DLogData', this.historyLog, {headers: headers})
    .pipe(
      catchError(this.handleError.bind(this))
     )
    .subscribe(
      result => {
        this.dto.Response = {};
        this.dto.Response = result;
        if (this.dto.Response.status == 'Success') {
          this.spinner.hide();
          this.router.navigate(['/twod-manual-detail']).then(() => {
            this.toastr.success(this.dto.Response.message, 'Success!', {
              timeOut: 3000,
              positionClass: 'toast-top-right'
            });
          })
        }
        else{
          this.spinner.hide();
          this.toastr.error(this.dto.Response.message, 'Invalid!', {
          timeOut: 3000,
          positionClass: 'toast-top-right',
          });
        }
      });
  }
}
