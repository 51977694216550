import { Component, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,HttpErrorResponse } from '@angular/common/http';
import { Router, ActivatedRoute   } from '@angular/router';

import { LocalStorageService } from 'ngx-webstorage';
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from 'ngx-toastr';

import { DtoService } from '../../service/dto.service';
import { FunctService } from '../../service/funct.service';
import { Subject } from 'rxjs';
declare var $: any;

import { catchError, retry } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { analyzeAndValidateNgModules } from '@angular/compiler';

@Component({
  selector: 'app-comment-detail',
  templateUrl: './twod-winner-comment-detail.component.html',
  styleUrls: ['./twod-winner-comment-detail.component.css']
})
export class TwodWinnerCommentDetailComponent implements OnInit {

  dtTrigger: Subject<any> = new Subject();
  idIndex: any;
  dtOptions: DataTables.Settings = {};
  commentDTO: any;
  token: any;
  commentId: any;
  isRealUser: any;
  reply: any;
  fieldTextType: boolean;
  confrimFieldTextType: boolean;
  roleList = [];
  originalAdminDTO:any 
  status_new :any;
  status_old :any;

  constructor(private storage: LocalStorageService, private spinner: NgxSpinnerService, private toastr: ToastrService, private http: HttpClient, private dto: DtoService, 
    private router: Router, private route: ActivatedRoute, private funct: FunctService) { 
      this.idIndex = 1;
      this.storage.store('isNotiSong', "");
    }

  ngOnInit(): void {

    this.dtOptions = {
      responsive: true,
      order: []
    }

    this.dtOptions.columnDefs = [
      { targets: [4], orderable: false }
    ];
    if(!this.storage.retrieve('loadFlag')){
      this.storage.store('loadFlag', 'noLoad');
      setTimeout(function(){
        location.reload();
      }, 1);
    }
    else{
      this.storage.clear('loadFlag');
    }

    
   const adminDataString = localStorage.getItem('cmtData');
   this.originalAdminDTO = JSON.parse(adminDataString);
 
    this.commentId = this.route.snapshot.paramMap.get("id");
    this.isRealUser = this.route.snapshot.paramMap.get("remark");
    this.getCommentById();
    this.commentDTO = {
      id: 0,
      comment_status:false,
      comment: '',
      commented_user:'',
      created_date:new Date(),
      created_date_str:'',
      like_count:0,
      profile_image:'',
      winner_name:'',
      winner_profile:'',
      reply_details:[],
    };

  }
  handleError(error: HttpErrorResponse){
    if(error.status == 423)
    {
      this.spinner.hide();
      $("#deleteData").modal("show");
    }
    if(error.status == 403)
    {
      this.spinner.hide();
      var id = 'tblComment' + this.idIndex;
      var table = $('#' + id).DataTable();
      this.toastr.error("Limited Access.", 'Invalid!', {
        timeOut: 3000,
        positionClass: 'toast-top-right',
        });
    }
    if(error.status == 400)
    {
      this.spinner.hide();
      this.toastr.error("Invalid Parameters.", 'Invalid!', {
        timeOut: 3000,
        positionClass: 'toast-top-right',
        });
    }
    return throwError(error);
    }
    OkLogout()
    {
      window.location.href ="./ad-login";
    } 

  getCommentById(){
    this.token = this.storage.retrieve('token');
    let headers = new HttpHeaders();
    headers = headers.set('Authorization',  this.token);
    let params = new HttpParams();
    params = params.set('cmt_id',this.commentId).set('isRealUser',this.isRealUser);
    this.http.get(this.funct.ipaddress + 'socialmedia/gettwodcommentbyid', {params: params, headers: headers })
    .pipe(
      catchError(this.handleError.bind(this))
     )
    .subscribe(
      result => {
        this.dto.Response = {};
        this.dto.Response = result;
        this.commentDTO = this.dto.Response.data;//.data.commentDTO;
        this.reply = this.commentDTO.reply_details;
        console.log(this.reply);
        this.dtTrigger.next();
        console.log(this.commentDTO);
      }
    );
  }
  toggleActive(item) {
    item.status = !item.status;
    this.token = this.storage.retrieve('token');
    let headers = new HttpHeaders();
    headers = headers.set('Authorization',  this.token);
    let params = {
      cmt_id : item.id,
      status : item.status,
      isRealUser : this.isRealUser
    };
    this.http.post(this.funct.ipaddress + 'socialmedia/change_reply_status', params,{ headers: headers })
    .pipe(
      catchError(this.handleError.bind(this))
     )
    .subscribe(
      result => {
        console.log(result);
      }
    );
  }
  flagRole(){
    this.commentDTO.role_id = $("#role_id").val();
  }

  toggleFieldTextType() {
    this.fieldTextType = !this.fieldTextType;
  }

  toggleConfrimFieldTextType() {
    this.confrimFieldTextType = !this.confrimFieldTextType;
  }

  goSave(){
    this.token = this.storage.retrieve('token');
  // let headers = new HttpHeaders();
  // headers = headers.set('Authorization',  this.token);

  
  let editedArray = this.getEditedArray();   
    let headers = new HttpHeaders();
	
    if(editedArray.length > 0)
    {
      headers = headers.set('Authorization', this.token)
      .set('editedArray', editedArray); 
    }
    else{
      headers = headers.set('Authorization',  this.token);
    } 

  let params = {
    cmt_id : this.commentId,
    status : this.commentDTO.comment_status,
    isRealUser : this.isRealUser,
    formpage : "2D"
  };
  this.http.post(this.funct.ipaddress + 'socialmedia/change_cmt_status', params,{ headers: headers })
  .pipe(
    catchError(this.handleError.bind(this))
   )
  .subscribe(
    result => {
      this.dto.Response = {};
    this.dto.Response = result;
    if (this.dto.Response.status == 'Success') {
      this.spinner.hide();
      this.router.navigate(['/commentsshistory/twod']).then(() => {
        this.toastr.success(this.dto.Response.message, 'Success!', {
          timeOut: 3000,
          positionClass: 'toast-top-right'
        });
      })
    }
    else{
      this.spinner.hide();
      this.toastr.error(this.dto.Response.message, 'Invalid!', {
      timeOut: 3000,
      positionClass: 'toast-top-right',
      });
    }
    }
  );
  }

  
  getEditedArray() {
    let editedArray = '';
    if (this.commentDTO.comment_status !== this.originalAdminDTO.status) 
    {
      if(this.commentDTO.comment_status == true)
      {
           this.status_new = "Active";
      }
      else{
        this.status_new = "InActive";
      }
      if(this.originalAdminDTO.status == true)
      {
           this.status_old = "Active";
      }
      else{
        this.status_old = "InActive";
      }
      editedArray += 'Status: ' + this.status_old + ' -> ' + this.status_new + ', ';
    }
    if (editedArray.endsWith(', ')) {
      editedArray = editedArray.slice(0, -2);
    }
    
    return editedArray;
  }
  
}
