<div class="wrapper">
    <app-header-sidebar></app-header-sidebar>
  
    <div class="content-wrapper">
      <!-- Content Header (Page header) -->
      <div class="content-header">
        <div class="container-fluid">
          <div class="row mb-2" style="border: 1px solid #ced4da;border-radius: 0.25rem;">
            <div class="col-sm-6">
              <h3 class="m-0 text-dark" style="font-weight: bold;padding: 3px 0px 3px 5px;"> Manage Topup/Withdrawal Logs </h3>
            </div>
          </div>
          <!-- /.row -->
        </div><!-- /.container-fluid -->
      </div>
      <!-- /.content-header -->
  
      <!-- Main content -->
      <section class="content">
        <div class="container-fluid">
            <div class="row">
                    <div class="form-group col-md-2">
                    <label>Name</label>
                    <input class="form-control form-control-sm" [(ngModel)]="name" [ngModelOptions]="{standalone: true}">
                    </div>
                    <div class="form-group col-md-2">
                    <label>Phone No</label>
                    <input class="form-control form-control-sm" [(ngModel)]="phoneNo_wallet" [ngModelOptions]="{standalone: true}" (ngModelChange)="onChangePhoneNo()" (input)="onInput($event)">
                    </div>
                    <div class="form-group col-md-2 col-sm-2 col-xs-2">
                        <label>&nbsp;&nbsp;</label>
                        <button id="search" type="button" class="btn btn-block btn-primary btn-sm" (click)="getAllData()">Search</button>
                    </div>
            </div>
            
            <div class="col-md-12">
                <div class="card">
                  <div class="card-header">
                    <h3 class="card-title" style="font-weight: bold;"> User List </h3>
                  </div>
                  <!-- /.card-header -->
              <!-- /.card-header -->
              <div class="card-body">
                <table width='100%' id="{{'tblUser' + idIndex}}" datatable [dtTrigger]="dtTrigger" [dtOptions]="dtOptions" class="table table-bordered table-hover">
                  <thead>
                    <tr>
                        <th style="font-family: unicode;">Name</th>
                        <th style="font-family: unicode;">Phone No</th>
                        <th style="font-family: unicode;">Old Wallet</th>
                        <th style="font-family: unicode;">Old Total Topup</th>
                        <th style="font-family: unicode;">Old Total Withdrawal</th>
                        <th style="font-family: unicode;">New Wallet</th>
                        <th style="font-family: unicode;">New Total Topup</th>
                        <th style="font-family: unicode;">New Total Withdrawal</th>
                        <th style="font-family: unicode;background-color: orange;color: white;">Action Type</th>
                        <th style="font-family: unicode;">Created Date</th>
                        <th style="font-family: unicode;">Inserted Amount</th>
                        <th style="font-family: unicode;">Status</th>
                        <th style="font-family: unicode;">Remark</th>
                        <th style="font-family: unicode;">Different Amount</th>
                        <th style="font-family: unicode;">Created By</th>
                        <!-- <th style="font-family: unicode;" >Action</th> -->
                    </tr>
                  </thead>
                  <tbody>
                        <tr *ngFor="let user of userList">
                            <td style="font-family: unicode;width: 150px;">{{user.userName}}</td>
                            <!-- <td style="font-family: unicode;"><a [routerLink] = "['/manage-wallet-log-cancel', user.userId]">{{user.phoneNo}}</a></td> -->
                            <td style="font-family: unicode;">
                              <a [routerLink]="['/user-detail', user.userId]" [queryParams]="{ type: 'topupwithdrawallog' }">{{user.phoneNo}}</a>
                            </td>
                            <td style="font-family: unicode;" class="text-right">{{user.oldwallet | number:'1.0-0'}}</td>
                            <td style="font-family: unicode;" class="text-right">{{user.oldTotaltopup | number:'1.0-0'}} </td>
                            <td style="font-family: unicode;">{{user.oldTotalwithdrawal | number:'1.0-0'}}</td>
                            <td style="font-family: unicode;">{{user.newwallet | number:'1.0-0'}}</td>
                            <td style="font-family: unicode;">{{user.newTotaltopup | number:'1.0-0'}}</td>
                            <td style="font-family: unicode;">{{user.newTotalwithdrawal | number:'1.0-0'}}</td>
                            <td style="font-family: unicode;background-color: orange;color: white;">{{user.actionType}}</td>
                            <td style="font-family: unicode;">{{user.created_date | date : 'yyyy-MM-dd HH:mm:ss'}}</td>
                            <td style="font-family: unicode;">{{user.insertAmount | number:'1.0-0'}}</td>
                            <td style="font-family: unicode;">{{user.status}}</td>
                            <td style="font-family: unicode;">{{user.remark}}</td>
                            <td style="font-family: unicode;">{{user.differentAmount | number:'1.0-0'}}</td>
                            <td style="font-family: unicode;">{{user.created_by_name}}</td>
                          
                        </tr>                        
                  </tbody>
                </table>
                  <tr *ngFor="let user of userList | paginate
                  : {
                      itemsPerPage: itemsPerPage,
                      currentPage: page,
                      totalItems: totalItems
                    }" 
                    >
                </tr> 
              <div class = "row">
                <div class = "col-md-6"></div>
                <div class = "col-md-6">
                  <pagination-controls
                    class="pagi"
                    (pageChange)="gty((page = $event))"
                  ></pagination-controls>
              </div>
              </div>
            </div>
              </div>
              </div>
          <!-- /.row -->
        </div>
        <!--/. container-fluid -->
      </section>
      <!-- /.content -->
    </div>
  
    <aside class="control-sidebar control-sidebar-dark">
      <!-- Control sidebar content goes here -->
    </aside>
  
    <app-footer></app-footer>
    <ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#fff" type="square-jelly-box">
      <p style="font-size: 16px; color: white">Loading...</p>
    </ngx-spinner>
  </div>
  
  <div id="deleteData" class="modal fade">
    <div class="modal-dialog">
        <div class="modal-content">
  
            <div class="modal-body">
                <form>
  
                    <div class="row" style="margin-top: 5%;margin-bottom: 3%;margin-left: 9%;">
                        <div class="form-group col-md-12">
                            <h5> This Account is used by another device. 
                                Please Sign Out. Login Again. </h5>
                        </div>
                    </div>
  
                    <div class="row" style="margin-left: 40%;margin-bottom: 3%;">
                        <div class="col-md-5 col-sm-6" id="deleteBtn">
                            <button type="button" class="btn btn-block btn-success" (click)="OkLogout()"> Ok </button>
                        </div>
                    </div>
                </form>
            </div> 
        </div>
    </div>
  </div> 

 
  