import { Component, OnInit, ViewChild } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, HttpErrorResponse } from '@angular/common/http';
import { Router,ActivatedRoute, NavigationEnd } from '@angular/router';
import { DataTableDirective } from 'angular-datatables';
import { ToastrService } from 'ngx-toastr';

import { LocalStorageService } from 'ngx-webstorage';
import { NgxSpinnerService } from "ngx-spinner";

import { catchError, retry } from 'rxjs/operators';
import { throwError } from 'rxjs';

import { DtoService } from '../../service/dto.service';
import { FunctService } from '../../service/funct.service';

import { Subject } from 'rxjs';
import Responsive from 'datatables.net-responsive';
import { de } from 'date-fns/locale';
import { DatePipe } from '@angular/common';

declare var $: any;

@Component({
  selector: 'app-game-transaction',
  templateUrl: './game-transaction.component.html',
  styleUrls: ['./game-transaction.component.css']
})
export class GameTransactionComponent implements OnInit {

  @ViewChild(DataTableDirective)
  dtElement: DataTableDirective;
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();

  phoneNo: string = '';
  transactionNo: string = '';
  amount: string = '';
  name: string = '';
  referralCode: string = '';
  status: any;
  gameTransactionErrorList: any;
  idIndex: any;
  alldate: any;
  transactionNumber: any;
  token: any;
  isAbnormal: any;

  hardcode_delete: any;
  hardcode_view: any;
  hardcode_view_bool: any;
  hardcode_delete_bool: any;
  isChecked: any = false;
  confirmallArr = [];
  transaction_number_temp: any;
  isTrue: any;

  itemsPerPage =  10;
  totalItems : any; 
  page = 1;


  constructor(private storage: LocalStorageService, private spinner: NgxSpinnerService, private toastr: ToastrService, private http: HttpClient, private dto: DtoService, private router: Router, private funct: FunctService) 
  {
    this.phoneNo ="";
    this.idIndex = 1;
    this.status = 'ACTIVE';
    this.storage.store('isNotiSong', "");
    this.hardcode_view = "Cash_View";
    this.hardcode_delete = "Cash_Del";
    this.dto.menuCodes = this.storage.retrieve('menucodes');
    const filteredMenuCodes_view = [];
    const filteredMenuCodeds_delete = [];

    this.dto.menuCodes.forEach(menuCode => {
      if (menuCode === this.hardcode_view) {
        filteredMenuCodes_view.push(menuCode);
      }
      if (menuCode === this.hardcode_delete) {
        filteredMenuCodeds_delete.push(menuCode);
      }
    });
    if (filteredMenuCodes_view.length != 0) {
      this.hardcode_view_bool = true;
    }
    if (filteredMenuCodeds_delete.length != 0) {
      this.hardcode_delete_bool = true;
    }
    this.search();
    if (this.dto.isGameTransaction == "h" || this.dto.isGameTransaction == undefined) 
    {
      this.storage.store('phoneNo', '');
      this.storage.store('status', '');
    }
    else 
    {
      if (!this.storage.retrieve('phoneNo')) 
      {
        this.phoneNo = '';
      }
      else 
      {
        this.phoneNo = this.storage.retrieve('phoneNo');
      }
    }

  }

  ngOnInit(): void {
    console.log("ngOnInit");
    this.router.routeReuseStrategy.shouldReuseRoute = function () {
      return false;
    };
    this.router.events.subscribe((evt) => {
      if (evt instanceof NavigationEnd) {
        this.router.navigated = false;
        window.scrollTo(0, 0);
      }
    });

    this.dtOptions = {
      // responsive: true,
      responsive: {
        details: {
            renderer: Responsive.renderer.listHiddenNodes()
        }
    },
      stateSave: true,
      order: []
    }
    // this.dtOptions.columnDefs = [
    // ];

    this.dtOptions.columnDefs = [
      { targets: [0], orderable: false },
      { targets: [9], orderable: false }
    ];

    if (!this.storage.retrieve('loadFlag')) {
      this.storage.store('loadFlag', 'noLoad');
      setTimeout(function () {
        location.reload();
      }, 5);
    }
    else {
      this.storage.clear('loadFlag');
    }
  }
  ngAfterViewInit() {
  }

  handleError(error: HttpErrorResponse) {
    if (error.status == 423) {
      this.spinner.hide();
     // $("#deleteData").modal("show");
    }
    if (error.status == 403) {
      this.spinner.hide();
      var id = 'tblAdmin' + this.idIndex;
      var table = $('#' + id).DataTable();
      this.toastr.error("Limited Access.", 'Invalid!', {
        timeOut: 3000,
        positionClass: 'toast-top-right',
      });
    }
    return throwError(error);
  }
  OkLogout() {
    window.location.href = "./ad-login";
  }

  search_old() {
    this.gameTransactionErrorList = [];
    var id = 'tblAdmin' + this.idIndex;
    var table = $('#' + id).DataTable();
    table.destroy();
    this.idIndex = this.idIndex + 1;
    this.spinner.show();
    this.dto.token = this.storage.retrieve('token');
    this.phoneNo = this.storage.retrieve('phoneNo');
    let headers = new HttpHeaders();
    this.alldate = $("#alldate").val();
    headers = headers.set('Authorization', this.dto.token).set("Roles", "SUPER_ADMIN");
    let params = new HttpParams();

    if (!this.storage.retrieve('phoneNo')) {
      this.phoneNo = '';
    }
    else {
      this.phoneNo = this.storage.retrieve('phoneNo');
    }
    if (this.isAbnormal == undefined) {      
      this.isAbnormal = true;
    }
    if (this.isAbnormal == "") {
      this.isAbnormal = "";
    }
    params = params.set('phone_no', this.phoneNo).set('amount', this.amount).set('transaction_number', this.transactionNo).set('isAbnormal', this.isAbnormal).set("pageNumber", "1").set("rowsOfPage","500");
    this.http.get(this.funct.ipaddress + 'transaction/gamecashlist', { params: params, headers: headers }).
      pipe(
        catchError(this.handleError.bind(this))
      )
      .subscribe(
        results => {
          this.dto.Response = {};
          this.dto.Response = results.results;
          this.gameTransactionErrorList = this.dto.Response;
          this.dtTrigger.next();
          this.spinner.hide();

        }
      );
  }


  
  // gty(page: any)
  // {
  //   this.gameTransactionErrorList = [];
  //   var id = 'tblAdmin' + this.idIndex;
  //   var table = $('#' + id).DataTable();
  //   table.destroy();
  //   this.idIndex = this.idIndex + 1;
  //   this.spinner.show();
  //   this.dto.token = this.storage.retrieve('token');
  //   this.phoneNo = this.storage.retrieve('phoneNo');
  //   let headers = new HttpHeaders();
  //   this.alldate = $("#alldate").val();
  //   headers = headers.set('Authorization', this.dto.token).set("Roles", "SUPER_ADMIN");
  //   let params = new HttpParams();

  //   if (!this.storage.retrieve('phoneNo')) {
  //     this.phoneNo = '';
  //   }
  //   else {
  //     this.phoneNo = this.storage.retrieve('phoneNo');
  //   }
  //   if (this.isAbnormal == undefined) {      
  //     this.isAbnormal = true;
  //   }
  //   if (this.isAbnormal == "") {
  //     this.isAbnormal = "";
  //   }
  //   params = params.set('phone_no', this.phoneNo).set('amount', this.amount).set('transaction_number', this.transactionNo).set('isAbnormal', this.isAbnormal).set("pageNumber",page.toString()).set("rowsOfPage",this.itemsPerPage.toString());
  //   this.http.get(this.funct.ipaddress + 'transaction/gamecashlist', { params: params, headers: headers }).
  //     pipe(
  //       catchError(this.handleError.bind(this))
  //     )
  //     .subscribe(
  //       results => {
  //         this.dto.Response = {};
  //         this.dto.Response = results.results;
  //         this.gameTransactionErrorList = this.dto.Response;
  //         this.totalItems = results.totalRows;
  //         this.dtTrigger.next();
  //         this.spinner.hide();

  //       }
  //     );
  //   if(this.phoneNo != undefined && this.name != undefined)
  //   {
  //     params = params.set('phoneNo',this.phoneNo).set('name',this.name)
  //   }
    
  // }


  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }
  delete(transaction_number) {
    $("#deleteData").modal("show");
    this.transactionNumber = transaction_number;

  }
  btncancel() {    
    this.router.navigate(['/game-transaction-list']).then(() => 
    {
      // this.toastr.error("Game Transaction delete", 'Cancel!', {
      //   timeOut: 3000,
      //   positionClass: 'toast-top-right',
      // });
    });
  }
  deleteByTransactionNumber() 
  { 
    this.spinner.show();
    console.log('this.transactionNumber>> ' + this.transactionNumber);

    this.token = this.storage.retrieve('token');
    let headers = new HttpHeaders();
    headers = headers.set('Authorization', this.token);
    let params = new HttpParams();
    params = params.set('referenceid', this.transactionNumber);
    this.http.get(this.funct.ipaddress + 'loginGS/deleteAbnormalTrans', { params: params, headers: headers }).subscribe(
      result => {
        this.dto.Response = {};
        this.dto.Response = result;
        if (this.dto.Response.status == 'Success') {
          this.spinner.hide();
          this.router.navigate(['/game-transaction-list']).then(() => {
            this.toastr.success(this.dto.Response.message, 'Success!', {
              timeOut: 3000,
              positionClass: 'toast-top-right'
            });
          })

        }
        else {
          this.spinner.hide();
          this.router.navigate(['/game-transaction-list']).then(() => {
            this.toastr.error(this.dto.Response.message.message, 'Fail!', {
              timeOut: 3000,
              positionClass: 'toast-top-right',
            });
          })
        }
      });
  }




  confirmAll() {
    this.spinner.show();
    this.dto.token = this.storage.retrieve('token');
    let headers = new HttpHeaders();
    headers = headers.set('Authorization', this.dto.token);
    let formData = new FormData();
    let deleteString = ""; 
    this.confirmallArr.forEach((referenceId: string, index: number) => {
      formData.append(`referenceids[${index}]`, referenceId);
    });

    if(this.confirmallArr.length == 0)
    {
      this.spinner.hide();
              this.toastr.error(this.dto.Response.message, 'Invalid!', {
                timeOut: 3000,
                positionClass: 'toast-top-right',
              });
              return;
    }

 

    this.http.post(this.funct.ipaddress + 'loginGS/batchDeleteAbnormalTrans', formData, { headers: headers })
      .pipe(
        catchError(this.handleError.bind(this))
      )
      .subscribe(
        result => {
          this.dto.Response = {};
          this.dto.Response = result;
          if (this.dto.Response.status == 'Success') {
            this.spinner.hide();
            this.router.navigate(['/game-transaction-list']).then(() => {
              this.toastr.success(this.dto.Response.message, 'Success!', {
                timeOut: 3000,
                positionClass: 'toast-top-right'
              });
            })

          }
          else {
            this.spinner.hide();
            this.toastr.error(this.dto.Response.message, 'Invalid!', {
              timeOut: 3000,
              positionClass: 'toast-top-right',
            });
          }
          this.spinner.hide();
        });
  }


  // checkedData() 
  // {
  //   this.isTrue =false;
  //   for (let i = 0; i < this.gameTransactionErrorList.length; i++)
  //   {
  //     if (this.gameTransactionErrorList[i].transaction_number == "check") 
  //     {
  //       this.isTrue = true; 
  //       this.confirmallArr.push(this.transaction_number_temp);
  //       this.gameTransactionErrorList[i].transaction_number = this.transaction_number_temp;
  //     } 
  //     if(this.gameTransactionErrorList[i].transaction_number == "uncheck")
  //     {
  //       this.gameTransactionErrorList[i].transaction_number = this.transaction_number_temp;
  //     }    

  //   }
  //   console.log("this.confirmallArr >>" + JSON.stringify(this.confirmallArr));
  // }



  checkedData() {
    this.isTrue = false;

    for (let i = 0; i < this.gameTransactionErrorList.length; i++) {
      if (this.gameTransactionErrorList[i].transaction_number === "check") {
        this.isTrue = true;
        this.gameTransactionErrorList[i].transaction_number = this.transaction_number_temp;
        this.confirmallArr.push(this.transaction_number_temp);
        this.gameTransactionErrorList[i].transaction_number = this.transaction_number_temp;
      }
      if (this.gameTransactionErrorList[i].transaction_number === "uncheck") 
      {
        this.gameTransactionErrorList[i].transaction_number = this.transaction_number_temp;
        if (this.confirmallArr[i] === this.transaction_number_temp) 
        {      
          this.confirmallArr.splice(i, 1);         
          i--;
      }
       
      }
    }
    console.log("this.confirmallArr >>" + JSON.stringify(this.confirmallArr));
  }


  checkuncheckall() {
    if (this.isChecked == true) {
      this.isChecked = false;
    }
    else {
      this.isChecked = true;
    }
  }


  changeSelection(ev, transaction_number) 
  {
      if(this.isChecked == true )
      {
        var target = ev.target;
        if (target.checked) {
          for (let i = 0; i < this.gameTransactionErrorList.length; i++) {
            transaction_number ='';
            transaction_number = this.gameTransactionErrorList[i].transaction_number;
            this.gameTransactionErrorList[i].transaction_number = "check";
            this.transaction_number_temp = transaction_number;
            this.checkedData();
          }
        }      
        
        else if (!target.checked) {
          for (let i = 0; i < this.gameTransactionErrorList.length; i++) {
            transaction_number ='';
            transaction_number = this.gameTransactionErrorList[i].transaction_number;
            this.gameTransactionErrorList[i].transaction_number = "uncheck";
            this.transaction_number_temp = transaction_number;
            this.checkedData();
          }
        } 
    }    
    else
    {
      var target = ev.target;
      if (target.checked && transaction_number
        == 0) 
        {
        for (let i = 0; i < this.gameTransactionErrorList.length; i++) {
          this.gameTransactionErrorList[i].transaction_number = "check";
          this.transaction_number_temp = transaction_number;
        }
      }
      else if (target.checked && transaction_number != 0) {
        for (let i = 0; i < this.gameTransactionErrorList.length; i++) {
          if (this.gameTransactionErrorList[i].transaction_number == transaction_number) 
          {
            this.gameTransactionErrorList[i].transaction_number = "check";
            this.transaction_number_temp = transaction_number;
          }
        }
      }
      else if (!target.checked && transaction_number == 0) {
        for (let i = 0; i < this.gameTransactionErrorList.length; i++) 
        {
          transaction_number = this.gameTransactionErrorList[i].transaction_number;//
          this.gameTransactionErrorList[i].transaction_number = "uncheck";
          this.transaction_number_temp = transaction_number;
        }
      }
      else if (!target.checked && transaction_number != 0) {
        for (let i = 0; i < this.gameTransactionErrorList.length; i++)
         {
          if (this.gameTransactionErrorList[i].transaction_number == transaction_number)
           {
              transaction_number = this.gameTransactionErrorList[i].transaction_number;//
              this.gameTransactionErrorList[i].transaction_number = "uncheck";
              this.transaction_number_temp = transaction_number;
          }
        }
      }
      //this.checkedData();
    }
    this.checkedData();
  }




  onChangePhoneNo() {
    this.storage.store('phoneNo', this.phoneNo.toString());
  }



  //amk added for paging 

  					
  search() 
  {
    this.gameTransactionErrorList = [];
    var id = 'tblAdmin' + this.idIndex;
    var table = $('#' + id).DataTable();
    table.destroy();
    this.idIndex = this.idIndex + 1;
    this.spinner.show();
    this.dto.token = this.storage.retrieve('token');  
    this.phoneNo = this.storage.retrieve('phoneNo');
    let headers = new HttpHeaders();
    this.alldate = $("#alldate").val();
    headers = headers.set('Authorization', this.dto.token).set("Roles", "SUPER_ADMIN");
    let params = new HttpParams();

    if (!this.storage.retrieve('phoneNo')) {
      this.phoneNo = '';
    }
    else {
      this.phoneNo = this.storage.retrieve('phoneNo');
    }
    if (this.isAbnormal == undefined) {      
      this.isAbnormal = true;
    }
    if (this.isAbnormal == "") {
      this.isAbnormal = "";
    }
    params = params.set('phone_no', this.phoneNo).set('amount', this.amount).set('transaction_number', this.transactionNo).set('isAbnormal', this.isAbnormal).set("pageNumber", "1").set("rowsOfPage",this.itemsPerPage.toString());
    this.http.get(this.funct.ipaddress + 'transaction/gamecashlist', { params: params, headers: headers }).
      pipe(
        catchError(this.handleError.bind(this))
      )
      .subscribe(
        results => {
          this.dto.Response = {};
          this.dto.Response = results.results;
          this.gameTransactionErrorList = this.dto.Response;
          this.totalItems = results.totalRows;     
          this.dtTrigger.next();
          this.spinner.hide();

        }
      );   

  }


  gty(page: any)
  {
    
    this.gameTransactionErrorList = [];
    var id = 'tblAdmin' + this.idIndex;
    var table = $('#' + id).DataTable();
    table.destroy();
    this.idIndex = this.idIndex + 1;
    this.spinner.show();
    this.dto.token = this.storage.retrieve('token');  
    this.phoneNo = this.storage.retrieve('phoneNo');
    let headers = new HttpHeaders();
    this.alldate = $("#alldate").val();
    headers = headers.set('Authorization', this.dto.token).set("Roles", "SUPER_ADMIN");
    let params = new HttpParams();

    if (!this.storage.retrieve('phoneNo')) {
      this.phoneNo = '';
    }
    else {
      this.phoneNo = this.storage.retrieve('phoneNo');
    }
    if (this.isAbnormal == undefined) {      
      this.isAbnormal = true;
    }
    if (this.isAbnormal == "") {
      this.isAbnormal = "";
    }
    params = params.set('phone_no', this.phoneNo).set('amount', this.amount).set('transaction_number', this.transactionNo).set('isAbnormal', this.isAbnormal).set("pageNumber", page).set("rowsOfPage",this.itemsPerPage.toString());
    this.http.get(this.funct.ipaddress + 'transaction/gamecashlist', { params: params, headers: headers }).
      pipe(
        catchError(this.handleError.bind(this))
      )
      .subscribe(
        results => {
          this.dto.Response = {};
          this.dto.Response = results.results;
          this.gameTransactionErrorList = this.dto.Response;
          this.totalItems = results.totalRows;     
          this.dtTrigger.next();
          this.spinner.hide();

        }
      );   

  }




}