import { Component, OnInit, ViewChild } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams ,HttpErrorResponse} from '@angular/common/http';
import { Router, NavigationEnd } from '@angular/router';
import { DataTableDirective } from 'angular-datatables';
import { ToastrService } from 'ngx-toastr';

import { LocalStorageService } from 'ngx-webstorage';
import { NgxSpinnerService } from "ngx-spinner";

import { catchError, retry } from 'rxjs/operators';
import { throwError } from 'rxjs';

import { DtoService } from '../../service/dto.service';
import { FunctService } from '../../service/funct.service';

import { Subject } from 'rxjs';
declare var $: any;

@Component({
  selector: 'app-game-transactions',
  templateUrl: './game-transactions.component.html',
  styleUrls: ['./game-transactions.component.css']
})
export class GameTransactionsComponent implements OnInit {

  @ViewChild(DataTableDirective)
  dtElement: DataTableDirective;
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();

  phoneNo: string = '';
  transactionNo: string ='';
  amount:string ='';
  name: string = '';
  referralCode: string = '';
  status: string = '';
  gameTransactionErrorList: any;
  idIndex: any;
  alldate: any;

  hardcode_edit :any;
  hardcode_add :any;
  hardcode_view: any;
  hardcode_delete:any;
  hardcode_view_bool : any;
  hardcode_add_bool : any;
  hardcode_edit_bool : any;
  hardcode_delete_bool:any;
  hardcode_double_bool:any;
  isChecked: any = false;
  confirmallArr:any;

  constructor(private storage: LocalStorageService, private spinner: NgxSpinnerService,private toastr: ToastrService, private http: HttpClient, private dto: DtoService, private router: Router, private funct: FunctService) {
    this.idIndex = 1;
    this.storage.store('isNotiSong', "");
    this.hardcode_delete="GameTransaction_Del"; 
    
    this.dto.menuCodes = this.storage.retrieve('menucodes');

    const filteredMenuCodes_delete =[];
    this.dto.menuCodes.forEach(menuCode => {
   
      if (menuCode === this.hardcode_delete) {
        filteredMenuCodes_delete.push(menuCode);
      }
      
    });
    
    if(filteredMenuCodes_delete.length != 0)
    {
          this.hardcode_delete_bool = true;
    }

    this.status = 'ACTIVE';
    this.search();
  }

  ngOnInit(): void {
    console.log("ngOnInit");
    this.router.routeReuseStrategy.shouldReuseRoute = function () {
      return false;
    };

    this.router.events.subscribe((evt) => {
      if (evt instanceof NavigationEnd) {
        this.router.navigated = false;
        window.scrollTo(0, 0);
      }
    });

    this.dtOptions = {
      responsive: true,
      stateSave : true,
      order: []
    }  
    this.dtOptions.columnDefs = [
    ];

    if (!this.storage.retrieve('loadFlag')) {
      this.storage.store('loadFlag', 'noLoad');
      setTimeout(function () {
        location.reload();
      }, 5);
    }
    else {
      this.storage.clear('loadFlag');
    }
  }
  ngAfterViewInit() {
  }

  handleError(error: HttpErrorResponse){
    if(error.status == 423)
    {
      this.spinner.hide();
      $("#deleteData").modal("show");
    }
    if(error.status == 403)
    {
      this.spinner.hide();
      var id = 'tblAdmin' + this.idIndex;
      var table = $('#' + id).DataTable();
      this.toastr.error("Limited Access.", 'Invalid!', {
        timeOut: 3000,
        positionClass: 'toast-top-right',
        });
    }
    return throwError(error);
    }
    OkLogout()
    {
      window.location.href ="./ad-login";
    } 

  search() {
    this.gameTransactionErrorList = [];
    var id = 'tblAdmin' + this.idIndex;
    var table = $('#' + id).DataTable();
    table.destroy();
    this.idIndex = this.idIndex + 1;
    this.spinner.show();
    this.dto.token = this.storage.retrieve('token');
    let headers = new HttpHeaders();
    this.alldate = $("#alldate").val(); 
    headers = headers.set('Authorization', this.dto.token).set("Roles","SUPER_ADMIN");
    let params = new HttpParams();
    
    params = params.set('phone_no', this.phoneNo).set('amount', this.amount).set('transaction_number', this.transactionNo).set("pageNumber","1").set("rowsOfPage","50");
    this.http.get(this.funct.ipaddress + 'transaction/gamecashretrylist', { params: params, headers: headers }).
    pipe(
      catchError(this.handleError.bind(this))
     )
    .subscribe(
        results => {
        this.dto.Response = {};
        this.dto.Response = results.results;
        //console.log("Ress" +this.dto.Response.name)
        this.gameTransactionErrorList = this.dto.Response;
        this.dtTrigger.next();
        this.spinner.hide();
       
      }
    );
  }


  confirmAll()
  {
      this.spinner.show();
      this.dto.token = this.storage.retrieve('token');
      let headers = new HttpHeaders();
      headers = headers.set('Authorization', this.dto.token); 
      let formData = new FormData();
      formData.append("ids", this.confirmallArr);
   
      this.http.post(this.funct.ipaddress + 'loginGS/batchDeleteAbnormalTrans', formData, { headers: headers })
      .pipe(
        catchError(this.handleError.bind(this))
       )
      .subscribe(
        result => {
          this.dto.Response = {};
          this.dto.Response = result;
          if (this.dto.Response.status == 'Success') {
            this.spinner.hide();
            this.router.navigate(['/game-transactions-list']).then(() => {
              this.toastr.success(this.dto.Response.message, 'Success!', {
                timeOut: 3000,
                positionClass: 'toast-top-right'
              });
            })
           
          }
          else {
            this.spinner.hide();
            this.toastr.error(this.dto.Response.message, 'Invalid!', {
              timeOut: 3000,
              positionClass: 'toast-top-right',
            });
          }
          this.spinner.hide();
        });
    }
    
  
    checkedData() {
      for (let i = 0; i < this.gameTransactionErrorList.length; i++) {
        if (this.gameTransactionErrorList[i].id == "check") {
          this.confirmallArr.push(this.gameTransactionErrorList[i].id);
        }
      }
      console.log("this.confirmallArr >>" + JSON.stringify(this.confirmallArr));
    }

  checkuncheckall() {
    if (this.isChecked == true) {
      this.isChecked = false;
    }
    else {
      this.isChecked = true;
    }
  }
  

  changeSelection(ev, id) {    
    var target = ev.target;
    this.confirmallArr = [];

    if (target.checked && id == 0) {
      for (let i = 0; i < this.gameTransactionErrorList.length; i++) {
        this.gameTransactionErrorList[i].id = "check";
      }
    }
    else if (target.checked && id != 0) {
      for (let i = 0; i < this.gameTransactionErrorList.length; i++) {
        if (this.gameTransactionErrorList[i].id == id) {
          this.gameTransactionErrorList[i].id = "check";
        }
      }
    }
    else if (!target.checked && id == 0) {
      for (let i = 0; i < this.gameTransactionErrorList.length; i++) {
        this.gameTransactionErrorList[i].id = "uncheck";
      }
    }
    else if (!target.checked && id != 0) {
      for (let i = 0; i < this.gameTransactionErrorList.length; i++) {
        if (this.gameTransactionErrorList[i].id == id) {
          this.gameTransactionErrorList[i].id = "uncheck";
        }
      }
    }
   this.checkedData();
  }

  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }
}