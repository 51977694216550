<div class="wrapper">
    <!-- Navbar -->
    <app-header-sidebar></app-header-sidebar>

    <!-- here here here -->
    <!-- Content Wrapper. Contains page content -->
    <div class="content-wrapper">
        <!-- Content Header (Page header) -->
        <div class="content-header">
            <div class="container-fluid">
                <!--                 <div class="row mb-2">
                    <div class="col-sm-6">
                        <h1 class="m-0 text-dark">Top-up Detail</h1>
                    </div>
                    <div class="col-sm-6">
                        <ol class="breadcrumb float-sm-right">
                            <li class="breadcrumb-item"><a [routerLink]="['/topup-check-list']">Top-up List</a></li>
                            <li class="breadcrumb-item active">Top-up Detail</li>
                        </ol>
                    </div> 
                </div> -->
                <!-- /.row -->
            </div><!-- /.container-fluid -->
        </div>
        <!-- /.content-header -->

        <!-- Main content -->
        <section class="content">
            <div class="container-fluid">
                <form>
                    <div class="row">
                        <div class="col-md-12">
                            <div class="card">
                                <div class="card-header">
                                    <h3 class="card-title" style="font-weight: bold;"> Top-up Detail</h3>
                                </div>
                                <!-- /.card-header -->
                                <div class="card-body">
                                    <form>
                                        <div class="row">
                                            <label class="col-md-2">Date:</label>
                                            <!--<div class="form-group col-md-3">
                                                <input id="singleDate" readonly class="form-control form-control-sm"
                                                    placeholder="Select Date" [ngxDatePicker]="dateInstanceSingle"
                                                    [value]="singleDate" (valueChange)="onChangeSingle()"
                                                    [(ngModel)]="date" [ngModelOptions]="{standalone: true}">
                                                <ngx-date-picker #dateInstanceSingle></ngx-date-picker>
                                            </div>-->
                                            <div class="form-group col-md-3">
                                                <input type = "text" id="singleDate" readonly class="form-control form-control-sm"                                                  
                                                    [value]="date" [(ngModel)]="date" [ngModelOptions]="{standalone: true}">
                                            </div>
                                    
                                            <div class="col-md-1"></div>
                                            <label class="col-md-2">Transaction No</label>
                                            <div class="form-group col-md-3">
                                                <input id="transactionNo" type="text" onKeyPress="if(this.value.length==20) return false;" class="form-control form-control-sm" [(ngModel)]="userFinancialTransactionDTO.transaction_no" [ngModelOptions]="{standalone: true}" >
                                            </div>
                                        </div>

                                        <div class="row">
                                            <label class="col-md-2">User</label>
                                            <div class="form-group col-md-3">
                                                <input id = "phoneNo" type = "text" class="form-control form-control-sm" [(ngModel)]="phoneNo" [ngModelOptions]="{standalone: true}"/>
                                            </div>
                                            <div class="col-md-1"></div>
                                            <label class="col-md-2">Amount</label>
                                            <div class="form-group col-md-3">
                                                <input id = "amount" type = "text" class="form-control form-control-sm" [(ngModel)]="userFinancialTransactionDTO.amount_Str" [ngModelOptions]="{standalone: true}" (keypress)="numericOnly($event)"/>
                                                
                                            </div>
                                        </div>


                                        <div class="row">
                                            <label class="col-md-2">Payment</label>
                                            <div class="form-group col-md-3">
                                                <div class="row" id="paymentId">
                                                    
                                                        <a *ngFor = "let item of paymentList">
                                                            <div class="col-lg-3">
                                                                <img id="{{item.id}}" [src]="item.image_url" width = "80px" hegith = "80px" style="object-fit: cover;border: 3px solid #ffffff;border-radius: 10px;border-color: #ffffff;margin-top: 5%;"
                                                               >
                                                            </div>
                                                        </a>
                                                  
                                                </div>
                                            </div>
                                            <div class="col-md-1"></div>
                                            <div class="col-md-6">
                                                <!-- <div class="row">
                                                    <label class="col-md-4" > QR Code </label>
                                                    <div class="form-group col-md-6" >
                                                        <img  width="120px" hegith="170px" style="margin-top: 5%; object-fit: cover; border: 3px solid #ffffff; border-radius: 10px; border-color: #ffffff;" 
                                                        [src]="userFinancialTransactionDTO.qrImageUrl"> 
                                                    </div>
                                                </div> -->

                                                <div class="row" *ngIf="userFinancialTransactionDTO.status == 'DENIED'">
                                                    <label class="col-md-4" id="description"> Remark </label>
                                                    <div class="form-group col-md-6" id="description1">
                                                        <textarea class="form-control form-control-sm" [(ngModel)]="userFinancialTransactionDTO.description"
                                                        [ngModelOptions]="{standalone: true}" > </textarea>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                    
                                        <div class="row">
                                            <label class="col-md-2">Choose Account Number</label>
                                            <div class="form-group col-md-3">
                                                <select (change) = "flagAcc()" id="acc" class="form-control form-control-sm col-md-12 col-sm-4 col-xs-4"
                                                [(ngModel)]="userFinancialTransactionDTO.account_id" [ngModelOptions]="{standalone: true}" >
                                                    <option *ngFor="let acc of paymentAccList" value={{acc.id}}>
                                                        {{acc.account_name}} - {{acc.account_no}}
                                                </select>
                                            </div>
                                        </div> 
                                    

                                        <div class="row">
                                            <label class="col-md-2">Status</label>
                                            <div class="form-group col-md-3">
                                                <select class="form-control form-control-sm col-md-12 col-sm-4 col-xs-4"
                                                    [(ngModel)]="userFinancialTransactionDTO.status"
                                                    [ngModelOptions]="{standalone: true}" id="approvestatus">
                                                    <option *ngFor="let status of statusList" value={{status}}>
                                                        {{status}}
                                                </select>
                                            </div>

                                            <!-- <div class="col-md-1"></div>
                                            <div class="col-md-6">
                                                <div class="row" *ngIf="userFinancialTransactionDTO.status == 'DENIED'">
                                                    <label class="col-md-4" id="description"> Remark </label>
                                                    <div class="form-group col-md-6" id="description1">
                                                        <textarea class="form-control form-control-sm" [(ngModel)]="userFinancialTransactionDTO.description"
                                                        [ngModelOptions]="{standalone: true}" > </textarea>
                                                    </div>
                                                </div>
                                            </div>  -->

                                        </div>

                                        <div class="row" style="margin-top: 1%;">
                                            <div class="col-md-2 col-sm-6">
                                                <button type="button" id = "idsave" class="btn btn-block btn-success"
                                                    (click)="edit()">Save</button>&nbsp;</div>
                                            
                                                <div class="col-md-2 col-sm-6" style="display: none;">
                                                        <button type="button" id = "deletebtn" class="btn btn-block btn-secondary"
                                                        (click)="deleteData()">Delete</button>&nbsp;
                                                </div>

                                            <div class="col-md-2 col-sm-6">
                                                <button type="button" class="btn btn-block btn-danger"
                                                [routerLink] = "['/topup-check-list']">Cancel</button></div>
                                            
                                            

                                        </div>
                                    </form>
                                </div>
                                <!-- /.card-body -->
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-md-12">
                            <div class="card">
                                <div class="card-body">
                                    <form>
                                        <div class="row" style="margin-top: 1.7%;">
                                            <label class="col-md-2"> Requested Date </label>
                                            <div class="form-group col-md-3">
                                                <input class="form-control form-control-sm"
                                                    [(ngModel)]="userFinancialTransactionDTO.created_date"
                                                    [ngModelOptions]="{standalone: true}" disabled>
                                            </div>
                                            <div class="col-md-1"></div>
                                            <label class="col-md-2"> Requested By</label>
                                            <div class="col-md-3">
                                                <input class="form-control form-control-sm"
                                                    [(ngModel)]="userFinancialTransactionDTO.name"
                                                    [ngModelOptions]="{standalone: true}" disabled>
                                            </div>
                                        </div>

                                        <div class="row" style="margin-top: 1%;"> <!---->
                                            <label class="col-md-2"> Updated Date </label>
                                            <div class="form-group col-md-3">
                                                <input class="form-control form-control-sm"
                                                    [(ngModel)]="userFinancialTransactionDTO.updated_date"
                                                    [ngModelOptions]="{standalone: true}" disabled>
                                            </div>
                                            <div class="col-md-1"></div>
                                            <label class="col-md-2"> Updated By </label>
                                            <div class="col-md-3">
                                                <input class="form-control form-control-sm"
                                                    [(ngModel)]="userFinancialTransactionDTO.updated_by"
                                                    [ngModelOptions]="{standalone: true}" disabled>
                                            </div>
                                        </div>

                                    </form>
                                </div>
                                <!-- /.card-body -->
                            </div>
                        </div>
                    </div>
                    
                </form>
                <!-- /.row -->

                <!-- /.row -->
            </div>
            <!--/. container-fluid -->
        </section>
        <!-- /.content -->
    </div>

    <aside class="control-sidebar control-sidebar-dark">
        <!-- Control sidebar content goes here -->
    </aside>

    <app-footer></app-footer>

    <ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#fff" type="square-jelly-box">
        <p style="font-size: 16px; color: white">Loading...</p>
    </ngx-spinner>

</div>

<div id="deleteData" class="modal fade">
    <div class="modal-dialog">
        <div class="modal-content">

            <div class="modal-body">
                <form>

                    <div class="row" style="margin-top: 5%;margin-bottom: 3%;margin-left: 9%;">
                        <div class="form-group col-md-12">
                            <h5> This Account is used by another device. 
                                Please Sign Out. Login Again. </h5>
                        </div>
                    </div>

                    <div class="row" style="margin-left: 40%;margin-bottom: 3%;">
                        <div class="col-md-5 col-sm-6" id="deleteBtn">
                            <button type="button" class="btn btn-block btn-success" (click)="OkLogout()"> Ok </button>
                        </div>
                    </div>
                </form>
            </div>

        </div>
    </div>
</div>

<div id="deleteData1" class="modal fade">
    <div class="modal-dialog">
        <div class="modal-content">

            <div class="modal-body">
                <form>

                    <div class="row" style="margin-top: 5%;margin-bottom: 3%;margin-left: 9%;">
                        <div class="form-group col-md-12">
                            <h3> Are you sure want to delete? This transaction can't recover.</h3>
                        </div>
                    </div>

                    <div class="row" style="margin-left: 10%;margin-bottom: 3%;">
                        <div class="col-md-5 col-sm-6" id="deleteBtn">
                            <button type="button" class="btn btn-block btn-success" (click)="deleteOk()"> Ok </button>
                        </div>

                        <div class="col-md-5 col-sm-6">
                            <button type="button" class="btn btn-block btn-danger" [routerLink]="['/topup-check-list']"> Cancel </button>
                        </div>
                    </div>

                </form>
            </div>

        </div>
    </div>
</div>