import { Component, OnInit, ViewChild } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,HttpErrorResponse } from '@angular/common/http';
import { DataTableDirective } from 'angular-datatables';
import { Router, NavigationEnd } from '@angular/router';
import { DatePipe } from '@angular/common'

import { LocalStorageService } from 'ngx-webstorage';
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from 'ngx-toastr';

import { catchError, retry } from 'rxjs/operators';
import { throwError } from 'rxjs';

import { DtoService } from '../../service/dto.service';
import { FunctService } from '../../service/funct.service';
import Responsive from 'datatables.net-responsive'; 
import { FormsModule } from '@angular/forms';

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { Subject } from 'rxjs';
declare var $: any;

@Component({
  selector: 'app-threedbet-cancel-list',
  templateUrl: './threedbet-cancel-list.component.html',
  styleUrls: ['./threedbet-cancel-list.component.css']
})
export class ThreedbetCancelListRecordComponent implements OnInit {

  @ViewChild(DataTableDirective)
  dtElement: DataTableDirective;
  dtOptions: DataTables.Settings = {};
  dtOptions2: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();
  dtTrigger2: Subject<any> = new Subject();

  singleDate: string = '';
  singleDate1: string = '';
  status: string= '';
  threedbetList: any;
  idIndex: any;
  idIndex2: any;
  phoneNo: any;
  userList: [];
  token: any;
  threeDBetDetailDTOList;
  threeDBetDetailDTOList_temp;
  id;
  phoneNo2: '';
  totalAmount;
  createdDate: '';
  userName: '';
  date : any;
  fromtodayDate : any;
  fromChangeDate : any;
  toDate : any;
  totodayDate : any;
  toChangeDate : any;
  hardcode_view : any;
  hardcode_delete: any;
  hardcode_delete_bool : any;
  hardcode_view_bool : any;

  constructor(private toastr: ToastrService,private storage: LocalStorageService,private spinner: NgxSpinnerService, private dto: DtoService, 
    private http: HttpClient, private funct: FunctService, private router: Router,private datepipe: DatePipe,) { 
    this.phoneNo = '';
    this.storage.store('isNotiSong', "");
    this.idIndex = 1;
    this.idIndex2 = 1;
    //this.getActiveUsers();

    this.hardcode_view = "BetHistory_View";
    this.hardcode_delete = "BetHistory_Cancel";
 
    this.dto.menuCodes = this.storage.retrieve('menucodes');

    const filteredMenuCodes_delete = [];
    const filteredMenuCodeds_view =[];
    this.dto.menuCodes.forEach(menuCode =>
       {
      if (menuCode === this.hardcode_delete) {
        filteredMenuCodes_delete.push(menuCode);
      }   
      if (menuCode === this.hardcode_view) {
        filteredMenuCodeds_view.push(menuCode);
      }
   
    });
        if(filteredMenuCodes_delete.length != 0)
        {
            this.hardcode_delete_bool = true;
        }
        else
        {
          this.hardcode_delete_bool = false;
        }    
     
        if(filteredMenuCodeds_view.length != 0)
        {
            this.hardcode_view_bool = true;
        }
        else
        {
          this.hardcode_view_bool = false;
        }


    this.date = new Date();
    this.fromtodayDate = this.datepipe.transform(this.date, 'MMM dd, yyyy');
    console.log("this.todayDate>> " + this.date);

    this.toDate = new Date();
    this.totodayDate = this.datepipe.transform(this.toDate, 'MMM dd, yyyy');
    console.log("this.todayDate>> " + this.date);

    this.search();
  }

  ngOnInit(): void {

    this.router.routeReuseStrategy.shouldReuseRoute = function () {
      return false;
    };

    this.router.events.subscribe((evt) => {
      if (evt instanceof NavigationEnd) {
        this.router.navigated = false;        
        window.scrollTo(0, 0);
      }
    });

    this.threeDBetDetailDTOList = [];

    this.dtOptions = {
      responsive: {
        details: {
            renderer: Responsive.renderer.listHiddenNodes()
        }
    },
      order:[]
    }

    this.dtOptions2 = {
      responsive: true,
      order:[]
    }

    this.dtOptions.columnDefs = [
     { targets: [4], orderable: false }

    ];

    this.dtOptions2.columnDefs = [
      { targets: [0], orderable: false }
     ];

    
    if(!this.storage.retrieve('loadFlag')){
      this.storage.store('loadFlag', 'noLoad');
      setTimeout(function(){
        location.reload(true);
      }, 5);
    }
    else{
      this.storage.clear('loadFlag');
    }
  }

  onChangeSingle(){
    $(document).ready(function () {
      this.singleDate = $("#singleDate").val();
    });
  }
  onChangeSingle1(){
    $(document).ready(function () {
      this.singleDate1 = $("#singleDate1").val();
    });
  }

  getActiveUsers() {
    this.token = this.storage.retrieve('token');
    let headers = new HttpHeaders();
    headers = headers.set('Authorization', 'ITW ' + this.token);
    this.http.get(this.funct.ipaddress + 'user/active-users', { headers: headers }).subscribe(
      result => {
        this.dto.Response = {};
        this.dto.Response = result;
        this.userList = this.dto.Response.data.userDTOList;
      }
    );
  }

  handleError(error: HttpErrorResponse){
    if(error.status == 423)
    {
      this.spinner.hide();
      $("#deleteData").modal("show");
    }
    if(error.status == 403)
    {
      this.spinner.hide();
      var id = 'tblthreedbet' + this.idIndex;
      var table = $('#' + id).DataTable();
      this.toastr.error("Limited Access.", 'Invalid!', {
        timeOut: 3000,
        positionClass: 'toast-top-right',
        });
    }
    return throwError(error);
    }
    OkLogout()
    {
      window.location.href ="./ad-login";
    } 

    onInput(event: InputEvent) {
      const myanmarCharactersRegex = new RegExp('^[\u1000-\u109F\uAA60-\uAA7F\uA9E0-\uA9FF]+$');
      const inputValue = (event.target as HTMLInputElement).value; 
      if(event.data!=null)
      {
        if (myanmarCharactersRegex.test(inputValue)) 
        {        
          this.spinner.hide();
          this.toastr.error( "Myanmar typing detected!", '', {
          timeOut: 5000,
          positionClass: 'toast-top-right',
          });  
        }
      }   
    }

  search(){
    this.threedbetList = [];
    var id = 'tblthreedbet' + this.idIndex;
    var table = $('#' + id).DataTable();
    table.destroy();
    this.idIndex = this.idIndex +1;
    this.spinner.show();
    this.dto.token = this.storage.retrieve('token');
    this.singleDate = $("#singleDate").val();
    this.singleDate1 = $("#singleDate1").val();
    if(this.singleDate == null || this.singleDate == 'undefined')
    this.fromChangeDate = this.fromtodayDate;
    else
     this.fromChangeDate = this.singleDate;

     if(this.singleDate1 == null || this.singleDate1 == 'undefined')
       this.toChangeDate = this.totodayDate;
     else
      this.toChangeDate = this.singleDate1;

    this.singleDate1 = $("#singleDate1").val();
    let headers = new HttpHeaders();
    headers = headers.set('Authorization',  this.dto.token);
    let params = new HttpParams();
   
    params = params.set('fdate',this.fromChangeDate).set("tdate",this.toChangeDate).set('phoneNo',this.phoneNo).set("pageNumber","1").set("rowsOfPage","10");
    this.http.get(this.funct.ipaddress + 'threedbet/GetCancelListbyParams', { params: params, headers: headers })
    .pipe(
      catchError(this.handleError.bind(this))
     )
    .subscribe(
      result => {
        this.dto.Response = {};
        this.dto.Response = result.results;
        this.threedbetList = this.dto.Response;
        this.dtTrigger.next();
        this.spinner.hide();
      }
    );
  }

  goModal(id, phoneNo, userName, totalAmount, createdDate){
    var id1 = 'tblthreedbetdetail' + this.idIndex2;
    var table = $('#' + id1).DataTable();
    table.destroy();
    this.idIndex2 = this.idIndex2 +1;
    this.spinner.show();
    this.id = id;
    this.phoneNo2 = phoneNo;
    this.userName = userName;
    this.totalAmount = totalAmount;
    this.createdDate = createdDate;
    this.token = this.storage.retrieve('token');
    let headers = new HttpHeaders();
    headers = headers.set('Authorization', this.token);
    let params = new HttpParams();
     params = params.set('id', id);
    this.http.get(this.funct.ipaddress + 'threedbet/GetCancelThreedbetDetail', { params: params, headers: headers })
    .pipe(
      catchError(this.handleError.bind(this))
     )
    .subscribe(
      result => {
        this.dto.Response = {};
        this.dto.Response = result;
        this.threeDBetDetailDTOList_temp = this.dto.Response;
        this.threeDBetDetailDTOList = this.threeDBetDetailDTOList_temp.results;       
        this.dtTrigger2.next();
      }
    ); 

    this.spinner.hide();
    $('#browseAccountData').modal("show");
  }

}
