<div class="wrapper">
  <!-- Navbar -->
  <app-header-sidebar></app-header-sidebar>

  <!-- here here here -->
  <!-- Content Wrapper. Contains page content -->
  <div class="content-wrapper">
    <!-- Content Header (Page header) -->
    <div class="content-header">
      <div class="container-fluid">
      </div><!-- /.container-fluid -->
    </div>
    <!-- /.content-header -->

    <!-- Main content -->
    <section class="content">
      <div class="container-fluid">
        <form>
          <div class="row">
            <div class="col-md-12">
              <div class="card">
                <div class="card-header">
                  <h3 class="card-title" style="font-weight: bold;"> Twod Section Detail </h3>
                </div>
                <!-- /.card-header -->
                <div class="card-body">
                  <form>

                    <div class="row">
                      <label class="col-md-2"> Section Name</label>
                      <div class="form-group col-md-3">
                        <input class="form-control form-control-sm" [(ngModel)]="sectionDTO.sectionName"
                          [ngModelOptions]="{standalone: true}" disabled>
                      </div>
                      <div class="col-md-1"></div>
                      <label class="col-md-2"> Result Time</label>
                      <div class="form-group col-md-3">
                        <input class="form-control form-control-sm" [(ngModel)]="sectionDTO.resultTime"
                          [ngModelOptions]="{standalone: true}" disabled>
                      </div>
                    </div>

                    <div class="row">
                      <label class="col-md-3"> From Time ( 24 Hours Format ) </label>
                      <div class="form-group col-md-1">
                        <input onKeyPress="if(this.value.length==2) return false;"
                          class="form-control form-control-sm" placeholder="HH" [(ngModel)]="fromhours"
                          [ngModelOptions]="{standalone: true}" (keypress)="numericOnly($event)">
                      </div>
                      <div class="form-group col-md-1">
                        <input onKeyPress="if(this.value.length==2) return false;"
                          class="form-control form-control-sm" placeholder="MM" [(ngModel)]="fromminutes"
                          [ngModelOptions]="{standalone: true}" (keypress)="numericOnly($event)">
                      </div>
                      <div class="col-md-1"></div>
                      <label class="col-md-3"> To Time ( 24 Hours Format )  </label>
                      <div class="form-group col-md-1">
                        <input onKeyPress="if(this.value.length==2) return false;" class="form-control form-control-sm" placeholder="HH" [(ngModel)]="tohours"
                          [ngModelOptions]="{standalone: true}" (keypress)="numericOnly($event)">
                      </div>
                      <div class="form-group col-md-1">
                        <input onKeyPress="if(this.value.length==2) return false;" class="form-control form-control-sm" placeholder="MM" [(ngModel)]="tominutes"
                          [ngModelOptions]="{standalone: true}" (keypress)="numericOnly($event)">
                      </div>
                    </div>
                    <div class="row">
                      <label class="col-md-2"> Updated By </label>
                      <div class="form-group col-md-3">
                        <input class="form-control form-control-sm" [(ngModel)]="sectionDTO.updated_user"
                          [ngModelOptions]="{standalone: true}" disabled>
                      </div>
                      <div class="col-md-1"></div>
                      <label class="col-md-2"> Updated Date </label>
                      <div class="form-group col-md-3">
                        <input class="form-control form-control-sm" [(ngModel)]="sectionDTO.updated_date_str"
                          [ngModelOptions]="{standalone: true}" disabled>
                      </div>
                    </div>
                    <div class="row" style="display:none">
                      <label class="col-md-2"> Status </label>
                      <div class="form-group col-md-3 radio">
                        <div class="form-group clearfix">
                          <div class="icheck-success d-inline">
                            <input type="radio" name="r3" checked id="radioSuccess1" value="ACTIVE"
                              [(ngModel)]="sectionDTO.status" [ngModelOptions]="{standalone: true}">
                            <label for="radioSuccess1">Active &nbsp;&nbsp;
                            </label>
                          </div>
                          <div class="icheck-success d-inline">
                            <input type="radio" name="r3" id="radioSuccess2" value="INACTIVE"
                              [(ngModel)]="sectionDTO.status" [ngModelOptions]="{standalone: true}">
                            <label for="radioSuccess2">Inactive
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-2 col-sm-6">
                        <button type="button" class="btn btn-block btn-success" (click)="goSave()">Save</button>&nbsp;
                      </div>
                      <div class="col-md-2 col-sm-6">
                        <button type="button" class="btn btn-block btn-danger"
                          [routerLink]="['/confighistory']">Cancel</button>
                      </div>
                    </div>
                  </form>
                </div>
                <!-- /.card-body -->
              </div>
            </div>
          </div>
        </form>
        <!-- /.row -->

        <!-- /.row -->
      </div>
      <!--/. container-fluid -->
    </section>
    <!-- /.content -->
  </div>

  <aside class="control-sidebar control-sidebar-dark">
    <!-- Control sidebar content goes here -->
  </aside>

  <app-footer></app-footer>

  <ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#fff" type="square-jelly-box">
    <p style="font-size: 16px; color: white">Loading...</p>
  </ngx-spinner>

</div>

<div id="deleteData" class="modal fade">
  <div class="modal-dialog">
      <div class="modal-content">

          <div class="modal-body">
              <form>

                  <div class="row" style="margin-top: 5%;margin-bottom: 3%;margin-left: 9%;">
                      <div class="form-group col-md-12">
                          <h5> This Account is used by another device. 
                              Please Sign Out. Login Again. </h5>
                      </div>
                  </div>

                  <div class="row" style="margin-left: 40%;margin-bottom: 3%;">
                      <div class="col-md-5 col-sm-6" id="deleteBtn">
                          <button type="button" class="btn btn-block btn-success" (click)="OkLogout()"> Ok </button>
                      </div>
                  </div>
              </form>
          </div>

      </div>
  </div>
</div>