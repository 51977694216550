<div class="wrapper">
    <!-- Navbar -->
    <app-header-sidebar></app-header-sidebar>

    <!-- here here here -->
    <!-- Content Wrapper. Contains page content -->
    <div class="content-wrapper">
        <!-- Content Header (Page header) -->
        <div class="content-header">
            <div class="container-fluid">
                <!--                 <div class="row mb-2">
                    <div class="col-sm-8">
                        <h1 class="m-0 text-dark"> 2D Bet Amount Limitation</h1>
                    </div>
                </div> -->
                <!-- /.row -->
            </div><!-- /.container-fluid -->
        </div>
        <!-- /.content-header -->
        <!-- Main content -->
        <!-- Main content -->
        <section class="content">
            <div class="container-fluid">
                <form>
                    <div class="row">
                        <div class="col-md-12">
                            <div class="card">
                                <div class="card-header">
                                    <h3 class="card-title" style="font-weight: bold;">Threed Dream Book Detail
                                    </h3>
                                </div>
                                <!-- /.card-header -->
                                <div class="card-body">
                                    <form>

                                      <div class="row">
                                            <label class="col-md-2"> Number1 </label>
                                            <div class="form-group col-md-3">
                                                <textarea id="singleDate" 
                                                [(ngModel)]="dreamBookDTO.number1"
                                                [ngModelOptions]="{standalone: true}"
                                                class="form-control form-control-sm"></textarea>
                                            </div>

                                            <label class="col-md-2"> Number2 </label>
                                            <div class="form-group col-md-3">
                                               <textarea id="toDate" 
                                               [(ngModel)]="dreamBookDTO.number2" 
                                               [ngModelOptions]="{standalone: true}"
                                               class="form-control form-control-sm"> </textarea>
                                               </div>
                                        </div>


                                        <div class="row">
                                            <label class="col-md-2"> Description (English)</label>
                                            <div class="form-group col-md-3">
                                                <textarea id="singleDate" 
                                                [(ngModel)]="dreamBookDTO.description_en"
                                                [ngModelOptions]="{standalone: true}"
                                                class="form-control form-control-sm"></textarea>
                                            </div>

                                            <label class="col-md-2"> Description (Burmese)</label>
                                             <div class="form-group col-md-3">
                                               <textarea id="toDate" 
                                               [(ngModel)]="dreamBookDTO.description_my" 
                                               [ngModelOptions]="{standalone: true}"
                                               class="form-control form-control-sm"> </textarea>
                                               </div>
                                        </div>

                                   <div class="row">

                                     <label class="col-md-2"> Description (Thailand)</label>
                                        <div class="form-group col-md-3">
                                        <textarea id="toDate" 
                                        [(ngModel)]="dreamBookDTO.description_th" 
                                        [ngModelOptions]="{standalone: true}" 
                                        class="form-control form-control-sm"></textarea>
                                        </div>

                                        <label class="col-md-2"> Description (Chinese)</label>
                                        <div class="form-group col-md-3">
                                           <textarea id="toDate" 
                                           [(ngModel)]="dreamBookDTO.description_zh" 
                                           [ngModelOptions]="{standalone: true}" 
                                           class="form-control form-control-sm"></textarea>
                                         </div>
                                    </div>

                                    <div class="row">
                                        <label class="col-md-2"> Image </label>
                                        <div class="form-group col-md-3">
                                            <input #file type="file" accept='image/*'
                                                (change)="preview(file.files)" /> 
                                                
                                                <img [src]="imgURL" height="200" width="255" *ngIf="imgURL"
                                                style="margin-top: 5%;">
                                        </div>
                                    </div>

                                        <div class="row">
                                            <label class="col-md-2"> Status </label>
                                            <div class="form-group radio">
                                              <div class="form-group clearfix">
                                                <div class="icheck-success d-inline">
                                                  <input type="radio" name="r3" checked id="radioSuccess1" value="ACTIVE"
                                                    [(ngModel)]="dreamBookDTO.status" [ngModelOptions]="{standalone: true}">
                                                  <label for="radioSuccess1">Active &nbsp;&nbsp;
                                                  </label>
                                                </div>
                                                <div class="icheck-success d-inline">
                                                  <input type="radio" name="r3" id="radioSuccess2" value="INACTIVE"
                                                    [(ngModel)]="dreamBookDTO.status" [ngModelOptions]="{standalone: true}">
                                                  <label for="radioSuccess2">Inactive
                                                  </label>
                                                </div>
                                              </div>
                                            </div>
                                        </div>
                                    
                                    <div class="row">    
                                        <div class="col-md-2 col-sm-2 col-xs-2">
                                            <button id="apply" type="button"
                                                class="btn btn-block btn-success btn-sm" (click)="edit()">
                                                Save
                                            </button>
                                        </div>
                                    
                                        <div class="col-md-2 col-sm-2 col-xs-2">
                                            <button id="save" type="button"
                                                class="btn btn-block btn-danger btn-sm"  [routerLink] = "['/drmhistory/threed']">
                                                Cancel
                                            </button>
                                        </div>
                                   </div>
                                    </form>

                                </div>
                            </div>
                        </div>
                    </div>
                </form>
                <!-- /.row -->
                <!-- /.row -->
            </div>
            <!--/. container-fluid -->
        </section>
        <!-- /.content -->
    </div>

    <aside class="control-sidebar control-sidebar-dark">
        <!-- Control sidebar content goes here -->
    </aside>

    <app-footer></app-footer>

    <ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#fff" type="square-jelly-box">
        <p style="font-size: 16px; color: white">Loading...</p>
    </ngx-spinner>

</div>