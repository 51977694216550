import { Component, OnInit, ViewChild } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,HttpErrorResponse } from '@angular/common/http';
import { DataTableDirective } from 'angular-datatables';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { DatePipe } from '@angular/common'
import { LocalStorageService } from 'ngx-webstorage';
import { NgxSpinnerService } from "ngx-spinner";
import { DtoService } from '../../service/dto.service';
import { FunctService } from '../../service/funct.service';
import { catchError, retry } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
declare var $: any;

@Component({
  selector: 'app-black-list',
  templateUrl: './black-list.component.html',
  styleUrls: ['./black-list.component.css']
})
export class BlackListComponent implements OnInit {

  @ViewChild(DataTableDirective)
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();

  dtOptions1: DataTables.Settings = {};
  dtTrigger1: Subject<any> = new Subject();

  dtOptions2: DataTables.Settings = {};
  dtTrigger2: Subject<any> = new Subject();

  dtOptions3: DataTables.Settings = {};
  dtTrigger3: Subject<any> = new Subject();

  section : string = '10:30 AM'; 

  TDWLPhoneNo: string = '';
  ip: string = '';
  TDWLWinAmount: string = '';
  TDWLsection: string = '';
  TDWLStatus: string = '';
  TDWLImgUrl: string ='';

  ThDWLPhoneNo: string = '';
  ThDWLBetAmount: string = '';
  ThDWLWinAmount: string = '';
  ThDWLStatus: string = '';

  TDWList: any;
  ThDWList: any;
  twoDWinnerInputList =[];
  threeDWinnerInputList =[];

  idIndex: any;
  idIndexTD: any;
  idIndexTDWL: any;
  idIndexThDWL: any;

  threeDOdd:any;
  odd:any;

  itemsPerPage =  10;
  itemsPerPage1 =  10;

  totalItems : any;  
  totalItems1 : any; 

  page = 1;
  page1 = 1;
  imagePath: any;
  filePath : any;
  imageURL: any;
  imgURL:any
  message:any;
  phoneNumber:any;
  selectedImages: File[] = [];


  type: any;

  hardcode_delete :any;
  hardcode_add :any;
  hardcode_view: any;
  hardcode_view_bool : any; 
  hardcode_delete_bool : any;
  hardcode_add_bool : any;

  winAmounts: number[] = [];
  ipList :any;
  addTranDto:any;
  IP:any;
  deletTranDto :any;
  userid :any;
  useripList:any;
  transaction_tutorial:any;
 

  constructor(private storage: LocalStorageService,private spinner: NgxSpinnerService, private dto: DtoService, private http: HttpClient, private router: Router,
    private route: ActivatedRoute, private funct: FunctService,private toastr: ToastrService, private datepipe: DatePipe) { 
      this.storage.store('isNotiSong', "");
    this.idIndex = 1;
    this.idIndexTD = 1;
    this.idIndexTDWL = 1;
    this.idIndexThDWL = 1;


      this.hardcode_view = "BlackLIst_View";
      this.hardcode_delete = "BlackLIst_Del";
      this.hardcode_add = "BlackLIst_Add";
      this.dto.menuCodes = this.storage.retrieve('menucodes');

      const filteredMenuCodes_view = [];    
      const filteredMenuCodeds_delete =[];
      const filteredMenuCodeds_add =[];
      this.dto.menuCodes.forEach(menuCode => {
        if (menuCode === this.hardcode_view) {
          filteredMenuCodes_view.push(menuCode);
        }     
        if (menuCode === this.hardcode_delete) {
          filteredMenuCodeds_delete.push(menuCode);
        }
        if (menuCode === this.hardcode_add) {
          filteredMenuCodeds_add.push(menuCode);
        }
      });
          if(filteredMenuCodes_view.length != 0)
          {
              this.hardcode_view_bool = true;
          }       
          if(filteredMenuCodeds_delete.length != 0)
          {
              this.hardcode_delete_bool = true;
          }
          if(filteredMenuCodeds_add.length != 0)
          {
              this.hardcode_add_bool = true;
          }

    if (!this.storage.retrieve('TDWLTagsection')) {
      this.TDWLsection = '10:30 AM';
    }
    else {
      this.TDWLsection = this.storage.retrieve('TDWLTagsection');
    } 

    this.twoDWinnerInputList.push({name:'',phoneNumber:'',imgURL:'',betAmount:0,winAmount:0,section:'',status:'Active',odd:0});
    this.threeDWinnerInputList.push({threeDName:'',threeDPhoneNumber:'',threeDImgUrl:'',threeDBetAmount:0,threeDWinAmount:0,threeDStatus:'Active',ThreeDOdd:0});

    this.getIPList();
  }

  ngOnInit(): void {   
    this.dtOptions = {
      responsive: true,
      stateSave: true,
      order: []
    }

    this.dtOptions1 = {
      responsive: true,
      stateSave: true,
      order: [],
      paging: false,
      info : true,
      dom: "Bfrltip"
    }

    this.deletTranDto = {
      tranType: '',    
      tranId: 0,
      source:''
    };

    this.dtOptions2 = {
      responsive: true,
      stateSave:true,
      order: []
    }

    this.addTranDto = {    
      IP: ''
    };

    this.dtOptions3 = {
      responsive: true,
      stateSave: true,
      order: [],
      paging: false,
      info : true,
      dom: "Bfrltip"
    }

    if (!this.storage.retrieve('loadFlag')) {
      this.storage.store('loadFlag', 'noLoad');
      setTimeout(function () {
        location.reload();
      }, 5);
    }
    else {
      this.storage.clear('loadFlag');
    }    

    

    this.type = this.route.snapshot.paramMap.get("type");
    if(this.type == 'blacklistiplist')
    {
        $('#twoD-tab').tab('show');
    }
    else if(this.type == 'addIp')
    {
        //this.TDWLSearch();
        $('#twoDList-tab').tab('show');
    }
    else if(this.type == 'userIdList')
    {
       //this.ThDWLSearch();
        $('#threeD-tab').tab('show');
    }
    else if(this.type == 'addUserId')
    {
        //this.ThDWLSearch();
        $('#threeDWinnerList-tab').tab('show');
    }
    
  }

  handleError(error: HttpErrorResponse){
    if(error.status == 423)
    {
      this.spinner.hide();
      $("#deleteData").modal("show");
    }
    if(error.status == 500)
    {
      this.spinner.hide();
      this.toastr.error('Please check connection', 'Invalid!', {
        timeOut: 3000,
        positionClass: 'toast-top-right',
      });
    }
    if(error.status == 403)
    {
      this.spinner.hide();
      var id = 'tbltwoD' + this.idIndex;
      var table = $('#' + id).DataTable();

      var id2 = 'tblthreeD' + this.idIndexTD;
      var table2 = $('#' + id2).DataTable();

      var id3 = 'tblTDWL' + this.idIndexTDWL;
      var table3 = $('#' + id3).DataTable();

      var id4 = 'tblThDWL' + this.idIndexThDWL;
      var table4 = $('#' + id4).DataTable();

      this.toastr.error("Limited Access.", 'Invalid!', {
        timeOut: 3000,
        positionClass: 'toast-top-right',
        });
    }



    if(error.status == 406)
    {
      this.spinner.hide();
      this.toastr.error('Failed!', 'Invalid!', {
        timeOut: 3000,
        positionClass: 'toast-top-right',
      });
    }
    return throwError(error);
  }

  OkLogout()
  {
    window.location.href ="./ad-login";
  }   
  

  



  ngOnDestroy(): void
  {
    this.dtTrigger1.unsubscribe();
    this.dtTrigger3.unsubscribe();
    this.dtTrigger.unsubscribe();
  }

  

  getIPList()
  {
    this.ipList = [];
    var id = 'tbltwoD' + this.idIndex;
    var table = $('#' + id).DataTable();
    table.destroy();
    this.idIndex = this.idIndex +1;
    this.spinner.show();
    this.dto.token = this.storage.retrieve('token');
    let headers = new HttpHeaders();
    headers = headers.set('Authorization', this.dto.token);
    this.http.get(this.funct.ipaddress + 'blackList/getiplist', { headers: headers } )
    .pipe(
      catchError(this.handleError.bind(this))
     )
    .subscribe(
      result => {
        this.dto.Response = {};
        this.dto.Response = result;
        this.ipList = this.dto.Response;
        this.totalItems = result.totalRows;
        this.dtTrigger.next();
        this.spinner.hide();
      }
    );
  }
  getMenuCodesgty(page: any)
  {    
      this.ipList = [];
      var id = 'tbltwoD' + this.idIndex;
      var table = $('#' + id).DataTable();
      table.destroy();
      this.idIndex = this.idIndex +1;
      this.spinner.show();
      this.dto.token = this.storage.retrieve('token');
      let headers = new HttpHeaders();
      headers = headers.set('Authorization', this.dto.token);
      this.http.get(this.funct.ipaddress + 'blackList/getiplist', { headers: headers } )
      .pipe(
        catchError(this.handleError.bind(this))
      )
      .subscribe(
        result => {
          this.dto.Response = {};
          this.dto.Response = result;
          this.ipList = this.dto.Response;
          this.totalItems = result.totalRows;
          this.dtTrigger.next();
          this.spinner.hide();
        }
      );

    }

    goSave(addip)
    {
      this.dto.token = this.storage.retrieve('token');
      let headers = new HttpHeaders();
      headers = headers.set('Authorization', this.dto.token); 
      var formData = new FormData();
      formData.append("IP", addip);   
      this.http.post(this.funct.ipaddress + 'BlackList/AddIP', formData, { headers: headers })  
      .pipe(
        catchError(this.handleError.bind(this))
       )
      .subscribe(
        result => {
          this.dto.Response = {};
          this.dto.Response = result;
          if(this.dto.Response.status == 'Success')
          {  
                this.spinner.hide();
                this.router.navigate(['/blacklist/blacklistiplist']).then(() => {
                  this.toastr.success(this.dto.Response.message, 'Success!', {
                    timeOut: 3000,
                    positionClass: 'toast-top-right'
                  });
                })
                this.ip="";
           
          }
          else{                 
            this.toastr.error(this.dto.Response.message, 'Invalid!', {
            timeOut: 3000,
            positionClass: 'toast-top-right',
            });
            this.spinner.hide(); 
            this.ip="";
           }
      
        }
      );
      
    }

    delete(ip)
    {
      this.dto.token = this.storage.retrieve('token');
      let headers = new HttpHeaders();
      headers = headers.set('Authorization', this.dto.token);      
   
      var formData = new FormData();
      formData.append("IP", ip);   
      this.http.post(this.funct.ipaddress + 'BlackList/DeleteIP', formData, { headers: headers })
      .pipe(
        catchError(this.handleError.bind(this))
       )
      .subscribe(
        result => {
          this.dto.Response = {};
          this.dto.Response = result;
          if(this.dto.Response.status == 'Success')
          {      
            
               this.spinner.hide();  
               this.getIPList();      
               this.toastr.success(this.dto.Response.message, 'Success!', {
                 timeOut: 3000,
                 positionClass: 'toast-top-right'
               }) 
               
               
           }
        }
      );
      
    }

    goSaveUserId(userid)
    {
      this.dto.token = this.storage.retrieve('token');
      let headers = new HttpHeaders();
      headers = headers.set('Authorization', this.dto.token); 
      var formData = new FormData();
      formData.append("phoneNo", userid);   
      this.http.post(this.funct.ipaddress + 'BlackList/AddUserID_ByPhoneNo', formData, { headers: headers })  
      .pipe(
        catchError(this.handleError.bind(this))
       )
      .subscribe(
        result => {
          this.dto.Response = {};
          this.dto.Response = result;
          if(this.dto.Response.status == 'Success')
          {  
                this.spinner.hide();
                this.router.navigate(['/blacklist/blacklistiplist']).then(() => {
                  this.toastr.success(this.dto.Response.message, 'Success!', {
                    timeOut: 3000,
                    positionClass: 'toast-top-right'
                  });
                })
                this.userid="";
           
          }
          else{                 
            this.toastr.error(this.dto.Response.message, 'Invalid!', {
            timeOut: 3000,
            positionClass: 'toast-top-right',
            });
            this.spinner.hide(); 
            this.userid="";
            
           }
      
        }
      );
    }

  getUserIDList()
  {
    this.useripList = [];
    var id2 = 'tblthreeD' + this.idIndexTD;
    var table2 = $('#' + id2).DataTable();
    table2.destroy();
    this.idIndexTD = this.idIndexTD +1;
    this.spinner.show();
    this.dto.token = this.storage.retrieve('token');
    let headers = new HttpHeaders();
    headers = headers.set('Authorization', this.dto.token);
    this.http.get(this.funct.ipaddress + 'blackList/getUserlist', { headers: headers } )
    .pipe(
      catchError(this.handleError.bind(this))
     )
    .subscribe(
      result => {
        this.dto.Response = {};
        this.dto.Response = result;
        this.useripList = this.dto.Response;
        this.totalItems = result.totalRows;
        this.dtTrigger1.next();
        this.spinner.hide();
      }
    );
  }


  deleteUser(userid)
  {
    $("#deleteDataForTutorial").modal("show");
    this.transaction_tutorial = userid;       
  } 

  closeTran()
  {
      $('#deleteDataForTutorial').modal("hide");
      this.router.navigate(['/blacklist/blacklistiplist']).then(() =>
       {
      // this.toastr.error("Transaction Tutorial ", 'Cancel!', {
      //   timeOut: 3000,
      //   positionClass: 'toast-top-right',
      // });
    });
  }

  deleteUserId()
  {
    this.dto.token = this.storage.retrieve('token');
    let headers = new HttpHeaders();
    headers = headers.set('Authorization', this.dto.token);      
 
    var formData = new FormData();
    formData.append("UserId", this.transaction_tutorial);   
    this.http.post(this.funct.ipaddress + 'BlackList/DeleteUserID', formData, { headers: headers })
    .pipe(
      catchError(this.handleError.bind(this))
     )
    .subscribe(
      result => {
        this.dto.Response = {};
        this.dto.Response = result;
        if(this.dto.Response.status == 'Success')
        { 
             this.spinner.hide();  
             this.getUserIDList();      
             this.toastr.success(this.dto.Response.message, 'Success!', {
               timeOut: 3000,
               positionClass: 'toast-top-right'
             }) 
                         
         }
      }
    );
    $('#deleteDataForTutorial').modal("hide");
    
  }
  onChangeTDWLTagSection() {     
    this.storage.store('TDWLTagsection', this.TDWLsection.toString());  
  }

}
