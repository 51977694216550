import { Component, OnInit, ViewChild } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,HttpErrorResponse } from '@angular/common/http';
import { DataTableDirective } from 'angular-datatables';
import { Router, NavigationEnd } from '@angular/router';

import { LocalStorageService } from 'ngx-webstorage';
import { NgxSpinnerService } from "ngx-spinner";

import { catchError, retry } from 'rxjs/operators';
import { throwError } from 'rxjs';

import { DtoService } from '../../service/dto.service';
import { FunctService } from '../../service/funct.service';
import { ToastrService } from 'ngx-toastr';
import Responsive from 'datatables.net-responsive'; 


import { Subject } from 'rxjs';
declare var $: any;

@Component({
  selector: 'app-feedback',
  templateUrl: './feedback.component.html',
  styleUrls: ['./feedback.component.css']
})
export class FeedbackComponent implements OnInit {

  @ViewChild(DataTableDirective)
  dtElement: DataTableDirective;
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();

  idIndex: any;
  title: string = '';
  phoneNo: string = '';

  feedBack: any;

  hardcode_edit :any;
  hardcode_add :any;
  hardcode_view: any;
  hardcode_delete:any;
  hardcode_view_bool : any;
  hardcode_add_bool : any;
  hardcode_edit_bool : any;
  hardcode_delete_bool:any;
  hardcode_double_bool:any; 

  itemsPerPage =  10;
  totalItems : any; 
  page = 1;

  constructor(private toastr: ToastrService,private storage: LocalStorageService, private spinner: NgxSpinnerService, 
    private dto: DtoService, private http: HttpClient, private funct: FunctService, private router: Router,) {
    this.idIndex = 1;
    this.storage.store('isNotiSong', "");
    this.hardcode_view = "Feedback_View";
    this.hardcode_edit = "Feedback_Edit";
 
    this.dto.menuCodes = this.storage.retrieve('menucodes');
    const filteredMenuCodes_view = [];
    const filteredMenuCodeds_edit =[];

    this.dto.menuCodes.forEach(menuCode => {
      if (menuCode === this.hardcode_view) {
        filteredMenuCodes_view.push(menuCode);
      }
    
      if (menuCode === this.hardcode_edit) {
        filteredMenuCodeds_edit.push(menuCode);
      }

    });
    if(filteredMenuCodes_view.length != 0)
    {
          this.hardcode_view_bool = true;
    }
  
    if(filteredMenuCodeds_edit.length != 0)
    {
          this.hardcode_edit_bool = true;
    }
 
    this.search()
   }

   ngOnInit(): void 
   {

    this.router.routeReuseStrategy.shouldReuseRoute = function () {
      return false;
    };

    this.router.events.subscribe((evt) => {
      if (evt instanceof NavigationEnd) {       
        this.router.navigated = false;       
        window.scrollTo(0, 0);
      }
    });
    //amk

    
    this.dtOptions = {
      responsive: 
      //true,
      {
        details: {
            renderer: Responsive.renderer.listHiddenNodes()
        }
    },
      order: [],
      paging: false,
      info:true,
      destroy : true
    }

    this.dtOptions.columnDefs = [
      { targets: [4], orderable: false}
    ];

    //

    if (!this.storage.retrieve('loadFlag')) {
      this.storage.store('loadFlag', 'noLoad');
      setTimeout(function () {
        location.reload();
      }, 5);
    }
    else {
      this.storage.clear('loadFlag');
    }
  }

  handleError(error: HttpErrorResponse){
    if(error.status == 423)
    {
      this.spinner.hide();
      $("#deleteData").modal("show");
    }
    if(error.status == 403)
    {
      this.spinner.hide();
      var id = 'tblFeedback' + this.idIndex;
      var table = $('#' + id).DataTable();
      this.toastr.error("Limited Access.", 'Invalid!', {
        timeOut: 3000,
        positionClass: 'toast-top-right',
        });
    }
    return throwError(error);
    }
    OkLogout()
    {
      window.location.href ="./ad-login";
    } 
  
  search()
   {    

        this.feedBack = [];
        var id = 'tblFeedback' + this.idIndex;
        var table = $('#' + id).DataTable();
        table.destroy();
        this.idIndex = this.idIndex + 1;
        this.spinner.show();
        this.dto.token = this.storage.retrieve('token');
        let headers = new HttpHeaders();
        headers = headers.set('Authorization', this.dto.token);
        let params = new HttpParams();
        if(this.title == undefined)
        {
          this.title ="";
        }
        if( this.phoneNo == undefined)
        {
          this.phoneNo="";
        }
        params = params.set('subject', this.title).set('phoneNo', this.phoneNo).set("pageNumber","1").set("rowsOfPage","10");       
        this.http.get(this.funct.ipaddress + 'feedback/feedbackByparamsPaging', { params: params, headers: headers })//feedbackByparams
        .pipe(
          catchError(this.handleError.bind(this))
        )
        .subscribe( 
          result => {
            this.dto.Response = {};
            this.dto.Response = result.results;          
            this.feedBack = this.dto.Response;
            this.totalItems = result.totalRows;
            this.dtTrigger.next();
            this.spinner.hide();
          }
        );
  }

    
  gty(page:any) 
  {
        this.feedBack = [];
        var id = 'tblFeedback' + this.idIndex;
        var table = $('#' + id).DataTable();
        table.destroy();
        this.idIndex = this.idIndex + 1;
        this.spinner.show();
        this.dto.token = this.storage.retrieve('token');
        let headers = new HttpHeaders();
        headers = headers.set('Authorization', this.dto.token);
        let params = new HttpParams();
        if(this.title == undefined)
        {
          this.title ="";
        }
        if( this.phoneNo == undefined)
        {
          this.phoneNo="";
        }
        params = params.set('subject', this.title).set('phoneNo', this.phoneNo).set("pageNumber",page.toString()).set("rowsOfPage",this.itemsPerPage.toString());          
        this.http.get(this.funct.ipaddress + 'feedback/feedbackByparamsPaging', { params: params, headers: headers })
        .pipe(
          catchError(this.handleError.bind(this))
        )
        .subscribe( 
          result => {
            this.dto.Response = {};
            this.dto.Response = result.results;
            this.feedBack = this.dto.Response;
            this.totalItems = result.totalRows;
            this.dtTrigger.next();
            this.spinner.hide();
            
          }
        );
  }
}
