<div class="wrapper">

  <app-header-sidebar></app-header-sidebar>

  <div class="content-wrapper">
    <div class="content-header">
      <div class="container-fluid">
        <div class="row mb-2">
          <div class="col-sm-6">
            <h1 class="m-0 text-dark">Dashboard</h1>
          </div>

        </div>
      </div>
    </div>

    <section class="content">
      <div class="container-fluid">

        <div class="row">
          <div class="clearfix hidden-md-up"></div>
          <div class="col-12 col-sm-6 col-md-3" *ngIf="hardcode_first_deposit_bool === true">
            <div class="info-box mb-3" style="cursor: pointer;">
              <span class="info-box-icon bg-success elevation-1"><i class="fas fa-dollar-sign"></i></span>
              <div class="info-box-content" [routerLink]="['/firsttopuplist']" style="cursor: pointer;">
                <span class="info-box-text"> First Deposit ( {{first_count_user}} ) </span>
                <span class="info-box-number"> {{first_deposit}} <small>MMK</small></span>
              </div>
            </div>
          </div>


          <div class="col-12 col-sm-6 col-md-3">
            <div class="info-box mb-3" *ngIf="hardcode_feedback_name_bool === true">
              <span class="info-box-icon bg-danger elevation-1"><i class="fas fa-comment-dots"></i></span>

              <div class="info-box-content" [routerLink]="['/feedbackandfaq/feedback-list']" style="cursor: pointer;">
                <span class="info-box-text">Feedback</span>
                <span class="info-box-number">{{feedback_count | number }} <small>total</small></span>
              </div>

            </div>

          </div>


          <div class="col-12 col-sm-6 col-md-3" *ngIf="hardcode_total_member_name_bool === true">
            <div class="info-box mb-3" style="cursor: pointer;" [routerLink]="['/detailshistory/active']">
              <span class="info-box-icon bg-warning elevation-1"><i class="fas fa-users"
                  style="color : white"></i></span>
              <div class="info-box-content">
                <span class="info-box-text">Total Members <span style="font-weight: bold;">{{total_count |
                    number}}</span></span>

                <span class="info-box-text">Active <span style="font-weight: bold;">{{total_active_users |
                    number}}</span></span>

                <span class="info-box-text">InActive <span style="font-weight: bold;">{{total_inactive_users |
                    number}}</span></span>

              </div>
            </div>
          </div>


          <div class="col-12 col-sm-6 col-md-3" *ngIf="hardcode_total_user_balance_name_bool === true">
            <div class="info-box mb-3">
              <span class="info-box-icon elevation-1" style="background-color: rgb(184, 228, 82);"><i
                  class="fas fa-dollar-sign" style="color: white;"></i></span>
              <div class="info-box-content">
                <span class="info-box-text">Total User Balance &nbsp;&nbsp;&nbsp;&nbsp;<i class="fas fa-redo"
                    title="Refresh" style="color: green;cursor: pointer;"
                    (click)="refreshUserBalancecredit()"></i></span>
                <span class="info-box-number">{{total_user_balance}}</span>
              </div>

            </div>

          </div>




          <div class="clearfix hidden-md-up"></div>
          <div class="col-12 col-sm-6 col-md-3" *ngIf="hardcode_total_deposit_name_bool === true">
            <div class="info-box mb-3" [routerLink]="['/topup-check-list']" style="cursor: pointer;">
              <span class="info-box-icon bg-success elevation-1"><i class="fas fa-dollar-sign"></i></span>
              <div class="info-box-content">
                <span class="info-box-text"> Total Deposit </span>
                <span class="info-box-number">{{total_income}} <small>MMK</small></span>
              </div>
            </div>
          </div>

          <!-- <div class="col-12 col-sm-6 col-md-3" *ngIf="hardcode_total_withdrawal_name_bool === true">
                <div class="info-box mb-3"  [routerLink]="['/withdraw-check-list']" style="cursor: pointer;">
                  <span class="info-box-icon elevation-1" style="background-color: purple;">
                    <i class="fas fa-dollar-sign" style="color: white;"></i></span>
                  <div class="info-box-content">
                    <span class="info-box-text">Today Withdraw </span>
                    <span class="info-box-number">{{total_outcome}} <small>MMK</small></span>
                  </div>
                 
                </div>
              </div> -->



          <div class="col-12 col-sm-6 col-md-3">
            <div class="info-box mb-3" [routerLink]="['/withdraw-check-list']" style="cursor: pointer;"
              *ngIf="hardcode_total_freeze_amount === true">
              <span class="info-box-icon elevation-1" style="background-color: rgb(184, 228, 82);"><i
                  class="fas fa-dollar-sign" style="color: white;"></i></span>
              <div class="info-box-content">
                <span class="info-box-text">Total Freeze Amount </span>
                <span class="info-box-number">{{total_freeze_balance}} <small>MMK</small> </span>
              </div>

            </div>

          </div>


          <div class="col-12 col-sm-6 col-md-3 detail" *ngIf="hardcode_total_withdrawal_name_bool === true">
            <div class="info-box mb-3" [routerLink]="['/withdraw-check-list']" style="cursor: pointer;">
              <span class="info-box-icon elevation-1" style="background-color: purple;">
                <i class="fas fa-dollar-sign" style="color: white;"></i></span>
              <div class="info-box-content">
                <span class="info-box-text">Today Withdraw </span>
                <span class="info-box-number">{{total_outcome}} <small>MMK</small></span>
              </div>

            </div>

            <button class="detail-button" style="background-color: rgba(97, 134, 204, 1);"
              (click)="handleButtonClick()">View Detail From Here!!</button>

          </div>



          <!-- <div class="col-12 col-sm-6 col-md-3 detail" *ngIf="hardcode_today_reach_amount_name_bool === true">
            <div class="info-box mb-3" (click)="goModal()" style="cursor: pointer">
              <span class="info-box-icon elevation-1" style="background-color: #993300;"><i class="fas fa-dollar-sign"
                  style="color: white;"></i></span>
              <div class="info-box-content">
                <span class="info-box-text">Today Reach Amount / My Amount </span>
                <span class="info-box-number"> <strong>Topup</strong> - {{accountDetailLists.topupTodayReach}} / {{accountDetailLists.topupMyAmount}} <small>MMK</small></span>
                <span class="info-box-number"> <strong>Withdrawal</strong> - {{accountDetailLists.withdrawalTodayReach}} / {{accountDetailLists.withdrawalMyAmount}} <small>MMK</small></span>
              </div>
            </div>

            <span class="tooltiptext">View Detail From Here!!</span>
          </div> -->


          <div class="col-12 col-sm-6 col-md-3" *ngIf="hardcode_game_name_bool === true">
            <div class="info-box mb-3">
              <span class="info-box-icon elevation-1" style="background-color: rgb(56, 13, 245);"><i
                  class="fas fa-dollar-sign" style="color: white;"></i></span>
              <div class="info-box-content">
                <span class="info-box-text">Game (Credit) &nbsp;&nbsp;&nbsp;&nbsp;<i class="fas fa-redo" title="Refresh"
                    style="color: green;cursor: pointer;" (click)="refreshQMcredit()"></i>
                </span>
                <span class="info-box-number">{{total_qm_balance}} <small>MMK</small></span>

              </div>

            </div>

          </div>


          <div class="col-12 col-sm-6 col-md-3 detail" *ngIf="hardcode_today_reach_amount_name_bool === true">
            <div class="info-box mb-3" (click)="goModal()" style="cursor: pointer">
              <span class="info-box-icon elevation-1" style="background-color: #993300;">
                <i class="fas fa-dollar-sign" style="color: white;"></i></span>

              <div class="info-box-content">
                <span class="info-box-text">Today Reach Amount / My Amount</span>
                <span class="info-box-number">
                  <span style="font-weight: normal;">Topup - </span>
                  <span style="font-weight: bold;">{{accountDetailLists.topupTodayReach}} /
                    {{accountDetailLists.topupMyAmount}}</span>
                  <small> MMK</small>
                </span>
                <span class="info-box-number">
                  <span style="font-weight: normal;">Withdrawal - </span>
                  <span style="font-weight: bold;">{{accountDetailLists.withdrawalTodayReach}} /
                    {{accountDetailLists.withdrawalMyAmount}}</span>
                  <small> MMK</small>
                </span>
              </div>

            </div>

            <span class="tooltiptext">View Detail From Here!!</span>
          </div>

        </div>

      </div>
    </section>

  </div>

  <aside class="control-sidebar control-sidebar-dark">

  </aside>

  <app-footer></app-footer>
  <ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#fff" type="square-jelly-box">
    <p style="font-size: 16px; color: white">Loading...</p>
  </ngx-spinner>

</div>

<div id="accountDetailData" class="modal fade">
  <div class="modal-dialog">
    <div class="modal-content" style="width : 1000px; margin-left: -200px;">
      <div class="modal-header">
        <h3 class="modal-title" style="font-weight: bold;font-size: 1.1rem;"> Bank Account Detail </h3>
        <button type="button" class="close" data-dismiss="modal" aria-hidden="true" (click)="Close()"> x </button>
      </div>
      <div class="modal-body">
        <form>
          <div class="row">
            <div class="form-group col-md-2">
              <label style="margin-left: 12%;">Payment Type </label>
            </div>
            <div class="form-group col-md-2" style="margin-right: 63%;">
              <select class="type form-control form-control-sm col-md-12 col-sm-6 col-xs-6" [(ngModel)]="type"
                [ngModelOptions]="{standalone: true}" (ngModelChange)="onTypeChange($event)">
                <option value="TOPUP" selected>TOPUP</option>
                <option value="WITHDRAWAL">WITHDRAWAL</option>
              </select>
            </div>
          </div>

          <div class="col-md-12">
            <div class="card">

              <div class="card-body">
              
                  <table width='100%' id="{{'tblServicePhone' + idIndex}}" datatable [dtTrigger]="dtTrigger" [dtOptions]="dtOptions" class="table table-bordered table-hover">                  
                  
                  <thead>
                    <tr>
                      <th style="font-family: unicode;">No</th>
                      <th style="font-family: unicode;" class="text-center">Type</th>
                      <th style="font-family: unicode;" class="text-center">Bank Type</th>
                      <th style="font-family: unicode;" class="text-center">Account Name</th>
                      <th style="font-family: unicode;" class="text-center">Account Number</th>
                      <th style="font-family: unicode;" class="text-center">Limited Amount</th>
                      <th style="font-family: unicode;" class="text-center">Today Reach Amount</th>
                      <th style="font-family: unicode;" class="text-center">My Amount</th>
                    </tr>
                  </thead>
                  <tbody>
                    <ng-container *ngFor="let accDTO of accountDetailList, let i = index">
                      <tr>
                        <td style="font-family: unicode;padding: 0.35rem 0.75rem 0.30rem 0.75rem;">{{ i + 1 }}
                        </td>
                        <td
                          style="font-family: unicode;padding: 0.35rem 2rem 0.30rem 0.75rem;text-align: left;width : 200px">
                          {{accDTO.type}}
                        </td>
                        <td
                          style="font-family: unicode;padding: 0.35rem 2rem 0.30rem 0.75rem;text-align: left;width : 200px">
                          {{accDTO.payment_type}}
                        </td>
                        <td
                          style="font-family: unicode;padding: 0.35rem 2rem 0.30rem 0.75rem;text-align: left;width : 200px">
                          {{accDTO.account_name}}
                        </td>
                        <td style="font-family: unicode;padding: 0.35rem 0.75rem 0.30rem 0.75rem;width : 200px">
                          {{accDTO.account_no}}
                        </td>
                        <td
                          style="font-family: unicode;padding: 0.35rem 2rem 0.30rem 0.75rem;text-align: right;width : 200px">
                          {{accDTO.limited_amt_Str}}
                        </td>
                        <td
                          style="font-family: unicode;padding: 0.35rem 2rem 0.30rem 0.75rem;text-align: right;width : 200px">
                          {{accDTO.today_reach_amt_Str}}
                        </td>
                        <td
                          style="font-family: unicode;padding: 0.35rem 2rem 0.30rem 0.75rem;text-align: right;width : 200px">
                          {{accDTO.my_amount_Str}}
                        </td>
                      </tr>
                    </ng-container>
                    
                  </tbody>

                  <tbody>
                    <tr style="background-color:steelblue; color: white">
                      <td style="font-family: unicode;padding: 0.35rem 0.75rem 0.30rem 0.75rem; text-align: right;"
                        colspan="6">Today Total Reach Amount & My Amount</td>
                      <!-- 5 -->
                      <td
                        style="font-family: unicode;padding: 0.35rem 2rem 0.30rem 0.75rem;text-align: right; width : 200px">
                        {{total_today_reach_amt_topup}}</td>
                      <td
                        style="font-family: unicode;padding: 0.35rem 2rem 0.30rem 0.75rem;text-align: right; width : 200px">
                        {{total_my_amount_topup}}</td>
                      <td class="hidden" style="display: none; border: 0px ;"></td>
                      <td class="hidden" style="display: none; border: 0px ;"></td>
                      <td class="hidden" style="display: none; border: 0px ;"></td>
                      <td class="hidden" style="display: none; border: 0px ;"></td>
                      <td class="hidden" style="display: none; border: 0px ;"></td>
                      <td class="hidden" style="display: none; border: 0px ;"></td>
                      <!-- column တစ်ခု တိုးရင် အောက်က တစ်ခု ထည့်ရင် ၆ ကို ၇ ပြောင်းရန် -->
                    </tr>
                  </tbody>


                </table>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>

<div id="deleteData" class="modal fade">
  <div class="modal-dialog">
    <div class="modal-content">

      <div class="modal-body">
        <form>

          <div class="row" style="margin-top: 5%;margin-bottom: 3%;margin-left: 9%;">
            <div class="form-group col-md-12">
              <h5> This Account is used by another device.
                Please Sign Out. Login Again. </h5>
            </div>
          </div>

          <div class="row" style="margin-left: 40%;margin-bottom: 3%;">
            <div class="col-md-5 col-sm-6" id="deleteBtn">
              <button type="button" class="btn btn-block btn-success" (click)="OkLogout()"> Ok </button>
            </div>
          </div>
        </form>
      </div>

    </div>
  </div>
</div>



<div id="accountDetailWithdrawalData" class="modal fade">
  <div class="modal-dialog">
    <div class="modal-content" style="width : 1000px; margin-left: -200px;">
      <div class="modal-header">
        <h3 class="modal-title" style="font-weight: bold;font-size: 1.1rem;"> Withdrawal Detail </h3>
        <button type="button" class="close" data-dismiss="modal" aria-hidden="true" (click)="Close()"> x </button>
      </div>
      <div class="modal-body">
        <form>
          <div class="col-md-12">
            <div class="card">
              <div class="card-body">
                <table width='100%' id="{{'tblServiceWithdrawal' + idpendingIndex}}" datatable [dtTrigger]="dtTrigger1"
                  [dtOptions]="dtOptions1" class="table table-bordered table-hover">
                  <thead>
                    <tr>
                      <th style="font-family: unicode;" class="text-center">No</th>
                      <th style="font-family: unicode;" class="text-center">Admin</th>
                      <th style="font-family: unicode;" class="text-center">Account Number</th>
                      <th style="font-family: unicode;" class="text-center">Wave </th>
                      <th style="font-family: unicode;" class="text-center">KBZ Pay </th>
                      <th style="font-family: unicode;" class="text-center">AYA Pay</th>
                      <th style="font-family: unicode;" class="text-center">CB Pay</th>
                      <th style="font-family: unicode;" class="text-center">Withdrawal</th>
                      <th style="font-family: unicode;" class="text-center">Trans Qty</th>
                    </tr>
                  </thead>
                  <tbody>
                    <ng-container *ngFor="let accDTO of accountDetailList, let i = index">
                      <tr>
                        <td style="font-family: unicode;padding: 0.35rem 0.75rem 0.30rem 0.75rem;" class="text-center">
                          {{ i + 1 }}
                        </td>
                        <td
                          style="font-family: unicode;padding: 0.35rem 2rem 0.30rem 0.75rem;text-align: left;width : 200px"
                          class="text-center">
                          {{accDTO.name}}
                        </td>
                        <td
                          style="font-family: unicode;padding: 0.35rem 2rem 0.30rem 0.75rem;text-align: left;width : 200px"
                          class="text-center">
                          {{accDTO.phone_no}}
                        </td>
                        <td
                          style="font-family: unicode;padding: 0.35rem 2rem 0.30rem 0.75rem;text-align: right;width : 200px"
                          class="text-right">
                          {{accDTO.wave_amount_str}}
                        </td>
                        <td
                          style="font-family: unicode;padding: 0.35rem 2rem 0.30rem 0.75rem;text-align: right;width : 200px"
                          class="text-right">
                          {{accDTO.kpay_amount_str}}
                        </td>
                        <td
                          style="font-family: unicode;padding: 0.35rem 2rem 0.30rem 0.75rem;text-align: right;width : 200px"
                          class="text-right">
                          {{accDTO.ayaPay_amount_str}}
                        </td>
                        <td
                          style="font-family: unicode;padding: 0.35rem 2rem 0.30rem 0.75rem;text-align: right;width : 200px"
                          class="text-right">
                          {{accDTO.cbPay_amount_str}}
                        </td>
                        <td style="font-family: unicode;padding: 0.35rem 2rem 0.30rem 0.75rem;width : 100px"
                          class="text-right">
                          {{accDTO.total_amount_str}}
                        </td>
                        <td style="font-family: unicode;padding: 0.35rem 2rem 0.30rem 0.75rem;width : 100px"
                          class="text-right">
                          {{accDTO.trans_Qty}}
                        </td>

                      </tr>
                    </ng-container>
                    <!-- <tr style="background-color:steelblue; color: white">
                      <td style="font-family: unicode;padding: 0.35rem 0.75rem 0.30rem 0.75rem; text-align: right;"
                        colspan="3"> Total </td>
                      <td
                        style="font-family: unicode;padding: 0.35rem 0.75rem 0.30rem 0.75rem;text-align: right; width : 100px">
                        {{waveTotal}}</td>
                      <td
                        style="font-family: unicode;padding: 0.35rem 0.75rem 0.30rem 0.75rem;text-align: right; width : 100px">
                        {{kpayTotal}}</td>
                      <td
                        style="font-family: unicode;padding: 0.35rem 0.75rem 0.30rem 0.75rem;text-align: right; width : 100px">
                        {{ayaPayTotal}}</td>
                      <td
                        style="font-family: unicode;padding: 0.35rem 0.75rem 0.30rem 0.75rem;text-align: right; width : 100px">
                        {{cbPayTotal}}</td>
                      <td
                        style="font-family: unicode;padding: 0.35rem 0.75rem 0.30rem 0.75rem;text-align: right; width : 100px">
                        {{total_withdrawal}}</td>
                      <td
                        style="font-family: unicode;padding: 0.35rem 0.75rem 0.30rem 0.75rem;text-align: right; width : 100px">
                        {{total_qty}}</td>
                    
                      <td class="hidden" style="display: none; border: 0px ;"></td>
                      <td class="hidden" style="display: none; border: 0px ;"></td>
                    </tr> -->
                  </tbody>

                  <tbody>
                    <tr style="background-color:steelblue; color: white">
                      <td style="font-family: unicode;padding: 0.35rem 0.75rem 0.30rem 0.75rem; text-align: right;"
                        colspan="3"> Total </td>
                      <td
                        style="font-family: unicode;padding: 0.35rem 0.75rem 0.30rem 0.75rem;text-align: right; width : 100px">
                        {{waveTotal}}</td>
                      <td
                        style="font-family: unicode;padding: 0.35rem 0.75rem 0.30rem 0.75rem;text-align: right; width : 100px">
                        {{kpayTotal}}</td>
                      <td
                        style="font-family: unicode;padding: 0.35rem 0.75rem 0.30rem 0.75rem;text-align: right; width : 100px">
                        {{ayaPayTotal}}</td>
                      <td
                        style="font-family: unicode;padding: 0.35rem 0.75rem 0.30rem 0.75rem;text-align: right; width : 100px">
                        {{cbPayTotal}}</td>
                      <td
                        style="font-family: unicode;padding: 0.35rem 0.75rem 0.30rem 0.75rem;text-align: right; width : 100px">
                        {{total_withdrawal}}</td>
                      <td
                        style="font-family: unicode;padding: 0.35rem 0.75rem 0.30rem 0.75rem;text-align: right; width : 100px">
                        {{total_qty}}</td>
                    
                      <td class="hidden" style="display: none; border: 0px ;"></td>
                      <td class="hidden" style="display: none; border: 0px ;"></td>
                    </tr>
                  </tbody>



                </table>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>










