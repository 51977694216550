import { Component, OnInit, ViewChild } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,HttpErrorResponse } from '@angular/common/http';
import { Router, NavigationEnd,ActivatedRoute } from '@angular/router';
import { DataTableDirective } from 'angular-datatables';

import { catchError, retry } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { ToastrService } from 'ngx-toastr';

import { LocalStorageService } from 'ngx-webstorage';
import { NgxSpinnerService } from "ngx-spinner";
import { FunctService } from '../../service/funct.service';
import { DtoService } from '../../service/dto.service';
import { DatePipe } from '@angular/common'

import { Subject } from 'rxjs';
import { DateRange } from '@ngx-tiny/date-picker';
import Responsive from 'datatables.net-responsive'; /*for responsive not working event datatable */
import { AnyRecordWithTtl } from 'dns';

declare var $: any;


@Component({
  selector: 'app-skm-lock-user-list',
  templateUrl: './skm-lock-user-list.component.html',
  styleUrls: ['./skm-lock-user-list.component.css'],
  providers: [DatePipe]//amk
})

export class SMKLockUserListComponent implements OnInit {

  @ViewChild(DataTableDirective)
  dtElement: DataTableDirective;
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();

  phoneNo: string= '';
  name: string= '';
  referralCode: string= '';
  status: string= '';
  userList: any;
  idIndex: any;
  approvedate : any;
  approvetodayDate : any;
  approveDate : any;
  allchangedate : any;

  approvetodate : any;
  allchangetodate : any;
  approvetodayToDate : any;
  approvetoDate : any;

  config: any;
  collection = [];
  page = 1;
  passenger: any; 
  itemsPerPage =  10;
  totalItems : any; 
  itemsPerPageFront =  10;
  appVersionList :[];
  appVersion : any;

  newTotalItems : any;

  startDate: string;
  defaultTodayDate: string;
  isDateRange : Date;
  endDate: string;
  selectedPreset: string;

  hardcode_edit :any; 
  hardcode_view: any;
  hardcode_view_bool : any;
  hardcode_edit_bool : any;

  deviceType:any;
  browserType:any;
  lockDTO:any;
  transaction_tutorial:any;
  
  constructor(private storage: LocalStorageService,private datepipe: DatePipe, private toastr: ToastrService,private spinner: NgxSpinnerService, private http: HttpClient, private dto: DtoService, private router: Router, 
    private route: ActivatedRoute, private funct: FunctService,) {
      this.storage.store('isNotiSong', "");
    this.idIndex = 1;
    this.phoneNo ="";
    this.getAllData();
    this.getAllAppVersion();  
   }   

  ngOnInit(): void {
    this.router.routeReuseStrategy.shouldReuseRoute = function () {
      return false;
    };

    this.router.events.subscribe((evt) => {
      if (evt instanceof NavigationEnd) {        
        this.router.navigated = false;      
        window.scrollTo(0, 0);
      }
    });

    this.dtOptions = {
      responsive: {
        details: {
            renderer: Responsive.renderer.listHiddenNodes()
        }
    },
      order:[],
      paging: false,
      info : true,
      dom: "Bfrltip", 
    }
    this.lockDTO ={
      phone_no : ''
    }

    this.dtOptions.columnDefs = [
      { targets: [12], orderable: true }

    ];

    if(!this.storage.retrieve('loadFlag')){
      this.storage.store('loadFlag', 'noLoad');
      setTimeout(function(){
        location.reload();
      }, 5);
    }
    else{
      this.storage.clear('loadFlag');
    }
  }
  onInput(event: InputEvent) {
    const myanmarCharactersRegex = new RegExp('^[\u1000-\u109F\uAA60-\uAA7F\uA9E0-\uA9FF]+$');
    const inputValue = (event.target as HTMLInputElement).value; 
    if(event.data!=null)
    {
      if (myanmarCharactersRegex.test(inputValue)) 
      {        
        this.spinner.hide();
        this.toastr.error( "Myanmar typing detected!", '', {
        timeOut: 5000,
        positionClass: 'toast-top-right',
        });  
      }
    } 
    
  }

  showEditRolesModel(phone_no)
  {
    $("#deleteDataForTutorial").modal("show");
    this.transaction_tutorial = phone_no; 
  }

  closeTran()
  {
      $('#deleteDataForTutorial').modal("hide");
      this.router.navigate(['/skmlockuserlist']).then(() => {
      this.toastr.error("SKM lock User ", 'Cancel!', {
        timeOut: 3000,
        positionClass: 'toast-top-right',
      });
    });
  }
 
  showEditRolesModels()
  {      
      
        this.dto.token = this.storage.retrieve('token');
        let headers = new HttpHeaders();
        headers = headers.set('Authorization',this.dto.token);  
        this.lockDTO.phone_no = this.transaction_tutorial;      
        this.http.post(this.funct.ipaddress + 'shkm/UnLockUser', this.lockDTO,{ headers: headers })

        .pipe(
          catchError(this.handleError.bind(this))
         )
        .subscribe
        (       
        
       );     
       $('#deleteDataForTutorial').modal("hide");
          this.getAllData();
  }


  ngAfterViewInit(){
  }

  handleError(error: HttpErrorResponse){
    if(error.status == 423)
    {
      this.spinner.hide();
      $("#deleteData").modal("show");
    }
    if(error.status == 403)
    {
      this.spinner.hide();
      var id = 'tblUser' + this.idIndex;
      var table = $('#' + id).DataTable();
      this.toastr.error("Limited Access.", 'Invalid!', {
        timeOut: 3000,
        positionClass: 'toast-top-right',
        });
    }
    return throwError(error);
    }
    OkLogout()
    {
      window.location.href ="./ad-login";
    }

  
    getAllData() {      
      this.userList = [];
      var id = 'tblUser' + this.idIndex;
      var table = $('#' + id).DataTable();
      table.destroy();
      this.idIndex = this.idIndex +1;
      this.spinner.show();
      this.dto.token = this.storage.retrieve('token');
      let headers = new HttpHeaders();
      headers = headers.set('Authorization',  this.dto.token); 
      let params = new HttpParams();  
      params = params.set('phone_no',  this.phoneNo);    
      this.http.get(this.funct.ipaddress + 'shkm/LockUserList', { params :params, headers: headers } ).subscribe((data: any) => 
      {
          this.dto.Response = {};
          this.dto.Response = data;
          this.userList = this.dto.Response;       
          this.dtTrigger.next();
          this.spinner.hide();
      })
    }

    gty(page: any){
      this.userList = [];
      var id = 'tblUser' + this.idIndex;
      var table = $('#' + id).DataTable();     
      table.destroy();
      this.idIndex = this.idIndex +1;
      this.spinner.show();
      let headers = new HttpHeaders();
      headers = headers.set('Authorization',  this.dto.token);  
      let params = new HttpParams();  
      params = params.set('phone_no',  this.phoneNo);   
      this.http.get(this.funct.ipaddress + 'shkm/LockUserList', {params :params, headers: headers } ).subscribe((data: any) => {
        this.dto.Response = {};
        this.dto.Response = data;
        this.userList = this.dto.Response;
        // this.totalItems = data.totalRows;
        this.dtTrigger.next();
        this.spinner.hide();
      })
    }

    getAllAppVersion()
    {
      this.dto.token = this.storage.retrieve('token');
      let headers = new HttpHeaders();
      headers = headers.set('Authorization', this.dto.token);
      let params = new HttpParams();
      this.http.get(this.funct.ipaddress + 'user/getAllAppVersion', {headers: headers }).subscribe(
        result => {
          this.dto.Response = {};
          this.dto.Response = result;
          this.appVersionList = this.dto.Response;
        }
      );
    }

  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }


  pageChange(newPage: number) {
    this.router.navigate(['/user-list'], { queryParams: { page: newPage } });
  }


}
