<div class="wrapper">
    <app-header-sidebar></app-header-sidebar>

    <div class="content-wrapper">
       
        <div class="content-header">
            <div class="container-fluid">
                <div class="row mb-2" style="border: 1px solid #ced4da;border-radius: 0.25rem;">
                    <div class="col-sm-8">
                        <h3 class="m-0 text-dark" style="font-weight: bold;padding: 3px 0px 3px 5px;">
                            First TopUp User List </h3>
                    </div>
                </div>
             
            </div>
        </div>
   
        <section class="content">
            <div class="container-fluid">
                <form>
                    <div class="row">

                        <div class="form-group col-md-2">
                            <label>Name</label>
                            <input type="text"
                              class="form-control form-control-sm" [(ngModel)]="name"
                              [ngModelOptions]="{standalone: true}">
                        </div>
                        <div class="form-group col-md-2">
                            <label>Phone No</label>
                            <input type="text"
                              class="form-control form-control-sm" [(ngModel)]="phone_no"
                              [ngModelOptions]="{standalone: true}">
                        </div>
                        <div class="form-group col-md-2">
                            <label>Referral Code</label>
                            <input type="text"
                              class="form-control form-control-sm" [(ngModel)]="referral_code"
                              [ngModelOptions]="{standalone: true}">
                        </div>
                        <div class="form-group col-md-2" >
                            <label>First TopUp Date</label>
                            <input id="alltodate" readonly class="form-control form-control-sm" placeholder="Select Date"
                              [ngxDatePicker]="dateInstanceSingle1" [value]="alltoDate" [(ngModel)]="alltodate"
                              [ngModelOptions]="{standalone: true}" (valueChange)="onChangeAllToDate()">
                            <ngx-date-picker #dateInstanceSingle1></ngx-date-picker>
                          </div>
                    
                        <div class="form-group col-md-2 col-sm-4 col-xs-4">
                            <label></label>
                            <button id="search" type="button" class="btn btn-block btn-primary btn-sm"
                                style="margin-top: 4%;" (click)="search()"> Search 
                            </button>
                        </div>
 
                         <div class="col-md-12">
                            <div class="card">
                                <div class="card-header">
                                    <h3 class="card-title" style="font-weight: bold;"> First TopUp User List</h3>
                                </div>
                               
                                <div class="card-body">
                                    <table width='100%' id="{{'tblComment' + idIndex}}" datatable [dtTrigger]="dtTrigger"
                                        [dtOptions]="dtOptions" class="table table-bordered table-hover">
                                        <thead>
                                            <tr>
                                                <th style="font-family: unicode;" class="text-center">Name</th>
                                                <th style="font-family: unicode;" class="text-center">Phone No</th>
                                                <th style="font-family: unicode;" class="text-center">Amount</th>
                                                <th style="font-family: unicode;" class="text-center">Referral Code</th>
                                                <th style="font-family: unicode;" class="text-center">Referral Date</th>
                                                <th style="font-family: unicode;" class="text-center">Register Date</th>
                                                <th style="font-family: unicode;" class="text-center">First Topup Date</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr *ngFor="let cmt of comment">
                                                <td style="font-family: unicode;" class="text-center">
                                                    {{cmt.name}}
                                                </td>
                                                <!-- <td style="font-family: unicode;" class="text-center">
                                                    <a [routerLink] = "['/first-topup-user-detail', cmt.userId]" style="text-decoration: underline; cursor: pointer;">{{cmt.phone_No}}
                                                    </a>
                                                </td>  -->

                                                <td style="font-family: unicode;" class="text-center">
                                                    <a [routerLink]="['/user-detail', cmt.userId]" [queryParams]="{ type: 'firsttopuplist' }">{{cmt.phone_No}}</a>
                                                  </td>
                                         

                                                <td style="font-family: unicode;" class="text-center">{{cmt.amount_str}}</td>
                                                <td style="font-family: unicode;" class="text-center">{{cmt.referral_Code}}</td>
                                                <td style="font-family: unicode;" class="text-center">{{cmt.referral_Date}}</td>
                                                <td style="font-family: unicode;" class="text-center">{{cmt.register_Date}}</td>
                                                <td style="font-family: unicode;" class="text-center">{{cmt.firstTopUpDate}}</td>                                            
                                            </tr>
                                        </tbody>
                                    </table>

                                    <tr *ngFor="let cmt of comment | paginate
                                    : {
                                        itemsPerPage: itemsPerPage,
                                        currentPage: page,
                                        totalItems: totalItems
                                      }" 
                                      >
                                  </tr> 
                                <div class = "row">
                                  <div class = "col-md-6"></div>
                                  <div class = "col-md-6">
                                    <pagination-controls
                                      class="pagi"
                                      (pageChange)="gty((page = $event))"
                                    ></pagination-controls>
                                </div>
                                </div>

                                      
                                </div>                               
                            </div>
                        </div> 



                       






                    </div>
                </form>
            
            </div>
       
        </section>
       
    </div>

    <aside class="control-sidebar control-sidebar-dark">
      
    </aside>

    <app-footer></app-footer>
    <ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#fff" type="square-jelly-box">
        <p style="font-size: 16px; color: white">Loading...</p>
    </ngx-spinner>
</div>


<div id="deleteData" class="modal fade">
    <div class="modal-dialog">
        <div class="modal-content">

            <div class="modal-body">
                <form>

                    <div class="row" style="margin-top: 5%;margin-bottom: 3%;margin-left: 9%;">
                        <div class="form-group col-md-12">
                            <h5> This Account is used by another device. 
                                Please Sign Out. Login Again. </h5>
                        </div>
                    </div>

                    <div class="row" style="margin-left: 40%;margin-bottom: 3%;">
                        <div class="col-md-5 col-sm-6" id="deleteBtn">
                            <button type="button" class="btn btn-block btn-success" (click)="OkLogout()"> Ok </button>
                        </div>
                    </div>
                </form>
            </div>

        </div>
    </div>
</div>


