
      

                    <div class="row">

                        <div class="form-group col-md-2">
                            <label>Comment</label>
                            <input type="text"
                              class="form-control form-control-sm" [(ngModel)]="commentmodel"
                              [ngModelOptions]="{standalone: true}">
                        </div>
                        <!-- <div class="form-group col-md-2" style="visibility: hidden;">
                          <label>From Date</label>
                          <input id="singleDate" readonly class="form-control form-control-sm" placeholder="Select Date"
                            [ngxDatePicker]="dateInstanceSingle" [value]="singleDate" [(ngModel)]="alldate"
                            [ngModelOptions]="{standalone: true}" (valueChange)="onChangeSingle()">
                          <ngx-date-picker #dateInstanceSingle></ngx-date-picker>
                        </div>
    
                        <div class="form-group col-md-2" style="visibility: hidden;">
                          <label>To Date</label>
                          <input id="alltodate" readonly class="form-control form-control-sm" placeholder="Select Date"
                            [ngxDatePicker]="dateInstanceSingle1" [value]="alltoDate" [(ngModel)]="alltodate"
                            [ngModelOptions]="{standalone: true}" (valueChange)="onChangeAllToDate()">
                          <ngx-date-picker #dateInstanceSingle1></ngx-date-picker>
                        </div>
                        <div class="form-group col-md-4">
                        </div> -->

                        <!-- <div class="form-group col-md-2">
                            <label>Name</label>
                            <input type="text"
                              class="form-control form-control-sm" [(ngModel)]="commentname"
                              [ngModelOptions]="{standalone: true}">
                        </div> -->

                        <div class="form-group col-md-2 col-sm-4 col-xs-4">
                            <label></label>
                            <button id="search" type="button" class="btn btn-block btn-primary btn-sm"
                                style="margin-top: 4%;" (click)="search()"> Search </button>
                        </div>

                        <div class="col-md-12">
                            <div class="card">
                                <div class="card-header">
                                    <h3 class="card-title" style="font-weight: bold;"> ThreeD Comment List </h3>
                                </div>
                                <!-- /.card-header -->
                                <div class="card-body">
                                    <table width='100%' id="{{'tblComment' + idIndex}}" datatable [dtTrigger]="dtTrigger"
                                        [dtOptions]="dtOptions" class="table table-bordered table-hover">
                                        <thead>
                                            <tr>
                                                <th style="font-family: unicode;">Comment</th>
                                                <th style="font-family: unicode;">Created Date</th>
                                                <th style="font-family: unicode;">Winner</th>
                                                <th style="font-family: unicode;">Commented User</th>
                                                <th style="font-family: unicode;">Comment User Account</th>
                                                <th style="font-family: unicode;">Winning Number</th>
                                                <th style="font-family: unicode;">Remark</th>
                                                <th style="font-family: unicode;">Status</th>
                                                <th style="font-family: unicode;" *ngIf="hardcode_edit_bool === true">Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr *ngFor="let cmt of comment">
                                                <td style="font-family: unicode;">
                                                    {{cmt.comment.length>50 ? (cmt.comment | slice:0:49)+' ...' : cmt.comment}}
                                                </td>
                                                <td style="font-family: unicode;">{{cmt.created_date_str}}</td>
                                                <td style="font-family: unicode;">{{cmt.winner_name}}</td>
                                                <td style="font-family: unicode;">{{cmt.comment_user_name}}</td>
                                                <td style="font-family: unicode;">{{cmt.comment_user_phone_no}}</td>
                                                <td style="font-family: unicode;">{{cmt.win_number}}</td>
                                                <td style="font-family: unicode;">{{cmt.remark}}</td>                                              
                                                <td style="font-family: unicode;">{{ cmt.status == true ? 'Active' : 'Inactive' }}</td>
                                                <td style="font-family: unicode;" *ngIf="cmt.remark == '' ">
                                                    <!-- <a [routerLink]="['/threed-winner-comment-detail', cmt.comment_id, 1]">
                                                        <i class="fas fa-eye" style="color:#4b88eb"></i>
                                                    </a> -->

                                                    <a (click)="addStepAndNavigate(cmt)">
                                                        <i class="fas fa-eye" style="color:#4b88eb"></i>
                                                    </a>
                                                </td>
                                                <td style="font-family: unicode;" *ngIf="cmt.remark == 'Winner Input' && hardcode_edit_bool === true">
                                                    <!-- <a [routerLink]="['/threed-winner-comment-detail', cmt.comment_id, 0]">
                                                        <i class="fas fa-eye" style="color:#4b88eb"></i>
                                                    </a> -->

                                                    <a (click)="addStepAndNavigate1(cmt)">
                                                        <i class="fas fa-eye" style="color:#4b88eb"></i>
                                                    </a>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <!-- /.card-body -->
                            </div>
                        </div>

                    </div>
           
   

    <ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#fff" type="square-jelly-box">
        <p style="font-size: 16px; color: white">Loading...</p>
    </ngx-spinner>



<div id="deleteData" class="modal fade">
    <div class="modal-dialog">
        <div class="modal-content">

            <div class="modal-body">
                <form>

                    <div class="row" style="margin-top: 5%;margin-bottom: 3%;margin-left: 9%;">
                        <div class="form-group col-md-12">
                            <h5> This Account is used by another device. 
                                Please Sign Out. Login Again. </h5>
                        </div>
                    </div>

                    <div class="row" style="margin-left: 40%;margin-bottom: 3%;">
                        <div class="col-md-5 col-sm-6" id="deleteBtn">
                            <button type="button" class="btn btn-block btn-success" (click)="OkLogout()"> Ok </button>
                        </div>
                    </div>
                </form>
            </div>

        </div>
    </div>
</div>