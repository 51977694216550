<div class="row">

  <div class="form-group col-md-2">
    <label>Name</label>
    <input class="form-control form-control-sm" [(ngModel)]="name" [ngModelOptions]="{standalone: true}">
  </div>

  <div class="form-group col-md-2">
    <label>Phone No</label>
    <input class="form-control form-control-sm" [(ngModel)]="phoneNo" [ngModelOptions]="{standalone: true}" (input)="onInput($event)">
  </div>

  <div class="form-group col-md-2">
    <label>Referral Code</label>
    <input class="form-control form-control-sm" [(ngModel)]="referralCode" [ngModelOptions]="{standalone: true}">
  </div>

  <div class="form-group col-md-2" >
    <label>Register From Date</label>
    <input id="approveDate" readonly class="form-control form-control-sm" placeholder="Select Date"
      [ngxDatePicker]="dateInstanceApprove"  [(ngModel)]="approvedate"
      [ngModelOptions]="{standalone: true}"  (ngModelChange)="onChangeApprove()">
    <ngx-date-picker #dateInstanceApprove></ngx-date-picker>
  </div>

  <div class="form-group col-md-2">
    <label>Register To Date</label>
    <input id="approvetoDate" readonly class="form-control form-control-sm" placeholder="Select Date"
      [ngxDatePicker]="dateInstanceApprove1"  [(ngModel)]="approvetodate"
      [ngModelOptions]="{standalone: true}"  (ngModelChange)="onChangeApproveTo()">
    <ngx-date-picker #dateInstanceApprove1></ngx-date-picker>
  </div>

  <div class="form-group col-md-2">
    <label>Status</label>
    <select class="status form-control form-control-sm col-md-12 col-sm-4 col-xs-4" [(ngModel)]="status" [ngModelOptions]="{standalone: true}">
      <option value="ACTIVE" selected>Active</option>
      <option value="INACTIVE">Inactive</option>
    </select>
  </div>
</div>

<div class="row">
  
  <div class="form-group col-md-2">
  <label>App Version</label>
      <select class="form-control form-control-sm"
          [(ngModel)]="appVersion"
          [ngModelOptions]="{standalone: true}" id="appVersion">
          <option *ngFor="let appVersion of appVersionList" value={{appVersion.app_version}}>
              {{appVersion.app_version}}
      </select>
  </div>


   <div class="form-group col-md-2">
    <label>Option</label>
    <select class="status form-control form-control-sm col-md-12 col-sm-4 col-xs-4" [(ngModel)]="option" [ngModelOptions]="{standalone: true}">
      <option value="complete" selected>Complete Needed Amount</option>
      <option value="incomplete">In-Complete Needed Amount</option>
      <option value="All">All</option>
    </select>
  </div>

  <div class="form-group col-md-2 col-sm-2 col-xs-2">
    <label>&nbsp;&nbsp;</label>
    <button id="search" type="button" class="btn btn-block btn-primary btn-sm" (click)="getAllData()">Search</button>
  </div>
</div>
  <div class="col-md-12">
    <div class="card">
      <div class="card-header">
      
        <h3 class="m-0 text-dark" style="font-weight: bold;padding: 3px 0px 3px 5px;"> <i class="nav-icon fas fa-star" style="color: green;"></i>  Point Promotion User List </h3>
      </div>
  
      <div class="card-body">
        <div class = "row">
          <div class="col-md-3 col-sm-4 col-xs-4" style="margin-left: -0.7%;" *ngIf="hardcode_add_bool === true">
              <button id="confirm" type="button" class="btn btn-block btn-primary btn-sm"
                  style="margin-bottom: 5%;" (click)="confirmAll()"> Add Point Wallet => Main Wallet  </button>
          </div>
        </div>

         <table width='100%' id="{{'tblUser' + idIndex}}" datatable [dtTrigger]="dtTrigger" [dtOptions]="dtOptions" class="table table-bordered table-hover">
          
          <thead> 
          <tr>
          <th>
              <input type="checkbox" name="userscheck"
                  (click)="checkuncheckall()"
                  style="width: 1.10rem;height: 1.10rem;margin-left: 28%;"
                  (change)="changeSelection($event,0)">
          </th>
          <th style="font-family: unicode;">Name</th>
          <th style="font-family: unicode;">Phone No</th>
          <th style="font-family: unicode;">App Version</th>
          <th style="font-family: unicode;">Wallet</th>
          <th style="font-family: unicode;">First Topup Amount</th>
          <th style="font-family: unicode;">Point Wallet</th>
          <th style="font-family: unicode;">Point Percentage (%)</th>
          <th style="font-family: unicode;">2D/3D Bet Amount</th>
          <th style="font-family: unicode;">General Game Bet Amount</th>
          <th style="font-family: unicode;">Total Bet Amount</th>
          <th style="font-family: unicode;">Need Bet Amount</th>
          <th style="font-family: unicode;">Register Date</th>
          <th style="font-family: unicode;">First Topup Date</th>
          <th style="font-family: unicode;">Total Days</th>
          <th style="font-family: unicode;">Updated Date</th>
          <th style="font-family: unicode;">Updated By</th>
          <th style="font-family: unicode;" *ngIf="hardcode_edit_bool === true">Action</th>
          </tr>
          </thead>
          <tbody>
          <tr *ngFor="let user of allList">
            <td>
              <input type="checkbox" name="userscheck" [checked]="isChecked"
                  value="{{user.id}}"
                  style="width: 1.10rem;height: 1.10rem;margin-left: 28%;"
                  (change)="changeSelection($event,user.id)">
           </td>

            <td style="font-family: unicode;width: 150px;">{{user.name}}</td>
      
            <td style="font-family: unicode;" >
              <div *ngIf="user.name == null">{{user.phone_no}}</div>
              <div *ngIf="user.name != null"><a [routerLink] = "['/user-detail', user.id]" style="color:#4b88eb"> {{user.phone_no}} </a> </div>
            </td>
            <td style="font-family: unicode;">{{user.app_version}}</td>
            <td style="font-family: unicode;" class="text-right">{{user.balance_Str}}</td>

            <td style="font-family: unicode;background-color:yellow" *ngIf = "(user.totalDays < 7 && user.totalBet <  user.needtotalBet)" >{{user.first_topup_amt_Str}}</td>
            <td style="font-family: unicode;background-color:green; color : #ffffff;" *ngIf = "(user.totalDays <= 7 || user.totalDays >= 7 ) && (user.totalBet >=  user.needtotalBet )" >{{user.first_topup_amt_Str}}</td>
            <td style="font-family: unicode;background-color:red; color : white;" *ngIf = "(user.totalDays >= 7 && user.totalBet <  user.needtotalBet )" >{{user.first_topup_amt_Str}}</td>

          
            <td style="font-family: unicode;background-color:yellow" class="text-right" *ngIf = "(user.totalDays < 7 && user.totalBet <  user.needtotalBet )">{{user.point_wallet | number : 0}}</td>
            <td style="font-family: unicode;background-color:green; color : white;" class="text-right" *ngIf = "(user.totalDays <= 7 || user.totalDays >= 7) && (user.totalBet >=  user.needtotalBet )">{{user.point_wallet | number : 0}}</td>
            <td style="font-family: unicode;background-color:red; color : white;" class="text-right" *ngIf = "(user.totalDays >= 7)  && (user.totalBet <  user.needtotalBet )">{{user.point_wallet | number : 0}}</td>

            <td style="font-family: unicode;background-color:yellow" class="text-right" *ngIf = "(user.totalDays < 7) && (user.totalBet <  user.needtotalBet )">{{user.point_percentage}} %</td>
            <td style="font-family: unicode;background-color:green; color : white;" class="text-right" *ngIf = "(user.totalDays <= 7 || user.totalDays >= 7 ) && (user.totalBet >=  user.needtotalBet )">{{user.point_percentage}} %</td>
            <td style="font-family: unicode;background-color:red; color : white;" class="text-right" *ngIf = "(user.totalDays >=  7)  && (user.totalBet <  user.needtotalBet )">{{user.point_percentage}} %</td>

            <td style="font-family: unicode;background-color:yellow" class="text-right" *ngIf = "(user.totalDays < 7) &&  (user.totalBet <  user.needtotalBet ) ">{{user.bet_amount_Str}} </td>
            <td style="font-family: unicode;background-color:green; color : white;" class="text-right" *ngIf = "(user.totalDays <= 7 || user.totalDays >= 7 ) && (user.totalBet >=  user.needtotalBet )">{{user.bet_amount_Str}} </td>
            <td style="font-family: unicode;background-color:red; color : white;" class="text-right" *ngIf = "(user.totalDays >= 7)  && (user.totalBet <  user.needtotalBet ) ">{{user.bet_amount_Str}} </td>

        
            <td style="font-family: unicode;background-color:yellow" class="text-right" *ngIf = "(user.totalDays < 7) && (user.totalBet <  user.needtotalBet )">{{user.general_game_betAmt | number : 0}} </td>
            <td style="font-family: unicode;background-color:green; color : white;" class="text-right" *ngIf = "(user.totalDays <= 7 || user.totalDays >= 7 ) && (user.totalBet >=  user.needtotalBet ) ">{{user.general_game_betAmt | number : 0}} </td>
            <td style="font-family: unicode;background-color:red; color : white;" class="text-right" *ngIf = "(user.totalDays >= 7) && (user.totalBet <  user.needtotalBet ) ">{{user.general_game_betAmt | number : 0}} </td>

            <td style="font-family: unicode;background-color:yellow" class="text-right" *ngIf = "(user.totalDays < 7) && (user.totalBet <  user.needtotalBet ) ">{{user.totalBet | number : 0}} </td>
            <td style="font-family: unicode;background-color:green; color : white;" class="text-right" *ngIf = "(user.totalDays <= 7 || user.totalDays >= 7 ) && (user.totalBet >=  user.needtotalBet ) ">{{user.totalBet | number : 0}} </td>
            <td style="font-family: unicode;background-color:red; color : white;" class="text-right" *ngIf = "(user.totalDays >= 7)  && (user.totalBet <  user.needtotalBet ) ">{{user.totalBet | number : 0}} </td>

            <td style="font-family: unicode;background-color:yellow" class="text-right" *ngIf = "(user.totalDays < 7) && (user.totalBet <  user.needtotalBet ) ">{{user.needtotalBet | number : 0}} </td>
            <td style="font-family: unicode;background-color:green; color : white;" class="text-right" *ngIf = "(user.totalDays <= 7 || user.totalDays >= 7 ) && (user.totalBet >=  user.needtotalBet )">{{user.needtotalBet | number : 0}}</td>
            <td style="font-family: unicode;background-color:red; color : white;" class="text-right" *ngIf = "(user.totalDays >= 7)  && (user.totalBet <  user.needtotalBet )">{{user.needtotalBet | number : 0}} </td>

            <td style="font-family: unicode;">{{user.created_date_Str}}</td>
            <td style="font-family: unicode;">{{user.first_topup_date_Str}}</td>
            <td style="font-family: unicode;">{{user.totalDays}}</td>
            <td style="font-family: unicode;">{{user.updated_date_Str}}</td>
            <td style="font-family: unicode;">{{user.updated_by_name}}</td>
            <td style="font-family: unicode;" *ngIf="hardcode_edit_bool === true">

          <a *ngIf = "( (user.totalDays <= 7 || user.totalDays >= 7 ) && user.totalBet >=  user.needtotalBet)" 
              [routerLink] = "['/point-promotion-users-detail', user.id]" style="background-color: green;padding: 5px;"> 
               Add Point Wallet => Main Wallet </a>

          <a *ngIf = "(user.totalDays >=7)  && (user.totalBet <  user.needtotalBet )" 
                style="background-color: red;color: #ffffff; pointer-events: none;padding: 5px 0px 5px 15px; "> 
              Due Date
           </a>

          <a *ngIf = "( user.totalDays < 7 && user.totalBet < user.needtotalBet)" 
           style="background-color: orange;color:#ffffff;"
           [routerLink] = "['/point-promotion-users-detail', user.id]">
              Add Point Wallet => Main Wallet
          </a>

         </td>
          </tr>                        
           </tbody>
          </table>
        
    </div>
    
    </div>


               
</div>

          

    <ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#fff" type="square-jelly-box">
      <p style="font-size: 16px; color: white">Loading...</p>
    </ngx-spinner>


<div id="deleteData" class="modal fade">
  <div class="modal-dialog">
      <div class="modal-content">

          <div class="modal-body">
              <form>

                  <div class="row" style="margin-top: 5%;margin-bottom: 3%;margin-left: 9%;">
                      <div class="form-group col-md-12">
                          <h5> This Account is used by another device. 
                              Please Sign Out. Login Again. </h5>
                      </div>
                  </div>

                  <div class="row" style="margin-left: 40%;margin-bottom: 3%;">
                      <div class="col-md-5 col-sm-6" id="deleteBtn">
                          <button type="button" class="btn btn-block btn-success" (click)="OkLogout()"> Ok </button>
                      </div>
                  </div>
              </form>
          </div>

      </div>
  </div>
</div>

<div id="pointToMain" class="modal fade">
    <div class="modal-dialog">
        <div class="modal-content">
  
            <div class="modal-body">
                <form>
  
                    <div class="row" style="margin-top: 5%;margin-bottom: 3%;margin-left: 9%;">
                        <div class="form-group col-md-12">
                            <h5> Point => Main Wallet</h5>
                        </div>
                    </div>
  
                    <div class="row" style="margin-left: 40%;margin-bottom: 3%;">
                        <div class="col-md-5 col-sm-6" id="deleteBtn">
                            <button type="button" class="btn btn-block btn-success" (click)="OkLogout()"> Ok </button>
                        </div>
                    </div>
                </form>
            </div>
  
        </div>
    </div>
  </div>
