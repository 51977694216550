import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class FunctService 
{

  public ipaddress: any;

  constructor() 
  {
     //testing
     //this.ipaddress= "http://148.72.246.131:93/api/"; //"https://api.gbost11.com/api/";
     //this.ipaddress = "https://testapi.shwetiger.com/api/"; // now use testing  
     //this.ipaddress= "https://api.gbost11.com/api/";   
     //prod
     this.ipaddress  = "https://api.shwetiger.com/api/";// now use prod
     //local--
     //this.ipaddress="http://localhost:22735/api/";
     //this.ipaddress="http://localhost:22735/api/";  // now use+
     //this.ipaddress="http://192.168.12.153/api/";
     
  }
} 
