    <div class="wrapper">      
        <app-header-sidebar></app-header-sidebar>
        <div class="content-wrapper">
        
          <div class="content-header">
            <div class="container-fluid">
              <div class="row mb-2" style="border: 1px solid #ced4da;border-radius: 0.25rem;">
                <div class="col-sm-8">
                  <h3 class="m-0 text-dark" style="font-weight: bold;padding: 3px 0px 3px 5px;"> Game Transaction </h3>
                </div>
              </div>
            </div>
          </div>
        
          <section class="content">
            <div class="container-fluid">
              <form>
               
                <div class="row">
                  <div class="form-group col-md-2">
                    <label>Phone Number</label>
                    <input class="form-control form-control-sm" [(ngModel)]="phoneNo" [ngModelOptions]="{standalone: true}"  (ngModelChange)="onChangePhoneNo()" >
                  </div>

                  <div class="form-group col-md-2">
                    <label>Amount</label>
                    <input class="form-control form-control-sm" [(ngModel)]="amount" [ngModelOptions]="{standalone: true}">
                  </div>

                 

                  <div class="form-group col-md-2">
                    <label>Is Abnormal?</label>
                    <select class="isAbnormal form-control form-control-sm col-md-12 col-sm-4 col-xs-4" [(ngModel)]="isAbnormal" [ngModelOptions]="{standalone: true}">                  
                      <option value=false selected>False</option> 
                      <option value=true>True</option>   
                      <option value= "">All</option>            
                    </select>
                  </div>

                  <div class="form-group col-md-2 col-sm-4 col-xs-4">
                    <label></label>
                    <button id="search" type="button" class="btn btn-block btn-primary btn-sm" style="margin-top: 4%;" (click)="search()">Search</button>
                  </div>

                  <div class="form-group col-md-2 col-sm-4 col-xs-4">
                    <label></label>
                    <button id="delete" type="button" class="btn btn-block btn-primary btn-sm" style="margin-top: 4%;" (click)="confirmAll()">BatchDelete</button>
                  </div> 

                  <div class="col-md-12">
                    <div class="card">
                      <div class="card-header">
                        <h3 class="card-title" style="font-weight: bold;"> Game Transaction Cash List </h3>
                      </div>
                     
                      <!-- <div class="card-body">
                        <table width='100%' id="{{'tblAdmin' + idIndex}}" datatable [dtTrigger]="dtTrigger" [dtOptions]="dtOptions" class="table table-bordered table-hover">
                          <thead>
                          <tr>  
                                 <th >
                              <input type="checkbox" name="logcheck"
                                  (click)="checkuncheckall()"
                                  style="width: 1.10rem;height: 1.10rem;margin-left: 28%;"
                                  (change)="changeSelection($event,0)">
                          </th>                         
                            <th style="font-family: unicode;">Name</th>
                            <th style="font-family: unicode;">Phone Number</th>
                            <th style="font-family: unicode;">Provider code</th>
                            <th style="font-family: unicode;">Amount</th>
                            <th style="font-family: unicode;">Deposit/Withdraw </th>
                            <th style="font-family: unicode;">Is Abnormal? </th>
                            <th style="font-family: unicode;">Transaction Number</th>                    
                            <th style="font-family: unicode;">Created Date</th> 
                            <th style="font-family: unicode;" *ngIf="hardcode_delete_bool === true">Action</th>
                          </tr>
                          </thead>
                          <tbody>
                          <tr *ngFor="let game of gameTransactionErrorList">  
                            
                                   <td >
                              <input type="checkbox" name="logcheck" [checked]="isChecked"
                                  value="{{game.id}}"
                                  style="width: 1.10rem;height: 1.10rem;margin-left: 28%;"
                                  (change)="changeSelection($event,game.transaction_number)">
                          </td>
                            <td style="font-family: unicode;">{{game.name}}</td>
                            <td style="font-family: unicode;">
                              <a [routerLink]="['/user-detail', game.userId]" [queryParams]="{ type: 'gametransactioncashlist' }">{{game.phone_no}}</a>
                            </td>
                            <td style="font-family: unicode;">{{game.provider_code}}</td>
                            <td style="font-family: unicode;">{{game.amount}}</td>
                            <td style="font-family: unicode;">{{game.method}}</td>
                            <td style="font-family: unicode;">{{game.isabnormal}}</td>
                            <td style="font-family: unicode;">{{game.transaction_number}}</td>
                            <td style="font-family: unicode;">{{game.created_date_Str}}
                            </td>                      
                            <td style="font-family: unicode;" *ngIf="hardcode_delete_bool === true">
                              <a *ngIf="game.isabnormal === 'True'">
                                <i class="fas fa-trash" title="Delete" (click)="delete(game.transaction_number)" style="color:#dc3545;cursor: pointer;"></i>
                              </a>
                            </td>
                            
                          </tr>                        
                          </tbody>
                        </table>



                   <tr *ngFor="let game of gameTransactionErrorList | paginate
                    : {
                        itemsPerPage: itemsPerPage,
                        currentPage: page,
                        totalItems: totalItems
                      }" 
                      >
                  </tr> 
                  <div class = "row">
                    <div class = "col-md-6"></div>
                    <div class = "col-md-6">
                      <pagination-controls
                        class="pagi"
                        (pageChange)="gty((page = $event))"
                      >
                    </pagination-controls>
                  </div>
                  </div> 





                      </div> -->




                      <div class="card-body">
                        <table width='100%' id="{{'tblAdmin' + idIndex}}" datatable [dtTrigger]="dtTrigger"
                          [dtOptions]="dtOptions" class="table table-bordered table-hover">
                          <thead>
                            <tr>  
                              <th >
                           <input type="checkbox" name="logcheck"
                               (click)="checkuncheckall()"
                               style="width: 1.10rem;height: 1.10rem;margin-left: 28%;"
                               (change)="changeSelection($event,0)">
                          </th>                         
                            <th style="font-family: unicode;">Name</th>
                            <th style="font-family: unicode;">Phone Number</th>
                            <th style="font-family: unicode;">Provider code</th>
                            <th style="font-family: unicode;">Amount</th>
                            <th style="font-family: unicode;">Deposit/Withdraw </th>
                            <th style="font-family: unicode; width: 120px; ">Is Abnormal? </th>
                            <th style="font-family: unicode;">Transaction Number</th>                    
                            <th style="font-family: unicode;">Created Date</th> 
                            <th style="font-family: unicode;" *ngIf="hardcode_delete_bool === true">Action</th>
                          </tr>
                          </thead>
                          <tbody>
                                <tr *ngFor="let game of gameTransactionErrorList">  
                                
                                  <td >
                               <input type="checkbox" name="logcheck" [checked]="isChecked"
                                value="{{game.id}}"
                                style="width: 1.10rem;height: 1.10rem;margin-left: 28%;"
                                (change)="changeSelection($event,game.transaction_number)">
                              </td>
                                <td style="font-family: unicode;">{{game.name}}</td>
                                <td style="font-family: unicode;">
                                  <a [routerLink]="['/user-detail', game.userId]" [queryParams]="{ type: 'gametransactioncashlist' }">{{game.phone_no}}</a>
                                </td>
                                <td style="font-family: unicode;">{{game.provider_code}}</td>
                                <td style="font-family: unicode;">{{game.amount}}</td>
                                <td style="font-family: unicode;">{{game.method}}</td>
                                <td style="font-family: unicode;">{{game.isabnormal}}</td>
                                <td style="font-family: unicode;">{{game.transaction_number}}</td>
                                <td style="font-family: unicode;">{{game.created_date_Str}}
                                </td>                      
                                <td style="font-family: unicode;" *ngIf="hardcode_delete_bool === true">
                                  <a *ngIf="game.isabnormal === 'True'">
                                    <i class="fas fa-trash" title="Delete" (click)="delete(game.transaction_number)" style="color:#dc3545;cursor: pointer;"></i>
                                  </a>
                                </td>
                          
                        </tr>  
                          </tbody>
                        </table>

                                  <tr *ngFor="let game of gameTransactionErrorList | paginate
                                  : {
                                      itemsPerPage: itemsPerPage,
                                      currentPage: page,
                                      totalItems: totalItems
                                    }" 
                                    >
                                </tr> 
                              <div class = "row">
                                <div class = "col-md-6"></div>
                                <div class = "col-md-6">
                                  <pagination-controls
                                    class="pagi"
                                    (pageChange)="gty((page = $event))"
                                  ></pagination-controls>
                              </div>
                              </div>


                      </div>
          







                     
                    </div>
                  </div>

                </div>
              </form>           
            </div>
          </section>
     
        </div>
  
        <aside class="control-sidebar control-sidebar-dark">
         
        </aside>
  
        <app-footer></app-footer>

        <ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#fff" type="square-jelly-box">
          <p style="font-size: 16px; color: white">Loading...</p>
        </ngx-spinner>
  
    </div>
    



<div id="deleteData" class="modal fade">
  <div class="modal-dialog">
      <div class="modal-content">

          <div class="modal-body">
              <form>

                  <div class="row justify-content-center">
                      <div class="col-md-12">
                        <h3 class="text-center">Are you sure you want to delete?</h3>
                      </div>
                  </div>

                  
                  <div class="row" style="margin-left: 10%;margin-bottom: 3%;">
                    <div class="col-md-5">
                      <button type="button" class="btn btn-block btn-danger" (click)="btncancel()" [routerLink]="['/game-transaction-list']">Cancel</button>
                    </div>

                      <div class="col-md-5">
                          <button type="button" class="btn btn-block btn-success"  (click)="deleteByTransactionNumber()">Confirm</button>
                      </div>
                  </div>

              </form>
          </div>

      </div>
  </div>
</div>

