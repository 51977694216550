import { Component, OnInit } from '@angular/core';
@Component({
  selector: 'app-commentss-history',
  templateUrl: './commentss-history.component.html',
  styleUrls: ['./commentss-history.component.css']
})
export class CommentssHistoryComponent implements OnInit {
 constructor() { 
  }

  ngOnInit(): void {
  }


}
