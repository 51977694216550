<div class="wrapper">
    <!-- Navbar -->
    <app-header-sidebar></app-header-sidebar>

    <!-- here here here -->
    <!-- Content Wrapper. Contains page content -->
    <div class="content-wrapper">
        <!-- Content Header (Page header) -->
        <div class="content-header">
            <div class="container-fluid">
                <!--                 <div class="row mb-2">
                    <div class="col-sm-6">
                        <h1 class="m-0 text-dark">Withdraw Detail</h1>
                    </div>
                    <div class="col-sm-6">
                        <ol class="breadcrumb float-sm-right">
                            <li class="breadcrumb-item"><a [routerLink]="['/withdraw-check-list']">Withdraw List</a></li>
                            <li class="breadcrumb-item active">Withdraw Detail</li>
                        </ol>
                    </div>
                </div> -->
                <!-- /.row -->
            </div><!-- /.container-fluid -->
        </div>
        <!-- /.content-header -->

        <!-- Main content -->
        <section class="content">
            <div class="container-fluid">
                <form>
                    <div class="row">
                        <div class="col-md-12">
                            <div class="card">
                                <div class="card-header">
                                    <h3 class="card-title" style="font-weight: bold;">Agent Withdraw Detail </h3>
                                </div>
                                <!-- /.card-header -->
                                <div class="card-body">
                                    <form>
                                        <div class = "row">
                                            <label class="col-md-2">Payment</label>
                                            <div class="form-group col-md-3">
                                                <!--  <select id='paymentId'
                                                    class="form-control form-control-sm col-md-12 col-sm-4 col-xs-4"
                                                    [(ngModel)]="paymentId" [ngModelOptions]="{standalone: true}">
                                                    <option *ngFor="let payment of paymentList" value={{payment.id}}>
                                                        {{payment.name}}
                                                </select> -->
                                                <div class="row">
                                                    <div id="paymentId" class="col-md-6" (click)="kbzTopUP()" align="left">
                                                        <img *ngIf="!clickkbzpay" src="assets/image/kbzpayb&w.png"
                                                            style=" width: 80px;object-fit: cover;border: 3px solid #ffffff;border-radius: 10px;border-color: #ffffff;cursor: pointer;">
                                                        <img *ngIf="clickkbzpay" src="assets/image/kbzpay.png"
                                                            style=" width: 80px;object-fit: cover;border: 3px solid #26a9e1;border-radius: 10px;border-color: #26a9e1;cursor: pointer;">
                                                    </div>
                                                    <div id="paymentId1" class="col-md-6"  (click)="wavepayTopUP()" align="end">
                                                        <img *ngIf="!clickwavepay" src="assets/image/wavepayb&w.png" 
                                                            style=" width: 80px;object-fit: cover;border: 3px solid #ffffff;border-radius: 10px;border-color: #ffffff;cursor: pointer;">
                                                        <img *ngIf="clickwavepay" src="assets/image/wavepay.png"
                                                            style=" width: 80px;object-fit: cover;border: 3px solid #26a9e1;border-radius: 10px;border-color: #26a9e1;cursor: pointer;">
                                                    </div>
                                                </div>
                                            </div>
                                          
                                        </div>

                                        <div class="row">
                                            <label class="col-md-2"> Referral Code </label>
                                            <div class="form-group col-md-3 inner-addon left-addon">
                                                    <i class="glyphicon glyphicon-user"></i>
                                                <input id='refcode' list = "activeagentList" type="text"  (change) = "getDataByRefCodeChange()"
                                                class="form-control form-control-sm" (keydown)="getDataByRefCode($event)"  [(ngModel)]="agentFinancialTransactionDTO.refcode"
                                                [ngModelOptions]="{standalone: true}" >
                                            <datalist id="activeagentList">
                                                <option *ngFor="let agent of activeagentList" [value]="agent.referralCode">{{agent.referralCode}}</option>
                                            </datalist>
                                            </div>
                                        </div>

                                        <div class="row">
                                            <label class="col-md-2"> Agent Name </label>
                                            <div class="form-group col-md-3">
                                                <input type = "text" id="agentName" readonly [(ngModel)] = "agentFinancialTransactionDTO.agentName" 
                                                 class="form-control form-control-sm" [ngModelOptions]="{standalone: true}">                                                 
                                            </div>
                                        </div>
                                        <div class="row">
                                            <label class="col-md-2"> Account No </label>
                                            <div class="form-group col-md-3">
                                                <input id="accountNo" type="string" class="form-control form-control-sm"
                                                    [(ngModel)]="agentFinancialTransactionDTO.phoneNo"
                                                    [ngModelOptions]="{standalone: true}" 
                                                  readonly >
                                            </div>
                                        </div>

                                        <div class="row">
                                            <label class="col-md-2">Batch No</label>
                                              <div class="form-group col-md-3">
                                              <select  (change) = "getCommissionAmt()" id = "batchNo" class="form-control form-control-sm col-md-12 col-sm-4 col-xs-4" 
                                              [(ngModel)]="agentFinancialTransactionDTO.batchNo"
                                               [ngModelOptions]="{standalone: true}">
                                                <option value="Please Select" selected>Please Select</option>
                                                <option *ngFor="let batch of batchList" value = "{{batch.batch_no}}">
                                                    {{batch.batch_no}}
                                                </option>
                                             </select>
                                            </div>
                                         </div>

                                         <div class="row">
                                            <label class="col-md-2"> Amount </label>
                                            <div class="form-group col-md-3">
                                                <input id="amount" type="text" class="form-control form-control-sm"
                                                    [(ngModel)]="agentFinancialTransactionDTO.amount" readonly
                                                    [ngModelOptions]="{standalone: true}"
                                                    oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');">
                                            </div>
                                        </div>

                                        <!--<div class="row" id="status">(keypress)="numericOnly($event)"
                                            <label class="col-md-2">Status</label>
                                            <div class="form-group col-md-3">
                                                <select class="form-control form-control-sm col-md-12 col-sm-4 col-xs-4" 
                                                    [(ngModel)]="userFinancialTransactionDTO.status"
                                                    [ngModelOptions]="{standalone: true}" id="approvestatus">
                                                    <option *ngFor="let status of statusList" value={{status}}>
                                                        {{status}}
                                                </select>
                                            </div>
                                        </div> -->

                                        <div class="row" style="margin-top: 1%;">
                                            <div class="col-md-2 col-sm-6">
                                                <button type="button" id = "approvesavebutton" class="btn btn-block btn-success"
                                                    (click)="goSave()">Save</button>&nbsp;</div>
                                            <div class="col-md-2 col-sm-6">
                                                <button type="button" class="btn btn-block btn-danger"
                                                [routerLink] = "['/agent-withdraw']">Cancel</button></div>
                                        </div>
                                    </form>
                                </div>
                                <!-- /.card-body -->
                            </div>
                        </div>
                    </div>
                </form>
                <!-- /.row -->
                <!-- /.row -->
            </div>
            <!--/. container-fluid -->
        </section>
        <!-- /.content -->
    </div>

    <aside class="control-sidebar control-sidebar-dark">
        <!-- Control sidebar content goes here -->
    </aside>

    <app-footer></app-footer>

    <ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#fff" type="square-jelly-box">
        <p style="font-size: 16px; color: white">Loading...</p>
    </ngx-spinner>

</div>