<div class="wrapper">
    <!-- Navbar -->
    <app-header-sidebar></app-header-sidebar>

    <div class="content-wrapper">     
        <div class="content-header">
            <div class="container-fluid">
                <div class="row mb-2" style="border: 1px solid #ced4da;border-radius: 0.25rem;">
                    <div class="col-sm-8">
                        <h3 class="m-0 text-dark" style="font-weight: bold;padding: 3px 0px 3px 5px;">
                            GSC APIs List
                        </h3>
                    </div>
                </div>
            </div>
        </div>
 
        <section class="content">
            <div class="container-fluid">
                <form>
                    <div class="row">
                    <div class="form-group col-md-2">
                       <label>Game Provider</label>
                       <select (change) = "flagProvider()" id = "providerId" class="form-control form-control-sm col-md-12 col-sm-4 col-xs-4"
                                               [(ngModel)]="providerId" [ngModelOptions]="{standalone: true}">
                                                <option *ngFor="let provider of gameproviderList" value = "{{provider.name}}" >
                                                  {{provider.name}}
                                                </option>
                                                
                                             </select>
                      </div> 


                        <div class="form-group col-md-2 col-sm-4 col-xs-4">
                            <label></label>
                            <button id="search" type="button" class="btn btn-block btn-primary btn-sm"
                                style="margin-top: 4%;" (click)="search()">
                                Search
                            </button>
                        </div>

                        <div class="form-group col-md-2 col-sm-4 col-xs-4" *ngIf="hardcode_add_bool === true">
                            <label></label>
                            <button type="button" class="btn btn-block btn-success btn-sm" style="margin-top: 4%;"
                                [routerLink]="['/lockgscapis-detail']">
                                Add New
                            </button>
                        </div>

                        <div class="col-md-12">
                            <div class="card">
                                <div class="card-header">
                                    <h3 class="card-title" style="font-weight: bold;"> Lock APIs List </h3>
                                </div>
                         
                                <div class="card-body">
                                    <table width='100%' id="{{'tblads' + idIndex}}" datatable [dtTrigger]="dtTrigger"
                            [dtOptions]="dtOptions" class="table table-bordered table-hover">
                                        <thead>
                                            <tr>
                                                <th style="font-family: unicode;">Game Provider Name</th>
                                                <th style="font-family: unicode;">GSC API</th> 
                                                <th style="font-family: unicode;">Is Lock</th>
                                                <th style="font-family: unicode;">Created By</th>
                                                 <th style="font-family: unicode;">Created Date</th>
                                               
                                                <th style="font-family: unicode;">Updated By</th>
                                                <th style="font-family: unicode;">Updated Date</th>
                                                <th style="font-family: unicode;" *ngIf="hardcode_delete_bool === true">Delete</th>
                                                <th style="font-family: unicode;" *ngIf="hardcode_edit_bool === true">Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr *ngFor="let ads of adsList">
                                                <td style="font-family: unicode;">{{ads.providerCode}}</td>
                                        
                                                <td *ngIf="ads.apiName === 'gsWithdrawal'">GSC Withdrawal</td>
                                                <td *ngIf="ads.apiName === 'gsDeposit'">GSC Deposit</td>
                                                <td *ngIf="ads.apiName === 'launchGame'">Play Game</td>

                                                
                                                <td *ngIf="ads.isLock === 0">false</td>
                                                <td *ngIf="ads.isLock === 1">true</td>
                                            
                                                <td style="font-family: unicode;">{{ads.createdBy}}</td>
                                                <td style="font-family: unicode;">{{ads.created_date_Str}}</td>
                                                
                                                <td style="font-family: unicode;">{{ads.updatedBy}}</td>
                                                <td style="font-family: unicode;">{{ads.updated_date_Str}}</td>

                                                <td style="font-family: unicode;" class="text-center" *ngIf="hardcode_delete_bool === true">                                                
                                                    <i class="fas fa-trash" title = "Delete" (click)="delete(ads.id)" style="color:#dc3545;cursor: pointer;"></i>
                                                   
                                                </td>

                                                <td style="font-family: unicode;" *ngIf="hardcode_edit_bool === true">
                                                    <a [routerLink]="['/lockgscapis-detail', ads.id]">
                                                        <i class="fas fa-edit" style="color:#4b88eb;"></i>
                                                    </a>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                               
                            </div>
                        </div>
                    </div>
                </form>
              
            </div>
           
        </section>
     
    </div>

    <aside class="control-sidebar control-sidebar-dark">
   
    </aside>

    <app-footer></app-footer>

    <ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#fff" type="square-jelly-box">
        <p style="font-size: 16px; color: white">Loading...</p>
    </ngx-spinner>

</div>





<!-- 

<div id="deleteData" class="modal fade">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-body">
                <form>
                  
                    <div class="row justify-content-center">
                        <div class="col-md-12">
                            <h3 class="text-center">Are you sure you want to delete?</h3>
                        </div>
                    </div>
  
                    <div class="row justify-content-around" style="margin-top: 20px;">
                        <div class="col-md-5">
                            <button type="button" class="btn btn-block btn-success" (click)="deletTran()"> Ok </button>
                        </div>
  
                        <div class="col-md-5">
                            <button type="button" class="btn btn-block btn-danger" (click) = "closeTran()" [routerLink] = "['/lockgscapis']"> Cancel </button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
  </div>
   -->



   <div id="deleteData" class="modal fade">
    <div class="modal-dialog">
        <div class="modal-content">

            <div class="modal-body">
                <form>

                    <div class="row justify-content-center">
                        <div class="col-md-12">
                            <h3 class="text-center">Are you sure you want to delete?</h3>
                        </div>
                    </div>

                    
                    <div class="row" style="margin-left: 10%;margin-bottom: 3%;">
                        <div class="col-md-5">
                            <button type="button" class="btn btn-block btn-danger" (click) = "closeTran()" [routerLink] = "['/lockgscapis']"> Cancel </button>
                        </div>
                        <div class="col-md-5">
                            <button type="button" class="btn btn-block btn-success" (click)="deletTran()"> Confirm </button>
                        </div>
  
                       
                    </div>

                </form>
            </div>

        </div>
    </div>
</div>
