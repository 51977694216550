<div class="wrapper">
 
    <app-header-sidebar></app-header-sidebar>
   
    <div class="content-wrapper">
      
        <div class="content-header">
            <div class="container-fluid">
              
            </div>
        </div>
        
        <section class="content">
            <div class="container-fluid">
                <form>
                    <div class="row">
                        <div class="col-md-12">
                            <div class="card">
                                <div class="card-header">
                                    <h3 class="card-title" style="font-weight: bold;"> 2D Result Detail </h3>
                                </div>
                              
                                <div class="card-body">
                                    <form>

                                        <div class="row">
                                            <label class="col-md-2">Number</label>
                                            <div class="form-group col-md-3">
                                                <input type="text" onKeyPress="if(this.value.length==2) return false;"
                                                    class="form-control form-control-sm" [(ngModel)]="resultDTO.number"
                                                    [ngModelOptions]="{standalone: true}"
                                                    (keypress)="numericOnly($event)">
                                            </div>
                                        </div>

                                       <div class="row">
                                            <label class="col-md-2">Date</label>
                                            <div class="form-group col-md-3">
                                                <input id="singleDate" readonly class="form-control form-control-sm"
                                                    placeholder="Select Date" [ngxDatePicker]="dateInstanceSingle"
                                                    [value]="singleDate"  [(ngModel)]="tdate"  [ngModelOptions]="{standalone: true}"
                                                    (valueChange)="onChangeSingle()"
                                                    >
                                                <ngx-date-picker #dateInstanceSingle></ngx-date-picker>
                                            </div>
                                        </div>

                                        <div class="row">
                                            <label class="col-md-2">Time</label>
                                            <div class="form-group col-md-3">
                                                <select class="time form-control form-control-sm col-md-12 col-sm-4 col-xs-4" [(ngModel)]="time"
                                                [ngModelOptions]="{standalone: true}">
                                                    <option *ngFor="let objSection of sectionList" value = "{{objSection.id}}" >
                                                        {{objSection.sectionName}}
                                                    </option>
                                                </select>
                                            </div>
                                        </div>



                                        <div class="row" style="margin-top: 1%;">
                                            <div class="col-md-2 col-sm-6" *ngIf="isAddNewDisable">
                                              <button type="button" class="btn btn-block btn-success" (click)="goSave()"> Save </button> &nbsp;
                                            </div>
                                            <div class="col-md-2 col-sm-6" *ngIf="!isAddNewDisable">
                                              <button type="button" class="btn btn-block btn-secondary" (click)="delete()"> Delete </button>
                                            </div>
                                            <div class="col-md-2 col-sm-6">
                                              <button type="button" class="btn btn-block btn-danger" [routerLink]="['/resulthistory/twod']"> Cancel </button>
                                            </div>
                                        </div>
                                          




                                        <!-- <div class="row" style="margin-top: 1%;">
                                          
                                            <div class="col-md-2 col-sm-6" *ngIf="isAddNewDisable">
                                                <button type="button" class="btn btn-block btn-success"
                                                    (click)="goSave()"> Save </button> &nbsp;
                                            </div>                                        

                                            <div class="col-md-2 col-sm-6" *ngIf="!isAddNewDisable" > 
                                                <button  type="button" class="btn btn-block btn-secondary"
                                                    (click)="delete()" >Delete</button>
                                            </div>
                                            <div class="col-md-2 col-sm-6">
                                                <button type="button" class="btn btn-block btn-danger"
                                                    [routerLink]="['/resulthistory']"> Cancel </button>
                                            </div>

                                        </div> -->


                                    </form>
                                </div>
                              
                            </div>
                        </div>

                    </div>
                </form>
                
            </div>
          
        </section>
     
    </div>

    <aside class="control-sidebar control-sidebar-dark">
   
    </aside>

    <app-footer></app-footer>

    <ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#fff" type="square-jelly-box">
        <p style="font-size: 16px; color: white">Loading...</p>
    </ngx-spinner>

</div>

<div id="deleteData" class="modal fade">
    <div class="modal-dialog">
        <div class="modal-content">

            <div class="modal-body">
                <form>

                    <div class="row" style="margin-top: 5%;margin-bottom: 3%;margin-left: 9%;">
                        <div class="form-group col-md-12">
                            <h5> Are you sure want to delete  ? </h5>
                        </div>
                    </div>

                    <div class="row" style="margin-left: 10%;margin-bottom: 3%;">
                        <div class="col-md-5 col-sm-6" id="deleteBtn">
                            <button type="button" class="btn btn-block btn-success" (click)="deleteOk()"> Ok </button>
                        </div>

                        <div class="col-md-5 col-sm-6">
                            <button type="button" class="btn btn-block btn-danger" [routerLink]="['/resulthistory']"> Cancel </button>
                        </div>
                    </div>

                </form>
            </div>

        </div>
    </div>
</div>


<div id="deleteData1" class="modal fade">
    <div class="modal-dialog">
        <div class="modal-content">

            <div class="modal-body">
                <form>

                    <div class="row" style="margin-top: 5%;margin-bottom: 3%;margin-left: 9%;">
                        <div class="form-group col-md-12">
                            <h5> This Account is used by another device. 
                                Please Sign Out. Login Again. </h5>
                        </div>
                    </div>

                    <div class="row" style="margin-left: 40%;margin-bottom: 3%;">
                        <div class="col-md-5 col-sm-6" id="deleteBtn">
                            <button type="button" class="btn btn-block btn-success" (click)="OkLogout()"> Ok </button>
                        </div>
                    </div>
                </form>
            </div>

        </div>
    </div>
</div>



<div id="saveData" class="modal fade">
    <div class="modal-dialog">
        <div class="modal-content">

            <div class="modal-body">
                <form>

                    <div class="row justify-content-center">
                        <div class="col-md-12">
                            <h5 class="text-center"> Are you sure to confirm result {{resultDTO.number}} ? </h5>
                        </div>
                    </div>

                    
                    <div class="row" style="margin-left: 10%;margin-bottom: 3%;">
                        <!-- <div class="col-md-5 col-sm-6" id="deleteBtn">
                            <button type="button" class="btn btn-block btn-success" (click)="onConfirm()"> Ok </button>
                        </div>

                        <div class="col-md-5 col-sm-6">
                            <button type="button" class="btn btn-block btn-danger" [routerLink]="['/resulthistory/twod']"> Cancel </button>
                        </div> -->
                        <div class="col-md-5 col-sm-6">
                            <button type="button" class="btn btn-block btn-danger" [routerLink]="['/resulthistory/twod']"> Cancel </button>
                        </div>
                        
                        <div class="col-md-5 col-sm-6" id="deleteBtn">
                            <button type="button" class="btn btn-block btn-success" (click)="onConfirm()"> Ok </button>
                        </div>

                      


                    </div>

                </form>
            </div>

        </div>
    </div>
</div>

