<div class="wrapper">
    <app-header-sidebar></app-header-sidebar>
    
    <div class="content-wrapper">      
        <div class="content-header">
          <div class="container-fluid">
            <div class="row mb-2" style="border: 1px solid #ced4da;border-radius: 0.25rem;">
              <div class="col-sm-8">
                <h3 class="m-0 text-dark" style="font-weight: bold;padding: 3px 0px 3px 5px;"> Daily New Registration </h3>
              </div>
            </div>
          </div>
        </div>
      
        <section class="content">
          <div class="container-fluid">
            <form>
              <div class="row">

                <div class="form-group col-md-2">
                  <label>Name</label>
                  <input class="form-control form-control-sm" [(ngModel)]="name" [ngModelOptions]="{standalone: true}">
                </div>

                <div class="form-group col-md-2">
                  <label>Phone No</label>
                  <input class="form-control form-control-sm" [(ngModel)]="phoneNo" [ngModelOptions]="{standalone: true}"  (input)="onInput($event)">
                </div>

                <div class="form-group col-md-2">
                  <label>Referral Code</label>
                  <input class="form-control form-control-sm" [(ngModel)]="referralCode" [ngModelOptions]="{standalone: true}">
                </div>

               

                <div class="form-group col-md-2">
                  <label>Device Type </label>
                  <input class="form-control form-control-sm" [(ngModel)]="deviceType" [ngModelOptions]="{standalone: true}">
                </div>


                <div class="form-group col-md-2">
                  <label>Browser Type </label>
                  <input class="form-control form-control-sm" [(ngModel)]="browserType" [ngModelOptions]="{standalone: true}">
                </div>

                <div class="form-group col-md-2">
                  <label>Country Code </label>
                  <select class="form-control form-control-sm"  [(ngModel)]="countryCode" [ngModelOptions]="{standalone: true}"
                  (ngModelChange) = "onChangeTagSection()" >
                    <option value="+959" selected>+959</option>
                    <option value="+66">+66</option>
                    <option value="+86">+86</option>
                    <option value="+60">+60</option>
                  </select>
                </div>

               


                <!-- <div class="form-group col-md-2">
                  <label>App Version</label>
                      <select class="form-control form-control-sm"
                          [(ngModel)]="appVersion"
                          [ngModelOptions]="{standalone: true}" id="appVersion">
                          <option *ngFor="let appVersion of appVersionList" value={{appVersion.app_version}}>
                              {{appVersion.app_version}}
                      </select>
                  </div> -->

                <!-- <div class="form-group col-md-2" >
                  <label>Register From Date</label>
                  <input id="approveDate" readonly class="form-control form-control-sm" placeholder="Select Date"
                    [ngxDatePicker]="dateInstanceApprove" [value]="approveDate" [(ngModel)]="approvedate"
                    [ngModelOptions]="{standalone: true}"  (valueChange)="onChangeApprove()">
                  <ngx-date-picker #dateInstanceApprove></ngx-date-picker>
                </div>


                <div class="form-group col-md-2">
                  <label>Register To Date</label>
                  <input id="approvetoDate" readonly class="form-control form-control-sm" placeholder="Select Date"
                    [ngxDatePicker]="dateInstanceApprove1" [value]="approvetoDate" [(ngModel)]="approvetodate"
                    [ngModelOptions]="{standalone: true}"  (valueChange)="onChangeApproveTo()">
                  <ngx-date-picker #dateInstanceApprove1></ngx-date-picker>
                </div> -->

              
              </div>

              <div class="row">


                <div class="form-group col-md-2" >
                  <label>Register From Date</label>
                  <input id="approveDate" readonly class="form-control form-control-sm" placeholder="Select Date"
                    [ngxDatePicker]="dateInstanceApprove" [value]="approveDate" [(ngModel)]="approvedate"
                    [ngModelOptions]="{standalone: true}"  (valueChange)="onChangeApprove()">
                  <ngx-date-picker #dateInstanceApprove></ngx-date-picker>
                </div>


                <div class="form-group col-md-2">
                  <label>Register To Date</label>
                  <input id="approvetoDate" readonly class="form-control form-control-sm" placeholder="Select Date"
                    [ngxDatePicker]="dateInstanceApprove1" [value]="approvetoDate" [(ngModel)]="approvetodate"
                    [ngModelOptions]="{standalone: true}"  (valueChange)="onChangeApproveTo()">
                  <ngx-date-picker #dateInstanceApprove1></ngx-date-picker>
                </div>


                <div class="form-group col-md-2">
                <label>App Version</label>
                    <select class="form-control form-control-sm"
                        [(ngModel)]="appVersion"
                        [ngModelOptions]="{standalone: true}" id="appVersion">
                        <option *ngFor="let appVersion of appVersionList" value={{appVersion.app_version}}>
                            {{appVersion.app_version}}
                    </select>
                </div>

                <div class="form-group col-md-2">
                  <label>Status</label>
                  <select class="status form-control form-control-sm col-md-12 col-sm-4 col-xs-4" [(ngModel)]="status" [ngModelOptions]="{standalone: true}">
                    <option value="ACTIVE" selected>Active</option>
                    <option value="INACTIVE">Inactive</option>
                  </select>
                </div>


                <!-- <div class="form-group col-md-2">
                  <label for="selectedPreset">Date Range</label>
                  <select class="form-control form-control-sm col-md-12"  [(ngModel)]="selectedPreset" [ngModelOptions]="{standalone: true}" (change)="onDateRangeChange()">                 
                    <option value="today" selected>Today</option>
                    <option value="yesterday">Yesterday</option>
                    <option value="last7days">Last 7 Days</option>
                    <option value="last30days">Last 30 Days</option>
                    <option value="thisweek">This Week</option>
                    <option value="lastweek">Last Week</option>
                    <option value="thismonth">This Month</option>
                    <option value="lastmonth">Last Month</option>
                  </select>
                </div> -->
                <!-- <div class="form-group col-md-2">
                  <label for="startDate">Date Range</label>
                  <div class="input-group">
                    <input class="form-control form-control-sm col-md-16" placeholder="yyyy-mm-dd - yyyy-mm-dd" name="startDate" [(ngModel)]="startDate">                  
                  </div>
                </div> -->                
                <div class="form-group col-md-2 col-sm-2 col-xs-2">
                  <label>&nbsp;&nbsp;</label>
                  <button id="search" type="button" class="btn btn-block btn-primary btn-sm" (click)="getAllData()">Search</button>
                </div>
                
              </div>

                <div class="col-md-12">
                  <div class="card">
                    <div class="card-header">
                      <h3 class="card-title" style="font-weight: bold;"> User List </h3>
                    </div>
                    <!-- /.card-header -->
                    <div class="card-body">
                      <table width='100%' id="{{'tblUser' + idIndex}}" datatable [dtTrigger]="dtTrigger" [dtOptions]="dtOptions" class="table table-bordered table-hover">
                        <thead>
                        <tr>
                          <th style="font-family: unicode;">Name</th>
                          <th style="font-family: unicode;">Phone No</th>
                          <th style="font-family: unicode;">Referral code</th>
                          <th style="font-family: unicode;">App Version</th>
                          <th style="font-family: unicode;">Wallet</th>
                          <th style="font-family: unicode;">Total Topup</th>
                          <th style="font-family: unicode;">Win Amount</th>
                          <th style="font-family: unicode;">Total Withdraw</th>
                          <th style="font-family: unicode;">Bet Amount</th>
                          <th style="font-family: unicode;">Status</th>
                          <th style="font-family: unicode;">Register Date</th>
                          <th style="font-family: unicode;">Last Login Date/Time</th>
                          <th style="font-family: unicode;">Device Type</th>
                          <th style="font-family: unicode;">Browser Type</th>
                          <th style="font-family: unicode;">First Topup Date</th>
                          <th style="font-family: unicode;">First Topup Amount</th>
                          <th style="font-family: unicode;">Updated Date</th>
                          <th style="font-family: unicode;">Updated By</th>
                          <th style="font-family: unicode;" *ngIf="hardcode_edit_bool === true">Action</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr *ngFor="let user of userList"
                          >
                          <td style="font-family: unicode;width: 150px;">{{user.name}}</td>
                          <td style="font-family: unicode;">{{user.phone_no}}</td>
                          <td style="font-family: unicode;">{{user.referral_code}}</td>
                          <td style="font-family: unicode;">{{user.app_version}}</td>
                       
                          <td style="font-family: unicode;" class="text-right">{{user.balance_Str}}</td>
                          <td style="font-family: unicode;" class="text-right">{{user.topup_balance_Str}} </td>
                          <td style="font-family: unicode;" class="text-right">{{user.win_amount_Str}} </td>
                          <td style="font-family: unicode;" class="text-right">{{user.withdraw_balance_Str}} </td>
                          <td style="font-family: unicode;" class="text-right">{{user.bet_amount_Str}} </td>
                          <td style="font-family: unicode;">{{user.status}}</td>
                          <td style="font-family: unicode;">{{user.created_date_Str}}</td>
                          <td style="font-family: unicode;">{{user.last_used_time_str}}</td>
                          <td style="font-family: unicode;">{{user.deviceos}}</td>
                          <td style="font-family: unicode;">{{user.browsertype}}</td>
                          <td style="font-family: unicode;">{{user.first_topup_date_Str}}</td>
                          <td style="font-family: unicode;">{{user.first_topup_amt_Str}}</td>
                          <td style="font-family: unicode;">{{user.updated_date_Str}}</td>
                          <td style="font-family: unicode;">{{user.updated_by_name}}</td>
                          <td style="font-family: unicode;" *ngIf="hardcode_edit_bool === true">
                            <!-- <a [routerLink] = "['/user-detail', user.id]"><i class="fas fa-edit" style="color:#4b88eb"></i></a> -->

                            <a (click)="addStepAndNavigate(user)"><i class="fas fa-edit" style="color:#4b88eb"></i></a>


                          </td>
                        </tr>                        
                        </tbody>
                      </table>
                     
                
                        <tr *ngFor="let user of userList | paginate
                        : {
                            itemsPerPage: itemsPerPage,
                            currentPage: page,
                            totalItems: totalItems
                          }" 
                          >
                      </tr> 

                    

                    <div class = "row">
                      <div class = "col-md-6"></div>
                      <div class = "col-md-6">
                        <pagination-controls
                          class="pagi"
                          (pageChange)="gty((page = $event))"
                          
                        ></pagination-controls>
                    </div> 
              
                    </div>
                  </div>
                    <!-- /.card-body -->
                  </div>
                </div>

              <!--</div>-->

            </form>
            <!-- /.row -->

            <!-- /.row -->
          </div><!--/. container-fluid -->
        </section>
        <!-- /.content -->
      </div>




      <aside class="control-sidebar control-sidebar-dark">
        <!-- Control sidebar content goes here -->
      </aside>
    
    <app-footer></app-footer>
    <ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#fff" type="square-jelly-box">
      <p style="font-size: 16px; color: white">Loading...</p>
    </ngx-spinner>
</div>

<div id="deleteData" class="modal fade">
  <div class="modal-dialog">
      <div class="modal-content">

          <div class="modal-body">
              <form>

                  <div class="row" style="margin-top: 5%;margin-bottom: 3%;margin-left: 9%;">
                      <div class="form-group col-md-12">
                          <h5> This Account is used by another device. 
                              Please Sign Out. Login Again. </h5>
                      </div>
                  </div>

                  <div class="row" style="margin-left: 40%;margin-bottom: 3%;">
                      <div class="col-md-5 col-sm-6" id="deleteBtn">
                          <button type="button" class="btn btn-block btn-success" (click)="OkLogout()"> Ok </button>
                      </div>
                  </div>
              </form>
          </div>

      </div>
  </div>
</div>
