import { Component, OnInit, ViewChild } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,HttpErrorResponse } from '@angular/common/http';
import { DataTableDirective } from 'angular-datatables';
import { Router, NavigationEnd } from '@angular/router';

import { LocalStorageService } from 'ngx-webstorage';
import { NgxSpinnerService } from "ngx-spinner";

import { catchError, retry } from 'rxjs/operators';
import { throwError } from 'rxjs';

import { DtoService } from '../../service/dto.service';
import { FunctService } from '../../service/funct.service';
import { ToastrService } from 'ngx-toastr';
import Responsive from 'datatables.net-responsive'; /*for responsive not working event datatable */
import { FormBuilder, FormGroup } from '@angular/forms';

import { Subject } from 'rxjs';
declare var $: any;

@Component({
  selector: 'app-faq-addnew',
  templateUrl: './faq-addnew.component.html',
  styleUrls: ['./faq-addnew.component.css']
})
export class FaqAddnewbackComponent implements OnInit {

  @ViewChild(DataTableDirective)
  dtElement: DataTableDirective;
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();

  idIndex: any;
  typeId: string = '';
  phoneNo: string = '';
  adsDTO:any;
  feedBack: any;

  hardcode_edit :any;
  hardcode_add :any;
  hardcode_view: any;
  hardcode_delete:any;
  hardcode_view_bool : any;
  hardcode_add_bool : any;
  hardcode_edit_bool : any;
  hardcode_delete_bool:any;
  hardcode_double_bool:any; 
  addTranAmt:any;
  addTranDto:any;
  addfacdto:any;
  paymentList:any;
  itemsPerPage =  10;
  totalItems : any; 
  page = 1;
  answer:any;
  question:any;
  checked:any;
  guide:any;
  id:any;
  faqquestion:any;
  stepNo:any;
  imageType:any;
  description:any;
  image:any;

  imagePath2: string = '';
  message2: string = '';
  filePath2 :any;
  imgURL2:any;

  constructor(private toastr: ToastrService,private storage: LocalStorageService, private spinner: NgxSpinnerService, 
    private dto: DtoService, private http: HttpClient, private funct: FunctService, private router: Router,) {
    this.idIndex = 1;
    this.storage.store('isNotiSong', "");
   }

   ngOnInit(): void 
   {

    this.id = this.storage.retrieve('faqstepid');
    this.faqquestion = this.storage.retrieve('faqsquestion');
    this.answer = this.storage.retrieve('faqsanswer');
    this.guide = "no";
 
  
    this.router.routeReuseStrategy.shouldReuseRoute = function () {
      return false;
    };

    this.router.events.subscribe((evt) => {
      if (evt instanceof NavigationEnd) {   
        this.router.navigated = false;      
        window.scrollTo(0, 0);
      }
    });

    this.dtOptions = {
      // responsive: true,
      responsive: {
        details: {
            renderer: Responsive.renderer.listHiddenNodes()
        }
    },
      order: [],
      paging: false,
      info : true,
    }

    this.dtOptions.columnDefs = [
      { targets: [5], orderable: false }
    ];

    if (!this.storage.retrieve('loadFlag')) {
      this.storage.store('loadFlag', 'noLoad');
      setTimeout(function () {
        location.reload();
      }, 5);
    }
    else {
      this.storage.clear('loadFlag');
    }

    this.addTranDto = {    
      Name: ''
    };

    this.addfacdto = {    
      id: 0
    };
    
    //this.search();
  }

  handleError(error: HttpErrorResponse){
    if(error.status == 423)
    {
      this.spinner.hide();
      $("#deleteData").modal("show");
    }
    if(error.status == 403)
    {
      this.spinner.hide();
      var id = 'tblFeedback' + this.idIndex;
      var table = $('#' + id).DataTable();
      this.toastr.error("Limited Access.", 'Invalid!', {
        timeOut: 3000,
        positionClass: 'toast-top-right',
        });
    }
    return throwError(error);
    }
    OkLogout()
    {
      window.location.href ="./ad-login";
    } 
  
  showEditRolesModel(editId , isTyping)
  {     
      isTyping = false;
      this.dto.token = this.storage.retrieve('token');
      let headers = new HttpHeaders();
      headers = headers.set('Authorization',  this.dto.token);
      let params = new HttpParams();
      params = params.set('id', editId);
      this.http.get(this.funct.ipaddress + 'admin/GetAdminRole', { params: params, headers: headers })
      .pipe(
        catchError(this.handleError.bind(this))
       )
      .subscribe( 
        result => {
          this.dto.Response = {};
          this.dto.Response = result;
          this.adsDTO = this.dto.Response;             
          $('#browseAccountData9').modal("show");
        });     
      
  } 
  showAddTranModelNew()
    {    
      $('#browseAccountData10').modal("show");
    }
    

  Close()
  {
    $('#browseAccountData10').modal("hide"); 
  }

  
  delete(id)
  {
        this.dto.token = this.storage.retrieve('token');
        let headers = new HttpHeaders();
        headers = headers.set('Authorization',  this.dto.token);
        this.addfacdto.id = id; 
        this.http.post(this.funct.ipaddress + 'feedback/faqTypeDel', this.addfacdto,{ headers: headers })
        .pipe(
          catchError(this.handleError.bind(this))
        )
        .subscribe(
          result => {
            this.dto.Response = {};
            this.dto.Response = result;
            if (this.dto.Response.status == 'Success')
             {
              this.spinner.hide();                     
              this.toastr.success(this.dto.Response.message, 'Success!', {
                timeOut: 3000,
                positionClass: 'toast-top-right'
              })     
            }
            else {
              this.spinner.hide();
              this.toastr.error(this.dto.Response.message, 'Invalid!', {
                timeOut: 3000,
                positionClass: 'toast-top-right',
              });
            }
          });
  }
  
    doSomething() 
    {
      console.log('Yes was selected');
    }
  goSave() 
  {
    if(this.guide == null || this.guide == undefined)
    {
          this.toastr.error("Please fill guide", 'Invalid!', {
            timeOut: 3000,
            positionClass: 'toast-top-right',
          });
          return;
    }

    if(this.stepNo == null || this.stepNo == undefined)
    {
          this.toastr.error("Please fill stepNo", 'Invalid!', {
            timeOut: 3000,
            positionClass: 'toast-top-right',
          });
          return;
    }
    
    if(this.imgURL2 == null || this.imgURL2 == undefined)
    {
          this.toastr.error("Please fill imgURL", 'Invalid!', {
            timeOut: 3000,
            positionClass: 'toast-top-right',
          });
          return;
    }
    else
    {
      if (this.imgURL2 != '' || this.imgURL2 != null || this.imgURL2 != undefined) {
        if(this.imgURL2.includes('data:image/jpeg;base64,'))
        this.imgURL2 = this.imgURL2.replace("data:image/jpeg;base64,","");
        if(this.imgURL2.includes('data:image/png;base64,'))
        this.imgURL2 = this.imgURL2.replace("data:image/png;base64,","");
      }
    }
    if(this.imageType == null || this.imageType == undefined)
    {
          this.toastr.error("Please fill imageType", 'Invalid!', {
            timeOut: 3000,
            positionClass: 'toast-top-right',
          });
          return;
    }

  
    if(this.description == null || this.description == undefined)
    {
          this.toastr.error("Please fill description", 'Invalid!', {
            timeOut: 3000,
            positionClass: 'toast-top-right',
          });
          return;
    }
      this.dto.token = this.storage.retrieve('token');
      let headers = new HttpHeaders().set('Authorization', this.dto.token);
       
      const payload = {
        FaqId : this.id,
        StepNo: this.stepNo,
        image: this.imgURL2,
        imageType: this.imageType,
        describe: this.description 
     
      };  

      this.dto.faqstepid = this.id;
      this.http.post(this.funct.ipaddress + 'feedback/faqstepInsert', payload, { headers: headers })
        .pipe(
          catchError(this.handleError.bind(this))
        )
        .subscribe(
          result => {
            this.dto.Response = result;
            if (this.dto.Response === true) 
            {
                  $('#browseAccountData10').modal("hide");            
                  this.spinner.hide();
                  this.router.navigate(['/feedbackandfaqs/faqsteps-list']).then(() => 
                  {
                    this.toastr.success(this.dto.Response.message, 'Success!', {
                      timeOut: 3000,
                      positionClass: 'toast-top-right'
                    });
                  })  
            } 
            else 
            {
                  $('#browseAccountData10').modal("hide");
                  this.toastr.error("New FAQs ", 'Invalid!', {
                    timeOut: 3000,
                    positionClass: 'toast-top-right',
                  });
                  this.spinner.hide();
            }
          

          },
          error => {
            console.error(error);
            $('#browseAccountData10').modal("hide");
            this.toastr.error("Failed to save FAQ", 'Error!', {
              timeOut: 3000,
              positionClass: 'toast-top-right',
            });
            this.spinner.hide();
          }
        );
  }
  
   

  preview2(files) {
    if (files.length === 0)
      return;
    var mimeType2 = files[0].type;
    if (mimeType2.match(/image\/*/) == null) {
      this.message2 = "Only images are supported.";
      return;
    }
    var reader = new FileReader();
    this.imagePath2 = files;
    this.filePath2 = files[0];
    reader.readAsDataURL(files[0]);
    reader.onload = (_event) => {
      this.imgURL2 = reader.result;
      console.log("imgURL>>>" + JSON.stringify(this.imgURL2));
    }
  }
  
}
