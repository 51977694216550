import { Component, ComponentFactoryResolver, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,HttpErrorResponse } from '@angular/common/http';
import { Router, ActivatedRoute } from '@angular/router';

import { LocalStorageService } from 'ngx-webstorage';
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from 'ngx-toastr';

import { catchError, retry } from 'rxjs/operators';
import { from, throwError } from 'rxjs';

import { DtoService } from '../../service/dto.service';
import { FunctService } from '../../service/funct.service';
import { isNull } from '@angular/compiler/src/output/output_ast';
import { DatePipe } from '@angular/common';

declare var $: any;

@Component({
  selector: 'app-gs-transaction-tutorial-detail',
  templateUrl: './transaction-tutorial-detail.component.html',
  styleUrls: ['./transaction-tutorial-detail.component.css']
})
export class TransactionTutorialDetailDetailComponent implements OnInit {

  gameDTO: any;
  gamPassDTO: any;
  token: any;
  gameId: any;
  imagePath: any;
  imgURL: any;
  imgURL1: any;
  imgURL2:any;
  message: string = '';
  count = 0;
  filePath : any;
  displayAcc : any;
  disableAccId : any;
  isMaintenance :boolean;
  filePath1 :any;
  message1: string = '';
  imagePath1: string = '';
  fromhours:any;
  fromminutes:any;
  tohours:any;
  tominutes:any;
  imagePath2: string = '';
  message2: string = '';
  filePath2 :any;
  
  singleDate: any;
  alldate: any;
  alltoDate: any;
  alltodate:any;
  alltodayDate: any;  
  alltodaytodate: any;
  bankType:any;
  bankTypeList:any;
  transType:any;
  addTranDto:any;

  
  hardcode_view : any;
  hardcode_edit :any;
  hardcode_add :any;
  hardcode_delete: any;
  hardcode_delete_bool : any;
  hardcode_add_bool : any;
  hardcode_edit_bool : any;
  hardcode_double :any;


  constructor(private storage: LocalStorageService, private spinner: NgxSpinnerService, private toastr: ToastrService, private http: HttpClient, private dto: DtoService, private router: Router,
    private route: ActivatedRoute, private funct: FunctService, private datepipe: DatePipe)
     {     

      this.storage.store('isNotiSong', "");
    this.hardcode_view = "Tutorial_View";
    this.hardcode_delete = "Tutorial_Del";
    this.hardcode_edit = "Tutorial_Edit";
    this.hardcode_add = "Tutorial_Add";
    this.dto.menuCodes = this.storage.retrieve('menucodes');

    const filteredMenuCodes_delete = [];
    const filteredMenuCodeds_edit =[];
    const filteredMenuCodeds_add =[];
    this.dto.menuCodes.forEach(menuCode => {
      if (menuCode === this.hardcode_delete) {
        filteredMenuCodes_delete.push(menuCode);
      }   
      if (menuCode === this.hardcode_edit) {
        filteredMenuCodeds_edit.push(menuCode);
      }
      if (menuCode === this.hardcode_add) {
        filteredMenuCodeds_add.push(menuCode);
      }
    });
        if(filteredMenuCodes_delete.length != 0)
        {
            this.hardcode_delete_bool = true;
        }
        else
        {
          this.hardcode_delete_bool = false;
        }    
     
        if(filteredMenuCodeds_edit.length != 0)
        {
            this.hardcode_edit_bool = true;
        }
        else
        {
          this.hardcode_edit_bool = false;
        }

        if(filteredMenuCodeds_add.length != 0)
        {
            this.hardcode_add_bool = true;
        }
        else
        {
          this.hardcode_add_bool = false;
        }

        if(this.hardcode_delete_bool == false && this.hardcode_edit_bool == false)        
        {
            this.hardcode_double = false;
        }
        else
        {
          this.hardcode_double = true;
        }

     }
  ngOnInit(): void 
  {  
    this.getBankTypeList();

    if (!this.storage.retrieve('loadFlag')) {
      this.storage.store('loadFlag', 'noLoad');
      setTimeout(function () {
        location.reload();
      }, 1);
    }
    else {
      this.storage.clear('loadFlag');
    }
    this.gameId = this.route.snapshot.paramMap.get("id");
    if (this.gameId == null) 
    {
      $(document).ready(function () {
      });
      this.gameDTO = {
        id: 0,
        name: '',
        imageUrl: '',
        status: '',
        isMaintenance:false,
        fromTime:'',
        toTime:'',
        imageUrl2:'',
        mimageUrl:'',
        name_my :'',  
        name_th  :'',  
        name_zh : '',
        name_en : '',
        decision:'',
        notSend:''

      };

      this.addTranDto = { 
        description_en:'',
        description_th:'',
        description_zh:'',
        description_my:'',
        transType:'',
        liveLink:'',  
        bankType: '',
        orderNumber:''    

      };
    }
    else {
      $(document).ready(function () {
      });
      this.gameDTO = {
        id: 0,
        name: '',
        imageUrl: '',
        status: '',
        isMaintenance:false,
        fromTime:'',
        toTime:'',
        imageUrl2:'',
        mimageUrl:'',
        name_my :'',  
        name_th  :'',  
        name_zh : '',
        name_en : '',
        decision:'',
        notSend:''
      };
      this.addTranDto = { 
        id:0,
        description_en:'',
        description_th:'',
        description_zh:'',
        description_my:'',
        transType:'',
        liveLink:'',  
        bankType: '',
        orderNumber:''      

      };
      this.getGameById();
    }
    
  }

  handleError(error: HttpErrorResponse){
    if(error.status == 423)
    {
      this.spinner.hide();
      $("#deleteData").modal("show");
    }
    if(error.status == 403)
    {
      this.spinner.hide();
      this.toastr.error("Limited Access.", 'Invalid!', {
        timeOut: 3000,
        positionClass: 'toast-top-right',
        });
    }
    return throwError(error);
    }
    OkLogout()
    {
      window.location.href ="./ad-login";
    }   
    
    numericOnly(event: any): boolean { 
      const charCode = (event.which) ? event.which : event.keyCode;
     
      if (charCode == 101 || charCode == 69 || charCode == 45 || charCode == 43 || charCode == 46  ) {
        return false;
      }
      return true;  
    }

    onChangeTagSection() {     
      this.storage.store('BankType', this.gameDTO.bankType.toString());  
    } 
    

  
  getBankTypeList() {
    this.bankTypeList = [];
    this.spinner.show();
    this.dto.token = this.storage.retrieve('token');
    let headers = new HttpHeaders();
    headers = headers.set('Authorization',  this.dto.token);
    this.http.get(this.funct.ipaddress + 'payment/GetBankTypeLists', { headers: headers })
    .pipe(
      catchError(this.handleError.bind(this))
     )
    .subscribe(
      result => {
        this.dto.Response = {};
        this.dto.Response = result;
        this.bankTypeList = this.dto.Response;
        this.spinner.hide();
      });
  }


  goSave() {
    if (this.gameId == null) 
    {
      this.save();
    }
    else 
    {
      this.edit();
    }
  }
   save() 
   {
          if(this.gameDTO.liveLink)
          {
            this.spinner.show(); 
            this.dto.token = this.storage.retrieve('token');
            let headers = new HttpHeaders();
            headers = headers.set('Authorization', this.dto.token);  
            this.addTranDto.livelink = this.gameDTO.liveLink; 
            this.addTranDto.description_en = this.gameDTO.description_en;
            this.addTranDto.description_my = this.gameDTO.description_my;
            this.addTranDto.description_th = this.gameDTO.description_th;
            this.addTranDto.description_zh = this.gameDTO.description_zh;
            this.addTranDto.bankType = this.gameDTO.bankType;
            this.addTranDto.transType = this.gameDTO.transType;
            this.addTranDto.orderNumber = this.gameDTO.orderNumber;
            this.http.post(this.funct.ipaddress + 'TransactionTutorial/add', 		this.addTranDto,{ headers: headers })
            .pipe(
              catchError(this.handleError.bind(this))
            )
            .subscribe(
              result => {
                this.dto.Response = {};
                this.dto.Response = result;
                if (this.dto.Response.status == 'Success') {
                  this.spinner.hide();
                  this.router.navigate(['/transactiontutorial']).then(() => {
                    this.toastr.success(this.dto.Response.message, 'Success!', {
                      timeOut: 3000,
                      positionClass: 'toast-top-right'
                    });
                  })
                }
                else{               
                  this.toastr.error(this.dto.Response.message, 'Invalid!', {
                  timeOut: 3000,
                  positionClass: 'toast-top-right',
                  });
                  this.spinner.hide(); 
                  
                }
            
              }
            );
          }
          else
          {
            this.toastr.error("Please Fill Data", 'Invalid!', {
              timeOut: 3000,
              positionClass: 'toast-top-right',
              });
              this.spinner.hide(); 
          }
          
  } 
  edit()
  {
      if(this.gameDTO.liveLink)
      {
        this.spinner.show(); 
        this.dto.token = this.storage.retrieve('token');
        let headers = new HttpHeaders();
        headers = headers.set('Authorization', this.dto.token);
        this.addTranDto.id  = this.gameId;
        this.addTranDto.livelink = this.gameDTO.liveLink; 
        this.addTranDto.description_en = this.gameDTO.description_en;
        this.addTranDto.description_my = this.gameDTO.description_my;
        this.addTranDto.description_th = this.gameDTO.description_th;
        this.addTranDto.description_zh = this.gameDTO.description_zh;
        this.addTranDto.bankType = this.gameDTO.bankType;
        this.addTranDto.transType = this.gameDTO.transType;
        this.addTranDto.orderNumber = this.gameDTO.orderNumber;
        this.http.post(this.funct.ipaddress + 'TransactionTutorial/update', this.addTranDto,{ headers: headers })
        .pipe(
          catchError(this.handleError.bind(this))
        )
        .subscribe(
          result => {
            this.dto.Response = {};
            this.dto.Response = result;
            if (this.dto.Response.status == 'Success') {
              this.spinner.hide();
              this.router.navigate(['/transactiontutorial']).then(() => {
                this.toastr.success(this.dto.Response.message, 'Success!', {
                  timeOut: 3000,
                  positionClass: 'toast-top-right'
                });
              })
            }
            else{               
              this.toastr.error(this.dto.Response.message, 'Invalid!', {
              timeOut: 3000,
              positionClass: 'toast-top-right',
              });
              this.spinner.hide(); 
              
            }
        
          }
        );
      }
      else
      {
        this.toastr.error("Please Fill Data", 'Invalid!', {
          timeOut: 3000,
          positionClass: 'toast-top-right',
          });
          this.spinner.hide(); 
      }
  }


  delete()
  {
    this.dto.token = this.storage.retrieve('token');
    let headers = new HttpHeaders();
    headers = headers.set('Authorization',  this.dto.token);
    let params = new HttpParams();
    params = params.set('id', this.gameId);
    this.http.get(this.funct.ipaddress + 'TransactionTutorial/del', { params: params, headers: headers })
    .pipe(
      catchError(this.handleError.bind(this))
     )
    .subscribe(
      result => {
        this.dto.Response = {};
        this.dto.Response = result;
        if (this.dto.Response.status == 'Success') {
          this.spinner.hide();    
          this.router.navigate(['/transactiontutorial']).then(() => {
            this.toastr.success(this.dto.Response.message, 'Success!', {
              timeOut: 3000,
              positionClass: 'toast-top-right'
            });
          })              
         
        }
        else {
          this.spinner.hide();
          this.toastr.error(this.dto.Response.message, 'Invalid!', {
            timeOut: 3000,
            positionClass: 'toast-top-right',
          });
        }
      });
    }


  getGameById() {
    this.token = this.storage.retrieve('token');
    let headers = new HttpHeaders();
    headers = headers.set('Authorization',  this.token);
    let params = new HttpParams();
    params = params.set('id', this.gameId);
    this.http.get(this.funct.ipaddress + 'TransactionTutorial/detail', { params: params, headers: headers })
    .pipe(
      catchError(this.handleError.bind(this))
     )
    .subscribe( 
      result => {
        this.dto.Response = {};
        this.dto.Response = result;       
        this.gameDTO = this.dto.Response;                   
      });
  }

  
        
  }





