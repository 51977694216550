




       
    
               
                    <div class="row">


                        <div class="form-group col-md-2">
                            <label> Subject </label>
                            <input class="form-control form-control-sm" [(ngModel)]="title"
                                [ngModelOptions]="{standalone: true}">
                        </div>

                        <div class="form-group col-md-2">
                            <label> Phone No </label>
                            <input class="form-control form-control-sm" [(ngModel)]="phoneNo"
                                [ngModelOptions]="{standalone: true}">
                        </div>

                        <!-- <div class="form-group col-md-6">
                        </div> -->

                        <div class="form-group col-md-2 col-sm-4 col-xs-4">
                            <label></label>
                            <button id="search" type="button" class="btn btn-block btn-primary btn-sm"
                                style="margin-top: 4%;" (click)="search()"> Search </button>
                        </div>

                        <div class="col-md-12">
                            <div class="card">
                                <div class="card-header">
                                    <h3 class="card-title" style="font-weight: bold;"> Feedback List </h3>
                                </div>
                              
                                <div class="card-body">                                 
                                        <table width='100%' id="{{'tblFeedback' + idIndex}}" datatable [dtTrigger]="dtTrigger"
                                        [dtOptions]="dtOptions" class="table table-bordered table-hover">
                                        <thead>
                                            <tr>                                                
                                                <th style="font-family: unicode;" class="subject-column text-left">Subject</th>
                                                <th style="font-family: unicode;" class="description-column text-left">Description</th>
                                                <th style="font-family: unicode;" class="phone-column text-center">Phone No</th>
                                                <th style="font-family: unicode;" class="created-date-column text-center">Created Date</th>
                                                <th style="font-family: unicode;" class="created-by-column text-center">Created By</th>
                                                <!-- <th style="font-family: unicode;" *ngIf="hardcode_edit_bool === true">Action</th> -->
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr *ngFor="let feed of feedBack">
                                                <td style="font-family: unicode;" class="text-left">{{feed.title}}</td>
                                                <td style="font-family: unicode;" class="text-left">{{feed.description}}</td>                                              
                                                <!-- <td style="font-family: unicode;" class="text-center">
                                                    <a [routerLink] = "['/feedback-user-detail-cancel', feed.created_by]" style="text-decoration: underline; cursor: pointer;">{{feed.phone_no}}
                                                  </a></td> -->
                                                  <td style="font-family: unicode;" class="text-center">
                                                    <a [routerLink]="['/user-detail', feed.created_by]" [queryParams]="{ type: 'feedbackandfaq' }">{{feed.phone_no}}</a>
                                                  </td>
                                                <td style="font-family: unicode;" class="text-center">{{feed.created_date_Str}}</td>
                                                <td style="font-family: unicode;" class="text-center">{{feed.created_by_name}}</td>
                                                <!-- <td style="font-family: unicode;" *ngIf="hardcode_edit_bool === true">
                                                    <a [routerLink]="['/feedback-detail', feed.id]">
                                                        <i class="fas fa-edit" style="color:#4b88eb"></i>
                                                    </a>
                                                </td> -->
                                            </tr>
                                        </tbody>
                                    </table>
                                    <tr *ngFor="let feed of feedBack | paginate
                                    : {
                                        itemsPerPage: itemsPerPage,
                                        currentPage: page,
                                        totalItems: totalItems
                                      }" 
                                      >
                                  </tr> 
                                <div class = "row">
                                  <div class = "col-md-6"></div>
                                  <div class = "col-md-6">
                                    <pagination-controls
                                      class="pagi"
                                      (pageChange)="gty((page = $event))"
                                    ></pagination-controls>
                                </div>
                                </div>


                                </div>

                            </div>
                        </div>

                    </div>
            
           
   

    <aside class="control-sidebar control-sidebar-dark">
       
    </aside>

    <app-footer></app-footer>
    <ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#fff" type="square-jelly-box">
        <p style="font-size: 16px; color: white">Loading...</p>
    </ngx-spinner>


<div id="deleteData" class="modal fade">
    <div class="modal-dialog">
        <div class="modal-content">

            <div class="modal-body">
                <form>

                    <div class="row" style="margin-top: 5%;margin-bottom: 3%;margin-left: 9%;">
                        <div class="form-group col-md-12">
                            <h5> This Account is used by another device. 
                                Please Sign Out. Login Again. </h5>
                        </div>
                    </div>

                    <div class="row" style="margin-left: 40%;margin-bottom: 3%;">
                        <div class="col-md-5 col-sm-6" id="deleteBtn">
                            <button type="button" class="btn btn-block btn-success" (click)="OkLogout()"> Ok </button>
                        </div>
                    </div>
                </form>
            </div>

        </div>
    </div>
</div>  




