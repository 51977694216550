
  <!-- <app-header-sidebar></app-header-sidebar> -->

     
            <div class="row">

              <div class="form-group col-md-2">
                <label>Name</label>
                <input class="form-control form-control-sm" [(ngModel)]="name" [ngModelOptions]="{standalone: true}">
              </div>

              <div class="form-group col-md-2">
                <label>Phone No</label>
                <input class="form-control form-control-sm" [(ngModel)]="phoneNo" [ngModelOptions]="{standalone: true}"  (input)="onInput($event)">
              </div>

              <div class="form-group col-md-2">
                <label>Referral Code</label>
                <input class="form-control form-control-sm" [(ngModel)]="referralCode" [ngModelOptions]="{standalone: true}">
              </div>

          

               <div class="form-group col-md-2" >
                <label>Register From Date</label>
                <input id="approveDate" readonly class="form-control form-control-sm" placeholder="Select Date"
                  [ngxDatePicker]="dateInstanceApprove" [value]="approveDate" [(ngModel)]="approvedate"
                  [ngModelOptions]="{standalone: true}"  (valueChange)="onChangeApprove()">
                <ngx-date-picker #dateInstanceApprove></ngx-date-picker>
              </div>


              <div class="form-group col-md-2">
                <label>Register To Date</label>
                <input id="approvetoDate" readonly class="form-control form-control-sm" placeholder="Select Date"
                  [ngxDatePicker]="dateInstanceApprove1" [value]="approvetoDate" [(ngModel)]="approvetodate"
                  [ngModelOptions]="{standalone: true}"  (valueChange)="onChangeApproveTo()">
                <ngx-date-picker #dateInstanceApprove1></ngx-date-picker>
              </div>

              
               <div  class="form-group mt-4"> &nbsp;&nbsp; 
                <input _ngcontent-vtw-c204="" type="checkbox" [(ngModel)]="isShowAll" (change)="toggleCheckbox()" style="width: 1.10rem; height: 1.10rem;">
              </div>
              &nbsp; 

              <label _ngcontent-vtw-c204="" class="mt-4" style="align-items: center;">Show All </label>
              <div class="form-group col-md-2 col-sm-2 col-xs-2">
                  <label>&nbsp;</label>
                  <button id="search" type="button" class="btn btn-block btn-primary btn-sm"
                      [disabled]="isEnableSearch" (click)="search()">Search</button>
              </div>              


            </div>

            <div class="row">
              

            </div>           
            

              <div class="col-md-12">
                <div class="card">
                  <div class="card-header">
                    <h3 class="card-title" style="font-weight: bold;"> User List </h3>
                  </div>
              
                  <div class="card-body">
                    <table width='100%' id="{{'tblUser' + idIndex}}" datatable [dtTrigger]="dtTrigger" [dtOptions]="dtOptions" class="table table-bordered table-hover">
                      <thead>
                      <tr>
                        <th style="font-family: unicode;">Name</th>
                        <th style="font-family: unicode;">Phone No</th>
                        <th style="font-family: unicode;">Referral code</th>
                        <th style="font-family: unicode;">App Version</th>
                        <th style="font-family: unicode;">Wallet</th>
                        <th style="font-family: unicode;">Total Topup</th>
                        <th style="font-family: unicode;">Win Amount</th>
                        <th style="font-family: unicode;">Total Withdraw</th>
                        <th style="font-family: unicode;">Bet Amount</th>
                        <th style="font-family: unicode;">Status</th>
                        <th style="font-family: unicode;">Register Date</th>
                        <th style="font-family: unicode;">Last Login Date/Time</th>
                        <th style="font-family: unicode;">First Topup Date</th>
                        <th style="font-family: unicode;">First Topup Amount</th>
                        <th style="font-family: unicode;">Updated Date</th>
                        <th style="font-family: unicode;">Updated By</th>
                        <th style="font-family: unicode;">Action</th>
                      </tr>
                      </thead>
                      <tbody>
                      <tr *ngFor="let user of userList"
                        >
                        <td style="font-family: unicode;width: 150px;">{{user.name}}</td>                      
                        <!-- <td style="font-family: unicode;">
                          <a [routerLink] = "['/user-detail-inactive-cancle', user.id]" style="text-decoration: underline; cursor: pointer;">{{user.phone_no}}
                        </a></td> -->
                        <td style="font-family: unicode;">
                          <a [routerLink] = "['/user-detail', user.id]" [queryParams]="{ type: 'userinactiveslist' }" style="text-decoration: underline; cursor: pointer;">{{user.phone_no}}
                        </a></td>
                        <td style="font-family: unicode;">{{user.referral_code}}</td>
                        <td style="font-family: unicode;">{{user.app_version}}</td>
                    
                        <td style="font-family: unicode;" class="text-right">{{user.balance_Str}}</td>
                        <td style="font-family: unicode;" class="text-right">{{user.topup_balance_Str}} </td>
                        <td style="font-family: unicode;" class="text-right">{{user.win_amount_Str}} </td>
                        <td style="font-family: unicode;" class="text-right">{{user.withdraw_balance_Str}} </td>
                        <td style="font-family: unicode;" class="text-right">{{user.bet_amount_Str}} </td>
                        <td style="font-family: unicode;">{{user.status}}</td>
                        <td style="font-family: unicode;">{{user.created_date_Str}}</td>
                        <td style="font-family: unicode;">{{user.last_used_time_str}}</td>
                        <td style="font-family: unicode;">{{user.first_topup_date_Str}}</td>
                        <td style="font-family: unicode;">{{user.first_topup_amt_Str}}</td>
                        <td style="font-family: unicode;">{{user.updated_date_Str}}</td>
                        <td style="font-family: unicode;">{{user.updated_by_name}}</td>
                        <td style="font-family: unicode;"><a [routerLink] = "['/user-detail-inactive', user.id]"><i class="fas fa-edit" style="color:#4b88eb"></i></a>
                        </td>
                      </tr>                        
                      </tbody>
                    </table>
                   
                      <tr *ngFor="let user of userList | paginate
                      : {
                          itemsPerPage: itemsPerPage,
                          currentPage: page,
                          totalItems: totalItems
                        }" 
                        >
                    </tr> 

                  

                  <div class = "row">
                    <div class = "col-md-6"></div>
                    <div class = "col-md-6">
                      <pagination-controls
                        class="pagi"
                        (pageChange)="gty((page = $event))"
                        
                      ></pagination-controls>
                  </div> 
                
                  </div>
                </div>
                 
                </div>
              </div>


          




    <aside class="control-sidebar control-sidebar-dark">
      <!-- Control sidebar content goes here -->
    </aside>
  
  <app-footer></app-footer>
  <ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#fff" type="square-jelly-box">
    <p style="font-size: 16px; color: white">Loading...</p>
  </ngx-spinner>


<div id="deleteData" class="modal fade">
<div class="modal-dialog">
    <div class="modal-content">

        <div class="modal-body">
            <form>

                <div class="row" style="margin-top: 5%;margin-bottom: 3%;margin-left: 9%;">
                    <div class="form-group col-md-12">
                        <h5> This Account is used by another device. 
                            Please Sign Out. Login Again. </h5>
                    </div>
                </div>

                <div class="row" style="margin-left: 40%;margin-bottom: 3%;">
                    <div class="col-md-5 col-sm-6" id="deleteBtn">
                        <button type="button" class="btn btn-block btn-success" (click)="OkLogout()"> Ok </button>
                    </div>
                </div>
            </form>
        </div>

    </div>
</div>
</div>
