import { Component, OnInit, ViewChild } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, HttpErrorResponse } from '@angular/common/http';
import { DataTableDirective } from 'angular-datatables';
import { Router, NavigationEnd } from '@angular/router';
import Responsive from 'datatables.net-responsive'; /*for responsive not working event datatable */

import { LocalStorageService } from 'ngx-webstorage';
import { NgxSpinnerService } from "ngx-spinner";

import { catchError, retry } from 'rxjs/operators';
import { throwError } from 'rxjs';

import { DtoService } from '../../service/dto.service';
import { FunctService } from '../../service/funct.service';
import { ToastrService } from 'ngx-toastr';

import { Subject } from 'rxjs';
declare var $: any;

@Component({
  selector: 'app-faq-type',
  templateUrl: './faq-type.component.html',
  styleUrls: ['./faq-type.component.css']
})
export class FaqTypebackComponent implements OnInit {

  @ViewChild(DataTableDirective)
  dtElement: DataTableDirective;
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();

  idIndex: any;
  typeId: string = '';
  phoneNo: string = '';
  adsDTO: any;
  feedBack: any;

  hardcode_edit: any;
  hardcode_add: any;
  hardcode_view: any;
  hardcode_delete: any;
  hardcode_view_bool: any;
  hardcode_add_bool: any;
  hardcode_edit_bool: any;
  hardcode_delete_bool: any;
  hardcode_double_bool: any;
  hardcode_double :any;
  addTranAmt: any;
  addTranDto: any;
  addfacdto: any;
  paymentList: any;
  itemsPerPage = 10;
  totalItems: any;
  page = 1;
  faqtypeid:any;
  faqtypename:any;
  orderNumber:any;
  faqorderNumber:any;
  faqTypeId:any;
  faqTypeName:any;

  constructor(private toastr: ToastrService, private storage: LocalStorageService, private spinner: NgxSpinnerService,
    private dto: DtoService, private http: HttpClient, private funct: FunctService, private router: Router,) {
    this.idIndex = 1;
    this.storage.store('isNotiSong', "");
    this.hardcode_view = "Feedback_View";
    this.hardcode_edit = "Feedback_Edit";
    this.hardcode_add = "Feedback_Add";
    this.hardcode_delete = "Feedback_Del"; 
    this.dto.menuCodes = this.storage.retrieve('menucodes');
    const filteredMenuCodes_view = [];
    const filteredMenuCodeds_edit =[];
    const filteredMenuCodeds_add =[];
    const filteredMenuCodeds_delete =[];

    this.dto.menuCodes.forEach(menuCode => {
      if (menuCode === this.hardcode_view) {
        filteredMenuCodes_view.push(menuCode);
      }
    
      if (menuCode === this.hardcode_edit) {
        filteredMenuCodeds_edit.push(menuCode);
      }
      if (menuCode === this.hardcode_add) {
        filteredMenuCodeds_add.push(menuCode);
      }
      if (menuCode === this.hardcode_delete) {
        filteredMenuCodeds_delete.push(menuCode);
      }

    });
    if(filteredMenuCodes_view.length != 0)
    {
          this.hardcode_view_bool = true;
    }
  
    if(filteredMenuCodeds_edit.length != 0)
    {
          this.hardcode_edit_bool = true;
    }
    else
    {
      this.hardcode_edit_bool = false;
    }
    if(filteredMenuCodeds_add.length != 0)
    {
          this.hardcode_add_bool = true;
    }
    else
    {
      this.hardcode_add_bool = false;
    }
    if(filteredMenuCodeds_delete.length != 0)
    {
          this.hardcode_delete_bool = true;
    }
    else
    {
      this.hardcode_delete_bool = false;
    }

    if(this.hardcode_delete_bool == false && this.hardcode_edit_bool == false)        
    {
      this.hardcode_double = false;
    }
    else
    {
      this.hardcode_double = true;
    } 

      this.search()
    }

  ngOnInit(): void {

    this.router.routeReuseStrategy.shouldReuseRoute = function () {
      return false;
    };

    this.router.events.subscribe((evt) => {
      if (evt instanceof NavigationEnd) {
        this.router.navigated = false;
        window.scrollTo(0, 0);
      }
    });

    this.dtOptions = {
      // responsive: true,
      responsive: {
        details: {
            renderer: Responsive.renderer.listHiddenNodes()
        }
    },
      order: [],
      paging: false,
      info: true,
    }

    this.dtOptions.columnDefs = [
      { targets: [5], orderable: false }
    ];

    if (!this.storage.retrieve('loadFlag')) {
      this.storage.store('loadFlag', 'noLoad');
      setTimeout(function () {
        location.reload();
      }, 5);
    }
    else {
      this.storage.clear('loadFlag');
    }

    this.addTranDto = {
      Name: ''
    };

    this.addTranDto = {
      Name: '',
      OrderNum:0
    };

    this.adsDTO = {
      id: 0,
      name:'',
      OrderNum:0
    };

    this.addfacdto = {
      Id: 0,
      Name:''
    };
  }

  handleError(error: HttpErrorResponse) {
    if (error.status == 423) {
      this.spinner.hide();
      $("#anotherlogin").modal("show");
    }
    if (error.status == 403) {
      this.spinner.hide();
      var id = 'tblFeedback' + this.idIndex;
      var table = $('#' + id).DataTable();
      this.toastr.error("Limited Access.", 'Invalid!', {
        timeOut: 3000,
        positionClass: 'toast-top-right',
      });
    }
    return throwError(error);
  }
  OkLogout() {
    window.location.href = "./ad-login";
  }

  search() {
    this.feedBack = [];
    var id = 'tblFeedback' + this.idIndex;
    var table = $('#' + id).DataTable();
    table.destroy();
    this.idIndex = this.idIndex + 1;
    this.spinner.show();
    this.dto.token = this.storage.retrieve('token');
    let headers = new HttpHeaders();
    headers = headers.set('Authorization', this.dto.token);
    let params = new HttpParams();
    if (this.typeId == undefined || this.typeId == "") {
      this.typeId = "";
    }
    params = params.set('name', this.typeId).set("pageNumber", this.page.toString()).set("rowsOfPage", this.itemsPerPage.toString());
    this.http.get(this.funct.ipaddress + 'feedback/faqTypelist', { params: params, headers: headers })
      .pipe(
        catchError(this.handleError.bind(this))
      )
      .subscribe(
        result => {
          this.dto.Response = {};
          this.dto.Response = result.results;
          this.feedBack = this.dto.Response;
          this.totalItems = result.totalRows;
          this.typeId = "";
          this.dtTrigger.next();
          this.spinner.hide();
        }
      );
  }


  gty(page: any) {
    this.feedBack = [];
    var id = 'tblFeedback' + this.idIndex;
    var table = $('#' + id).DataTable();
    table.destroy();
    this.idIndex = this.idIndex + 1;
    this.spinner.show();
    this.dto.token = this.storage.retrieve('token');
    let headers = new HttpHeaders();
    headers = headers.set('Authorization', this.dto.token);
    let params = new HttpParams();
    if (this.typeId == undefined || this.typeId == "") {
      this.typeId = "";
    }
    params = params.set('name', this.typeId).set("pageNumber", page.toString()).set("rowsOfPage", this.itemsPerPage.toString());
    this.http.get(this.funct.ipaddress + 'feedback/faqTypelist', { params: params, headers: headers })
      .pipe(
        catchError(this.handleError.bind(this))
      )
      .subscribe(
        result => {
          this.dto.Response = {};
          this.dto.Response = result.results;
          this.feedBack = this.dto.Response;
          this.totalItems = result.totalRows;
          this.typeId = "";
          this.dtTrigger.next();
          this.spinner.hide();
        }
      );
  }
  showEditRolesModel(editId, isTyping) {
    isTyping = false;
    this.dto.token = this.storage.retrieve('token');
    let headers = new HttpHeaders();
    headers = headers.set('Authorization', this.dto.token);
    let params = new HttpParams();
    params = params.set('id', editId);
    this.http.get(this.funct.ipaddress + 'feedback/Getfaqtypeid', { params: params, headers: headers })
      .pipe(
        catchError(this.handleError.bind(this))
      )
      .subscribe(
        result => {
          this.dto.Response = {};
          this.dto.Response = result;
          this.adsDTO = this.dto.Response;
          this.faqtypename = this.adsDTO.name;
          this.faqorderNumber = this.adsDTO.ordernum;
          $('#browseAccountData9').modal("show");
          // this.dtTrigger.next();
        });

  }
  showAddTranModelNew() {
    $('#browseAccountData10').modal("show");
  }
  SaveNewRole(addAmount,orderNumber) {
    if (this.addTranAmt == null || this.addTranAmt == undefined)
     {
          this.toastr.error("Please fill data ", 'Invalid!', {
            timeOut: 3000,
            positionClass: 'toast-top-right',
          });
          $('#browseAccountData10').modal("hide");
          this.addTranAmt = null;       
          this.orderNumber = null;   
          return;
     }

     if (this.orderNumber == null || this.orderNumber == undefined)
     {
               this.toastr.error("Please fill data ", 'Invalid!',
                {
                 timeOut: 3000,
                 positionClass: 'toast-top-right',
               });
               $('#browseAccountData10').modal("hide");
               this.addTranAmt = null;       
               this.orderNumber = null;   
               return;
       }
       this.dto.token = this.storage.retrieve('token');
       let headers = new HttpHeaders();
       headers = headers.set('Authorization', this.dto.token);
       this.addTranDto.Name = addAmount;
       this.addTranDto.OrderNum= orderNumber;
       this.http.post(this.funct.ipaddress + 'feedback/faqTypeInsert', this.addTranDto, { headers: headers })
         .pipe(
           catchError(this.handleError.bind(this))
         )
         .subscribe(
           result => {
             this.dto.Response = {};
             this.dto.Response = result;
             if (this.dto.Response == true) 
             {                               
               this.toastr.success("New FAQ Type ", 'Success!', {
                timeOut: 3000,
                positionClass: 'toast-top-right'
               }); 
               $('#browseAccountData10').modal("hide");               
               this.search();
              //  this.dtTrigger.next();
               this.spinner.hide();
             }
           }
         );
       this.addTranAmt = null;       
       this.orderNumber = null;     

  }
  close() {

      this.addTranAmt = null;       
       this.orderNumber = null
    $('#browseAccountData9').modal("hide");
    $('#browseAccountData10').modal("hide");
  }
  Close()
  {
     this.addTranAmt = null;       
       this.orderNumber = null
    $('#browseAccountData9').modal("hide");
    $('#browseAccountData10').modal("hide");
  }


  //delete(id,name) {
    deletTran() {
    this.dto.token = this.storage.retrieve('token');
    let headers = new HttpHeaders();
    headers = headers.set('Authorization', this.dto.token);  
    this.addfacdto.Id = this.faqTypeId;
    this.addfacdto.Name = this.faqTypeName;
    this.http.post(this.funct.ipaddress + 'feedback/faqTypeDel', this.addfacdto, { headers: headers })
      .pipe(
        catchError(this.handleError.bind(this))
      )
      .subscribe(
        result => {
          this.dto.Response = {};
          this.dto.Response = result;
          if (this.dto.Response == true) {
            $('#deleteData').modal("hide");
            this.spinner.hide();
            this.search();
            this.toastr.success(this.dto.Response.message, 'Success!', {
              timeOut: 3000,
              positionClass: 'toast-top-right'
            })
          }
          else {
            this.spinner.hide();
            this.toastr.error(this.dto.Response.message, 'Invalid!', {
              timeOut: 3000,
              positionClass: 'toast-top-right',
            });
          }
        });
  }

  addTran(id, addTranAmt , faqorderNumber) 
  {
    this.dto.token = this.storage.retrieve('token');
    let headers = new HttpHeaders();
    headers = headers.set('Authorization', this.dto.token);  
    this.adsDTO.id = id; 
    this.adsDTO.name = addTranAmt; 
    this.adsDTO.OrderNum= faqorderNumber;
    this.http.post(this.funct.ipaddress + 'feedback/faqTypeEdit', this.adsDTO,{ headers: headers })
    .pipe(
      catchError(this.handleError.bind(this))
     )
    .subscribe(
      result => {
        this.dto.Response = {};
        this.dto.Response = result;
        if(this.dto.Response == true)
        {          
          $('#browseAccountData9').modal("hide");   
          this.toastr.success(this.dto.Response.message, 'Success!',
          {
            timeOut: 3000,
            positionClass: 'toast-top-right'
          });        
          this.spinner.hide();                 
       }           
       this.search();    
      }
    );
  }


  delete(id,name)
  {
    $("#deleteData").modal("show");
    this.faqTypeId = id;
    this.faqTypeName = name;     
  }  


  closeTran()
  {
      $('#deleteData').modal("hide");
      this.router.navigate(['/feedbackandfaq/faqtype']).then(() =>
       {
      // this.toastr.error("Faq Type ", 'Cancel!', {
      //   timeOut: 3000,
      //   positionClass: 'toast-top-right',
      // });
    });
  }



}
