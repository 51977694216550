import { Component, OnInit, ViewChild ,AfterViewInit} from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,HttpErrorResponse } from '@angular/common/http';
import { DataTableDirective } from 'angular-datatables';
import { Router, NavigationEnd } from '@angular/router';

import { LocalStorageService } from 'ngx-webstorage';
import { NgxSpinnerService } from "ngx-spinner";

import { catchError, retry } from 'rxjs/operators';
import { throwError } from 'rxjs';

import { DtoService } from '../../service/dto.service';
import { FunctService } from '../../service/funct.service';
import { ToastrService } from 'ngx-toastr';
import Responsive from 'datatables.net-responsive'; /*for responsive not working event datatable */

import { Subject } from 'rxjs';
declare var $: any;

@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.css']
})
export class FaqbackComponent implements OnInit  {

  @ViewChild(DataTableDirective)
  dtElement: DataTableDirective;
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();

  idIndex: any;
  typeId: string = '';
  phoneNo: string = '';
  adsDTO:any;
  adsFaqs :any;
  feedBack: any;

  hardcode_edit: any;
  hardcode_add: any;
  hardcode_view: any;
  hardcode_delete: any;
  hardcode_view_bool: any;
  hardcode_add_bool: any;
  hardcode_edit_bool: any;
  hardcode_delete_bool: any;
  hardcode_double_bool: any;
  hardcode_double :any;
  addTranAmt:any;
  addTranDto:any;
  addfacdto:any;
  paymentList:any;
  type:any;
  question:any;
  answer:any;
  orderNumber:any;
  has:any;
  faqType:any;
  faqquestion :any;
  itemsPerPage =  10;
  totalItems : any; 
  page = 1;

  faqsType :any;
  faqquestions:any;
  answers:any;
  hass:any;
  orderNumbers :any;
  faqid:any;

  constructor(private toastr: ToastrService,private storage: LocalStorageService, private spinner: NgxSpinnerService, 
    private dto: DtoService, private http: HttpClient, private funct: FunctService, private router: Router,)
     {
      this.storage.store('isNotiSong', "");
    this.idIndex = 1;    
    this.hardcode_view = "Feedback_View";
    this.hardcode_edit = "Feedback_Edit";
    this.hardcode_add = "Feedback_Add";
    this.hardcode_delete = "Feedback_Del"; 
    this.dto.menuCodes = this.storage.retrieve('menucodes');
    const filteredMenuCodes_view = [];
    const filteredMenuCodeds_edit =[];
    const filteredMenuCodeds_add =[];
    const filteredMenuCodeds_delete =[];

    this.dto.menuCodes.forEach(menuCode => {
      if (menuCode === this.hardcode_view) {
        filteredMenuCodes_view.push(menuCode);
      }
    
      if (menuCode === this.hardcode_edit) {
        filteredMenuCodeds_edit.push(menuCode);
      }
      if (menuCode === this.hardcode_add) {
        filteredMenuCodeds_add.push(menuCode);
      }
      if (menuCode === this.hardcode_delete) {
        filteredMenuCodeds_delete.push(menuCode);
      }

    });
    if(filteredMenuCodes_view.length != 0)
    {
          this.hardcode_view_bool = true;
    }
  
    if(filteredMenuCodeds_edit.length != 0)
    {
          this.hardcode_edit_bool = true;
    }
    else
    {
      this.hardcode_edit_bool = false;
    }
    if(filteredMenuCodeds_add.length != 0)
    {
          this.hardcode_add_bool = true;
    }
    else
    {
      this.hardcode_add_bool = false;
    }
    if(filteredMenuCodeds_delete.length != 0)
    {
          this.hardcode_delete_bool = true;
    }
    else
    {
      this.hardcode_delete_bool = false;
    }

    if(this.hardcode_delete_bool == false && this.hardcode_edit_bool == false)        
    {
      this.hardcode_double = false;
    }
    else
    {
      this.hardcode_double = true;
    } 
 

   }

   ngOnInit(): void 
   {


    this.storage.store('faqstepid', "");  
    this.router.routeReuseStrategy.shouldReuseRoute = function () {
      return false;
    };

    this.router.events.subscribe((evt) => {
      if (evt instanceof NavigationEnd) {   
        this.router.navigated = false;      
        window.scrollTo(0, 0);
      }
    });
    this.dtOptions = {
      responsive: {
        details: {
            renderer: Responsive.renderer.listHiddenNodes()
        }
    },
      order: [],
      paging: false,
      destroy : true
    }

    this.dtOptions.columnDefs = [
      { targets: [5], orderable: false}
    ];

    if (!this.storage.retrieve('loadFlag')) {
      this.storage.store('loadFlag', 'noLoad');
      setTimeout(function () {
        location.reload();
      }, 5);
    }
    else {
      this.storage.clear('loadFlag');
    }

    this.addTranDto = {    
      TypeId: 0,
      Question:'',
      Answer:'',
      HasGuide:false,
      OrderNum:0
    };

    this.adsFaqs = {   
      Id:0, 
      TypeId: 0,
      Question:'',
      Answer:'',
      HasGuide:false,
      OrderNum:0
    };   

    this.addfacdto = {    
      id: 0
    };

 
    this.getFAQType();
    //this.search();
  }


  addStepAndNavigate(feed: any): void
   { 
    localStorage.setItem('faqData', JSON.stringify({
      id: feed.id,
      question: feed.question,
      answer: feed.answer,
      hasGuide: feed.hasGuide
    }));

    this.router.navigate(['/feedbackandfaqs/faqsteps-list']);
  }
  

  handleError(error: HttpErrorResponse){
    if(error.status == 423)
    {
      this.spinner.hide();
      $("#anotherlogin").modal("show");
    }
    if(error.status == 403)
    {
      this.spinner.hide();
      var id = 'tblFeedback' + this.idIndex;
      var table = $('#' + id).DataTable();
      this.toastr.error("Limited Access.", 'Invalid!', {
        timeOut: 3000,
        positionClass: 'toast-top-right',
        });
    }
    return throwError(error);
    }
    OkLogout()
    {
      window.location.href ="./ad-login";
    } 
  
  search() {
    this.feedBack = [];
    var id = 'tblFeedback' + this.idIndex;
    var table = $('#' + id).DataTable();
    table.destroy();
    this.idIndex = this.idIndex + 1;
    this.spinner.show();
    this.dto.token = this.storage.retrieve('token');
    let headers = new HttpHeaders();
    headers = headers.set('Authorization', this.dto.token);
    let params = new HttpParams();
    if(this.type == undefined || this.type =="") 
    {
      this.type =0;
    }
    if(this.question == undefined || this.question =="") 
    {
      this.question ="";
    }
    params = params.set('typeId', this.type).set('question',this.question ).set("pageNumber",this.page.toString()).set("rowsOfPage",this.itemsPerPage.toString());  
     this.http.get(this.funct.ipaddress + 'feedback/faqlist', { params: params, headers: headers })
     .pipe(
      catchError(this.handleError.bind(this))
     )
     .subscribe( 
      result => {
        this.dto.Response = {};
        this.dto.Response = result.results;
        this.feedBack = this.dto.Response;    
        this.totalItems = result.totalRows;   
        this.dtTrigger.next();
        this.spinner.hide();
      }
    );

  }

  gty(page:any) {
    this.feedBack = [];
    var id = 'tblFeedback' + this.idIndex;
    var table = $('#' + id).DataTable();
    table.destroy();
    this.idIndex = this.idIndex + 1;
    this.spinner.show();
    this.dto.token = this.storage.retrieve('token');
    let headers = new HttpHeaders();
    headers = headers.set('Authorization', this.dto.token);
    let params = new HttpParams();
    if(this.type == undefined || this.type =="") 
    {
      this.type ="";
    }
    if(this.question == undefined || this.question =="") 
    {
      this.type ="";
    }
    params = params.set('id', this.type).set('question',this.question ).set("pageNumber",page.toString()).set("rowsOfPage",this.itemsPerPage.toString());  
     this.http.get(this.funct.ipaddress + 'feedback/faqlist', { params: params, headers: headers })
     .pipe(
      catchError(this.handleError.bind(this))
     )
     .subscribe( 
      result => {
        this.dto.Response = {};
        this.dto.Response = result.results;
        this.feedBack = this.dto.Response;
        this.totalItems = result.totalRows;       
        this.dtTrigger.next();
        this.spinner.hide();
      }
    );
  }


  
 
  showEditRolesModel(editId , isTyping)
  {     
      isTyping = false;
      this.dto.token = this.storage.retrieve('token');
      let headers = new HttpHeaders();
      headers = headers.set('Authorization',  this.dto.token);
      let params = new HttpParams();
      params = params.set('id', editId);
      this.http.get(this.funct.ipaddress + 'feedback/Getfaqid', { params: params, headers: headers })
      .pipe(
        catchError(this.handleError.bind(this))
       )
      .subscribe( 
        result => {
          this.dto.Response = {};
          this.dto.Response = result;
          this.adsDTO = this.dto.Response;  
          this.faqType = this.adsDTO.typeId;     
          this.faqquestion = this.adsDTO.question;  
          this.answer = this.adsDTO.answer;  
          this.has = this.adsDTO.hasGuide;
          this.orderNumber = this.adsDTO.ordernum;
          if(this.has =="True")
          {
              this.has = true;
          }  
          else
          {
            this.has = false;
          }
          $('#browseAccountData9').modal("show");
        });     
      
  } 
  showAddTranModelNew()
    {    
      $('#browseAccountData10').modal("show");
    }

  close()
  {
    this.faqsType =null;
    this.faqquestions=null;
    this.answers=null;
    this.hass="";
    this.orderNumbers=null;
    $('#browseAccountData10').modal("hide"); 
    $('#browseAccountData9').modal("hide"); 
  }


  saveNewRole(faqsType, faqquestions, answers, hass, orderNumbers) 
  {
    if (faqsType == null && faqsType == undefined) 
    {
        this.toastr.error("Please fill data", 'Invalid!', 
        {
          timeOut: 3000,
          positionClass: 'toast-top-right',
        });
        this.close();
        return;
    } 

    if (faqquestions == null && faqquestions == undefined) 
    {
        this.toastr.error("Please fill data", 'Invalid!', 
        {
          timeOut: 3000,
          positionClass: 'toast-top-right',
        });
        this.close();
        return;
    } 

    if (answers == null && answers == undefined) 
    {
        this.toastr.error("Please fill data", 'Invalid!', 
        {
          timeOut: 3000,
          positionClass: 'toast-top-right',
        });
        this.close();
        return;
    }
    
    if (hass == null && hass == undefined) 
    {
        this.toastr.error("Please fill data", 'Invalid!', 
        {
          timeOut: 3000,
          positionClass: 'toast-top-right',
        });
        this.close();
        return;
    } 

    if (orderNumbers == null && orderNumbers == undefined) 
    {
        this.toastr.error("Please fill data", 'Invalid!', 
        {
          timeOut: 3000,
          positionClass: 'toast-top-right',
        });
        this.close();;
        return;
    } 
   


    this.dto.token = this.storage.retrieve('token');
      let headers = new HttpHeaders().set('Authorization', this.dto.token); 
      if(hass =="true")
      {
        hass = true;
      }
      else
      {
        hass = false;
      }     
      const payload = {
        TypeId: faqsType,
        Question: faqquestions,
        Answer: answers,
        HasGuide: hass, 
        OrderNum: orderNumbers
      };  
      this.http.post(this.funct.ipaddress + 'feedback/faqInsert', payload, { headers: headers })
        .pipe(
          catchError(this.handleError.bind(this))
        )
        .subscribe(
          result => {
            this.dto.Response = result;
            if (this.dto.Response === true)
             {
              $('#browseAccountData10').modal("hide");
              this.search();
              this.toastr.success("New FAQs ", 'Success!', {
                timeOut: 3000,
                positionClass: 'toast-top-right'
              });
              this.spinner.hide();
            } else {
              $('#browseAccountData10').modal("hide");
              this.toastr.error("New FAQs ", 'Invalid!', {
                timeOut: 3000,
                positionClass: 'toast-top-right',
              });
              this.spinner.hide();
            }
          

          },
          error => {
            console.error(error);
            $('#browseAccountData10').modal("hide");
            this.toastr.error("Failed to save FAQ", 'Error!', {
              timeOut: 3000,
              positionClass: 'toast-top-right',
            });
            this.spinner.hide();
          }
        );

        this.faqsType =null;
        this.faqquestions=null;
        this.answers=null;
        this.hass="";
        this.orderNumbers=null;

  }
  

  
  deleteByTransactionNumber()
  {
        this.dto.token = this.storage.retrieve('token');
        let headers = new HttpHeaders();
        headers = headers.set('Authorization',  this.dto.token);    
        this.addfacdto.id = this.faqid; 
        this.http.post(this.funct.ipaddress + 'feedback/faqDel', this.addfacdto,{ headers: headers })
        .pipe(
          catchError(this.handleError.bind(this))
        )
        .subscribe(
          result => {
            this.dto.Response = {};
            this.dto.Response = result;
            if (this.dto.Response == true) 
            {              
              
              $('#deleteData').modal("hide");
              this.router.navigate(['/feedbackandfaq/faq-list']).then(() => {
                this.toastr.success(this.dto.Response.message, 'Success!', {
                  timeOut: 3000,
                  positionClass: 'toast -top-right'
                });
              })
             
            }
            else {
              this.spinner.hide();
              this.toastr.error(this.dto.Response.message, 'Invalid!', {
                timeOut: 3000,
                positionClass: 'toast-top-right',
              });
            }
          });
  }

  addTran(id,faqType,faqquestion,answer,has,orderNumber)
    {
      
      this.dto.token = this.storage.retrieve('token');
      let headers = new HttpHeaders();
      headers = headers.set('Authorization', this.dto.token); 
      this.adsFaqs.Id=id;
      this.adsFaqs.TypeId = faqType; 
      this.adsFaqs.Question = faqquestion; 
      this.adsFaqs.Answer = answer;
      this.adsFaqs.HasGuide = has;
      if( this.adsFaqs.HasGuide == true || this.adsFaqs.HasGuide == "true")
      {
        this.adsFaqs.HasGuide = true;
      }
      else
      {
        this.adsFaqs.HasGuide = false;
      }
      this.adsFaqs.OrderNum = orderNumber;

      this.http.post(this.funct.ipaddress + 'feedback/faqEdit', this.adsFaqs,{ headers: headers })
      .pipe(
        catchError(this.handleError.bind(this))
       )
      .subscribe(
        result => {
          this.dto.Response = {};
          this.dto.Response = result;
          if(this.dto.Response == true)
          {            

            this.router.navigate(['/feedbackandfaq/faq-list']).then(() => {
              this.toastr.success(this.dto.Response.message, 'Success!', {
                timeOut: 3000,
                positionClass: 'toast -top-right'
              });
            })
           
         }
         $('#browseAccountData9').modal("hide");   
        }
      );
    }

   

    getFAQType() {
      this.paymentList = [];
      this.dto.token = this.storage.retrieve('token');
      let headers = new HttpHeaders();
      headers = headers.set('Authorization',  this.dto.token);
    
      this.http.get(this.funct.ipaddress + 'feedback/faqTypeDroplist', {  headers: headers }).subscribe(
        result => {
          this.dto.Response = {};
          this.dto.Response = result;
          this.paymentList = this.dto.Response;
        }
      );
    }


    getBankTypeList() {
      this.paymentList = [];
      this.spinner.show();
      this.dto.token = this.storage.retrieve('token');
      let headers = new HttpHeaders();
      headers = headers.set('Authorization',  this.dto.token);
      this.http.get(this.funct.ipaddress + 'payment/GetBankTypeLists', { headers: headers })
      .pipe(
        catchError(this.handleError.bind(this))
       )
      .subscribe(
        result => {
          this.dto.Response = {};
          this.dto.Response = result;
          this.paymentList = this.dto.Response;
          this.spinner.hide();
        });
    }


    
  delete(id)
  {
    $("#deleteData").modal("show");
    this.faqid = id;       
  }  


  closeTran()
  {
      $('#deleteData').modal("hide");
      this.router.navigate(['/feedbackandfaq/faq-list']).then(() =>
       {
      // this.toastr.error("Faq ", 'Cancel!', {
      //   timeOut: 3000,
      //   positionClass: 'toast-top-right',
      // });
      
    });
  }

    
}
