import { Component, OnInit, ViewChild } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,HttpErrorResponse } from '@angular/common/http';

import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { DataTableDirective } from 'angular-datatables';
import { DatePipe, JsonPipe } from '@angular/common'

import { LocalStorageService } from 'ngx-webstorage';
import { NgxSpinnerService } from "ngx-spinner";

import { DtoService } from '../../service/dto.service';
import { FunctService } from '../../service/funct.service';

import { catchError, retry } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { ToastrService } from 'ngx-toastr'; 
import { Subject } from 'rxjs';
import Responsive from 'datatables.net-responsive';

declare var $: any;

@Component({
  selector: 'app-userforgotpassword',
  templateUrl: './bank-slip.component.html',
  styleUrls: ['./bank-slip.component.css']
})
export class BankSlipComponent implements OnInit {

  @ViewChild(DataTableDirective)
  token: any;
  dtOptions1: DataTables.Settings = {};
  dtTrigger1: Subject<any> = new Subject();
 

  dtElement3: DataTableDirective;
  dtOptions3: DataTables.Settings = {};
  dtTrigger3: Subject<any> = new Subject();

  dtElement4: DataTableDirective;
  dtOptions4: DataTables.Settings = {};
  dtTrigger4: Subject<any> = new Subject();
 
  dtElement5: DataTableDirective;
  dtOptions5: DataTables.Settings = {};
  dtTrigger5: Subject<any> = new Subject();
 
 
  approveFromDate: string = '';  
  pendingFromDate: string = '';
  deniedFromDate: string = '';
  status: string = '';
  accountNo: string = '';
  approvephoneno: string = ''; 
  pendingphoneno: string = '';
  deniedphoneno: string = '';
  inactivatedphoneno: string= '';
  
  fgpwdapproveList: any; 
  fgpwdppengingList: any;
  fgpwddeniedList: any;
  fgpinactivatedList: any;

  idIndex: any;
  idapproveIndex: any; 
  idpendingIndex: any;
  iddeniedIndex: any; 
  inactivatedIndex: any;
  approvefromdate: any; 
  pendingfromdate: any;
  deniedfromdate: any;
 

  todate : any; //add this
  approvetodate :any;
  approveToDate : any; 
  pendingtodate : any;
  pendingToDate : any; 
  deniedtodate : any;
  deniedToDate : any;
   
  approvetodaytodate : any;
  addedtodaytodate : any;
  pendingtodaytodate : any;
  deniedtodaytodate : any;
   
  alltodatechangeDate : any; //add this
  approvetodatechangeDate : any;
  pendingtodatechangeDate : any;
  deniedtodatechangeDate : any;
  addedtodatechangeDate : any;
  
  approvetodayDate: any;
  addtodayDate: any;
  pendingtodayDate: any;
  deniedtodayDate: any;
  alltodayDate: any;

  approvechangeDate: any;
  addedchangeDate: any;
  pendingchangeDate: any;
  deniedchangeDate: any;
  allchangeDate: any;

  tranNo2 : any;
  totalAmount2 : any;
  admin_name2 : any; 
  accountDetailList : any;
  total_today_reach_amt : any;
  total_my_amount :any;
  accidIndex : any;

  hardcode_edit :any;
  hardcode_add :any;
  hardcode_view: any;
  hardcode_view_bool : any; 
  hardcode_edit_bool : any;
  name:any;
  detail_type:any;

  constructor(private storage: LocalStorageService,private spinner: NgxSpinnerService, private dto: DtoService, private http: HttpClient, private router: Router,
    private route: ActivatedRoute, private funct: FunctService,private toastr: ToastrService, private datepipe: DatePipe) { 
      this.storage.store('isNotiSong', "");
    this.idIndex = 1;
    this.idapproveIndex = 1; 
    this.idpendingIndex = 1;
    this.iddeniedIndex = 1;
    this.inactivatedIndex = 1;
    this.accidIndex = 1;    
    this.hardcode_view = "UserForgotPassword_View";
    this.hardcode_edit = "UserForgotPassword_Edit";
    this.dto.menuCodes = this.storage.retrieve('menucodes');
    this.name = this.route.snapshot.paramMap.get("type");
    const filteredMenuCodes_view = [];    
    const filteredMenuCodeds_edit =[];
    this.dto.menuCodes.forEach(menuCode => {
      if (menuCode === this.hardcode_view) {
        filteredMenuCodes_view.push(menuCode);
      }     
      if (menuCode === this.hardcode_edit) {
        filteredMenuCodeds_edit.push(menuCode);
      }
    });
    if(filteredMenuCodes_view.length != 0)
    {
      this.hardcode_view_bool = true;
    }       
    if(filteredMenuCodeds_edit.length != 0)
    {
          this.hardcode_edit_bool = true;
    }     


    //amk
    //topup 
    //
    if(this.dto.isForgotPassword == "h" ) 
    {
        this.storage.store('pendingphoneno', '');     
    }
    else
    {
          if (!this.storage.retrieve('pendingphoneno')) {
            this.pendingphoneno = '';
          }
          else {
            this.pendingphoneno = this.storage.retrieve('pendingphoneno');
          }      
    }
    
    if(this.dto.isForgotPassword == "h" ) 
    {
         this.storage.store('approvephoneno', ''); 
    
    }
    else
    {
        if (!this.storage.retrieve('approvephoneno')) {
          this.approvephoneno = '';
        }
        else {
          this.approvephoneno = this.storage.retrieve('approvephoneno');
        }
    }
    if(this.dto.isForgotPassword == "h" ) 
    {
        this.storage.store('inactivatedphoneno', ''); 
    
    }
    else 
    {
          if (!this.storage.retrieve('inactivatedphoneno')) {
            this.inactivatedphoneno = '';
          }
          else {
            this.inactivatedphoneno = this.storage.retrieve('inactivatedphoneno');
          }
     
    }
    if(this.dto.isForgotPassword == "h" ) 
    {
          this.storage.store('deniedphoneno', ''); 
    
    }
    else
    {
          if (!this.storage.retrieve('deniedphoneno')) {
            this.deniedphoneno = '';
          }
          else {
            this.deniedphoneno = this.storage.retrieve('deniedphoneno');
          }
    }


  if(this.detail_type =="detail")
  {
        this.pendingfromdate = new Date(this.storage.retrieve('PendingTagForgotPwdFromDate'));
        this.pendingtodayDate = this.datepipe.transform(this.pendingfromdate, 'MMM dd, yyyy');
  }
  else
  {
        if(this.name =="d")
        {
            if (!this.storage.retrieve('PendingTagForgotPwdFromDate')) 
            {
              this.pendingfromdate = new Date(); 
              this.pendingtodayDate = this.datepipe.transform(this.pendingfromdate, 'MMM dd, yyyy');   
            }
            else
             {
              this.pendingfromdate = new Date(this.storage.retrieve('PendingTagForgotPwdFromDate'));
              this.pendingtodayDate = this.datepipe.transform(this.pendingfromdate, 'MMM dd, yyyy');
            }
          
        }
        else
        {
        if(this.dto.isForgotPassword == "h" || this.dto.isForgotPassword == undefined) 
        {
          this.pendingfromdate = new Date(); 
          this.pendingtodayDate = this.datepipe.transform(this.pendingfromdate, 'MMM dd, yyyy'); 
      
        }
        else
        {
                  
            if (!this.storage.retrieve('PendingTagForgotPwdFromDate')) {
              this.pendingfromdate = new Date(); 
              this.pendingtodayDate = this.datepipe.transform(this.pendingfromdate, 'MMM dd, yyyy');   
            }
            else {
              this.pendingfromdate = new Date(this.storage.retrieve('PendingTagForgotPwdFromDate'));
              this.pendingtodayDate = this.datepipe.transform(this.pendingfromdate, 'MMM dd, yyyy');
            }
        }
      }

  }
      
    
    
    if(this.dto.isForgotPassword == "h" ) 
    {
      this.approvefromdate = new Date(); 
      this.approvetodayDate = this.datepipe.transform(this.approvefromdate, 'MMM dd, yyyy');  
    }
    else
    {
      if (!this.storage.retrieve('ApproveTagForgotPwdFromDate'))
       {
        this.approvefromdate = new Date(); 
        this.approvetodayDate = this.datepipe.transform(this.approvefromdate, 'MMM dd, yyyy');   
      }
      else {
        this.approvefromdate = new Date(this.storage.retrieve('ApproveTagForgotPwdFromDate'));
        this.approvetodayDate = this.datepipe.transform(this.approvefromdate, 'MMM dd, yyyy');
      }
    }
    
    if(this.dto.isForgotPassword == "h" ) 
    {
      this.deniedfromdate = new Date(); 
      this.deniedtodayDate = this.datepipe.transform(this.deniedfromdate, 'MMM dd, yyyy'); 
    }
    else
    {
      if (!this.storage.retrieve('DeniedTagForgotPwdFromDate')) {
        this.deniedfromdate = new Date(); 
        this.deniedtodayDate = this.datepipe.transform(this.deniedfromdate, 'MMM dd, yyyy'); 
      }
      else {
        this.deniedfromdate = new Date(this.storage.retrieve('DeniedTagForgotPwdFromDate'));
        this.deniedtodayDate = this.datepipe.transform(this.deniedfromdate, 'MMM dd, yyyy');
      }
    }
    

    if(this.dto.isForgotPassword == "h" || this.dto.isForgotPassword == undefined) 
    {
        this.pendingtodate = new Date();  
        this.pendingtodaytodate = this.datepipe.transform(this.pendingtodate, 'MMM dd, yyyy');
        this.pendingphoneno = '';
    }
    else
    {
      if (!this.storage.retrieve('PendingTagForgotPwdToDate')) {
        this.pendingtodate = new Date();  
        this.pendingtodaytodate = this.datepipe.transform(this.pendingtodate, 'MMM dd, yyyy');
      }
      else {
        this.pendingtodate = new Date(this.storage.retrieve('PendingTagForgotPwdToDate'));
        this.pendingtodaytodate = this.datepipe.transform(this.pendingtodate, 'MMM dd, yyyy');

      }
    }
    
    if(this.dto.isForgotPassword == "h" ) 
    {
      this.approvetodate = new Date();  
      this.approvetodaytodate = this.datepipe.transform(this.approvetodate, 'MMM dd, yyyy');
    }
    else
    {
      if (!this.storage.retrieve('ApproveTagForgotPwdToDate')) {
        this.approvetodate = new Date();  
        this.approvetodaytodate = this.datepipe.transform(this.approvetodate, 'MMM dd, yyyy');
      }
      else {
        this.approvetodate = new Date(this.storage.retrieve('ApproveTagForgotPwdToDate'));
        this.approvetodaytodate = this.datepipe.transform(this.approvetodate, 'MMM dd, yyyy');
      }
    }
   

    if(this.dto.isForgotPassword == "h" ) 
    {
      this.deniedtodate = new Date();   
      this.deniedtodaytodate = this.datepipe.transform(this.deniedtodate, 'MMM dd, yyyy');
    }
    else
    {
      
      if (!this.storage.retrieve('DeniedTagForgotPwdToDate')) {
        this.deniedtodate = new Date();   
        this.deniedtodaytodate = this.datepipe.transform(this.deniedtodate, 'MMM dd, yyyy');
      }
      else {
        this.deniedtodate = new Date(this.storage.retrieve('DeniedTagForgotPwdToDate'));
        this.deniedtodaytodate = this.datepipe.transform(this.deniedtodate, 'MMM dd, yyyy');
      }
    }   

    //this.pendingSearch('btnsearch');//amk   
  
  }
  ngOnInit(): void {
    this.inactivatedphoneno =""; //amk added

    this.route.queryParams.subscribe(params => 
    {
      this.detail_type = params['type'];     
    });

    if(this.detail_type == "detail") 
    {
      if (!this.storage.retrieve('PendingTagForgotPwdToDate'))
       {
        this.pendingtodate = new Date();  
        this.pendingtodaytodate = this.datepipe.transform(this.pendingtodate, 'MMM dd, yyyy');
      }
      else {
        this.pendingtodate = new Date(this.storage.retrieve('PendingTagForgotPwdToDate'));
        this.pendingtodaytodate = this.datepipe.transform(this.pendingtodate, 'MMM dd, yyyy');

      }

      if (!this.storage.retrieve('PendingTagForgotPwdFromDate')) 
            {
              this.pendingfromdate = new Date(); 
              this.pendingtodayDate = this.datepipe.transform(this.pendingfromdate, 'MMM dd, yyyy');   
            }
            else
             {
              this.pendingfromdate = new Date(this.storage.retrieve('PendingTagForgotPwdFromDate'));
              this.pendingtodayDate = this.datepipe.transform(this.pendingfromdate, 'MMM dd, yyyy');
            }
      
    }
   
  
    // if(this.detail_type != undefined)
    // {
    //      return;
    // }

    this.router.routeReuseStrategy.shouldReuseRoute = function () {
      return false;
    };

    this.router.events.subscribe((evt) => {
      if (evt instanceof NavigationEnd) {
     
        this.router.navigated = false;
        
        window.scrollTo(0, 0);
      }
    });
  
    this.dtOptions1 = {
      // responsive: true,
      responsive: {
        details: {
            renderer: Responsive.renderer.listHiddenNodes()
        }
    },
      order: []
    }
  
    this.dtOptions3 = {
      // responsive: true,
      responsive: {
        details: {
            renderer: Responsive.renderer.listHiddenNodes()
        }
    },
      order: []
    } 
  

    this.dtOptions4 = {
      // responsive: true,
      responsive: {
        details: {
            renderer: Responsive.renderer.listHiddenNodes()
        }
    },
      order: []
    }
    this.dtOptions5 = {
      // responsive: true,
      responsive: {
        details: {
            renderer: Responsive.renderer.listHiddenNodes()
        }
    },
      order: []
    }
   
    if (!this.storage.retrieve('loadFlag')) {
      this.storage.store('loadFlag', 'noLoad');
      setTimeout(function () {
        location.reload();
      }, 5);
    }
    else {
      this.storage.clear('loadFlag');
    } 

    if (!this.storage.retrieve('ForgotPwdTag')) {
      this.storage.store('ForgotPwdTag', "PENDING");      
      this.pendingSearch('tabsearch');
      $('#pending-tab').tab('show');
    }
    else {
      if(this.storage.retrieve('ForgotPwdTag') == "PENDING"){        
        this.pendingSearch('tabsearch');
        $('#pending-tab').tab('show');
      }
      else if(this.storage.retrieve('ForgotPwdTag') == "APPROVE"){        
        this.approveSearch('tabsearch');
        $('#approve-tab').tab('show');
      }
      else if(this.storage.retrieve('ForgotPwdTag') == "DENIED"){        
        this.rejectedSearch('tabsearch');
        $('#rejected-tab').tab('show');
      }
      else if(this.storage.retrieve('ForgotPwdTag') == "INACTIVED"){        
        this.inActivedUserSearch();
        $('#inactived-tab').tab('show');
      }
    }  
  }
 
  onChangeApproveFromDate() {
    // $(document).ready(function () {
    //   this.approveFromDate = $("#approvefromdate").val();
    // });
    this.storage.store('ApproveTagForgotPwdFromDate', this.approvefromdate.toString());
  }

  onChangeApproveToDate()
  {
    // $(document).ready(function () {
    //   this.approvetoDate = $("#approvetodate").val();
    // });
    this.storage.store('ApproveTagForgotPwdToDate', this.approvetodate.toString());
  }
 

  onChangePendingFromDate() {
    // $(document).ready(function () {
    //   this.pendingFromDate = $("#pendingfromdate").val();
    // });
    this.storage.store('PendingTagForgotPwdFromDate', this.pendingfromdate.toString());
  }

  onChangePendingToDate()
  {
    // $(document).ready(function () {
    //   this.pendingtoDate = $("#pendingtodate").val();
    // });
    this.storage.store('PendingTagForgotPwdToDate', this.pendingtodate.toString());
  }

  onChangeDeniedFromDate() {
    // $(document).ready(function () {
    //   this.deniedFromDate = $("#deniedfromdate").val();
    // });
    this.storage.store('DeniedTagForgotPwdFromDate', this.deniedfromdate.toString());
  }

  onChangeDeniedToDate()
  {
    // $(document).ready(function () {
    //   this.deniedtoDate = $("#deniedtodate").val();
    // }); 
    this.storage.store('DeniedTagForgotPwdToDate', this.deniedtodate.toString());
  }

  handleError(error: HttpErrorResponse){
    if(error.status == 423)
    {
      this.spinner.hide();
      $("#deleteData").modal("show");
    }
    if(error.status == 500)
    {
      this.spinner.hide();
      this.toastr.error('Please check connection', 'Invalid!', {
        timeOut: 3000,
        positionClass: 'toast-top-right',
      });
    }
    if(error.status == 403)
    {
      this.spinner.hide(); 

      var id2 = 'tblapproveTopup' + this.idapproveIndex;
      var table2 = $('#' + id2).DataTable();
  
      var id4 = 'tblpendingTopup' + this.idpendingIndex;
      var table4 = $('#' + id4).DataTable();

      var id5 = 'tbldeniedTopup' + this.iddeniedIndex;
      var table5 = $('#' + id5).DataTable();

      var id6  = 'tblinactivate' + this.inactivatedIndex;
      var table6 =  $('#' + id6).DataTable();
      this.toastr.error("Limited Access.", 'Invalid!', {
        timeOut: 3000,
        positionClass: 'toast-top-right',
        });
    }
    return throwError(error);
    }
    OkLogout()
    {
      window.location.href ="./ad-login";
    } 
  
  approveSearch(searchkey) {    
    this.inactivatedphoneno =""; //amk added
    this.storage.store('ForgotPwdTag', "APPROVE");
    this.fgpwdapproveList = [];
    var id = 'tblapprove' + this.idapproveIndex;
    var table = $('#' + id).DataTable();
    this.dto.token = this.storage.retrieve('token');    
    table.destroy();
    this.idapproveIndex = this.idapproveIndex + 1;
    this.spinner.show();
    this.dto.token = this.storage.retrieve('token');
    this.approveFromDate = $("#approveFromDate").val();
    this.approveToDate = $("#approveToDate").val();
    if(searchkey =="btnsearch")
    {
          if (this.approveFromDate == '' || this.approveFromDate == undefined) {
            this.approvechangeDate = this.approvetodayDate;
          }
          else {
            this.approvechangeDate = this.approveFromDate;
          }
      
          if (this.approveToDate == '' || this.approveToDate == undefined) {
            this.approvetodatechangeDate = this.approvetodaytodate;
          }
          else {
            this.approvetodatechangeDate = this.approveToDate;
          }
          if( this.approvephoneno == undefined ||  this.approvephoneno == null)
          {
            this.approvephoneno = '';
            this.storage.store('approvephoneno', "");  
          }
    }
    else
    {
          if (this.approveFromDate == '' || this.approveFromDate == undefined) {
            this.approvechangeDate = this.approvetodayDate;
          }
          else {

            this.approvefromdate = new Date();
            this.approvechangeDate = this.datepipe.transform(this.approvefromdate, 'MMM dd, yyyy'); 
            this.approvephoneno = '';
          }
      
          if (this.approveToDate == '' || this.approveToDate == undefined) {        
            this.approvetodatechangeDate = this.approvetodaytodate  
          }
          else {
          
            this.approvetodate = new Date();
            this.approvetodatechangeDate = this.datepipe.transform(this.approvetodate, 'MMM dd, yyyy');  
          }
          
    }
    let headers = new HttpHeaders();
    headers = headers.set('Authorization', this.dto.token);
    let params = new HttpParams();    
    if(this.approvephoneno == null)
    {   
      this.approvephoneno="";
    }
    params = params.set('requestStatus','0')
    .set('fromDate', this.approvechangeDate)
    .set("toDate",this.approvetodatechangeDate) 
    .set('phone_no', this.approvephoneno)
    .set('status','APPROVED');
    this.http.get(this.funct.ipaddress + 'user/getbankslip-by-params', { params: params, headers: headers })
     .pipe(
      catchError(this.handleError.bind(this))
     )
    .subscribe(
      result => {
        this.dto.Response = {};
        this.dto.Response = result;
        this.fgpwdapproveList = this.dto.Response;
        this.approveFromDate = '';
        this.dtTrigger1.next();
        this.spinner.hide();
      });
  } 
  pendingSearch(searchkey){    
    this.inactivatedphoneno =""; //amk added
    this.storage.store('ForgotPwdTag', "PENDING");
    this.fgpwdppengingList = [];
    var id = 'tblpending' + this.idpendingIndex; 
    var table = $('#' + id).DataTable(); 
    table.destroy();
    this.idpendingIndex = this.idpendingIndex + 1;
    this.spinner.show();
    this.dto.token = this.storage.retrieve('token');
    this.pendingFromDate = $("#pendingFromDate").val();
    this.pendingToDate = $("#pendingToDate").val();   

    if(searchkey =='btnsearch')
    {          
      if (this.pendingFromDate == '' || this.pendingFromDate == undefined)
       {
              this.pendingchangeDate = this.pendingtodayDate;
              }
              else {
    
                this.pendingchangeDate = this.pendingFromDate;
              }
    
              if (this.pendingToDate == '' || this.pendingToDate == undefined) {
    
                this.pendingtodatechangeDate = this.pendingtodaytodate;
              }
              else {
    
                this.pendingtodatechangeDate = this.pendingToDate;
              }
              if( this.pendingphoneno == undefined ||  this.pendingphoneno == null)
              {
                this.pendingphoneno = '';
                this.storage.store('pendingphoneno', "");  
              }
    }
    else
   {          
     
        if (this.pendingFromDate == '' || this.pendingFromDate == undefined)
         {    
            this.pendingchangeDate = this.pendingtodayDate;
     
         }
          else 
          {       
            this.pendingfromdate = new Date();
            this.pendingchangeDate = this.datepipe.transform(this.pendingfromdate, 'MMM dd, yyyy'); 
            this.pendingphoneno = '';
          }

          if (this.pendingToDate == '' || this.pendingToDate == undefined) 
          {        
            this.pendingtodatechangeDate = this.pendingtodaytodate; 
          }
          else {        
            this.pendingtodate = new Date();
            this.pendingtodatechangeDate = this.datepipe.transform(this.pendingtodate, 'MMM dd, yyyy'); 
          }
          
    }

    let headers = new HttpHeaders();
    headers = headers.set('Authorization', this.dto.token);
    let params = new HttpParams();
    if(this.pendingphoneno == null)
    {   
      this.pendingphoneno="";
    }
    params = params.set('requestStatus','0')
    .set('fromDate', this.pendingchangeDate)
    .set("toDate",this.pendingtodatechangeDate) 
    .set('phone_no', this.pendingphoneno)
    .set('status','PENDING');//ADDED
    this.http.get(this.funct.ipaddress + 'user/getbankslip-by-params', { params: params, headers: headers })
     .pipe(
      catchError(this.handleError.bind(this))
     )
    .subscribe(
      result => {
        this.dto.Response = {};
        this.dto.Response = result;
        this.fgpwdppengingList = this.dto.Response;
        this.pendingFromDate = '';
        this.dtTrigger3.next();
        this.spinner.hide();
      });
  }

  inActivedUserSearch()
  {  
    this.fgpinactivatedList = [];
    this.storage.store('ForgotPwdTag', "INACTIVED");
    //this.fgpinactivatedList = [];
    var id = 'tblinactivate' + this.inactivatedIndex;
    var table = $('#' + id).DataTable();
    table.destroy();
    this.inactivatedIndex = this.inactivatedIndex + 1;
    this.spinner.show();
    this.dto.token = this.storage.retrieve('token');    
    let headers = new HttpHeaders();   
    if(this.inactivatedphoneno == null)
    {   
      this.inactivatedphoneno="";
    }
    headers = headers.set('Authorization',  this.dto.token);
    let params = new HttpParams();
    params = params.set('phoneno', this.inactivatedphoneno);
    this.http.get(this.funct.ipaddress + 'user/getForgetPasswordInActiveUser',{headers: headers, params: params})
    .pipe(
      catchError(this.handleError.bind(this))
     )
    .subscribe(
      result => {
        this.dto.Response = {};
        this.dto.Response = result;
        this.fgpinactivatedList = this.dto.Response;        
        this.dtTrigger5.next();
        this.spinner.hide();
      });

      this.spinner.hide();
     
  }
  reActive(phone_no){
    this.spinner.show();
    this.dto.token = this.storage.retrieve('token');
    let headers = new HttpHeaders();
    headers = headers.set('Authorization', this.dto.token);
    const formData = new FormData();
    formData.append("phone_no",phone_no);
    this.http.post(this.funct.ipaddress + 'user/reActiveForgetPasswordUser',formData,{ headers: headers })
    .pipe(
      catchError(this.handleError.bind(this))
     )
    .subscribe(
      result => {
        this.dto.Response = {};
        this.dto.Response = result;  
        if (this.dto.Response.status == 'Success'){
          this.spinner.hide();
          this.router.navigate(['/bank-slip-list']).then(() => {
            this.toastr.success(this.dto.Response.message, 'Success!', {
              timeOut: 3000,
              positionClass: 'toast-top-right'
            });
          });
        } 
      }
    );
  }
  
  rejectedSearch(searchkey){
    
    this.inactivatedphoneno =""; //amk added
    this.storage.store('ForgotPwdTag', "DENIED");
    this.fgpwddeniedList = [];
    var id = 'tbldenied' + this.iddeniedIndex;
    var table = $('#' + id).DataTable();
    table.destroy();
    this.iddeniedIndex = this.iddeniedIndex + 1;
    this.spinner.show();
    this.dto.token = this.storage.retrieve('token');
    this.deniedFromDate = $("#deniedFromDate").val();
    this.deniedToDate = $("#deniedToDate").val();
    if(searchkey =="btnsearch")
    {
          if (this.deniedFromDate == '' || this.deniedFromDate == undefined) {

            this.deniedchangeDate = this.deniedtodayDate;
          }
          else {
            this.deniedchangeDate = this.deniedFromDate;
          }
      
          if (this.deniedToDate == '' || this.deniedToDate == undefined) {
            this.deniedtodatechangeDate = this.deniedtodaytodate;
          }
          else {
            this.deniedtodatechangeDate = this.deniedToDate;
          }
          if( this.deniedphoneno == undefined ||  this.deniedphoneno == null)
          {
            this.deniedphoneno = '';
            this.storage.store('deniedphoneno', "");  
          }
    }
    else
    {

          if (this.deniedFromDate == '' || this.deniedFromDate == undefined) {
            this.deniedchangeDate = this.deniedtodayDate;
          }
          else {
            this.deniedfromdate = new Date();
            this.deniedchangeDate = this.datepipe.transform(this.deniedfromdate, 'MMM dd, yyyy'); 
            this.deniedphoneno = '';
          }

          if (this.deniedToDate == '' || this.deniedToDate == undefined) {
            this.deniedtodatechangeDate = this.deniedtodaytodate;
          }
          else {
            this.deniedtodate = new Date();
            this.deniedtodatechangeDate = this.datepipe.transform(this.deniedtodate, 'MMM dd, yyyy');           
          }
       

          // if (this.deniedFromDate == '' || this.deniedFromDate == undefined) {
          //   this.deniedtodayDate = this.deniedtodayDate;
          // }
          // else {

          //   this.deniedfromdate = new Date();
          //   this.deniedchangeDate = this.datepipe.transform(this.deniedfromdate, 'MMM dd, yyyy');  
          // }
      
          // if (this.deniedToDate == '' || this.deniedToDate == undefined) {        
          //   this.deniedtodatechangeDate = this.deniedtodaytodate;  
          // }
          // else {
          
          //   this.deniedtodate = new Date();
          //   this.deniedtodatechangeDate = this.datepipe.transform(this.deniedtodate, 'MMM dd, yyyy');  
          // }

    }
    let headers = new HttpHeaders();
    headers = headers.set('Authorization',  this.dto.token); 
    let params = new HttpParams();
    //this.deniedphoneno = this.storage.retrieve('deniedphoneno');
    if(this.deniedphoneno == null)
    {   
      this.deniedphoneno="";
    }
    params = params.set('requestStatus','0')
    .set('fromDate', this.deniedchangeDate)
    .set("toDate",this.deniedtodatechangeDate) 
    .set('phone_no', this.deniedphoneno)
    .set('status','DENIED');
    this.http.get(this.funct.ipaddress + 'user/getbankslip-by-params', { params: params, headers: headers })
     .pipe(
      catchError(this.handleError.bind(this))
     )
    .subscribe(
      result => {
        this.dto.Response = {};
        this.dto.Response = result;
        this.fgpwddeniedList = this.dto.Response;
        this.deniedFromDate = '';
        this.dtTrigger4.next();
        this.spinner.hide();
      });
  }

 filterMyanmarText(inputText) {
    const myanmarCharactersRegex = /[\u1000-\u109F\uAA60-\uAA7F\uA9E0-\uA9FF]+/g;
    const myanmarTextMatches = inputText.match(myanmarCharactersRegex);
  
    if (myanmarTextMatches !== null) {
      const myanmarText = myanmarTextMatches.join('');
      return myanmarText;
    }
  
    return '';
  }
  
  numericOnly(event): boolean { 
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode == 101 || charCode == 69 || charCode == 45 || charCode == 43 || charCode == 46) {
      return false;
    }
    return true;
  }  
  ngOnDestroy(): void {
    this.dtTrigger3.unsubscribe();
    this.dtTrigger1.unsubscribe();
    this.dtTrigger4.unsubscribe();
    this.dtTrigger5.unsubscribe(); 
  }
  onChangePhoneNo() {     
    this.storage.store('pendingphoneno', this.pendingphoneno.toString());  
  }
  onChangeTagSection() {     
    this.storage.store('approvephoneno', this.approvephoneno.toString());  
  }
  
  onChangeInactivePhoneNo() {     
    this.storage.store('inactivatedphoneno', this.inactivatedphoneno.toString());  
  }
  
  onChangeRejectedPhoneNo() {     
    this.storage.store('deniedphoneno', this.deniedphoneno.toString());  
  }




   
  addStepAndNavigate(fgp: any): void
  { 
   localStorage.setItem('fgpData', JSON.stringify({
     id: fgp.id,
     name:fgp.name,
     phone_no:fgp.phone_no,
     status:fgp.status,
     created_date:fgp.created_date,
     updated_date:fgp.updated_date,
     updated_by:fgp.updated_by
   
   }));

   this.router.navigate(['/bank-slip-detail', fgp.id]);
 }


}
