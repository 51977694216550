import { Component, OnInit } from '@angular/core';
@Component({
  selector: 'app-config-history',
  templateUrl: './config-history.component.html',
  styleUrls: ['./config-history.component.css']
})
export class ConfigHistoryComponent implements OnInit {
 constructor() { 
  }

  ngOnInit(): void {
  }


}
