
      

                    <div class="row">

                

                        <div class="col-md-12">
                            <div class="card">
                                <div class="card-header">
                                    <h3 class="card-title" style="font-weight: bold;"> Ban List </h3>
                                </div>
                               
                                <div class="card-body">
                                    <table width='100%' id="{{'tblComment' + idIndex}}" datatable [dtTrigger]="dtTrigger"
                                        [dtOptions]="dtOptions" class="table table-bordered table-hover">
                                        <thead>
                                            <tr>
                                                <th style="font-family: unicode;">Name</th>
                                                <th style="font-family: unicode;">Phone No</th>
                                                <th style="font-family: unicode;">Wallet</th>
                                                <th style="font-family: unicode;">Total Topup</th>
                                                <th style="font-family: unicode;">Total Withdrawal</th>
                                                <th style="font-family: unicode;">Total Bet </th>
                                                <th style="font-family: unicode;">Total Win</th>
                                                <th style="font-family: unicode;">First Topup Amount </th>
                                                <th style="font-family: unicode;">First Topup Date</th>
                                                <th style="font-family: unicode;" *ngIf="hardcode_delete_bool === true">Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>

                                            <tr *ngFor="let ip of banlist">
                                                <td style="font-family: unicode;" class="text-center">{{ip.name}}</td>
                                                <td style="font-family: unicode;" class="text-center">{{ip.phone_no}}</td>
                                                <td style="font-family: unicode;" class="text-center">{{ip.topup_balance_Str
                                                }}</td>
                                                <td style="font-family: unicode;" class="text-center">{{ip.topup_balance_Str
                                                }}</td>
                                                <td style="font-family: unicode;" class="text-center">{{ip.withdraw_balance_Str
                                                }}</td>
                                                <td style="font-family: unicode;" class="text-center">{{ip.bet_amount_Str
                                                }}</td>
                                                <td style="font-family: unicode;" class="text-center">{{ip.win_amount_Str
                                                }}</td>
                                                <td style="font-family: unicode;" class="text-center">{{ip.first_topup_amt_Str}}</td>
                                                <td style="font-family: unicode;" class="text-center">{{ip.first_topup_date_Str}}</td>
                                                <td style="font-family: unicode;" class="text-center" *ngIf="hardcode_delete_bool ===true">
                                                  <a  >
                                                  <i class="fas fa-trash" title = "Delete" (click)="deleteUserId(ip.id)" style="color:#dc3545;cursor: pointer;"></i>
                                                  </a>                                   
                                              </td>
                                           
                                        </tbody>
                                    </table>
                                </div>
                                
                            </div>
                        </div>

                    </div>
           
   

    <ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#fff" type="square-jelly-box">
        <p style="font-size: 16px; color: white">Loading...</p>
    </ngx-spinner>



<div id="deleteData" class="modal fade">
    <div class="modal-dialog">
        <div class="modal-content">

            <div class="modal-body">
                <form>

                    <div class="row" style="margin-top: 5%;margin-bottom: 3%;margin-left: 9%;">
                        <div class="form-group col-md-12">
                            <h5> This Account is used by another device. 
                                Please Sign Out. Login Again. </h5>
                        </div>
                    </div>

                    <div class="row" style="margin-left: 40%;margin-bottom: 3%;">
                        <div class="col-md-5 col-sm-6" id="deleteBtn">
                            <button type="button" class="btn btn-block btn-success" (click)="OkLogout()"> Ok </button>
                        </div>
                    </div>
                </form>
            </div>

        </div>
    </div>
</div>




<div id="deleteDataForBan" class="modal fade">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-body">
                <form>
                  
                    <div class="row justify-content-center">
                        <div class="col-md-12">
                            <h3 class="text-center">Are you sure you want to delete?</h3>
                        </div>
                    </div>
  
                    <div class="row" style="margin-left: 10%;margin-bottom: 3%;">                   
  
                        <div class="col-md-5">
                          <button type="button" class="btn btn-block btn-danger" (click)="closeTran()"
                          [routerLink]="['/game-transaction-list']"> Cancel </button>
                        </div>
  
                        <div class="col-md-5">
                          <button type="button" class="btn btn-block btn-success" (click)="deletTran()"> Confirm </button>
                        </div>
  
                    </div>
                </form>
            </div>
        </div>
    </div>
  </div>
