<div class="wrapper">
  <app-header-sidebar></app-header-sidebar>
  <div class="content-wrapper">
    <div class="content-header">
      <div class="container-fluid">
        <div class="row mb-2" style="border: 1px solid #ced4da;border-radius: 0.25rem;">
          <div class="col-sm-6">
            <h3 class="m-0 text-dark" style="font-weight: bold;padding: 3px 0px 3px 5px;"> Bank Account List </h3>
          </div>
        </div>
      </div>
    </div>
   
    <section class="content">
      <div class="container-fluid">
        <form>
          <div class="row">

            <div class="col-md-12">
            
              <ul class="nav nav-tabs nav-pills">
                <li (click)="search('tabsearch')">
                  <a id="all-tab" data-toggle="tab" href="#all"> ALL </a>
                </li>
                <li (click)="search_pending('tabsearch')">
                  <a id="pending-tab" data-toggle="tab" href="#pending"> KBZ PAY </a>
                </li>
                <li (click)="search_wavepay('tabsearch')">
                  <a id="denied-tab" data-toggle="tab" href="#wavepay"> WAVE PAY </a>
                </li>
                <li (click)="search_cbpay('tabsearch')">                
                  <a id="checked-tab" data-toggle="tab" href="#cbpay"> CB PAY </a>
                </li>
                <li (click)="search_ayapay('tabsearch')">
                  <a id="approve-tab" data-toggle="tab" href="#ayapay"> AYA PAY </a>
                </li>              
          
              </ul>

          
              <div class="tab-content clearfix">

                <div class="tab-pane" id="all">
                  <div class="row">

                    <div class="form-group col-md-2">
                      <label> Account No </label>
                      <input class="form-control form-control-sm" [(ngModel)]="accountNo" [ngModelOptions]="{standalone: true}">
                    </div>
        
                    <div class="form-group col-md-2" style="display: none;">
                      <label> Payment Type </label>
                      <select id="parent_id" class="form-control form-control-sm col-md-12 col-sm-4 col-xs-4" [(ngModel)]="type"
                        [ngModelOptions]="{standalone: true}">
                        <option *ngFor="let payment of paymentList" value="{{payment.id}}">
                          {{payment.payment_type}}
                        </option>
                      </select>
                    </div>
        
                    <div class="form-group col-md-2">
                      <label> Status </label>
                      <select class="status form-control form-control-sm col-md-12 col-sm-4 col-xs-4" [(ngModel)]="status"
                        [ngModelOptions]="{standalone: true}">
                        <option value="ACTIVE" selected>Active</option>
                        <option value="INACTIVE">Inactive</option>
                      </select>
                    </div>
        
                    <div class="form-group col-md-2">
                      <label> Bank Type </label>             
        
                      <select class="status form-control form-control-sm col-md-12 col-sm-4 col-xs-4" [(ngModel)]="BankType"
                        [ngModelOptions]="{standalone: true}">
                        <option value="null" selected>All</option>
                        <option *ngFor="let bt of bankTypeList" [value]="bt.id">
                          {{ type === 'TOPUP' ? bt.bankType : bt.name }}
                        </option>
                      </select>
        
                    </div>
        
                    <div class="form-group col-md-2">
                      <label> Type </label>
                      <select class="status form-control form-control-sm col-md-12 col-sm-4 col-xs-4" [(ngModel)]="type"
                        [ngModelOptions]="{standalone: true}" (ngModelChange)="onChangeType()">
                        <option value="TOPUP" selected>TOPUP</option>
                        <option value="WITHDRAWAL">WITHDRAWAL</option>
                      </select>
                    </div>
        
                    <div class="form-group col-md-2 col-sm-4 col-xs-4">
                      <label></label>
                      <button id="search" type="button" class="btn btn-block btn-primary btn-sm" style="margin-top: 4%;" (click)="search('btnsearch')">                    
                        Search
                      </button>
                    </div>
        
                    <div class="form-group col-md-2 col-sm-4 col-xs-4" *ngIf="hardcode_add_bool === true">
                      <label></label>
                      <button type="button" class="btn btn-block btn-success btn-sm" style="margin-top: 4%;"
                        [routerLink]="['/payment-info-detail-new']">
                        Add New
                      </button>
                    </div>

                    <div class="form-group col-md-2 col-sm-4 col-xs-4">
                      <label></label>
                      <button type="button" class="btn btn-block btn-success btn-sm" style="margin-top: 4%;"
                      (click)="getHolder()">
                        All Bank
                      </button>
                    </div>                 
                    

                    <div class="col-md-12" *ngIf="type === 'TOPUP'">
                      <div class="card">
                        <div class="card-header">
                          <div class="row">
                            <h3 class="card-title font-weight-bold">Bank Account All List</h3>
                            <div class="form-group col-md-2 col-sm-4 col-xs-4 ml-auto">
                              <button id="confirm" type="button" class="btn btn-block btn-sm"
                                style="background-color: #FFC107; color: white; margin-bottom: 12%;" (click)="readNotiData('ALL')">
                                <!-- (click)="readNotiData()" -->
                                <span> Config = {{config_all}} {{persentage}}</span>
                              </button>
                            </div>
                          </div>
                        </div>
                    
                        <div class="card-body">
                          <table width='100%' id="tblpayment" datatable [dtTrigger]="dtTrigger" [dtOptions]="dtOptions"
                            class="table table-bordered table-hover">
                            <thead>
                              <tr>
                                <th style="font-family: unicode;">Bank Type</th>
        
                                <th style="font-family: unicode;">Type</th>
        
                                <th style="font-family: unicode;">Account Number</th>
        
                                <th style="font-family: unicode;">Code Number</th>
        
                                <th style="font-family: unicode;">Account Name</th>
        
                                <th style="font-family: unicode;">Minimum Amount</th>
        
                                <th style="font-family: unicode;">Maximum Amount</th>
        
                                <th style="font-family: unicode;">Max-Account Count</th>
        
                                <th style="font-family: unicode;">Limited Amount</th>
        
                                <th style="font-family: unicode;">Today Reach Amount</th>
        
                                <th style="font-family: unicode;">Status</th>
        
                                <th style="font-family: unicode;">Created Date</th>
                                <th style="font-family: unicode;">Updated Date</th>
                                <th style="font-family: unicode;">Updated By</th>
        
                                <th style="font-family: unicode;" *ngIf="hardcode_edit_bool === true">Action</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr *ngFor="let payment of paymentList">
        
                                <td style="font-family: unicode;">{{payment.payment_type}}</td>
        
                                <td style="font-family: unicode;">{{payment.type}}</td>
        
                                <td style="font-family: unicode;">{{payment.account_no}}</td>
        
                                <td style="font-family: unicode;">{{payment.code_number}}</td>
        
                                <td style="font-family: unicode;">{{payment.account_name}}</td>
        
                                <td style="font-family: unicode;">{{payment.min_amount_Str}}</td>
                                <td style="font-family: unicode;">{{payment.max_amount_Str}}</td>
                                <td style="font-family: unicode;">{{payment.maxAccountCount}}</td>
                                <td style="font-family: unicode;">{{payment.limited_amt_Str}}</td>
        
                                <td style="font-family: unicode;">{{payment.today_reach_amt_Str}}</td>
        
                                <td style="font-family: unicode;">{{payment.status}}</td>
        
                              
                                <td style="font-family: unicode;">{{payment.created_date_Str}}</td>
        
                                <td style="font-family: unicode;">{{payment.updated_date_Str}}</td>
                                <td style="font-family: unicode;">{{payment.updated_by_name}}</td>
        
        
                                <td style="font-family: unicode;" *ngIf="hardcode_edit_bool === true">
        
                                  <a (click)="addStepAndNavigate(payment)">
                                    <i class="fas fa-edit" style="color:#4b88eb;"></i>
                                  </a>
                                  &nbsp;&nbsp;
                                  <a herf="#" id="viewDetailMe" title="View Detail">
                                    <i class="far fa-eye" style="color:#4b88eb; cursor: pointer;"
                                      (click)="goModal(payment.id, payment.account_no, payment.payment_type,payment.code_number, payment.account_name)">
                                    </i>
        
                                  </a>
        
                                </td>
        
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>

                    <!-- TOPUP -->


                    <div class="col-md-12" *ngIf="type === 'WITHDRAWAL'">
                      <div class="card">
                        <div class="card-header">
                          <h3 class="card-title" style="font-weight: bold;"> Bank Account All List </h3>
                        </div>
        
                        <div class="card-body">
                          <table width='100%' id="tblpayment" datatable [dtTrigger]="dtTrigger" [dtOptions]="dtOptions"
                            class="table table-bordered table-hover">
                            <thead>
                              <tr>
                                <th style="font-family: unicode;">Bank Type</th>        
                                <th style="font-family: unicode;">Type</th>        
                                <th style="font-family: unicode;">Account Number</th>      
        
                                <th style="font-family: unicode;">Code Number</th>
                                <th style="font-family: unicode;">Account Name</th>
                                <th style="font-family: unicode;">Today Withdrawal Amount</th>
        
                                <th style="font-family: unicode;">Status</th>
        
                                <th style="font-family: unicode;">Created Date</th>
                                <th style="font-family: unicode;">Updated Date</th>
                                <th style="font-family: unicode;">Updated By</th>
        
                                <th style="font-family: unicode;" *ngIf="hardcode_edit_bool === true">Action</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr *ngFor="let payment of paymentList">
        
                                <td style="font-family: unicode;">{{payment.payment_type}}</td>
        
                                <td style="font-family: unicode;">{{payment.type}}</td>
        
                                <td style="font-family: unicode;">{{payment.account_no}}</td>
        
                                <td style="font-family: unicode;">{{payment.code_number}}</td>
                                <td style="font-family: unicode;">{{payment.account_name}}</td>
        
                                <td style="font-family: unicode;">{{payment.today_withdrawal_amt_Str}}</td>
                                <td style="font-family: unicode;">{{payment.status}}</td>      
        
        
                                <td style="font-family: unicode;">{{payment.created_date_Str}}</td>
        
                                <td style="font-family: unicode;">{{payment.updated_date_Str}}</td>
                                <td style="font-family: unicode;">{{payment.updated_by_name}}</td>
        
        
                                <td style="font-family: unicode;" *ngIf="hardcode_edit_bool === true">
        
                                  <a (click)="addStepAndNavigate(payment)">
                                    <i class="fas fa-edit" style="color:#4b88eb;"></i>
                                  </a>
                                  &nbsp;&nbsp;
                                  <a herf="#" id="viewDetailMe" title="View Detail">
                                    <i class="far fa-eye" style="color:#4b88eb; cursor: pointer;"
                                      (click)="goModal(payment.id, payment.account_no, payment.payment_type,payment.code_number, payment.account_name)">
                                    </i>
        
                                  </a>
        
                                </td>
        
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>                   

                    <!-- WITHDRAWAL -->                    

                  </div>
                </div>



                <div class="tab-pane" id="pending">
                  <div class="row">
                    <div class="form-group col-md-2">
                      <label> Account No </label>
                      <input class="form-control form-control-sm" [(ngModel)]="accountNo_pending" [ngModelOptions]="{standalone: true}">
                    </div>
        
                    <div class="form-group col-md-2" style="display: none;">
                      <label> Payment Type </label>
                      <select id="parent_id_pending" class="form-control form-control-sm col-md-12 col-sm-4 col-xs-4" [(ngModel)]="type_pending"
                        [ngModelOptions]="{standalone: true}">
                        <option *ngFor="let payment of paymentList" value="{{payment.id}}">
                          {{payment.payment_type}}
                        </option>
                      </select>
                    </div>
        
                    <div class="form-group col-md-2">
                      <label> Status </label>
                      <select class="status form-control form-control-sm col-md-12 col-sm-4 col-xs-4" [(ngModel)]="status_pending"
                        [ngModelOptions]="{standalone: true}">
                        <option value="ACTIVE" selected>Active</option>
                        <option value="INACTIVE">Inactive</option>
                      </select>
                    </div>       
                    
        
                    <div class="form-group col-md-2">
                      <label> Type </label>
                      <select class="status form-control form-control-sm col-md-12 col-sm-4 col-xs-4" [(ngModel)]="type_pending"
                        [ngModelOptions]="{standalone: true}" (ngModelChange)="onChangeType_pending()">
                        <option value="TOPUP" selected>TOPUP</option>
                        <option value="WITHDRAWAL">WITHDRAWAL</option>
                      </select>
                    </div>
        
                    <div class="form-group col-md-2 col-sm-4 col-xs-4">
                      <label></label>
                      <button id="search" type="button" class="btn btn-block btn-primary btn-sm" style="margin-top: 4%;" (click)="search_pending('btnsearch')">                    
                        Search
                      </button>
                    </div>
        
                    <div class="form-group col-md-2 col-sm-4 col-xs-4" *ngIf="hardcode_add_bool === true">
                      <label></label>
                      <button type="button" class="btn btn-block btn-success btn-sm" style="margin-top: 4%;"
                        [routerLink]="['/payment-info-detail-new']">
                        Add New
                      </button>
                    </div>


                    <div class="form-group col-md-2 col-sm-4 col-xs-4">
                      <label></label>
                      <button type="button" class="btn btn-block btn-success btn-sm" style="margin-top: 4%;"
                      (click)="getHolder_pending()">
                        All Bank
                      </button>
                    </div>

                    <!-- <div class="form-group col-md-2 col-sm-4 col-xs-4">
                      <label></label>
                      <button id="confirm" type="button" class="btn btn-block btn-sm"
                                style="background-color: #FFC107; color: white; margin-bottom: 12%; margin-right: 50%; margin-top: 3%;" (click)="readNotiData()">
                                <span> Config = {{config}} {{persentage}}</span>
                              </button>                     
                    </div> -->

                    
                    

                    <div class="col-md-12" *ngIf="type_pending === 'TOPUP'">
                      <div class="card">
                        <div class="card-header">                      


                          <div class="row">
                            <h3 class="card-title font-weight-bold">Bank Account KBZ PAY List</h3>
                            <div class="form-group col-md-2 col-sm-4 col-xs-4 ml-auto">
                              <button id="confirm" type="button" class="btn btn-block btn-sm"
                                style="background-color: #FFC107; color: white; margin-bottom: 12%;" (click)="readNotiData('KBZ Pay')">
                                <span> Config = {{config_kbz}} {{persentage}}</span>
                              </button>
                            </div>
                          </div>

                        </div>
                    
                        <div class="card-body">
                          <table width='100%' id="tblpayment1" datatable [dtTrigger]="dtTrigger1" [dtOptions]="dtOptions1"
                            class="table table-bordered table-hover">
                            <thead>
                              <tr>
                                <th style="font-family: unicode;">Bank Type</th>
        
                                <th style="font-family: unicode;">Type</th>
        
                                <th style="font-family: unicode;">Account Number</th>
        
                                <th style="font-family: unicode;">Code Number</th>
        
                                <th style="font-family: unicode;">Account Name</th>
        
                                <th style="font-family: unicode;">Minimum Amount</th>
        
                                <th style="font-family: unicode;">Maximum Amount</th>
        
                                <th style="font-family: unicode;">Max-Account Count</th>
        
                                <th style="font-family: unicode;">Limited Amount</th>
        
                                <th style="font-family: unicode;">Today Reach Amount</th>
        
                                <th style="font-family: unicode;">Status</th>
        
                                <th style="font-family: unicode;">Created Date</th>
                                <th style="font-family: unicode;">Updated Date</th>
                                <th style="font-family: unicode;">Updated By</th>
        
                                <th style="font-family: unicode;" *ngIf="hardcode_edit_bool === true">Action</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr *ngFor="let payment of paymentList1">
        
                                <td style="font-family: unicode;">{{payment.payment_type}}</td>
        
                                <td style="font-family: unicode;">{{payment.type}}</td>
        
                                <td style="font-family: unicode;">{{payment.account_no}}</td>
        
                                <td style="font-family: unicode;">{{payment.code_number}}</td>
        
                                <td style="font-family: unicode;">{{payment.account_name}}</td>
        
                                <td style="font-family: unicode;">{{payment.min_amount_Str}}</td>
                                <td style="font-family: unicode;">{{payment.max_amount_Str}}</td>
                                <td style="font-family: unicode;">{{payment.maxAccountCount}}</td>
                                <td style="font-family: unicode;">{{payment.limited_amt_Str}}</td>
        
                                <td style="font-family: unicode;">{{payment.today_reach_amt_Str}}</td>
        
                                <td style="font-family: unicode;">{{payment.status}}</td>
        
                              
                                <td style="font-family: unicode;">{{payment.created_date_Str}}</td>
        
                                <td style="font-family: unicode;">{{payment.updated_date_Str}}</td>
                                <td style="font-family: unicode;">{{payment.updated_by_name}}</td>
        
        
                                <td style="font-family: unicode;" *ngIf="hardcode_edit_bool === true">
        
                                  <a (click)="addStepAndNavigate(payment)">
                                    <i class="fas fa-edit" style="color:#4b88eb;"></i>
                                  </a>
                                  &nbsp;&nbsp;
                                  <a herf="#" id="viewDetailMe" title="View Detail">
                                    <i class="far fa-eye" style="color:#4b88eb; cursor: pointer;"
                                      (click)="goModal(payment.id, payment.account_no, payment.payment_type,payment.code_number, payment.account_name)">
                                    </i>
        
                                  </a>
        
                                </td>
        
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>

                    <!-- TOPUP -->


                    <div class="col-md-12" *ngIf="type_pending === 'WITHDRAWAL'">
                      <div class="card">
                        <div class="card-header">
                          <h3 class="card-title" style="font-weight: bold;">Bank Account KBZ PAY List </h3>
                        </div>
        
                        <div class="card-body">
                          <table width='100%' id="tblpayment1" datatable [dtTrigger]="dtTrigger1" [dtOptions]="dtOptions1"
                            class="table table-bordered table-hover">
                            <thead>
                              <tr>
                                <th style="font-family: unicode;">Bank Type</th>        
                                <th style="font-family: unicode;">Type</th>        
                                <th style="font-family: unicode;">Account Number</th>      
        
                                <th style="font-family: unicode;">Code Number</th>
                                <th style="font-family: unicode;">Account Name</th>
                                <th style="font-family: unicode;">Today Withdrawal Amount</th>
        
                                <th style="font-family: unicode;">Status</th>
        
                                <th style="font-family: unicode;">Created Date</th>
                                <th style="font-family: unicode;">Updated Date</th>
                                <th style="font-family: unicode;">Updated By</th>
        
                                <th style="font-family: unicode;" *ngIf="hardcode_edit_bool === true">Action</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr *ngFor="let payment of paymentList1">
        
                                <td style="font-family: unicode;">{{payment.payment_type}}</td>
        
                                <td style="font-family: unicode;">{{payment.type}}</td>
        
                                <td style="font-family: unicode;">{{payment.account_no}}</td>
        
                                <td style="font-family: unicode;">{{payment.code_number}}</td>
                                <td style="font-family: unicode;">{{payment.account_name}}</td>
        
                                <td style="font-family: unicode;">{{payment.today_withdrawal_amt_Str}}</td>
                                <td style="font-family: unicode;">{{payment.status}}</td>      
        
        
                                <td style="font-family: unicode;">{{payment.created_date_Str}}</td>
        
                                <td style="font-family: unicode;">{{payment.updated_date_Str}}</td>
                                <td style="font-family: unicode;">{{payment.updated_by_name}}</td>
        
        
                                <td style="font-family: unicode;" *ngIf="hardcode_edit_bool === true">
        
                                  <a (click)="addStepAndNavigate(payment)">
                                    <i class="fas fa-edit" style="color:#4b88eb;"></i>
                                  </a>
                                  &nbsp;&nbsp;
                                  <a herf="#" id="viewDetailMe" title="View Detail">
                                    <i class="far fa-eye" style="color:#4b88eb; cursor: pointer;"
                                      (click)="goModal(payment.id, payment.account_no, payment.payment_type,payment.code_number, payment.account_name)">
                                    </i>
        
                                  </a>
        
                                </td>
        
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>                 

                    <!-- WITHDRAWAL -->                    

                  </div>
                  
                </div>


              <!-- AMK FOR CB PAY -->

              <div class="tab-pane" id="cbpay">
                <div class="row">
                  <div class="form-group col-md-2">
                    <label> Account No </label>
                    <input class="form-control form-control-sm" [(ngModel)]="accountNo_cbpay" [ngModelOptions]="{standalone: true}">
                  </div>
      
                  <div class="form-group col-md-2" style="display: none;">
                    <label> Payment Type </label>
                    <select id="parent_id_cbpay" class="form-control form-control-sm col-md-12 col-sm-4 col-xs-4" [(ngModel)]="type_cbpay"
                      [ngModelOptions]="{standalone: true}">
                      <option *ngFor="let payment of paymentList" value="{{payment.id}}">
                        {{payment.payment_type}}
                      </option>
                    </select>
                  </div>
      
                  <div class="form-group col-md-2">
                    <label> Status </label>
                    <select class="status form-control form-control-sm col-md-12 col-sm-4 col-xs-4" [(ngModel)]="status_cbpay"
                      [ngModelOptions]="{standalone: true}">
                      <option value="ACTIVE" selected>Active</option>
                      <option value="INACTIVE">Inactive</option>
                    </select>
                  </div>       
                  
      
              

                  <div class="form-group col-md-2">
                    <label> Type </label>
                    <select class="status form-control form-control-sm col-md-12 col-sm-4 col-xs-4" [(ngModel)]="type_cbpay"
                      [ngModelOptions]="{standalone: true}" (ngModelChange)="onChangeType_cbpay()">
                      <option value="TOPUP" selected>TOPUP</option>
                      <option value="WITHDRAWAL">WITHDRAWAL</option>
                    </select>
                  </div>
      
                  <div class="form-group col-md-2 col-sm-4 col-xs-4">
                    <label></label>
                    <button id="search" type="button" class="btn btn-block btn-primary btn-sm" style="margin-top: 4%;" (click)="search_cbpay('btnsearch')">                    
                      Search
                    </button>
                  </div>
      
                  <div class="form-group col-md-2 col-sm-4 col-xs-4" *ngIf="hardcode_add_bool === true">
                    <label></label>
                    <button type="button" class="btn btn-block btn-success btn-sm" style="margin-top: 4%;"
                      [routerLink]="['/payment-info-detail-new']">
                      Add New
                    </button>
                  </div>

                  <div class="form-group col-md-2 col-sm-4 col-xs-4">
                    <label></label>
                    <button type="button" class="btn btn-block btn-success btn-sm" style="margin-top: 4%;"
                    (click)="getHolder_cbpay()">
                      All Bank
                    </button>
                  </div>


                  <div class="col-md-12" *ngIf="type_cbpay === 'TOPUP'">
                    <div class="card">
                      <div class="card-header">
                        <div class="row">
                          <h3 class="card-title font-weight-bold">Bank Account CB PAY List</h3>
                          <div class="form-group col-md-2 col-sm-4 col-xs-4 ml-auto">
                            <button id="confirm" type="button" class="btn btn-block btn-sm"
                              style="background-color: #FFC107; color: white; margin-bottom: 12%; margin-right: 50%; margin-top: 3%;" (click)="readNotiData('CB Pay')">
                              <span> Config = {{config_cb}} {{persentage}}</span>
                            </button>
                          </div>
                        </div>
                      </div>
                  
                      <div class="card-body">
                        <table width='100%' id="tblpayment" datatable [dtTrigger]="dtTrigger" [dtOptions]="dtOptions"
                          class="table table-bordered table-hover">
                          <thead>
                            <tr>
                              <th style="font-family: unicode;">Bank Type</th>
      
                              <th style="font-family: unicode;">Type</th>
      
                              <th style="font-family: unicode;">Account Number</th>
      
                              <th style="font-family: unicode;">Code Number</th>
      
                              <th style="font-family: unicode;">Account Name</th>
      
                              <th style="font-family: unicode;">Minimum Amount</th>
      
                              <th style="font-family: unicode;">Maximum Amount</th>
      
                              <th style="font-family: unicode;">Max-Account Count</th>
      
                              <th style="font-family: unicode;">Limited Amount</th>
      
                              <th style="font-family: unicode;">Today Reach Amount</th>
      
                              <th style="font-family: unicode;">Status</th>
      
                              <th style="font-family: unicode;">Created Date</th>
                              <th style="font-family: unicode;">Updated Date</th>
                              <th style="font-family: unicode;">Updated By</th>
      
                              <th style="font-family: unicode;" *ngIf="hardcode_edit_bool === true">Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr *ngFor="let payment of paymentList3">
      
                              <td style="font-family: unicode;">{{payment.payment_type}}</td>
      
                              <td style="font-family: unicode;">{{payment.type}}</td>
      
                              <td style="font-family: unicode;">{{payment.account_no}}</td>
      
                              <td style="font-family: unicode;">{{payment.code_number}}</td>
      
                              <td style="font-family: unicode;">{{payment.account_name}}</td>
      
                              <td style="font-family: unicode;">{{payment.min_amount_Str}}</td>
                              <td style="font-family: unicode;">{{payment.max_amount_Str}}</td>
                              <td style="font-family: unicode;">{{payment.maxAccountCount}}</td>
                              <td style="font-family: unicode;">{{payment.limited_amt_Str}}</td>
      
                              <td style="font-family: unicode;">{{payment.today_reach_amt_Str}}</td>
      
                              <td style="font-family: unicode;">{{payment.status}}</td>
      
                            
                              <td style="font-family: unicode;">{{payment.created_date_Str}}</td>
      
                              <td style="font-family: unicode;">{{payment.updated_date_Str}}</td>
                              <td style="font-family: unicode;">{{payment.updated_by_name}}</td>
      
      
                              <td style="font-family: unicode;" *ngIf="hardcode_edit_bool === true">
      
                                <a (click)="addStepAndNavigate(payment)">
                                  <i class="fas fa-edit" style="color:#4b88eb;"></i>
                                </a>
                                &nbsp;&nbsp;
                                <a herf="#" id="viewDetailMe" title="View Detail">
                                  <i class="far fa-eye" style="color:#4b88eb; cursor: pointer;"
                                    (click)="goModal(payment.id, payment.account_no, payment.payment_type,payment.code_number, payment.account_name)">
                                  </i>
      
                                </a>
      
                              </td>
      
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>

                  <!-- TOPUP -->


                  <div class="col-md-12" *ngIf="type_cbpay === 'WITHDRAWAL'">
                    <div class="card">
                      <div class="card-header">
                        <h3 class="card-title" style="font-weight: bold;">Bank Account CB PAY List </h3>
                      </div>
      
                      <div class="card-body">
                        <table width='100%' id="tblpayment" datatable [dtTrigger]="dtTrigger" [dtOptions]="dtOptions"
                          class="table table-bordered table-hover">
                          <thead>
                            <tr>
                              <th style="font-family: unicode;">Bank Type</th>        
                              <th style="font-family: unicode;">Type</th>        
                              <th style="font-family: unicode;">Account Number</th>      
      
                              <th style="font-family: unicode;">Code Number</th>
                              <th style="font-family: unicode;">Account Name</th>
                              <th style="font-family: unicode;">Today Withdrawal Amount</th>
      
                              <th style="font-family: unicode;">Status</th>
      
                              <th style="font-family: unicode;">Created Date</th>
                              <th style="font-family: unicode;">Updated Date</th>
                              <th style="font-family: unicode;">Updated By</th>
      
                              <th style="font-family: unicode;" *ngIf="hardcode_edit_bool === true">Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr *ngFor="let payment of paymentList3">
      
                              <td style="font-family: unicode;">{{payment.payment_type}}</td>
      
                              <td style="font-family: unicode;">{{payment.type}}</td>
      
                              <td style="font-family: unicode;">{{payment.account_no}}</td>
      
                              <td style="font-family: unicode;">{{payment.code_number}}</td>
                              <td style="font-family: unicode;">{{payment.account_name}}</td>
      
                              <td style="font-family: unicode;">{{payment.today_withdrawal_amt_Str}}</td>
                              <td style="font-family: unicode;">{{payment.status}}</td>      
      
      
                              <td style="font-family: unicode;">{{payment.created_date_Str}}</td>
      
                              <td style="font-family: unicode;">{{payment.updated_date_Str}}</td>
                              <td style="font-family: unicode;">{{payment.updated_by_name}}</td>
      
      
                              <td style="font-family: unicode;" *ngIf="hardcode_edit_bool === true">
      
                                <a (click)="addStepAndNavigate(payment)">
                                  <i class="fas fa-edit" style="color:#4b88eb;"></i>
                                </a>
                                &nbsp;&nbsp;
                                <a herf="#" id="viewDetailMe" title="View Detail">
                                  <i class="far fa-eye" style="color:#4b88eb; cursor: pointer;"
                                    (click)="goModal(payment.id, payment.account_no, payment.payment_type,payment.code_number, payment.account_name)">
                                  </i>
      
                                </a>
      
                              </td>
      
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>                 

                  <!-- WITHDRAWAL -->                    

                </div>
                
              </div>




              <div class="tab-pane" id="ayapay">
                <div class="row">
                  <div class="form-group col-md-2">
                    <label> Account No </label>
                    <input class="form-control form-control-sm" [(ngModel)]="accountNo_ayapay" [ngModelOptions]="{standalone: true}">
                  </div>
      
                  <div class="form-group col-md-2" style="display: none;">
                    <label> Payment Type </label>
                    <select id="parent_id_ayapay" class="form-control form-control-sm col-md-12 col-sm-4 col-xs-4" [(ngModel)]="type_ayapay"
                      [ngModelOptions]="{standalone: true}">
                      <option *ngFor="let payment of paymentList" value="{{payment.id}}">
                        {{payment.payment_type}}
                      </option>
                    </select>
                  </div>
      
                  <div class="form-group col-md-2">
                    <label> Status </label>
                    <select class="status form-control form-control-sm col-md-12 col-sm-4 col-xs-4" [(ngModel)]="status_ayapay"
                      [ngModelOptions]="{standalone: true}">
                      <option value="ACTIVE" selected>Active</option>
                      <option value="INACTIVE">Inactive</option>
                    </select>
                  </div>       
                  
      
                  <div class="form-group col-md-2">
                    <label> Type </label>
                    <select class="status form-control form-control-sm col-md-12 col-sm-4 col-xs-4" [(ngModel)]="type_ayapay"
                      [ngModelOptions]="{standalone: true}" (ngModelChange)="onChangeType_ayapay()">
                      <option value="TOPUP" selected>TOPUP</option>
                      <option value="WITHDRAWAL">WITHDRAWAL</option>
                    </select>
                  </div>
      
                  <div class="form-group col-md-2 col-sm-4 col-xs-4">
                    <label></label>
                    <button id="search" type="button" class="btn btn-block btn-primary btn-sm" style="margin-top: 4%;" (click)="search_ayapay('btnsearch')">                    
                      Search
                    </button>
                  </div>
      
                  <div class="form-group col-md-2 col-sm-4 col-xs-4" *ngIf="hardcode_add_bool === true">
                    <label></label>
                    <button type="button" class="btn btn-block btn-success btn-sm" style="margin-top: 4%;"
                      [routerLink]="['/payment-info-detail-new']">
                      Add New
                    </button>
                  </div>

                  <div class="form-group col-md-2 col-sm-4 col-xs-4">
                    <label></label>
                    <button type="button" class="btn btn-block btn-success btn-sm" style="margin-top: 4%;"
                    (click)="getHolder_ayapay()">
                      All Bank
                    </button>
                  </div>

                  <!-- <div class="form-group col-md-2 col-sm-4 col-xs-4">
                    <label></label>
                    <button id="confirm" type="button" class="btn btn-block btn-sm"
                              style="background-color: #FFC107; color: white; margin-bottom: 12%; margin-right: 50%; margin-top: 3%;" (click)="readNotiData()">
                              <span> Config = {{config}} {{persentage}}</span>
                            </button>                     
                  </div> -->

                  
                  

                  <div class="col-md-12" *ngIf="type_ayapay === 'TOPUP'">
                    <div class="card">
                      <div class="card-header">
                        <div class="row">
                          <h3 class="card-title font-weight-bold">Bank Account AYA PAY List</h3>
                          <div class="form-group col-md-2 col-sm-4 col-xs-4 ml-auto">
                            <button id="confirm" type="button" class="btn btn-block btn-sm"
                            style="background-color: #FFC107; color: white; margin-bottom: 12%;" (click)="readNotiData('AYA Pay')">
                            <span> Config = {{config_aya}} {{persentage}}</span>
                          </button>
                          </div>
                        </div>
                      </div>
                  
                      <div class="card-body">
                        <table width='100%' id="tblpayment" datatable [dtTrigger]="dtTrigger" [dtOptions]="dtOptions"
                          class="table table-bordered table-hover">
                          <thead>
                            <tr>
                              <th style="font-family: unicode;">Bank Type</th>
      
                              <th style="font-family: unicode;">Type</th>
      
                              <th style="font-family: unicode;">Account Number</th>
      
                              <th style="font-family: unicode;">Code Number</th>
      
                              <th style="font-family: unicode;">Account Name</th>
      
                              <th style="font-family: unicode;">Minimum Amount</th>
      
                              <th style="font-family: unicode;">Maximum Amount</th>
      
                              <th style="font-family: unicode;">Max-Account Count</th>
      
                              <th style="font-family: unicode;">Limited Amount</th>
      
                              <th style="font-family: unicode;">Today Reach Amount</th>
      
                              <th style="font-family: unicode;">Status</th>
      
                              <th style="font-family: unicode;">Created Date</th>
                              <th style="font-family: unicode;">Updated Date</th>
                              <th style="font-family: unicode;">Updated By</th>
      
                              <th style="font-family: unicode;" *ngIf="hardcode_edit_bool === true">Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr *ngFor="let payment of paymentList4">
      
                              <td style="font-family: unicode;">{{payment.payment_type}}</td>
      
                              <td style="font-family: unicode;">{{payment.type}}</td>
      
                              <td style="font-family: unicode;">{{payment.account_no}}</td>
      
                              <td style="font-family: unicode;">{{payment.code_number}}</td>
      
                              <td style="font-family: unicode;">{{payment.account_name}}</td>
      
                              <td style="font-family: unicode;">{{payment.min_amount_Str}}</td>
                              <td style="font-family: unicode;">{{payment.max_amount_Str}}</td>
                              <td style="font-family: unicode;">{{payment.maxAccountCount}}</td>
                              <td style="font-family: unicode;">{{payment.limited_amt_Str}}</td>
      
                              <td style="font-family: unicode;">{{payment.today_reach_amt_Str}}</td>
      
                              <td style="font-family: unicode;">{{payment.status}}</td>
      
                            
                              <td style="font-family: unicode;">{{payment.created_date_Str}}</td>
      
                              <td style="font-family: unicode;">{{payment.updated_date_Str}}</td>
                              <td style="font-family: unicode;">{{payment.updated_by_name}}</td>
      
      
                              <td style="font-family: unicode;" *ngIf="hardcode_edit_bool === true">
      
                                <a (click)="addStepAndNavigate(payment)">
                                  <i class="fas fa-edit" style="color:#4b88eb;"></i>
                                </a>
                                &nbsp;&nbsp;
                                <a herf="#" id="viewDetailMe" title="View Detail">
                                  <i class="far fa-eye" style="color:#4b88eb; cursor: pointer;"
                                    (click)="goModal(payment.id, payment.account_no, payment.payment_type,payment.code_number, payment.account_name)">
                                  </i>
      
                                </a>
      
                              </td>
      
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>

                  <!-- TOPUP -->


                  <div class="col-md-12" *ngIf="type_ayapay === 'WITHDRAWAL'">
                    <div class="card">
                      <div class="card-header">
                        <h3 class="card-title" style="font-weight: bold;">Bank Account AYA PAY List </h3>
                      </div>
      
                      <div class="card-body">
                        <table width='100%' id="tblpayment" datatable [dtTrigger]="dtTrigger" [dtOptions]="dtOptions"
                          class="table table-bordered table-hover">
                          <thead>
                            <tr>
                              <th style="font-family: unicode;">Bank Type</th>        
                              <th style="font-family: unicode;">Type</th>        
                              <th style="font-family: unicode;">Account Number</th>      
      
                              <th style="font-family: unicode;">Code Number</th>
                              <th style="font-family: unicode;">Account Name</th>
                              <th style="font-family: unicode;">Today Withdrawal Amount</th>
      
                              <th style="font-family: unicode;">Status</th>
      
                              <th style="font-family: unicode;">Created Date</th>
                              <th style="font-family: unicode;">Updated Date</th>
                              <th style="font-family: unicode;">Updated By</th>
      
                              <th style="font-family: unicode;" *ngIf="hardcode_edit_bool === true">Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr *ngFor="let payment of paymentList4">
      
                              <td style="font-family: unicode;">{{payment.payment_type}}</td>
      
                              <td style="font-family: unicode;">{{payment.type}}</td>
      
                              <td style="font-family: unicode;">{{payment.account_no}}</td>
      
                              <td style="font-family: unicode;">{{payment.code_number}}</td>
                              <td style="font-family: unicode;">{{payment.account_name}}</td>
      
                              <td style="font-family: unicode;">{{payment.today_withdrawal_amt_Str}}</td>
                              <td style="font-family: unicode;">{{payment.status}}</td>      
      
      
                              <td style="font-family: unicode;">{{payment.created_date_Str}}</td>
      
                              <td style="font-family: unicode;">{{payment.updated_date_Str}}</td>
                              <td style="font-family: unicode;">{{payment.updated_by_name}}</td>
      
      
                              <td style="font-family: unicode;" *ngIf="hardcode_edit_bool === true">
      
                                <a (click)="addStepAndNavigate(payment)">
                                  <i class="fas fa-edit" style="color:#4b88eb;"></i>
                                </a>
                                &nbsp;&nbsp;
                                <a herf="#" id="viewDetailMe" title="View Detail">
                                  <i class="far fa-eye" style="color:#4b88eb; cursor: pointer;"
                                    (click)="goModal(payment.id, payment.account_no, payment.payment_type,payment.code_number, payment.account_name)">
                                  </i>
      
                                </a>
      
                              </td>
      
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>                 

                  <!-- WITHDRAWAL -->                    

                </div>
                
              </div>





              <div class="tab-pane" id="wavepay">
                <div class="row">
                  <div class="form-group col-md-2">
                    <label> Account No </label>
                    <input class="form-control form-control-sm" [(ngModel)]="accountNo_wavepay" [ngModelOptions]="{standalone: true}">
                  </div>
      
                  <div class="form-group col-md-2" style="display: none;">
                    <label> Payment Type </label>
                    <select id="parent_id_wavepay" class="form-control form-control-sm col-md-12 col-sm-4 col-xs-4" [(ngModel)]="type_wavepay"
                      [ngModelOptions]="{standalone: true}">
                      <option *ngFor="let payment of paymentList" value="{{payment.id}}">
                        {{payment.payment_type}}
                      </option>
                    </select>
                  </div>
      
                  <div class="form-group col-md-2">
                    <label> Status </label>
                    <select class="status form-control form-control-sm col-md-12 col-sm-4 col-xs-4" [(ngModel)]="status_wavepay"
                      [ngModelOptions]="{standalone: true}">
                      <option value="ACTIVE" selected>Active</option>
                      <option value="INACTIVE">Inactive</option>
                    </select>
                  </div>       
                  
      
                  <div class="form-group col-md-2">
                    <label> Type </label>
                    <select class="status form-control form-control-sm col-md-12 col-sm-4 col-xs-4" [(ngModel)]="type_wavepay"
                      [ngModelOptions]="{standalone: true}" (ngModelChange)="onChangeType_wavepay()">
                      <option value="TOPUP" selected>TOPUP</option>
                      <option value="WITHDRAWAL">WITHDRAWAL</option>
                    </select>
                  </div>
      
                  <div class="form-group col-md-2 col-sm-4 col-xs-4">
                    <label></label>
                    <button id="search" type="button" class="btn btn-block btn-primary btn-sm" style="margin-top: 4%;" (click)="search_wavepay('btnsearch')">                    
                      Search
                    </button>
                  </div>
      
                  <div class="form-group col-md-2 col-sm-4 col-xs-4" *ngIf="hardcode_add_bool === true">
                    <label></label>
                    <button type="button" class="btn btn-block btn-success btn-sm" style="margin-top: 4%;"
                      [routerLink]="['/payment-info-detail-new']">
                      Add New
                    </button>
                  </div>

                  <div class="form-group col-md-2 col-sm-4 col-xs-4">
                    <label></label>
                    <button type="button" class="btn btn-block btn-success btn-sm" style="margin-top: 4%;"
                    (click)="getHolder_wavepay()">
                      All Bank
                    </button>
                  </div>


                  <div class="col-md-12" *ngIf="type_wavepay === 'TOPUP'">
                    <div class="card">
                      <div class="card-header">
                        <div class="row">
                          <h3 class="card-title font-weight-bold">Bank Account WAVE PAY List</h3>
                          <div class="form-group col-md-2 col-sm-4 col-xs-4 ml-auto">
                            <button id="confirm" type="button" class="btn btn-block btn-sm"
                            style="background-color: #FFC107; color: white; margin-bottom: 12%;" (click)="readNotiData('Wave Pay')">
                            <!-- Wave Pay -->
                            <span> Config = {{config_wavepay}} {{persentage}}</span>
                          </button>
                          </div>
                        </div>
                      </div>
                  
                      <div class="card-body">
                        <table width='100%' id="tblpayment" datatable [dtTrigger]="dtTrigger" [dtOptions]="dtOptions"
                          class="table table-bordered table-hover">
                          <thead>
                            <tr>
                              <th style="font-family: unicode;">Bank Type</th>
      
                              <th style="font-family: unicode;">Type</th>
      
                              <th style="font-family: unicode;">Account Number</th>
      
                              <th style="font-family: unicode;">Code Number</th>
      
                              <th style="font-family: unicode;">Account Name</th>
      
                              <th style="font-family: unicode;">Minimum Amount</th>
      
                              <th style="font-family: unicode;">Maximum Amount</th>
      
                              <th style="font-family: unicode;">Max-Account Count</th>
      
                              <th style="font-family: unicode;">Limited Amount</th>
      
                              <th style="font-family: unicode;">Today Reach Amount</th>
      
                              <th style="font-family: unicode;">Status</th>
      
                              <th style="font-family: unicode;">Created Date</th>
                              <th style="font-family: unicode;">Updated Date</th>
                              <th style="font-family: unicode;">Updated By</th>      
                              <th style="font-family: unicode;" *ngIf="hardcode_edit_bool === true">Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr *ngFor="let payment of paymentList5">
      
                              <td style="font-family: unicode;">{{payment.payment_type}}</td>
      
                              <td style="font-family: unicode;">{{payment.type}}</td>
      
                              <td style="font-family: unicode;">{{payment.account_no}}</td>
      
                              <td style="font-family: unicode;">{{payment.code_number}}</td>
      
                              <td style="font-family: unicode;">{{payment.account_name}}</td>
      
                              <td style="font-family: unicode;">{{payment.min_amount_Str}}</td>
                              <td style="font-family: unicode;">{{payment.max_amount_Str}}</td>
                              <td style="font-family: unicode;">{{payment.maxAccountCount}}</td>
                              <td style="font-family: unicode;">{{payment.limited_amt_Str}}</td>
      
                              <td style="font-family: unicode;">{{payment.today_reach_amt_Str}}</td>
      
                              <td style="font-family: unicode;">{{payment.status}}</td>
      
                            
                              <td style="font-family: unicode;">{{payment.created_date_Str}}</td>
      
                              <td style="font-family: unicode;">{{payment.updated_date_Str}}</td>
                              <td style="font-family: unicode;">{{payment.updated_by_name}}</td>
      
      
                              <td style="font-family: unicode;" *ngIf="hardcode_edit_bool === true">
      
                                <a (click)="addStepAndNavigate(payment)">
                                  <i class="fas fa-edit" style="color:#4b88eb;"></i>
                                </a>
                                &nbsp;&nbsp;
                                <a herf="#" id="viewDetailMe" title="View Detail">
                                  <i class="far fa-eye" style="color:#4b88eb; cursor: pointer;"
                                    (click)="goModal(payment.id, payment.account_no, payment.payment_type,payment.code_number, payment.account_name)">
                                  </i>
      
                                </a>
      
                              </td>
      
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>

                  <!-- TOPUP -->


                  <div class="col-md-12" *ngIf="type_wavepay === 'WITHDRAWAL'">
                    <div class="card">
                      <div class="card-header">
                        <h3 class="card-title" style="font-weight: bold;">Bank Account WAVE PAY List </h3>
                      </div>
      
                      <div class="card-body">
                        <table width='100%' id="tblpayment" datatable [dtTrigger]="dtTrigger" [dtOptions]="dtOptions"
                          class="table table-bordered table-hover">
                          <thead>
                            <tr>
                              <th style="font-family: unicode;">Bank Type</th>        
                              <th style="font-family: unicode;">Type</th>        
                              <th style="font-family: unicode;">Account Number</th>      
      
                              <th style="font-family: unicode;">Code Number</th>
                              <th style="font-family: unicode;">Account Name</th>
                              <th style="font-family: unicode;">Today Withdrawal Amount</th>
      
                              <th style="font-family: unicode;">Status</th>
      
                              <th style="font-family: unicode;">Created Date</th>
                              <th style="font-family: unicode;">Updated Date</th>
                              <th style="font-family: unicode;">Updated By</th>
      
                              <th style="font-family: unicode;" *ngIf="hardcode_edit_bool === true">Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr *ngFor="let payment of paymentList5">
      
                              <td style="font-family: unicode;">{{payment.payment_type}}</td>
      
                              <td style="font-family: unicode;">{{payment.type}}</td>
      
                              <td style="font-family: unicode;">{{payment.account_no}}</td>
      
                              <td style="font-family: unicode;">{{payment.code_number}}</td>
                              <td style="font-family: unicode;">{{payment.account_name}}</td>
      
                              <td style="font-family: unicode;">{{payment.today_withdrawal_amt_Str}}</td>
                              <td style="font-family: unicode;">{{payment.status}}</td>      
      
      
                              <td style="font-family: unicode;">{{payment.created_date_Str}}</td>
      
                              <td style="font-family: unicode;">{{payment.updated_date_Str}}</td>
                              <td style="font-family: unicode;">{{payment.updated_by_name}}</td>
      
      
                              <td style="font-family: unicode;" *ngIf="hardcode_edit_bool === true">
      
                                <a (click)="addStepAndNavigate(payment)">
                                  <i class="fas fa-edit" style="color:#4b88eb;"></i>
                                </a>
                                &nbsp;&nbsp;
                                <a herf="#" id="viewDetailMe" title="View Detail">
                                  <i class="far fa-eye" style="color:#4b88eb; cursor: pointer;"
                                    (click)="goModal(payment.id, payment.account_no, payment.payment_type,payment.code_number, payment.account_name)">
                                  </i>
      
                                </a>
      
                              </td>
      
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>                 

                  <!-- WITHDRAWAL -->                    

                </div>
                
              </div>


              </div>
            </div>

          </div>
        </form>
       
      </div>

    </section>

  </div>

  <aside class="control-sidebar control-sidebar-dark">
 
  </aside>

  <app-footer></app-footer>

  <ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#fff" type="square-jelly-box">
    <p style="font-size: 16px; color: white">Loading...</p>
  </ngx-spinner>

</div>


<div id="browseAccountHolderList" class="modal fade">
  <div class="modal-dialog">
    <div class="modal-content" style="width : 700px; margin-left: -100px;">
      <div class="modal-header">
        <h3 class="modal-title" style="font-weight: bold;font-size: 1.1rem;"> Account Holder (Admin) Detail </h3>
        <button type="button" class="close" data-dismiss="modal" aria-hidden="true"> x </button>
      </div>

      <div class="modal-body">
        <form>
          <div class="col-md-12">

            <div class="row" style="margin-left: 0.1%;">
              <label class="col-md-2"> Bank Type </label>
              <div class="form-group col-md-3">
                <input class="form-control form-control-sm" [(ngModel)]="bankName" [ngModelOptions]="{standalone: true}"
                  disabled>
              </div>
              <div class="col-md-1"></div>
              <label class="col-md-2">Account Number</label>
              <div class="form-group col-md-3">
                <input class="form-control form-control-sm" [(ngModel)]="account_no"
                  [ngModelOptions]="{standalone: true}" disabled>
              </div>
            </div>

            <div class="row" style="margin-left: 0.1%;">

              <label class="col-md-2">Code Number</label>
              <div class="form-group col-md-3">
                <input class="form-control form-control-sm" [(ngModel)]="code_number"
                  [ngModelOptions]="{standalone: true}" disabled>
              </div>
              <div class="col-md-1"></div>
              <label class="col-md-2">Account Name</label>
              <div class="form-group col-md-3">
                <input class="form-control form-control-sm" [(ngModel)]="account_name"
                  [ngModelOptions]="{standalone: true}" disabled>
              </div>
            </div>

            <div class="col-md-12" style="margin-top: 0.5%;">

              <div class="card">
                <div class="card-body">
                  <table width='100%' id="{{'paymentBankAccountHolderDetaiList' + idIndex2}}" datatable
                    [dtTrigger]="dtTrigger2" [dtOptions]="dtOptions2" class="table table-bordered table-hover">

                    <thead>
                      <tr>
                        <th style="font-family: unicode;">No</th>
                        <th style="font-family: unicode;">Account Holder(Admin)</th>
                      </tr>
                    </thead>

                    <tbody>
                      <tr *ngFor="let accDetail of paymentBankAccountHolderDetaiList, let i = index">
                        <td style="font-family: unicode;padding: 0.35rem 0.75rem 0.30rem 0.75rem;">{{ i + 1 }}</td>
                        <td style="font-family: unicode;padding: 0.35rem 0.75rem 0.30rem 0.75rem;">
                          {{accDetail.adminName}}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>

            </div>

          </div>
        </form>
      </div>
    </div>
  </div>
</div>




<div id="readData" class="modal fade">
  <div class="modal-dialog modal-dialog-above" role="document">
    <div class="modal-content">
      <div class="modal-body text-center">
        <div class="row justify-content-center mb-3">
          <label class="col-md-4 text-right">Percent (%)</label>
          <div class="col-md-6">
            <input class="form-control form-control-sm" [(ngModel)]="persentage_data">
          </div>
        </div>
        <div class="row justify-content-center">
          <div class="col-md-3">
            <button type="button" class="btn btn-success btn-block" (click)="readNoti_byBankType()">Ok</button>
          </div>
          <!-- readNoti -->
          <div class="col-md-3">
            <button type="button" class="btn btn-danger btn-block" (click)="closeTran()">Cancel</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div> 







<div id="accountholder" class="modal fade">
  <div class="modal-dialog">
    <div class="modal-content" style="width: 500px; margin-left: -50px;">
      <div class="modal-header">
        <h3 class="modal-title" style="font-weight: bold; font-size: 1.1rem;">Account Holder Detail</h3>
        <button type="button" class="close" data-dismiss="modal" aria-hidden="true">x</button>
      </div>

      <div class="modal-body">
        <form>
          <div class="col-md-12">
            <div class="row" style="margin-left: 10%; margin-top: 15px; margin-right: 2%;">
              <label class="col-md-5"> Account Holder (Admin)</label>
              <div class="form-group col-md-7">
                <ngx-bootstrap-multiselect 
                  [options]="myOptions" 
                  [(ngModel)]="optionsModel" 
                  (ngModelChange)="onChange()"
                  [ngModelOptions]="{standalone: true}" 
                  [texts]="myTexts" 
                  [settings]="mySettings" 
                  class="form-control-lg" 
                  style="width: 200%; height: 40px;" 
                >
                </ngx-bootstrap-multiselect>
              </div>
            </div>
          </div>
        </form>
      </div>

      <div class="modal-footer" style="display: flex; justify-content: center;">
        <button type="button" class="btn btn-secondary" data-dismiss="modal" (click)="close()">Cancel</button>
        <button type="button" class="btn btn-primary" (click)="confirmSelection()">Confirm</button>
      </div>
    </div>
  </div>
</div>




