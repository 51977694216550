<body class="hold-transition login-page">
    <div class="login-box">
      <div class="login-logo">
        <a><b> Gbost </b> Admin</a>
      </div>
      <div class="card">
        <div class="card-body login-card-body">
          <p class="login-box-msg">Login in to start your session</p>
    
          <form (ngSubmit)="login()">
            <div class="input-group mb-3"> 
              <input class="form-control" placeholder="Phone No." [(ngModel)]="phone_no" [ngModelOptions]="{standalone: true}" (keyup.enter)="login()">
              <div class="input-group-append">
                <div class="input-group-text">
                  <span class="fas fa-mobile-alt"></span>
                </div>
              </div>
            </div>
            <div class="input-group mb-3">
              <input type="password" class="form-control" placeholder="Password" [(ngModel)]="password" [ngModelOptions]="{standalone: true}" (keyup.enter)="login()">
              <div class="input-group-append">
                <div class="input-group-text">
                  <span class="fas fa-lock"></span>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-8">
                <div class="icheck-primary">
                  <input type="checkbox" id="remember">
                  <label for="remember">
                    Remember Me
                  </label>
                </div>
              </div>
              <!-- <div class="col-4">
                <button type="submit" class="btn btn-block st-btn" (click)="login()"> Log In </button>
              </div> -->


            </div>
          </form>
    
          <div class="social-auth-links text-center mb-3">
            <p></p>
            <!-- <a href class="btn btn-block st-btn" href="https://www.facebook.com">
              <i class="fab fa-facebook mr-2"></i> Like us on Facebook
            </a>
            <a href class="btn btn-block btn-danger" href="https://www.facebook.com">
              <i class="fab fa fa-globe mr-2"></i> Visit our website
            </a> -->

            <!-- <button type="submit" class="btn btn-block st-btn" (click)="login()"> Log In </button> -->
            <form (ngSubmit)="login()" (keydown)="onKeydown($event)">           
              <button type="submit" class="btn btn-block st-btn" #loginButton> Log In </button>
            </form>
           
            
          </div>
        </div>
      </div>
    </div>
    <ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#fff" type="square-jelly-box">
      <p style="font-size: 16px; color: white">Loading...</p>
    </ngx-spinner>
  </body>

  


