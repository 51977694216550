import { Component, OnInit, ViewChild } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,HttpErrorResponse } from '@angular/common/http';
import { Router, NavigationEnd } from '@angular/router';
import { DataTableDirective } from 'angular-datatables';

import { LocalStorageService } from 'ngx-webstorage';
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from 'ngx-toastr';

import { DtoService } from '../../service/dto.service';
import { FunctService } from '../../service/funct.service';

import { catchError, retry } from 'rxjs/operators';
import { throwError } from 'rxjs';

import { Subject } from 'rxjs';
declare var $: any;

@Component({
  selector: 'app-game',
  templateUrl: './twod-section.component.html',
  styleUrls: ['./twod-section.component.css']
})
export class TwodSectionComponent implements OnInit {

  @ViewChild(DataTableDirective)
  dtElement: DataTableDirective;
  dtOptions: DataTables.Settings = {};
  dtOptions2 : DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();
  dtTrigger2 : Subject<any> = new Subject();
  providerName: string = '';
  gameCode: string = '';
  type: string = '';
  status: string = '';
  sectionList: any;
  idIndex: any;
  token: any;
  idIndex2 : any;
  pid : any;
  paymentTemp : any;
  paymentTempList : any;
  name : any;

  hardcode_edit :any;
  hardcode_add :any;
  hardcode_view: any;
  hardcode_view_bool : any;
  hardcode_add_bool : any;
  hardcode_edit_bool : any;

constructor(private storage: LocalStorageService, private spinner: NgxSpinnerService, private http: HttpClient, private dto: DtoService,
    private router: Router, private funct: FunctService, private toastr: ToastrService,) {
    this.idIndex = 1;
    this.storage.store('isNotiSong', "");
    this.hardcode_view = "ResultConfiguration_View";
    this.hardcode_edit = "ResultConfiguration_Edit";
    this.hardcode_add = "ResultConfiguration_Add";
    this.dto.menuCodes = this.storage.retrieve('menucodes');

      const filteredMenuCodes_view = [];
      const filteredMenuCodeds_add =[];
      const filteredMenuCodeds_edit =[];
      this.dto.menuCodes.forEach(menuCode => {
        if (menuCode === this.hardcode_view) {
          filteredMenuCodes_view.push(menuCode);
        }
        if (menuCode === this.hardcode_add) {
          filteredMenuCodeds_add.push(menuCode);
        }
        if (menuCode === this.hardcode_edit) {
          filteredMenuCodeds_edit.push(menuCode);
        }
      });
      if(filteredMenuCodes_view.length != 0)
      {
            this.hardcode_view_bool = true;
      }
      if(filteredMenuCodeds_add.length != 0)
      {
            this.hardcode_add_bool = true;
      }
      if(filteredMenuCodeds_edit.length != 0)
      {
            this.hardcode_edit_bool = true;
      }


    //this.status = 'ACTIVE';
    this.search();
  }

  ngOnInit(): void {

    this.router.routeReuseStrategy.shouldReuseRoute = function () {
      return false;
    };

    this.router.events.subscribe((evt) => {
      if (evt instanceof NavigationEnd) {
        // trick the Router into believing it's last link wasn't previously loaded
        this.router.navigated = false;
        // if you need to scroll back to top, here is the right place
        window.scrollTo(0, 0);
      }
    });
    this.dtOptions = {
      responsive: true,
      order: []
    }
    this.dtOptions.columnDefs = [
    ];

    if (!this.storage.retrieve('loadFlag')) {
      this.storage.store('loadFlag', 'noLoad');
      setTimeout(function () {
        location.reload();
      }, 5);
    }
    else {
      this.storage.clear('loadFlag');
    }
  }

  handleError(error: HttpErrorResponse){
    if(error.status == 423)
    {
      this.spinner.hide();
      $("#deleteData").modal("show");
    }
    if(error.status == 403)
    {
      this.spinner.hide();
      var id = 'tbl2dsection' + this.idIndex;
      var table = $('#' + id).DataTable();
      this.toastr.error("Limited Access.", 'Invalid!', {
        timeOut: 3000,
        positionClass: 'toast-top-right',
        });
    }
    return throwError(error);
    }
    OkLogout()
    {
      window.location.href ="./ad-login";
    } 

  search() {
    
    var id = 'tbl2dsection' + this.idIndex;
    var table = $('#' + id).DataTable();
    table.destroy();
    this.idIndex = this.idIndex + 1;
    this.spinner.show();
    this.dto.token = this.storage.retrieve('token');
    let headers = new HttpHeaders();
    headers = headers.set('Authorization',  this.dto.token);
    this.sectionList = [];
    this.http.get(this.funct.ipaddress + 'twodsection/getSectionByAdmin', { headers: headers })
    .pipe(
      catchError(this.handleError.bind(this))
     )
    .subscribe(
      result => {
        this.dto.Response = {};
        this.dto.Response = result;
        this.sectionList = this.dto.Response;
        this.dtTrigger.next();
        this.spinner.hide();
      });
  }

  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }


  

}
