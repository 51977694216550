import { Component, OnInit, ViewChild } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams ,HttpErrorResponse} from '@angular/common/http';
import { Router, NavigationEnd } from '@angular/router';
import { DataTableDirective } from 'angular-datatables';
import { ToastrService } from 'ngx-toastr';

import { LocalStorageService } from 'ngx-webstorage';
import { NgxSpinnerService } from "ngx-spinner";

import { catchError, retry } from 'rxjs/operators';
import { throwError } from 'rxjs';

import { DtoService } from '../../service/dto.service';
import { FunctService } from '../../service/funct.service';

import { Subject } from 'rxjs';
declare var $: any;

@Component({
  selector: 'app-user-role',
  templateUrl: './user-role.component.html',
  styleUrls: ['./user-role.component.css']
})
export class UserRoleComponent implements OnInit {

  @ViewChild(DataTableDirective)
  dtElement: DataTableDirective;
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();

  phoneNo: string = '';
  name: string = '';
  referralCode: string = '';
  status: string = '';
  adminList: any;
  idIndex: any;
  token: any;
  addTranDto:any;
  addTranAmt:any;
  editId:any;
  adsDTO:any;
  totalItems : any; 
  rolename:any;
  isTyping: boolean = false;

  hardcode_edit :any;
  hardcode_add :any;
  hardcode_view: any;
  hardcode_delete:any;
  hardcode_view_bool : any;
  hardcode_add_bool : any;
  hardcode_edit_bool : any;
  hardcode_delete_bool : any;

  constructor(private storage: LocalStorageService, private spinner: NgxSpinnerService,private toastr: ToastrService, private http: HttpClient, private dto: DtoService, private router: Router, private funct: FunctService) {
    this.storage.store('isNotiSong', "");
    this.idIndex = 1;
    this.status = 'ACTIVE';

    const filteredMenuCodes_view = [];
    const filteredMenuCodes_add = [];
    const filteredMenuCodes_edit = []; 
    this.dto.menuCodes = this.storage.retrieve('menucodes');
    this.hardcode_view = "RoleManagement_View";
    this.hardcode_edit = "RoleManagement_Edit";
    this.hardcode_add = "RoleManagement_Add";
    this.dto.menuCodes.forEach(menuCode => {
      if (menuCode === this.hardcode_view) {
        filteredMenuCodes_view.push(menuCode);
      } 
      if (menuCode === this.hardcode_edit) {
        filteredMenuCodes_edit.push(menuCode);
      }
      if (menuCode === this.hardcode_add) {
        filteredMenuCodes_add.push(menuCode);
      }
      
    });
    
    if(filteredMenuCodes_view.length != 0)
    {
        this.hardcode_view_bool = true;
    }
  
    if(filteredMenuCodes_add.length != 0)
    {
        this.hardcode_add_bool = true;
    }
    if(filteredMenuCodes_edit.length != 0)
    {
        this.hardcode_edit_bool = true;
    }
    else
    {
      this.hardcode_edit_bool = false;
    }

    this.search();   
  }



  ngOnInit(): void {

    console.log("ngOnInit");

    this.router.routeReuseStrategy.shouldReuseRoute = function () {
      return false;
    };

    this.router.events.subscribe((evt) => {
      if (evt instanceof NavigationEnd) {

        this.router.navigated = false;
       
        window.scrollTo(0, 0);
      }
    });

    this.dtOptions = {
      // responsive: true,
      // stateSave : true,
      responsive: true,
      order: []
    }
    this.adminList = {
      id: 0,      
      name:''
      
    };

    this.addTranDto = {    
      roleName: ''
    };
    this.adsDTO = {
      id: 0,
      roleName:''      
    };

    this.dtOptions.columnDefs = [
      { targets: [4], orderable: false }
    ];

    if (!this.storage.retrieve('loadFlag')) {
      this.storage.store('loadFlag', 'noLoad');
      setTimeout(function () {
        location.reload();
      }, 4);
    }
    else {
      this.storage.clear('loadFlag');
    }
  }

  ngAfterViewInit() {
  }

  handleError(error: HttpErrorResponse){
    if(error.status == 423)
    {
      this.spinner.hide();
      $("#deleteData").modal("show");
    }
    if(error.status == 403)
    {
      this.spinner.hide();
      var id = 'tblAdmin' + this.idIndex;
      var table = $('#' + id).DataTable();
      this.toastr.error("Limited Access.", 'Invalid!', {
        timeOut: 3000,
        positionClass: 'toast-top-right',
        });
    }
    return throwError(error);
    }
    OkLogout()
    {
      window.location.href ="./ad-login";
    } 

    showAddTranModel()
    {      
      $('#browseAccountData9').modal("show");
    }
    
 
    showAddTranModelNew()
    {     
 
      $('#browseAccountData10').modal("show");

      
    }
    UpdateCache()
    {

      this.adminList = [];
      var id = 'tblAdmin' + this.idIndex;
      var table = $('#' + id).DataTable();
      table.destroy();
      this.idIndex = this.idIndex + 1;
      this.spinner.show();  
      this.token = this.storage.retrieve('token');
      let headers = new HttpHeaders();
      headers = headers.set('Authorization',  this.token);
      let params = new HttpParams();        
      this.http.get(this.funct.ipaddress + 'admin/UpdateCache', { headers: headers })

        .subscribe(
          result => {
            this.dto.Response = {};    
            this.dto.Response = result;  
            this.adminList = this.dto.Response; 

            if (this.dto.Response.status == 'Success') 
            {
              this.spinner.hide();      
              //this.RoleNameSearch();      
              this.router.navigate(['/user-role']).then(() => {
                this.toastr.success(this.dto.Response.message, 'Success!', {
                  timeOut: 3000,
                  positionClass: 'toast-top-right'
                });
              })
            }
            else {
              this.spinner.hide();
              this.toastr.error(this.dto.Response.message, 'Invalid!', {
                timeOut: 3000,
                positionClass: 'toast-top-right',
              });
            }

          
          }
        );

    }
  

    Close()
    {
     

      $('#browseAccountData9').modal("hide"); 
      $('#browseAccountData10').modal("hide"); 

    }

 

    showEditRolesModel(editId , isTyping)
    {     
        isTyping = false;
        this.token = this.storage.retrieve('token');
        let headers = new HttpHeaders();
        headers = headers.set('Authorization',  this.token);
        let params = new HttpParams();
        params = params.set('id', editId);
        this.http.get(this.funct.ipaddress + 'admin/GetAdminRole', { params: params, headers: headers })
        .pipe(
          catchError(this.handleError.bind(this))
         )
        .subscribe( 
          result => {
            this.dto.Response = {};
            this.dto.Response = result;
            this.adsDTO = this.dto.Response;             
            $('#browseAccountData9').modal("show");
          });     
        
    }

    addTran(id ,addTranAmt)
    {
      
      this.token = this.storage.retrieve('token');
      let headers = new HttpHeaders();
      headers = headers.set('Authorization', this.token);  
      this.adsDTO.id = id; 
      this.adsDTO.roleName = addTranAmt; 
      this.http.post(this.funct.ipaddress + 'admin/UpdateRoleName', this.adsDTO,{ headers: headers })
      .pipe(
        catchError(this.handleError.bind(this))
       )
      .subscribe(
        result => {
          this.dto.Response = {};
          this.dto.Response = result;
          if(this.dto.Response.status == 'Success')
          {   
            this.search();
              $('#browseAccountData9').modal("hide");   
            this.toastr.success(this.dto.Response.message, 'Success!', {
              timeOut: 3000,
              positionClass: 'toast-top-right'
            });
            this.spinner.hide();
         }
       
        }
      );
    }
    
    SaveNewRole(addAmount)
    {

      if(this.addTranAmt != null || this.addTranAmt != undefined)
      {

        this.token = this.storage.retrieve('token');
      let headers = new HttpHeaders();
      headers = headers.set('Authorization', this.token);  
      this.addTranDto.roleName = addAmount; 
      this.http.post(this.funct.ipaddress + 'admin/AddRoleName', this.addTranDto,{ headers: headers })
      .pipe(
        catchError(this.handleError.bind(this))
       )
      .subscribe(
        result => {
          this.dto.Response = {};
          this.dto.Response = result;
          if(this.dto.Response.status == 'Success')
          {    
            this.search(); 
            $('#browseAccountData9').modal("hide");
            $('#browseAccountData10').modal("hide");
            this.toastr.success(this.dto.Response.message, 'Success!', {
              timeOut: 3000,
              positionClass: 'toast -top-right'
            });         
            this.spinner.hide();
                       
          }
          else{

            $('#browseAccountData9').modal("hide");
            $('#browseAccountData10').modal("hide");         
            this.toastr.error(this.dto.Response.message, 'Invalid!', {
            timeOut: 3000,
            positionClass: 'toast-top-right',
            });
            this.spinner.hide(); 
            
           }
      
        }
      );
      
      } 
      else{
        this.toastr.error("Please fill data ", 'Invalid!', {
          timeOut: 3000,
          positionClass: 'toast-top-right',
        });
      }
      
    }

    RoleNameSearch()
    {
      this.adminList = [];
      var id = 'tblAdmin' + this.idIndex;
      var table = $('#' + id).DataTable();
      table.destroy();
      this.idIndex = this.idIndex + 1;
      this.spinner.show();  
      this.token = this.storage.retrieve('token');
      let headers = new HttpHeaders();
      headers = headers.set('Authorization',  this.token);
      let params = new HttpParams();        
      if(this.rolename == null || this.rolename== undefined)
      {   

        this.http.get(this.funct.ipaddress + 'admin/userRoleByparams', { headers: headers })

        .subscribe(
          result => {
            this.dto.Response = {};    
            this.dto.Response = result;  
            this.adminList = this.dto.Response;    
            this.dtTrigger.next();
            this.spinner.hide();
          
          }
        );
      }
      else
      {
        params = params.set('name',  this.rolename);   
        this.http.get(this.funct.ipaddress + 'admin/GetAllRolesByName', 
        { params: params, headers: headers } ).subscribe((result: any) => {
            this.dto.Response = {};    
            this.dto.Response = result;  
            this.adminList = this.dto.Response;
            this.dtTrigger.next();
            this.spinner.hide();
           
          }
        );
      }
      
    }

  search() {
    this.adminList = [];
    var id = 'tblAdmin' + this.idIndex;
    var table = $('#' + id).DataTable();
    table.destroy();
    this.idIndex = this.idIndex + 1;
    this.spinner.show();

    this.token = this.storage.retrieve('token');
    let headers = new HttpHeaders();
    headers = headers.set('Authorization',  this.token);  
    this.adminList = [];
    this.http.get(this.funct.ipaddress + 'admin/userRoleByparams', { headers: headers })

    .subscribe(
      result => {
        this.dto.Response = {};    
        this.dto.Response = result;  
        this.adminList = this.dto.Response;   
        this.dtTrigger.next();
        this.spinner.hide();
       
      }
    );
  }

  qty(page: any) {
    this.adminList = [];
    var id = 'tblAdmin' + this.idIndex;
    var table = $('#' + id).DataTable();
    table.destroy();
    this.idIndex = this.idIndex + 1;
    this.spinner.show();

    this.token = this.storage.retrieve('token');
    let headers = new HttpHeaders();
    headers = headers.set('Authorization',  this.token);  
    this.adminList = [];
    this.http.get(this.funct.ipaddress + 'admin/userRoleByparams', { headers: headers })  

    .subscribe(
      result => {
        this.dto.Response = {};    
        this.dto.Response = result;  
        this.adminList = this.dto.Response;       
        this.dtTrigger.next();
        this.spinner.hide();
       
      }
    );
  }


onInputKeyDown(): void {
  this.isTyping = true;
}

onInputKeyUp(): void {
  this.isTyping = false;
}


  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }
}