<div class="wrapper">
    <!-- Navbar -->
    <app-header-sidebar></app-header-sidebar>

    <!-- here here here -->
    <!-- Content Wrapper. Contains page content -->
    <div class="content-wrapper">
        <!-- Content Header (Page header) -->
        <div class="content-header">
            <div class="container-fluid">
            </div><!-- /.container-fluid -->
        </div>
        <!-- /.content-header -->

        <!-- Main content -->
        <section class="content">
            <div class="container-fluid">
                <form>
                    <div class="row">
                        <div class="col-md-12">
                            <div class="card">
                                <div class="card-header">
                                    <h3 class="card-title" style="font-weight: bold;">2D/3D Discount Percentage Detail </h3>
                                </div>
                                <!-- /.card-header -->
                                <div class="card-body">
                                    <form>
                                       <!-- <div class="row">
                                            <label class="col-md-2"> Date </label>
                                            <div class="form-group col-md-3">
                                                <input id="date" readonly class="form-control form-control-sm"
                                                    placeholder="Select Date" [ngxDatePicker]="dateInstanceSingle"
                                                    [value]="date" (valueChange)="onChangeDate()"
                                                    [(ngModel)]="changedate" [ngModelOptions]="{standalone: true}">
                                                <ngx-date-picker #dateInstanceSingle></ngx-date-picker>
                                            </div>
                                        </div>-->

                                         <div class="row">
                                            <label class="col-md-2">Discount Percentage (%)</label>
                                            <div class="form-group col-md-3">
                                              <input type = "text" class="form-control form-control-sm" [(ngModel)]="discountDTO.discount_percent"
                                                [ngModelOptions]="{standalone: true}">
                                            </div>
                                          </div>

                                          <div class="row">
                                            <label class="col-md-2">Started Total Bet Amount</label>
                                            <div class="form-group col-md-3">
                                              <input type = "text" class="form-control form-control-sm" [(ngModel)]="discountDTO.limitedAmt"
                                                [ngModelOptions]="{standalone: true}">
                                            </div>
                                          </div>


                                          <div class="row">
                                            <label class="col-md-2">Type</label>
                                            <div class="form-group col-md-3">
                                            <select id = "type" class="form-control form-control-sm" [(ngModel)]="discountDTO.betType" [ngModelOptions]="{standalone: true}">
                                                <option value = "2D">2D</option>
                                                <option value = "3D">3D</option>
                                            </select>
                                            </div>
                                          </div>

                                          <div class="row">
                                            <label class="col-md-2"> Status </label>
                                            <div class="form-group col-md-3 radio">
                                                <div class="form-group clearfix">
                                                    <div class="icheck-success d-inline">
                                                        <input type="radio" name="r3" checked id="radioSuccess1"
                                                            value="ACTIVE" [(ngModel)]="discountDTO.status"
                                                            [ngModelOptions]="{standalone: true}">
                                                        <label for="radioSuccess1">Active &nbsp;&nbsp;
                                                        </label>
                                                    </div>
                                                    <div class="icheck-success d-inline">
                                                        <input type="radio" name="r3" id="radioSuccess2"
                                                            value="INACTIVE" [(ngModel)]="discountDTO.status"
                                                            [ngModelOptions]="{standalone: true}">
                                                        <label for="radioSuccess2">Inactive
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>


                                        <div class="row" style="margin-top: 1%;">
                                            <div class="col-md-2 col-sm-6">
                                                <button type="button" class="btn btn-block btn-success"
                                                    (click)="goOddSave()"> Save </button>&nbsp;
                                            </div>
                                            <div class="col-md-2 col-sm-6">
                                                <button type="button" class="btn btn-block btn-danger"
                                                [routerLink] = "['/discount-entry']"> Cancel </button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                                <!-- /.card-body -->
                            </div>
                        </div>

                    </div>
                </form>
                <!-- /.row -->

                <!-- /.row -->
            </div>
            <!--/. container-fluid -->
        </section>
        <!-- /.content -->
    </div>

    <aside class="control-sidebar control-sidebar-dark">
        <!-- Control sidebar content goes here -->
    </aside>

    <app-footer></app-footer>

    <ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#fff" type="square-jelly-box">
        <p style="font-size: 16px; color: white">Loading...</p>
    </ngx-spinner>

</div>


<div id="deleteData" class="modal fade">
    <div class="modal-dialog">
        <div class="modal-content">

            <div class="modal-body">
                <form>

                    <div class="row" style="margin-top: 5%;margin-bottom: 3%;margin-left: 9%;">
                        <div class="form-group col-md-12">
                            <h5> This Account is used by another device. 
                                Please Sign Out. Login Again. </h5>
                        </div>
                    </div>

                    <div class="row" style="margin-left: 40%;margin-bottom: 3%;">
                        <div class="col-md-5 col-sm-6" id="deleteBtn">
                            <button type="button" class="btn btn-block btn-success" (click)="OkLogout()"> Ok </button>
                        </div>
                    </div>
                </form>
            </div>

        </div>
    </div>
</div>