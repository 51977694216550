<div class="wrapper">
    <!-- Navbar -->
    <app-header-sidebar></app-header-sidebar>

    <!-- here here here -->
    <!-- Content Wrapper. Contains page content -->
    <div class="content-wrapper">
        <!-- Content Header (Page header) -->
        <div class="content-header">
            <div class="container-fluid">
                <!--  <div class="row mb-2">
                    <div class="col-sm-6">
                        <h1 class="m-0 text-dark"> Promotion and News </h1>
                    </div>
                </div> -->
                <!-- /.row -->
            </div><!-- /.container-fluid -->
        </div>
        <!-- /.content-header -->

        <!-- Main content -->
        <section class="content">
            <div class="container-fluid">
                <form>
                    <div class="row">
                        <div class="col-md-12">

                            <div class="card">
                                <div class="card-header">
                                    <h3 class="card-title" style="font-weight: bold;"> Point Promotion Detail </h3>
                                </div>
                                <!-- /.card-header -->
                                <div class="card-body">
                                    <form>

                                        <div class="row">
                                            <label class="col-md-2"> User Type </label>
                                            <div class="form-group col-md-3">
                                                <select id = "roleName" class="form-control form-control-sm col-md-12 col-sm-4 col-xs-4"
                                                [(ngModel)]="pointPromotionModel.userType" [ngModelOptions]="{standalone: true}">
                                                 <option *ngFor="let p of promotionUserType" value = "{{p.userType}}" >
                                                   {{p.userType}}
                                                 </option>
                                              </select>
                                            </div>
                                        </div>

                                        <div class="row">
                                                  <label class="col-md-2"> Percentage (%) </label>
                                                    <div class="form-group col-md-3">
                                                        <input class="form-control form-control-sm" 
                                                            [(ngModel)]="pointPromotionModel.percentage" 
                                                            [ngModelOptions]="{standalone: true}">
                                                    </div>
                                        </div> 

                                        <div class="row">
                                            <label class="col-md-2"> From Amount </label>
                                            <div class="form-group col-md-3">
                                                <input class="form-control form-control-sm" 
                                                [(ngModel)]="pointPromotionModel.fromAmount"
                                                [ngModelOptions]="{standalone: true}">
                                           </div>
                                        </div> 

                                        <div class="row">
                                            <label class="col-md-2"> To Amount </label>
                                            <div class="form-group col-md-3">
                                                <input class="form-control form-control-sm" 
                                                [(ngModel)]="pointPromotionModel.toAmount"
                                                [ngModelOptions]="{standalone: true}">
                                           </div>
                                        </div> 

                                        <div class="row">
                                            <label class="col-md-2"> Status </label>
                                            <div class="form-group col-md-3 radio">
                                                <div class="form-group clearfix">
                                                    <div class="icheck-success d-inline">
                                                        <input type="radio" name="r3" checked id="radioSuccess1"
                                                            value="ACTIVE" [(ngModel)]="pointPromotionModel.status"
                                                            [ngModelOptions]="{standalone: true}">
                                                        <label for="radioSuccess1">Active &nbsp;&nbsp;
                                                        </label>
                                                    </div>
                                                    <div class="icheck-success d-inline">
                                                        <input type="radio" name="r3" id="radioSuccess2"
                                                            value="INACTIVE" [(ngModel)]="pointPromotionModel.status"
                                                            [ngModelOptions]="{standalone: true}">
                                                        <label for="radioSuccess2">Inactive
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="row" style="margin-top: 1%;">
                                            <div class="col-md-2 col-sm-6">
                                                <button type="button" class="btn btn-block btn-success"
                                                    (click)="goSave()">Save</button>&nbsp;</div>
                                            <div class="col-md-2 col-sm-6">
                                                <button type="button" class="btn btn-block btn-danger"
                                                    [routerLink]="['/point-promotion']"> Cancel </button></div>
                                        </div>
                                    </form>

                                </div>
                            </div>
                        </div>
                    </div>
                </form>
                <!-- /.row -->

                <!-- /.row -->
            </div>
            <!--/. container-fluid -->
        </section>
        <!-- /.content -->
    </div>

    <aside class="control-sidebar control-sidebar-dark">
        <!-- Control sidebar content goes here -->
    </aside>

    <app-footer></app-footer>

    <ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#fff" type="square-jelly-box">
        <p style="font-size: 16px; color: white">Loading...</p>
    </ngx-spinner>

</div>

