<div class="wrapper">
    <!-- Navbar -->
    <app-header-sidebar></app-header-sidebar>

    <!-- here here here -->
    <!-- Content Wrapper. Contains page content -->
    <div class="content-wrapper">
        <!-- Content Header (Page header) -->
        <div class="content-header">
            <div class="container-fluid">
                <!--  <div class="row mb-2">
                    <div class="col-sm-6">
                        <h1 class="m-0 text-dark"> Promotion and News </h1>
                    </div>
                </div> -->
                <!-- /.row -->
            </div><!-- /.container-fluid -->
        </div>
        <!-- /.content-header -->

        <!-- Main content -->
        <section class="content">
            <div class="container-fluid">
                <form>

                    <div class="row">
                        <div class="col-md-12">

                            <div class="card">
                                <div class="card-header">
                                    <h3 class="card-title" style="font-weight: bold;"> Payment Detail </h3>
                                </div>
                                <!-- /.card-header -->
                                <div class="card-body">
                                    <form>

                                        <div class="row">
                                            <label class="col-md-2"> Name </label>
                                            <div class="form-group col-md-3">
                                                <input class="form-control form-control-sm"
                                                    [(ngModel)]="paymentDTO.name"
                                                    [ngModelOptions]="{standalone: true}">
                                            </div>
                                        </div>

                                        <div class="row" id = "add_acc" style="display:none">
                                            <label class="col-md-2"> Account No </label>
                                                    <div class="form-group col-md-3 showInputField">
                                                        <input class="form-control form-control-sm" 
                                                            [(ngModel)]="paymentDTO.account_no" name="data[]"
                                                            [ngModelOptions]="{standalone: true}">
                                                    </div>
                                                    <div><button type="button" style="display:none" (click)="add()" class="btn btn-sm btn-primary">Add More</button></div> &nbsp;
                                                    <div><button style = "display: none" id = "btn-rem" type="button" (click)="minus()" class="btn btn-sm btn-danger">Remove</button></div>
                                                </div> 

                                        <div class="row" id = "edit_acc" style="display: none;">
                                            <label class="col-md-2"> Account No </label>
                                            <div>
                                            <div class="form-group col-lg-10" *ngFor="let item of paymentDTO.detailList">
                                                <div class="input-group">
                                                       
                                                        <input id = "{{ 'acc' + item.id }}" *ngIf= "item.status == 'ACTIVE'" class="form-control form-control-sm" style = "width : 290px"
                                                            [(ngModel)]="item.account_no"
                                                            [ngModelOptions]="{standalone: true}"> &nbsp;&nbsp;
                                                            <span class="input-group-btn">
                                                                <button style = "cursor: pointer" type="button" (click)="inactiveAcc(item.id,item.account_no)"  *ngIf= "item.status == 'ACTIVE'" class="btn btn-sm btn-primary">
                                                                <i class="fas fa-ban"></i>
                                                            </button>
                                                           </span>

                                                      <input id = "{{ 'acc' + item.id }}"  *ngIf= "item.status == 'INACTIVE'" class="form-control form-control-sm" style = "width : 290px;"
                                                            [(ngModel)]="item.account_no"
                                                            [ngModelOptions]="{standalone: true}" readonly> &nbsp;&nbsp;
                                                           
                                                     <input id = "{{ 'acc' + item.id }}"  class="form-control form-control-sm" style = "width : 290px;"
                                                            [(ngModel)]="item.account_no"
                                                            [ngModelOptions]="{standalone: true}"> &nbsp;&nbsp;    

                                                            <span class="input-group-btn">
                                                                <button style = "cursor: pointer" type="button" (click)="activeAcc(item.id)" *ngIf= "item.status == 'INACTIVE'" class="btn btn-sm btn-danger">
                                                                <i class="fas fa-ban"></i>
                                                            </button>
                                                           </span>
                                                 </div>
                                                    
                                               </div>
                                         </div>
                                        </div> 
                                       <!-- <div class="row">
                                            <label class="col-md-2"> Image Url </label>
                                            <div class="form-group col-md-3">
                                                <input class="form-control form-control-sm"
                                                    [(ngModel)]="paymentDTO.image_url"
                                                    [ngModelOptions]="{standalone: true}">
                                            </div>
                                        </div> -->
                                        <div class="row">
                                            <label class="col-md-2"> Payment Type</label>
                                            <div class="form-group col-md-3">
                                                <select
                                                    class="status form-control form-control-sm col-md-12 col-sm-4 col-xs-4"
                                                    [(ngModel)]="paymentDTO.payment_type"
                                                    [ngModelOptions]="{standalone: true}">
                                                    <option value="KBZ Pay" selected> KBZ Pay </option>
                                                    <option value="CB Pay"> CB Pay </option>
                                                    <option value="Wave Pay"> Wave Pay </option>
													<option value="Wave Shop"> Wave Shop </option>
                                                    <option value="AYA Pay"> AYA Pay </option>
                                                </select>
                                            </div>
                                        </div> 

                                        <div class="row">
                                            <label class="col-md-2">Type</label>
                                            <div class="form-group col-md-3">
                                                <select
                                                    class="status form-control form-control-sm col-md-12 col-sm-4 col-xs-4"
                                                    [(ngModel)]="paymentDTO.type"
                                                    [ngModelOptions]="{standalone: true}">
                                                    <option value="TOPUP" selected> TOPUP </option>
                                                    <option value="WITHDRAWAL"> WITHDRAWAL </option>
                                                </select>
                                            </div>
                                        </div>

                                        <div class="row">
                                            <label class="col-md-2"> Image URL </label>
                                            <div class="form-group col-md-3">
                                                <input #file type="file" accept='image/*'
                                                    (change)="preview(file.files)" /> 
                                                    <img [src]="imgURL" height="200" width="255" *ngIf="imgURL"
                                                    style="margin-top: 5%;">
                                            </div>
                                        </div> 

                                        <div class="row">
                                            <label class="col-md-2"> Bank Application Id </label>
                                            <div class="form-group col-md-3">
                                                <input class="form-control form-control-sm"
                                                    [(ngModel)]="paymentDTO.paymentAppId"
                                                    [ngModelOptions]="{standalone: true}">
                                            </div>
                                        </div>

                                        <div class="row">
                                            <label class="col-md-2"> Status </label>
                                            <div class="form-group col-md-3 radio">
                                                <div class="form-group clearfix">
                                                    <div class="icheck-success d-inline">
                                                        <input type="radio" name="r3" checked id="radioSuccess1"
                                                            value="ACTIVE" [(ngModel)]="paymentDTO.status"
                                                            [ngModelOptions]="{standalone: true}">
                                                        <label for="radioSuccess1">Active &nbsp;&nbsp;
                                                        </label>
                                                    </div>
                                                    <div class="icheck-success d-inline">
                                                        <input type="radio" name="r3" id="radioSuccess2"
                                                            value="INACTIVE" [(ngModel)]="paymentDTO.status"
                                                            [ngModelOptions]="{standalone: true}">
                                                        <label for="radioSuccess2">Inactive
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="row" style="margin-top: 1%;">
                                            <div class="col-md-2 col-sm-6">
                                                <button type="button" class="btn btn-block btn-success"
                                                    (click)="goSave()">Save</button>&nbsp;</div>
                                            <div class="col-md-2 col-sm-6">
                                                <button type="button" class="btn btn-block btn-danger"
                                                    [routerLink]="['/payment-list']"> Cancel </button></div>
                                        </div>
                                    </form>

                                </div>
                            </div>
                        </div>

                    </div>
                    
                    <div class="row" id="updatedata">
                        <div class="col-md-12">
                            <div class="card">
                                <div class="card-body">
                                    <form>
                                        <div class="row" style="margin-top: 2%;">
                                            <label class="col-md-2"> Created Date </label>
                                            <div class="form-group col-md-3">
                                                <input class="form-control form-control-sm"
                                                    [(ngModel)]="paymentDTO.created_date"
                                                    [ngModelOptions]="{standalone: true}" disabled>
                                            </div>
                                            <div class="col-md-1"></div>
                                            <label class="col-md-2"> Created By</label>
                                            <div class="col-md-3">
                                                <input class="form-control form-control-sm"
                                                    [(ngModel)]="paymentDTO.created_by_name"
                                                    [ngModelOptions]="{standalone: true}" disabled>
                                            </div>
                                        </div>

                                        <div class="row" style="margin-top: 1%;">
                                            <label class="col-md-2"> Updated Date </label>
                                            <div class="form-group col-md-3">
                                                <input class="form-control form-control-sm"
                                                    [(ngModel)]="paymentDTO.updated_date"
                                                    [ngModelOptions]="{standalone: true}" disabled>
                                            </div>
                                            <div class="col-md-1"></div>
                                            <label class="col-md-2"> Updated By </label>
                                            <div class="col-md-3">
                                                <input class="form-control form-control-sm"
                                                    [(ngModel)]="paymentDTO.updated_by_name"
                                                    [ngModelOptions]="{standalone: true}" disabled>
                                            </div>
                                        </div>

                                    </form>
                                </div>
                                <!-- /.card-body -->
                            </div>
                        </div>
                    </div>
                </form>
                <!-- /.row -->

                <!-- /.row -->
            </div>
            <!--/. container-fluid -->
        </section>
        <!-- /.content -->
    </div>

    <aside class="control-sidebar control-sidebar-dark">
        <!-- Control sidebar content goes here -->
    </aside>

    <app-footer></app-footer>

    <ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#fff" type="square-jelly-box">
        <p style="font-size: 16px; color: white">Loading...</p>
    </ngx-spinner>

</div>


<div id="deleteData" class="modal fade">
    <div class="modal-dialog">
        <div class="modal-content">

            <div class="modal-body">
                <form>

                    <div class="row" style="margin-top: 5%;margin-bottom: 3%;margin-left: 9%;">
                        <div class="form-group col-md-12">
                            <h5> This Account is used by another device. 
                                Please Sign Out. Login Again. </h5>
                        </div>
                    </div>

                    <div class="row" style="margin-left: 40%;margin-bottom: 3%;">
                        <div class="col-md-5 col-sm-6" id="deleteBtn">
                            <button type="button" class="btn btn-block btn-success" (click)="OkLogout()"> Ok </button>
                        </div>
                    </div>
                </form>
            </div>

        </div>
    </div>
</div>


<div id="inactiveData" class="modal fade">
    <div class="modal-dialog">
        <div class="modal-content">

            <div class="modal-body">
                <form>

                    <div class="row" style="margin-top: 5%;margin-bottom: 1%;margin-left: 15%;">
                        <div class="form-group col-md-12">
                            <h3> Are you sure want to De-active? </h3>
                        </div>
                    </div>

                    <div class="row" style="margin-top: 1%;">
                    <label class="col-md-4"> Account Number </label>
                    <div class="form-group col-md-6">
                      <input class="form-control form-control-sm" [(ngModel)]="displayAcc" [ngModelOptions]="{standalone: true}"
                        disabled>
                    </div>
                   </div>

                    <div class="row" style="margin-left: 10%;margin-bottom: 3%;">
                        <div class="col-md-5 col-sm-6" id="deleteBtn">
                            <button type="button" class="btn btn-block btn-success" (click)="inactiveOk()"> Ok </button>
                        </div>

                        <div class="col-md-5 col-sm-6">
                            <button type="button" class="btn btn-block btn-danger" [routerLink]="['/payment-list']"> Cancel </button>
                        </div>
                    </div>

                </form>
            </div>

        </div>
    </div>
</div>