<div class="wrapper">

  <app-header-sidebar></app-header-sidebar>

  <div class="content-wrapper">
    <div class="content-header">
      <div class="container-fluid">

      </div>
    </div>

    <section class="content">
      <div class="container-fluid">
        <form>
          <div class="row">

            <!-- <div class="form-row">
              <div class="form-group col-md-4">
                <label for="selectedPreset">Date Range</label>
                <div class="input-group custom-input-group">
                  <div class="input-group-prepend">
                    <span class="input-group-text" id="dateIcon">
                      <i class="fas fa-calendar-alt"></i>
                    </span>
                  </div>
                  <select class="form-control form-control-sm col-md-12" [(ngModel)]="selectedPreset"
                    [ngModelOptions]="{standalone: true}" (change)="onDateRangeChange()">
                    <option value="today">Today</option>
                    <option value="yesterday">Yesterday</option>
                    <option value="last7days">Last 7 Days</option>
                    <option value="last30days">Last 30 Days</option>
                    <option value="thisweek">This Week</option>
                    <option value="lastweek">Last Week</option>
                    <option value="thismonth">This Month</option>
                    <option value="lastmonth">Last Month</option>
                  </select>
                </div>
              </div>

               <div class="form-group col-md-4">
                <label for="startDate">From Date</label>
                <div class="input-group">
                  <input class="status form-control form-control-sm col-md-12" placeholder="yyyy-mm-dd" name="startDate"
                    [(ngModel)]="startDate">
                </div>
              </div>

              <div class="form-group col-md-4">
                <label for="endDate">To Date</label>
                <div class="input-group">
                  <input class="status form-control form-control-sm col-md-12" placeholder="yyyy-mm-dd" name="endDate"
                    [(ngModel)]="endDate">
                </div>
              </div>              
		

            </div>  -->
            

            <div class="form-row">
              <div class="form-group col-md-4">
                <label for="selectedPreset">Date Range</label>
                <div class="input-group custom-input-group">
                  <div class="input-group-prepend">
                    <span class="input-group-text" id="dateIcon">
                      <i class="fas fa-calendar-alt"></i>
                    </span>
                  </div>
                  <select class="form-control form-control-sm col-md-12" [(ngModel)]="selectedPreset"
                    [ngModelOptions]="{standalone: true}" (change)="onDateRangeChange()">
                    <option value="today">Today</option>
                    <option value="yesterday">Yesterday</option>
                    <option value="last7days">Last 7 Days</option>
                    <option value="last30days">Last 30 Days</option>
                    <option value="thisweek">This Week</option>
                    <option value="lastweek">Last Week</option>
                    <option value="thismonth">This Month</option>
                    <option value="lastmonth">Last Month</option>
                  </select>
                </div>
              </div>

              <div class="form-group col-md-4">
                <label for="startDate">From Date</label>
                <input id="startDate" readonly class="form-control form-control-sm" placeholder="Select Date"
                  [ngxDatePicker]="dateInstanceSingle" name="startDate" (valueChange)="onChangeSingle()">
                <ngx-date-picker #dateInstanceSingle></ngx-date-picker>
              </div>

              <div class="form-group col-md-4">
                <label for="endDate">To Date</label>
                <input id="endDate" readonly class="form-control form-control-sm" placeholder="Select Date"
                  [ngxDatePicker]="dateInstanceSingle1" name="endDate" (valueChange)="onChangeAllToDate()">
                <ngx-date-picker #dateInstanceSingle1></ngx-date-picker>
              </div>


            </div>

            <!-- old -->
            <!-- <div class="form-group col-md-2">
              <label>Admin Name</label>
              <select class="admin form-control form-control-sm col-md-12 col-sm-4 col-xs-4" [(ngModel)]="admin"
                [ngModelOptions]="{standalone: true}">
                <option *ngFor="let admin of admin_list" [value]="admin.name">{{ admin.name }}</option>
                <option value="" selected>All</option>
              </select>
            </div> -->


            <!-- <div class="form-group col-md-2">
              <label for="adminName">Admin Name</label>
              <input
                id="adminName"
                type="text"
                class="form-control form-control-sm"
                placeholder="Enter text here"
                [(ngModel)]="admin"
                (input)="filterMenuOptions_admin()"
                (focus)="initializeDropdown_admin()"
                (blur)="onInputBlur_admin()"
                [ngModelOptions]="{ standalone: true }">
              <div class="dropdown" *ngIf="showDropdown" (mousedown)="onDropdownMouseDown_admin($event)" (mouseover)="keepDropdownVisible_admin()" (mouseout)="hideDropdownWithDelay_admin()">
                <select
                  class="menu form-control form-control-sm"
                  size="10"
                  [(ngModel)]="admin.name"
                  [ngModelOptions]="{ standalone: true }">
                  <ng-container >
                    <optgroup>
                      <option *ngFor="let admin of admin_list" [value]="admin.name" (click)="selectAdmin(admin.name)" >{{ admin.name }}</option>
                      <option value="" selected>All</option>                      
                    </optgroup>
                  </ng-container>
                </select>
              </div>
            </div> -->

            <!-- <div class="form-group col-md-2">
              <label for="adminName">Admin Name</label>
              <input
                id="adminName"
                type="text"
                class="form-control form-control-sm"
                placeholder="Enter text here"
                [(ngModel)]="admin"
                (input)="filterMenuOptions_admin()"
                (focus)="initializeDropdown_admin()"
                (blur)="onInputBlur_admin()"
                [ngModelOptions]="{ standalone: true }">            
              <div class="dropdown" *ngIf="showDropdown_admin" 
                   (mousedown)="onDropdownMouseDown_admin($event)" 
                   (mouseover)="keepDropdownVisible_admin()" 
                   (mouseout)="hideDropdownWithDelay_admin()">
                <select
                  class="menu form-control form-control-sm"
                  size="10"
                  [(ngModel)]="admin.name"
                  (change)="selectAdmin($event.target.value)"
                  [ngModelOptions]="{ standalone: true }">
                  <optgroup>
                    <option *ngFor="let admin of filteredAdmins" [value]="admin.name" (click)="selectAdmin(admin.name)">{{admin.name}}</option>                    
                  </optgroup>
                </select>
              </div>
            </div> -->

              <div class="form-group col-md-2">
                <label class="align-label">Admin Name</label>
               
              <input class="form-control form-control-sm col-md-12 col-sm-4 col-xs-4"
                [(ngModel)]="adminName"
                (focus)="showDropdown()"
                (input)="filterOptions()"
                (blur)="hideDropdown()"
                placeholder="Type to search..." [ngModelOptions]="{standalone: true}">
                <div class="custom-dropdown" *ngIf="dropdownVisible">
                        <div class="dropdown-option" 
                            *ngFor="let admin of filteredAdminList"
                            (mousedown)="selectOption(admin.name)">
                          {{ admin.name }}
                        </div>
                        <div  class="dropdown-option" (mousedown)="selectOption('')">
                      <span>All</span>
                    </div>
            </div>

            </div>
            
          

            <!-- <div class="form-group col-md-2">
              <label for="menuName">Menu Name</label>
              <input
                id="menuName"
                type="text"
                class="form-control form-control-sm"
                placeholder="Enter text here"
                [(ngModel)]="subCategoryInput"
                (input)="filterMenuOptions_1()"
                (focus)="initializeDropdown()"
                (blur)="onInputBlur()"
                [ngModelOptions]="{ standalone: true }">
              <div class="dropdown" *ngIf="showDropdown" (mousedown)="onDropdownMouseDown($event)" (mouseover)="keepDropdownVisible()" (mouseout)="hideDropdownWithDelay()">
                <select
                  class="menu form-control form-control-sm"
                  size="10"
                  [(ngModel)]="subCategory"
                  [ngModelOptions]="{ standalone: true }">
                  <ng-container *ngFor="let category of filteredMenuCategories | keyvalue">
                    <optgroup [label]="category.key">
                      <option
                        *ngFor="let subCategory of category.value"
                        [value]="category.key + '->' + subCategory"  (mousedown)="selectSubCategory(category.key, subCategory, $event)">
                        {{ subCategory }}
                      </option>
                    </optgroup>
                  </ng-container>
                </select>
              </div>
            </div> -->


             <!-- Search Input Field -->
             <div class="form-group col-md-2">              
                  <label class="align-label">Menu Name</label>
                  <input class="form-control form-control-sm col-md-12 col-sm-4 col-xs-4"
                  [(ngModel)]="menuname"
                  (focus)="showDropdown1()"
                  (input)="findOption($event.target.value)"
                  (blur)="hideDropdown1()"
                  placeholder="Type to search..." [ngModelOptions]="{standalone: true}">
                  <div class="custom-dropdown" *ngIf="dropdownVisible1">
                    <div *ngFor="let firstLevel of filteredMenuMap | keyvalue">
                        <optgroup [label]="firstLevel.key">
                          <ng-container *ngFor="let secondLevel of firstLevel.value | keyvalue">
                            <ng-container *ngIf="secondLevel.value.size > 0; else singleOption">
                              <option class="dropdown-option" *ngFor="let thirdLevel of secondLevel.value" value="{{thirdLevel}}" (mousedown)="selectOption1(thirdLevel)">{{ thirdLevel }}</option>
                            </ng-container>
                            <ng-template #singleOption>
                              <option class="dropdown-option" value="{{secondLevel.key}}" (mousedown)="selectOption1(secondLevel.key)">{{ secondLevel.key }}</option>
                            </ng-template>
                          </ng-container>
                        </optgroup>
                    </div>
                    <option value="" (mousedown)="selectOption1('')" class="dropdown-option">
                      <span style="font-weight:bold;color:black">All</span></option>
                  </div>
            </div>

         
            
       

            <div class="form-group col-md-2 col-sm-4 col-xs-4">
              <label></label>
              <button id="search" type="button" class="btn btn-block btn-primary btn-sm" style="margin-top: 4%;"
                (click)="search()">Search</button>
            </div>



            <div class="col-md-12">
              <div class="card">
                <div class="card-header">
                  <h3 class="card-title" style="font-weight: bold;"> Admin Logs List </h3>
                </div>

                <div class="card-body">
                  <section class="content">
                    <div class="container-fluid">
                      <div class="log-container" *ngFor="let log of admin_nameList">

                        <div class="log-date border">
                          <h4>{{ log.date | date: 'MMMM d' }}</h4>
                        </div>

                        <div class="horizontal-line" style="padding: 0px;">

                        </div>

                        <div class="small-width" style="padding: 0px;">
                          <div class="log-entry log-entry-spacing" *ngFor="let entry of log.typeList">
                            <div class="log-category">
                              <h4 class="text-center">{{ entry.typeTitle }}</h4>

                            </div>
                            <!-- <div class="log-details">
                              <div class="log-item" *ngFor="let item of entry.typeDetail">
                                <div class="log-time">{{ item.time }}</div>
                                <div class="log-admin">{{ item.adminName }}</div>
                                <div class="log-action" *ngIf="item.menuDetail.smallChildMenu">
                                  {{item.menuDetail.parentMenu}} -> {{item.menuDetail.childMenu}} ->
                                  {{item.menuDetail.grandChildMenu}} ->
                                  <a [routerLink]="[item.link]">{{item.menuDetail.smallChildMenu}}</a> ->
                                  {{item.menuDetail.action}}
                                </div>

                                <div class="log-action"
                                  *ngIf="item.menuDetail.grandChildMenu && !item.menuDetail.smallChildMenu">
                                  {{item.menuDetail.parentMenu}} -> {{item.menuDetail.childMenu}} ->
                                  <a [routerLink]="[item.link]"> {{item.menuDetail.grandChildMenu}}</a> ->
                                  {{item.menuDetail.action}}
                                </div>

                                <div class="log-action"
                                  *ngIf="!item.menuDetail.grandChildMenu && !item.menuDetail.smallChildMenu && item.menuDetail.childMenu">
                                  {{item.menuDetail.parentMenu}} ->
                                  <a [routerLink]="[item.link]"> {{item.menuDetail.childMenu}}</a> ->
                                  {{item.menuDetail.action}}
                                </div>
                              </div>
                            </div> -->


                            <div class="log-details">
                              <div class="log-item" *ngFor="let item of entry.typeDetail">
                                <div class="log-time">{{ item.time }}</div>
                                <div class="log-admin">{{ item.adminName }}</div>

                                <div class="log-action" *ngIf="item.menuDetail.smallChildMenu && item.menuDetail.childMenu == 'Daily New Registration'">
                                  {{item.menuDetail.parentMenu}} -> 
                                  <a [routerLink]="['/user-detail', item.entityId]">{{item.menuDetail.childMenu}} ({{ item.entityId }}) </a> ->
                                  {{item.menuDetail.grandChildMenu}} ->
                                  {{item.menuDetail.smallChildMenu}} ->
                                  {{item.menuDetail.action}}
                                </div>
                                
                                <div class="log-action" *ngIf="item.menuDetail.smallChildMenu && item.menuDetail.childMenu != 'Daily New Registration'">
                                  {{item.menuDetail.parentMenu}} -> {{item.menuDetail.childMenu}} ->
                                  {{item.menuDetail.grandChildMenu}} ->
                                  <a [routerLink]="[item.link]">{{item.menuDetail.smallChildMenu}}</a> ->
                                  {{item.menuDetail.action}}
                                </div>

                                <div class="log-action"
                                  *ngIf="item.menuDetail.grandChildMenu && !item.menuDetail.smallChildMenu">
                                  {{item.menuDetail.parentMenu}} -> {{item.menuDetail.childMenu}} ->
                                  <a [routerLink]="[item.link]"> {{item.menuDetail.grandChildMenu}}</a> ->
                                  {{item.menuDetail.action}}
                                </div>

                                <div class="log-action"
                                  *ngIf="!item.menuDetail.grandChildMenu && !item.menuDetail.smallChildMenu && item.menuDetail.childMenu">
                                  {{item.menuDetail.parentMenu}} ->

                                  <a  *ngIf="item.entityId !== 0 && item.menuDetail.childMenu === 'Top-up'" [routerLink]="['/topup-check-detail-update', item.entityId]"> {{item.menuDetail.childMenu}} <span *ngIf="item.entityId !== 0"> ({{ item.entityId }}) </span> </a> 

                                  <a  *ngIf="item.entityId === 0 && item.menuDetail.childMenu === 'Top-up'" [routerLink]="[item.link]"> {{item.menuDetail.childMenu}}</a> 

                                  <a  *ngIf="item.entityId !== 0 && item.menuDetail.childMenu === 'Withdraw'" [routerLink]="['/withdraw-check-detail', item.entityId]"> {{item.menuDetail.childMenu}} <span *ngIf="item.entityId !== 0"> ({{ item.entityId }}) </span> </a>    

                                  <a  *ngIf="item.entityId === 0 && item.menuDetail.childMenu === 'Withdraw'" [routerLink]="[item.link]"> {{item.menuDetail.childMenu}} </a> 

                                                                   
                                  <a  *ngIf="item.entityId !== 0 && item.menuDetail.childMenu === 'Daily New Registration'" [routerLink]="['/user-detail', item.entityId]"> {{item.menuDetail.childMenu}} <span *ngIf="item.entityId !== 0"> ({{ item.entityId }}) </span> </a> 
                                  
                                  

                                  <a *ngIf="item.entityId === 0 && item.menuDetail.childMenu !== 'Withdraw' && item.menuDetail.childMenu !== 'Top-up' && item.menuDetail.childMenu !== 'Daily New Registration'" [routerLink]="[item.link]">{{item.menuDetail.childMenu}} 
                                  </a>
                                  ->
                                  
                                  {{item.menuDetail.action}}
                                </div>
                              </div>
                            </div>




                          </div>
                        </div>


                        
                      </div>
                    </div>
                  </section>








                </div>

              </div>
            </div>

          </div>
        </form>

      </div>

    </section>

  </div>

  <aside class="control-sidebar control-sidebar-dark">

  </aside>

  <app-footer></app-footer>

  <ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#fff" type="square-jelly-box">
    <p style="font-size: 16px; color: white">Loading...</p>
  </ngx-spinner>

</div>
<div id="deleteData" class="modal fade">
  <div class="modal-dialog">
    <div class="modal-content">

      <div class="modal-body">
        <form>

          <div class="row" style="margin-top: 5%;margin-bottom: 3%;margin-left: 9%;">
            <div class="form-group col-md-12">
              <h5> This Account is used by another device.
                Please Sign Out. Login Again. </h5>
            </div>
          </div>

          <div class="row" style="margin-left: 40%;margin-bottom: 3%;">
            <div class="col-md-5 col-sm-6" id="deleteBtn">
              <button type="button" class="btn btn-block btn-success" (click)="OkLogout()"> Ok </button>
            </div>
          </div>
        </form>
      </div>

    </div>
  </div>
</div>




