
      
        <section class="content">
            <div class="container-fluid">
                <form>
                    <div class="row">
                        <div class="form-group col-md-2 col-sm-4 col-xs-4" *ngIf="false">
                            <label></label>
                            <button type="button" class="btn btn-block btn-success btn-sm" style="margin-top: 4%;"
                                [routerLink]="['/bank-maintenance-withdraw-detail/0']">
                                Add New
                            </button>
                        </div>

                        <div class="col-md-12">
                            <div class="card">
                                <div class="card-header">
                                    <h3 class="card-title" style="font-weight: bold;"> Bank Maintenance Withdraw List </h3>
                                </div>
                                <!-- /.card-header -->
                                <div class="card-body">
                                    <table width='100%' id="{{'tblpayment' + idIndex}}" datatable
                                        [dtTrigger]="dtTrigger" [dtOptions]="dtOptions"
                                        class="table table-bordered table-hover">
                                        <thead>
                                            <tr>
                                                <th style="font-family: unicode;">Bank Name</th>
                                                <th style="font-family: unicode;">Description English</th> 
                                                <th style="font-family: unicode;">Description Myanmar</th>
                                                <th style="font-family: unicode;">Description Thailand</th>
                                                <th style="font-family: unicode;">Description Chinese</th>
                                                <th style="font-family: unicode;">From Date</th>
                                                <th style="font-family: unicode;">To Date</th>
                                                <th style="font-family: unicode;">Is Maintain</th>
                                                <th style="font-family: unicode;" *ngIf="hardcode_edit_bool === true">Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr *ngFor="let maintenance of maintenanceList">
                                                <td style="font-family: unicode;">{{maintenance.bank_type}}</td>
                                                <td style="font-family: unicode;">{{maintenance.description_en}}</td>
                                                <td style="font-family: unicode;">{{maintenance.description_my}}</td> 
                                                <td style="font-family: unicode;">{{maintenance.description_th}}</td>
                                                <td style="font-family: unicode;">{{maintenance.description_zh}}</td>
                                                <td style="font-family: unicode;">{{maintenance.fromTime | date:'yyyy-MM-dd HH:mm:ss'}}</td>
                                                <td style="font-family: unicode;">{{maintenance.toTime | date:'yyyy-MM-dd HH:mm:ss'}}</td>
                                                <td style="font-family: unicode;">{{maintenance.is_maintain}}</td>
                                                <td style="font-family: unicode;" *ngIf="hardcode_edit_bool === true">
                                                    <!-- <a [routerLink]="['/bank-maintenance-withdraw-detail', maintenance.id]">
                                                        <i  title = "Edit" class="fas fa-edit" style="color:#4b88eb;cursor: pointer;"></i>
                                                    </a> -->
                                                    <a (click)="addStepAndNavigate(maintenance)">
                                                      <i  title = "Edit" class="fas fa-edit" style="color:#4b88eb;cursor: pointer;"></i>
                                                  </a>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <!-- /.card-body -->
                            </div>
                        </div>
                    </div>
                </form>
                <!-- /.row -->
                <!-- /.row -->
            </div>
            <!--/. container-fluid -->
        </section>
      

    <ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#fff" type="square-jelly-box">
        <p style="font-size: 16px; color: white">Loading...</p>
    </ngx-spinner>



<div id="browseAccountData" class="modal fade">
    <div class="modal-dialog">
      <div class="modal-content" style="width: 600px;">
        <div class="modal-header">
          <h3 class="modal-title" style="font-weight: bold;font-size: 1.1rem;"> Bank Account Detail </h3>
          <button type="button" class="close" data-dismiss="modal" aria-hidden="true"> x </button>
        </div>
        <div class="modal-body">
          <form>
            <div class="col-md-12">
              <div class="row" style="margin-left: 0.1%;">
                    <label class="col-md-2"> Name </label>
                    <div class="form-group col-md-3">
                    <input class="form-control form-control-sm" [(ngModel)]="name" [ngModelOptions]="{standalone: true}"
                        disabled>
                    </div>
              </div>
              <div class="col-md-12">
                <div class="card">
                  <div class="card-body"  style = "width : 100%">
                    <table width='100%' id="{{'tbltwodbetdetail' + idIndex2}}" datatable [dtTrigger]="dtTrigger2"
                      [dtOptions]="dtOptions2" class="table table-bordered table-hover">
                      <thead>
                        <tr>
                          <th style="font-family: unicode;">No</th>
                          <th style="font-family: unicode;">Account Number</th>
                          <th style="font-family: unicode;">Status</th>
                          <th style="font-family: unicode;">Order Number</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr *ngFor="let temp of paymentTempList, let i = index">
                          <td style="font-family: unicode; padding: 0.35rem 0.75rem 0.30rem 0.75rem;">{{ i + 1 }}</td>
                          <td style="font-family: unicode; padding: 0.35rem 0.75rem 0.30rem 0.75rem;">{{temp.account_no}}
                          <td style="font-family: unicode; padding: 0.35rem 0.75rem 0.30rem 0.75rem;">{{temp.status}}
                        <td style="font-family: unicode; padding: 0.35rem 0.75rem 0.30rem 0.75rem;">{{temp.order_id}}

                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
  
            </div>
  
          </form>
        </div>
  
    </div>
  </div>


  <div id="deleteData" class="modal fade">
    <div class="modal-dialog">
        <div class="modal-content">

            <div class="modal-body">
                <form>

                    <div class="row" style="margin-top: 5%;margin-bottom: 3%;margin-left: 9%;">
                        <div class="form-group col-md-12">
                            <h5> This Account is used by another device. 
                                Please Sign Out. Login Again. </h5>
                        </div>
                    </div>

                    <div class="row" style="margin-left: 40%;margin-bottom: 3%;">
                        <div class="col-md-5 col-sm-6" id="deleteBtn">
                            <button type="button" class="btn btn-block btn-success" (click)="OkLogout()"> Ok </button>
                        </div>
                    </div>
                </form>
            </div>

        </div>
    </div>
</div>