import { Component, OnInit, ViewChild } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,HttpErrorResponse } from '@angular/common/http';
import { Router, NavigationEnd } from '@angular/router';
import { DataTableDirective } from 'angular-datatables';

import { LocalStorageService } from 'ngx-webstorage';
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from 'ngx-toastr';

import { DtoService } from '../../service/dto.service';
import { FunctService } from '../../service/funct.service';

import { catchError, retry } from 'rxjs/operators';
import { throwError } from 'rxjs';

import { Subject } from 'rxjs';
import Responsive from 'datatables.net-responsive'; 
import { AnimationKeyframesSequenceMetadata } from '@angular/animations';
import { ThrowStmt } from '@angular/compiler';
declare var $: any;


@Component({
  selector: 'app-game',
  templateUrl: './game-maintain-all.component.html',
  styleUrls: ['./game-maintain-all.component.css']
})
export class GameMaintainAllComponent implements OnInit {

  @ViewChild(DataTableDirective)
  dtElement: DataTableDirective;
  dtOptions: DataTables.Settings = {};
  dtOptions2 : DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();
  dtTrigger2 : Subject<any> = new Subject();
  providerName: string = '';
  gameCode: string = '';
  type: string = '';
  status: string = '';
  gamealertList: any;
  idIndex: any;
  token: any;
  idIndex2 : any;
  pid : any;
  paymentTemp : any;
  paymentTempList : any;
  name : any;
  desEng :any;
  desMM:any;
  desThai:any;
  desZh:any;
  statusDetail:any;
  decisionDetail:any;
   //NotSend:any;
   NotSend:any;
  gameMaintainId:any;
  fromhours:any;
  fromminutes:any;
  tohours:any;
  tominutes:any;

  singleDate: any;
  alldate: any;
  alltoDate: any;
  alltodate:any;
  alltodayDate: any;  
  alltodaytodate: any;

  hardcode_edit :any;
  hardcode_add :any;
  hardcode_view: any;
  hardcode_delete:any;
  hardcode_view_bool : any;
  hardcode_add_bool : any;
  hardcode_edit_bool : any;
  hardcode_delete_bool:any;
  hardcode_double_bool:any;
  status_original :any;

constructor(private storage: LocalStorageService, private spinner: NgxSpinnerService, private http: HttpClient, private dto: DtoService,
    private router: Router, private funct: FunctService, private toastr: ToastrService,) {
      this.storage.store('isNotiSong', "");
    this.idIndex = 1; 

      this.hardcode_view = "GameMaintainAll_View";
      this.hardcode_edit = "GameMaintainAll_Edit";

      this.dto.menuCodes = this.storage.retrieve('menucodes');
      const filteredMenuCodes_view = [];    
      const filteredMenuCodeds_edit =[];
  
      this.dto.menuCodes.forEach(menuCode => {
        if (menuCode === this.hardcode_view) {
          filteredMenuCodes_view.push(menuCode);
        }      
        if (menuCode === this.hardcode_edit) {
          filteredMenuCodeds_edit.push(menuCode);
        }     
        
      });
      if(filteredMenuCodes_view.length != 0)
      {
            this.hardcode_view_bool = true;
      }   
      if(filteredMenuCodeds_edit.length != 0)
      {
            this.hardcode_edit_bool = true;
      }
    

    this.search();
  }

  ngOnInit(): void {

    this.router.routeReuseStrategy.shouldReuseRoute = function () {
      return false;
    };

    this.router.events.subscribe((evt) => {
      if (evt instanceof NavigationEnd) {        
        this.router.navigated = false;        
        window.scrollTo(0, 0);
      }
    });
    this.dtOptions = {
      responsive: {
        details: {
        renderer: Responsive.renderer.listHiddenNodes()
    }
  },
      order: []
    }
    if (!this.storage.retrieve('loadFlag')) {
      this.storage.store('loadFlag', 'noLoad');
      setTimeout(function () {
        location.reload();
      }, 5);
    }
    else {
      this.storage.clear('loadFlag');
    }
  }
  handleError(error: HttpErrorResponse){
    if(error.status == 423)
    {
      this.spinner.hide();
      $("#deleteData").modal("show");
    }
    if(error.status == 403)
    {
      this.spinner.hide();
      var id = 'tblalert' + this.idIndex;
      var table = $('#' + id).DataTable();
      this.toastr.error("Limited Access.", 'Invalid!', {
        timeOut: 3000,
        positionClass: 'toast-top-right',
        });
    }
    return throwError(error);
    }
    OkLogout()
    {
      window.location.href ="./ad-login";
    } 
  goDetail(val){
    this.gameMaintainId=val;
    this.dto.token = this.storage.retrieve('token');
    let headers = new HttpHeaders();
    headers = headers.set('Authorization',  this.dto.token);
    let params = new HttpParams();
    params = params.set('id', val);
    this.http.get(this.funct.ipaddress + 'GameMaintainAll/GetGameMaintainAllById', {params, headers: headers })
    .pipe(
      catchError(this.handleError.bind(this))
     )
    .subscribe(
      result => {
        this.dto.Response = {};
        this.dto.Response = result;
        this.desEng = this.dto.Response.description_en;
        this.desMM = this.dto.Response.description_my;
        this.desThai = this.dto.Response.description_th;
        this.desZh = this.dto.Response.description_zh;
        this.statusDetail = this.dto.Response.status;
        this.decisionDetail = this.dto.Response.decision;
        this.NotSend = this.dto.Response.notSend;

        if(this.dto.Response.fromTime =='' || this.dto.Response.fromTime ==undefined || this.dto.Response.fromTime ==null)
        {
          this.alldate = new Date();
          this.alltodate = new Date();          
        }
        else
        {
          this.alldate = this.dto.Response.fromTime.split(" ")[0];
          //this.alldate = new Date(this.alldate); 
          this.alldate = new Date();         
          this.fromhours = this.dto.Response.fromTime.split(" ")[1].split(":")[0];
          this.fromminutes = this.dto.Response.fromTime.split(" ")[1].split(":")[1];
          this.alltodate = this.dto.Response.toTime.split(" ")[0];
          //this.alltodate = new Date(this.alltodate);  
          this.alltodate = new Date();         
          this.tohours = this.dto.Response.toTime.split(" ")[1].split(":")[0];
          this.tominutes = this.dto.Response.toTime.split(" ")[1].split(":")[1];         
        } 
        this.status_original = this.dto.Response.status;

        // this.fromhours = this.dto.Response.fromTime.split(":")[0];
        // this.fromminutes = this.dto.Response.fromTime.split(":")[1];
        // this.tohours = this.dto.Response.toTime.split(":")[0];
        // this.tominutes = this.dto.Response.toTime.split(":")[1];
      });
    $('#editGameMaintainAll').modal("show");
  }

  goSave()
  {
    if(this.desEng == null || this.desEng == undefined || this.desEng =="")
    {
      this.toastr.error("Please enter name (English)", 'Invalid!', {
        timeOut: 3000,
        positionClass: 'toast-top-right',
      });
      return;
    }
  this.spinner.show();
  this.token = this.storage.retrieve('token');
  // let headers = new HttpHeaders();
  // headers = headers.set('Authorization',  this.token);

  let editedArray = this.getEditedArray();   
  let headers = new HttpHeaders();

  if(editedArray.length > 0)
  {
    headers = headers.set('Authorization', this.token)
    .set('editedArray', editedArray); 
  }
  else{
    headers = headers.set('Authorization',  this.token);
  }

  //amk
  if( this.alldate == 'undefined' || this.alldate == null)
  {
           this.alldate = this.alltodayDate;
            var fdate = new Date(this.alldate),
            mnth = ("0" + (fdate.getMonth() + 1)).slice(-2),
            day = ("0" + fdate.getDate()).slice(-2);     
            var fromDateTime = '';             
            this.alldate =  [day, mnth, fdate.getFullYear()].join("/");                
   }
   else
    {                      
            var fdate = new Date(this.alldate),
            mnth = ("0" + (fdate.getMonth() + 1)).slice(-2),
            day = ("0" + fdate.getDate()).slice(-2);     
            var fromDateTime = '';
            this.alldate =  [fdate.getFullYear(), mnth, day].join("/");            
    }          
    if(this.alltodate == 'undefined' || this.alltodate == null)
    {
            this.alltodate = this.alltodayDate;          
            var tdate = new Date(this.alltodate),
            mnth = ("0" + (tdate.getMonth() + 1)).slice(-2),
            day = ("0" + tdate.getDate()).slice(-2);     
            var toDateTime = '';
            this.alltodate  =  [fdate.getFullYear(), mnth, day].join("/");             
    }
    else
    {                          
            var tdate = new Date(this.alltodate),
            mnth = ("0" + (tdate.getMonth() + 1)).slice(-2),
            day = ("0" + tdate.getDate()).slice(-2);     
            var toDateTime = '';
            this.alltodate =  [tdate.getFullYear(), mnth, day].join("/");            
    }
    // if( this.NotSend =="true" )
    // {
    //      this.NotSend = true;
    // }
    // else
    // {
    //   this.NotSend = false;
    // }
  
  //
  let param = {
    id:this.gameMaintainId,
    description_en:this.desEng,
    description_my:this.desMM,
    description_th:this.desThai,
    description_zh:this.desZh,
    status:this.statusDetail,
    decision : this.decisionDetail,
    NotSend : this.NotSend,

    fromTime: this.alldate + ' ' + this.fromhours+':'+this.fromminutes,
    toTime: this.alltodate + ' ' +  this.tohours+':'+this.tominutes
  };
  this.http.post(this.funct.ipaddress + 'GameMaintainAll/UpdateGameMaintainAllNew', param, { headers: headers })//UpdateGameMaintainAll
  .pipe(
    catchError(this.handleError.bind(this))
   )
  .subscribe( 
    result => {
      this.dto.Response = {};
      this.dto.Response = result;
      if (this.dto.Response) {
        this.spinner.hide();
        $('#editGameMaintainAll').modal("hide");
        this.toastr.success(this.dto.Response.message, 'Success!', {
          timeOut: 3000,
          positionClass: 'toast-top-right'
        });
      }
      else {
        this.spinner.hide();
        this.toastr.error(this.dto.Response.message, 'Invalid!', {
          timeOut: 3000,
          positionClass: 'toast-top-right',
        });
      }
    }); 
  }
  search() {
    
    var id = 'tblalert' + this.idIndex;
    var table = $('#' + id).DataTable();
    table.destroy();
    this.idIndex = this.idIndex + 1;
    this.spinner.show();
    this.dto.token = this.storage.retrieve('token');
    let headers = new HttpHeaders();
    headers = headers.set('Authorization',  this.dto.token);
    let params = new HttpParams();
    params = params.set('status', this.status);
    this.gamealertList = [];
    this.http.get(this.funct.ipaddress + 'GameMaintainAll/GetGameMaintainAll', {headers: headers })
    .pipe(
      catchError(this.handleError.bind(this))
     )
    .subscribe(
      result => {
        this.dto.Response = {};
        this.dto.Response = result;
        this.gamealertList = this.dto.Response;
        this.dtTrigger.next();
        this.spinner.hide();
      });
  }

  numericOnly(event): boolean { 
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode == 101 || charCode == 69 || charCode == 45 || charCode == 43 || charCode == 46) {
      return false;
    }
    return true;  
  }

  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }

  onChangeSingle() {
    $(document).ready(function () { 
      this.alldate = $("#alldate").val();
    });
  }
  onChangeAllToDate() {
    $(document).ready(function () {
      this.alltodate = $("#alltodate").val();
    });
  }

  
  getEditedArray() {
    let editedArray = ''; 
  
    if (this.statusDetail !== this.status_original) {
      editedArray += 'Status: ' + this.status_original + ' -> ' + this.statusDetail + ', ';
    }
    if (editedArray.endsWith(', ')) {
      editedArray = editedArray.slice(0, -2);
    }
    
    return editedArray;
  }
  

}
