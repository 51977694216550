import { Component, OnInit, ViewChild } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,HttpErrorResponse } from '@angular/common/http';
import { DataTableDirective } from 'angular-datatables';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { DatePipe } from '@angular/common'

import { LocalStorageService } from 'ngx-webstorage';
import { NgxSpinnerService } from "ngx-spinner";

import { DtoService } from '../../service/dto.service';
import { FunctService } from '../../service/funct.service';

import { catchError, retry } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import Responsive from 'datatables.net-responsive'; /*for responsive not working event datatable */



import { Subject } from 'rxjs';
declare var $: any;

@Component({
  selector: 'app-topup-check',
  templateUrl: './topup-check.component.html',
  styleUrls: ['./topup-check.component.css']
})
export class TopupCheckComponent implements OnInit {

  @ViewChild(DataTableDirective)
  dtElement: DataTableDirective;
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();

  dtElement1: DataTableDirective;
  dtOptions1: DataTables.Settings = {};
  dtTrigger1: Subject<any> = new Subject();

  dtElement2: DataTableDirective;
  dtOptions2: DataTables.Settings = {};
  dtTrigger2: Subject<any> = new Subject();

  dtElement3: DataTableDirective;
  dtOptions3: DataTables.Settings = {};
  dtTrigger3: Subject<any> = new Subject();

  dtElement4: DataTableDirective;
  dtOptions4: DataTables.Settings = {};
  dtTrigger4: Subject<any> = new Subject();

  dtElementacc: DataTableDirective;
  dtOptionsacc: DataTables.Settings = {};
  dtTriggeracc: Subject<any> = new Subject();


  singleDate: string = '';
  approveDate: string = '';
  addedDate: string = '';

  alltoDate: string = ''; //add this
  alltodate : any;

  pendingDate: string = '';
  deniedDate: string = '';
  status: string = '';
  accountNo: string ;
  approveaccountNo: string ;
  addedaccountNo: string ;
  pendingaccountNo: string ;
  deniedaccountNo: string ;
  topupallList: any;
  topupapproveList: any;
  topupaddList: any;
  topuppengingList: any;
  topupdeniedList: any;
  idIndex: any;
  idapproveIndex: any;
  idaddIndex: any;
  idpendingIndex: any;
  iddeniedIndex: any;
  topupId: any;
  approvedate: any;
  addeddate: any;

  todate : any; //add this
  approvetodate :any;
  approvetoDate : any;
  addedtoDate : any;
  addedtodate : any;
  pendingtodate : any;
  pendingtoDate : any;
  deniedtodate : any;
  deniedtoDate : any;
  
  alltodaytodate : any;
  approvetodaytodate : any;
  addedtodaytodate : any;
  pendingtodaytodate : any;
  deniedtodaytodate : any;
   
  alltodatechangeDate : any; //add this
  approvetodatechangeDate : any;
  pendingtodatechangeDate : any;
  deniedtodatechangeDate : any;
  addedtodatechangeDate : any;
  
  pendingdate: any;
  denieddate: any;
  alldate: any;

  approvetodayDate: any;
  addtodayDate: any;
  pendingtodayDate: any;
  deniedtodayDate: any;
  alltodayDate: any;

  approvechangeDate: any;
  addedchangeDate: any;
  pendingchangeDate: any;
  deniedchangeDate: any;
  allchangeDate: any;

  tranNo2 : any;
  totalAmount2 : any;
  admin_name2 : any;
  added_date2 : any;
  accountDetailList : any;
  total_today_reach_amt : any;
  total_my_amount :any;
  accidIndex : any;
  gameproviderList : any;
  handelAccounts : any;
  transactiontype :any;
  totalItems : any; 
  itemsPerPage =  10;
  page = 1;

  hardcode_edit :any;
  hardcode_add :any;
  hardcode_view: any;
  hardcode_view_bool : any; 
  hardcode_edit_bool : any;
  hardcode_add_bool :any;
  param1:any;

  orderby:string='';
  ordermethod:string='';
  orderbypending:string='';
  ordermethodpending:string='';
  orderbyadd:string='';
  ordermethodadd:string='';
  orderbyapproved:string='';
  ordermethodapproved:string='';
  orderbydenied:string='';
  ordermethoddenied:string='';
  type:any;
  // handelAccounts: string | null = '';

  constructor(private storage: LocalStorageService,private spinner: NgxSpinnerService, private dto: DtoService, private http: HttpClient, private router: Router,
    private route: ActivatedRoute, private funct: FunctService,private toastr: ToastrService, private datepipe: DatePipe) { 
      this.storage.store('isNotiSong', "");
    this.idIndex = 1;
    this.idapproveIndex = 1;
    this.idaddIndex = 1;
    this.idpendingIndex = 1;
    this.iddeniedIndex = 1;
    this.accidIndex = 1;

    this.hardcode_view = "Topup_View";
    this.hardcode_edit = "Topup_Edit";
    this.hardcode_add = "Topup_Add";
    this.dto.menuCodes = this.storage.retrieve('menucodes');

    const filteredMenuCodes_view = [];    
    const filteredMenuCodeds_edit =[];
    const filteredMenuCodeds_add =[];
    this.dto.menuCodes.forEach(menuCode => {
      if (menuCode === this.hardcode_view) {
        filteredMenuCodes_view.push(menuCode);
      }     
      if (menuCode === this.hardcode_edit) {
        filteredMenuCodeds_edit.push(menuCode);
      }
      if (menuCode === this.hardcode_add) {
        filteredMenuCodeds_add.push(menuCode);
      }
    });
        if(filteredMenuCodes_view.length != 0)
        {
              this.hardcode_view_bool = true;
        }       
        if(filteredMenuCodeds_edit.length != 0)
        {
              this.hardcode_edit_bool = true;
        }
        if(filteredMenuCodeds_add.length != 0)
        {
              this.hardcode_add_bool = true;
        }


        this.accountNo = '';
        this.pendingaccountNo = '';
        this.addedaccountNo = '';
        this.deniedaccountNo = '';
    if (!this.storage.retrieve('ALLTagTopupFromDate')) 
    {
      this.alldate = new Date();
      this.alltodayDate = this.datepipe.transform(this.alldate, 'MMM dd, yyyy');      
    }
    else 
    {     
      this.alldate = new Date(this.storage.retrieve('ALLTagTopupFromDate'));
      this.alltodayDate = this.datepipe.transform(this.alldate, 'MMM dd, yyyy');
    }
    
    if (!this.storage.retrieve('PendingTagTopupFromDate')) {
      this.pendingdate = new Date();
      console.log("this.pendingdate>> " + this.pendingdate);
      this.pendingtodayDate = this.datepipe.transform(this.pendingdate, 'MMM dd, yyyy');
      console.log("this.pendingtodayDate>> " + this.pendingtodayDate);
    }
    else {      
      this.pendingdate = new Date(this.storage.retrieve('PendingTagTopupFromDate'));
      this.pendingtodayDate = this.datepipe.transform(this.pendingdate, 'MMM dd, yyyy');
    } 
    if (!this.storage.retrieve('CheckedTagTopupFromDate')) 
      {
        this.addeddate = new Date();     
        this.addtodayDate = this.datepipe.transform(this.addeddate, 'MMM dd, yyyy');
    
      }
      else {
        this.addeddate= new Date();
        this.addtodayDate = this.datepipe.transform(this.addeddate, 'MMM dd, yyyy');//amk
        // this.addeddate = new Date(this.storage.retrieve('CheckedTagTopupFromDate'));
        // this.addtodayDate = this.datepipe.transform(this.addeddate, 'MMM dd, yyyy');
      }  

    if (!this.storage.retrieve('ApprovedTagTopupFromDate')) {
      this.approvedate = new Date();
      console.log("this.approvedate>> " + this.approvedate);
      this.approvetodayDate = this.datepipe.transform(this.approvedate, 'MMM dd, yyyy');
      console.log("this.todayDate>> " + this.approvetodayDate);
    }
    else {
      //this.approvedate= new Date();
      //this.approvetodayDate = this.datepipe.transform(this.approvedate, 'MMM dd, yyyy');//amk
      this.approvedate = new Date(this.storage.retrieve('ApprovedTagTopupFromDate'));
      this.approvetodayDate = this.datepipe.transform(this.approvedate, 'MMM dd, yyyy');
    }  

    if (!this.storage.retrieve('DeniedTagTopupFromDate')) {
      this.denieddate = new Date();
      console.log("this.denieddate>> " + this.denieddate);
      this.deniedtodayDate = this.datepipe.transform(this.denieddate, 'MMM dd, yyyy');
      console.log("this.deniedtodayDate>> " + this.deniedtodayDate);
    }
    else {
      //this.denieddate= new Date();
      //this.deniedtodayDate = this.datepipe.transform(this.denieddate, 'MMM dd, yyyy');//amk
      this.denieddate = new Date(this.storage.retrieve('DeniedTagTopupFromDate'));
      this.deniedtodayDate = this.datepipe.transform(this.denieddate, 'MMM dd, yyyy');
    } 

    // ------------

    if (!this.storage.retrieve('ALLTagTopupToDate')) {
      this.alltodate = new Date();  //add this
      console.log("this.allltodate>> " + this.alltodate);
      this.alltodaytodate = this.datepipe.transform(this.alltodate, 'MMM dd, yyyy');
    }
    else {
      //this.alltodate= new Date();
      //this.alltodaytodate = this.datepipe.transform(this.alltodate, 'MMM dd, yyyy');//amk
      this.alltodate = new Date(this.storage.retrieve('ALLTagTopupToDate'));
      this.alltodaytodate = this.datepipe.transform(this.alltodate, 'MMM dd, yyyy');
    } 

    if (!this.storage.retrieve('PendingTagTopupToDate')) {
      this.pendingtodate = new Date();  //add this
      console.log("this.pendingtodate>> " + this.pendingtodate);
      this.pendingtodaytodate = this.datepipe.transform(this.pendingtodate, 'MMM dd, yyyy');
    }
    else {
      //this.pendingtodate= new Date();
      //this.pendingtodaytodate = this.datepipe.transform(this.pendingtodate, 'MMM dd, yyyy');//amk
      this.pendingtodate = new Date(this.storage.retrieve('PendingTagTopupToDate'));
      this.pendingtodaytodate = this.datepipe.transform(this.pendingtodate, 'MMM dd, yyyy');
    } 

    if (!this.storage.retrieve('CheckedTagTopupToDate')) {
      this.addedtodate = new Date();  //add this
      console.log("this.addedtodate>> " + this.addedtodate);
      this.addedtodaytodate = this.datepipe.transform(this.addedtodate, 'MMM dd, yyyy');
    }
    else {
      this.addedtodate= new Date();
      this.addedtodaytodate = this.datepipe.transform(this.addedtodate, 'MMM dd, yyyy');//amk
      // this.addedtodate = new Date(this.storage.retrieve('CheckedTagTopupToDate'));
      // this.addedtodaytodate = this.datepipe.transform(this.addedtodate, 'MMM dd, yyyy');
    } 

    if (!this.storage.retrieve('ApprovedTagTopupToDate')) {
      this.approvetodate = new Date();  //add this
      console.log("this.approvetodate>> " + this.approvetodate);
      this.approvetodaytodate = this.datepipe.transform(this.approvetodate, 'MMM dd, yyyy');
    }
    else {
      //this.approvetodate= new Date();
      //this.approvetodaytodate = this.datepipe.transform(this.approvetodate, 'MMM dd, yyyy');//amk
      this.approvetodate = new Date(this.storage.retrieve('ApprovedTagTopupToDate'));
      this.approvetodaytodate = this.datepipe.transform(this.approvetodate, 'MMM dd, yyyy');
    } 

    if (!this.storage.retrieve('DeniedTagTopupToDate')) {
      this.deniedtodate = new Date();  //add this
      console.log("this.deniedtodate>> " + this.deniedtodate);
      this.deniedtodaytodate = this.datepipe.transform(this.deniedtodate, 'MMM dd, yyyy');
    }
    else {
      //this.deniedtodate= new Date();
      //this.deniedtodaytodate = this.datepipe.transform(this.deniedtodate, 'MMM dd, yyyy');//amk
      this.deniedtodate = new Date(this.storage.retrieve('DeniedTagTopupToDate'));
      this.deniedtodaytodate = this.datepipe.transform(this.deniedtodate, 'MMM dd, yyyy');
    }    
    this.getAllProvider();
  }

  ngOnInit(): void
   {  
    this.router.routeReuseStrategy.shouldReuseRoute = function () {
      return false;
    };

    this.router.events.subscribe((evt) => {
      if (evt instanceof NavigationEnd) {

        this.router.navigated = false;
  
        window.scrollTo(0, 0);
      }
    });
    this.topupId = this.route.snapshot.paramMap.get("id"); 

    //amk
    // this.type = this.route.snapshot.paramMap.get("type");
    // if(this.type == 'Main'){
    //   $('#all-tab').tab('show');
    // }
    // else if(this.type == 'add'){
    //   this.addedSearch('tabsearch');
    //   $('#added-tab').tab('show');
    // }
    // else if(this.type == 'pending'){
    //   this.pendingSearch('tabsearch');
    //   $('#pending-tab').tab('show');
    // }
    // else if(this.type == 'approved'){
    //   this.approveSearch('tabsearch');
    //   $('#approve-tab').tab('show');
    // }
    // else if(this.type == 'denied'){
    //   this.deniedSearch('tabsearch');
    //   $('#approve-tab').tab('show');
    // }



    this.dtOptions = {
      responsive: {
        details: {
            renderer: Responsive.renderer.listHiddenNodes()
        }
    },
      order: [],
      paging: false,
      destroy : true
    }

    this.dtOptions.columnDefs = [
      { targets: [9], orderable: true }
      // { targets: [14], orderable: true }
    ];



    this.dtOptions1 = {
      responsive: true,
      order: [],     
      paging: false,
      info : true,
    }

    this.dtOptions1.columnDefs = [
      { targets: [9], orderable: true }
    ];

    this.dtOptions2 = {
      responsive: true,
      order: [],     
      paging: false,
      info : true,
    }

 

    this.dtOptions2.columnDefs = [
      { targets: [9], orderable: true }
    ];

    this.dtOptions3 = {
      responsive: true,
      order: [],     
      paging: false,
      info : true,
    }

    this.dtOptions3.columnDefs = [
      { targets: [9], orderable: true }
    ];

    this.dtOptions4 = {
      responsive: true,
      order: [],     
      paging: false,
      info : true,
    }

    this.dtOptions4.columnDefs = [
      { targets: [9], orderable: true }
    ];

    this.dtOptionsacc = {
      responsive: true,
      order: [],     
      paging: false,
      info : true,
    }
    this.dtOptionsacc.columnDefs = [
      
    ];
    if (!this.storage.retrieve('loadFlag')) {
      this.storage.store('loadFlag', 'noLoad');
      setTimeout(function () {
        location.reload();
      }, 5);//5
    }
    else {
      this.storage.clear('loadFlag');
    }

    if (!this.storage.retrieve('TopupTag')) {
      this.storage.store('TopupTag', "PENDING");      
      this.pendingSearch('tabsearch');
      $('#pending-tab').tab('show');
    }
    else {
      if(this.storage.retrieve('TopupTag') == "ALL"){        
        this.allSearch('tabsearch');
        $('#all-tab').tab('show');
      }
      else if(this.storage.retrieve('TopupTag') == "PENDING"){        
        this.pendingSearch('tabsearch');
        $('#pending-tab').tab('show');
      }
      else if(this.storage.retrieve('TopupTag') == "ADDED"){        
        this.addedSearch('tabsearch');
        $('#added-tab').tab('show');
      }
      else if(this.storage.retrieve('TopupTag') == "APPROVE"){        
        this.approveSearch('tabsearch');
        $('#approve-tab').tab('show');
      }
      else if(this.storage.retrieve('TopupTag') == "DENIED"){        
        this.deniedSearch('tabsearch');
        $('#denied-tab').tab('show');
      }
    }  
  }

  onChangeSingle() {    
    this.storage.store('ALLTagTopupFromDate', this.alldate.toString());
  }
   
  onChangeAllToDate() {
   
    this.storage.store('ALLTagTopupToDate', this.alltodate.toString());
  }

  onChangeApprove() {
   
    this.storage.store('ApprovedTagTopupFromDate', this.approvedate.toString());
  }

  onChangeApproveToDate()
  {
 
    this.storage.store('ApprovedTagTopupToDate', this.approvetodate.toString());
  }

  onChangeAdded() {
    
    this.storage.store('CheckedTagTopupFromDate', this.addeddate.toString());
  }

  onChangeAddedToDate()
  {
    
    this.storage.store('CheckedTagTopupToDate', this.addedtodate.toString());
  }

  onChangePending() {
 
    this.storage.store('PendingTagTopupFromDate', this.pendingdate.toString());
  }

  onChangePendingToDate()
  {
    
    this.storage.store('PendingTagTopupToDate', this.pendingtodate.toString());
  }

  onChangeDenied() {
   
    this.storage.store('DeniedTagTopupFromDate', this.denieddate.toString());
  }

  onChangeDeniedToDate()
  {
  
    this.storage.store('DeniedTagTopupToDate', this.deniedtodate.toString());
  }

  handleError(error: HttpErrorResponse){
    if(error.status == 423)
    {
      this.spinner.hide();
      $("#deleteData").modal("show");
    }
    if(error.status == 500)
    {
      this.spinner.hide();
      this.toastr.error('Please check connection', 'Invalid!', {
        timeOut: 3000,
        positionClass: 'toast-top-right',
      });
    }
    if(error.status == 403)
    {
      this.spinner.hide();
      var id1 = 'tblTopup' + this.idIndex;
      var table1 = $('#' + id1).DataTable();

      var id2 = 'tblapproveTopup' + this.idapproveIndex;
      var table2 = $('#' + id2).DataTable();

      var id3 = 'tbladdTopup' + this.idaddIndex;
      var table3 = $('#' + id3).DataTable();

      var id4 = 'tblpendingTopup' + this.idpendingIndex;
      var table4 = $('#' + id4).DataTable();

      var id5 = 'tbldeniedTopup' + this.iddeniedIndex;
      var table5 = $('#' + id5).DataTable();

      this.toastr.error("Limited Access.", 'Invalid!', {
        timeOut: 3000,
        positionClass: 'toast-top-right',
        });
    }
    return throwError(error);
    }
    OkLogout()
    {
      window.location.href ="./ad-login";
    } 

  allSearch(searchkey){  
    this.storage.store('TopupTag', "ALL");
    this.topupallList = [];
    var id = 'tblTopup' + this.idIndex;
    var table = $('#' + id).DataTable();
    table.destroy();
    this.idIndex = this.idIndex +1;
    this.spinner.show();
    this.dto.token = this.storage.retrieve('token');
    this.singleDate = $("#singleDate").val();
    this.alltoDate = $("#alltodate").val();
    if(searchkey =="btnsearch")
    {
          if (this.singleDate == '' || this.singleDate == undefined) {
            console.log("date if case");
            this.allchangeDate = this.alltodayDate;
          }
          else {
            console.log("date else case");
            this.allchangeDate = this.singleDate;
          }
        
          if (this.alltoDate == '' || this.alltoDate == undefined) {
            console.log("date if case for to date");
            this.alltodatechangeDate = this.alltodaytodate;
          }
          else {
            console.log("date else case");
            this.alltodatechangeDate = this.alltoDate;
          }
    }
    else
    {
          if (this.singleDate == '' || this.singleDate == undefined) {
            //this.allchangeDate = this.alltoDate;
            this.alldate = new Date();
            this.allchangeDate = this.datepipe.transform(this.alldate, 'MMM dd, yyyy');  
          }
          else {
            this.alldate = new Date();
            this.allchangeDate = this.datepipe.transform(this.alldate, 'MMM dd, yyyy');   
          }        
          if (this.alltoDate == '' || this.alltoDate == undefined) {          
            //this.alltodatechangeDate = this.alltodaytodate;
            this.alltodate = new Date();
            this.alltodatechangeDate = this.datepipe.transform(this.alltodate, 'MMM dd, yyyy'); 
          }
          else {    
            this.alltodate = new Date();
            this.alltodatechangeDate = this.datepipe.transform(this.alltodate, 'MMM dd, yyyy');  
          }
    }
    console.log("All to date change date is : "+this.alltodatechangeDate)
    let headers = new HttpHeaders();
    
    headers = headers.set('Authorization', this.dto.token);
    if(this.accountNo == null){
      this.accountNo ='';
    }
    let params = new HttpParams();
    params = params.set('fromDate',this.allchangeDate).set('toDate',this.alltodatechangeDate).set('status', '').set('accountNo', this.accountNo).set('orderByField',this.orderby).set('orderMethod',this.ordermethod).set("pageNumber","1").set("rowsOfPage","10");
    
    this.http.get(this.funct.ipaddress + 'transaction/topups-by-params_pending_Order',{ params: params,headers:headers})//topups-by-params
    .pipe(
      catchError(this.handleError.bind(this))
     )
    .subscribe(
      result => {
        this.dto.Response = {};
        this.dto.Response = result.results;
        this.topupallList = this.dto.Response;
        this.totalItems = result.totalRows;
        this.dtTrigger.next();
        this.spinner.hide();
      }
    );
  }
  gty(page: any){
    this.storage.store('TopupTag', "ALL");
    this.topupallList = [];
    var id = 'tblTopup' + this.idIndex;
    var table = $('#' + id).DataTable();
    table.destroy();
    this.idIndex = this.idIndex +1;
    this.spinner.show();
    this.dto.token = this.storage.retrieve('token');
    this.singleDate = $("#singleDate").val();
    this.alltoDate = $("#alltodate").val();    

    this.allchangeDate = this.datepipe.transform( this.singleDate, 'MMM dd, yyyy');  
    this.alltodatechangeDate = this.datepipe.transform(this.alltoDate, 'MMM dd, yyyy');     
    
    let headers = new HttpHeaders();    
    headers = headers.set('Authorization', this.dto.token);
    if(this.accountNo == null){
      this.accountNo ='';
    }
    let params = new HttpParams(); 

    params = params.set('fromDate',this.allchangeDate).set('toDate',this.alltodatechangeDate).set('status', '').set('accountNo', this.accountNo).set('orderByField',this.orderby).set('orderMethod',this.ordermethod).set("pageNumber",page).set("rowsOfPage",this.itemsPerPage.toString()); 
 
    this.http.get(this.funct.ipaddress + 'transaction/topups-by-params_pending_Order', { params: params, headers: headers } )
    .pipe(
      catchError(this.handleError.bind(this))
    )
    .subscribe((result: any) => {
      this.dto.Response = {};
      this.dto.Response = result.results;
      this.topupallList = this.dto.Response;
      this.totalItems = result.totalRows;
      this.dtTrigger.next();
      this.spinner.hide();
    })
  }

 

  //#region approve
  approveSearch(searchkey) {

    this.storage.store('TopupTag', "APPROVE");
    this.topupapproveList = [];
    var id = 'tblapproveTopup' + this.idapproveIndex;
    var table = $('#' + id).DataTable();
    this.dto.token = this.storage.retrieve('token');
    table.destroy();
    this.idapproveIndex = this.idapproveIndex + 1;
    this.spinner.show();
    this.dto.token = this.storage.retrieve('token');
    this.approveDate = $("#approveDate").val();
    this.approvetoDate = $("#approvetodate").val();
    console.log("this.approveDate>> " + this.approveDate);
    if(searchkey =="btnsearch")
    {
          if (this.approveDate == '' || this.approveDate == undefined) {
            console.log("date if case");
            this.approvechangeDate = this.approvetodayDate;
          }
          else {
            console.log("date else case");
            this.approvechangeDate = this.approveDate;
          }
      
          if (this.approvetoDate == '' || this.approvetoDate == undefined) {
            console.log("date if case in approve to date");
            this.approvetodatechangeDate = this.approvetodaytodate;
          }
          else {
            console.log("date else case");
            this.approvetodatechangeDate = this.approvetoDate;
          }
    }
    else
    {
          if (this.approveDate == '' || this.approveDate == undefined) {
            this.approvechangeDate = this.approvetodayDate;
          }
          else {
    
            this.approvedate = new Date();
            this.approvechangeDate = this.datepipe.transform(this.approvedate, 'MMM dd, yyyy');  
          }
      
          if (this.approvetoDate == '' || this.approvetoDate == undefined) {        
            this.approvetodatechangeDate = this.approvetodaytodate  
          }
          else {
           
            this.approvetodate = new Date();
            this.approvetodatechangeDate = this.datepipe.transform(this.approvetodate, 'MMM dd, yyyy');  
          }
    }
    console.log("Approve todate change date is : "+ this.approvetodatechangeDate)
    let headers = new HttpHeaders();
    headers = headers.set('Authorization', this.dto.token);
    let params = new HttpParams();

    if(this.approveaccountNo == undefined)
    {
      this.approveaccountNo ="";
    }
    
    if(this.handelAccounts == undefined)
    {
      this.handelAccounts ="";
    }
    if(this.transactiontype == undefined)
    {
        this.transactiontype ="";
    }
    if(this.transactiontype =="")
    {
      this.transactiontype ="All";
    }
   
    params = params.set('fromDate', this.approvechangeDate).set("toDate",this.approvetodatechangeDate).set('status', 'APPROVED').set('accountNo', this.approveaccountNo).set('handelAccounts', this.handelAccounts).set('transactiontype', this.transactiontype).set('orderByField',this.orderbyapproved).set('orderMethod',this.ordermethodapproved).set("pageNumber","1").set("rowsOfPage","10");

    this.http.get(this.funct.ipaddress + 'transaction/topups-by-params_Order',{ params: params, headers : headers})
    .pipe(
      catchError(this.handleError.bind(this))
     )
    .subscribe(
      result => {
        this.dto.Response = {};
        this.dto.Response = result.results;
        this.topupapproveList = this.dto.Response;
        this.totalItems = result.totalRows;
        this.approveDate = '';
        this.dtTrigger1.next();
        this.spinner.hide();
      });
  }
//#endregion


gtyApproved(page :any) {
  this.storage.store('TopupTag', "APPROVE");
  this.topupapproveList = [];
  var id = 'tblapproveTopup' + this.idapproveIndex;
  var table = $('#' + id).DataTable();
  this.dto.token = this.storage.retrieve('token');
  table.destroy();
  this.idapproveIndex = this.idapproveIndex + 1;
  this.spinner.show();
  this.dto.token = this.storage.retrieve('token');
  this.approveDate = $("#approveDate").val();
  this.approvetoDate = $("#approvetodate").val();
  
  this.approvechangeDate = this.datepipe.transform(this.approveDate, 'MMM dd, yyyy');  
    
  this.approvetodatechangeDate = this.datepipe.transform(this.approvetoDate, 'MMM dd, yyyy');      
    
    
  let headers = new HttpHeaders();
  headers = headers.set('Authorization', this.dto.token);
  let params = new HttpParams();

  if(this.approveaccountNo == undefined)
  {
    this.approveaccountNo ="";
  }
  
  if(this.handelAccounts == undefined)
  {
    this.handelAccounts ="";
  }
  if(this.transactiontype == undefined)
  {
      this.transactiontype ="";
  }
  if(this.transactiontype =="")
  {
    this.transactiontype ="All";
  }   

  params = params.set('fromDate', this.approvechangeDate).set("toDate",this.approvetodatechangeDate).set('status', 'APPROVED').set('accountNo', this.approveaccountNo).set('handelAccounts', this.handelAccounts).set('transactiontype', this.transactiontype).set('orderByField',this.orderbyapproved).set('orderMethod',this.ordermethodapproved).set("pageNumber",page).set("rowsOfPage",this.itemsPerPage.toString());

  this.http.get(this.funct.ipaddress + 'transaction/topups-by-params_Order',{ params: params, headers : headers})
  .pipe(    //transaction/topups-by-params_1
    catchError(this.handleError.bind(this))
   )
  .subscribe(
    result => {
      this.dto.Response = {};
      this.dto.Response = result.results;
      this.topupapproveList = this.dto.Response;
      this.totalItems = result.totalRows;
      this.approveDate = '';
      this.dtTrigger1.next();
      this.spinner.hide();
    });
}



  addedSearch(searchkey) {
    this.storage.store('TopupTag', "ADDED");
    this.topupaddList = [];
    var id = 'tbladdTopup' + this.idaddIndex;
    this.dto.token = this.storage.retrieve('token');
    var table = $('#' + id).DataTable();
    table.destroy();
    this.idaddIndex = this.idaddIndex + 1;
    this.spinner.show();
    this.dto.token = this.storage.retrieve('token');
    this.addedDate = $("#addedDate").val();
    this.addedtoDate = $("#addedtodate").val();
    if(searchkey =="btnsearch")
    {
          if (this.addedDate == '' || this.addedDate == undefined) {           
            this.addedchangeDate = this.addtodayDate;
          }
          else {
            console.log("date else case");
            this.addedchangeDate = this.addedDate;
          }
      
          if (this.addedtoDate == '' || this.addedtoDate == undefined) {
            console.log("date if case");
            this.addedtodatechangeDate = this.addedtodaytodate;
          }
          else {
            console.log("date else case");
            this.addedtodatechangeDate = this.addedtoDate;
          }
    }
    else
    {
          // if (this.addedDate == '' || this.addedDate == undefined) {        
          //   this.addedchangeDate = this.addedtoDate; 
          // }
          // else {
          //   this.addeddate = new Date();
          //   this.addedchangeDate = this.datepipe.transform(this.addeddate, 'MMM dd, yyyy'); 
          // }
      
          // if (this.addedtoDate == '' || this.addedtoDate == undefined) 
          // {
          //   this.addedtodatechangeDate = this.addedtodaytodate;
          // }
          // else {           
          //   this.addedtodate= new Date();
          //   this.addedtodatechangeDate = this.datepipe.transform(this.addedtodate, 'MMM dd, yyyy'); 
          // }

              this.addeddate = new Date();
            this.addedchangeDate = this.datepipe.transform(this.addeddate, 'MMM dd, yyyy'); 
                  this.addedtodate= new Date();
            this.addedtodatechangeDate = this.datepipe.transform(this.addedtodate, 'MMM dd, yyyy'); 
        }

    let headers = new HttpHeaders();
    headers = headers.set('Authorization', this.dto.token);
    let params = new HttpParams();
    params = params.set('fromDate', this.addedchangeDate).set("toDate",this.addedtodatechangeDate).set('status', 'ADDED').set('accountNo', this.addedaccountNo).set('orderByField',this.orderbyadd).set('orderMethod',this.ordermethodadd).set("pageNumber","1").set("rowsOfPage",this.itemsPerPage.toString());
     this.http.get(this.funct.ipaddress + 'transaction/topups-by-params_pending_Order',{ params: params, headers: headers})
    .pipe(
      catchError(this.handleError.bind(this))
     )
    .subscribe(
      result => {
        this.dto.Response = {};
        this.dto.Response = result.results;
        this.topupaddList = this.dto.Response;
        this.totalItems = result.totalRows;
        this.addedDate = '';
        this.dtTrigger2.next();
        this.spinner.hide();
      });

    
  }

  qtyadd(page:any) {
    this.storage.store('TopupTag', "ADDED");
    this.topupaddList = [];
    var id = 'tbladdTopup' + this.idaddIndex;
    this.dto.token = this.storage.retrieve('token');
    var table = $('#' + id).DataTable();
    table.destroy();
    this.idaddIndex = this.idaddIndex + 1;
    this.spinner.show();
    this.dto.token = this.storage.retrieve('token');
    this.addedDate = $("#addedDate").val();
    this.addedtoDate = $("#addedtodate").val();
    this.addedchangeDate = this.datepipe.transform(this.addedDate, 'MMM dd, yyyy'); 
    this.addedtodatechangeDate = this.datepipe.transform(this.addedtoDate, 'MMM dd, yyyy'); 
    let headers = new HttpHeaders();
    headers = headers.set('Authorization', this.dto.token);
    let params = new HttpParams();
    params = params.set('fromDate', this.addedchangeDate).set("toDate",this.addedtodatechangeDate).set('status', 'ADDED').set('accountNo', this.addedaccountNo).set('orderByField',this.orderbyadd).set('orderMethod',this.ordermethodadd).set("pageNumber",page).set("rowsOfPage",this.itemsPerPage.toString());
     this.http.get(this.funct.ipaddress + 'transaction/topups-by-params_pending_Order',{ params: params, headers: headers})  //transaction/topups-by-params_1_pending
    .pipe(
      catchError(this.handleError.bind(this))
     )
    .subscribe(
      result => {
        this.dto.Response = {};
        this.dto.Response = result.results;
        this.topupaddList = this.dto.Response;
        this.totalItems = result.totalRows;
        this.addedDate = '';
        this.dtTrigger2.next();
        this.spinner.hide();
      });
  }
  pendingSearch(searchkey){
    this.storage.store('TopupTag', "PENDING");
    console.log ("Active Tab");
    this.topuppengingList = [];
    var id = 'tblpendingTopup' + this.idpendingIndex;
    var table = $('#' + id).DataTable();
    table.destroy();
    this.idpendingIndex = this.idpendingIndex + 1;
    this.spinner.show();
    this.dto.token = this.storage.retrieve('token');
    this.pendingDate = $("#pendingDate").val();
    this.pendingtoDate = $("#pendingtodate").val();
    if(searchkey =='btnsearch')
    {          
        if (this.pendingDate == '' || this.pendingDate == undefined) {          
          this.pendingchangeDate = this.pendingtodayDate;
        }
        else {
          console.log("date else case");
          this.pendingchangeDate = this.pendingDate;
        }

        if (this.pendingtoDate == '' || this.pendingtoDate == undefined) {
          console.log("date if case");
          this.pendingtodatechangeDate = this.pendingtodaytodate;
        }
        else {
          console.log("date else case");
          this.pendingtodatechangeDate = this.pendingtoDate;
        }
    }
    else
   {          
        if (this.pendingDate == '' || this.pendingDate == undefined) {    
          this.pendingchangeDate = this.pendingtodayDate;
        }
        else {       
          this.pendingdate = new Date();
          this.pendingchangeDate = this.datepipe.transform(this.pendingdate, 'MMM dd, yyyy');  
        }

        if (this.pendingtoDate == '' || this.pendingtoDate == undefined) {         
          this.pendingtodatechangeDate = this.pendingtodaytodate;
        }
        else {        
          this.pendingtodate = new Date();
          this.pendingtodatechangeDate = this.datepipe.transform(this.pendingtodate, 'MMM dd, yyyy'); 
        }
    }

    let headers = new HttpHeaders();
    headers = headers.set('Authorization', this.dto.token);
    let params = new HttpParams();
    console.log(this.dto.token);
    params = params.set('fromDate', this.pendingchangeDate).set("toDate",this.pendingtodatechangeDate).set('status', 'PENDING').set('accountNo', this.pendingaccountNo).set('orderByField',this.orderbypending).set('orderMethod',this.ordermethodpending).set("pageNumber","1").set("rowsOfPage","10"); 
    this.http.get(this.funct.ipaddress + 'transaction/topups-by-params_pending_Order',{ params: params, headers: headers})//transaction/topups-by-params_1_pending
    .pipe(
      catchError(this.handleError.bind(this))
     )
    .subscribe(
      result => {
        this.dto.Response = {};
        this.dto.Response = result.results;
        this.topuppengingList = this.dto.Response;
        this.totalItems = result.totalRows;
        this.pendingDate = '';
        this.dtTrigger3.next();
        this.spinner.hide();
      });
  }
  qtyPending(page:any){
    this.storage.store('TopupTag', "PENDING");
    console.log ("Active Tab");
    this.topuppengingList = [];
    var id = 'tblpendingTopup' + this.idpendingIndex;
    var table = $('#' + id).DataTable();
    table.destroy();
    this.idpendingIndex = this.idpendingIndex + 1;
    this.spinner.show();
    this.dto.token = this.storage.retrieve('token');
    this.pendingDate = $("#pendingDate").val();
    this.pendingtoDate = $("#pendingtodate").val();
    this.pendingchangeDate = this.datepipe.transform(this.pendingDate, 'MMM dd, yyyy');  
    this.pendingtodatechangeDate = this.datepipe.transform(this.pendingtoDate, 'MMM dd, yyyy');  

    let headers = new HttpHeaders();
    headers = headers.set('Authorization', this.dto.token);
    let params = new HttpParams();
    console.log(this.dto.token);
    params = params.set('fromDate', this.pendingchangeDate).set("toDate",this.pendingtodatechangeDate).set('status', 'PENDING').set('accountNo', this.pendingaccountNo).set('orderByField',this.orderbypending).set('orderMethod',this.ordermethodpending).set("pageNumber",page).set("rowsOfPage",this.itemsPerPage.toString());
    this.http.get(this.funct.ipaddress + 'transaction/topups-by-params_pending_Order',{ params: params, headers: headers})
    .pipe(
      catchError(this.handleError.bind(this))
     )
    .subscribe(
      result => {
        this.dto.Response = {};
        this.dto.Response = result.results;
        this.topuppengingList = this.dto.Response;
        this.totalItems = result.totalRows;
        this.pendingDate = '';
        this.dtTrigger3.next();
        this.spinner.hide();
      });

   

   
  }

  deniedSearch(searchkey){
    this.storage.store('TopupTag', "DENIED");
    this.topupdeniedList = [];
    var id = 'tbldeniedTopup' + this.iddeniedIndex;
    var table = $('#' + id).DataTable();
    table.destroy();
    this.iddeniedIndex = this.iddeniedIndex + 1;
    this.spinner.show();
    this.dto.token = this.storage.retrieve('token');
    this.deniedDate = $("#deniedDate").val();
    this.deniedtoDate = $("#deniedtodate").val();
    if(searchkey =="btnsearch")
    {          
        if (this.deniedDate == '' || this.deniedDate == undefined) {
          console.log("date if case");
          this.deniedchangeDate = this.deniedtodayDate;
        }
        else {
          console.log("date else case");
          this.deniedchangeDate = this.deniedDate;
        }

        if (this.deniedtoDate == '' || this.deniedtoDate == undefined) {
          console.log("date if case");
          this.deniedtodatechangeDate = this.deniedchangeDate;
        }
        else {
          console.log("date else case");
          this.deniedtodatechangeDate = this.deniedtoDate;
        }
    }
    else
    {          
        if (this.deniedDate == '' || this.deniedDate == undefined) {
          this.deniedchangeDate = this.deniedtodayDate;
        }
        else {
          this.denieddate = new Date();
          this.deniedchangeDate = this.datepipe.transform(this.denieddate, 'MMM dd, yyyy'); 
        }

        if (this.deniedtoDate == '' || this.deniedtoDate == undefined) {
          this.deniedtodatechangeDate = this.deniedtodayDate;
        }
        else {
          this.deniedtodate = new Date();
          this.deniedtodatechangeDate = this.datepipe.transform(this.deniedtodate, 'MMM dd, yyyy');           
        }
    }
    let headers = new HttpHeaders();
    headers = headers.set('Authorization',  this.dto.token);
    let params = new HttpParams();
    params = params.set('fromDate', this.deniedchangeDate).set("toDate",this.deniedtodatechangeDate).set('status', 'DENIED').set('accountNo', this.deniedaccountNo).set('orderByField',this.orderbydenied).set('orderMethod',this.ordermethoddenied).set("pageNumber","1").set("rowsOfPage","10");
   
    this.http.get(this.funct.ipaddress + 'transaction/topups-by-params_pending_Order',{headers: headers, params: params})
    .pipe(
      catchError(this.handleError.bind(this))
     )
    .subscribe(
      result => {
        this.dto.Response = {};
        this.dto.Response = result.results;
        this.topupdeniedList = this.dto.Response;
        this.totalItems = result.totalRows;     
        this.deniedDate = '';
        this.dtTrigger4.next();
        this.spinner.hide();
      });
  }

  qtydenied(page:any){
    this.storage.store('TopupTag', "DENIED");
    this.topupdeniedList = [];
    var id = 'tbldeniedTopup' + this.iddeniedIndex;
    var table = $('#' + id).DataTable();
    table.destroy();
    this.iddeniedIndex = this.iddeniedIndex + 1;
    this.spinner.show();
    this.dto.token = this.storage.retrieve('token');
    this.deniedDate = $("#deniedDate").val();
    this.deniedtoDate = $("#deniedtodate").val();
    this.deniedchangeDate = this.datepipe.transform(this.deniedDate, 'MMM dd, yyyy'); 
    this.deniedtodatechangeDate = this.datepipe.transform(this.deniedtoDate, 'MMM dd, yyyy');  

    let headers = new HttpHeaders();
    headers = headers.set('Authorization',  this.dto.token);
    let params = new HttpParams();
    params = params.set('fromDate', this.deniedchangeDate).set("toDate",this.deniedtodatechangeDate).set('status', 'DENIED').set('accountNo', this.deniedaccountNo).set('orderByField',this.orderbydenied).set('orderMethod',this.ordermethoddenied).set("pageNumber",page).set("rowsOfPage",this.itemsPerPage.toString());
   
    this.http.get(this.funct.ipaddress + 'transaction/topups-by-params_pending_Order',{headers: headers, params: params})
    .pipe(
      catchError(this.handleError.bind(this))
     )
    .subscribe(
      result => {
        this.dto.Response = {};
        this.dto.Response = result.results;
        this.topupdeniedList = this.dto.Response;
        this.totalItems = result.totalRows;
        this.deniedDate = '';
        this.dtTrigger4.next();
        this.spinner.hide();
      });
  }

  numericOnly(event): boolean { // restrict e,+,-,E characters in  input type number
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode == 101 || charCode == 69 || charCode == 45 || charCode == 43 || charCode == 46) {
      return false;
    }
    return true;
  }

/*XXXXXXXXXXXXX DELETE XXXXXXXXXXXXXXXXXXXX*/
  deleteOk()
  {
    
  }
  flagProvider()
  {
      this.handelAccounts = $("#handelAccounts").val();
  }


  getAllProvider()
  {
   this.dto.token = this.storage.retrieve('token');
   let headers = new HttpHeaders();
   headers = headers.set('Authorization',  this.dto.token);
   let params = new HttpParams();
   params = params.set('type','TOPUP');//'WITHDRAWAL' , TOPUP
   this.http.get(this.funct.ipaddress + 'user/getBankAccountHandleList', {params: params, headers: headers })
   .pipe(
     catchError(this.handleError.bind(this))
    )
   .subscribe(
     result => {
       this.dto.Response = {};
       this.dto.Response = result;
       this.gameproviderList = this.dto.Response;
       
     });
  }

  /*My amount -2021-10-05*/
  goModal(){

    this.accountDetailList = [];
    var id = 'tblServicePhone' + this.accidIndex;
    var table = $('#' + id).DataTable();
    table.destroy();

    this.accidIndex = this.accidIndex + 1;
    let headers = new HttpHeaders();
    this.dto.token = this.storage.retrieve('token');
     headers = headers.set('Authorization', this.dto.token);
     this.http.get(this.funct.ipaddress + 'payment/GetAccountDetailByAdminID', {headers: headers}).subscribe(
      result => {
        this.dto.Response = {};
        this.dto.Response = result;
        this.accountDetailList = this.dto.Response;
        var tot = 0, mytot = 0;

         for (let i = 0; i < this.accountDetailList.length; i++) {
          tot = parseInt(this.accountDetailList[i].today_reach_amt) + tot;
          mytot = parseInt(this.accountDetailList[i].my_amount) + mytot;
       }
       this.total_today_reach_amt = new Intl.NumberFormat().format(tot);
       this.total_my_amount= new Intl.NumberFormat().format(mytot);
       this.dtTriggeracc.next();
      });
    $('#accountDetailData').modal("show");

  }

  onChangeTranNoAll() {     
    this.storage.store('ALLTagTopupAccountNo', this.accountNo.toString());  
  }

  onChangeTranNoPending() {    
    this.storage.store('PendingTagTopupAccountNo', this.pendingaccountNo.toString());    
  }

  onChangeTranNoAdded() {     
    this.storage.store('CheckedTagTopupAccountNo', this.addedaccountNo.toString());    
  }

  onChangeTranNoApprove() { 
    this.storage.store('ApprovedTagTopupAccountNo', this.approveaccountNo.toString());
  }

  onChangeTranNoDenied() {    
    this.storage.store('DeniedTagTopupAccountNo', this.deniedaccountNo.toString());   
  } 


  addStepAndNavigate(topup: any): void
  { 
   localStorage.setItem('topupData', JSON.stringify({
    id:topup.id,
    transaction_no:topup.transaction_no,
    status:topup.status,
    account_name:topup.account_name,
    account_number:topup.account_number
    
   }));

   this.router.navigate(['/topup-check-detail-update', topup.id]);
 }

}
