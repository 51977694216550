import { Component, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams ,HttpErrorResponse } from '@angular/common/http';
import { Router, ActivatedRoute } from '@angular/router';

import { LocalStorageService } from 'ngx-webstorage';
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from 'ngx-toastr';

import { DtoService } from '../../service/dto.service';
import { FunctService } from '../../service/funct.service';
import { Subject } from 'rxjs';
import { DatePipe } from '@angular/common';

import { catchError, retry } from 'rxjs/operators';
import { throwError } from 'rxjs';

declare const getCurrentDate: any;
declare var $: any;

export class AppModule {
}
@Component({
  selector: 'app-user',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.css'],
  providers: [DatePipe]

})
export class NotificationsComponent implements OnInit {
  title: string ='';
  body: string = '';
  dtTrigger: Subject<any> = new Subject();
  model : any;
  frontend_parameter :any;
  myDate = new Date();
  notiId : any;
  
  hardcode_edit :any;
  hardcode_add :any;
  hardcode_view: any;
  hardcode_ReSend:any;
  hardcode_view_bool : any;
  hardcode_add_bool : any;
  hardcode_edit_bool : any;
  hardcode_ReSend_bool:any;
  hardcode_double_bool:any;
  
  constructor( private route: ActivatedRoute,private toastr: ToastrService,private datePipe: DatePipe,private storage: LocalStorageService, private spinner: NgxSpinnerService, private http: HttpClient, private dto: DtoService, private router: Router, private funct: FunctService,) {
    this.storage.store('isNotiSong', "");
   
    
    this.hardcode_view = "Notification_View";
    this.hardcode_edit = "Notification_Edit";
    this.hardcode_add = "Notification_Add";
    //this.hardcode_ReSend="Notification_ReSend"; 
    
    this.dto.menuCodes = this.storage.retrieve('menucodes');

    const filteredMenuCodes_view = [];
    const filteredMenuCodeds_add =[];
    const filteredMenuCodeds_edit =[];
    const filteredMenuCodes_ReSend =[];
    this.dto.menuCodes.forEach(menuCode => {
      if (menuCode === this.hardcode_view) {
        filteredMenuCodes_view.push(menuCode);
      }
      if (menuCode === this.hardcode_add) {
        filteredMenuCodeds_add.push(menuCode);
      }
      if (menuCode === this.hardcode_edit) {
        filteredMenuCodeds_edit.push(menuCode);
      }
    
      
    });
    if(filteredMenuCodes_view.length != 0)
    {
          this.hardcode_view_bool = true;
    }
    if(filteredMenuCodeds_add.length != 0)
    {
          this.hardcode_add_bool = true;
    }
    if(filteredMenuCodeds_edit.length != 0)
    {
          this.hardcode_edit_bool = true;
    }
  
  
  }
  ngOnInit(): void 
  {
    this.notiId = this.route.snapshot.paramMap.get("id");
    if (this.notiId == null) {
      $(document).ready(function () 
      {
      });
      // this.model = 
      // {
       
      //   title: '',
      //   type: 'All',
      //   body: '',
      //   sendNow:true
      
      // };

      this.model = {
        id: 0,
        title: '',
        type: 'All',
        body: '',
        sendNow: true,
        isResend: ''
      };
    }
    else {
      $(document).ready(function () {
      });
      // this.model = 
      // {
      //   id: 0,
      //   title: '',
      //   type: 'All',
      //   body: '',
      //   sendNow:true,
      //   isResend:''
      
      // };

      this.model = {
        id: 0,
        title: '',
        type: 'All',
        body: '',
        sendNow: true,
        isResend: ''
      };
      
      this.getNotiById();
    }
  }

  handleError(error: HttpErrorResponse){
    if(error.status == 423)
    {
      this.spinner.hide();
      $("#deleteData").modal("show");
    }
    if(error.status == 403)
    {
      this.spinner.hide();
      this.toastr.error("Limited Access.", 'Invalid!', {
        timeOut: 3000,
        positionClass: 'toast-top-right',
        });
    }
    return throwError(error);
    }
    OkLogout()
    {
      window.location.href ="./ad-login";
    }

    getNotiById()
    {
      this.dto.token = this.storage.retrieve('token');
      let headers = new HttpHeaders();
      headers = headers.set('Authorization',  this.dto.token);
      let params = new HttpParams();
      params = params.set('notiId', this.notiId);
      this.http.get(this.funct.ipaddress + 'notification/GetNotiByIdAdmin', { params: params, headers: headers })
      .pipe(
        catchError(this.handleError.bind(this))
       )
      .subscribe( 
        result => {
          this.dto.Response = {};
          this.dto.Response = result;
          this.model = this.dto.Response;
        });
    }

  sendNotifications(){
    this.spinner.show();
    this.dto.token = this.storage.retrieve('token');
    let headers = new HttpHeaders();
    headers = headers.set('Authorization', this.dto.token).set('Accept', 'application/json').set('Content-Type', 'application/json');
    let params = new HttpParams();
    var formData = new FormData();
    if(this.model.type == null || this.model.type == '' || this.model.type == 'undefined')
    {
        this.toastr.error("Please choose type", 'Invalid!', {
          timeOut: 3000,
          positionClass: 'toast-top-right'
        });  
        return; 
    }
    if(this.model.title == null || this.model.title == '' || this.model.title == 'undefined')
      {
          this.toastr.error("Please enter title", 'Invalid!', {
            timeOut: 3000,
            positionClass: 'toast-top-right'
          });  
          return; 
      }
      if(this.model.body == null || this.model.body == '' || this.model.body == 'undefined')
      {
          this.toastr.error("Please enter body", 'Invalid!', {
            timeOut: 3000,
            positionClass: 'toast-top-right'
          });  
          return; 
      }
    
    formData.append("title", this.model.title);
    formData.append("body", this.model.body);
    formData.append("type", this.model.type); 
    //this.http.post(this.funct.ipaddress + 'notification/SendOthersNoti',this.model,{headers: headers})
    this.http.post(this.funct.ipaddress + 'notification/SendOthersNotiNew',this.model,{headers: headers})
    .pipe(
      catchError(this.handleError.bind(this))
     )
     .subscribe(data => {     
          this.spinner.hide();
          this.router.navigate(['/notification']).then(() => {
            this.toastr.success("success", 'Success!', {
              timeOut: 3000,
              positionClass: 'toast-top-right'
            });
          })
        this.dtTrigger.next();
        this.spinner.hide();
        });
  }

  

  goSave(val) {
    if (this.notiId == null ) {
      this.sendNotifications();
    }    
    else {
      if(val =="Resend" && this.model.sendNow == true)
      {
        this.edit(val);
        //this.sendNotifications();
      }
      else 
      {
        this.edit(val);
        
      }      
    
    }
  }

//    edit(val) 
//    {
//     if(this.model.type == null || this.model.type == undefined || this.model.type =="")
//     {
//       this.toastr.error("Please choose type", 'Invalid!', {
//         timeOut: 3000,
//         positionClass: 'toast-top-right',
//       });
//       return;
//     }
//     if(this.model.title == null || this.model.title == undefined || this.model.title =="")
//     {
//       this.toastr.error("Please enter title", 'Invalid!', {
//         timeOut: 3000,
//         positionClass: 'toast-top-right',
//       });
//       return;
//     }
//     if(this.model.body == null || this.model.body == undefined || this.model.body =="")
//     {
//       this.toastr.error("Please enter title", 'Invalid!', {
//         timeOut: 3000,
//         positionClass: 'toast-top-right',
//       });
//       return;
//     }
//     this.spinner.show();
//     this.dto.token = this.storage.retrieve('token');
//     let headers = new HttpHeaders();
//     headers = headers.set('Authorization',  this.dto.token);
//     this.model.isResend=val;
//     this.frontend_parameter = this.model;
//     console.log("Notification list " + this.model);   
//     this.http.post(this.funct.ipaddress + 'notification/updateNew', this.frontend_parameter, { headers: headers })
//     .pipe(//'notification/update'
//       catchError(this.handleError.bind(this))
//      )
//     .subscribe( 
//       result => {
//         this.dto.Response = {};
//         this.dto.Response = result;
//         if (this.dto.Response.status == 'Success') {
//           this.spinner.hide();
//           this.router.navigate(['/notification']).then(() => {
//             this.toastr.success(this.dto.Response.message, 'Success!', {
//               timeOut: 3000,
//               positionClass: 'toast-top-right'
//             });
//           })
//         }
//         else {
//           this.spinner.hide();
//           this.toastr.error(this.dto.Response.message, 'Invalid!', {
//             timeOut: 3000,
//             positionClass: 'toast-top-right',
//           });
//         }
//       }); 
//   }


edit(val)
 {
      if(this.model.type == null || this.model.type == undefined || this.model.type =="")
      {
        this.toastr.error("Please choose type", 'Invalid!', {
          timeOut: 3000,
          positionClass: 'toast-top-right',
        });
        return;
      }
      if(this.model.title == null || this.model.title == undefined || this.model.title =="")
      {
        this.toastr.error("Please enter title", 'Invalid!', {
          timeOut: 3000,
          positionClass: 'toast-top-right',
        });
        return;
      }
      if(this.model.body == null || this.model.body == undefined || this.model.body =="")
      {
        this.toastr.error("Please enter title", 'Invalid!', {
          timeOut: 3000,
          positionClass: 'toast-top-right',
        });
        return;
      }

  // Remove 'updated_by' from the model
  delete this.model.updated_by;

  this.spinner.show();
  this.dto.token = this.storage.retrieve('token');

  let headers = new HttpHeaders().set('Authorization', this.dto.token);
  this.model.isResend = val;
  this.frontend_parameter = { ...this.model };

  console.log("Notification list", this.frontend_parameter);

  this.http.post(this.funct.ipaddress + 'notification/updateNew', this.frontend_parameter, { headers: headers })
    .pipe(
      catchError(this.handleError.bind(this))
    )
    .subscribe(result => {
      this.dto.Response = result;

      if (this.dto.Response.status === 'Success') {
        this.spinner.hide();
        this.router.navigate(['/notification']).then(() => {
          this.toastr.success(this.dto.Response.message, 'Success!', {
            timeOut: 3000,
            positionClass: 'toast-top-right'
          });
        });
      } else {
        this.spinner.hide();
        this.toastr.error(this.dto.Response.message, 'Invalid!', {
          timeOut: 3000,
          positionClass: 'toast-top-right',
        });
      }
    });
}




}
