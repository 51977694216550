import { Component, OnInit } from '@angular/core';
import { DtoService } from '../../service/dto.service';
import { LocalStorageService } from 'ngx-webstorage';
import { HttpClient, HttpHeaders, HttpParams,HttpErrorResponse } from '@angular/common/http';
import { FunctService } from '../../service/funct.service';
import { catchError } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from "ngx-spinner";
import { Router, ActivatedRoute } from '@angular/router';
import { Subject } from 'rxjs';
import Responsive from 'datatables.net-responsive'; 
import { param } from 'jquery';
declare var $: any;

@Component({
  selector: 'app-adjust-user-balance',
  templateUrl: './adjust-user-balance.component.html',
  styleUrls: ['./adjust-user-balance.component.css']
})
export class AdjustUserBalanceComponent implements OnInit {
  dtTrigger: Subject<any> = new Subject();
  dtOptions: DataTables.Settings = {};
  name: any;
  status  :any;
  amt : any;
  ephone : any;
  userList : any;
  idIndex : any;
  selectedHero : any;
  phone_no: any;
  prefix: any;
  sendPhone : any;
  userWalletEditModel : any;
  token: any;
  phoneNo: any;
  itemsPerPage =  10;
  totalItems : any; 
  page = 1;
  userBetLimitList : any;
  detailph :any;
  detialBetLimit : any;
  detailDate: any;
  detialName :any;
  editBalance: any;
  userId : any;
  balanceBak : any;
  editRemark:any;

  hardcode_edit :any;
  hardcode_add :any;
  hardcode_view: any;
  hardcode_view_bool : any; 
  hardcode_edit_bool : any;

  constructor( private router: Router,private spinner: NgxSpinnerService,private toastr: ToastrService,private http: HttpClient,private storage: LocalStorageService,private dto: DtoService, private funct: FunctService) 
   {   
    this.storage.store('isNotiSong', "");
      this.hardcode_view = "EditUsersBalance_View";
      this.hardcode_edit = "EditUsersBalance_Edit";
      this.dto.menuCodes = this.storage.retrieve('menucodes');

      const filteredMenuCodes_view = [];    
      const filteredMenuCodeds_edit =[];
      this.dto.menuCodes.forEach(menuCode => {
        if (menuCode === this.hardcode_view) {
          filteredMenuCodes_view.push(menuCode);
        }     
        if (menuCode === this.hardcode_edit) {
          filteredMenuCodeds_edit.push(menuCode);
        }
      });
          if(filteredMenuCodes_view.length != 0)
          {
              this.hardcode_view_bool = true;
          }       
          if(filteredMenuCodeds_edit.length != 0)
          {
              this.hardcode_edit_bool = true;
          }

   }
  ngOnInit(): void
   {
    this.getAllData();
    this.dtOptions = {
      responsive: {
        details: {
            renderer: Responsive.renderer.listHiddenNodes()
        }
    },
      order:[],
      paging: false,
      info : true,
      dom: "Bfrltip",
    }
    this.userWalletEditModel=
    {
      userId: '',
      phone_no : '',
      balance: 0.00
    }

    if(!this.storage.retrieve('loadFlag')){
      this.storage.store('loadFlag', 'noLoad');
      setTimeout(function(){
        location.reload();
      }, 5);
    }
    else{
      this.storage.clear('loadFlag');
    }
  }
  onInput(event: InputEvent) {
    const myanmarCharactersRegex = new RegExp('^[\u1000-\u109F\uAA60-\uAA7F\uA9E0-\uA9FF]+$');
    const inputValue = (event.target as HTMLInputElement).value; 
    if(event.data!=null)
    {
      if (myanmarCharactersRegex.test(inputValue)) 
      {        
        this.spinner.hide();
        this.toastr.error( "Myanmar typing detected!", '', {
        timeOut: 5000,
        positionClass: 'toast-top-right',
        });  
      }
    }  
    
  }
  getAllData() {
    this.userList = [];
    var id = 'tblUser' + this.idIndex;
    var table = $('#' + id).DataTable();
    table.destroy();
    this.idIndex = this.idIndex +1;
    this.spinner.show();
    this.dto.token = this.storage.retrieve('token');
    let headers = new HttpHeaders();
    headers = headers.set('Authorization',  this.dto.token);
    let params = new HttpParams(); 
    if(this.phoneNo != undefined)
    {
     params = params.set('phoneNo',this.phoneNo).set("pageNumber","1").set("rowsOfPage","10");
    }
    if(this.name != undefined)
    {
      params =  params.set('name',this.name).set("pageNumber","1").set("rowsOfPage","10");
    }
    if(this.phoneNo == undefined && this.name == undefined)
    {
      params = params.set("pageNumber","1").set("rowsOfPage","10");
    }
    if(this.phoneNo != undefined && this.name != undefined)
    {
      params = params.set('phoneNo',this.phoneNo).set('name',this.name).set("pageNumber","1").set("rowsOfPage","10");
    }
    //params = params.set('phoneNo',this.phoneNo).set('name',this.name).set("pageNumber","1").set("rowsOfPage","10");
    this.http.get(this.funct.ipaddress + 'user/getUsersForBalanceEdit', { params: params, headers: headers } )
    .pipe(
      catchError(this.handleError.bind(this))
     ).
    subscribe((data: any) => {
      this.dto.Response = {};
      this.dto.Response = data.results;
      this.userList = this.dto.Response;
      this.totalItems = data.totalRows;
      this.dtTrigger.next();
      this.spinner.hide();
      this.phoneNo = '';
    })
  }

  gty(page: any){
    this.userList = [];
    var id = 'tblUser' + this.idIndex;
    var table = $('#' + id).DataTable();
    table.destroy();
    this.idIndex = this.idIndex +1;
    this.spinner.show();
    let headers = new HttpHeaders();
    headers = headers.set('Authorization',  this.dto.token);
    let params = new HttpParams();
    if(this.phoneNo != undefined)
    {
     params = params.set('phoneNo',this.phoneNo).set("pageNumber",page.toString()).set("rowsOfPage",this.itemsPerPage.toString());
    }
    if(this.name != undefined)
    {
      params =  params.set('name',this.name).set("pageNumber",page.toString()).set("rowsOfPage",this.itemsPerPage.toString());
    }
    if(this.phoneNo == undefined && this.name == undefined)
    {
      params = params.set("pageNumber",page.toString()).set("rowsOfPage",this.itemsPerPage.toString());
    }
    if(this.phoneNo != undefined && this.name != undefined)
    {
      params = params.set('phoneNo',this.phoneNo).set('name',this.name).set("pageNumber",page.toString()).set("rowsOfPage",this.itemsPerPage.toString());
    }
    this.http.get(this.funct.ipaddress + 'user/getUsersForBalanceEdit', { params: params, headers: headers } )
    .pipe(
      catchError(this.handleError.bind(this))
     )
     .subscribe((data: any) => {
      this.dto.Response = {};
      this.dto.Response = data.results;
      this.userList = this.dto.Response;
      this.totalItems = data.totalRows;
      this.dtTrigger.next();
      this.spinner.hide();
    })
  }
  getuserDetail(userId,phone_no,twodbetlimit,update_date,username, balanceBak)
  {
    this.detailph = phone_no
    this.detialBetLimit = twodbetlimit;
    this.detailDate = update_date;
    this.sendPhone = this.detailph;
    this.detialName = username;
    this.userId = userId;
    this.balanceBak = balanceBak;
    this.editRemark = '';
    $('#showeditModal').modal("show");
  }
  editUserBalance()
  {
      this.spinner.show();
      this.token = this.storage.retrieve('token');
      let headers = new HttpHeaders();
      headers = headers.set('Authorization',  this.token);
      if(this.sendPhone == null || this.sendPhone == undefined)
      {
        this.toastr.error("Please enter phone number", 'Invalid!', {
          timeOut: 3000,
          positionClass: 'toast-top-right'
        });
        this.spinner.hide();
        return;
      }
      if(this.editBalance == null || this.editBalance== undefined)
      {
        this.toastr.error("Please Fill amount", 'Invalid!', {
          timeOut: 3000,
          positionClass: 'toast-top-right'
        });
        this.spinner.hide();
        return;
      }
      if(this.editBalance != null && this.editBalance!= undefined && this.editBalance < 0)
      {
        this.toastr.error("Invalid Amount", 'Invalid!', {
          timeOut: 3000,
          positionClass: 'toast-top-right'
        });
        this.spinner.hide();
        return;
      }
    
      this.userWalletEditModel.phone_no= this.sendPhone ;
      this.userWalletEditModel.balance= this.editBalance ;
      this.userWalletEditModel.editRemark = this.editRemark;
      this.userWalletEditModel.userId= this.userId.toString() ;
      this.http.post(this.funct.ipaddress + 'user/editWallet', this.userWalletEditModel, { headers: headers })
      .pipe(
        catchError(this.handleError.bind(this))
       )
      .subscribe( 
        result => {
          this.dto.Response = {};
          this.dto.Response = result;
          if( this.dto.Response == true)
          {
            this.toastr.success("Edit User Wallet Success", 'Success!', {
              timeOut: 3000,
              positionClass: 'toast-top-right'
            });
            this.spinner.hide();
            this.userWalletEditModel.phone_no= '' ;
            this.userWalletEditModel.balance= '' ;
            this.userWalletEditModel.userId = '';
            this.editBalance = '';
            $('#showeditModal').modal("hide");
            this.getAllData();
          }
        }); 
  }
  handleError(error: HttpErrorResponse)
  {
    this.spinner.hide();
    if(error.status == 403)
    {
      this.toastr.error("Limited Access", 'Invalid!', {
        timeOut: 3000,
        positionClass: 'toast-top-right'
      });
      this.editBalance = '';
      return;
    }
  }
  OkLogout()
  {
    window.location.href ="./ad-login";
  } 
}
