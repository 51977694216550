
import { Component, OnInit, ViewChild } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,HttpErrorResponse } from '@angular/common/http';
import { Router, NavigationEnd,ActivatedRoute } from '@angular/router';
import { DataTableDirective } from 'angular-datatables';

import { catchError, retry } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { ToastrService } from 'ngx-toastr';

import { LocalStorageService } from 'ngx-webstorage';
import { NgxSpinnerService } from "ngx-spinner";
import { FunctService } from '../../service/funct.service';
import { DtoService } from '../../service/dto.service';
import { DatePipe } from '@angular/common';
import { Subject } from 'rxjs';
declare var $: any;
@Component({
  selector: 'app-promotion-confirmed-users',
  templateUrl: './promotion-confirmed-users.component.html',
  styleUrls: ['./promotion-confirmed-users.component.css']
})
export class PromotionConfirmedUsersComponent implements OnInit {
  @ViewChild(DataTableDirective)
  dtElement: DataTableDirective;
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();

  phoneNo: string= '';
  name: string= '';
  referralCode: string= '';
  status: string= '';
  userList: any;
  idIndex: any;
  approvedate : any;
  approvetodayDate : any;
  approveDate : any;
  allchangedate : any;

  approvetodate : any;
  allchangetodate : any;
  approvetodayToDate : any;
  approvetoDate : any;
  /*XXXXXXXXXXXX*/
  config: any;
  collection = [];
  page = 1;
  passenger: any; 
  itemsPerPage =  10;
  totalItems : any; 
  itemsPerPageFront =  10;
  appVersionList :[];
  appVersion : any;
  confirmallArr: any;
  isChecked: any = false;
  updatedFromDate : any;
  updatedToDate : any;		

  /*newtotal items*/
  newTotalItems : any;
  option :any;

  
  hardcode_edit :any;
  hardcode_add :any;
  hardcode_view: any;
  hardcode_delete:any;
  hardcode_view_bool : any;
  hardcode_add_bool : any;
  hardcode_edit_bool : any;
  hardcode_delete_bool:any;
  hardcode_double_bool:any;


  constructor(private storage: LocalStorageService,private datepipe: DatePipe, private toastr: ToastrService,private spinner: NgxSpinnerService, private http: HttpClient, private dto: DtoService, private router: Router, 
    private route: ActivatedRoute, private funct: FunctService,) {
      this.idIndex = 1;
      this.storage.store('isNotiSong', "");
    this.hardcode_add = "PromotionUsers_Add";
    this.hardcode_view = "PromotionUsers_View";
    this.hardcode_edit = "PromotionUsers_Edit";
    
    this.dto.menuCodes = this.storage.retrieve('menucodes');
    const filteredMenuCodes_view = [];
    const filteredMenuCodeds_edit =[];
    const filteredMenuCodeds_add =[];
 
    this.dto.menuCodes.forEach(menuCode => {
      if (menuCode === this.hardcode_view) {
        filteredMenuCodes_view.push(menuCode);
      }   
      if (menuCode === this.hardcode_edit) {
        filteredMenuCodeds_edit.push(menuCode);
      }  
      if (menuCode === this.hardcode_add) {
        filteredMenuCodeds_add.push(menuCode);
      }  
      
    });
    if(filteredMenuCodes_view.length != 0)
    {
          this.hardcode_view_bool = true;
    }  
    if(filteredMenuCodeds_edit.length != 0)
    {
          this.hardcode_edit_bool = true;
    } 
    if(filteredMenuCodeds_add.length != 0)
    {
          this.hardcode_add_bool = true;
    }  

      if (!this.storage.retrieve('updatedFromDate')) {
        this.approvedate = new Date();
        this.approvetodayDate = this.datepipe.transform(this.approvedate, 'MMM dd, yyyy');        
      }
      else {
        this.approvedate = new Date(this.storage.retrieve('updatedFromDate'));
        this.approvetodayDate = this.datepipe.transform(this.approvedate, 'MMM dd, yyyy');
      }   
      
      if (!this.storage.retrieve('updatedToDate')) {
        this.approvetodate = new Date();
        this.approvetodayToDate = this.datepipe.transform(this.approvetodate, 'MMM dd, yyyy');        
      }
      else {
        this.approvetodate = new Date(this.storage.retrieve('updatedToDate'));
        this.approvetodayToDate = this.datepipe.transform(this.approvetodate, 'MMM dd, yyyy');
      }

    this.getAllAppVersion();
    //this.search();
    this.getAllData();
     }

  // ngOnInit(): void {
  //   this.dtOptions = {
  //     order:[],
  //     responsive:true,
  //     paging: true,
  //     info : true,
  //     dom: "Bfrltip"
  //     }
  //   this.dtOptions.columnDefs = [
  //     { targets: [0], orderable: true }
  //   ];
  // }

  
  ngOnInit(): void 
  {    
    this.router.routeReuseStrategy.shouldReuseRoute = function ()
     {
      return false;
    };

    this.router.events.subscribe((evt) =>
    {
      if (evt instanceof NavigationEnd)
      {
        this.router.navigated = false;
        window.scrollTo(0, 0);
      }
    });
    this.dtOptions = {
          order:[],
          responsive:true,
          paging: true,
          info : true,
          dom: "Bfrltip"
          }
   this.dtOptions.columnDefs = 
   [
        { targets: [0], orderable: true }
   ];

    

   
    if(!this.storage.retrieve('loadFlag')){
      this.storage.store('loadFlag', 'noLoad');
      setTimeout(function(){
        location.reload();
      }, 5);
    }
    else{
      this.storage.clear('loadFlag');
    }
  }

  handleError(error: HttpErrorResponse){
    if(error.status == 423)
    {
      
    }
    if(error.status == 403)
    {
      this.spinner.hide();
      var id = 'tblUser' + this.idIndex;
      var table = $('#' + id).DataTable();
      this.toastr.error("Limited Access.", 'Invalid!', {
        timeOut: 3000,
        positionClass: 'toast-top-right',
        });
    }
    return throwError(error);
    }
    OkLogout()
    {
      window.location.href ="./ad-login";
    }
    onInput(event: InputEvent) {
      const myanmarCharactersRegex = new RegExp('^[\u1000-\u109F\uAA60-\uAA7F\uA9E0-\uA9FF]+$');
      const inputValue = (event.target as HTMLInputElement).value; 
      if(event.data!=null)
      {
        if (myanmarCharactersRegex.test(inputValue)) 
        {        
          this.spinner.hide();
          this.toastr.error( "Myanmar typing detected!", '', {
          timeOut: 5000,
          positionClass: 'toast-top-right',
          });  
        }
      }   
      
    }

    getAllData() {
      this.userList = [];
      var id = 'tblUser' + this.idIndex;
      var table = $('#' + id).DataTable();
      table.destroy();
      this.idIndex = this.idIndex +1;
      this.spinner.show();
      this.dto.token = this.storage.retrieve('token');
      let headers = new HttpHeaders();
      headers = headers.set('Authorization',  this.dto.token);
      let params = new HttpParams(); 
      this.approveDate = $("#approveDateConfirmed").val();
      this.approvetoDate = $("#approvetoDateConfirmed").val();
      if (this.approveDate == '' || this.approveDate == undefined) {
        this.allchangedate = this.approvetodayDate;
      }
      else {
        this.allchangedate = this.approveDate;
      }
  
      if (this.approvetoDate == '' || this.approvetoDate == undefined) {
        this.allchangetodate = this.approvetodayToDate;
      }
      else {
        this.allchangetodate = this.approvetoDate;
      } 
      params = params.set('app_version',this.appVersion).set('phoneNo',this.phoneNo).set('name',this.name).set('referralCode',this.referralCode).set('status', this.status)
      .set('register_date',this.allchangedate).set('register_todate',this.allchangetodate)
      this.http.get(this.funct.ipaddress + 'user/GetConfirmPointPromotionUsers', { params: params, headers: headers } )
      .pipe(
        catchError(this.handleError.bind(this))
       )
      .subscribe((data: any) => {
        this.dto.Response = {};
        this.dto.Response = data;
        this.userList = this.dto.Response;
        this.totalItems = data.totalRows;
        this.dtTrigger.next();
        this.spinner.hide();
      })
    }
    getAllAppVersion()
    {
      this.dto.token = this.storage.retrieve('token');
      let headers = new HttpHeaders();
      headers = headers.set('Authorization', this.dto.token);
      let params = new HttpParams();
      this.http.get(this.funct.ipaddress + 'user/getAllAppVersion', {headers: headers }).subscribe(
        result => {
          this.dto.Response = {};
          this.dto.Response = result;
          this.appVersionList = this.dto.Response;
        }
      );
    }

    onChangeApprove() {
      // $(document).ready(function () {
      //   this.approveDate = $("#approveDate").val();
      // });
      this.storage.store('updatedFromDate', this.approvedate.toString());
    }
    
    onChangeApproveTo()
    {
      // $(document).ready(function () {
      //   this.approvetoDate = $("#approvetoDate").val();
      // });
      this.storage.store('updatedToDate', this.approvetodate.toString());
    }

    ngOnDestroy(): void {
      this.dtTrigger.unsubscribe();
    }
  
}
