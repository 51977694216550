import { Component, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,HttpErrorResponse } from '@angular/common/http';
import { Router, ActivatedRoute } from '@angular/router';

import { LocalStorageService } from 'ngx-webstorage';
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from 'ngx-toastr';

import { catchError, retry } from 'rxjs/operators';
import { throwError } from 'rxjs';

import { DtoService } from '../../service/dto.service';
import { FunctService } from '../../service/funct.service';
declare var $: any;

@Component({
  selector: 'app-lockgscapis-detail',
  templateUrl: './lockgscapis-detail.component.html',
  styleUrls: ['./lockgscapis-detail.component.css']
})
export class LockgscapisDetailComponent implements OnInit {

  adsDTO: any;
  token: any;
  adsId: any;
  imagePath: any;
  imgURL: any;
  message: string = '';
  name : any;
  imageUrL : any;
  linkUrl : any;
  content : any;
  gameproviderList : any;
  gameProviderCode : any;
  apiName:any;

  constructor(private storage: LocalStorageService, private spinner: NgxSpinnerService, private toastr: ToastrService, private http: HttpClient, private dto: DtoService, private router: Router,
    private route: ActivatedRoute, private funct: FunctService) {
      this.getAllProvider();
      this.storage.store('isNotiSong', "");
     }

  ngOnInit(): void {

    if (!this.storage.retrieve('loadFlag')) {
      this.storage.store('loadFlag', 'noLoad');
      setTimeout(function () {
        location.reload();
      }, 1);
    }
    else {
      this.storage.clear('loadFlag');
    }

    this.adsId = this.route.snapshot.paramMap.get("id");
    if (this.adsId == null) {
      $(document).ready(function () {
        $('#updatedata').hide();
      });
      this.adsDTO = {
        id: 0,
        //gameProviderCode:'',
        providerCode:'',
        apiName : '',
        isLock :1
        
      };
    }
    else {
      $(document).ready(function () {
        $('#updatedata').show();
      });
      this.adsDTO = {
        id: 0,
        //gameProviderCode:'',
        providerCode:'',
        apiName : '',
        isLock :1
        
      };
      this.getAdsById();
    }
  }

  handleError(error: HttpErrorResponse){
    this.spinner.hide();
    if(error.status == 423)
    {
      this.spinner.hide();
      $("#deleteData").modal("show");
    }
    if(error.status == 403)
    {
      this.spinner.hide();
      this.toastr.error("Limited Access.", 'Invalid!', {
        timeOut: 3000,
        positionClass: 'toast-top-right',
        });
    }
    if(error.status == 400)
    {
      this.spinner.hide();
      this.toastr.error("Please choose game provider or blank space for home", 'Invalid!', {
        timeOut: 3000,
        positionClass: 'toast-top-right',
        });
    }
    return throwError(error);
    }
    OkLogout()
    {
      window.location.href ="./ad-login";
    } 

    getAllProvider()
    {
     this.dto.token = this.storage.retrieve('token');
     let headers = new HttpHeaders();
     headers = headers.set('Authorization',  this.dto.token);
     this.http.get(this.funct.ipaddress + 'gameProvider/getGameProviderList', {headers: headers })
     .pipe(
       catchError(this.handleError.bind(this))
      )
     .subscribe(
       result => {
         this.dto.Response = {};
         this.dto.Response = result;
         this.gameproviderList = this.dto.Response;
       });
    }

  getAdsById() {
    this.token = this.storage.retrieve('token');
    let headers = new HttpHeaders();
    headers = headers.set('Authorization',  this.token);
    let params = new HttpParams();
    params = params.set('id', this.adsId);
    this.http.get(this.funct.ipaddress + 'ads/GetLockgscapiDetailList', { params: params, headers: headers })
    .pipe(
      catchError(this.handleError.bind(this))
     )
    .subscribe( 
      result => {
        this.dto.Response = {};
        this.dto.Response = result;
        this.adsDTO = this.dto.Response;
      
      });
  }

  flagProvider()
  {
      this.gameProviderCode = $("#gameProviderCode").val();
  }

  preview(files) {
    if (files.length === 0)
      return;
    var mimeType = files[0].type;
    if (mimeType.match(/image\/*/) == null) {
      this.message = "Only images are supported.";
      return;
    }

    var reader = new FileReader();
    this.imagePath = files;
    reader.readAsDataURL(files[0]);
    reader.onload = (_event) => {
      this.imgURL = reader.result;
    }
  }

  goSave() {
    if (this.adsId == null) {
      this.save();
    }
    else {
      this.edit();
    }
  }

   save() {
     this.spinner.show();
     this.token = this.storage.retrieve('token');
     let headers = new HttpHeaders();
     headers = headers.set('Authorization',  this.token);
     if(this.adsDTO.providerCode == null || this.adsDTO.providerCode == "" || this.adsDTO.providerCode == undefined)
     {
       this.adsDTO.providerCode= '';
     }
     else{
     this.adsDTO.providerCode = this.adsDTO.providerCode;
     }
     if(this.adsDTO.apiName == null || this.adsDTO.apiName == "" || this.adsDTO.apiName == undefined)
     {
       this.adsDTO.apiName= '';
     }
     else{
       this.adsDTO.apiName = this.adsDTO.apiName;
     }
     if(this.adsDTO.isLock == null || this.adsDTO.isLock == "" || this.adsDTO.isLock  == undefined)
     {
       this.adsDTO.isLock= '0';
     }
     else{
       this.adsDTO.isLock = this.adsDTO.isLock;
     }

     this.http.post(this.funct.ipaddress + 'ads/insertLockgscapis', this.adsDTO, { headers: headers })
     .pipe(
      catchError(this.handleError.bind(this))
           )
          .subscribe( 
            result => {
              this.dto.Response = {};
              this.dto.Response = result;
              if (this.dto.Response.status == 'Success') {
                this.spinner.hide();
                this.router.navigate(['/lockgscapis']).then(() => {
                  this.toastr.success(this.dto.Response.message, 'Success!', {
                    timeOut: 3000,
                    positionClass: 'toast-top-right'
                  });
                })
              }
              else {
                this.spinner.hide();
                this.toastr.error(this.dto.Response.message, 'Invalid!', {
                  timeOut: 3000,
                  positionClass: 'toast-top-right',
                });
              }
            }); 
    }  

  

  edit() {
    this.spinner.show();
    this.token = this.storage.retrieve('token');
    let headers = new HttpHeaders();
  
    headers = headers.set('Authorization', this.token);
    if(this.adsDTO.providerCode == null || this.adsDTO.providerCode == "" || this.adsDTO.providerCode == undefined)
    {
      this.adsDTO.providerCode= '';
    }
    else{
    this.adsDTO.providerCode = this.adsDTO.providerCode;
    }
    if(this.adsDTO.apiName == null || this.adsDTO.apiName == "" || this.adsDTO.apiName == undefined)
    {
      this.adsDTO.apiName= '';
    }
    else{
      this.adsDTO.apiName = this.adsDTO.apiName;
    }
    if(this.adsDTO.isLock == null || this.adsDTO.isLock == "" || this.adsDTO.isLock  == undefined)
    {
      this.adsDTO.isLock= '0';
    }
    else{
      this.adsDTO.isLock = this.adsDTO.isLock;
    }
    if(this.adsDTO.id == null || this.adsDTO.id == "" || this.adsDTO.id  == undefined)
    {
      this.adsDTO.id= '';
    }
    else{
      this.adsDTO.id = this.adsDTO.id;
    }

    this.http.post(this.funct.ipaddress + 'ads/lockgscapisupdate', this.adsDTO, { headers: headers })
    .pipe(
      catchError(this.handleError.bind(this))
     )
    .subscribe( 
      result => {
        this.dto.Response = {};
        this.dto.Response = result;
        if (this.dto.Response.status == 'Success') {
          this.spinner.hide();
          this.router.navigate(['/lockgscapis']).then(() => {
            this.toastr.success(this.dto.Response.message, 'Success!', {
              timeOut: 3000,
              positionClass: 'toast-top-right'
            });
          })
        }
        else {
          this.spinner.hide();
          this.toastr.error(this.dto.Response.message, 'Invalid!', {
            timeOut: 3000,
            positionClass: 'toast-top-right',
          });
        }
      });
  }

}
