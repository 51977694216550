<div class="wrapper">
  <app-header-sidebar></app-header-sidebar>

  <div class="content-wrapper">

    <div class="content-header">
      <div class="container-fluid">
        <div class="row mb-2" style="border: 1px solid #ced4da;border-radius: 0.25rem;">
          <div class="col-sm-8">
            <h3 class="m-0 text-dark" style="font-weight: bold;padding: 3px 0px 3px 5px;"> Search Member By Bank Account </h3>
          </div>
        </div>
      </div>
    </div>

    <section class="content">
      <div class="container-fluid">
        <form>
          <div class="row">

            <div class="form-group col-md-2">
              <label>Account No</label>
              <input class="form-control form-control-sm" [(ngModel)]="phone_no"
              (ngModelChange) = "onChangePhoneNo()" 
              [ngModelOptions]="{standalone: true}">
            </div>


            

            <div class="form-group col-md-2">
              <label>Bank Type</label>
              <select class="status form-control form-control-sm col-md-12 col-sm-4 col-xs-4"
                      [(ngModel)]="BankType" 
                      [ngModelOptions]="{standalone: true}"
                      (ngModelChange)="onChangeTagSection()">                
                <option *ngFor="let bt of bankTypeList" [value]="bt.bankType">
                  {{ bt.bankType }}
                </option>
                <option value="" selected>All</option>
              </select>
            </div>
            

          

            <div class="form-group col-md-2">
            </div>


            <div class="form-group col-md-2 col-sm-6 col-xs-6" *ngIf="hardcode_edit_bool === true">
              <label></label>
              <button id="search" type="button" class="btn btn-block btn-primary btn-sm" style="margin-top: 4%;"
                 (click)="Search()">Search</button>                
            </div> 


            <div class="col-md-12">
              <div class="card">
                <div class="card-header">
                  <h3 class="card-title" style="font-weight: bold;"> Search Member By Bank Account Details List </h3>
                </div>       
                <div class="card-body">
                  <table width='100%' id="{{'tblAgent' + idIndex}}" datatable [dtTrigger]="dtTrigger"
                    [dtOptions]="dtOptions" class="table table-bordered table-hover">
                    <thead>
                      <tr>
                        <th style="font-family: unicode;">Name</th>
                        <th style="font-family: unicode;" class="center-text">Phone No</th>      
                        <th style="font-family: unicode;" class="center-text">Bank Account</th> 
                        <th style="font-family: unicode;" class="center-text"> Account Name </th>                
                        <th style="font-family: unicode;" class="center-text">Current Wallet</th>
                        <th style="font-family: unicode;" class="center-text">Bank Type</th>
                        <th style="font-family: unicode;" class="center-text">Status</th>
                        <th style="font-family: unicode;" class="center-text">Created Date</th>
                                                                             
                        <th style="font-family: unicode;" class="center-text">Last Login Time</th>
                      </tr>
                     
                    </thead>
                    <tbody>
                      <tr *ngFor="let agent of agentList">
                        <td style="font-family: unicode;" class="center-text">{{agent.name}}</td>   
                        <!-- <td style="font-family: unicode;" class="center-text">
                          <a [routerLink] = "['/bank-acc-search-member-detail', agent.id]" style="text-decoration: underline; cursor: pointer;">{{agent.phone_no}}
                        </a></td> -->

                        <td style="font-family: unicode;" class="center-text">
                          <a [routerLink]="['/user-detail', agent.id]" [queryParams]="{ type: 'searchmemberbybankaccount' }">{{agent.phone_no}}</a>
                        </td>

                        <td style="font-family: unicode;" class="center-text">
                        {{agent.account_number}}
                        </td>
                        <td style="font-family: unicode;" class="center-text">
                          {{agent.account_name}}
                          </td>
                                            
                        <td style="font-family: unicode;" class="center-text">{{agent.balance | number: '1.0-0' }}</td>   
                        <td style="font-family: unicode;" class="center-text">
                          {{agent.payment_name}}
                          </td>   
                        <td style="font-family: unicode;" class="center-text">{{agent.status}}</td>                                       
                        <td style="font-family: unicode;" class="center-text">{{agent.created_date_Str}}</td>                                     
                                         
                        <td style="font-family: unicode;" class="center-text">{{agent.last_used_time}}</td>
              
                        
                      </tr>                      
                    </tbody>
                  </table>
                </div>              
              </div>
            </div>
          </div>
        </form>
      </div>      
    </section>
  </div>

  <aside class="control-sidebar control-sidebar-dark">   
  </aside>

  <app-footer></app-footer>
  <ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#fff" type="square-jelly-box">
    <p style="font-size: 16px; color: white">Loading...</p>
  </ngx-spinner>
</div>

<div id="deleteData" class="modal fade">
  <div class="modal-dialog">
      <div class="modal-content">

          <div class="modal-body">
              <form>

                  <div class="row" style="margin-top: 5%;margin-bottom: 3%;margin-left: 9%;">
                      <div class="form-group col-md-12">
                          <h5> This Account is used by another device. 
                              Please Sign Out. Login Again. </h5>
                      </div>
                  </div>

                  <div class="row" style="margin-left: 40%;margin-bottom: 3%;">
                      <div class="col-md-5 col-sm-6" id="deleteBtn">
                          <button type="button" class="btn btn-block btn-success" (click)="OkLogout()"> Ok </button>
                      </div>
                  </div>
              </form>
          </div>

      </div>
  </div>
</div>