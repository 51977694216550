import { Component, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,HttpErrorResponse } from '@angular/common/http';
import { Router, ActivatedRoute } from '@angular/router';
import { LocalStorageService } from 'ngx-webstorage';
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from 'ngx-toastr';
import { DtoService } from '../../service/dto.service';
import { FunctService } from '../../service/funct.service';
import { from, Subject } from 'rxjs';
import { DatePipe, NgStyle } from '@angular/common';
import * as XLSX from 'xlsx'; 
import * as fs from 'file-saver';
import { Workbook } from 'exceljs';
import { jsPDF } from "jspdf";
import 'jspdf-autotable'
import autoTable from 'jspdf-autotable'
declare const getCurrentDate: any;
declare var $: any;
import { catchError, retry } from 'rxjs/operators';
import { throwError } from 'rxjs';
import {IMultiSelectOption  } from 'ngx-bootstrap-multiselect';
import {IMultiSelectSettings } from 'ngx-bootstrap-multiselect';
import {IMultiSelectTexts} from 'ngx-bootstrap-multiselect';
import { PassThrough } from 'stream';
export class AppModule {
}
@Component({
  selector: 'app-user',
  templateUrl: './detailreports.component.html',
  styleUrls: ['./detailreports.component.css'],
  providers: [DatePipe]
})
export class DetailReportComponent implements OnInit {
  fromdate: string ='';
  todate: string = '';
  section: string= '';
  phoneno : string = ''; twodstatus :any;
  report_option : string ='';
  choose_msg : string = '';
  isActive : boolean = false;
  ispdfActive : boolean = false;
  iscsvActive : boolean = false;
  dtTrigger: Subject<any> = new Subject();
  clickkbzpay: any = true;
  clickwavepay: any = false;
  csvrep: any = false;
  filename : string = "";
  filename_date :any ;
  myDate = new Date();
  gameDate = new Date();
  orderby : string ='';
  adminObj : any;
  ad_name : any;
  twodBetRptList = [];
  twodBetRptPdfList = [];
  token :any;
  sectionList = [];
  isexist: any;
  widetail:any;
  resultData:any;
  tab:any;r3Type:any; rType:any;
  threedbetfromdate:any;threedbettodate:any;threedbetphoneno:any;
  topupfromdate:any;topuptodate:any;topupphoneno:any;topupstatus:any;
  withfromdate:any;withtodate:any;
  withphoneno:any;
  withstatus:any;
  alltranfromdate:any;alltrantodate:any;alltranphoneno:any;
  gametranphoneno:any;gametrantodate:any;gametranfromdate:any;
  threedBetRptList:any; threedwinnerRptList =[];
  threedBetRptPdfList :any; threedwinnerRptPdfList = [];threedstatus:any;
  twodwinnerRptList:any;withtype:any; 
  userFinancialRptList = [];paymentList:any;
  userFinancialRptPdfList = [];gameTranType:any;bankId:any;
  optionsModel: number[];myOptions:any;
  codeNumberList = [];
  mycodeOptions: IMultiSelectOption[];
  codeoptionsModel: number[];adminList:any;
  mySettings: IMultiSelectSettings;
  myTexts: IMultiSelectTexts;
  regDate: any;gameproviderList:any;providerId:any;

  hardcode_edit :any;
  hardcode_add :any;
  hardcode_view: any;
  hardcode_delete:any;
  hardcode_view_bool : any;
  hardcode_add_bool : any;
  hardcode_edit_bool : any;
  hardcode_delete_bool:any;
  hardcode_double_bool:any;

  constructor(private toastr: ToastrService, private datePipe: DatePipe,private storage: LocalStorageService, private spinner: NgxSpinnerService, private http: HttpClient, private dto: DtoService, private router: Router, private funct: FunctService,) {
    this.storage.store('isNotiSong', "");
    this.fromdate = this.datePipe.transform(this.myDate,'yyyy-MM-dd');
    this.todate = this.datePipe.transform(this.myDate,'yyyy-MM-dd');
    this.withfromdate = this.datePipe.transform(this.myDate,'yyyy-MM-dd');
    this.withtodate = this.datePipe.transform(this.myDate,'yyyy-MM-dd');
    this.gametranfromdate = this.datePipe.transform(this.myDate,'yyyy-MM-dd');
    this.gametrantodate = this.datePipe.transform(this.myDate,'yyyy-MM-dd');
    this.alltranfromdate = this.datePipe.transform(this.myDate,'yyyy-MM-dd');
    this.alltrantodate = this.datePipe.transform(this.myDate,'yyyy-MM-dd');
    this.threedbetfromdate = this.datePipe.transform(this.myDate,'yyyy-MM-dd');
    this.threedbettodate = this.datePipe.transform(this.myDate,'yyyy-MM-dd');
    this.tab = "twodbetTab";
    this.rType = "Bet";
    this.r3Type  = "Bet";
    this.twodstatus = 'CONFIRMED';
    this.withstatus = 'APPROVED';
    this.withtype = 'TOPUP';
    this.gameTranType = "";
    this.bankId = "" ;
    this.providerId = "";
    this.threedstatus = 'CONFIRMED';    

    this.hardcode_add = "UserTransactionReport_Add"; //UserDetailReport_Add
    this.dto.menuCodes = this.storage.retrieve('menucodes');
    const filteredMenuCodeds_add =[]; 

    this.dto.menuCodes.forEach(menuCode => {    
      if (menuCode === this.hardcode_add) {
        filteredMenuCodeds_add.push(menuCode);
      }    
      
    });
 
    if(filteredMenuCodeds_add.length != 0)
    {
       this.hardcode_add_bool = true;
    }
 
   
  }

   ngOnInit(): void 
    {
      if(!this.storage.retrieve('loadFlag')){
        this.storage.store('loadFlag', 'noLoad');
        setTimeout(function(){
          location.reload();
        }, 5);
      }
      else{
        this.storage.clear('loadFlag');
      }
      this.getCurrentAdmin();
      this.getAllSections();
      this.getTopupPayments();
      if(this.withtype =='TOPUP')
      {
        this.getAllAdmins();
      }
      else 
      {
          this.getAllAdminsWithWithdrawal();
      }
    
      this.getAllProvider();
       // Settings configuration
    this.mySettings = {
      enableSearch: true,
      checkedStyle: 'checkboxes',
      buttonClasses: 'btn btn-default btn-block',
      dynamicTitleMaxItems: 3,
      displayAllSelectedText: true,
      showCheckAll : true,
      showUncheckAll : true,
      autoUnselect : true,
      pullRight : false,
      closeOnSelect : false
    };

    // Text configuration
    this.myTexts = {
      checkAll: 'Select all',
      uncheckAll: 'Unselect all',
      checked: 'item selected',
      checkedPlural: 'items selected',
      searchPlaceholder: 'Find',
      searchEmptyResult: 'Nothing found...',
      searchNoRenderText: 'Type in search box to see results...',
      defaultTitle: 'Select',
      allSelected: 'All selected',
    };
    }
    // getAllProvider()
    // {
    //  this.dto.token = this.storage.retrieve('token');
    //  let headers = new HttpHeaders();
    //  headers = headers.set('Authorization',  this.dto.token);
    //  this.http.get(this.funct.ipaddress + 'gameProvider/getAllGameProviderListAdmin', {headers: headers })
    //  .pipe(
    //    catchError(this.handleError.bind(this))
    //   )
    //  .subscribe(
    //    result => {
    //      this.dto.Response = {};
    //      this.dto.Response = result;
    //      this.gameproviderList = this.dto.Response;
    //    });
    // }

    getAllProvider()
    {
     this.dto.token = this.storage.retrieve('token');
     let headers = new HttpHeaders();
     headers = headers.set('Authorization',  this.dto.token);
     this.http.get(this.funct.ipaddress + 'gameProvider/getGameProviderList', {headers: headers })
     .pipe(
       catchError(this.handleError.bind(this))
      )
     .subscribe(
       result => {
         this.dto.Response = {};
         this.dto.Response = result;
         this.gameproviderList = this.dto.Response; 
   
       });
    }
    getAllAdmins()
    {
      this.adminList =[];
      this.dto.token = this.storage.retrieve('token');
      let headers = new HttpHeaders();
      headers = headers.set('Authorization',  this.dto.token );
      this.http.get(this.funct.ipaddress + 'admin/GetAllAdmin', { headers: headers }).subscribe(
        result => {
          this.dto.Response = {};
          this.dto.Response = result;
          this.adminList = this.dto.Response;
          this.myOptions = this.adminList;
        }
      );
    }
    getAllAdminsWithWithdrawal()
    {
      this.adminList =[];
      this.dto.token = this.storage.retrieve('token');
      let headers = new HttpHeaders();
      headers = headers.set('Authorization',  this.dto.token );
      this.http.get(this.funct.ipaddress + 'admin/GetWithdrawalAdmin', { headers: headers }).subscribe(
        result => {
          this.dto.Response = {};
          this.dto.Response = result;
          this.adminList = this.dto.Response;
          this.myOptions = this.adminList;
        }
      );
    }
    getAllSections()
    {
      this.sectionList =[];
      this.token = this.storage.retrieve('token');
      let headers = new HttpHeaders();
      headers = headers.set('Authorization',  this.token);
      this.http.get(this.funct.ipaddress + 'twodsection/getTwodSectionList', { headers: headers }).subscribe( 
        result => {
          this.dto.Response = {};
          this.dto.Response = result;
          this.sectionList = this.dto.Response;
        }
      ); 
    }
    getReportOption(options)
    {
      this.report_option = options;
      this.choose_msg = "You choose "+this.report_option;
      if(options == "excel")
      {
        this.clickkbzpay =true;
        this.clickwavepay = false;
        this.csvrep =false;
      }
      if(options == "pdf")
      {
        this.clickkbzpay =false;
        this.clickwavepay = true;
        this.csvrep =false;
      }
      if(options == "csv")
        {
          this.clickkbzpay =false;
          this.clickwavepay = false;
          this.csvrep =true;
        }
    }
    handleError(error: HttpErrorResponse){
      this.spinner.hide();
    if(error.status == 423)
    {
      this.spinner.hide();
      $("#deleteData").modal("show");
    }
    if(error.status == 403)
    {
      this.spinner.hide();
      this.toastr.error("Limited Access.", 'Invalid!', {
        timeOut: 3000,
        positionClass: 'toast-top-right',
        });
    }
    if(error.status == 404)
    {
      this.spinner.hide();
      this.toastr.error("User Not Found.", 'Invalid!', {
        timeOut: 3000,
        positionClass: 'toast-top-right',
        });
    }
    return throwError(error);
    }
    OkLogout()
    {
      window.location.href ="./ad-login";
    } 
    getCurrentAdmin() {
      this.dto.token = this.storage.retrieve('token');
      let headers = new HttpHeaders();
      headers = headers.set('Authorization',this.dto.token);
      this.http.get(this.funct.ipaddress + 'admin/GetCurrentAdminDetail', { headers: headers }).subscribe(
        result => {
          this.dto.Response = {};
          this.dto.Response = result;
          this.adminObj = this.dto.Response;
          this.ad_name = this.adminObj.name;
        }
      );
    }
    //get register date

    checkRegisterDate() {
      this.spinner.show(); 
      this.resultData ="";
      if (this.alltranphoneno != ''  && this.alltranphoneno != null ) {
        this.token = this.storage.retrieve('token');
        let headers = new HttpHeaders();   
        headers = headers.set('Authorization', this.token);
        let params = new HttpParams();
        params = params.set('phoneno', this.alltranphoneno);    
         this.http.get(this.funct.ipaddress + 'loginGS/getDateByPhone', { params: params, headers: headers})
         .pipe(
           catchError(this.handleError.bind(this))
           )
        .subscribe( 
          result => {
            this.dto.Response = {};
            this.dto.Response = result;           
            this.widetail = this.dto.Response;
            if(this.widetail.length !=0)
            {
              for (let i = 0; i < this.widetail.length; i++) 
              {
                if (this.widetail[i].created_date != '')
                 {
                  this.spinner.hide(); 
                  this.regDate = this.widetail[i].created_date ; 
                 }
                 else 
                 {
                  this.spinner.hide();
                  this.toastr.error(this.dto.Response.errMsg, 'Invalid!', {
                    timeOut: 5000,
                    positionClass: 'toast-top-right',
                  });
                }  
              }
            }
            else
            {
              this.spinner.hide();
                  this.toastr.error('Register Date', 'Invalid!', {
                    timeOut: 5000,
                    positionClass: 'toast-top-right',
                  })
            }
  
          }
        );
      }
      else {
        this.spinner.hide();
        this.toastr.error('Please fill all fields', 'Invalid input!', {
          timeOut: 3000,
          positionClass: 'toast-top-right',
        });
      }
    }
   
    // checkRegisterDate()
    // {
    //   this.dto.token = this.storage.retrieve('token');
    //   let headers = new HttpHeaders();
    //   headers = headers.set('Authorization',this.dto.token);
    //   let params = new HttpParams();
    //   if(this.alltranphoneno == undefined)
    //   {
    //     this.toastr.error('Please enter phone number', 'Invalid!', {
    //       timeOut: 3000,
    //       positionClass: 'toast-top-right',
    //     });
    //     return;
    //   }
    //   params = params.set("phoneno",this.alltranphoneno)
    //   this.http.get(this.funct.ipaddress + 'loginGS/getDateByPhone', { headers: headers,params:params }).subscribe(
    //     result => {
    //       this.dto.Response = result;
    //       this.regDate = this.dto.Response.created_date;//created_date_Str;
    //     }
    //   );
    // }
  flagProvider()
  {
      this.providerId = $("#providerId").val();
      this.storage.clear('providerId');
      this.storage.store('providerId', Number(this.providerId));
  }
    IsExistPhoneNo() {
      this.spinner.show();    
      if (this.phoneno != ''  && this.phoneno != null ) {
        this.token = this.storage.retrieve('token');
        let headers = new HttpHeaders();   
        headers = headers.set('Authorization', this.token);
        let params = new HttpParams();
        params = params.set('userPhoneNo', this.phoneno);    
         this.http.get(this.funct.ipaddress + 'transaction/IsExistPhoneNo', { params: params, headers: headers})
       
    .pipe(
      catchError(this.handleError.bind(this))
     )
    .subscribe( 
      result => {
        this.dto.Response = {};
        this.dto.Response = result;
        this.isexist = this.dto.Response;
        if(this.isexist.length !=0)
        {
              if(this.rType == 'Bet')
              {
                this.twodReport();
              }
              else
              {
                this.twodWinningReport();
              }        
        }
        else 
        {
         this.spinner.hide();
         this.toastr.error('Phone Number', 'Invalid!', {
           timeOut: 5000,
           positionClass: 'toast-top-right',
         });
       }
      });
  
      }
      else {
        this.spinner.hide();
        this.toastr.error('Please fill all fields', 'Invalid input!', {
          timeOut: 3000,
          positionClass: 'toast-top-right',
        });
      }
    }
    twodReportUI()
    {
      this.IsExistPhoneNo();
      // if(this.rType == 'Bet')
      // {
      //   this.twodReport();
      // }
      // else
      // {
      //   this.twodWinningReport();
      // }
    }
    twodReport(){
    var section = this.section;
    var repType = '';
    this.spinner.show();
    this.twodBetRptList = [];
    this.twodBetRptPdfList = [];
    var grand_total = 0;
    this.dto.token = this.storage.retrieve('token');
    if(this.report_option =='')
    {
      this.report_option = 'excel';
    }
    let headers = new HttpHeaders();
    headers = headers.set('Authorization', this.dto.token);
    let params = new HttpParams();
    if(this.phoneno != '')
    {

    params = params.set('fromdate',this.fromdate).set('todate',this.todate).set('userPhoneNo',this.phoneno).set('orderby',this.orderby).set('section',this.section).set('report_option', this.report_option);
     if(this.report_option == 'excel')
        {
           repType = ".xlsx";
        }
        if(this.report_option == 'pdf')
        {
            repType = '.pdf';
        }
        if(this.report_option == "csv")
        {
          repType = ".csv";
        }
      this.http.get(this.funct.ipaddress + 'twodreport/twoDBetDetails', { params: params, headers: headers})
      .pipe(
        catchError(this.handleError.bind(this))
       )
      .subscribe(data => 
        {
        this.filename_date = this.datePipe.transform( new Date(),'dd-MM-yyyy');
        this.filename = "twoDBetHistory"+section+"_"+this.filename_date;
        if(data == null)
        {
          this.toastr.error('No Data Found', 'Invalid input!', {
            timeOut: 3000,
            positionClass: 'toast-top-right',
          });
        }
        else{
          this.spinner.hide();
          this.dto.Response = {};
          //this.dto.Response = data.retList;
          this.dto.Response = data;
          this.dto.Response.forEach((row: any) => {
            this.twodBetRptList.push(Object.values(row));
            this.twodBetRptPdfList.push(Object.values(row));
            var result = row.total_amount_Str.toString().replace(/,/g, "");
            grand_total = grand_total + parseInt(result) ;
          })
          if(this.report_option == "excel")
        {                
                var title = '';
                if(this.section == ''){
                   title = 'TwoD Detail Bet History Report (All)';
                }
                else
                   title = 'TwoD Detail Bet History Report ('+this.section+')';
               
                const header = ['No','User Name','Phone No', 'Bet Date', 'Bet Time','Created Date', 'Odd','2D Number', 'Amount'];
                // Create workbook and worksheet
                const workbook = new Workbook();
                const worksheet = workbook.addWorksheet('TwoD Detail Bet History Report');
                // Add Row and formatting
                const titleRow = worksheet.addRow([title]);
                titleRow.font = { name: 'Corbel', family: 4, size: 16, underline: 'double', bold: true };
                worksheet.mergeCells('A1:F2');
                // Set the style for each cell in the row
                titleRow.eachCell((cell) => {
                  cell.style = {
                    font:{ name: 'Corbel', family: 4, size: 16, underline: 'double', bold: true },
                    alignment:{horizontal:'left'},
                  };
                });
                const pdRow = worksheet.addRow(['Printed Date : '+this.filename_date]);
                worksheet.mergeCells('A3:C3');
                 // Set the style for each cell in the row
                 pdRow.eachCell((cell) => {
                  cell.style = {
                    alignment:{horizontal:'left'},
                  };
                });
               
                const prRow = worksheet.addRow(['Printed By : '+this.ad_name +' - From Date : '+this.fromdate+' - To Date : '+this.todate]);
                worksheet.mergeCells('A4:E4');
                // Set the style for each cell in the row
                prRow.eachCell((cell) => {
                  cell.style = {
                   // font: { bold: true },
                    alignment:{horizontal:'left'},
                   // fill: { type: 'pattern', pattern: 'solid', fgColor: { argb: 'CCCCCC' } },
                  };
                });
                const headerRow = worksheet.addRow(header);
                  // Cell Style : Fill and Border
                  headerRow.eachCell((cell, number) => 
                  {
                    cell.fill = {
                    type: 'gradient',
                    gradient: 'angle',
                    degree: 0,
                    stops: [
                      {position:0, color:{argb:'ff095108'}},
                      {position:0.5, color:{argb:'ff095108'}},
                      {position:1, color:{argb:'ff095108'}}
                    ],
                    };
                    cell.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
                    cell.alignment = {horizontal:'center' };
                    cell.font= {color:{argb:'FFFFFF'}};
                    });
              
                  var tot = 0;
                  this.twodBetRptList.forEach(d => {
                  const row = worksheet.addRow(d);
                  const qty = row.getCell(9).value;
                  row.getCell(1).alignment = { horizontal: 'center' ,wrapText: true};
                  row.getCell(6).alignment = { horizontal: 'center' ,wrapText: true};
                  row.getCell(2).alignment = { horizontal: 'left' ,wrapText: true};
                  row.getCell(9).alignment = { horizontal: 'right' ,wrapText: true};
                  var result = qty.toString().replace(/,/g, "");
                  tot = parseInt(result) + tot;
                  }
              );
              worksheet.getColumn(2).width = 20;
              worksheet.getColumn(3).width = 20;
              worksheet.getColumn(4).width = 20;   
              worksheet.getColumn(4).alignment = { horizontal: 'center', wrapText: true }                         
              worksheet.getColumn(6).width = 20;
              worksheet.getColumn(6).alignment = { horizontal: 'center', wrapText: true }
              worksheet.getColumn(7).width = 15;
              worksheet.getColumn(7).alignment = { horizontal: 'center', wrapText: true }
              worksheet.getColumn(8).width = 18;
              worksheet.getColumn(8).alignment = { horizontal: 'center', wrapText: true };
              worksheet.getColumn(9).width = 18;                 
             worksheet.getColumn(9).alignment = { horizontal: 'right', wrapText: true };

              // Footer Row
              const footerRow = worksheet.addRow([]);
              footerRow.getCell(8).value = 'Grand Total';
              footerRow.getCell(9).value = tot.toLocaleString();
              footerRow.getCell(9).alignment = {horizontal:'right'};
              footerRow.getCell(8).border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
              footerRow.getCell(8).fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: { argb: 'FFCCFFE5' }
                };
              footerRow.getCell(9).border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
              footerRow.getCell(9).fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: { argb: 'FFCCFFE5' }
                };
                footerRow.getCell(9).alignment ={horizontal:'right'};
              workbook.xlsx.writeBuffer().then((data: any) => {
              const blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
              fs.saveAs(blob, this.filename + repType);
              });
        }
        if(this.report_option == "pdf")
        {
          var totalList = ['', '', '', 'Grand Total',grand_total.toLocaleString()];
          this.twodBetRptPdfList.push(totalList)
          this.convert(this.twodBetRptPdfList, this.filename+repType);
        }
        if(this.report_option == "csv")
        {
          this.downloadFile(data,this.filename+repType);
        }
          }
      });
   }
  else {
    this.spinner.hide();
    this.toastr.error('Please enter phone no.', 'Invalid input!', {
      timeOut: 3000,
      positionClass: 'toast-top-right',
    });
  }
    }
    convert(list, pdfname){
      var doc = new jsPDF('p', 'pt','a4');
      const head = [['No', 'Bet Date','Created Date', 'Bet Time', '2D Number', 'Amount']];
      doc.setFontSize(12);
      doc.setTextColor(0);
      var tot = 0;
      var title = '';
      if(this.section == ''){
         title = '2D Detail Bet History Report (All)';
      }
      else
         title = '2D Detail Bet History Report ('+this.section+')';
      doc.text(title, 40, 20);
      doc.text('Printed By : '+this.ad_name+' - Printed Date : '+this.filename_date,40,40);
      autoTable(doc,
      {
      head: head,
      body: list,
      startY: 50,
      styles: { overflow: "linebreak" },
      didDrawCell: (list) => {
      },
      didParseCell:(list)=>{
        var rows = list.table.body;
        if (list.row.index === rows.length - 1) {
            list.cell.styles.fillColor = [239, 154, 154];
        }
      }
      })
      doc.save(pdfname);
      }
    downloadFile(data, filename) {
        let csvData = this.ConvertToCSV(data, ['sr_no','user_name','phone_no', 'bet_date_Str','bet_time','created_date_str','odd','twod_number','total_amount_Str']);
   
        console.log(csvData)
        let blob = new Blob([csvData], { type: 'text/csv;charset=utf-8;' });
        let dwldLink = document.createElement("a");
        let url = URL.createObjectURL(blob);
        let isSafariBrowser = navigator.userAgent.indexOf('Safari') != -1 && navigator.userAgent.indexOf('Chrome') == -1;
        if (isSafariBrowser) {  
            dwldLink.setAttribute("target", "_blank");
        }
        dwldLink.setAttribute("href", url);
        dwldLink.setAttribute("download", filename + ".csv");
        dwldLink.style.visibility = "hidden";
        document.body.appendChild(dwldLink);
        dwldLink.click();
        document.body.removeChild(dwldLink);
      }
      downloadFileWinning(data, filename) {
        let csvData = this.ConvertToCSV(data, ['sr_no','name','phoneNo','for_time','created_date','confirmed_by','number','odd','bet_amount_Str','balance_Str']);
   
        console.log(csvData)
        let blob = new Blob([csvData], { type: 'text/csv;charset=utf-8;' });
        let dwldLink = document.createElement("a");
        let url = URL.createObjectURL(blob);
        let isSafariBrowser = navigator.userAgent.indexOf('Safari') != -1 && navigator.userAgent.indexOf('Chrome') == -1;
        if (isSafariBrowser) {  
            dwldLink.setAttribute("target", "_blank");
        }
        dwldLink.setAttribute("href", url);
        dwldLink.setAttribute("download", filename + ".csv");
        dwldLink.style.visibility = "hidden";
        document.body.appendChild(dwldLink);
        dwldLink.click();
        document.body.removeChild(dwldLink);
      }
      downloadFileWithAll(data, filename) {
        let csvData = this.ConvertToCSV(data, ['sr_no','cashIn','cashOut', 'amount','wallet','createdDate','approvedDate']);   
        console.log(csvData)
        let blob = new Blob([csvData], { type: 'text/csv;charset=utf-8;' });
        let dwldLink = document.createElement("a");
        let url = URL.createObjectURL(blob);
        let isSafariBrowser = navigator.userAgent.indexOf('Safari') != -1 && navigator.userAgent.indexOf('Chrome') == -1;
        if (isSafariBrowser) {  
            dwldLink.setAttribute("target", "_blank");
        }
        dwldLink.setAttribute("href", url);
        dwldLink.setAttribute("download", filename + ".csv");
        dwldLink.style.visibility = "hidden";
        document.body.appendChild(dwldLink);
        dwldLink.click();
        document.body.removeChild(dwldLink);

      }
    ConvertToCSV(objArray, headerList) {
        let array = typeof objArray != 'object' ? JSON.parse(objArray) : objArray;
        let str = '';
        let row = '';
        for (let index in headerList) {
            row += headerList[index] + ',';
        }
        row = row.slice(0, -1);
        str += row + '\r\n';
        for (let i = 0; i < array.length; i++) {
            let line = '';
            for (let index in headerList) {
              let head = headerList[index];
              var d = array[i][head];
              if(d != null)
              {
              if(d.toString().includes(","))
                  d = d.toString().replace(/,/g, "");
              }
              line +=  d + ",";
            }
            line = line.slice(0,-1);
            str += line + '\r\n';
        }
      return str;
      }
    twodWinningReport()
      {
          var repType = '';
          this.spinner.show();
          this.twodwinnerRptList = [];
          this.dto.token = this.storage.retrieve('token');
          if(this.report_option =='')
          {
            this.report_option = 'excel';
          }        
          let headers = new HttpHeaders();
          headers = headers.set('Authorization', this.dto.token);
          let params = new HttpParams();
          params = params.set('fromdate',this.fromdate).set('todate',this.todate).set('status',this.twodstatus).set('userPhoneNo',this.phoneno).set('section',this.section).set('report_option', this.report_option);
           if(this.report_option == 'excel')
              {
                 repType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
                 repType = ".xlsx";
              }
              if(this.report_option == 'pdf')
              {
                  repType = 'application/pdf';
                  repType = ".pdf";
              }
              if(this.report_option == "csv")
              {
                repType = "application/vnd.ms-excel";
                repType = ".csv";
              }
            this.http.get(this.funct.ipaddress + 'twodwinnerlistreport/twoddetailwinnerlistreport', { params: params, headers: headers})
            .pipe(
              catchError(this.handleError.bind(this))
             )
            .subscribe(data => {
              this.filename_date = this.datePipe.transform( new Date(),'dd-MM-yyyy');
              this.filename = "twoDWinnerList"+this.twodstatus+"_"+this.filename_date;
              this.spinner.hide();
              this.dto.Response = {};        
              this.dto.Response = data;
              this.dto.Response.forEach((row: any) => {
                this.twodwinnerRptList.push(Object.values(row))
              })
              if(this.report_option == "pdf")
              {
                var pdfname = this.filename + repType;        
              }
              if(this.report_option == "csv")
              {
                this.downloadFileWinning(data,this.filename+repType);
              }
              if(this.report_option == "excel")
              {
            // Excel Title, Header, Data ,responseType: 'arraybuffer'
            if(this.twodstatus == '')
            {
              this.twodstatus = 'All';
            }
            const title = 'TwoD Winner Detail Report ('+this.twodstatus+')';          
            const header = ['No', 'Name', 'Phone Number','Section','Created Date','Confirmed By','2D Number','Odd','Bet Amount', 'Win Amount'];  
            // Create workbook and worksheet
            const workbook = new Workbook();
            const worksheet = workbook.addWorksheet('TwoD Winner Detail Report');
            // Add Row and formatting
            const titleRow = worksheet.addRow([title]);
            titleRow.font = { name: 'Corbel', family: 4, size: 16, underline: 'double', bold: true };
            worksheet.addRow([]);
            const subTitleRow = worksheet.addRow(['Printed Date : '+this.filename_date]);
            worksheet.mergeCells('A3:E3');
            worksheet.mergeCells('A1:E2');
          
            worksheet.addRow(['Printed By : '+this.ad_name + ' - From Date : '+this.fromdate+' - To Date : '+this.todate]);
          
            worksheet.mergeCells('A4:E4');
              // Add Header Row
            const headerRow = worksheet.addRow(header);
              // Cell Style : Fill and Border
              headerRow.eachCell((cell, number) => {
                cell.fill = {
                type: 'gradient',
                gradient: 'angle',
                degree: 0,
                stops: [
                  {position:0, color:{argb:'ff095108'}},
                  {position:0.5, color:{argb:'ff095108'}},
                  {position:1, color:{argb:'ff095108'}}
                ],
                };
                cell.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
                cell.alignment = {horizontal:'center' };
                cell.font= {color:{argb:'FFFFFF'}};
                });
              // Add Data and Conditional Formatting
              var tot = 0;var betTot = 0;
              this.twodwinnerRptList.forEach(d => {
              const row = worksheet.addRow(d);
              const qty = row.getCell(10).value;
              row.getCell(1).alignment = { horizontal: 'center' ,wrapText: true};
              var result = qty.toString().replace(/,/g, "");
              tot = parseInt(result) + tot;
         
              row.getCell(4).alignment = {horizontal : 'center'};
              row.getCell(5).alignment = {horizontal : 'center'};
              row.getCell(6).alignment = {horizontal : 'center'};
              row.getCell(7).alignment = {horizontal : 'center'};
              row.getCell(8).alignment = {horizontal : 'center'};
              row.getCell(9).alignment = {horizontal : 'right'};
              row.getCell(10).alignment = {horizontal : 'right'};

              }
          );
          worksheet.getColumn(2).width = 20;
          worksheet.getColumn(3).width = 20;    
          worksheet.getColumn(4).width = 20;
          worksheet.getColumn(5).width = 20; 
          worksheet.getColumn(6).width = 20;
          worksheet.getColumn(7).width = 18;    
          worksheet.getColumn(8).width = 15;
          worksheet.getColumn(9).width = 15;
          worksheet.getColumn(10).width = 15;  
          // worksheet.getColumn(10).alignment = { horizontal: 'center', wrapText: true }; 
           // Footer Row
           const footerRow = worksheet.addRow([]);
           footerRow.getCell(9).value = 'Grand Total';
           footerRow.getCell(10).value = tot.toLocaleString();
           footerRow.getCell(9).fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: 'FFCCFFE5' }
            };       
       
           footerRow.getCell(10).value = tot.toLocaleString();
           footerRow.getCell(10).alignment = {horizontal:'right'};
           footerRow.getCell(10).fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: 'FFCCFFE5' }
            };
            footerRow.getCell(9).border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
           footerRow.getCell(9).fill = {
           type: 'pattern',
           pattern: 'solid',
           fgColor: { argb: 'FFCCFFE5' }
           };
           footerRow.getCell(9).border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
           footerRow.getCell(10).fill = {
           type: 'pattern',
           pattern: 'solid',
           fgColor: { argb: 'FFCCFFE5' }
           };
           footerRow.getCell(10).border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
            workbook.xlsx.writeBuffer().then((data: any) => {
            const blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
            fs.saveAs(blob, this.filename + repType);
            });
              }
              this.toastr.success('Reporting Success', 'Success!', {
                timeOut: 3000,
                positionClass: 'toast-top-right',
              });
            this.spinner.hide();
            });
      }
    //end of twod 

    IsExistPhoneNoFor3D() 
    {
      this.spinner.show();    
      if (this.threedbetphoneno != ''  && this.threedbetphoneno != null || this.threedbetphoneno != undefined) 
      {
            this.token = this.storage.retrieve('token');
            let headers = new HttpHeaders();   
            headers = headers.set('Authorization', this.token);
            let params = new HttpParams();
            params = params.set('userPhoneNo', this.threedbetphoneno);    
            this.http.get(this.funct.ipaddress + 'transaction/IsExistPhoneNo', 
            { params: params, headers: headers})
          
            .pipe(
              catchError(this.handleError.bind(this))
            )
            .subscribe( 
              result =>
              {
                    this.dto.Response = {};
                    this.dto.Response = result;
                    this.isexist = this.dto.Response;
                    if(this.isexist.length !=0)
                    {
                      if(this.r3Type == "Bet")
                      {
                        this.threedBetReport();
                      }
                      else{
                        this.threedwinnerListReport();
                      }  
                    }
                    else 
                    {
                        this.spinner.hide();
                        this.toastr.error('Phone Number', 'Invalid!', 
                        {
                          timeOut: 5000,
                          positionClass: 'toast-top-right',
                        });
                    }
              });
  
      }
      else 
      {
              this.spinner.hide();
              this.toastr.error('Please fill all fields', 'Invalid input!', {
                timeOut: 3000,
                positionClass: 'toast-top-right',
              });
      }
    }

    //start of threed
    threedReportUi()
    {
      this.IsExistPhoneNoFor3D();
      
    }
  threedBetReport(){
    var repType = '';
    this.spinner.show();
    this.threedBetRptList = [];
    this.threedBetRptPdfList = [];
    var grand_total = 0;
    this.dto.token = this.storage.retrieve('token');
    if(this.report_option =='')
    {
      this.report_option = 'excel';
    }
    let headers = new HttpHeaders();
    headers = headers.set('Authorization', this.dto.token);
    let params = new HttpParams();
    if(this.threedbetphoneno != '')
    {
    params = params.set('fromdate',this.threedbetfromdate).set('todate',this.threedbettodate).set('userPhoneNo',this.threedbetphoneno).set('report_option', this.report_option);
     if(this.report_option == 'excel')
        {
           repType = ".xlsx";
        }
        if(this.report_option == 'pdf')
        {
            repType = '.pdf';
        }
        if(this.report_option == "csv")
        {
          repType = ".csv";
        }   
        this.http.get(this.funct.ipaddress + 'threedreport/threeDBetDetails', { params: params, headers: headers})
        .pipe(
        catchError(this.handleError.bind(this))
       )
      .subscribe(data => {
        this.filename_date = this.datePipe.transform( new Date(),'dd-MM-yyyy');
        this.filename = "threeDBetHistory_"+this.filename_date;
        if(data == null)
        {
          this.toastr.error('No Data Found', 'Invalid input!', {
            timeOut: 3000,
            positionClass: 'toast-top-right',
          });
        }
        else{
          this.spinner.hide();
          this.dto.Response = {};
          //this.dto.Response = data.retList;
          this.dto.Response = data;
          this.dto.Response.forEach((row: any) => {
            this.threedBetRptList.push(Object.values(row));
            this.threedBetRptPdfList.push(Object.values(row));
            var result = row.total_amount_Str.toString().replace(/,/g, "");
            grand_total = grand_total + parseInt(result) ;
          })
        if(this.report_option == "excel")
          {
         
            const title = 'ThreeD Detail Bet History Report';
            const header = ['No','User Name','Phone No', 'Created Date', 'Result Date','Odd','3D Number', 'Amount'];
  
            const workbook = new Workbook();
            const worksheet = workbook.addWorksheet('ThreeD Detail Bet History Report');
    
            const titleRow = worksheet.addRow([title]);
            titleRow.font = { name: 'Corbel', family: 4, size: 16, underline: 'double', bold: true };
            worksheet.addRow([]);
            const subTitleRow = worksheet.addRow(['Printed Date : '+this.filename_date]);
            worksheet.mergeCells('A3:F3');
            worksheet.mergeCells('A1:F2');
  
            worksheet.addRow(['Printed By : '+this.ad_name + ' - From Date : '+this.threedbetfromdate+' - To Date : '+this.threedbettodate]);
       
            worksheet.mergeCells('A4:F4');

            const headerRow = worksheet.addRow(header);

              headerRow.eachCell((cell, number) => {
                    cell.fill = {
                    type: 'gradient',
                    gradient: 'angle',
                    degree: 0,
                    stops: [
                      {position:0, color:{argb:'ff095108'}},
                      {position:0.5, color:{argb:'ff095108'}},
                      {position:1, color:{argb:'ff095108'}}
                    ],
                    };
                    cell.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
                    cell.alignment = {horizontal:'center' };
                    cell.font= {color:{argb:'FFFFFF'}};
              });
     
              var tot = 0;
              this.threedBetRptList.forEach(d => {
              const row = worksheet.addRow(d);
              const qty = row.getCell(8).value;//7
              var result = qty.toString().replace(/,/g, "");
              tot = parseInt(result) + tot;
              row.alignment = {horizontal :'center',wrapText: true};
              row.getCell(8).alignment ={ horizontal: 'right', wrapText: true };
              row.getCell(2).alignment = { horizontal: 'left' ,wrapText: true};
              row.getCell(7).alignment = { horizontal: 'center' ,wrapText: true};
           
              }
            );
            worksheet.getColumn(2).width = 20;
            worksheet.getColumn(3).width = 20;
            worksheet.getColumn(4).width = 20;  
            worksheet.getColumn(4).alignment = { horizontal: 'center', wrapText: true }; 
            worksheet.getColumn(5).width = 20;
            worksheet.getColumn(5).alignment = { horizontal: 'center', wrapText: true };                         
            worksheet.getColumn(6).width = 15;
            worksheet.getColumn(6).alignment = { horizontal: 'center', wrapText: true };
            worksheet.getColumn(7).width = 18;
            worksheet.getColumn(7).alignment = { horizontal: 'center', wrapText: true };
            worksheet.getColumn(8).width = 18;
            // worksheet.getColumn(8).alignment = { horizontal: 'center', wrapText: true };
  
            const footerRow = worksheet.addRow([]);
            footerRow.getCell(7).value = 'Grand Total';
            footerRow.getCell(8).value = tot.toLocaleString();
            footerRow.getCell(8).alignment = {horizontal:'right',wrapText:true};
            footerRow.getCell(7).fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: 'FFCCFFE5' }
            };
            footerRow.getCell(7).border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
            footerRow.getCell(8).fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: 'FFCCFFE5' }
            };
            footerRow.getCell(7).border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
            
            workbook.xlsx.writeBuffer().then((data: any) => {
            const blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
            fs.saveAs(blob, this.filename + repType);
            });
          }
        if(this.report_option == "pdf")
        {
          var totalList = ['', '','','', 'Grand Total',grand_total.toLocaleString()];
          this.threedBetRptList.push(totalList)
          this.convert(this.threedBetRptPdfList, this.filename+repType);
        }
        if(this.report_option == "csv")
        {
          this.downloadFile3D(data,this.filename+repType);
        }
          }
      });
   }
  else {
    this.spinner.hide();
    this.toastr.error('Please enter phone no.', 'Invalid input!', {
      timeOut: 3000,
      positionClass: 'toast-top-right',
    });
  }
    }
  convert3D(list, pdfname)
  {
        var doc = new jsPDF('p', 'pt','a4');
        const head = [['No', 'Bet Date', '3D Number', 'Amount']];
        doc.setFontSize(12);
        doc.setTextColor(0);
        var tot = 0;
        var title = '';
        title = '3D Detail Bet History Report';
        doc.text(title, 40, 20);
        doc.text('Printed By : '+this.ad_name+' - Printed Date : '+this.filename_date,40,40);
        autoTable(doc,
        {
        head: head,
        body: list,
        startY: 50,
        styles: { overflow: "linebreak" },
        didDrawCell: (list) => {
        },
        didParseCell:(list)=>{
          var rows = list.table.body;
          if (list.row.index === rows.length - 1) {
              list.cell.styles.fillColor = [239, 154, 154];
          }
        }
        })
        doc.save(pdfname);
        }
  downloadFile3D(data, filename) {
          let csvData = this.ConvertToCSV(data, ['sr_no','user_name', 'phone_no','created_date_str','result_date_str','odd','threed_number','total_amount_Str']);
          console.log(csvData)
          let blob = new Blob([csvData], { type: 'text/csv;charset=utf-8;' });
          let dwldLink = document.createElement("a");
          let url = URL.createObjectURL(blob);
          let isSafariBrowser = navigator.userAgent.indexOf('Safari') != -1 && navigator.userAgent.indexOf('Chrome') == -1;
          if (isSafariBrowser) {  
              dwldLink.setAttribute("target", "_blank");
          }
          dwldLink.setAttribute("href", url);
          dwldLink.setAttribute("download", filename + ".csv");
          dwldLink.style.visibility = "hidden";
          document.body.appendChild(dwldLink);
          dwldLink.click();
          document.body.removeChild(dwldLink);
      }
      downloadFileWithTopup(data, filename) 
      {
         let csvData = this.ConvertToCSV(data, ['sr_no','name', 'phone_no','updated_date','created_date_Str','updated_by','status','payment','current_wallet','transaction_no_acctount','amount_Str']);

    
        console.log(csvData)
        let blob = new Blob([csvData], { type: 'text/csv;charset=utf-8;' });
        let dwldLink = document.createElement("a");
        let url = URL.createObjectURL(blob);
        let isSafariBrowser = navigator.userAgent.indexOf('Safari') != -1 && navigator.userAgent.indexOf('Chrome') == -1;
        if (isSafariBrowser) {  
            dwldLink.setAttribute("target", "_blank");
        }
        dwldLink.setAttribute("href", url);
        dwldLink.setAttribute("download", filename + ".csv");
        dwldLink.style.visibility = "hidden";
        document.body.appendChild(dwldLink);
        dwldLink.click();
        document.body.removeChild(dwldLink);
    }
    downloadFileWithGameTransaction(data, filename) {
      let csvData = this.ConvertToCSV(data, ['sr_no','name','created_date','method','game_user_id','transaction_id','privider_name','amount']);
      console.log(csvData)
      let blob = new Blob([csvData], { type: 'text/csv;charset=utf-8;' });
      let dwldLink = document.createElement("a");
      let url = URL.createObjectURL(blob);
      let isSafariBrowser = navigator.userAgent.indexOf('Safari') != -1 && navigator.userAgent.indexOf('Chrome') == -1;
      if (isSafariBrowser) {  
          dwldLink.setAttribute("target", "_blank");
      }
      dwldLink.setAttribute("href", url);
      dwldLink.setAttribute("download", filename + ".csv");
      dwldLink.style.visibility = "hidden";
      document.body.appendChild(dwldLink);
      dwldLink.click();
      document.body.removeChild(dwldLink);
  }
  ConvertToCSV3D(objArray, headerList) {
        let array = typeof objArray != 'object' ? JSON.parse(objArray) : objArray;
        let str = '';
        let row = '';
        for (let index in headerList) {
            row += headerList[index] + ',';
        }
        row = row.slice(0, -1);
        str += row + '\r\n';
        for (let i = 0; i < array.length; i++) {
            let line = '';
            for (let index in headerList) {
               let head = headerList[index];
               var d = array[i][head];
               if(d != null)
               {
               if(d.toString().includes(","))
                  d = d.toString().replace(/,/g, "");
               }
               line +=  d + ",";
            }
            line = line.slice(0,-1);
            str += line + '\r\n';
        }
      return str;
      }
  threedwinnerListReport(){
        var status = this.threedstatus;
        this.threedwinnerRptList =[];
        this.threedwinnerRptPdfList = [];
        var tot11 = 0 , tot22 = 0;
        var repType = '';
        this.spinner.show();
        this.dto.token = this.storage.retrieve('token');
        if(this.report_option =='')
        {
          this.report_option = 'excel';
        }    
        let headers = new HttpHeaders();
        headers = headers.set('Authorization',  this.dto.token);
        let params = new HttpParams();
        params = params.set('fromdate',this.threedbetfromdate).set('todate',this.threedbettodate).set('status',this.threedstatus).set('userPhoneNo',this.threedbetphoneno).set('report_option', this.report_option);
         if(this.report_option == 'excel')
            {
               repType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
               repType = ".xlsx";
            }
            if(this.report_option == 'pdf')
            {
                repType = 'application/pdf';
                repType = ".pdf";
            }
            if(this.report_option == "csv")
            {
              repType = "application/vnd.ms-excel";
              repType = ".csv";
            } 
          //  this.http.get(this.funct.ipaddress + 'threedwinnerlistreport/threedwinnerDetailslistreport', { params: params, headers: headers})
          //  .pipe(
            this.http.get(this.funct.ipaddress + 'threedwinnerlistreport/threeddetailwinnerlistreport', { params: params, headers: headers})
            .pipe(
            catchError(this.handleError.bind(this))
           )
           .subscribe(data => {
            this.filename_date = this.datePipe.transform( new Date(),'dd-MM-yyyy');
            this.filename = "threeDWinnerList"+status+"_"+this.filename_date;
            this.spinner.hide();
            this.dto.Response = {};
            // this.dto.Response = data.retList;
            this.dto.Response = data;
            this.dto.Response.forEach((row: any) =>
            {
              var result1 = row.bet_amount_Str.toString().replace(/,/g, "");
              var result2 = row.balance_Str.toString().replace(/,/g, "");
              tot11 = tot11+ parseInt(result1) ;
              tot22 = tot22+ parseInt(result2) ;
              this.threedwinnerRptPdfList.push(Object.values(row))
              this.threedwinnerRptList.push(Object.values(row))
            })
            if(this.report_option == "pdf")
            {
              var pdfname = this.filename + repType;
              var totalList = [ '', '', '', '', 'Grand Total', tot11.toLocaleString(),tot22.toLocaleString()];
              this.threedwinnerRptPdfList.push(totalList)
              //this.convert(this.threedwinnerRptPdfList,pdfname);
            }
            if(this.report_option == "csv")
            {
              this.downloadFile(data,this.filename+repType)
            }
            if(this.report_option == "excel")
            {
            // Excel Title, Header, Data ,responseType: 'arraybuffer'
            if(this.threedstatus == '')
            {
              this.threedstatus = 'All';
            }
            const title = 'ThreeD Winner Detail Report ('+this.threedstatus+')';
            // const header = ['No', 'Bet/Created Date','3D Number','Odd','Bet Amount', 'Win Amount','Confirm By'];
            const header = ['No', 'Name', 'PhoneNo','3D Number','Confirmed Date','Result Date','Confirmed By','Odd','Bet Amount', 'Win Amount'];  
            // Create workbook and worksheet
            const workbook = new Workbook();
            const worksheet = workbook.addWorksheet('ThreeD Winner Detail Report');
            // Add Row and formatting
            const titleRow = worksheet.addRow([title]);
            titleRow.font = { name: 'Corbel', family: 4, size: 16, underline: 'double', bold: true };
            worksheet.addRow([]);
            const subTitleRow = worksheet.addRow(['Printed Date : '+this.filename_date]);
            worksheet.mergeCells('A3:E3');
            worksheet.mergeCells('A1:E2');
              // Blank Row        
            worksheet.addRow(['Printed By : '+this.ad_name + ' - From Date : '+this.threedbetfromdate+' - To Date : '+this.threedbettodate]);
            worksheet.mergeCells('A4:D4');
              // Add Header Row
            const headerRow = worksheet.addRow(header);
              // Cell Style : Fill and Border
              headerRow.eachCell((cell, number) => {
                cell.fill = {
                type: 'gradient',
                gradient: 'angle',
                degree: 0,
                stops: [
                  {position:0, color:{argb:'ff095108'}},
                  {position:0.5, color:{argb:'ff095108'}},
                  {position:1, color:{argb:'ff095108'}}
                ],
                };
                cell.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
                cell.alignment = {horizontal:'center' };
                cell.font= {color:{argb:'FFFFFF'}};
                });
              // Add Data and Conditional Formatting
              var tot = 0, tot1= 0;
              this.threedwinnerRptList.forEach(d => {
              const row = worksheet.addRow(d);
              const qty = row.getCell(10).value; //bet
              var result = qty.toString().replace(/,/g, "");
              const qty1 = row.getCell(10).value; //win
              var result1 = qty1.toString().replace(/,/g, "");
              tot = parseInt(result) + tot; //bet
              tot1 = parseInt(result1) + tot1 //win
              row.getCell(10).alignment = {horizontal : 'right'};
              row.getCell(6).alignment = {horizontal : 'right'};
              row.getCell(2).alignment = {horizontal : 'left'};
              row.getCell(3).alignment = {horizontal : 'center'};
              row.getCell(4).alignment = {horizontal : 'center'};
              row.getCell(5).alignment = {horizontal : 'center'};
              row.getCell(6).alignment = {horizontal : 'center'};
              row.getCell(7).alignment = {horizontal : 'right'};
              row.getCell(8).alignment = {horizontal : 'right'};
              } 
            );
            worksheet.getColumn(2).width = 20;
            worksheet.getColumn(3).width = 20;  
            worksheet.getColumn(4).width = 20;
            worksheet.getColumn(5).width = 20;
            worksheet.getColumn(6).width = 20;
            worksheet.getColumn(7).width = 18;
            worksheet.getColumn(7).alignment = { horizontal: 'center', wrapText: true };
            worksheet.getColumn(8).width = 15;
            worksheet.getColumn(8).alignment = { horizontal: 'center', wrapText: true };
            worksheet.getColumn(9).width = 16;   
            worksheet.getColumn(9).alignment = { horizontal: 'right', wrapText: true };
            worksheet.getColumn(10).width = 16; 
            // worksheet.getColumn(10).alignment = { horizontal: 'right', wrapText: true };
            // Footer Row -- first topup amt
            const footerRow = worksheet.addRow([]);
            footerRow.getCell(9).value = 'Grand Total';
            footerRow.getCell(9).border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
            footerRow.getCell(9).fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: 'FFCCFFE5' }
            };
            /*Bet amt*/
            footerRow.getCell(10).value = tot.toLocaleString();
            footerRow.getCell(10).alignment =  {horizontal : 'right',wrapText: true};
            footerRow.getCell(10).fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: 'FFCCFFE5' }
            };
            footerRow.getCell(10).border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
            footerRow.getCell(10).fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: 'FFCCFFE5' }
            };
            footerRow.getCell(9).border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
            /*Balance*/
            footerRow.getCell(10).value = tot1.toLocaleString();
            footerRow.getCell(10).alignment =  {horizontal : 'right',wrapText: true};
            footerRow.getCell(10).fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: 'FFCCFFE5' }
            };
            footerRow.getCell(10).alignment ={horizontal : 'right',wrapText: true};
            footerRow.getCell(9).border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
            footerRow.getCell(9).fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: 'FFCCFFE5' }
            };
           // footerRow.getCell(7).border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
    
            workbook.xlsx.writeBuffer().then((data: any) => {
            const blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
            fs.saveAs(blob, this.filename + repType);
            });
            }
          this.toastr.success('Reporting Success', 'Success!', {
                timeOut: 3000,
                positionClass: 'toast-top-right',
              });
            this.spinner.hide();
            });
      }
   //end of threed
    topupReport()
    {

    }
    //withdrawal, topup
    getWithdrawalPayments() {
      this.dto.token = this.storage.retrieve('token');
      let headers = new HttpHeaders();
      headers = headers.set('Authorization',  this.dto.token);
      let params = new HttpParams();
      params = params.set('type', 'TOPUP');
      this.http.get(this.funct.ipaddress + 'payment/withdrawallistPayment', { params: params, headers: headers }).subscribe(
        result => {
          this.dto.Response = {};
          this.dto.Response = result;
          this.paymentList = this.dto.Response;
        }
      );
    }
    getTopupPayments() {
      this.dto.token = this.storage.retrieve('token');
      let headers = new HttpHeaders();
      headers = headers.set('Authorization',  this.dto.token);
      let params = new HttpParams();
      params = params.set('type', 'TOPUP');
      this.http.get(this.funct.ipaddress + 'payment/topuplistPayment', { params: params, headers: headers }).subscribe(
        result => {
          this.dto.Response = {};
          this.dto.Response = result;
          this.paymentList = this.dto.Response;
        }
      );
    }
    flagBank()
    {
      this.bankId = this.bankId;
    }
    flagType()
    {
      this.optionsModel =[];
      if(this.withtype =='TOPUP')
      {
        this.getTopupPayments();
        this.getAllAdmins();
      }
      else
      {
        this.getWithdrawalPayments();
        this.getAllAdminsWithWithdrawal();
      }

    }
    onChange() {
    }
    IsExistPhoneNoForTopupWithdeawal()
    {
      this.spinner.show();    
      if (this.withphoneno != ''  && this.withphoneno != null ) {
        this.token = this.storage.retrieve('token');
        let headers = new HttpHeaders();   
        headers = headers.set('Authorization', this.token);
        let params = new HttpParams();
        params = params.set('userPhoneNo', this.withphoneno);    
         this.http.get(this.funct.ipaddress + 'transaction/IsExistPhoneNo', { params: params, headers: headers})
       
    .pipe(
      catchError(this.handleError.bind(this))
     )
    .subscribe( 
      result => {
        this.dto.Response = {};
        this.dto.Response = result;
        this.isexist = this.dto.Response;
        if(this.isexist.length !=0)
        {
          this.withTopupReports();
        }
        else 
        {
         this.spinner.hide();
         this.toastr.error('Phone Number', 'Invalid!', {
           timeOut: 5000,
           positionClass: 'toast-top-right',
         });
       }
      });
  
      }
      else {
        this.spinner.hide();
        this.toastr.error('Please fill all fields', 'Invalid input!', {
          timeOut: 3000,
          positionClass: 'toast-top-right',
        });
      }
    }
    withTopupReport()
    {
      this.IsExistPhoneNoForTopupWithdeawal();
    }
    withTopupReports()
    {
        var fromdate = this.withfromdate;
        var todate = this.withtodate;
        var withdrawalfromdate = this.withfromdate;
        var withdrawaltodate = this.withtodate;
        var status = this.withstatus;
        var type = this.withtype;
        var report_option = this.report_option;
        var repType = '';
        this.spinner.show();
        this.dto.token = this.storage.retrieve('token');
        this.userFinancialRptList = [];
        this.userFinancialRptPdfList = [];
        var grand_total = 0;
        if(this.report_option == '')
        {
          this.report_option = 'excel';
        }
     
        let headers = new HttpHeaders();
        headers = headers.set('Authorization', this.dto.token);
        let params = new HttpParams();
        if(this.report_option == 'excel')
            {
               repType = '.xlsx';
            }
            if(this.report_option == 'pdf')
            {
                repType = '.pdf';
            }
            if(this.report_option == "csv")
            {
              repType = '.csv';
            } 
          if(this.optionsModel.length == 0 && this.withtype != 'WITHDRAW')
        {
          this.toastr.error('Please select account holder or code number', 'Invalid!', {
              timeOut: 3000,
              positionClass: 'toast-top-right',
            });
          this.spinner.hide();
          return;
        }

          params = params.set("fromdate", fromdate).set("todate",todate).set("status",status).set("type", type).set("userPhoneNo",this.withphoneno).set("adminid", this.optionsModel.toString()).set("banktypeid",this.bankId);
          this.http.get(this.funct.ipaddress + 'userfinancialreport/userfinancialdetailreport', { params: params, headers: headers})
          .pipe(
            catchError(this.handleError.bind(this))
           )
          .subscribe(data => 
            {
            this.filename_date = this.datePipe.transform( new Date(),'dd-MM-yyyy');
            this.filename = "topupandwithdrawal"+type+"_"+status+"_"+this.filename_date;
            this.spinner.hide();
            this.dto.Response = {};
            if(status == 'PENDING')
            {              
              this.dto.Response = data;
            }
            else
            {              
              this.dto.Response = data;
            }
            this.dto.Response.forEach((row: any) => 
            {
              this.userFinancialRptList.push(Object.values(row));
              this.userFinancialRptPdfList.push(Object.values(row));
              var result = row.amount_Str.toString().replace(/,/g, "");
              grand_total = grand_total + parseInt(result) ;
            })
            if(this.report_option == "pdf")
            {
              var totalList = ['', '', '','','', 'Grand Total',grand_total.toLocaleString()];
              this.userFinancialRptPdfList.push(totalList);
              var pdfname = this.filename + repType;
            
            }
          if(this.report_option == "excel")
          {
          
          const title = 'Topup/Withdrawal Detail Report ('+type+' - '+status+')';
          const subTitle = 'Printed Date : '+this.filename_date;
          const printedBy = 'Printed By : '+this.ad_name +' - From Date : '+ withdrawalfromdate +' - To Date : '+ withdrawaltodate + ' - Name : '+ data.name +' - PhoneNumber : '+data.topupphoneno;
          if(status == 'PENDING')
          {             
                // const header = ['No', 'Name', 'Phone Number','Approved Date/Time','Created Date/Time','Updated By','Status','Payment','Current Wallet ','Trans / Account No', 'Amount'];

                const header = ['No', 'Name', 'Phone Number','Created Date/Time','Status','Payment','Current Wallet ','Trans / Account No', 'Amount'];
       
                const workbook = new Workbook();
                const worksheet = workbook.addWorksheet('Topup And Withdrawal Detail Report');
              
                const titleRow = worksheet.addRow([title]);
                titleRow.font = { name: 'Corbel', family: 4, size: 16, underline: 'double', bold: true };
                worksheet.addRow([]);
                const subTitleRow = worksheet.addRow(['Printed Date : '+this.filename_date]);
                worksheet.mergeCells('A3:D3');
                worksheet.mergeCells('A1:D2');
                
                worksheet.addRow(['Printed By : '+this.ad_name + ' - From Date : '+ withdrawalfromdate+' - To Date : '+withdrawaltodate]);
            
                worksheet.mergeCells('A4:F4');
              
                const headerRow = worksheet.addRow(header);
                  
                headerRow.eachCell((cell, number) => {
                    cell.fill = {
                    type: 'gradient',
                    gradient: 'angle',
                    degree: 0,
                    stops: [
                      {position:0, color:{argb:'ff095108'}},
                      {position:0.5, color:{argb:'ff095108'}},
                      {position:1, color:{argb:'ff095108'}}
                    ],
                    };
                    cell.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
                    cell.alignment = {horizontal:'center' };
                    cell.font= {color:{argb:'FFFFFF'}};
                    });
                  
                  var tot = 0, tot1 = 0;
                  this.userFinancialRptList.forEach(d => 
                    {
                  const row = worksheet.addRow(d);
                  const qty = row.getCell(9).value;//11
                  var result = qty.toString().replace(/,/g, "");
                  tot = parseInt(result) + tot;         
                  row.getCell(1).alignment = {horizontal : 'center'}
                  row.getCell(3).alignment = {horizontal : 'center'}
                  row.getCell(6).alignment = {horizontal : 'left'}
                  row.getCell(7).alignment = {horizontal : 'center'}
                  row.getCell(8).alignment = {horizontal : 'center'}
                  row.getCell(10).alignment = {horizontal : 'center'}
                  row.getCell(9).alignment = {horizontal : 'right'}
                 
                  }
                );
                worksheet.getColumn(2).width = 23;
                worksheet.getColumn(3).width = 23;
                worksheet.getColumn(4).width = 23;
                worksheet.getColumn(5).width = 20;
                worksheet.getColumn(6).width = 13;
                worksheet.getColumn(7).width = 16;
                worksheet.getColumn(8).width = 16;
                worksheet.getColumn(9).width = 15;                       
                const footerRow = worksheet.addRow([]);
                footerRow.getCell(8).value = 'Grand Total';
                footerRow.getCell(9).value = tot.toLocaleString();  
                footerRow.getCell(8).fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: { argb: 'FFCCFFE5' }
                };
                footerRow.getCell(8).border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
                footerRow.getCell(9).fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: { argb: 'FFCCFFE5' }
                };  
                footerRow.getCell(9).alignment = {horizontal : 'right'};
                workbook.xlsx.writeBuffer().then((data: any) => {
                const blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
                fs.saveAs(blob, this.filename + repType);
                });            
          }
          else
          {          
              const header = ['No', 'Name', 'Phone Number','Approved Date/Time','Created Date/Time','Updated By','Status','Payment','Current Wallet ','Trans / Account No', 'Amount'];            
              const workbook = new Workbook();
              const worksheet = workbook.addWorksheet('Topup And Withdrawal Detail Report');          
              const titleRow = worksheet.addRow([title]);
              titleRow.font = { name: 'Corbel', family: 4, size: 16, underline: 'double', bold: true };
              worksheet.addRow([]);
              const subTitleRow = worksheet.addRow(['Printed Date : '+this.filename_date]);
              worksheet.mergeCells('A3:D3');
              worksheet.mergeCells('A1:D2');              
              worksheet.addRow(['Printed By : '+this.ad_name + ' - From Date : '+ withdrawalfromdate+' - To Date : '+withdrawaltodate]);          
              worksheet.mergeCells('A4:F4');        
              const headerRow = worksheet.addRow(header);           
              headerRow.eachCell((cell, number) => {
                  cell.fill = {
                  type: 'gradient',
                  gradient: 'angle',
                  degree: 0,
                  stops: [
                    {position:0, color:{argb:'ff095108'}},
                    {position:0.5, color:{argb:'ff095108'}},
                    {position:1, color:{argb:'ff095108'}}
                  ],
                  };
                  cell.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
                  cell.alignment = {horizontal:'center' };
                  cell.font= {color:{argb:'FFFFFF'}};
                  });            
                var tot = 0, tot1 = 0;
                this.userFinancialRptList.forEach(d => {
                const row = worksheet.addRow(d);
                const qty = row.getCell(11).value;
                var result = qty.toString().replace(/,/g, "");
                tot = parseInt(result) + tot;         
                row.getCell(1).alignment = {horizontal : 'center'}
                row.getCell(3).alignment = {horizontal : 'center'}
                row.getCell(6).alignment = {horizontal : 'left'}
                row.getCell(7).alignment = {horizontal : 'center'}
                row.getCell(8).alignment = {horizontal : 'center'}
                row.getCell(10).alignment = {horizontal : 'center'}
                row.getCell(9).alignment = {horizontal : 'right'}
                row.getCell(11).alignment = {horizontal : 'right'}
                }
              );
              worksheet.getColumn(2).width = 23;
              worksheet.getColumn(3).width = 23;
              worksheet.getColumn(4).width = 23;
              worksheet.getColumn(5).width = 20;
              worksheet.getColumn(6).width = 13;
              worksheet.getColumn(7).width = 13;
              worksheet.getColumn(8).width = 13;
              worksheet.getColumn(9).width = 15;
              worksheet.getColumn(10).width = 16;
              worksheet.getColumn(11).width = 16;
              const footerRow = worksheet.addRow([]);
              footerRow.getCell(10).value = 'Grand Total';
              footerRow.getCell(11).value = tot.toLocaleString();     

              footerRow.getCell(10).fill = {
              type: 'pattern',
              pattern: 'solid',
              fgColor: { argb: 'FFCCFFE5' }
              };
              footerRow.getCell(10).border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
              footerRow.getCell(11).fill = {
              type: 'pattern',
              pattern: 'solid',
              fgColor: { argb: 'FFCCFFE5' }
              };  
              footerRow.getCell(11).alignment = {horizontal : 'right'};
              workbook.xlsx.writeBuffer().then((data: any) => {
              const blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
              fs.saveAs(blob, this.filename + repType);
              });
        }
       }
       if(this.report_option == 'csv')
       {
          this.downloadFileWithTopup(data, this.filename+repType)
       }
                this.toastr.success('Reporting Success', 'Success!', 
                {
                  timeOut: 3000,
                  positionClass: 'toast-top-right',
                });
              this.spinner.hide();
              });
    }
    PendingFile(title :any, subTitle:any,printedBy:any,userFinancialRptList:any,repType:any)
    {
         
          // const header = ['No', 'Name', 'Phone Number','Approved Date/Time','Created Date/Time','Updated By','Status','Payment','Current Wallet ','Trans / Account No', 'Amount'];

          const header = ['No', 'Name', 'Phone Number','Created Date/Time','Status','Payment','Current Wallet ','Trans / Account No', 'Amount'];
          
          const workbook = new Workbook();
          const worksheet = workbook.addWorksheet('Topup/Withdrawal Detail Report');
          const titleRow = worksheet.addRow([title]);
          titleRow.font = { name: 'Corbel', family: 4, size: 16, underline: 'double', bold: true };
          worksheet.addRow([]);
          const subTitleRow = worksheet.addRow([subTitle]);
          worksheet.mergeCells('A3:D3');
          worksheet.mergeCells('A1:D2');        
       
          worksheet.addRow(['Printed By : '+this.ad_name + ' - From Date : '+this.fromdate+' - To Date : '+this.todate]);
          worksheet.mergeCells('A4:F4');        
          const headerRow = worksheet.addRow(header);           
          headerRow.eachCell((cell, number) => {
              cell.fill = {
              type: 'gradient',
              gradient: 'angle',
              degree: 0,
              stops: [
                {position:0, color:{argb:'ff095108'}},
                {position:0.5, color:{argb:'ff095108'}},
                {position:1, color:{argb:'ff095108'}}
              ],
              };
              cell.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
              cell.alignment = {horizontal:'center' };
              cell.font= {color:{argb:'FFFFFF'}};
              });            
            var tot = 0, tot1 = 0;
            this.userFinancialRptList.forEach(d => {
            const row = worksheet.addRow(d);
            const qty = row.getCell(11).value;
            var result = qty.toString().replace(/,/g, "");
            tot = parseInt(result) + tot;
            row.getCell(10).alignment = {horizontal : 'right'}
            row.getCell(11).alignment = {horizontal : 'right'}
            }
          );
          worksheet.getColumn(2).width = 23;
          worksheet.getColumn(3).width = 23;
          worksheet.getColumn(4).width = 23;
          worksheet.getColumn(5).width = 20;
          worksheet.getColumn(6).width = 13;
          worksheet.getColumn(7).width = 13;
          worksheet.getColumn(8).width = 13;
          worksheet.getColumn(9).width = 15;
          worksheet.getColumn(10).width = 16;
          worksheet.getColumn(11).width = 16;
         
    
          // Footer Row
          const footerRow = worksheet.addRow([]);
          footerRow.getCell(10).value = 'Grand Total';
          footerRow.getCell(11).value = tot.toLocaleString();
          //footerRow.getCell(9).value = tot1.toLocaleString();
          footerRow.getCell(10).alignment ={horizontal : 'right'};
          footerRow.getCell(11).alignment ={horizontal : 'right'};
          footerRow.getCell(11).fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: 'FFCCFFE5' }
          };
          footerRow.getCell(11).border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
          footerRow.getCell(10).fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: 'FFCCFFE5' }
          };
          footerRow.getCell(11).border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
          footerRow.getCell(11).alignment ={horizontal : 'right'};
          workbook.xlsx.writeBuffer().then((data: any) => {
          const blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
          fs.saveAs(blob, this.filename + repType);
          });
    }
    IsExistPhoneNoForGameTransaction() 
    {
      this.spinner.show();    
      if (this.gametranphoneno != ''  && this.gametranphoneno != null ) {
        this.token = this.storage.retrieve('token');
        let headers = new HttpHeaders();   
        headers = headers.set('Authorization', this.token);
        let params = new HttpParams();
        params = params.set('userPhoneNo', this.gametranphoneno);    
         this.http.get(this.funct.ipaddress + 'transaction/IsExistPhoneNo', { params: params, headers: headers})
       
    .pipe(
      catchError(this.handleError.bind(this))
     )
    .subscribe( 
      result => {
        this.dto.Response = {};
        this.dto.Response = result;
        this.isexist = this.dto.Response;
        if(this.isexist.length !=0)
        {
          this.gametranReports();
        }
        else 
        {
         this.spinner.hide();
         this.toastr.error('Phone Number', 'Invalid!', {
           timeOut: 5000,
           positionClass: 'toast-top-right',
         });
       }
      });
  
      }
      else {
        this.spinner.hide();
        this.toastr.error('Please fill all fields', 'Invalid input!', {
          timeOut: 3000,
          positionClass: 'toast-top-right',
        });
      }
    }
    gametranReport()
    {
       this.IsExistPhoneNoForGameTransaction();
    }
    
    gametranReports()
    {
        var fromdate = this.gametranfromdate;
        var todate = this.gametrantodate;
        var status = this.gameTranType;
        var type = this.gameTranType;
        var report_option = this.report_option;
        var repType = '';
        this.spinner.show();
        this.dto.token = this.storage.retrieve('token');
        this.userFinancialRptList = [];
        this.userFinancialRptPdfList = [];
        var grand_total = 0;
        if(this.report_option == '')
        {
          this.report_option = 'excel';
        }
       
        let headers = new HttpHeaders();
        headers = headers.set('Authorization', this.dto.token);
        let params = new HttpParams();
        if(this.report_option == 'excel')
            {
               repType = '.xlsx';
            }
            if(this.report_option == 'pdf')
            {
                repType = '.pdf';
            }
            if(this.report_option == "csv")
            {
              repType = '.csv';
            } 

          params = params.set("fromdate", fromdate).set("todate",todate).set("phoneno",this.gametranphoneno).set("type", type).set("providerId",this.providerId);
          this.http.get(this.funct.ipaddress + 'loginGS/getGameTransactionAdminWeb', { params: params, headers: headers})
          .pipe(
            catchError(this.handleError.bind(this))
           )
          .subscribe(data => {
            this.filename_date = this.datePipe.transform( new Date(),'dd-MM-yyyy');
            this.filename = "gameTransaction"+type+"_"+this.filename_date;
            this.spinner.hide();
            this.dto.Response = {};
            
            this.dto.Response = data;
            this.dto.Response.forEach((row: any) => {
              this.userFinancialRptList.push(Object.values(row));
              this.userFinancialRptPdfList.push(Object.values(row));
              var result = row.amount.toString().replace(/,/g, "");
              grand_total = grand_total + parseInt(result) ;
            })
            if(this.report_option == "pdf")
            {
              var totalList = ['', '', '','','', 'Grand Total',grand_total.toLocaleString()];
              this.userFinancialRptPdfList.push(totalList);
              var pdfname = this.filename + repType;
            
            }
          if(this.report_option == "excel")
          {
          if(type == undefined || type == '')
          {
            type = 'All';
          }
          const title = 'Game Transaction Detail Report ('+type+')';
          // const header = ['No', 'Created Date', 'Game UserId','Method','TransactionID','Provider Name', 'Amount'];
          const header = ['No','Name', 'Created Date', 'Method','Game UserID','Transaction ID','Provider Name', 'Amount'];
          const workbook = new Workbook();
          const worksheet = workbook.addWorksheet('Game Transaction Detail Report');       
          const titleRow = worksheet.addRow([title]);
          titleRow.font = { name: 'Corbel', family: 4, size: 16, underline: 'double', bold: true };
          worksheet.addRow([]);
          const subTitleRow = worksheet.addRow(['Printed Date : '+this.filename_date]);
          worksheet.mergeCells('A3:D3');
          worksheet.mergeCells('A1:D2');
         
           worksheet.addRow(['Printed By : '+this.ad_name +' - From Date: '+this.gametranfromdate+'- To Date : '+this.gametrantodate  +' - Phone No : ' + this.gametranphoneno]); 
      
           worksheet.mergeCells('A4:F4');
            // Add Header Row
          const headerRow = worksheet.addRow(header);
            // Cell Style : Fill and Border
            headerRow.eachCell((cell, number) => {
              cell.fill = {
              type: 'gradient',
              gradient: 'angle',
              degree: 0,
              stops: [
                {position:0, color:{argb:'ff095108'}},
                {position:0.5, color:{argb:'ff095108'}},
                {position:1, color:{argb:'ff095108'}}
              ],
              };
              cell.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
              cell.alignment = {horizontal:'center' };
              cell.font= {color:{argb:'FFFFFF'}};
              });
            var tot = 0, tot1 = 0;
            this.userFinancialRptList.forEach(d => {
            const row = worksheet.addRow(d);
            const qty = row.getCell(8).value;
            var result = qty.toString().replace(/,/g, "");
            tot = parseInt(result) + tot;
            row.getCell(1).alignment = {horizontal : 'center'}
            row.getCell(2).alignment = {horizontal : 'left'}
            row.getCell(3).alignment = {horizontal : 'center'}
            row.getCell(4).alignment = {horizontal : 'center'}
            row.getCell(5).alignment = {horizontal : 'center'}
            row.getCell(6).alignment = {horizontal : 'center'}
            row.getCell(7).alignment = {horizontal : 'center'}
            row.getCell(8).alignment = {horizontal : 'right'}
            }
          );
          worksheet.getColumn(1).width = 8;
          worksheet.getColumn(2).width = 20;
          worksheet.getColumn(3).width = 20;
          worksheet.getColumn(4).width = 20;
          worksheet.getColumn(5).width = 13;
          worksheet.getColumn(6).width = 28;
          worksheet.getColumn(7).width = 16;
          worksheet.getColumn(8).width = 15;          
          // worksheet.getColumn(8).alignment = { horizontal: 'center', wrapText: true };
    
          // Footer Row
          const footerRow = worksheet.addRow([]);
          footerRow.getCell(7).value = 'Grand Total';
          footerRow.getCell(8).value = tot.toLocaleString();
          footerRow.getCell(8).alignment = {horizontal:'right'};
          footerRow.getCell(7).fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: 'FFCCFFE5' }
          };
          footerRow.getCell(7).border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
          footerRow.getCell(8).fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: 'FFCCFFE5' }
          };
          footerRow.getCell(8).alignment = {horizontal:'right'}
          workbook.xlsx.writeBuffer().then((data: any) => {
          const blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
          fs.saveAs(blob, this.filename + repType);
          });
       }
       if(this.report_option == 'csv')
       {
        this.downloadFileWithGameTransaction(data, this.filename+repType)
       }
         this.toastr.success('Reporting Success', 'Success!', {
                  timeOut: 3000,
                  positionClass: 'toast-top-right',
                });
              this.spinner.hide();
              });
    }
    IsExistPhoneNoForAllTransaction() {
      this.spinner.show();    
      if (this.alltranphoneno != ''  && this.alltranphoneno != null ) {
        this.token = this.storage.retrieve('token');
        let headers = new HttpHeaders();   
        headers = headers.set('Authorization', this.token);
        let params = new HttpParams();
        params = params.set('userPhoneNo', this.alltranphoneno);    
         this.http.get(this.funct.ipaddress + 'transaction/IsExistPhoneNo', { params: params, headers: headers})
       
    .pipe(
      catchError(this.handleError.bind(this))
     )
    .subscribe( 
      result => {
        this.dto.Response = {};
        this.dto.Response = result;
        this.isexist = this.dto.Response;
        if(this.isexist.length !=0)
        {
          this.alltranReports();
        }
        else 
        {
         this.spinner.hide();
         this.toastr.error('Phone Number', 'Invalid!', {
           timeOut: 5000,
           positionClass: 'toast-top-right',
         });
       }
      });
  
      }
      else {
        this.spinner.hide();
        this.toastr.error('Please fill all fields', 'Invalid input!', {
          timeOut: 3000,
          positionClass: 'toast-top-right',
        });
      }
    }
    alltranReport()
    {
       this.IsExistPhoneNoForAllTransaction();
    }

    // alltranReports()
    // {
    //   var fromdate = this.alltranfromdate;
    //   var todate = this.alltrantodate;
    //   var repType = '';
    //   this.spinner.show();
    //   this.dto.token = this.storage.retrieve('token');
    //   this.userFinancialRptList = [];
    //   this.userFinancialRptPdfList = [];
    //   var grand_total = 0;
    //   if(this.report_option == '')
    //   {
    //     this.report_option = 'excel';
    //   }

    //   let headers = new HttpHeaders();
    //   headers = headers.set('Authorization', this.dto.token);
    //   let params = new HttpParams();
    //   if(this.report_option == 'excel')
    //       {
    //          repType = '.xlsx';
    //       }
    //       if(this.report_option == 'pdf')
    //       {
    //           repType = '.pdf';
    //       }
    //       if(this.report_option == "csv")
    //       {
    //         repType = '.csv';
    //       }        
    //     params = params.set("fromdate", fromdate).set("todate",todate).set("phoneno",this.alltranphoneno);
    //     this.http.get(this.funct.ipaddress + 'loginGS/transactionallreport', { params: params, headers: headers})
    //     .pipe(
    //       catchError(this.handleError.bind(this))
    //      )
    //     .subscribe(data => {
    //       this.filename_date = this.datePipe.transform( new Date(),'dd-MM-yyyy');
    //       this.filename = "TransactionAll_"+this.filename_date;
    //       this.spinner.hide();
    //       this.dto.Response = {};
    //       //this.dto.Response = data.retList;
    //       this.dto.Response = data;
    //       this.dto.Response.forEach((row: any) => {
    //         this.userFinancialRptList.push(Object.values(row));
    //         this.userFinancialRptPdfList.push(Object.values(row));
    //         var result = row.amount.toString().replace(/,/g, "");
    //         grand_total = grand_total + parseInt(result) ;
    //       })
    //       if(this.report_option == "pdf")
    //       {
    //         var totalList = ['', '', '','','', 'Grand Total',grand_total.toLocaleString()];
    //         this.userFinancialRptPdfList.push(totalList);
    //         var pdfname = this.filename + repType;
      
    //       }
    //     if(this.report_option == "excel")
    //     {
    //     const title = 'Transaction All Detail Report';       
    //     const header = ['No', 'CashIn','CashOut', 'Amount','Wallet/Balance','Created Date','Updated Date'];  
    //     const workbook = new Workbook();
    //     const worksheet = workbook.addWorksheet('Game Transaction Detail Report');
    //     // Add Row and formatting
    //     const titleRow = worksheet.addRow([title]);
    //     titleRow.font = { name: 'Corbel', family: 4, size: 16, underline: 'double', bold: true };
    //     worksheet.addRow([]);
    //     const subTitleRow = worksheet.addRow(['Printed Date : '+this.filename_date]);
    //     worksheet.mergeCells('A3:D3');
    //     worksheet.mergeCells('A1:D2');  
    //     worksheet.addRow(['Printed By : '+this.ad_name + ' - From Date : '+this.alltranfromdate+' - To Date : '+this.alltrantodate  +'- Phone No : ' + this.alltranphoneno]);

    //      worksheet.mergeCells('A4:F4');

    //     const headerRow = worksheet.addRow(header);
    //       // Cell Style : Fill and Border
    //       headerRow.eachCell((cell, number) => {
    //         cell.fill = {
    //         type: 'gradient',
    //         gradient: 'angle',
    //         degree: 0,
    //         stops: [
    //           {position:0, color:{argb:'ff095108'}},
    //           {position:0.5, color:{argb:'ff095108'}},
    //           {position:1, color:{argb:'ff095108'}}
    //         ],
    //         };
    //         cell.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
    //         cell.alignment = {horizontal:'center' };
    //         cell.font= {color:{argb:'FFFFFF'}};
    //         });
    //       // Add Data and Conditional Formatting
    //       var tot = 0, tot1 = 0;
    //       this.userFinancialRptList.forEach(d => {
    //       const row = worksheet.addRow(d);
    //       const qty = row.getCell(4).value;
    //       var result = qty.toString().replace(/,/g, "");
    //       tot = parseInt(result) + tot;
    //       row.getCell(1).alignment = {horizontal : 'center'}
    //       row.getCell(2).alignment = {horizontal : 'center'}
    //       row.getCell(3).alignment = {horizontal : 'center'}
    //       row.getCell(4).alignment = {horizontal : 'right'}
    //       row.getCell(5).alignment = {horizontal : 'right'}
    //       row.getCell(6).alignment = {horizontal : 'center'}
    //       row.getCell(7).alignment = {horizontal : 'center'}
    //       }
    //     );
    //     worksheet.getColumn(2).width = 20;
    //     worksheet.getColumn(3).width = 20;    
    //     worksheet.getColumn(4).width = 20;
    //     worksheet.getColumn(5).width = 20; 
    //     worksheet.getColumn(6).width = 20;
    //     worksheet.getColumn(7).width = 20;
    //     // Footer Row
    //     const footerRow = worksheet.addRow([]);
    //     footerRow.getCell(3).value = 'Grand Total';
    //     footerRow.getCell(4).value = tot.toLocaleString();       
    //     footerRow.getCell(4).alignment = {horizontal:'right'};
    //     footerRow.getCell(4).alignment = {horizontal:'right'};
    //     footerRow.getCell(4).fill = {
    //     type: 'pattern',
    //     pattern: 'solid',
    //     fgColor: { argb: 'FFCCFFE5' }
    //     };
    //     footerRow.getCell(4).border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
    //     footerRow.getCell(4).fill = {
    //     type: 'pattern',
    //     pattern: 'solid',
    //     fgColor: { argb: 'FFCCFFE5' }
    //     };
    //     workbook.xlsx.writeBuffer().then((data: any) => {
    //     const blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    //     fs.saveAs(blob, this.filename + repType);
    //     });
    //  }
    //  if(this.report_option == 'csv')
    //  {
    //   this.downloadFileWithAll(data, this.filename+repType)
    //  }
    //    this.toastr.success('Reporting Success', 'Success!', {
    //             timeOut: 3000,
    //             positionClass: 'toast-top-right',
    //           });
    //         this.spinner.hide();
    //         });
    // }

    alltranReports()
    {
      var fromdate = this.alltranfromdate;
      var todate = this.alltrantodate;
      var repType = '';
      this.spinner.show();
      this.dto.token = this.storage.retrieve('token');
      this.userFinancialRptList = [];
      this.userFinancialRptPdfList = [];
      var grand_total = 0;
      if(this.report_option == '')
      {
        this.report_option = 'excel';
      }

      let headers = new HttpHeaders();
      headers = headers.set('Authorization', this.dto.token);
      let params = new HttpParams();
      if(this.report_option == 'excel')
          {
             repType = '.xlsx';
          }
          if(this.report_option == 'pdf')
          {
              repType = '.pdf';
          }
          if(this.report_option == "csv")
          {
            repType = '.csv';
          }        
        params = params.set("fromdate", fromdate).set("todate",todate).set("phoneno",this.alltranphoneno);
        this.http.get(this.funct.ipaddress + 'loginGS/transactionallreport', { params: params, headers: headers})
        .pipe(
          catchError(this.handleError.bind(this))
         )
        .subscribe(data => {
          this.filename_date = this.datePipe.transform( new Date(),'dd-MM-yyyy');
          this.filename = "TransactionAll_"+this.filename_date;
          this.spinner.hide();
          this.dto.Response = {};
          
          this.dto.Response = data;
          this.dto.Response.forEach((row: any) => {
            this.userFinancialRptList.push(Object.values(row));
            this.userFinancialRptPdfList.push(Object.values(row));
            var result = row.amount.toString().replace(/,/g, "");
            grand_total = grand_total + parseInt(result) ;
          })
          if(this.report_option == "pdf")
          {
            var totalList = ['', '', '','','', 'Grand Total',grand_total.toLocaleString()];
            this.userFinancialRptPdfList.push(totalList);
            var pdfname = this.filename + repType;
      
          }
        if(this.report_option == "excel")
        {
        const title = 'Transaction All Detail Report';       
        const header = ['CashIn','CashOut', 'Amount','Wallet/Balance','Created Date','Updated Date'];  
        const workbook = new Workbook();
        const worksheet = workbook.addWorksheet('Game Transaction Detail Report');
       
        const titleRow = worksheet.addRow([title]);
        titleRow.font = { name: 'Corbel', family: 4, size: 16, underline: 'double', bold: true };
        worksheet.addRow([]);
        const subTitleRow = worksheet.addRow(['Printed Date : '+this.filename_date]);
        worksheet.mergeCells('A3:D3');
        worksheet.mergeCells('A1:D2');  
        worksheet.addRow(['Printed By : '+this.ad_name + ' - From Date : '+this.alltranfromdate+' - To Date : '+this.alltrantodate  +'- Phone No : ' + this.alltranphoneno]);

         worksheet.mergeCells('A4:F4');

        const headerRow = worksheet.addRow(header);
          // Cell Style : Fill and Border
          headerRow.eachCell((cell, number) => {
            cell.fill = {
            type: 'gradient',
            gradient: 'angle',
            degree: 0,
            stops: [
              {position:0, color:{argb:'ff095108'}},
              {position:0.5, color:{argb:'ff095108'}},
              {position:1, color:{argb:'ff095108'}}
            ],
            };
            cell.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
            cell.alignment = {horizontal:'center' };
            cell.font= {color:{argb:'FFFFFF'}};
            });          
          var tot = 0, tot1 = 0;
          this.userFinancialRptList.forEach(d => {
          const row = worksheet.addRow(d);
          const qty = row.getCell(3).value;
          var result = qty.toString().replace(/,/g, "");
          tot = parseInt(result) + tot;
          row.getCell(1).alignment = {horizontal : 'center'}
          row.getCell(2).alignment = {horizontal : 'center'}
          row.getCell(3).alignment = {horizontal : 'right'}
          row.getCell(4).alignment = {horizontal : 'right'}
          row.getCell(5).alignment = {horizontal : 'center'}
          row.getCell(6).alignment = {horizontal : 'center'}
        
          }
        );
        worksheet.getColumn(1).width = 20;
        worksheet.getColumn(2).width = 20;    
        worksheet.getColumn(3).width = 20;
        worksheet.getColumn(4).width = 20; 
        worksheet.getColumn(5).width = 20;
        worksheet.getColumn(6).width = 20;
        
        const footerRow = worksheet.addRow([]);
        footerRow.getCell(2).value = 'Grand Total';
        footerRow.getCell(3).value = tot.toLocaleString();       
        footerRow.getCell(3).alignment = {horizontal:'right'};
        footerRow.getCell(3).alignment = {horizontal:'right'};
        footerRow.getCell(3).fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'FFCCFFE5' }
        };
        footerRow.getCell(3).border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
        footerRow.getCell(3).fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'FFCCFFE5' }
        };
        workbook.xlsx.writeBuffer().then((data: any) => {
        const blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        fs.saveAs(blob, this.filename + repType);
        });
     }
     if(this.report_option == 'csv')
     {
      this.downloadFileWithAll(data, this.filename+repType)
     }
       this.toastr.success('Reporting Success', 'Success!', {
                timeOut: 3000,
                positionClass: 'toast-top-right',
              });
            this.spinner.hide();
            });
    }
    TwoDetailNewReport()
    {
       this.IsExistPhoneNoForTwoDDetailNewReports();
    }
    IsExistPhoneNoForTwoDDetailNewReports() 
    {
      this.spinner.show();    
      if (this.phoneno != ''  && this.phoneno != null ) {
        this.token = this.storage.retrieve('token');
        let headers = new HttpHeaders();   
        headers = headers.set('Authorization', this.token);
        let params = new HttpParams();
        params = params.set('userPhoneNo', this.phoneno);    
         this.http.get(this.funct.ipaddress + 'transaction/IsExistPhoneNo', { params: params, headers: headers})
       
    .pipe(
      catchError(this.handleError.bind(this))
     )
    .subscribe( 
      result => {
        this.dto.Response = {};
        this.dto.Response = result;
        this.isexist = this.dto.Response;
        if(this.isexist.length !=0)
        {
          this.alltranReports();
        }
        else 
        {
         this.spinner.hide();
         this.toastr.error('Phone Number', 'Invalid!', {
           timeOut: 5000,
           positionClass: 'toast-top-right',
         });
       }
      });
  
      }
      else {
        this.spinner.hide();
        this.toastr.error('Please fill all fields', 'Invalid input!', {
          timeOut: 3000,
          positionClass: 'toast-top-right',
        });
      }
    }
    TwoDetailNewReports()
    {
      var fromdate = this.alltranfromdate;
      var todate = this.alltrantodate;
      var repType = '';
      this.spinner.show();
      this.dto.token = this.storage.retrieve('token');
      this.userFinancialRptList = [];
      this.userFinancialRptPdfList = [];
      var grand_total = 0;
      if(this.report_option == '')
      {
        this.report_option = 'excel';
      }

      let headers = new HttpHeaders();
      headers = headers.set('Authorization', this.dto.token);
      let params = new HttpParams();
      if(this.report_option == 'excel')
          {
             repType = '.xlsx';
          }
          if(this.report_option == 'pdf')
          {
              repType = '.pdf';
          }
          if(this.report_option == "csv")
          {
            repType = '.csv';
          }        
        params = params.set("fromdate", fromdate).set("todate",todate).set("phoneno",this.alltranphoneno);
        this.http.get(this.funct.ipaddress + 'twodreport/twoDBetDetailsNew', { params: params, headers: headers})
        .pipe(
          catchError(this.handleError.bind(this))
         )
        .subscribe(data => {
          this.filename_date = this.datePipe.transform( new Date(),'dd-MM-yyyy');
          this.filename = "TwoDDetailNewReport_"+this.filename_date;
          this.spinner.hide();
          this.dto.Response = {};
         
          this.dto.Response = data;
          this.dto.Response.forEach((row: any) => {
            this.userFinancialRptList.push(Object.values(row));
            this.userFinancialRptPdfList.push(Object.values(row));
            var result = row.amount.toString().replace(/,/g, "");
            grand_total = grand_total + parseInt(result) ;
          })
          if(this.report_option == "pdf")
          {
            var totalList = ['', '', '','','', 'Grand Total',grand_total.toLocaleString()];
            this.userFinancialRptPdfList.push(totalList);
            var pdfname = this.filename + repType;
      
          }
        if(this.report_option == "excel")
        {
        const title = 'TwoD Detail New Report';       
        const header = ['No', 'Name','BetTime', 'Section','UserName','PhoneNo','2D Number','Amount'];
  
        const workbook = new Workbook();
        const worksheet = workbook.addWorksheet('TwoD Detail New Report');
        // Add Row and formatting
        const titleRow = worksheet.addRow([title]);
        titleRow.font = { name: 'Corbel', family: 4, size: 16, underline: 'double', bold: true };
        worksheet.addRow([]);
        const subTitleRow = worksheet.addRow(['Printed Date : '+this.filename_date]);
        worksheet.mergeCells('A3:D3');
        worksheet.mergeCells('A1:D2');  
        worksheet.addRow(['Printed By : '+this.ad_name + ' - From Date : '+this.alltranfromdate+' - To Date : '+this.alltrantodate  +'- Phone No : ' + this.alltranphoneno]);

         worksheet.mergeCells('A4:F4');

        const headerRow = worksheet.addRow(header);
          // Cell Style : Fill and Border
          headerRow.eachCell((cell, number) => {
            cell.fill = {
            type: 'gradient',
            gradient: 'angle',
            degree: 0,
            stops: [
              {position:0, color:{argb:'ff095108'}},
              {position:0.5, color:{argb:'ff095108'}},
              {position:1, color:{argb:'ff095108'}}
            ],
            };
            cell.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
            cell.alignment = {horizontal:'center' };
            cell.font= {color:{argb:'FFFFFF'}};
            });
          // Add Data and Conditional Formatting
          var tot = 0, tot1 = 0;
          this.userFinancialRptList.forEach(d => {
          const row = worksheet.addRow(d);
          const qty = row.getCell(4).value;
          var result = qty.toString().replace(/,/g, "");
          tot = parseInt(result) + tot;       
          row.getCell(4).alignment = {horizontal : 'right'}
          row.getCell(5).alignment = {horizontal : 'right'}
          }
        );
        worksheet.getColumn(2).width = 20;
        worksheet.getColumn(3).width = 20;    
        worksheet.getColumn(4).width = 20;
        worksheet.getColumn(5).width = 20; 
        worksheet.getColumn(6).width = 20;
        worksheet.getColumn(7).width = 20;
        // Footer Row
        const footerRow = worksheet.addRow([]);
        footerRow.getCell(3).value = 'Grand Total';
        footerRow.getCell(4).value = tot.toLocaleString();       
        footerRow.getCell(4).alignment = {horizontal:'right'};
        footerRow.getCell(4).alignment = {horizontal:'right'};
        footerRow.getCell(4).fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'FFCCFFE5' }
        };
        footerRow.getCell(4).border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
        footerRow.getCell(4).fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'FFCCFFE5' }
        };
        workbook.xlsx.writeBuffer().then((data: any) => {
        const blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        fs.saveAs(blob, this.filename + repType);
        });
     }
     if(this.report_option == 'csv')
     {
      
     }
       this.toastr.success('Reporting Success', 'Success!', {
                timeOut: 3000,
                positionClass: 'toast-top-right',
              });
            this.spinner.hide();
            });
    }

}
