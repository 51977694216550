import { Component, OnInit, ViewChild } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,HttpErrorResponse } from '@angular/common/http';
import { Router, NavigationEnd } from '@angular/router';
import { DataTableDirective } from 'angular-datatables';

import { LocalStorageService } from 'ngx-webstorage';
import { NgxSpinnerService } from "ngx-spinner";

import { catchError, retry } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { DtoService } from '../../service/dto.service';
import { FunctService } from '../../service/funct.service';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
import Responsive from 'datatables.net-responsive';
declare var $: any;
@Component({
  selector: 'app-advertise-team-list',
  templateUrl: './advertise-team-list.component.html',
  styleUrls: ['./advertise-team-list.component.css']
})
export class AdvertiseTeamListComponent implements OnInit {

  @ViewChild(DataTableDirective)
  dtElement: DataTableDirective;
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();

  phoneNo: string= '';
  name: string= '';
  referralCode: string= '';
  status: string= '';
  agentList: any;
  idIndex: any;

  hardcode_view : any;
  hardcode_edit :any;
  hardcode_add :any;
  hardcode_delete: any;
  hardcode_delete_bool : any;
  hardcode_add_bool : any;
  hardcode_edit_bool : any;
  hardcode_double :any;
  id_advertise :any;

  constructor(private storage: LocalStorageService, private spinner: NgxSpinnerService, private toastr: ToastrService, private http: HttpClient, private dto: DtoService, private router: Router,
    private funct: FunctService) {
      this.storage.store('isNotiSong', "");
    this.idIndex = 1;
    this.status = 'ACTIVE';
    
    this.hardcode_view = "AdvertisingTeam_View";
    this.hardcode_delete = "AdvertisingTeam_Del";
    this.hardcode_edit = "AdvertisingTeam_Edit";
    this.hardcode_add = "AdvertisingTeam_Add";
    this.dto.menuCodes = this.storage.retrieve('menucodes');

    const filteredMenuCodes_delete = [];
    const filteredMenuCodeds_add =[];
    const filteredMenuCodeds_edit =[];
    this.dto.menuCodes.forEach(menuCode => {
      if (menuCode === this.hardcode_delete) {
        filteredMenuCodes_delete.push(menuCode);
      }
      if (menuCode === this.hardcode_add) {
        filteredMenuCodeds_add.push(menuCode);
      }
      if (menuCode === this.hardcode_edit) {
        filteredMenuCodeds_edit.push(menuCode);
      }
    });
        if(filteredMenuCodes_delete.length != 0)
        {
            this.hardcode_delete_bool = true;
        }
        else
        {
          this.hardcode_delete_bool = false;
        }
        if(filteredMenuCodeds_add.length != 0)
        {
              this.hardcode_add_bool = true;
        }
        else
        {
          this.hardcode_add_bool = false;
        }
        if(filteredMenuCodeds_edit.length != 0)
        {
            this.hardcode_edit_bool = true;
        }
        else
        {
          this.hardcode_edit_bool = false;
        }
        if(this.hardcode_delete_bool == false && this.hardcode_edit_bool == false)        
        {
            this.hardcode_double = false;
        }
        else
        {
          this.hardcode_double = true;
        }


    this.search();
    
   }

  ngOnInit(): void {

    
    this.router.routeReuseStrategy.shouldReuseRoute = function () {
      return false;
    };

    this.router.events.subscribe((evt) => {
      if (evt instanceof NavigationEnd) {
        this.router.navigated = false;
        window.scrollTo(0, 0);
      }
    });

    this.dtOptions = {
      responsive:{
        details: {
            renderer: Responsive.renderer.listHiddenNodes()
        }
      },
      stateSave: true,
      order:[]
    }

    this.dtOptions.columnDefs = [
      { targets: [12], orderable: true }

    ];

    if(!this.storage.retrieve('loadFlag')){
      this.storage.store('loadFlag', 'noLoad');
      setTimeout(function(){
        location.reload();
      }, 5);
    }
    else{
      this.storage.clear('loadFlag');
    }


  }

  ngAfterViewInit(){

  }

  handleError(error: HttpErrorResponse){
    this.spinner.hide();
    if(error.status == 423)
    {
      this.spinner.hide();
      $("#deleteData").modal("show");
    }
    if(error.status == 403)
    {
      this.spinner.hide();
      var id = 'tblAgent' + this.idIndex;
      var table = $('#' + id).DataTable();
      this.toastr.error("Limited Access.", 'Invalid!', {
        timeOut: 3000,
        positionClass: 'toast-top-right',
        });
    }
    if(error.status == 406)
    {
      this.spinner.hide();
      this.toastr.error("Can't delete because users use this referral code!", 'Invalid!', {
        timeOut: 3000,
        positionClass: 'toast-top-right',
      });
    }
    return throwError(error);
    }
    OkLogout()
    {
      window.location.href ="./ad-login";
    } 

  search(){
    this.agentList = [];
    var id = 'tblAgent' + this.idIndex;
    var table = $('#' + id).DataTable();
    table.destroy();
    this.idIndex = this.idIndex +1;
    this.spinner.show();
    this.dto.token = this.storage.retrieve('token');
    let headers = new HttpHeaders();
    headers = headers.set('Authorization', this.dto.token);
    let params = new HttpParams();
    params = params.set('phoneNo',this.phoneNo).set('name',this.name).set('referralCode',this.referralCode).set('status', this.status).set('role_name', 'ADVERTISING');
    this.http.get(this.funct.ipaddress + 'agent/agentsByparams', { params: params, headers: headers } )
    .pipe(
      catchError(this.handleError.bind(this))
     )
    .subscribe(
      result => {
        this.dto.Response = {};
        this.dto.Response = result;
        this.agentList = this.dto.Response;
        this.dtTrigger.next();
        this.spinner.hide();
      }
    );
  }
  onInput(event: InputEvent) {
    const myanmarCharactersRegex = new RegExp('^[\u1000-\u109F\uAA60-\uAA7F\uA9E0-\uA9FF]+$');
    const inputValue = (event.target as HTMLInputElement).value; 
    if(event.data!=null)
    {
      if (myanmarCharactersRegex.test(inputValue)) 
      {        
        this.spinner.hide();
        this.toastr.error( "Myanmar typing detected!", '', {
        timeOut: 5000,
        positionClass: 'toast-top-right',
        });  
      }
    }   
  }
  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }

  
  delete(id)
  {
    $("#deleteDataForTutorial").modal("show");
    this.id_advertise = id;       
  } 

  closeTran()
  {
      $('#deleteDataForTutorial').modal("hide");
      this.router.navigate(['/advertise-team-list']).then(() => 
      {
      // this.toastr.error("Advertise Team ", 'Cancel!', {
      //   timeOut: 3000,
      //   positionClass: 'toast-top-right',
      // });
    });
  }

  deleteadvertise()
  {
    this.dto.token = this.storage.retrieve('token');
    let headers = new HttpHeaders();
    headers = headers.set('Authorization',  this.dto.token);
    let params = new HttpParams();
    params = params.set('id',this.id_advertise);
    this.http.get(this.funct.ipaddress + 'agent/delete', { params: params, headers: headers })
    .pipe(
      catchError(this.handleError.bind(this))
     )
    .subscribe(
      result => {
        this.dto.Response = {};
        this.dto.Response = result;
        if (this.dto.Response.status == 'Success') {
          this.spinner.hide();  
          this.search();         
          this.toastr.success(this.dto.Response.message, 'Success!', {
            timeOut: 3000,
            positionClass: 'toast-top-right'
          })     
        }
        else {
          this.spinner.hide();
          this.toastr.error(this.dto.Response.message, 'Invalid!', {
            timeOut: 3000,
            positionClass: 'toast-top-right',
          });
        }
      });
      $('#deleteDataForTutorial').modal("hide");
    }



    addStepAndNavigate(agent: any): void
    { 
     localStorage.setItem('advData', JSON.stringify({
      id:agent.id,
      name:agent.name,
      phone_no:agent.phone_no,
      role_name:agent.role_name,
      referral_code:agent.referral_code,
      balance_Str:agent.balance_Str,
      commission_balance_Str:agent.commission_balance_Str,      
      withdrawal_balance_Str:agent.withdrawal_balance_Str,
      status:agent.status,
      created_date_string:agent.created_date_string,
      created_by_name:agent.created_by_name,
      updated_date_string:agent.updated_date_string,
      updated_by_name:agent.updated_by_name
       
     }));
  
     this.router.navigate(['/advertise-team-detail', agent.id]);
   }


}
