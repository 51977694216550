import { Component, OnInit, ViewChild } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,HttpErrorResponse } from '@angular/common/http';
import { DataTableDirective } from 'angular-datatables';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { DatePipe } from '@angular/common'
import { LocalStorageService } from 'ngx-webstorage';
import { NgxSpinnerService } from "ngx-spinner";
import { DtoService } from '../../service/dto.service';
import { FunctService } from '../../service/funct.service';
import { catchError, retry } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
declare var $: any;

@Component({
  selector: 'app-admin-detail-historys',
  templateUrl: './admin-detail-historys.component.html',
  styleUrls: ['./admin-detail-historys.component.css']
})
export class AdminDetailHistorysComponent implements OnInit {

  @ViewChild(DataTableDirective)
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();

  dtOptions1: DataTables.Settings = {};
  dtTrigger1: Subject<any> = new Subject();

  idIndex: any;
  page = 1;
  page1 = 1;
  type: any;
  //ADMIN  
  adminDTO: any;
  token: any;
  adminId: any;
  fieldTextType: boolean;
  confrimFieldTextType: boolean;  
  roleList = [];
  initialAdminDTO: any = {}; 
  editedArray: any[] = [];  
  confirmFieldTextType: boolean = false;
  originalAdminDTO :any;
  formattedChanges :any;
  confirmPassword:any;

  
  hardcode_edit :any;
  hardcode_add :any;
  hardcode_view: any;
  hardcode_delete:any;
  hardcode_view_bool : any;
  hardcode_add_bool : any;
  hardcode_edit_bool : any;
  hardcode_delete_bool:any;
  hardcode_double_bool:any;

  constructor(private storage: LocalStorageService,private spinner: NgxSpinnerService, private dto: DtoService, private http: HttpClient, private router: Router,
    private route: ActivatedRoute, private funct: FunctService,private toastr: ToastrService, private datepipe: DatePipe) 
    { 
      this.storage.store('isNotiSong', "");
      this.getAllRoles();      
    this.hardcode_add = "Admin_ResetPwd";    
    this.dto.menuCodes = this.storage.retrieve('menucodes');
    const filteredMenuCodeds_add =[];  
    this.dto.menuCodes.forEach(menuCode => 
    {  
      if (menuCode === this.hardcode_add) {
        filteredMenuCodeds_add.push(menuCode);
      }   
      
    });
   
    if(filteredMenuCodeds_add.length != 0)
    {
          this.hardcode_add_bool = true;
    }
    else 
    {
      this.hardcode_add_bool = false;
    }
  }

  ngOnInit(): void
  {
    this.adminId = this.route.snapshot.paramMap.get("id");

    if(!this.storage.retrieve('loadFlag')){
      this.storage.store('loadFlag', 'noLoad');
      setTimeout(function(){
        location.reload();
      }, 1);
    }
    else{
      this.storage.clear('loadFlag');
    }
        
   
    const adminDataString = localStorage.getItem('adminData');
    this.originalAdminDTO = JSON.parse(adminDataString);

    // this.adminId = this.route.snapshot.paramMap.get("id");
    if(this.adminId == null){
      $(document).ready(function () {
        $('#referralCode').remove();
        $('#updatedata').remove();
      });

      this.adminDTO = {
        id: 0,
        phoneNo: '',
        roleId: 0,
        roleName: '',      
        name: '',
        password: '',
        confirmPassword: '',
        oldPassword: '',
        newPassword: '',
        balance: 0,
        referralCode: '',
        image: '',
        status: 'ACTIVE'
      }
    }
    else{
      $(document).ready(function () {
        $('#password').remove();
        $('#confirmPassword').remove();
        $('#updatedata').show();
      });
      this.adminDTO = {
        id: 0,
        phoneNo: '',
        roleId: 0,
        roleName: '',     
        name: '',
        password: '',
        confirmPassword: '',
        oldPassword: '',
        newPassword: '',
        balance: 0,
        referralCode: '',
        image: '',
        status: 'ACTIVE'
      };
      this.getAdminById();     
    }

    $('#admindetail-tab').tab('show');
    

  }

  goToAdminDetail(): void {
    // window.location.href = `/admin-detail/${this.adminId}`;
    $('#admindetail-tab').tab('show');
  }

  resettab(): void {
    setTimeout(() => {
      $('#resetpassword-tab').tab('show');
    }, 0);
  }

  toggleConfirmFieldTextType() {
    this.confirmFieldTextType = !this.confirmFieldTextType;
  }


  handleError(error: HttpErrorResponse){
    if(error.status == 423)
    {
      this.spinner.hide();
      $("#deleteData").modal("show");
    }
    if(error.status == 403)
    {
      this.spinner.hide();
      this.toastr.error("Limited Access.", 'Invalid!', {
        timeOut: 3000,
        positionClass: 'toast-top-right',
        });
    }
    if(error.status == 400)
    {
      this.spinner.hide();
      this.toastr.error("Invalid Parameters.", 'Invalid!', {
        timeOut: 3000,
        positionClass: 'toast-top-right',
        });
    }
    return throwError(error);
    }
    OkLogout()
    {
      window.location.href ="./ad-login";
    } 

  getAdminById(){
    this.token = this.storage.retrieve('token');
    let headers = new HttpHeaders();
    headers = headers.set('Authorization',  this.token);
    let params = new HttpParams();
    params = params.set('id',this.adminId);
    this.http.get(this.funct.ipaddress + 'admin/GetDetail', {params: params, headers: headers })
    .pipe(
      catchError(this.handleError.bind(this))
     )
    .subscribe(
      result => {
        this.dto.Response = {};
        this.dto.Response = result;
        this.adminDTO = this.dto.Response;
      }
    );
  }

  getAllRoles() {
    this.token = this.storage.retrieve('token');
    let headers = new HttpHeaders();
    headers = headers.set('Authorization',  this.token);
    this.roleList = [];
    this.http.get(this.funct.ipaddress + 'admin/GetAllRoles', { headers: headers }).subscribe(
      result => {
        this.dto.Response = {};
        this.dto.Response = result;
        this.roleList = this.dto.Response;
        this.roleList.push("None");
      }
    );
  }

  flagRole(){
    this.adminDTO.role_id = $("#role_id").val();
    
  }

  toggleFieldTextType() {
    this.fieldTextType = !this.fieldTextType;
  }

  toggleConfrimFieldTextType() {
    this.confrimFieldTextType = !this.confrimFieldTextType;
  }

  goSave(){
    

    if(this.adminId == null){
      this.save();
    }
    else{
      this.edit();
    }
  }

  goCancel(){
    if(this.adminId == null){
      this.cancel();
    }
    else{
      this.editCancel();
    }
  }
  
  edit(){
    this.spinner.show();
    this.token = this.storage.retrieve('token');
    let editedArray = this.getEditedArray();      
    let headers = new HttpHeaders();
    if(editedArray.length > 0)
    {    
      headers = headers.set('Authorization',  this.token).set("editedArray",editedArray);
    }
    else{
      headers = headers.set('Authorization',  this.token);
    } 
    console.log('Edit Array',editedArray);
    this.http.post(this.funct.ipaddress + 'admin/update', this.adminDTO, {headers: headers})
    .pipe(
      catchError(this.handleError.bind(this))
     )
    .subscribe(
      result => {
        this.dto.Response = {};
        this.dto.Response = result;
        if (this.dto.Response.status == 'Success') {
          this.spinner.hide();
          this.router.navigate(['/admin-list']).then(() => {
            this.toastr.success(this.dto.Response.message, 'Success!', {
              timeOut: 3000,
              positionClass: 'toast-top-right'
            });
          })
        }
        else{
          this.spinner.hide();
          this.toastr.error(this.dto.Response.message, 'Invalid!', {
          timeOut: 3000,
          positionClass: 'toast-top-right',
          });
        }
      });
  }

  save(){
    this.spinner.show();
    let mobNumberPattern = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{3,6}$/im;
    if (mobNumberPattern.test(this.adminDTO.phone_no)) {
      if(this.adminDTO.password === this.adminDTO.confirmPassword){
        if(this.adminDTO.role_id != 0){
          this.token = this.storage.retrieve('token');
          let headers = new HttpHeaders();
          headers = headers.set('Authorization', this.token);
          this.http.post(this.funct.ipaddress + 'Authenticate/adRegister', this.adminDTO, {headers: headers})
          .pipe(
            catchError(this.handleError.bind(this))
           )
          .subscribe(
            result => {
              this.dto.Response = {};
              this.dto.Response = result;
              if (this.dto.Response.status == 'Success') {
                this.spinner.hide();
                this.router.navigate(['/admin-list']).then(() => {
                  this.toastr.success(this.dto.Response.message, 'Success!', {
                    timeOut: 3000,
                    positionClass: 'toast-top-right'
                  });
                })
              }
              else{
                this.spinner.hide();
                this.toastr.error(this.dto.Response.message, 'Invalid!', {
                timeOut: 3000,
                positionClass: 'toast-top-right',
                });
              }
            }
          );
        }
        else{
          this.spinner.hide();
          this.toastr.error('Choose admin role', 'Invalid input!', {
            timeOut: 3000,
            positionClass: 'toast-top-right',
          });
        }
      }
      else{
      this.spinner.hide();
      this.toastr.error('Password missmatch', 'Invalid input!', {
        timeOut: 3000,
        positionClass: 'toast-top-right',
      });
      }

    }
    else{
      this.spinner.hide();
      this.toastr.error('Please enter valid phone no.', 'Invalid input!', {
        timeOut: 3000,
        positionClass: 'toast-top-right',
      });
    }
  }

  cancel(){
    this.adminDTO = {
      id: 0,
      phoneNo: '',
      roleId: 0,
      roleName: '',
      name: '',
      password: '',
      confirmPassword: '',
      oldPassword: '',
      newPassword: '',
      balance: 0,
      referralCode: '',
      image: '',
      status: 'ACTIVE'
    }
  }
  editCancel(){
    this.getAdminById();
  } 


  getEditedArray() {
    let editedArray = '';
   
    if (this.adminDTO.name !== this.originalAdminDTO.name) {
       editedArray += 'Name: ' + this.originalAdminDTO.name + ' -> ' + this.adminDTO.name + ', ';
     
    }
  
    if (this.adminDTO.phone_no !== this.originalAdminDTO.phone_no) {
      editedArray += 'Phone No: ' + this.originalAdminDTO.phone_no + ' -> ' + this.adminDTO.phone_no + ', ';
    }
  
    if(this.adminDTO.roleName !== undefined)
    {
      if (this.adminDTO.roleName !== this.originalAdminDTO.role_name) {
        editedArray += 'Role: ' + this.originalAdminDTO.role_name + ' -> ' + this.adminDTO.roleName + ', ';
      }
    }
  
    if (this.adminDTO.status !== this.originalAdminDTO.status) {
      editedArray += 'Status: ' + this.originalAdminDTO.status + ' -> ' + this.adminDTO.status + ', ';
    }
    if (editedArray.endsWith(', ')) {
      editedArray = editedArray.slice(0, -2);
    }
    
    return editedArray;
  }
  
  
  onRoleChange(event: Event) {
    const selectElement = event.target as HTMLSelectElement;
    const selectedRoleId = selectElement.value;

    const selectedRole = this.roleList.find(role => role.id === +selectedRoleId);

    if (selectedRole) 
    {
      this.adminDTO.role_id = selectedRole.id;
      this.adminDTO.roleName =selectedRole.name
    
      console.log('Selected Role ID:', selectedRole.id);
      console.log('Selected Role Name:', selectedRole.name);
      
    }
  }
  goSavePasswordReset()
  { 
    this.spinner.show();
    this.token = this.storage.retrieve('token');       
    let headers = new HttpHeaders();   
    headers = headers.set('Authorization',  this.token);    
    var formData = new FormData();
    formData.append("adminId",this.adminDTO.id);
    formData.append("newPassword",this.confirmPassword);

    this.http.post(this.funct.ipaddress + 'admin/updatePasswordById', formData, {headers: headers})
    .pipe(
      catchError(this.handleError.bind(this))
     )
    .subscribe(
      result => {
        this.dto.Response = {};
        this.dto.Response = result;
        if (this.dto.Response.status == 'Success') 
        {
          this.spinner.hide();
          this.router.navigate(['/admin-list']).then(() => {
            this.toastr.success(this.dto.Response.message, 'Success!', {
              timeOut: 3000,
              positionClass: 'toast-top-right'
            });
          })
        }
        else{
          this.spinner.hide();
          this.toastr.error(this.dto.Response.message, 'Invalid!', {
          timeOut: 3000,
          positionClass: 'toast-top-right',
          });
        }
      });
    
   
  }

}
