import { Component, OnInit, ViewChild  } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,HttpErrorResponse } from '@angular/common/http';
import { DataTableDirective } from 'angular-datatables';
import { Router, NavigationEnd } from '@angular/router';
import { DatePipe } from '@angular/common'

import { LocalStorageService } from 'ngx-webstorage';
import { NgxSpinnerService } from "ngx-spinner";

import { catchError, retry } from 'rxjs/operators';
import { throwError } from 'rxjs';

import { DtoService } from '../../service/dto.service';
import { FunctService } from '../../service/funct.service';
import { ToastrService } from 'ngx-toastr';

import { Subject } from 'rxjs';
declare var $: any;
@Component({
  selector: 'app-comment',
  templateUrl: './threed-winner-comment.component.html',
  styleUrls: ['./threed-winner-comment.component.css']
})
export class ThreedWinnerCommentComponent implements OnInit {

  @ViewChild(DataTableDirective)
  dtElement: DataTableDirective;
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();

  alltoDate: string = ''; //add this
  alltodate : any;
  alldate: any;
  singleDate: string = '';
  commentmodel:string ='';
  allchangeDate: any;
  alltodayDate: any;
  alltodaytodate : any;
  alltodatechangeDate : any; //add this

  idIndex: any;
  title: string = '';
  phoneNo: string = '';

  comment: any;
  commentname:any;

  hardcode_edit :any;
  hardcode_add :any;
  hardcode_delete:any;
  hardcode_view: any;
  hardcode_view_bool : any; 
  hardcode_edit_bool : any;
  hardcode_delete_bool:any;

  constructor(private toastr: ToastrService,private storage: LocalStorageService, private spinner: NgxSpinnerService, 
    private dto: DtoService, private http: HttpClient, private funct: FunctService, private router: Router,private datepipe: DatePipe) {
      this.storage.store('isNotiSong', "");
    this.idIndex = 1;
    this.alldate = new Date();
    console.log("alldate >>"+this.alldate);
    this.alltodate = new Date();  
    console.log("alltodate >>"+this.alltodate);
    this.alltodayDate = this.datepipe.transform(this.alldate, 'MMM dd, yyyy');
    this.alltodaytodate = this.datepipe.transform(this.alltodate, 'MMM dd, yyyy');


  
  
  this.hardcode_view = "Comments_View";
          this.hardcode_edit = "Comments_Edit";
          this.hardcode_delete ="Comments_Del";
          this.dto.menuCodes = this.storage.retrieve('menucodes');
    
          const filteredMenuCodes_view = [];    
          const filteredMenuCodeds_edit =[];
          const filteredMenuCodeds_delete =[];
          this.dto.menuCodes.forEach(menuCode => {
            if (menuCode === this.hardcode_view) {
              filteredMenuCodes_view.push(menuCode);
            }     
            if (menuCode === this.hardcode_edit) {
              filteredMenuCodeds_edit.push(menuCode);
            }
            if (menuCode === this.hardcode_delete) {
              filteredMenuCodeds_delete.push(menuCode);
            }
          });
              if(filteredMenuCodes_view.length != 0)
              {
                  this.hardcode_view_bool = true;
              }       
              if(filteredMenuCodeds_edit.length != 0)
              {
                  this.hardcode_edit_bool = true;
              }
              if(filteredMenuCodeds_delete.length != 0)
              {
                  this.hardcode_delete_bool = true;
              }
  
    this.search();
   }

   ngOnInit(): void {

    this.router.routeReuseStrategy.shouldReuseRoute = function () {
      return false;
    };

    this.router.events.subscribe((evt) => {
      if (evt instanceof NavigationEnd) {
        // trick the Router into believing it's last link wasn't previously loaded
        this.router.navigated = false;
        // if you need to scroll back to top, here is the right place
        window.scrollTo(0, 0);
      }
    });

    this.dtOptions = {
      responsive: true,
      order: []
    }

    this.dtOptions.columnDefs = [
      { targets: [5], orderable: false }
    ];

    if (!this.storage.retrieve('loadFlag')) {
      this.storage.store('loadFlag', 'noLoad');
      setTimeout(function () {
        location.reload();
      }, 5);
    }
    else {
      this.storage.clear('loadFlag');
    }
  }

  onChangeSingle() {
    $(document).ready(function () {
      this.singleDate = $("#singleDate").val();
    });
  }
   
  onChangeAllToDate() {
    $(document).ready(function () {
      this.alltoDate = $("#alltodate").val();
    });
  }
  handleError(error: HttpErrorResponse){
    if(error.status == 423)
    {
      this.spinner.hide();
      $("#deleteData").modal("show");
    }
    if(error.status == 403)
    {
      this.spinner.hide();
      var id = 'tblComment' + this.idIndex;
      var table = $('#' + id).DataTable();
      this.toastr.error("Limited Access.", 'Invalid!', {
        timeOut: 3000,
        positionClass: 'toast-top-right',
        });
    }
    return throwError(error);
    }
    OkLogout()
    {
      window.location.href ="./ad-login";
    } 
  
  search() {
    this.comment = [];
    var id = 'tblComment' + this.idIndex;
    var table = $('#' + id).DataTable();
    table.destroy();
    this.idIndex = this.idIndex + 1;
    this.spinner.show();
    this.dto.token = this.storage.retrieve('token');
    this.singleDate = $("#singleDate").val();
    this.alltoDate = $("#alltodate").val();
    if (this.singleDate == '' || this.singleDate == undefined) {
      console.log("date if case");
      this.allchangeDate = this.alltodayDate;
    }
    else {
      console.log("date else case");
      this.allchangeDate = this.singleDate;
    }
  
    if (this.alltoDate == '' || this.alltoDate == undefined) {
      console.log("date if case for to date");
      this.alltodatechangeDate = this.alltodaytodate;
    }
    else {
      console.log("date else case");
      this.alltodatechangeDate = this.alltoDate;
    }
    let headers = new HttpHeaders();
    headers = headers.set('Authorization', this.dto.token);
    let params = new HttpParams();
    params = params.set('fromDate',this.allchangeDate).set('toDate',this.alltodatechangeDate).set('comment', this.commentmodel);
     this.http.get(this.funct.ipaddress + 'socialmedia/getthreedcomment-by-params', { params: params, headers: headers })
     .pipe(
      catchError(this.handleError.bind(this))
     )
     .subscribe( //change
      result => {
        this.dto.Response = {};
        this.dto.Response = result;
        //console.log("this.dto.Response>> " + JSON.stringify(this.dto.Response));
        this.comment = this.dto.Response;//.data.feedbackDTOList;
        this.dtTrigger.next();
        this.spinner.hide();
      }
    );
  }

  
   
   
  addStepAndNavigate(cmt: any): void
 { 
  localStorage.setItem('cmtData', JSON.stringify({
    id : cmt.id,
    status :cmt.status
  }));

  this.router.navigate(['/threed-winner-comment-detail', cmt.comment_id, 1]);
}
addStepAndNavigate1(cmt: any): void
{ 
 localStorage.setItem('cmtData', JSON.stringify({
   id : cmt.id,
   status :cmt.status
 }));

 this.router.navigate(['/threed-winner-comment-detail', cmt.comment_id, 0]);
}

}
