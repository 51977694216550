<div class="wrapper">
    <app-header-sidebar></app-header-sidebar>
    
    <div class="content-wrapper">

        <div class="content-header">
          <div class="container-fluid">
            <div class="row mb-2" style="border: 1px solid #ced4da;border-radius: 0.25rem;">
              <div class="col-sm-8">
                <h3 class="m-0 text-dark" style="font-weight: bold;padding: 3px 0px 3px 5px;"> User Otps </h3>
              </div>
            </div>
          </div>
        </div>
 
        <section class="content">
          <div class="container-fluid">
            <form>
              <div class="row">

                <div class="form-group col-md-2">
                  <label>Name</label>
                  <input class="form-control form-control-sm" [(ngModel)]="name" [ngModelOptions]="{standalone: true}">
                </div>

                <div class="form-group col-md-2">
                  <label>Phone No</label>
                  <input class="form-control form-control-sm" [(ngModel)]="phoneNo" [ngModelOptions]="{standalone: true}" (input)="onInput($event)">
                </div>


                <!-- <div class="form-row">
                  <div class="form-group col-md-4">
                    <label for="selectedPreset">Date Range</label>
                    <div class="input-group custom-input-group">
                      <div class="input-group-prepend">
                        <span class="input-group-text" id="dateIcon">
                          <i class="fas fa-calendar-alt"></i>
                        </span>
                      </div>
                      <select class="form-control form-control-sm col-md-12" [(ngModel)]="selectedPreset"
                        [ngModelOptions]="{standalone: true}" (change)="onDateRangeChange()">
                        <option value="today">Today</option>
                        <option value="yesterday">Yesterday</option>
                        <option value="last7days">Last 7 Days</option>
                        <option value="last30days">Last 30 Days</option>
                        <option value="thisweek">This Week</option>
                        <option value="lastweek">Last Week</option>
                        <option value="thismonth">This Month</option>
                        <option value="lastmonth">Last Month</option>
                      </select>
                    </div>
                  </div>
    
                  <div class="form-group col-md-4">
                    <label for="startDate">From Date</label>
                    <input id="startDate" readonly class="form-control form-control-sm" placeholder="Select Date"
                      [ngxDatePicker]="dateInstanceSingle" name="startDate" (valueChange)="onChangeSingle()">
                    <ngx-date-picker #dateInstanceSingle></ngx-date-picker>
                  </div>
    
                  <div class="form-group col-md-4">
                    <label for="endDate">To Date</label>
                    <input id="endDate" readonly class="form-control form-control-sm" placeholder="Select Date"
                      [ngxDatePicker]="dateInstanceSingle1" name="endDate" (valueChange)="onChangeAllToDate()">
                    <ngx-date-picker #dateInstanceSingle1></ngx-date-picker>
                  </div>
    
    
                </div> -->

                <div class="form-group col-md-2">
                  <label>From Date</label>
                  <input id="approveFromDate" readonly class="form-control form-control-sm" placeholder="Select Date"
                    [ngxDatePicker]="dateInstanceApprove"  [(ngModel)]="approvefromdate"
                    [ngModelOptions]="{standalone: true}"  (ngModelChange)="onChangeApproveFromDate()">
                  <ngx-date-picker #dateInstanceApprove></ngx-date-picker>
                </div>
                 
                <div class="form-group col-md-2">
                  <label>To Date</label>
                  <input id="approveToDate" readonly class="form-control form-control-sm" placeholder="Select Date"
                    [ngxDatePicker]="dateInstanceApprove1"  [(ngModel)]="approvetodate"
                    [ngModelOptions]="{standalone: true}" (ngModelChange)="onChangeApproveToDate()">
                  <ngx-date-picker #dateInstanceApprove1></ngx-date-picker>
                </div>

                <div class="form-group col-md-2">
                  <label> Latest Function Name </label>
                  <select class="latest_function_name form-control form-control-sm col-md-12 col-sm-4 col-xs-4"
                      [(ngModel)]="latest_function_name" [ngModelOptions]="{standalone: true}">
                      <option value="" selected>All</option>
                      <option value="Forgot Password OTP">Forgot Password OTP</option>
                      <option value="Register OTP">Register OTP</option>
                      <option value="Withdrawal OTP">Withdrawal OTP</option>
                      <option value="New Device OTP">New Device OTP</option>
                     
                      
                  </select>
              </div>

                <div class="form-group col-md-2 col-sm-2 col-xs-2">
                  <label>&nbsp;&nbsp;</label>
                  <button id="search" type="button" class="btn btn-block btn-primary btn-sm" (click)="Search()">Search</button>
                </div>
         
              </div>

             
                <div class="col-md-12">
                  <div class="card">
                    <div class="card-header">
                       <h3 class="card-title" style="font-weight: bold;"> User Otps List </h3>
                    </div>
               
                    <div class="card-body">

                      <table width='100%' id="{{'tbOtpSendCount' + idIndex}}" datatable   
                      [dtTrigger]="dtTrigger" [dtOptions]="dtOptions" class="table table-bordered table-hover"> 
                        <thead>
                        <tr>
                          <th style="font-family: unicode;">Name</th>
                          <th style="font-family: unicode;">Phone No</th>
                          <th style="font-family: unicode; color: white; background-color: orange;">Send Count</th>
                          <th style="font-family: unicode; color: white; background-color: orange;">Is Blocked?</th>
                          <th style="font-family: unicode;">Latest Function Name</th>                       
                          <th style="font-family: unicode;">Latest Send Date and Time</th>
                          <th style="font-family: unicode;">Updated Date</th>
                          <th style="font-family: unicode;">Updated By</th>
                          <th style="font-family: unicode;" >Block Action</th>                      
                          <th style="font-family: unicode;" *ngIf="hardcode_edit_bool === true">Send Count Action</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr *ngFor="let userOtp of userOtpList">
                          <td style="font-family: unicode;width: 150px;">{{userOtp.name}}</td>
                          <!-- <td style="font-family: unicode;" >
                            <div *ngIf="userOtp.name == null">{{userOtp.phone_no}}</div>
                            <div *ngIf="userOtp.name != null"><a [routerLink] = "['/user-otp-detail', userOtp.userID]" style="color:#4b88eb"> {{userOtp.phone_no}} </a> </div>
                          </td> -->
                          <td style="font-family: unicode;">
                            <div *ngIf="userOtp.name == null">{{userOtp.phone_no}}</div>
                            <div *ngIf="userOtp.name != null"><a [routerLink] = "['/user-detail', userOtp.userID]" [queryParams]="{ type: 'userotplist' }" style="color:#4b88eb" > {{userOtp.phone_no}} </a> </div>
                          </td>
                          <td style="font-family: unicode; color: white; background-color: orange;">{{userOtp.send_count}}</td>
                          <td style="font-family: unicode; color: white; background-color: orange;">{{userOtp.is_blocked}}</td>
                          <td style="font-family: unicode;">{{userOtp.latest_function_name}}</td>
                          <td style="font-family: unicode;">{{userOtp.latest_send_date_and_time_Str}}</td>
                          <td style="font-family: unicode;">{{userOtp.updated_date_Str}}</td>
                          <td style="font-family: unicode;">{{userOtp.updated_by}}</td>

                          <td style="font-family: unicode;" >
                            <button  type="button" class="btn btn-block btn-danger btn-sm" (click)="isBlock(true, userOtp.id)" *ngIf="userOtp.is_blocked == false && hardcode_block_bool === true" >Block?</button>
                            <button  type="button" class="btn btn-block btn-success btn-sm" (click)="isBlock(false, userOtp.id)" *ngIf="userOtp.is_blocked == true && hardcode_unblock_bool === true">Unblock?</button>
                          </td>

                          <td style="font-family: unicode;" *ngIf="hardcode_edit_bool === true">
                            <button  type="button" class="btn btn-block " (click)="editInfoPopup(userOtp.id, userOtp.phone_no, userOtp.send_count )" ><i class="fas fa-edit" style="color:#4b88eb"></i></button>
                          </td>
                        </tr>                        
                        </tbody>
                      </table> 

                      <tr *ngFor="let userOtp of userOtpList | paginate:  
                      { 
                        itemsPerPage: itemsPerPage,  
                        currentPage: page, 
                        totalItems: totalItems, 
                        id:'pagi1'}" ></tr> 
                      <div class = "row">
                        <div class = "col-md-6"></div>
                        <div class = "col-md-6">
                          <pagination-controls id="pagi1" (pageChange)="gty((page = $event))"></pagination-controls>
                        </div>
                      </div> 








                   
                  </div>
            
                  </div>
                </div>

            </form>

          </div>
        </section>

      </div>
      <aside class="control-sidebar control-sidebar-dark">
 
      </aside>
    
    <app-footer></app-footer>
    <ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#fff" type="square-jelly-box">
      <p style="font-size: 16px; color: white">Loading...</p>
    </ngx-spinner>
</div>

<div id="deleteData" class="modal fade">
  <div class="modal-dialog">
      <div class="modal-content">

          <div class="modal-body">
              <form>

                  <div class="row" style="margin-top: 5%;margin-bottom: 3%;margin-left: 9%;">
                      <div class="form-group col-md-12">
                          <h5> This Account is used by another device. 
                              Please Sign Out. Login Again. </h5>
                      </div>
                  </div>

                  <div class="row" style="margin-left: 40%;margin-bottom: 3%;">
                      <div class="col-md-5 col-sm-6" id="deleteBtn">
                          <button type="button" class="btn btn-block btn-success" (click)="OkLogout()"> Ok </button>
                      </div>
                  </div>
              </form>
          </div>

      </div>
  </div>
</div>

<div id="editInfo" class="modal fade">
  <div class="modal-dialog">
    <div class="modal-content" style="width : 700px; margin-left: -100px;">
      <div class="modal-header">
        <h3 class="modal-title" style="font-weight: bold;font-size: 1.1rem;">Edit Info</h3>
        <button type="button" class="close" data-dismiss="modal" aria-hidden="true"> x </button>
      </div>

      <div class="modal-body">
        <form>
          <div class="col-md-12">
            <div class="row" style="margin-left: 0.1%;">
              <label class="col-md-4"> Phone Number </label>
              <div class="form-group col-md-8">
                <label class="col-md-4"> {{PhoneNumber}} </label>
              </div>
            </div>
            <div class="row" style="margin-left: 0.1%;">
              <label class="col-md-4"> Type Desire Send Count </label>
              <div class="form-group col-md-8">              
                <input type = "text" class="form-control form-control-sm" [(ngModel)]="SendCount" [ngModelOptions]="{standalone: true}" >
              </div>
            </div>          
          
            <div class="row" style="margin-top: 1%;">
                <div class="col-md-4"></div>
                <div class="col-md-2 col-sm-6">
                  <button type="button"  class="btn btn-block btn-success" (click)="goSave()">Save</button>&nbsp;
                </div>
                <div class="col-md-2 col-sm-6">
                  <button type="button" class="btn btn-block btn-danger" data-dismiss="modal">Cancel</button>
                </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>


