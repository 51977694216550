
<div class="wrapper" ng-app="" >
  <app-header-sidebar></app-header-sidebar>

      <div class="content-wrapper">
        <!-- Content Header (Page header) -->
        <div class="content-header">
          <div class="container-fluid">
            <!--<div class="row mb-2" style="border: 1px solid #ced4da;border-radius: 0.25rem;">
              <div class="col-sm-8">
                <h3 class="m-0 text-dark" style="font-weight: bold;padding: 3px 0px 3px 5px;"> 2D Bet History Report </h3>
              </div>
            </div>-->
          </div><!-- /.container-fluid -->
        </div>
        <!-- /.content-header -->

    
        <!--end of test -->
        <!-- Main content -->
        <section class="content">
          <div class="container-fluid">
            <!-- here paste -->
            <!--start of test-->
        <form>
          <div class="row">

            <div class="col-md-12">
              <!-- Nav tabs -->
              <ul class="nav nav-tabs nav-pills">
                <li>
                  <a [class]="{active: this.tab == 'twodbetTab'}" id="twod-tab" data-toggle="tab" href="#twodbet"> 2D Report </a>
                </li>

                <li>
                  <a [class]="{active: this.tab == 'threedbetTab'}" id="threed-tab" data-toggle="tab" href="#threedbet"> 3D Report </a>
                </li>
            
                <li>
                  <a [class]="{active: this.tab == 'withdrawalTab'}" id="withdrawal-tab" data-toggle="tab" href="#withdrawalTab">Topup / Withdrawal</a>
                </li>
                <li>
                  <a [class]="{active: this.tab == 'gameTranTab'}" id="gameTran-tab" data-toggle="tab" href="#gameTranTab">Game Transaction</a>
                </li>
                <li>
                  <a [class]="{active: this.tab == 'allTranTab'}" id="allTran-tab" data-toggle="tab" href="#allTranTab">All Transaction</a>
                </li>
              
              </ul>
              <!-- Tab panes -->
              <div class="tab-content clearfix">
                <div class="tab-pane" id="twodbet"  [class.active]="this.tab == 'twodbetTab'">
                  <div class="card">
                    <div class="card-header">
                        <h3 class="card-title" style="font-weight: bold;"> 2D Bet / Winning Report </h3>
                    </div>
                    <!-- /.card-header -->
                    <div class="card-body">
                  <form>
                    <div class="row">
                      <div class="form-group col-md-2">
                          <label>From Date </label><span class ="req">*</span>
                      </div>
                      <div class="form-group col-md-2">
                        <input type = "date" style="width:250px;" [(ngModel)]="fromdate" name = "fromdate" class="form-control form-control-sm" id = "fdate">
                      </div>
                  </div>
      
                  <div class="row">
                      <div class="form-group col-md-2">
                          <label>To Date</label><span class ="req">*</span>
                      </div>
                      <div class="form-group col-md-2">
                        <input type = "date" style="width:250px;"  name = "todate" [(ngModel)]="todate" [ngModelOptions]="{standalone: true}" min="{{fromdate | date:'yyyy-MM-dd'}}" class="form-control form-control-sm" id = "tdate">
                      </div>
                  </div>
      
                  <div class="row">
                    <div class="form-group col-md-2">
                        <label>Phone</label><span class ="req">*</span>
                    </div>
                    <div class="form-group col-md-2">
                      <input type = "text" style="width:250px;" name = "phoneno" [(ngModel)]="phoneno" [ngModelOptions]="{standalone: true}"  id = "phoneno">
                    </div>
                </div>
      
                  <div class="row">
                      <div class="form-group col-md-2">
                          <label>Section</label><span class ="req">*</span>
                      </div>
                      <div class="form-group col-md-2">
                        <select id = "section" style="width:250px;"  class="form-control form-control-sm" [(ngModel)]="section" [ngModelOptions]="{standalone: true}">
                          <option *ngFor="let objSection of sectionList" value="{{objSection.sectionName}}">{{objSection.sectionName}}</option>
                           <option value = "">All</option>
                        </select> 
                      </div>
                  </div>
                  <div class="row">
                    <div class="form-group col-md-2">
                        <label>Choose Type</label><span class ="req">*</span>
                    </div>
                    <div class="form-group col-md-2">
                      <select id = "rType" style="width:250px;"  class="form-control form-control-sm" [(ngModel)]="rType" [ngModelOptions]="{standalone: true}">
                         <option value = "Bet">Bet</option>
                         <option value = "Winning">Winning</option>
                      </select> 
                    </div>
                </div>

                <div class="row" *ngIf = "rType == 'Winning'">
                  <div class="form-group col-md-2">
                      <label>Status</label><span class ="req">*</span>
                  </div>
                  <div class="form-group col-md-2">
                    <select id = "status" style="width:250px;"  class="form-control form-control-sm" [(ngModel)]="twodstatus" [ngModelOptions]="{standalone: true}">
                        <option value = "CONFIRMED" selected>CONFIRMED</option>
                        <option value = "PENDING">PENDING</option>
                       <option value = "">All</option> 
                    </select>
                  </div>
              </div>

                      <div class="row">
                        <div class = "col-lg-2">
                          <label> Choose Option</label>
                         </div>
                         <div class="form-group col-md-5" style="cursor: pointer;">
                          <img *ngIf="!clickkbzpay" src="assets/image/excel_pm.png" (click) = "getReportOption('excel')"
                            style="opacity: 0.3; height : 60px ;width: 60px;object-fit: cover;padding-left : 0px;border: 3px solid #ffffff;border-radius: 10px;border-color: #ffffff;">
                          <img *ngIf="clickkbzpay" src="assets/image/excel_pm.png" (click) = "getReportOption('excel')"
                           style="opacity: 1; height : 60px ; width: 60px; object-fit: cover;border: 3px sol;border-radius: 10px;padding-left : 0px;">
                           &nbsp;&nbsp;
          
                          <img *ngIf="!clickwavepay" src="assets/image/pdf3.png" (click) = "getReportOption('pdf')"
                            style="opacity: 0.3; margin-left : 2px; height : 60px ;width: 60px;object-fit: cover;border: 3px solid #ffffff;border-radius: 10px;border-color: #ffffff;">
                          <img *ngIf="clickwavepay" src="assets/image/pdf3.png" (click) = "getReportOption('pdf')"
                           style="opacity: 1; height : 60px ; margin-left : 2px; width: 60px;object-fit: cover;border: 3px sol;border-radius: 10px">
          
                           &nbsp;&nbsp;
          
                          <img *ngIf="!csvrep" src="assets/image/csv3.png" (click) = "getReportOption('csv')"
                            style="opacity: 0.3; padding-left :0px; height : 60px ;width: 60px;object-fit: cover;border: 3px solid #ffffff;border-radius: 10px;border-color: #ffffff;">
                          <img *ngIf="csvrep" src="assets/image/csv3.png" (click) = "getReportOption('csv')"
                           style="opacity: 1; height : 60px ; padding-left : 0px; width: 60px;object-fit: cover;border: 3px sol;border-radius: 10px">
                          </div> 
                      </div>
                      <div class="row">
                        <div class="form-group col-md-2 col-sm-4 col-xs-4">
                        </div>
                      <div class="form-group col-md-2 col-sm-4 col-xs-4" *ngIf="hardcode_add_bool === true">
                          <label></label>
                          <button id="generaterep" type="button" class="btn btn-block btn-primary btn-sm" style="margin-top: 4%;" (click)="twodReportUI()">Generate Report</button>
                      </div>
                      </div>
                  </form>
                  </div>
                  </div>
                </div>              
            

                <div class="tab-pane" id="threedbet" [class.active]="this.tab == 'threedbetTab'">
                  <div class="row">
                    <div class="col-md-12">
                      <div class="card">
                        <div class="card-header">
                            <h3 class="card-title" style="font-weight: bold;"> 3D Bet / Winning Report </h3>
                        </div>
                        
                        <div class="card-body">
                      <form>
                        <div class="row">
                          <div class="form-group col-md-2">
                              <label>From Date </label><span class ="req">*</span>
                          </div>
                          <div class="form-group col-md-2">
                            <input type = "date" style="width:250px;" [(ngModel)]="threedbetfromdate" name = "threedbetfromdate" class="form-control form-control-sm" id = "threedbetfdate">
                          </div>
                      </div>
          
                      <div class="row">
                          <div class="form-group col-md-2">
                              <label>To Date</label><span class ="req">*</span>
                          </div>
                          <div class="form-group col-md-2">
                            <input type = "date" style="width:250px;"  name = "threedbettodate" [(ngModel)]="threedbettodate" [ngModelOptions]="{standalone: true}" min="{{fromdate | date:'yyyy-MM-dd'}}" class="form-control form-control-sm" id = "threedbettdate">
                          </div>
                      </div>
          
                      <div class="row">
                        <div class="form-group col-md-2">
                            <label>Phone</label><span class ="req">*</span>
                        </div>
                        <div class="form-group col-md-2">
                          <input type = "text" style="width:250px;" name = "threedbetphoneno" [(ngModel)]="threedbetphoneno" [ngModelOptions]="{standalone: true}"  id = "threedbetphoneno">
                        </div>
                    </div>

                  <div class="row">
                      <div class="form-group col-md-2">
                          <label>Choose Type</label><span class ="req">*</span>
                      </div>
                      <div class="form-group col-md-2">
                        <select id = "r3Type" style="width:250px;"  class="form-control form-control-sm" [(ngModel)]="r3Type" [ngModelOptions]="{standalone: true}">
                           <option value = "Bet">Bet</option>
                           <option value = "Winning">Winning</option>
                        </select> 
                      </div>
                  </div>

                  <div class="row" *ngIf = "r3Type == 'Winning'">
                    <div class="form-group col-md-2">
                        <label>Status</label><span class ="req">*</span>
                    </div>
                    <div class="form-group col-md-2">
                      <select id = "status" style="width:250px;"  class="form-control form-control-sm" [(ngModel)]="threedstatus" [ngModelOptions]="{standalone: true}">
                          <option value = "CONFIRMED" selected>CONFIRMED</option>
                          <option value = "PENDING">PENDING</option>
                          <option value = "">All</option>
                      </select>
                    </div>
                </div>
                    <div class="row">
                            <div class = "col-lg-2">
                              <label> Choose Option</label>
                             </div>
                             <div class="form-group col-md-5" style="cursor: pointer;">
                              <img *ngIf="!clickkbzpay" src="assets/image/excel_pm.png" (click) = "getReportOption('excel')"
                                style="opacity: 0.3; height : 60px ;width: 60px;object-fit: cover;padding-left : 0px;border: 3px solid #ffffff;border-radius: 10px;border-color: #ffffff;">
                              <img *ngIf="clickkbzpay" src="assets/image/excel_pm.png" (click) = "getReportOption('excel')"
                               style="opacity: 1; height : 60px ; width: 60px; object-fit: cover;border: 3px sol;border-radius: 10px;padding-left : 0px;">
                               &nbsp;&nbsp;
              
                              <img *ngIf="!clickwavepay" src="assets/image/pdf3.png" (click) = "getReportOption('pdf')"
                                style="opacity: 0.3; margin-left : 2px; height : 60px ;width: 60px;object-fit: cover;border: 3px solid #ffffff;border-radius: 10px;border-color: #ffffff;">
                              <img *ngIf="clickwavepay" src="assets/image/pdf3.png" (click) = "getReportOption('pdf')"
                               style="opacity: 1; height : 60px ; margin-left : 2px; width: 60px;object-fit: cover;border: 3px sol;border-radius: 10px">
              
                               &nbsp;&nbsp;
              
                              <img *ngIf="!csvrep" src="assets/image/csv3.png" (click) = "getReportOption('csv')"
                                style="opacity: 0.3; padding-left :0px; height : 60px ;width: 60px;object-fit: cover;border: 3px solid #ffffff;border-radius: 10px;border-color: #ffffff;">
                              <img *ngIf="csvrep" src="assets/image/csv3.png" (click) = "getReportOption('csv')"
                               style="opacity: 1; height : 60px ; padding-left : 0px; width: 60px;object-fit: cover;border: 3px sol;border-radius: 10px">
                              </div> 
                          </div>
                          <div class="row">
                            <div class="form-group col-md-2 col-sm-4 col-xs-4">
                            </div>
                          <div class="form-group col-md-2 col-sm-4 col-xs-4" *ngIf="hardcode_add_bool === true">
                              <label></label>
                              <button id="threedgeneraterep" type="button" class="btn btn-block btn-primary btn-sm" style="margin-top: 4%;" (click)="threedReportUi()">Generate Report</button>
                          </div>
                          </div>
                      </form>
                      </div>
                      </div>
                    </div>

                  </div>
                </div> 

                <div class="tab-pane" id="topupTab" [class.active]="this.tab == 'topupTab'">
                  <div class="row">
                    <div class="col-md-12">
                      <div class="card">
                        <div class="card-header">
                            <h3 class="card-title" style="font-weight: bold;"> Top-Up Report </h3>
                        </div>
                     
                        <div class="card-body">
                      <form>
                        <div class="row">
                          <div class="form-group col-md-2">
                              <label>From Date </label><span class ="req">*</span>
                          </div>
                          <div class="form-group col-md-2">
                            <input type = "date" style="width:250px;" [(ngModel)]="topupfromdate" name = "topupfromdate" class="form-control form-control-sm" id = "topupfromdate">
                          </div>
                      </div>
          
                      <div class="row">
                          <div class="form-group col-md-2">
                              <label>To Date</label><span class ="req">*</span>
                          </div>
                          <div class="form-group col-md-2">
                            <input type = "date" style="width:250px;"  name = "topuptodate" [(ngModel)]="topuptodate" [ngModelOptions]="{standalone: true}" min="{{fromdate | date:'yyyy-MM-dd'}}" class="form-control form-control-sm" id = "topuptodate">
                          </div>
                      </div>

                      <div class="row">
                        <div class="form-group col-md-2">
                            <label>Phone</label><span class ="req">*</span>
                        </div>
                        <div class="form-group col-md-2">
                          <input type = "text" style="width:250px;" name = "topupphoneno" [(ngModel)]="topupphoneno" [ngModelOptions]="{standalone: true}"  id = "topupphoneno">
                        </div>
                    </div>

                    <div class="row">
                      <div class="form-group col-md-2">
                          <label>Status</label><span class ="req">*</span>
                      </div>
                      <div class="form-group col-md-2">
                        <select id = "status" style="width:250px;"  class="form-control form-control-sm" [(ngModel)]="topupstatus" [ngModelOptions]="{standalone: true}">
                            <option value = "APPROVED" selected>APPROVED</option>
                            <option value = "PENDING">PENDING</option>
                        </select>
                      </div>
                  </div>

                    <div class="row">
                            <div class = "col-lg-2">
                              <label> Choose Option</label>
                             </div>
                             <div class="form-group col-md-5" style="cursor: pointer;">
                              <img *ngIf="!clickkbzpay" src="assets/image/excel_pm.png" (click) = "getReportOption('excel')"
                                style="opacity: 0.3; height : 60px ;width: 60px;object-fit: cover;padding-left : 0px;border: 3px solid #ffffff;border-radius: 10px;border-color: #ffffff;">
                              <img *ngIf="clickkbzpay" src="assets/image/excel_pm.png" (click) = "getReportOption('excel')"
                               style="opacity: 1; height : 60px ; width: 60px; object-fit: cover;border: 3px sol;border-radius: 10px;padding-left : 0px;">
                               &nbsp;&nbsp;
              
                              <img *ngIf="!clickwavepay" src="assets/image/pdf3.png" (click) = "getReportOption('pdf')"
                                style="opacity: 0.3; margin-left : 2px; height : 60px ;width: 60px;object-fit: cover;border: 3px solid #ffffff;border-radius: 10px;border-color: #ffffff;">
                              <img *ngIf="clickwavepay" src="assets/image/pdf3.png" (click) = "getReportOption('pdf')"
                               style="opacity: 1; height : 60px ; margin-left : 2px; width: 60px;object-fit: cover;border: 3px sol;border-radius: 10px">
              
                               &nbsp;&nbsp;
              
                              <img *ngIf="!csvrep" src="assets/image/csv3.png" (click) = "getReportOption('csv')"
                                style="opacity: 0.3; padding-left :0px; height : 60px ;width: 60px;object-fit: cover;border: 3px solid #ffffff;border-radius: 10px;border-color: #ffffff;">
                              <img *ngIf="csvrep" src="assets/image/csv3.png" (click) = "getReportOption('csv')"
                               style="opacity: 1; height : 60px ; padding-left : 0px; width: 60px;object-fit: cover;border: 3px sol;border-radius: 10px">
                              </div> 
                          </div>
                          <div class="row">
                            <div class="form-group col-md-2 col-sm-4 col-xs-4">
                            </div>
                          <div class="form-group col-md-2 col-sm-4 col-xs-4" *ngIf="hardcode_add_bool === true">
                              <label></label>
                              <button type="button" class="btn btn-block btn-primary btn-sm" style="margin-top: 4%;" (click)="topupReport()">Generate Report</button>
                          </div>
                          </div>
                      </form>
                      </div>
                      </div>
                    </div>

                  </div>
                </div> 

                <div class="tab-pane" id="withdrawalTab" [class.active]="this.tab == 'withdrawalTab'">
                  <div class="row">
                    <div class="col-md-12">
                      <div class="card">
                        <div class="card-header">
                            <h3 class="card-title" style="font-weight: bold;"> Topup / Withdrawal Report </h3>
                        </div>
                       
                        <div class="card-body">
                      <form>
                        <div class="row">
                          <div class="form-group col-md-2">
                              <label>From Date </label><span class ="req">*</span>
                          </div>
                          <div class="form-group col-md-2">
                            <input type = "date" style="width:250px;" [(ngModel)]="withfromdate" name = "withfromdate" class="form-control form-control-sm" id = "withfromdate">
                          </div>
                      </div>
          
                      <div class="row">
                          <div class="form-group col-md-2">
                              <label>To Date</label><span class ="req">*</span>
                          </div>
                          <div class="form-group col-md-2">
                            <input type = "date" style="width:250px;"  name = "withtodate" [(ngModel)]="withtodate" [ngModelOptions]="{standalone: true}" min="{{fromdate | date:'yyyy-MM-dd'}}" class="form-control form-control-sm" id = "withtodate">
                          </div>
                      </div>


                      <div class="row">
                        <div class="form-group col-md-2">
                            <label>Phone</label><span class ="req">*</span>
                        </div>
                        <div class="form-group col-md-2">
                          <input type = "text" style="width:250px;" name = "withphoneno" [(ngModel)]="withphoneno" [ngModelOptions]="{standalone: true}"  id = "withphoneno">
                        </div>
                    </div>

                    <div class="row">
                      <div class="form-group col-md-2">
                          <label>Type</label><span class ="req">*</span>
                      </div>
                      <div class="form-group col-md-2">
                        <select id = "status" (change)="flagType()" style="width:250px;"  class="form-control form-control-sm" [(ngModel)]="withtype" [ngModelOptions]="{standalone: true}">
                            <option value = "TOPUP" selected>TOPUP</option>
                            <option value = "WITHDRAW">WITHDRAWAL</option>
                        </select>
                      </div>
                   </div>

                   <div class="row">
                    <div class="form-group col-md-2">
                        <label>Bank Type</label><span class ="req">*</span>
                    </div>
                    <div class="form-group col-md-2">
                    <select (change)="flagBank()" style="width:250px;" class="form-control form-control-sm" [(ngModel)]="bankId" [ngModelOptions]="{standalone: true}">
                       <option value="">All</option>
                      <option *ngFor="let objBank of paymentList" value={{objBank.id}}>{{objBank.name}}
                    </select>
                    </div>
                </div>

                <div class="row">
                  <div class="form-group col-md-2">
                      <label>Select Account Holder (Admin) </label>
                  </div>
                  <div class="form-group col-md-3">
                    <ngx-bootstrap-multiselect [options]="myOptions" [(ngModel)]="optionsModel" (ngModelChange)="onChange()"
                    [ngModelOptions]="{standalone: true}" [texts]="myTexts" [settings]="mySettings" class = "form-control-sm"  style="margin-left : -7px"
                    >
                  </ngx-bootstrap-multiselect>
                </div>
              </div>

                    <div class="row">
                      <div class="form-group col-md-2">
                          <label>Status</label><span class ="req">*</span>
                      </div>
                      <div class="form-group col-md-2">
                        <select id = "status" style="width:250px;"  class="form-control form-control-sm" [(ngModel)]="withstatus" [ngModelOptions]="{standalone: true}">
                            <option value = "APPROVED" selected>APPROVED</option>
                            <option value = "PENDING">PENDING</option>
                        </select>
                      </div>
                  </div>

                    <div class="row">
                            <div class = "col-lg-2">
                              <label> Choose Option</label>
                             </div>
                             <div class="form-group col-md-5" style="cursor: pointer;">
                              <img *ngIf="!clickkbzpay" src="assets/image/excel_pm.png" (click) = "getReportOption('excel')"
                                style="opacity: 0.3; height : 60px ;width: 60px;object-fit: cover;padding-left : 0px;border: 3px solid #ffffff;border-radius: 10px;border-color: #ffffff;">
                              <img *ngIf="clickkbzpay" src="assets/image/excel_pm.png" (click) = "getReportOption('excel')"
                               style="opacity: 1; height : 60px ; width: 60px; object-fit: cover;border: 3px sol;border-radius: 10px;padding-left : 0px;">
                               &nbsp;&nbsp;
              
                              <img *ngIf="!clickwavepay" src="assets/image/pdf3.png" (click) = "getReportOption('pdf')"
                                style="opacity: 0.3; margin-left : 2px; height : 60px ;width: 60px;object-fit: cover;border: 3px solid #ffffff;border-radius: 10px;border-color: #ffffff;">
                              <img *ngIf="clickwavepay" src="assets/image/pdf3.png" (click) = "getReportOption('pdf')"
                               style="opacity: 1; height : 60px ; margin-left : 2px; width: 60px;object-fit: cover;border: 3px sol;border-radius: 10px">
              
                               &nbsp;&nbsp;
              
                              <img *ngIf="!csvrep" src="assets/image/csv3.png" (click) = "getReportOption('csv')"
                                style="opacity: 0.3; padding-left :0px; height : 60px ;width: 60px;object-fit: cover;border: 3px solid #ffffff;border-radius: 10px;border-color: #ffffff;">
                              <img *ngIf="csvrep" src="assets/image/csv3.png" (click) = "getReportOption('csv')"
                               style="opacity: 1; height : 60px ; padding-left : 0px; width: 60px;object-fit: cover;border: 3px sol;border-radius: 10px">
                              </div> 
                          </div>
                          <div class="row">
                            <div class="form-group col-md-2 col-sm-4 col-xs-4">
                            </div>
                          <div class="form-group col-md-2 col-sm-4 col-xs-4" *ngIf="hardcode_add_bool === true">
                              <label></label>
                              <button type="button" class="btn btn-block btn-primary btn-sm" style="margin-top: 4%;" (click)="withTopupReport()">Generate Report</button>
                          </div>
                          </div>

                          <!-- <div class="row">
                            <div class="form-group col-md-2 col-sm-4 col-xs-4">
                            </div>
                          <div class="form-group col-md-2 col-sm-4 col-xs-4">                              
                          </div>
                          </div>

                          <div class="row">
                            <div class="form-group col-md-2 col-sm-4 col-xs-4">
                            </div>
                          <div class="form-group col-md-2 col-sm-4 col-xs-4">                              
                          </div>
                          </div>

                          <div class="row">
                            <div class="form-group col-md-2 col-sm-4 col-xs-4">
                            </div>
                          <div class="form-group col-md-2 col-sm-4 col-xs-4">                              
                          </div>
                          </div>


                          <div class="row">
                            <div class="form-group col-md-2 col-sm-4 col-xs-4">
                            </div>
                          <div class="form-group col-md-2 col-sm-4 col-xs-4">                              
                          </div>
                          </div>
                          <div class="row">
                            <div class="form-group col-md-2 col-sm-4 col-xs-4">
                            </div>
                          <div class="form-group col-md-2 col-sm-4 col-xs-4">                              
                          </div>
                          </div>
                          <div class="row">
                            <div class="form-group col-md-2 col-sm-4 col-xs-4">
                            </div>
                          <div class="form-group col-md-2 col-sm-4 col-xs-4">                              
                          </div>
                          </div>
                          <div class="row">
                            <div class="form-group col-md-2 col-sm-4 col-xs-4">
                            </div>
                          <div class="form-group col-md-2 col-sm-4 col-xs-4">                              
                          </div>
                          </div>
                          <div class="row">
                            <div class="form-group col-md-2 col-sm-4 col-xs-4">
                            </div>
                          <div class="form-group col-md-2 col-sm-4 col-xs-4">                              
                          </div>
                          </div>
                          <div class="row">
                            <div class="form-group col-md-2 col-sm-4 col-xs-4">
                            </div>
                          <div class="form-group col-md-2 col-sm-4 col-xs-4">                              
                          </div>
                          </div>
                          <div class="row">
                            <div class="form-group col-md-2 col-sm-4 col-xs-4">
                            </div>
                          <div class="form-group col-md-2 col-sm-4 col-xs-4">                              
                          </div>
                          </div>
                          <div class="row">
                            <div class="form-group col-md-2 col-sm-4 col-xs-4">
                            </div>
                          <div class="form-group col-md-2 col-sm-4 col-xs-4">                              
                          </div>
                          </div>
                          <div class="row">
                            <div class="form-group col-md-2 col-sm-4 col-xs-4">
                            </div>
                          <div class="form-group col-md-2 col-sm-4 col-xs-4">                              
                          </div>
                          </div>-->

                          <div class="row">
                            <div class="form-group col-md-2">
                           
                            </div>
                            <div class="form-group col-md-2">
                             
                            </div>
                        </div>

                        <div class="row">
                          <div class="form-group col-md-2">
                         
                          </div>
                          <div class="form-group col-md-2">
                           
                          </div>
                      </div>

                      <div class="row">
                        <div class="form-group col-md-2">
                       
                        </div>
                        <div class="form-group col-md-2">
                         
                        </div>
                    </div>
                    <div class="row">
                      <div class="form-group col-md-2">
                     
                      </div>
                      <div class="form-group col-md-2">
                       
                      </div>
                  </div>
                  <div class="row">
                    <div class="form-group col-md-2">
                   
                    </div>
                    <div class="form-group col-md-2">
                     
                    </div>
                </div>
                <div class="row">
                  <div class="form-group col-md-2">
                 
                  </div>
                  <div class="form-group col-md-2">
                   
                  </div>
              </div>
              <div class="row">
                <div class="form-group col-md-2">
               
                </div>
                <div class="form-group col-md-2">
                 
                </div>
            </div>
                          




                      </form>
                      </div><!--card body-->
                      </div><!--card-->
                    </div>

                  </div>
                </div> <!-- ./ with -->

                <div class="tab-pane" id="gameTranTab" [class.active]="this.tab == 'gameTranTab'">
                  <div class="row">
                    <div class="col-md-12">
                      <div class="card">
                        <div class="card-header">
                            <h3 class="card-title" style="font-weight: bold;"> Game Transaction Report </h3>
                        </div>
                        
                        <div class="card-body">
                      <form>
                        <!-- <div class="row">
                          <div class="form-group col-md-2">
                              <label>From Date </label><span class ="req">*</span>
                          </div>
                          <div class="form-group col-md-2">
                            <input type = "date" style="width:250px;" [(ngModel)]="gametranfromdate" name = "gametranfromdate" class="form-control form-control-sm" id = "gametranfromdate">
                          </div>
                      </div>
          
                      <div class="row">
                          <div class="form-group col-md-2">
                              <label>To Date</label><span class ="req">*</span>
                          </div>
                          <div class="form-group col-md-2">
                            <input type = "date" style="width:250px;"  name = "gametrantodate" [(ngModel)]="gametrantodate" [ngModelOptions]="{standalone: true}" min="{{fromdate | date:'yyyy-MM-dd'}}" class="form-control form-control-sm" id = "gametrantodate">
                          </div>
                      </div> -->


                      <!-- amk -->

                      <div class="row">
                        <div class="form-group col-md-2">
                            <label>From Date </label><span class ="req">*</span>
                        </div>
                        <div class="form-group col-md-2">
                          <input type = "date" style="width:250px;" [(ngModel)]="gametranfromdate" name = "gametranfromdate" class="form-control form-control-sm" id = "gametranfromdate">
                        </div>
                    </div>
        
                    <div class="row">
                        <div class="form-group col-md-2">
                            <label>To Date</label><span class ="req">*</span>
                        </div>
                        <div class="form-group col-md-2">
                          <input type = "date" style="width:250px;"  name = "gametrantodate" [(ngModel)]="gametrantodate" [ngModelOptions]="{standalone: true}"  class="form-control form-control-sm" id = "gametrantodate">
                        </div>
                     </div>



                    



                      <div class="row">
                        <div class="form-group col-md-2">
                            <label>Phone</label><span class ="req">*</span>
                        </div>
                        <div class="form-group col-md-2">
                          <input type = "text" style="width:250px;" name = "gametranphoneno" [(ngModel)]="gametranphoneno" [ngModelOptions]="{standalone: true}"  id = "gametranphoneno">
                        </div>
                       </div>

                  <div class="row">
                    <div class="form-group col-md-2">
                        <label>Game Provider</label><span class ="req">*</span>
                    </div>
                    <div class="form-group col-md-2">
                      <select (change) = "flagProvider()" id = "providerId" style="width:250px;"  class="form-control form-control-sm"
                            [(ngModel)]="providerId" [ngModelOptions]="{standalone: true}">
                              <option *ngFor="let provider of gameproviderList" value = "{{provider.provider_id}}"  
                              [selected]="provider.id==3">
                                {{provider.name}}
                              </option>
                              <option value = "">All</option>
                          </select>
                    </div>
                </div>

                      <div class="row">
                        <div class="form-group col-md-2">
                            <label>Type</label><span class ="req">*</span>
                        </div>
                        <div class="form-group col-md-2">
                          <select id = "status" style="width:250px;"  class="form-control form-control-sm" [(ngModel)]="gameTranType" [ngModelOptions]="{standalone: true}">
                              <option value = "DEPOSIT" selected>DEPOSIT</option>
                              <option value = "WITHDRAWAL">WITHDRAWAL</option>
                              <option value = "">All</option>
                          </select>
                        </div>
                     </div>

                    <div class="row">
                            <div class = "col-lg-2">
                              <label> Choose Option</label>
                             </div>
                             <div class="form-group col-md-5" style="cursor: pointer;">
                              <img *ngIf="!clickkbzpay" src="assets/image/excel_pm.png" (click) = "getReportOption('excel')"
                                style="opacity: 0.3; height : 60px ;width: 60px;object-fit: cover;padding-left : 0px;border: 3px solid #ffffff;border-radius: 10px;border-color: #ffffff;">
                              <img *ngIf="clickkbzpay" src="assets/image/excel_pm.png" (click) = "getReportOption('excel')"
                               style="opacity: 1; height : 60px ; width: 60px; object-fit: cover;border: 3px sol;border-radius: 10px;padding-left : 0px;">
                               &nbsp;&nbsp;
              
                              <img *ngIf="!clickwavepay" src="assets/image/pdf3.png" (click) = "getReportOption('pdf')"
                                style="opacity: 0.3; margin-left : 2px; height : 60px ;width: 60px;object-fit: cover;border: 3px solid #ffffff;border-radius: 10px;border-color: #ffffff;">
                              <img *ngIf="clickwavepay" src="assets/image/pdf3.png" (click) = "getReportOption('pdf')"
                               style="opacity: 1; height : 60px ; margin-left : 2px; width: 60px;object-fit: cover;border: 3px sol;border-radius: 10px">
              
                               &nbsp;&nbsp;
              
                              <img *ngIf="!csvrep" src="assets/image/csv3.png" (click) = "getReportOption('csv')"
                                style="opacity: 0.3; padding-left :0px; height : 60px ;width: 60px;object-fit: cover;border: 3px solid #ffffff;border-radius: 10px;border-color: #ffffff;">
                              <img *ngIf="csvrep" src="assets/image/csv3.png" (click) = "getReportOption('csv')"
                               style="opacity: 1; height : 60px ; padding-left : 0px; width: 60px;object-fit: cover;border: 3px sol;border-radius: 10px">
                              </div> 
                          </div>
                          <div class="row">
                            <div class="form-group col-md-2 col-sm-4 col-xs-4">
                            </div>
                          <div class="form-group col-md-2 col-sm-4 col-xs-4" *ngIf="hardcode_add_bool === true">
                              <label></label>
                              <button type="button" class="btn btn-block btn-primary btn-sm" style="margin-top: 4%;" (click)="gametranReport()">Generate Report</button>
                          </div>
                          </div>
                      </form>
                      </div><!--card body-->
                      </div><!--card-->
                    </div>

                  </div>
                </div> <!-- ./ game transaction -->

                <div class="tab-pane" id="allTranTab" [class.active]="this.tab == 'allTranTab'">
                  <div class="row">
                    <div class="col-md-12">
                      <div class="card">
                        <div class="card-header">
                            <h3 class="card-title" style="font-weight: bold;"> All Transaction Report </h3>
                        </div>
                        <!-- /.card-header -->
                        <div class="card-body">
                      <form>
                        <div class="row">
                          <div class="form-group col-md-2">
                              <label>From Date </label><span class ="req">*</span>
                          </div>
                          <div class="form-group col-md-2">
                            <input type = "date" style="width:250px;" [(ngModel)]="alltranfromdate" name = "alltranfromdate" class="form-control form-control-sm" id = "alltranfromdate">
                          </div>
                      </div>
          
                      <div class="row">
                          <div class="form-group col-md-2">
                              <label>To Date</label><span class ="req">*</span>
                          </div>
                          <div class="form-group col-md-2">
                            <input type = "date" style="width:250px;"  name = "alltrantodate" [(ngModel)]="alltrantodate" [ngModelOptions]="{standalone: true}" min="{{fromdate | date:'yyyy-MM-dd'}}" class="form-control form-control-sm" id = "alltrantodate">
                          </div>
                      </div>

                      <div class="row">
                        <div class="form-group col-md-2">
                            <label>Phone</label><span class ="req">*</span>
                        </div>
                        <div class="form-group col-md-2">
                          <input type = "text" name = "alltranphoneno" class="form-control form-control-sm" [(ngModel)]="alltranphoneno" [ngModelOptions]="{standalone: true}"  id = "alltranphoneno">
                        </div>
                        <!--button-->
                        <div class="form-group col-md-2">
                          <button type="button" class="btn-success btn-sm"  (click)="checkRegisterDate()">Get Register Date</button>
                        </div>
                     </div>
                     <!--data -->
                     <div class="row" *ngIf="regDate != null && regDate != undefined">
                      <div class="form-group col-md-2">
                        <label>Register Date</label>
                      </div>
                      <div class="form-group col-md-2">
                        <label class="regDate">{{regDate}}</label>
                      </div>
                     </div>

                    <div class="row">
                            <div class = "col-lg-2">
                              <label> Choose Option</label>
                             </div>
                             <div class="form-group col-md-5" style="cursor: pointer;">
                              <img *ngIf="!clickkbzpay" src="assets/image/excel_pm.png" (click) = "getReportOption('excel')"
                                style="opacity: 0.3; height : 60px ;width: 60px;object-fit: cover;padding-left : 0px;border: 3px solid #ffffff;border-radius: 10px;border-color: #ffffff;">
                              <img *ngIf="clickkbzpay" src="assets/image/excel_pm.png" (click) = "getReportOption('excel')"
                               style="opacity: 1; height : 60px ; width: 60px; object-fit: cover;border: 3px sol;border-radius: 10px;padding-left : 0px;">
                               &nbsp;&nbsp;
              
                              <img *ngIf="!clickwavepay" src="assets/image/pdf3.png" (click) = "getReportOption('pdf')"
                                style="opacity: 0.3; margin-left : 2px; height : 60px ;width: 60px;object-fit: cover;border: 3px solid #ffffff;border-radius: 10px;border-color: #ffffff;">
                              <img *ngIf="clickwavepay" src="assets/image/pdf3.png" (click) = "getReportOption('pdf')"
                               style="opacity: 1; height : 60px ; margin-left : 2px; width: 60px;object-fit: cover;border: 3px sol;border-radius: 10px">
              
                               &nbsp;&nbsp;
              
                              <img *ngIf="!csvrep" src="assets/image/csv3.png" (click) = "getReportOption('csv')"
                                style="opacity: 0.3; padding-left :0px; height : 60px ;width: 60px;object-fit: cover;border: 3px solid #ffffff;border-radius: 10px;border-color: #ffffff;">
                              <img *ngIf="csvrep" src="assets/image/csv3.png" (click) = "getReportOption('csv')"
                               style="opacity: 1; height : 60px ; padding-left : 0px; width: 60px;object-fit: cover;border: 3px sol;border-radius: 10px">
                              </div> 
                          </div>
                          <div class="row">
                            <div class="form-group col-md-2 col-sm-4 col-xs-4">
                            </div>
                          <div class="form-group col-md-2 col-sm-4 col-xs-4" *ngIf="hardcode_add_bool === true">
                              <label></label>
                              <button type="button" class="btn btn-block btn-primary btn-sm" style="margin-top: 4%;" (click)="alltranReport()">Generate Report</button>
                          </div>
                          </div>
                      </form>
                      </div><!--card body-->
                      </div><!--card-->
                    </div>

                  </div>
                </div> <!-- ./ game transaction -->

              <!-- <div>amk new</div> -->
              <div class="tab-pane" id="detailnewTab" [class.active]="this.tab == 'detailnewTab'">
                <div class="row">
                  <div class="col-md-12">
                    <div class="card">
                      <div class="card-header">
                          <h3 class="card-title" style="font-weight: bold;"> 2D Detail New Report </h3>
                      </div>
                      <!-- /.card-header -->
                      <div class="card-body">
                    <form>
                      <div class="row">
                        <div class="form-group col-md-2">
                            <label>From Date </label><span class ="req">*</span>
                        </div>
                        <div class="form-group col-md-2">
                          <input type = "date" style="width:250px;" [(ngModel)]="fromdate" name = "fromdate" class="form-control form-control-sm" id = "fdate">
                        </div>
                    </div>
        
                    <div class="row">
                        <div class="form-group col-md-2">
                            <label>To Date</label><span class ="req">*</span>
                        </div>
                        <div class="form-group col-md-2">
                          <input type = "date" style="width:250px;"  name = "alltrantodate" [(ngModel)]="alltrantodate" [ngModelOptions]="{standalone: true}" min="{{fromdate | date:'yyyy-MM-dd'}}" class="form-control form-control-sm" id = "alltrantodate">
                        </div>
                    </div>

                    <div class="row">
                      <div class="form-group col-md-2">
                          <label>To Date</label><span class ="req">*</span>
                      </div>
                      <div class="form-group col-md-2">
                        <input type = "date" style="width:250px;"  name = "todate" [(ngModel)]="todate" [ngModelOptions]="{standalone: true}" min="{{fromdate | date:'yyyy-MM-dd'}}" class="form-control form-control-sm" id = "tdate">
                      </div>
                  </div>
      
                  <div class="row">
                    <div class="form-group col-md-2">
                        <label>Phone</label><span class ="req">*</span>
                    </div>
                    <div class="form-group col-md-2">
                      <input type = "text" style="width:250px;" name = "phoneno" [(ngModel)]="phoneno" [ngModelOptions]="{standalone: true}"  id = "phoneno">
                    </div>
                </div>
      
                  <div class="row">
                      <div class="form-group col-md-2">
                          <label>Section</label><span class ="req">*</span>
                      </div>
                      <div class="form-group col-md-2">
                        <select id = "section" style="width:250px;"  class="form-control form-control-sm" [(ngModel)]="section" [ngModelOptions]="{standalone: true}">
                          <option *ngFor="let objSection of sectionList" value="{{objSection.sectionName}}">{{objSection.sectionName}}</option>
                           <option value = "">All</option>
                        </select> 
                      </div>
                  </div>
                  <div class="row">
                    <div class="form-group col-md-2">
                        <label>Choose Type</label><span class ="req">*</span>
                    </div>
                    <div class="form-group col-md-2">
                      <select id = "rType" style="width:250px;"  class="form-control form-control-sm" [(ngModel)]="rType" [ngModelOptions]="{standalone: true}">
                         <option value = "Bet">Bet</option>
                         <option value = "Winning">Winning</option>
                      </select> 
                    </div>
                </div>

                <div class="row" *ngIf = "rType == 'Winning'">
                  <div class="form-group col-md-2">
                      <label>Status</label><span class ="req">*</span>
                  </div>
                  <div class="form-group col-md-2">
                    <select id = "status" style="width:250px;"  class="form-control form-control-sm" [(ngModel)]="twodstatus" [ngModelOptions]="{standalone: true}">
                        <option value = "CONFIRMED" selected>CONFIRMED</option>
                        <option value = "PENDING">PENDING</option>
                       <option value = "">All</option> 
                    </select>
                  </div>
              </div>

                  <div class="row">
                          <div class = "col-lg-2">
                            <label> Choose Option</label>
                           </div>
                           <div class="form-group col-md-5" style="cursor: pointer;">
                            <img *ngIf="!clickkbzpay" src="assets/image/excel_pm.png" (click) = "getReportOption('excel')"
                              style="opacity: 0.3; height : 60px ;width: 60px;object-fit: cover;padding-left : 0px;border: 3px solid #ffffff;border-radius: 10px;border-color: #ffffff;">
                            <img *ngIf="clickkbzpay" src="assets/image/excel_pm.png" (click) = "getReportOption('excel')"
                             style="opacity: 1; height : 60px ; width: 60px; object-fit: cover;border: 3px sol;border-radius: 10px;padding-left : 0px;">
                             &nbsp;&nbsp;
            
                            <img *ngIf="!clickwavepay" src="assets/image/pdf3.png" (click) = "getReportOption('pdf')"
                              style="opacity: 0.3; margin-left : 2px; height : 60px ;width: 60px;object-fit: cover;border: 3px solid #ffffff;border-radius: 10px;border-color: #ffffff;">
                            <img *ngIf="clickwavepay" src="assets/image/pdf3.png" (click) = "getReportOption('pdf')"
                             style="opacity: 1; height : 60px ; margin-left : 2px; width: 60px;object-fit: cover;border: 3px sol;border-radius: 10px">
            
                             &nbsp;&nbsp;
            
                            <img *ngIf="!csvrep" src="assets/image/csv3.png" (click) = "getReportOption('csv')"
                              style="opacity: 0.3; padding-left :0px; height : 60px ;width: 60px;object-fit: cover;border: 3px solid #ffffff;border-radius: 10px;border-color: #ffffff;">
                            <img *ngIf="csvrep" src="assets/image/csv3.png" (click) = "getReportOption('csv')"
                             style="opacity: 1; height : 60px ; padding-left : 0px; width: 60px;object-fit: cover;border: 3px sol;border-radius: 10px">
                            </div> 
                        </div>
                        <div class="row">
                          <div class="form-group col-md-2 col-sm-4 col-xs-4">
                          </div>
                        <div class="form-group col-md-2 col-sm-4 col-xs-4" *ngIf="hardcode_add_bool === true">
                            <label></label>
                            <button type="button" class="btn btn-block btn-primary btn-sm" style="margin-top: 4%;" (click)="TwoDetailNewReport()">Generate Report</button>
                        </div>
                        </div>
                    </form>
                    </div><!--card body-->
                    </div><!--card-->
                  </div>

                </div>
              </div> <!-- ./ game transaction -->



              </div>
            </div>
          </div>
        </form>
            <!-- /.row -->
          </div><!--/. container-fluid -->
        </section>
        <!-- /.content -->
      </div>

      <aside class="control-sidebar control-sidebar-dark">
        <!-- Control sidebar content goes here -->
      </aside>
    
 <app-footer></app-footer>
     <ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#fff" type="square-jelly-box">
      <p style="font-size: 16px; color: white">Loading...</p>
    </ngx-spinner> 
</div>


<div id="deleteData" class="modal fade">
  <div class="modal-dialog">
      <div class="modal-content">

          <div class="modal-body">
              <form>

                  <div class="row" style="margin-top: 5%;margin-bottom: 3%;margin-left: 9%;">
                      <div class="form-group col-md-12">
                          <h5> This Account is used by another device. 
                              Please Sign Out. Login Again. </h5>
                      </div>
                  </div>

                  <div class="row" style="margin-left: 40%;margin-bottom: 3%;">
                      <div class="col-md-5 col-sm-6" id="deleteBtn">
                          <button type="button" class="btn btn-block btn-success" (click)="OkLogout()"> Ok </button>
                      </div>
                  </div>
              </form>
          </div>

      </div>
  </div>
</div>
