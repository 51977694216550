import { Component, OnInit } from '@angular/core';
import { DtoService } from '../../service/dto.service';
import { LocalStorageService } from 'ngx-webstorage';
import { HttpClient, HttpHeaders, HttpParams,HttpErrorResponse } from '@angular/common/http';
import { FunctService } from '../../service/funct.service';
import { catchError } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from "ngx-spinner";
import { Subject } from 'rxjs';
import { param } from 'jquery';
import { Router, NavigationEnd,ActivatedRoute } from '@angular/router';
import Responsive from 'datatables.net-responsive';
declare var $: any;

@Component({
  selector: 'app-topup-withdraw-logs',
  templateUrl: './topup-withdraw-logs.component.html',
  styleUrls: ['./topup-withdraw-logs.component.css']
})
export class TopupWithdrawLogsComponent implements OnInit {
  dtTrigger: Subject<any> = new Subject();
  dtOptions: DataTables.Settings = {};
  name: any;
  status  :any;
  amt : any;
  ephone : any;
  userList : any;
  idIndex : any;
  phone_no: any;
  token: any;
  phoneNo_wallet: any;
  itemsPerPage =  10;
  totalItems : any; 
  page = 1;
  editUserDTO : any;
  userId: any;
  userDTO:any;
  originalAdminDTO:any;
  constructor(private router: Router,private spinner: NgxSpinnerService,private toastr: ToastrService,private http: HttpClient,private storage: LocalStorageService,private dto: DtoService, private funct: FunctService ,private route: ActivatedRoute,)
   { 
    this.storage.store('isNotiSong', "");
      this.storage.store('phoneNo_wallet', ''); 
      if (!this.storage.retrieve('phoneNo_wallet')) {
        this.phoneNo_wallet = '';
      }
      else {
        this.phoneNo_wallet = this.storage.retrieve('phoneNo_wallet');
      }    
      //this.getAllData();//amk
   }

  ngOnInit(): void {
    //amk
    this.router.routeReuseStrategy.shouldReuseRoute = function () {
      return false;
    };
    this.router.events.subscribe((evt) => {
      if (evt instanceof NavigationEnd) {        this.router.navigated = false;    
        window.scrollTo(0, 0);
      }
    });

    const adminDataString = localStorage.getItem('userData');
    this.originalAdminDTO = JSON.parse(adminDataString);
    
    this.userId = this.route.snapshot.paramMap.get("id");
    this.userDTO = {
      id: 0,
      phoneNo: '',
      name: '',
      adminId: 0,
      agentId: 0,
      balance: 0,
      referralCode: '',
      image: '',
      total_topup: '',
      total_withdraw: '',
      registerDate: '',
      registerBy: '',
      updatedDate: '',
      updatedBy: '',
      status: 'ACTIVE'
    };
    
    //amk
    this.adjust();
    //this.getAllData();//amk edit now
    this.editUserDTO = {
      id: 0
    };

    this.dtOptions = {
      // responsive:true,
      responsive: {
        details: {
            renderer: Responsive.renderer.listHiddenNodes()
        }
    },
      order:[],
      paging: false,
      info : true,
      dom: "Bfrltip",
    }

    if(!this.storage.retrieve('loadFlag')){
      this.storage.store('loadFlag', 'noLoad');
      setTimeout(function(){
        location.reload();
      }, 5);
    }
    else{
      this.storage.clear('loadFlag');
    }
  }
  getAllData() {
    this.userList = [];
    var id = 'tblUser' + this.idIndex;
    var table = $('#' + id).DataTable();
    table.destroy();
    this.idIndex = this.idIndex +1;
    this.spinner.show();
    this.dto.token = this.storage.retrieve('token');
    let headers = new HttpHeaders();
    headers = headers.set('Authorization',  this.dto.token);
    let params = new HttpParams(); 
    if(this.phoneNo_wallet != undefined)
    {
     params = params.set('phoneNo',this.phoneNo_wallet).set("pageNumber","1").set("rowsOfPage","10");
    }
    if(this.name != undefined)
    {
      params =  params.set('name',this.name).set("pageNumber","1").set("rowsOfPage","10");
    }
    if(this.phoneNo_wallet == undefined && this.name == undefined)
    {
      params = params.set("pageNumber","1").set("rowsOfPage","10");
    }
    if(this.phoneNo_wallet != undefined && this.name != undefined)
    {
      params = params.set('phoneNo',this.phoneNo_wallet).set('name',this.name).set("pageNumber","1").set("rowsOfPage","10");
    }
    this.http.get(this.funct.ipaddress + 'topupWithdrawalAdjust/getManageTopupWithdrawalLogs', { params: params, headers: headers } )
    .pipe(
      catchError(this.handleError.bind(this))
     ).
    subscribe((data: any) => {
      this.dto.Response = {};
      this.dto.Response = data.results;
      this.userList = this.dto.Response;
      this.totalItems = data.totalRows;
      this.dtTrigger.next();
      this.spinner.hide();
      //this.phoneNo_wallet = '';
    })
  }
  gty(page: any){
    this.userList = [];
    var id = 'tblUser' + this.idIndex;
    var table = $('#' + id).DataTable();
    table.destroy();
    this.idIndex = this.idIndex +1;
    this.spinner.show();
    let headers = new HttpHeaders();
    headers = headers.set('Authorization',  this.dto.token);
    let params = new HttpParams();
    if(this.phoneNo_wallet != undefined)
    {
     params = params.set('phoneNo',this.phoneNo_wallet).set("pageNumber",page.toString()).set("rowsOfPage",this.itemsPerPage.toString());
    }
    if(this.name != undefined)
    {
      params =  params.set('name',this.name).set("pageNumber",page.toString()).set("rowsOfPage",this.itemsPerPage.toString());
    }
    if(this.phoneNo_wallet == undefined && this.name == undefined)
    {
      params = params.set("pageNumber",page.toString()).set("rowsOfPage",this.itemsPerPage.toString());
    }
    if(this.phoneNo_wallet != undefined && this.name != undefined)
    {
      params = params.set('phoneNo',this.phoneNo_wallet).set('name',this.name).set("pageNumber",page.toString()).set("rowsOfPage",this.itemsPerPage.toString());
    }
    this.http.get(this.funct.ipaddress + 'topupWithdrawalAdjust/getManageTopupWithdrawalLogs', { params: params, headers: headers } )
    .pipe(
      catchError(this.handleError.bind(this))
     )
     .subscribe((data: any) => {
      this.dto.Response = {};
      this.dto.Response = data.results;
      this.userList = this.dto.Response;
      this.totalItems = data.totalRows;
      this.dtTrigger.next();
      this.spinner.hide();
    })
  }
  handleError(error: HttpErrorResponse)
  {
    this.spinner.hide();
    if(error.status == 403)
    {
      this.toastr.error("Limited Access", 'Invalid!', {
        timeOut: 3000,
        positionClass: 'toast-top-right'
      });
    
      return;
    }
    if(error.status == 406)
    {
      this.toastr.error("Not enough balance", 'Invalid!', {
        timeOut: 3000,
        positionClass: 'toast-top-right'
      });
     
      return;
    }
  }
  
  onChangePhoneNo() {     
    this.storage.store('phoneNo_wallet', this.phoneNo_wallet.toString());  
  }
  onInput(event: InputEvent) {
    const myanmarCharactersRegex = new RegExp('^[\u1000-\u109F\uAA60-\uAA7F\uA9E0-\uA9FF]+$');
    const inputValue = (event.target as HTMLInputElement).value; 
    if(event.data!=null)
    {
      if (myanmarCharactersRegex.test(inputValue)) 
      {        
        this.spinner.hide();
        this.toastr.error( "Myanmar typing detected!", '', {
        timeOut: 5000,
        positionClass: 'toast-top-right',
        });  
      }
    }   
  }
  OkLogout()
  {
    window.location.href ="./ad-login";
  } 

  adjust() {
    this.dto.token = this.storage.retrieve('token');
     let headers = new HttpHeaders();
     headers = headers.set('Authorization',  this.dto.token);
     let params = new HttpParams();
     params = params.set('user_id', this.userId).set('name',this.name).set("pageNumber","1").set("rowsOfPage","100");
     this.http.get(this.funct.ipaddress + 'topupWithdrawalAdjust/getAdjustLogs', {params: params,headers: headers })
     .pipe(
       catchError(this.handleError.bind(this))
      )
     .subscribe(
       result => {
         this.dto.Response = {};
         this.dto.Response = result.results;
         this.userList = this.dto.Response;
         this.totalItems = result.totalRows;
         this.dtTrigger.next();
         this.spinner.hide();
         this.phoneNo_wallet = '';
       });    
}
}
