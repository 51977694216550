<div class="wrapper">
    <!-- Navbar -->
    <app-header-sidebar></app-header-sidebar>

    <!-- here here here -->
    <!-- Content Wrapper. Contains page content -->
    <div class="content-wrapper">
        <!-- Content Header (Page header) -->
        <div class="content-header">
            <div class="container-fluid">
                <!--                 <div class="row mb-2">
                    <div class="col-sm-6">
                        <h1 class="m-0 text-dark">Withdraw Detail</h1>
                    </div>
                    <div class="col-sm-6">
                        <ol class="breadcrumb float-sm-right">
                            <li class="breadcrumb-item"><a [routerLink]="['/withdraw-check-list']">Withdraw List</a></li>
                            <li class="breadcrumb-item active">Withdraw Detail</li>
                        </ol>
                    </div>
                </div> -->
                <!-- /.row -->
            </div><!-- /.container-fluid -->
        </div>
        <!-- /.content-header -->

        <!-- Main content -->
        <section class="content">
            <div class="container-fluid">
                <form>
                    <div class="row">
                        <div class="col-md-12">
                            <div class="card">
                                <div class="card-header">
                                    <h3 class="card-title" style="font-weight: bold;">Agent Withdraw Detail </h3>
                                </div>
                                <!-- /.card-header -->
                                <div class="card-body">
                                    <form>
                                        <div class="row">
                                            <label class="col-md-2"> Agent Name </label>
                                            <div class="form-group col-md-3">
                                                <input type = "text" id="agentname" [(ngModel)]="userFinancialTransactionDTO.name" 
                                                readonly class="form-control form-control-sm" [ngModelOptions]="{standalone: true}">                                                 
                                            </div>

                                            <div class="col-md-1"></div>
                                            <label class="col-md-2"> Account No </label>
                                            <div class="form-group col-md-3">
                                                <input id="accountNo" type="string" class="form-control form-control-sm"
                                                    [(ngModel)]="userFinancialTransactionDTO.phoneNo"
                                                    [ngModelOptions]="{standalone: true}" readonly
                                                   >
                                            </div>

                                        </div>
                                        <div class="row">
                                            <label class="col-md-2"> Referral Code </label>
                                            <div class="form-group col-md-3">
                                                <input id='refcode' type="text" list="users"
                                                class="form-control form-control-sm" [(ngModel)]="userFinancialTransactionDTO.refcode"
                                                [ngModelOptions]="{standalone: true}" readonly>
                                            </div>

                                            <div class="col-md-1"></div>
                                            <label class="col-md-2"> Wallet </label>
                                            <div class="form-group col-md-3">
                                                <input id="wallet" type="string" class="form-control form-control-sm"
                                                    [(ngModel)]="userFinancialTransactionDTO.balance"
                                                    [ngModelOptions]="{standalone: true}" readonly
                                                    (keypress)="numericOnly($event)">
                                            </div>
                                        </div>

                                        <div class = "row">
                                            <label class="col-md-2">Payment</label>
                                            <div class="form-group col-md-3">
                                                <!--  <select id='paymentId'
                                                    class="form-control form-control-sm col-md-12 col-sm-4 col-xs-4"
                                                    [(ngModel)]="paymentId" [ngModelOptions]="{standalone: true}">
                                                    <option *ngFor="let payment of paymentList" value={{payment.id}}>
                                                        {{payment.name}}
                                                </select> -->
                                                <div class="row">
                                                    <div id="paymentId" class="col-md-4" align="left">
                                                        <img *ngIf="!clickkbzpay" src="assets/image/kbzpayb&w.png"
                                                            style="width: 75px;object-fit: cover;border: 2px solid #ffffff;border-radius: 10px;border-color: #ffffff;">
                                                        <img *ngIf="clickkbzpay" src="assets/image/kbzpay.png"
                                                            style="width: 75px;object-fit: cover;border: 2px solid #26a9e1;border-radius: 10px;border-color: #26a9e1;">
                                                    </div>
                                                    <div id="paymentId1" class="col-md-4" align="center">
                                                        <img *ngIf="!clickwavepay" src="assets/image/wavepayb&w.png"
                                                            style="width: 75px;object-fit: cover;border: 2px solid #ffffff;border-radius: 10px;border-color: #ffffff;">
                                                        <img *ngIf="clickwavepay" src="assets/image/wavepay.png"
                                                            style="width: 75px;object-fit: cover;border: 2px solid #26a9e1;border-radius: 10px;border-color: #26a9e1;">
                                                    </div>
                                                    <div id="paymentId2" class="col-md-4" align="end">
                                                        <img *ngIf="!clickwavemoney" src="assets/image/wavemoneyb&w.png"
                                                            style="width: 75px;object-fit: cover;border: 2px solid #ffffff;border-radius: 10px;border-color: #ffffff;">
                                                        <img *ngIf="clickwavemoney" src="assets/image/wavemoney.png"
                                                            style="width: 75px;object-fit: cover;border: 2px solid #26a9e1;border-radius: 10px;border-color: #26a9e1;">
                                                    </div>
                                                </div>
                                            </div>

                                               <div class="col-md-1"></div>
                                            <label class="col-md-2"> Amount </label>
                                            <div class="form-group col-md-3">
                                                <input id="amount" type="string" class="form-control form-control-sm"
                                                    [(ngModel)]="userFinancialTransactionDTO.amount"
                                                    [ngModelOptions]="{standalone: true}" readonly
                                                    (keypress)="numericOnly($event)">
                                            </div>

                                        </div>

                                        <div class="row" id="status">
                                            <label class="col-md-2">Status</label>
                                            <div class="form-group col-md-3">
                                                <select class="form-control form-control-sm col-md-12 col-sm-4 col-xs-4" 
                                                    [(ngModel)]="userFinancialTransactionDTO.status"
                                                    [ngModelOptions]="{standalone: true}" id="approvestatus">
                                                    <option *ngFor="let status of statusList" value={{status}}>
                                                        {{status}}
                                                </select>
                                            </div>
                                        </div>
                                        <div class="row" style="margin-top: 1%;">
                                            <div class="col-md-2 col-sm-6">
                                                <button type="button" id = "approvesavebutton" class="btn btn-block btn-success"
                                                    (click)="goSave()">Save</button>&nbsp;</div>
                                            <div class="col-md-2 col-sm-6">
                                                <button type="button" class="btn btn-block btn-danger"
                                                [routerLink] = "['/agent-withdraw']">Cancel</button></div>
                                        </div>
                                    </form>
                                </div>
                                <!-- /.card-body -->
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-md-12">
                            <div class="card">
                                <div class="card-body">
                                    <form>
                                        <div class="row" style="margin-top: 1.7%;">
                                            <label class="col-md-2"> Requested Date </label>
                                            <div class="form-group col-md-3">
                                                <input class="form-control form-control-sm"
                                                    [(ngModel)]="userFinancialTransactionDTO.createdDate"
                                                    [ngModelOptions]="{standalone: true}" disabled>
                                            </div>
                                            <div class="col-md-1"></div>
                                            <label class="col-md-2"> Requested By</label>
                                            <div class="col-md-3">
                                                <input class="form-control form-control-sm"
                                                    [(ngModel)]="userFinancialTransactionDTO.userName"
                                                    [ngModelOptions]="{standalone: true}" disabled>
                                            </div>
                                        </div>

                                        <div class="row" style="margin-top: 1%;">
                                            <label class="col-md-2"> Updated Date </label>
                                            <div class="form-group col-md-3">
                                                <input class="form-control form-control-sm"
                                                    [(ngModel)]="userFinancialTransactionDTO.updatedDate"
                                                    [ngModelOptions]="{standalone: true}" disabled>
                                            </div>
                                            <div class="col-md-1"></div>
                                            <label class="col-md-2"> Updated By </label>
                                            <div class="col-md-3">
                                                <input class="form-control form-control-sm"
                                                    [(ngModel)]="userFinancialTransactionDTO.updatedBy"
                                                    [ngModelOptions]="{standalone: true}" disabled>
                                            </div>
                                        </div>

                                    </form>
                                </div>
                                <!-- /.card-body -->
                            </div>
                        </div>
                    </div>

                </form>
                <!-- /.row -->

                <!-- /.row -->
            </div>
            <!--/. container-fluid -->
        </section>
        <!-- /.content -->
    </div>

    <aside class="control-sidebar control-sidebar-dark">
        <!-- Control sidebar content goes here -->
    </aside>

    <app-footer></app-footer>

    <ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#fff" type="square-jelly-box">
        <p style="font-size: 16px; color: white">Loading...</p>
    </ngx-spinner>

</div>