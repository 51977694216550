import { Component, OnInit, ViewChild } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,HttpErrorResponse } from '@angular/common/http';
import { Router, NavigationEnd } from '@angular/router';
import { DataTableDirective } from 'angular-datatables';

import { LocalStorageService } from 'ngx-webstorage';
import { NgxSpinnerService } from "ngx-spinner";

import { catchError, retry } from 'rxjs/operators';
import { throwError } from 'rxjs';

import { DtoService } from '../../service/dto.service';
import { FunctService } from '../../service/funct.service';
import { ToastrService } from 'ngx-toastr';
import { DatePipe } from '@angular/common'

import { Subject } from 'rxjs';
declare var $: any;

@Component({
  selector: 'app-bankAcc',
  templateUrl: './bankAcc.component.html',
  styleUrls: ['./bankAcc.component.css']
})
export class BankAccComponent implements OnInit {

  @ViewChild(DataTableDirective)
  dtElement: DataTableDirective;
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();

  phoneNo: string= '';
  phone_no: string= '';
  name: string= '';
  accountno:string= '';
  referralCode: string= '';
  status: string= '';
  type: string= '';
  agentList: any;
  idIndex: any;
  gameproviderList : any;
  providerId : any;
  alldate: any;
  alltodate:any;
  alltodayDate: any;
  alltodaytodate:any;
  singleDate: string = '';
  approveDate: string = '';
  alltodatechangeDate : any;
  allchangeDate: any;
  alltoDate: string = '';
  bankTypeList:any;
  BankType:any;

  hardcode_edit :any;
  hardcode_add :any;
  hardcode_view: any;
  hardcode_delete:any;
  hardcode_view_bool : any;
  hardcode_add_bool : any;
  hardcode_edit_bool : any;
  hardcode_delete_bool:any;
  hardcode_double_bool:any;

  constructor(private storage: LocalStorageService, private spinner: NgxSpinnerService, private toastr: ToastrService, private http: HttpClient, private dto: DtoService, private router: Router,
    private funct: FunctService,private datepipe: DatePipe) {
      this.storage.store('isNotiSong', "");
    this.idIndex = 1;
    this.status = 'ACTIVE';  
  
    this.hardcode_view = "SearchMember_View"; 
    this.hardcode_edit = "MemberbyBankAccount_Edit";    
    this.dto.menuCodes = this.storage.retrieve('menucodes');
    const filteredMenuCodeds_edit =[];
    const filteredMenuCodeds_view =[];
 
    this.dto.menuCodes.forEach(menuCode => {
      if (menuCode === this.hardcode_view) {
        filteredMenuCodeds_view.push(menuCode);
      }
   
      if (menuCode === this.hardcode_edit) {
        filteredMenuCodeds_edit.push(menuCode);
      }
     
    });
  
    if(filteredMenuCodeds_view.length != 0)
    {
          this.hardcode_view_bool = true;
    }
    else
    {
      this.hardcode_view_bool = false;
    }
    if(filteredMenuCodeds_edit.length != 0)
    {
          this.hardcode_edit_bool = true;
    }
    else
    {
      this.hardcode_edit_bool = false;
    }
  
    this.getAllProvider();
    this.getBankTypeList();

    this.alldate = new Date();
    this.alltodayDate = this.datepipe.transform(this.alldate, 'MMM dd, yyyy');   
    this.alltodate = new Date(); 
    this.alltodaytodate = this.datepipe.transform(this.alltodate, 'MMM dd, yyyy');

    if(this.dto.isAccMember == "h") 
    {
      this.storage.store('phone_no', ''); 
      this.storage.store('BankType', '');       
    }
    else
    {  
        if (!this.storage.retrieve('phone_no')) {
          this.phoneNo = '';
        }
        else {
          this.phoneNo = this.storage.retrieve('phone_no');
        }
    
        if (!this.storage.retrieve('BankType')) {
          this.status = '';
        }
        else {
          this.status = this.storage.retrieve('BankType');
        }
        
        if(this.phoneNo!=="")
        {
           this.Search();
        }
    } 
  
   }
  ngOnInit(): void {   
    this.BankType ="";
    this.router.routeReuseStrategy.shouldReuseRoute = function () {
      return false;
    };

    this.router.events.subscribe((evt) => {
      if (evt instanceof NavigationEnd) {        
        this.router.navigated = false;       
        window.scrollTo(0, 0);
      }
    });

    this.dtOptions = {
      responsive: true,
      order:[],
      paging: false,
      info : true,
      dom: "Bfrltip", 
    }

    this.dtOptions.columnDefs = [
    ];
    if(!this.storage.retrieve('loadFlag')){
      this.storage.store('loadFlag', 'noLoad');
      setTimeout(function(){
        location.reload();
      }, 5);
    }
    else{
      this.storage.clear('loadFlag');
    }
   
  }
  ngAfterViewInit(){

  }

  handleError(error: HttpErrorResponse){
    if(error.status == 423)
    {
      this.spinner.hide();
      $("#deleteData").modal("show");
    }
    if(error.status == 403)
    {
      this.spinner.hide();
      var id = 'tblAgent' + this.idIndex;
      var table = $('#' + id).DataTable();
      this.toastr.error("Limited Access.", 'Invalid!', {
        timeOut: 3000,
        positionClass: 'toast-top-right',
        });
    }
    return throwError(error);
    }
    OkLogout()
    {
      window.location.href ="./ad-login";
    } 

    getBankTypeList() {
      this.bankTypeList = [];
      this.spinner.show();
      this.dto.token = this.storage.retrieve('token');
      let headers = new HttpHeaders();
      headers = headers.set('Authorization',  this.dto.token);
      this.http.get(this.funct.ipaddress + 'payment/GetBankTypeLists', { headers: headers })
      .pipe(
        catchError(this.handleError.bind(this))
       )
      .subscribe(
        result => {
          this.dto.Response = {};
          this.dto.Response = result;
          this.bankTypeList = this.dto.Response;
          this.spinner.hide();
        });
    }

    Search(){
    this.agentList = [];
    var id = 'tblAgent' + this.idIndex;
    var table = $('#' + id).DataTable();
    table.destroy();
    this.idIndex = this.idIndex +1;
    this.spinner.show();
    this.dto.token = this.storage.retrieve('token');
    let headers = new HttpHeaders();
    headers = headers.set('Authorization', this.dto.token);
    let params = new HttpParams();
    this.singleDate = $("#singleDate").val();
    this.alltoDate = $("#alltodate").val();  

    this.phone_no = this.storage.retrieve('phone_no'); 
    this.BankType = this.storage.retrieve('BankType'); 

    if (this.phone_no == '' || this.phone_no == null || this.phone_no == undefined) 
    {
      this.spinner.hide();
      this.toastr.error('Please upload phone_no.', 'Invalid input!', {
        timeOut: 3000,
        positionClass: 'toast-top-right',
      });
      return;
    }  
    if (this.BankType == undefined) 
    {
      this.spinner.hide();
      this.toastr.error('Please choose banktype.', 'Invalid input!', {
        timeOut: 3000,
        positionClass: 'toast-top-right',
      });
      return;
    } 

    params = params.set('phone_no',this.phone_no).set('bankType', this.BankType)   
    .set("pageNumber","1").set("rowsOfPage","100");
    this.http.get(this.funct.ipaddress + 'user/getUserlist', { params: params, headers: headers } )
    .pipe(
      catchError(this.handleError.bind(this))
     )
    .subscribe(
      result => {
        this.dto.Response = {};
        this.dto.Response = result.results;
        this.agentList = this.dto.Response;
        this.dtTrigger.next();
        this.spinner.hide();
      }
    );
  }


  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }

  getAllProvider()
 {
  this.dto.token = this.storage.retrieve('token');
  let headers = new HttpHeaders();
  headers = headers.set('Authorization',  this.dto.token);
  this.http.get(this.funct.ipaddress + 'gameProvider/getGameProviderList', {headers: headers })
  .pipe(
    catchError(this.handleError.bind(this))
   )
  .subscribe(
    result => {
      this.dto.Response = {};
      this.dto.Response = result;
      this.gameproviderList = this.dto.Response;
      this.gameproviderList.push("None");
    });
 }
 flagProvider()
 {
     this.providerId = $("#providerId").val();
     this.storage.clear('providerId');
     this.storage.store('providerId', Number(this.providerId));
 }

 onChangePhoneNo() {     
  this.storage.store('phone_no', this.phone_no.toString());  
}
onChangeTagSection() {     
  this.storage.store('BankType', this.BankType.toString());  
}
onChangeSingle() {
 
 this.storage.store('ALLTagTopupFromDate', this.alldate.toString());
}
onChangeAllToDate() {
  
 this.storage.store('ALLTagTopupToDate', this.alltodate.toString());
}

}
