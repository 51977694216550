<div class="wrapper">
    <app-header-sidebar></app-header-sidebar>
    <div class="content-wrapper">
      <!-- Content Header (Page header) -->
      <div class="content-header">
        <div class="container-fluid">
          <div class="row mb-2" style="border: 1px solid #ced4da;border-radius: 0.25rem;">
            <div class="col-sm-8">
              <h3 class="m-0 text-dark" style="font-weight: bold;padding: 3px 0px 3px 5px;"> User Permission </h3>
            </div>
          </div><!-- /.row -->
        </div><!-- /.container-fluid -->
      </div>
      <!-- /.content-header -->
      <!-- Main content -->
      <section class="content">
        <div class="container-fluid">
          <form>
            <div class="row">
                <div class="form-group col-md-3 col-sm-3 col-xs-3">
                    <label>Role Name</label>
                    <!-- <label class="form-control form-control-sm">{{this.roleName}}</label> -->
                    <input class="form-control form-control-sm col-xs-10" [(ngModel)]="roleName"
                    [ngModelOptions]="{standalone: true}" disabled>
                </div>
                <div class="form-group col-md-5 col-sm-5 col-xs-5"></div>
                <div class="form-group col-md-2 col-sm-2 col-xs-2">
                    <button type="button" (click) ="save()" class="btn btn-block btn-success btn-sm" style="margin-top: 4%;"> Save </button>
                </div>
                <div class="form-group col-md-2 col-sm-2 col-xs-2">
                    <button type="button" (click) ="cancel()" class="btn btn-block btn-danger btn-sm" style="margin-top: 4%;"> Cancel </button>
                </div>
            </div>
              <div class="col-md-12" style="overflow-y: auto; max-height: 500px;">
                <div class="card">
                  <div class="card-header">
                  <div class="row">
                   <div class="form-group col-md-8 col-sm-8 col-xs-8">
                     <h3 class="card-title" style="font-weight: bold;"> Module List </h3>
                   </div>
                   <div class="form-group col-md-2 col-sm-2 col-xs-2">
                    <button type="button" class="btn btn-block btn-sm" style="margin-top: 4%; background-color: black; color : white !important" (click) ="selectAll()"> Select all </button>
                    </div>
                  <div class="form-group col-md-2 col-sm-2 col-xs-2">
                      <button type="button" class="btn btn-block btn-sm" style="margin-top: 4%;background-color: black;color:white !important;" (click) ="deselectAll()"> Deselect all </button>
                  </div>
                </div>
                  </div>
                  <div class="card-body">
                    <!-- <div id="accordion">
                      <div class="card" *ngFor="let mainMenu of roleList; let i = index; trackBy: trackByFn">
                        <div class="card-header" [id]="'heading' + (i + 1)">
                          <div class="row">
                            <div class="col-md-4">
                              <div class="row">
                                <div class="col-md-6">
                                  <h5 class="mb-0">
                                    <button class="btn btn-link" data-toggle="collapse" [attr.data-target]="'#collapse' + (i + 1)"
                                            [attr.aria-expanded]="i === 0 ? 'true' : 'false'" [attr.aria-controls]="'collapse' + (i + 1)">
                                      {{ mainMenu.title }}
                                    </button>
                                  </h5>
                                </div>
                                <div class="col-md-6">
                                  <h5 class="mb-0 mt-2">
                                    <input type="checkbox" [(ngModel)]="mainMenu.isHaved" (change)="toggleSelectAll(mainMenu)"
                                           [ngModelOptions]="{standalone: true}">
                                  </h5>
                                </div>
                              </div>
                            </div>
                            <div class="col-md-2 mt-2">
                              View
                            </div>
                            <div class="col-md-2 mt-2">
                              Edit
                            </div>
                            <div class="col-md-2 mt-2">
                              Create
                            </div>
                            <div class="col-md-2 mt-2">
                              Delete
                            </div>
                          </div>
                        </div>
                        <div [id]="'collapse' + (i + 1)" class="collapse" [attr.aria-labelledby]="'heading' + (i + 1)" data-parent="#accordion">
                          <div *ngFor="let childMenu of mainMenu.childrens; let j = index">
                            <ng-container *ngIf="!checkSubMenuType(childMenu)">
                              <div class="row card-body">
                                <div class="col-md-4">{{ childMenu.title }}</div>
                                
                                <ng-container *ngIf ="childMenu.childrens.length == 4">
                                  <div class="col-md-2" *ngFor="let subMenu of childMenu.childrens; let k = index">
                                    <input type="checkbox" [(ngModel)]="subMenu.isHaved" [ngModelOptions]="{ standalone: true }">
                                  </div>
                                </ng-container>

                                <ng-container *ngIf ="childMenu.childrens.length >0 && childMenu.childrens.length < 4">
                                  <div class="col-md-2" *ngFor="let subMenu of childMenu.childrens; let k = index">
                                    <div class="col-md-2" *ngIf ="checkCode(subMenu,'_View', subMenu.code); let viewFlag = flag">
                                      <input type="checkbox" [(ngModel)]="subMenu.isHaved" [ngModelOptions]="{ standalone: true }">
                                     </div>
                                     <div class="col-md-2" *ngIf ="checkCode(subMenu,'_Edit',subMenu.code);let editFlag = flag">
                                      <input type="checkbox" [(ngModel)]="subMenu.isHaved" [ngModelOptions]="{ standalone: true }">
                                     </div>
                                     <div class="col-md-2" *ngIf ="checkCode(subMenu,'_Add',subMenu.code);let addFlag = flag">
                                      <input type="checkbox" [(ngModel)]="subMenu.isHaved" [ngModelOptions]="{ standalone: true }">
                                     </div>
                                     <div class="col-md-2" *ngIf ="checkCode(subMenu,'_Del',subMenu.code);let delFlag = flag">
                                      <input type="checkbox" [(ngModel)]="subMenu.isHaved" [ngModelOptions]="{ standalone: true }">
                                     </div>
                                  </div>
  <div class="col-md-2" *ngIf="!viewFlag"></div>

  <div class="col-md-2" *ngIf="!editFlag"></div>

  <div class="col-md-2" *ngIf="!addFlag"></div>

  <div class="col-md-2" *ngIf="!delFlag"></div>
                                </ng-container>

                              </div>
                            </ng-container>
                            <ng-container *ngIf="checkSubMenuType(childMenu)">
                              <div class="accordion" id="childAccordion{{i}}-{{j}}">
                                <div class="card">
                                  <div class="card-header" id="childHeading{{i}}-{{j}}">
                                    <div class="row">
                                      <div class="col-md-4">
                                        <h2 class="mb-0">
                                          <button class="btn btn-link" data-toggle="collapse" [attr.data-target]="'#childCollapse' + i + j"
                                                  [attr.aria-expanded]="j === 0 ? 'true' : 'false'" [attr.aria-controls]="'childCollapse' + i + j">
                                            {{ childMenu.title }}
                                          </button>
                                          <input type="checkbox" [(ngModel)]="childMenu.isHaved" (change)="toggleSelectAll(childMenu)"
                                          [ngModelOptions]="{standalone: true}">
                                        </h2>
                                      </div>
                                      <div class="col-md-2 mt-2">
                                        View
                                      </div>
                                      <div class="col-md-2 mt-2">
                                        Edit
                                      </div>
                                      <div class="col-md-2 mt-2">
                                        Create
                                      </div>
                                      <div class="col-md-2 mt-2">
                                        Delete
                                      </div>
                                    </div>
                                  </div>
                                  <div [id]="'childCollapse' + i + j" class="collapse" [attr.aria-labelledby]="'childHeading' + i + j" data-parent="#childAccordion{{i}}-{{j}}">
                                    <div *ngFor="let subMenu1 of childMenu.childrens; let k = index" class="col-md-12">
                                      <div class="row card-body">
                                        <div class="col-md-4">{{ subMenu1.title }}</div>

                                        <ng-container *ngIf ="subMenu1.childrens.length == 4">
                                         <div class="col-md-2" *ngFor="let subMenu2 of subMenu1.childrens; let l = index">
                                          <input type="checkbox" [(ngModel)]="subMenu2.isHaved" [ngModelOptions]="{ standalone: true }">
                                        </div>
                                        </ng-container>

                                      
                                        
                                        <ng-container *ngIf="subMenu1.childrens.length > 0 && subMenu1.childrens.length < 4">
                                          <div class="col-md-2" *ngFor="let subMenu2 of subMenu1.childrens; let l = index">
                                            <div class="col-md-2" *ngIf="checkCode(subMenu2,'_View', subMenu2.code); let viewFlag = flag">
                                              <input type="checkbox" [(ngModel)]="subMenu2.isHaved" [ngModelOptions]="{ standalone: true }">
                                            </div>
                                            <div class="col-md-2" *ngIf="checkCode(subMenu2,'_Edit', subMenu2.code); let editFlag = flag">
                                              <input type="checkbox" [(ngModel)]="subMenu2.isHaved" [ngModelOptions]="{ standalone: true }">
                                            </div>
                                            <div class="col-md-2" *ngIf="checkCode(subMenu2,'_Add', subMenu2.code); let addFlag = flag">
                                              <input type="checkbox" [(ngModel)]="subMenu2.isHaved" [ngModelOptions]="{ standalone: true }">
                                            </div>
                                            <div class="col-md-2" *ngIf="checkCode(subMenu2,'_Del', subMenu2.code); let delFlag = flag">
                                              <input type="checkbox" [(ngModel)]="subMenu2.isHaved" [ngModelOptions]="{ standalone: true }">
                                            </div>
                                          </div>
                                        
  <div class="col-md-2" *ngIf="!viewFlag"></div>

  <div class="col-md-2" *ngIf="!editFlag"></div>

  <div class="col-md-2" *ngIf="!addFlag"></div>

  <div class="col-md-2" *ngIf="!delFlag"></div>
                                        </ng-container>
                                        

                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </ng-container>
                          </div>
                        </div>
                      </div>
                    </div> -->
                   
            
                    <!-- <div id="accordion">
                      <div class="card" *ngFor="let mainMenu of roleList.childrens.data; let i = index; trackBy: trackByFn">
                        <div class="card-header" [id]="'heading' + (i + 1)">
                          <div class="row">
                            <div class="col-md-4">
                              <div class="row">
                                <div class="col-md-6">
                                  <h5 class="mb-0">
                                    <button class="btn btn-link" data-toggle="collapse" [attr.data-target]="'#collapse' + (i + 1)"
                                            [attr.aria-expanded]="i === 0 ? 'true' : 'false'" [attr.aria-controls]="'collapse' + (i + 1)">
                                      {{ mainMenu?.title }}
                                    </button>
                                  </h5>
                                </div>
                                <div class="col-md-6">
                                  <h5 class="mb-0 mt-2">
                                    <input *ngIf="mainMenu?.type === 1" type="checkbox" [(ngModel)]="mainMenu.isHaved" (change)="toggleSelectAll(mainMenu)"
                                           [ngModelOptions]="{standalone: true}">
                                  </h5>
                                </div>
                              </div>
                            </div>
                            <div class="col-md-2 mt-2">
                              View
                            </div>
                            <div class="col-md-2 mt-2">
                              Edit
                            </div>
                            <div class="col-md-2 mt-2">
                              Create
                            </div>
                            <div class="col-md-2 mt-2">
                              Delete
                            </div>
                          </div>
                        </div>
                        <div [id]="'collapse' + (i + 1)" class="collapse" [attr.aria-labelledby]="'heading' + (i + 1)" data-parent="#accordion">
                          <div class="row card-body" *ngFor="let childMenu of mainMenu?.childrens?.data; let j = index">
                            <div class="col-md-4">{{ childMenu?.title }}</div>
                            <div *ngFor="let subMenu of childMenu?.childrens?.data; let k = index" class="col-md-2">
                              <input *ngIf="subMenu?.type === 3" type="checkbox" [(ngModel)]="subMenu.isHaved" [ngModelOptions]="{ standalone: true }">
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                     -->
                    <!-- correct one <div id="accordion">
                      <div class="card" *ngFor="let mainMenu of roleList; let i = index; trackBy: trackByFn">
                        <div class="card-header" [id]="'heading' + (i + 1)">
                          <div class="row">
                            <div class="col-md-4">
                              <div class="row">
                                <div class="col-md-6">
                                  <h5 class="mb-0">
                                    <button class="btn btn-link" data-toggle="collapse" [attr.data-target]="'#collapse' + (i + 1)"
                                            [attr.aria-expanded]="i === 0 ? 'true' : 'false'" [attr.aria-controls]="'collapse' + (i + 1)">
                                      {{ mainMenu.title }}
                                    </button>
                                  </h5>
                                </div>
                                <div class="col-md-6">
                                  <h5 class="mb-0 mt-2">
                                    <input type="checkbox" [(ngModel)]="mainMenu.isHaved" (change)="toggleSelectAll(mainMenu)"
                                           [ngModelOptions]="{standalone: true}">
                                  </h5>
                                </div>
                              </div>
                            </div>
                            <div class="col-md-2 mt-2">
                              View
                            </div>
                            <div class="col-md-2 mt-2">
                              Edit
                            </div>
                            <div class="col-md-2 mt-2">
                              Create
                            </div>
                            <div class="col-md-2 mt-2">
                              Delete
                            </div>
                          </div>
                        </div>
                        <div [id]="'collapse' + (i + 1)" class="collapse" [attr.aria-labelledby]="'heading' + (i + 1)" data-parent="#accordion">
                          <div class="row card-body" *ngFor="let childMenu of mainMenu.childrens; let j = index">
                            <div class="col-md-4">{{ childMenu.title }}</div>
                            <div *ngFor="let subMenu of childMenu.childrens; let k = index" class="col-md-2">
                              <input type="checkbox" [(ngModel)]="subMenu.isHaved" [ngModelOptions]="{ standalone: true }">
                            </div>
                          </div>
                        </div>
                      </div>
                    </div> -->
                    <!-- <div id="accordion">
                      <div class="card" *ngFor="let item of roleList; let i = index; trackBy: trackByFn">
                        <div class="card-header" [id]="'heading' + (i + 1)">
                          <div class="row">
                            <div class="col-md-4">
                              <div class="row">
                                <div class="col-md-6">
                                  <h5 class="mb-0">
                                    <button class="btn btn-link" data-toggle="collapse" [attr.data-target]="'#collapse' + (i + 1)"
                                            [attr.aria-expanded]="i === 0 ? 'true' : 'false'" [attr.aria-controls]="'collapse' + (i + 1)">
                                      {{ item.title }}
                                    </button>
                                  </h5>
                                </div>
                                <div class="col-md-6">
                                  <h5 class="mb-0 mt-2">
                                    <input type="checkbox" [(ngModel)]="item.isHaved" (change)="toggleSelectAll(item)"
                                           [ngModelOptions]="{standalone: true}">
                                  </h5>
                                </div>
                              </div>
                            </div>
                            <div class="col-md-2 mt-2">
                              View
                            </div>
                            <div class="col-md-2 mt-2">
                              Edit
                            </div>
                            <div class="col-md-2 mt-2">
                              Create
                            </div>
                            <div class="col-md-2 mt-2">
                              Delete
                            </div>
                          </div>
                        </div>
                        <div [id]="'collapse' + (i + 1)" class="collapse" [attr.aria-labelledby]="'heading' + (i + 1)" data-parent="#accordion">
                          
                          <div class="row card-body" *ngFor="let child of item.childrens; let j = index">
                            <div class="col-md-4">{{ child.title }}</div>
                            <div class="col-md-2"><input type="checkbox" [(ngModel)]="child.viewIsHaved" [ngModelOptions]="{ standalone: true }"></div>
                            <div class="col-md-2"><input type="checkbox" [(ngModel)]="child.editIsHaved" [ngModelOptions]="{ standalone: true }"></div>
                            <div class="col-md-2"><input type="checkbox" [(ngModel)]="child.createIsHaved" [ngModelOptions]="{ standalone: true }"></div>
                            <div class="col-md-2"><input type="checkbox" [(ngModel)]="child.deleteIsHaved" [ngModelOptions]="{ standalone: true }"></div>
                          </div>
                        </div>
                      </div>
                    </div> -->
                    <!-- <div id="accordion">
                      <div class="card" *ngFor="let item of roleList; let i = index;trackBy: trackByFn">
                        <div class="card-header" [id]="'heading' + (i + 1)">
                          <div class="row">
                            <div class="col-md-4">
                              <div class="row">
                                <div class="col-md-6">
                                  <h5 class="mb-0">
                                    <button class="btn btn-link" data-toggle="collapse" [attr.data-target]="'#collapse' + (i + 1)"
                                      [attr.aria-expanded]="i === 0 ? 'true' : 'false'" [attr.aria-controls]="'collapse' + (i + 1)">
                                      {{ item.title }}
                                    </button>
                                  </h5>
                                </div>
                                <div class="col-md-6">
                                  <h5 class="mb-0 mt-2">
                                    <input type="checkbox" [(ngModel)]="roleList[i].isHaved" (change)="toggleSelectAll(i)"
                                      [ngModelOptions]="{standalone: true}">
                                  </h5>
                                </div>
                              </div>
                            </div>
                            <div class="col-md-2 mt-2">
                              View
                            </div>
                            <div class="col-md-2 mt-2">
                              Edit
                            </div>
                            <div class="col-md-2 mt-2">
                              Create
                            </div>
                            <div class="col-md-2 mt-2">
                              Delete
                            </div>
                          </div>
                        </div>
                         <div [id]="'collapse' + (i + 1)" class="collapse" [attr.aria-labelledby]="'heading' + (i + 1)" data-parent="#accordion">
                          <div class="row card-body" *ngFor="let children of item.childrens; let j = index">
                            <div class="col-md-4">{{ children.title }}</div>
                            <div class="col-md-2"><input type="checkbox" [(ngModel)]="item.childrens[j].isHaved" [ngModelOptions]="{standalone: true}">{{item.childrens[j].code}}</div>
                            <div class="col-md-2"><input type="checkbox" [(ngModel)]="item.childrens[j].isHaved" [ngModelOptions]="{standalone: true}">{{item.childrens[j].code}}</div>
                            <div class="col-md-2"><input type="checkbox" [(ngModel)]="item.childrens[j].isHaved" [ngModelOptions]="{standalone: true}">{{item.childrens[j].code}}</div>
                            <div class="col-md-2"><input type="checkbox" [(ngModel)]="item.childrens[j].isHaved" [ngModelOptions]="{standalone: true}">{{item.childrens[j].code}}</div>
                          </div>
                        </div> 
                       
                      </div>
                    </div>   -->
                    
                    <!-- correct <div class="accordion">
                      <div *ngFor="let item of roleList" class="accordion-item">
                        <div class="accordion-header" (click)="toggleItem(item)">
                          <div class="row">
                            <div class="col-md-4">{{ item.title }}</div>
                            <div class="col-md-4">
                              <input
                                type="checkbox"
                                [(ngModel)]="item.isHaved"
                                [ngModelOptions]="{ standalone: true }"
                                (change)="toggleAllItems(item, 'level1', item.childrens)"
                              />
                            </div>
                          </div>
                        </div>
                        <div *ngIf="item.expanded && item.childrens" class="accordion-content">
                          <div class="accordion-item" *ngFor="let childItem of item.childrens">
                            <div class="accordion-header" (click)="toggleItem(childItem)">
                              <div class="row">
                                <div class="col-md-4">{{ childItem.title }}</div>
                                <div class="col-md-4">
                                  <input
                                    type="checkbox"
                                    [(ngModel)]="childItem.isHaved"
                                    [ngModelOptions]="{ standalone: true }"
                                    (change)="toggleAllItems(childItem, 'level2', childItem.childrens)"
                                  />
                                </div>
                              </div>
                            </div>
                            <div *ngIf="childItem.expanded && childItem.childrens" class="accordion-content">
                              <div class="accordion-item" *ngFor="let grandChildItem of childItem.childrens">
                                <div class="accordion-header" (click)="toggleItem(grandChildItem)" style="background-color: gainsboro;">
                                  <div class="row">
                                    <div class="col-md-4">{{ grandChildItem.code }}</div>
                                    <div class="col-md-4">
                                      <input
                                        type="checkbox"
                                        [(ngModel)]="grandChildItem.isHaved"
                                        [ngModelOptions]="{ standalone: true }"
                                        (change)="toggleAllItems(grandChildItem, 'level3', grandChildItem)"
                                      />
                                    </div>
                                  </div>
                                </div>
                               
                                <div *ngIf="grandChildItem.expanded && grandChildItem.childrens" class="accordion-content">
                                  <div class="accordion-item" *ngFor="let grandChildItem1 of grandChildItem.childrens">
                                    <div class="accordion-header" (click)="toggleItem(grandChildItem1)" style="background-color: gainsboro;">
                                      <div class="row">
                                        <div class="col-md-4">{{ grandChildItem1.code }}</div>
                                        <div class="col-md-4">
                                          <input
                                            type="checkbox"
                                            [(ngModel)]="grandChildItem1.isHaved"
                                            [ngModelOptions]="{ standalone: true }"
                                            (change)="toggleAllItems(grandChildItem1, 'level4', grandChildItem1)"
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  
                                    <div *ngIf="grandChildItem1.expanded && grandChildItem1.childrens" class="accordion-content">
                                      <div class="accordion-item" *ngFor="let grandChildItem2 of grandChildItem1.childrens">
                                        <div class="accordion-header" style="background-color: gainsboro;">
                                          <div class="row">
                                            <div class="col-md-4">{{ grandChildItem2.code }}</div>
                                            <div class="col-md-4">
                                              <input
                                                type="checkbox"
                                                [(ngModel)]="grandChildItem2.isHaved"
                                                [ngModelOptions]="{ standalone: true }"
                                                (change)="toggleAllItems(grandChildItem2, 'level5', grandChildItem2)"
                                              />
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>

                                  </div>
                                </div>
                                
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div> -->

                    <!-- <div class="container">
                      <div class="accordion" id="accordionExample">
                        <div class="item">
                           <div class="item-header" id="headingOne">
                              <h2 class="mb-0">
                                 <button class="btn btn-link" type="button" data-toggle="collapse"
                                    data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                 Collapsible Item #1
                                 <i class="fa fa-angle-down"></i>
                                 </button>
                              </h2>
                           </div>
                           <div id="collapseOne" class="collapse show" aria-labelledby="headingOne"
                              data-parent="#accordionExample">
                              <div class="t-p">
                      It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English. Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text, and a search for 'lorem ipsum' will uncover many web sites still in their infancy. Various versions have evolved over the years, sometimes by accident, sometimes on purpose (injected humour and the like).
                              </div>
                           </div>
                        </div>
                        <div class="item">
                           <div class="item-header" id="headingTwo">
                              <h2 class="mb-0">
                                 <button class="btn btn-link collapsed" type="button" data-toggle="collapse"
                                    data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                 Collapsible Item #2
                                 <i class="fa fa-angle-down"></i>
                                 </button>
                              </h2>
                           </div>
                           <div id="collapseTwo" class="collapse" aria-labelledby="headingTwo"
                              data-parent="#accordionExample">
                              <div class="t-p">
                      It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English. Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text, and a search for 'lorem ipsum' will uncover many web sites still in their infancy. Various versions have evolved over the years, sometimes by accident, sometimes on purpose (injected humour and the like).
                              </div>
                           </div>
                        </div>
                        <div class="item">
                           <div class="item-header" id="headingThree">
                              <h2 class="mb-0">
                                 <button class="btn btn-link collapsed" type="button" data-toggle="collapse"
                                    data-target="#collapseThree" aria-expanded="false"
                                    aria-controls="collapseThree">
                                 Collapsible Item #3
                                 <i class="fa fa-angle-down"></i>
                                 </button>
                              </h2>
                           </div>
                           <div id="collapseThree" class="collapse" aria-labelledby="headingThree"
                              data-parent="#accordionExample">
                              <div class="t-p">
                      It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English. Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text, and a search for 'lorem ipsum' will uncover many web sites still in their infancy. Various versions have evolved over the years, sometimes by accident, sometimes on purpose (injected humour and the like).
                              </div>
                           </div>
                        </div>
                        <div class="item">
                           <div class="item-header" id="headingFour">
                              <h2 class="mb-0">
                                 <button class="btn btn-link collapsed" type="button" data-toggle="collapse"
                                    data-target="#collapseFour" aria-expanded="false"
                                    aria-controls="collapseFour">
                                 Collapsible Item #4
                                 <i class="fa fa-angle-down"></i>
                                 </button>
                              </h2>
                           </div>
                           <div id="collapseFour" class="collapse" aria-labelledby="headingFour"
                              data-parent="#accordionExample">
                              <div class="t-p">
                                 It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English. Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text, and a search for 'lorem ipsum' will uncover many web sites still in their infancy. Various versions have evolved over the years, sometimes by accident, sometimes on purpose (injected humour and the like).
                              </div>
                           </div>
                        </div>
                      </div>
                    </div> -->
                    <div class="accordion" id="roleAccordion">
                      <div *ngFor="let item of roleList" class="accordion-item">
                        <div class="accordion-header" (click)="toggleItem(item)" [ngClass]="{ 'active': item.expanded }">
                          <div class="row">
                            <div class="col-md-6">{{ item.title }}</div>
                            <div class="col-md-4">
                              <input
                                type="checkbox"
                                [(ngModel)]="item.isHaved"
                                [ngModelOptions]="{ standalone: true }"
                                (change)="toggleAllItems(item, 'level1', item.childrens)"
                              />
                            </div>
                            <div class="col-md-2" *ngIf="item.childrens.length > 0">
                              <i class="fas" [ngClass]="{'fa-angle-down': item.expanded, 'fa-angle-up': !item.expanded}"></i>
                            </div>
                          </div>
                        </div>
                        <div *ngIf="item.expanded && item.childrens" class="accordion-content">
                          <div *ngFor="let childItem of item.childrens" class="accordion-item_1">
                            <div class="accordion-header_1" (click)="toggleItem(childItem)" [ngClass]="{ 'active': childItem.expanded }">
                              <div class="row">
                                <div class="col-md-6">{{ childItem.title }}</div>
                                <div class="col-md-4">
                                  <input
                                    type="checkbox"
                                    [(ngModel)]="childItem.isHaved"
                                    [ngModelOptions]="{ standalone: true }"
                                    (change)="toggleAllItems(childItem, 'level2', childItem.childrens)"
                                  />
                                </div>
                                <div class="col-md-2" *ngIf="childItem.childrens.length > 0">
                                  <i class="fas" [ngClass]="{'fa-angle-down': childItem.expanded, 'fa-angle-up': !childItem.expanded}"></i>
                                </div>
                              </div>
                            </div>
                            <div *ngIf="childItem.expanded && childItem.childrens" class="accordion-content">
                              <div *ngFor="let grandChildItem of childItem.childrens" class="accordion-item_2">
                                <div class="accordion-header_2" (click)="toggleItem(grandChildItem)" [ngClass]="{ 'active': grandChildItem.expanded }">
                                  <div class="row">
                                    <div class="col-md-6">{{ grandChildItem.code }}</div>
                                    <div class="col-md-4">
                                      <input
                                        type="checkbox"
                                        [(ngModel)]="grandChildItem.isHaved"
                                        [ngModelOptions]="{ standalone: true }"
                                        (change)="toggleAllItems(grandChildItem, 'level3', grandChildItem)"
                                      />
                                    </div>
                                    <div class="col-md-2" *ngIf="grandChildItem.childrens.length > 0">
                                      <i class="fas" [ngClass]="{'fa-angle-down': grandChildItem.expanded, 'fa-angle-up': !grandChildItem.expanded}"></i>
                                    </div>
                                  </div>
                                </div>
                                <div *ngIf="grandChildItem.expanded && grandChildItem.childrens" class="accordion-content">
                                  <div *ngFor="let grandChildItem1 of grandChildItem.childrens" class="accordion-item_2">
                                    <div class="accordion-header_2" (click)="toggleItem(grandChildItem1)" [ngClass]="{ 'active': grandChildItem1.expanded }" >
                                      <div class="row">
                                        <div class="col-md-6">{{ grandChildItem1.code }}</div>
                                        <div class="col-md-4">
                                          <input
                                            type="checkbox"
                                            [(ngModel)]="grandChildItem1.isHaved"
                                            [ngModelOptions]="{ standalone: true }"
                                            (change)="toggleAllItems(grandChildItem1, 'level4', grandChildItem1)"
                                          />
                                        </div>
                                        <div class="col-md-2" *ngIf="grandChildItem1.childrens.length > 0">
                                          <i class="fas" [ngClass]="{'fa-angle-down': grandChildItem1.expanded, 'fa-angle-up': !grandChildItem1.expanded}"></i>
                                        </div>
                                      </div>
                                    </div>
                                    <div *ngIf="grandChildItem1.expanded && grandChildItem1.childrens" class="accordion-content">
                                      <div *ngFor="let grandChildItem2 of grandChildItem1.childrens" class="accordion-item_2">
                                        <div class="accordion-header_2" [ngClass]="{ 'active': grandChildItem2.expanded }">
                                          <div class="row">
                                            <div class="col-md-6">{{ grandChildItem2.code }}</div>
                                            <div class="col-md-4">
                                              <input
                                                type="checkbox"
                                                [(ngModel)]="grandChildItem2.isHaved"
                                                [ngModelOptions]="{ standalone: true }"
                                                (change)="toggleAllItems(grandChildItem2, 'level5', grandChildItem2)"
                                              />
                                            </div>
                                            <div class="col-md-2" *ngIf="grandChildItem2.childrens.length > 0">
                                              <i class="fas" [ngClass]="{'fa-angle-down': grandChildItem2.expanded, 'fa-angle-up': !grandChildItem2.expanded}"></i>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>                    
                   
                    <!--end test-->

                  </div>
                </div>
              </div>
           
          </form>
        </div>
      </section>
    </div>
    <aside class="control-sidebar control-sidebar-dark">
    </aside>
    <app-footer></app-footer>
    <ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#fff" type="square-jelly-box">
      <p style="font-size: 16px; color: white">Loading...</p>
    </ngx-spinner>
  </div>
  