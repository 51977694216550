import { Router, NavigationEnd,ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Component, OnInit, ViewChild, Pipe, PipeTransform } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,HttpErrorResponse } from '@angular/common/http';
import { DataTableDirective } from 'angular-datatables';
import { DatePipe } from '@angular/common'
import { LocalStorageService } from 'ngx-webstorage';
import { NgxSpinnerService } from "ngx-spinner";
import { FunctService } from '../../service/funct.service';
import { DtoService } from '../../service/dto.service';
import { DomSanitizer ,SafeUrl} from '@angular/platform-browser'; //for unsafe image

import { catchError, retry } from 'rxjs/operators';
import { throwError } from 'rxjs';

import { Subject } from 'rxjs';
import { truncate } from 'fs';
declare var $: any;

@Component({
  selector: 'app-user-detail-active',
  templateUrl: './user-detail-active.component.html',
  styleUrls: ['./user-detail-active.component.css']
})

export class UserDetailActiveComponent implements OnInit,PipeTransform {

  @ViewChild(DataTableDirective)
  dtElement: DataTableDirective;
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();
  dtOptions1: DataTables.Settings = {};
  dtTrigger1: Subject<any> = new Subject();
  dtOptions2: DataTables.Settings = {};
  dtTrigger2: Subject<any> = new Subject();
  dtOptions3: DataTables.Settings = {};
  dtTrigger3: Subject<any> = new Subject();
  dtOptions4: DataTables.Settings = {};
  dtTrigger4: Subject<any> = new Subject();
  dtOptions5: DataTables.Settings = {};
  dtTrigger5: Subject<any> = new Subject();
  dtOptions6: DataTables.Settings = {};
  dtTrigger6: Subject<any> = new Subject();
  dtOptions7: DataTables.Settings = {};
  dtTrigger7: Subject<any> = new Subject();
  dtOptions8: DataTables.Settings = {};
  dtTrigger8: Subject<any> = new Subject();
  dtOptionswinlose: DataTables.Settings = {};
  dtTriggergwinlose: Subject<any> = new Subject();
  dtOptions10: DataTables.Settings = {};
  dtTrigger10: Subject<any> = new Subject();
  dtOptions11: DataTables.Settings = {};
  dtTrigger11: Subject<any> = new Subject();
  dtOptions12: DataTables.Settings = {};
  dtTrigger12: Subject<any> = new Subject();
  dtOptionsKM: DataTables.Settings = {};
  dtTriggerKM: Subject<any> = new Subject();
  dtTriggerTrand : Subject<any> = new Subject();
  dtOptionsTrand: DataTables.Settings = {};
  dtTriggergamewallet : Subject<any> = new Subject(); 
  dtOptionsgamewallet : DataTables.Settings = {};
  userDTO: any;
  token: any;
  userId: any;
  agentList: [];
  agentId: null;
  adminList: [];
  adminId: null;
  amount : any;
  wallet : any;
  twodwinamount : any;
  twodwinwallet : any;
  twodhitamount : any;
  twodhitwallet : any;
  threedhitamount : any;
  threedhitwallet : any;
  tempUserBankInfoDTOList : any;
  bankimgURL : any;
  qrImageURL :any;
  editUserDTO : any;

  topupamount : any;
  topuptran : any;
  topupwallet : any;
  withdrawalAcc :any;
  withdrawalamount : any;
  withdrawalwallet : any;
  threeDBetDetailDTOList : any;


  walletHistoryDTOLit :any;
  tempWinnerDTOList : any;
  tempWinnerDTOList1 : any;
  tempFinancialTransactionDTOList : any;
  tempFinancialTransactionDTOList1 : any;
  tempQMfinancialTransactionDTOList : any;
  tempBetHistoryDTOList : any;
  tempBetHistoryDTOList1 : any;
  twoDBetDetailDTOList : any;

  idIndex: any;
  bankidIndex : any;
  idtwodindex : any;
  idtwodwinIndex : any;
  idtopupIndex : any;
  idwithdrawalIndex : any;
  idthreedbetIndex : any;
  idthreedwinIndex :any;
  idgameIndex : any;
  idgamewinIndex : any;
  idbonusIndex : any
  idtwoddetailIndex: any;
  idthreeddetailIndex : any;

  imgURL : any;
  message :any;
  imagePath: any;
  isProfile : boolean = false;

  singleDate: string = '';
  todate : any; //add this

  alltoDate: string = ''; //add this
  alltodate : any;
  alltodaytodate : any;
  alltodatechangeDate : any; //add this
  alldate: any;
  allchangeDate: any;
  alltodayDate: any;

  twodwinDate: string = '';
  twodwindate: any;
  twodwintoDate : any;
  twodwintodate : any;
  twodwintodaytodate : any;
  twodwintodatechangeDate : any;
  twodwintodayDate: any;
  twodwinchangeDate: any;

  twodbetDate: string = '';
  twodbetdate: any;
  twodbettodate :any;
  twodbettoDate : any;
  twodbettodaytodate : any;
  twodbettodatechangeDate : any;
  twodbettodayDate: any;
  twodbetchangeDate: any;
  towdbetDate : any;

  topuptodate : any;
  topuptoDate : any;
  topupdate: any;
  topupDate: any;
  topuptodayDate: any;
  topupchangeDate: any;
  topuptodaytodate : any;
  topuptodatechangeDate : any;

  withtodate : any;
  withtoDate : any;
  withtodatechangeDate : any;
  withtodaytodate : any;
  withdate: any;
  withtodayDate: any;
  withchangeDate: any;
  withDate : any;

  threedbetdate : any;
  threedbetDate : any;
  threedbetchangeDate : any;
  threedbettodate : any;
  threedbettoDate : any;
  threedbettodatechangeDate : any;
  threedbettodaytodate : any;
  threedbettodaydate : any;

  threedwindate : any;
  threedwinDate : any;
  threedwinchangeDate : any;
  threedwintodate : any;
  threedwintoDate : any;
  threedwintodatechangeDate : any;
  threedwintodaytodate : any;
  threedwintodayDate : any;

  gamedate : any;
  gameDate : any;
  gamechangeDate : any;
  gametodate : any;
  gametoDate : any;
  gametodatechangeDate : any;
  gametodaytodate : any;
  gametodaydate : any;

  gamewindate : any;
  gamewinDate : any;
  gamewinchangeDate : any;
  gamewintodate : any;
  gamewintoDate : any;
  gamwinetodatechangeDate : any;
  gamewintodaytodate : any;
  gamewintodaydate : any;
  bonusdate : any;
  bonusDate : any;
  bonuschangeDate : any;
  bonustodate : any;
  bonustoDate : any;
  bonustodatechangeDate : any;
  bonustodaytodate : any;
  bonustodaydate : any;
  twoDBetDetailDTOList_temp : any;
  threeDBetDetailDTOList_temp : any;
  queenmakerdate : any;
  qmdate : any;
  queenmakertodate : any;
  qmtodate : any;
  qmtodaydate: any;
  qmtodaytodate: any;
  queenmakerfchangedate : any;
  queenmakertchangedate  : any;
  tblqmtranid : any;
  gameproviderList : any;
  providerId : any;
  transactionDetailList : any;
  idtbltrnadetail : any;
  winnerDetail  : any;
  gameTranDetailList :  any;
  idgamewallet : any;
  gameWalletModel_temp : any;
  userGameLogList : any;
  tblgamelogtran : any;
  displayUserId : any;
  providerId1 : any;
  displayUserIdTran : any;
  allTranFromDate : any;
  isUseSearchMem : any;
  adjustLogs : any;
  userbalances:any;
  addTranType:any;
  deletTranDto:any;
  addTranDto:any;
  addTranAmt:any;
  addTranRemark:any;
  addAmount:any;
  addRemark:any;
  isInsertByAdmin:any;
  trantype:any;
  deleteId:any;
  gameDTO:any;
  data1 : any;
  data2: any;
  data3:any;
  source:any;
  addfacdto:any;

  deleteuseraccid :any;
  deletepaymentId  :any;
  deletebankaccid :any;
  originalAdminDTO:any;
  statusforEdit:any;

  
  //adjust
  adjustmakerdate:any;
  adjustmakertodaydate:any;
  adjusttodate:any;
  adjustfromdate:any;
  adjfromdate:any;
  adjtodate:any;

  adjustchangedate : any;
  adjusttochangedate  : any;
  adjtodaydate: any;
  adjtodaytodate: any;
  tempAdjustTransactionList :any;
  tbladjustid : any;
  dtOptionsadjust: DataTables.Settings = {};
  dtTriggeradjust: Subject<any> = new Subject();
  adjusttype:any;
  temp_bankaccount:any;
  temp_paymenttype:any;
  temp_user_account_no:any;
  selectedButton:any;
  isWithdrawalVisible = false; 
  tempUserBankInfoDTOList_topup:any;
  remark:any;
  qrImageUrl?: SafeUrl;
  isQrModalVisible: boolean = false;

  constructor(private storage: LocalStorageService, private spinner: NgxSpinnerService, private toastr: ToastrService, private http: HttpClient, private dto: DtoService, 
    private router: Router, private route: ActivatedRoute,private datepipe: DatePipe, private funct: FunctService,private sanitizer:DomSanitizer) {
      this.storage.store('isNotiSong', "");
    this.idIndex = 1;
    this.bankidIndex = 1;
    this.idtwodindex = 1;
    this.idtopupIndex = 1;
    this.idwithdrawalIndex = 1;
    this.idthreedwinIndex = 1;
    this.idthreedbetIndex = 1;
    this.idgameIndex = 1;
    this.idgamewinIndex = 1;
    this.idbonusIndex = 1;
    this.tblqmtranid = 1;
    this.idtbltrnadetail = 1;
    this.idgamewallet = 1;
    this.tblgamelogtran = 1;
    this.data1 = this.dto.isSearchMember;
    this.data2 = this.dto.isSearchBoolean;
    this.data3 = this.dto.isUseSearch;
      
    this.dto.isSearchMember = "d";
    this.dto.isSearchBoolean ="d"; 
    this.dto.isGameTransaction=  "d"; 
    this.dto.isGameTransactionBoolean=  "d"; 
    this.dto.isForgotPassword = "d";
    this.dto.isAccMember="d";
    
   
    this.dto.iisAccMemberBoolean ="d"; 

    //amk added for adjust 
    this.tbladjustid = 1;

    //

    this.getAllProvider();

    this.twodbetdate = new Date();
    this.twodbettodayDate = this.datepipe.transform(this.twodbetdate, 'MMM dd, yyyy');

    this.twodwindate = new Date();
    this.twodwintodayDate = this.datepipe.transform(this.twodwindate, 'MMM dd, yyyy');

    this.topupdate = new Date();
    this.topuptodayDate = this.datepipe.transform(this.topupdate, 'MMM dd, yyyy');

    this.withdate = new Date();
    this.withtodayDate = this.datepipe.transform(this.withdate, 'MMM dd, yyyy');

   
    this.alldate = new Date();
    this.alltodayDate = this.datepipe.transform(this.alldate, 'MMM dd, yyyy');


    this.alltodate = new Date(); //add this
    this.alltodaytodate = this.datepipe.transform(this.alltodate, 'MMM dd, yyyy');

    this.twodbettodate = new Date();  //add this
    this.twodbettodaytodate = this.datepipe.transform(this.twodbetdate, 'MMM dd, yyyy');

    this.twodwintodate = new Date();  //add this
    this.twodwintodaytodate = this.datepipe.transform(this.twodwintodate, 'MMM dd, yyyy');

    this.topuptodate = new Date();  //add this
    this.topuptodaytodate = this.datepipe.transform(this.topuptodate, 'MMM dd, yyyy');

    this.withtodate = new Date();  //add this
    this.withtodaytodate = this.datepipe.transform(this.withtodate, 'MMM dd, yyyy');

    this.threedbetdate = new Date();  //add this
    this.threedbettodaydate = this.datepipe.transform(this.threedbetdate, 'MMM dd, yyyy');

    this.threedbettodate = new Date();  //add this
    this.threedbettodaytodate = this.datepipe.transform(this.threedbettodate, 'MMM dd, yyyy');

    this.threedwindate = new Date();  //add this
    this.threedwintodayDate = this.datepipe.transform(this.threedwindate, 'MMM dd, yyyy');

    this.threedwintodate = new Date();  //add this
    this.threedwintodaytodate = this.datepipe.transform(this.threedwintodate, 'MMM dd, yyyy');

    this.gamewindate = new Date();  //add this
    this.gamewintodaydate = this.datepipe.transform(this.gamewindate, 'MMM dd, yyyy');

    this.gamewintodate = new Date();  //add this
    this.gamewintodaytodate = this.datepipe.transform(this.gamewintodate, 'MMM dd, yyyy');

    this.queenmakerdate = new Date();
    this.qmtodaydate = this.datepipe.transform(this.queenmakerdate, 'MMM dd, yyyy');
    this.queenmakertodate = new Date();
    this.qmtodaytodate = this.datepipe.transform(this.queenmakertodate, 'MMM dd, yyyy');

        //admk added for adjust 

        this.adjustfromdate = new Date();  //add this
        this.adjtodaydate = this.datepipe.transform(this.adjustfromdate, 'MMM dd, yyyy');
        this.adjusttodate = new Date();
        this.adjtodaytodate = this.datepipe.transform(this.adjusttodate, 'MMM dd, yyyy');
        
   }

  ngOnInit(): void {

    this.editUserDTO = {
      id: 0,    
      status: '',
      description:'',
      Risky : false
    };

    this.deletTranDto = {
      tranType: '',    
      tranId: 0,
      source:''
    };
    this.addTranDto = {
      type: '',
      amount: '',
      userId:0,
      remark:''
    };

    this.transactionDetailList =
    {
      transaction_no :'',
      amount_Str :'',
      created_date_Str :'',
      updated_date_Str:'',
      status :'',
      type :'',
      accountNumber:'',
      bankName :'',
      approvedBy :'',
      tran_from:'',
      isInsertByAdmin:''

    };

    this.addfacdto = {    
      userId: 0,
      payment_id:0,
      bank_acc_id:0
  
    };

    this.winnerDetail=
    {
       betAmount_Str :'',
       winAmount_Str :'',
       odd :'',
       status :'',
       created_date_Str:'',
       number :''
    };

    this.gameTranDetailList=
    {
       amount :'',
       referenceid :'',
       created_date :'',
       providerName:''
    }
    this.router.routeReuseStrategy.shouldReuseRoute = function () {
      return false;
    };

    this.router.events.subscribe((evt) => {
      if (evt instanceof NavigationEnd) {
        // trick the Router into believing it's last link wasn't previously loaded
        this.router.navigated = false;
        // if you need to scroll back to top, here is the right place
        window.scrollTo(0, 0);
      }
    });

    this.dtOptions = {
      responsive: true,
      order:[],
    };
    this.dtOptions.columnDefs = [
      //{ targets: [0], orderable: false }
    ];

    this.dtOptions1 = {
      responsive: true,
      order:[],
    };
    this.dtOptions1.columnDefs = [
      //{ targets: [0], orderable: false }
    ];

    this.dtOptions2 = {
      responsive: true,
      order:[],
    };
    this.dtOptions2.columnDefs = [
      //{ targets: [0], orderable: false }
    ];

    this.dtOptions3 = {
      responsive: true,
      order:[],
    };
    this.dtOptions3.columnDefs = [
      //{ targets: [0], orderable: false }
    ];

    this.dtOptions4 = {
      responsive: true,
      order:[],
    };
    this.dtOptions4.columnDefs = [
      //{ targets: [0], orderable: false }
    ];

    this.dtOptions5 = {
      responsive: true,
      order:[],
    };
    this.dtOptions5.columnDefs = [
      //{ targets: [0], orderable: false }
    ];

    this.dtOptions6 = {
      responsive: true,
      order:[],
    };
    this.dtOptions6.columnDefs = [
      //{ targets: [0], orderable: false }
    ];

    this.dtOptions7 = {
      responsive: true,
      order:[],
    };
    this.dtOptions7.columnDefs = [
      //{ targets: [0], orderable: false }
    ];

    this.dtOptions8 = {
      responsive: true,
      order:[],
    };
    this.dtOptions8.columnDefs = [
      //{ targets: [0], orderable: false }
    ];

    
    this.dtOptionswinlose = {
      responsive: true,
      order:[],
    };
    this.dtOptionswinlose.columnDefs = [
    
    ];

    
    this.dtOptions10 = {
      responsive: true,
      order:[],
    };
    this.dtOptions10.columnDefs = [
     
    ];

    if(!this.storage.retrieve('loadFlag')){
      this.storage.store('loadFlag', 'noLoad');
      setTimeout(function(){
        location.reload();
      }, 5);
    }
    else{
      this.storage.clear('loadFlag');
    }

    const adminDataString = localStorage.getItem('userData');
    this.originalAdminDTO = JSON.parse(adminDataString);
    
    this.userId = this.route.snapshot.paramMap.get("id");
    this.userDTO = {
      id: 0,
      phoneNo: '',
      name: '',
      adminId: 0,
      agentId: 0,
      balance: 0,
      referralCode: '',
      image: '',
      total_topup: '',
      total_withdraw: '',
      registerDate: '',
      registerBy: '',
      updatedDate: '',
      updatedBy: '',
      status: 'ACTIVE',
      Risky: false
    };
    
    this.getUserById();
    this.getActiveAgents();
   
  } //end init function

  ngAfterViewInit(){
  }

  onChangeSingle() {
      this.singleDate = $("#singleDate").val();
  }
   
  onChangeAllToDate() {
      this.alltoDate = $("#alltodate").val();
  }

  getActiveAgents() {
    this.token = this.storage.retrieve('token');
    let headers = new HttpHeaders();
    headers = headers.set('Authorization', this.token);
    this.http.get(this.funct.ipaddress + 'agent/GetActiveAgents', { headers: headers }).subscribe(
      result => {
        this.dto.Response = {};
        this.dto.Response = result;
        this.agentList = this.dto.Response;
      }
    );
  }
  getActiveAdmins() {
    this.token = this.storage.retrieve('token');
    let headers = new HttpHeaders();
    headers = headers.set('Authorization', 'ITW ' + this.token);
    this.http.get(this.funct.ipaddress + 'admin/active-admins', { headers: headers }).subscribe(
      result => {
        this.dto.Response = {};
        this.dto.Response = result;
        this.adminList = this.dto.Response.data.adminDTOList;
      }
    );
  }

  handleError(error: HttpErrorResponse){
    if(error.status == 423)
    {
      this.spinner.hide();
      //$("#deleteData").modal("show");
    }
    if(error.status == 403)
    {
      this.spinner.hide();
      var id1 = 'tblBank' + this.bankidIndex;
      var table1 = $('#' + id1).DataTable();
      var id = 'tblUser' + this.idIndex;
      var tranalltable = $('#' + id).DataTable();
     
      this.toastr.error("Limited Access.", 'Invalid!', {
        timeOut: 3000,
        positionClass: 'toast-top-right',
        });
    }
    if(error.status == 400)
    {
      this.spinner.hide();
      this.toastr.error("Bad Request.", 'Invalid!', {
        timeOut: 3000,
        positionClass: 'toast-top-right',
        });
    }
    return throwError(error);
    }
    OkLogout()
    {
      window.location.href ="./ad-login";
    } 

    getAllProvider()
    {
     this.dto.token = this.storage.retrieve('token');
     let headers = new HttpHeaders();
     headers = headers.set('Authorization',  this.dto.token);
     this.http.get(this.funct.ipaddress + 'gameProvider/getGameProviderList', {headers: headers })
     .pipe(
       catchError(this.handleError.bind(this))
      )
     .subscribe(
       result => {
         this.dto.Response = {};
         this.dto.Response = result;
         this.gameproviderList = this.dto.Response.filter(data => data.name != 'FISH');
         this.gameproviderList.push("None");
       });
    }
   
    flagProvider()
    {
        this.providerId = $("#providerId").val();
    }

    flagProvider1()
    {
        this.providerId1 = $("#providerId1").val();
    }

  // getUserbankInfo()
  // {
  //   this.spinner.show();
  //   var id1 = 'tblBank' + this.bankidIndex;
  //   var table1 = $('#' + id1).DataTable();
  //   table1.destroy();

  //   var id = 'tblUser' + this.idIndex;
  //   var tranalltable = $('#' + id).DataTable();
  //   tranalltable.destroy();

  //   this.tempUserBankInfoDTOList = [];
  //   this.token = this.storage.retrieve('token');
  //   let headers = new HttpHeaders();
  //   headers = headers.set('Authorization', this.token);
  //   let params = new HttpParams();
  //   params = params.set('userId', this.userId);
  //   this.http.get(this.funct.ipaddress + 'userbankaccount/getuserbankinfo-details-by-params', { params: params, headers: headers })
  //   .pipe(
  //     catchError(this.handleError.bind(this))
  //    )
  //   .subscribe(
  //     result => {
  //       this.dto.Response = {};
  //       this.dto.Response = result;
  //       var img;
  //       var qrimg;
  //       this.tempUserBankInfoDTOList = this.dto.Response;
  //       for(var i = 0 ; i < this.tempUserBankInfoDTOList.length ; i++)
  //       {
  //        img = this.tempUserBankInfoDTOList[i].imageUrl;
  //        if(img != null)
  //        {
  //         let objectURL =  img;
  //         this.bankimgURL = this.sanitizer.bypassSecurityTrustUrl(objectURL);
  //         this.tempUserBankInfoDTOList[i].banklogo = this.bankimgURL;
  //        }
  //        qrimg = this.tempUserBankInfoDTOList[i].qrImageUrl;
  //        if(qrimg != null)
  //        {
  //         let qrobjectURL =  qrimg;
  //         this.qrImageURL = this.sanitizer.bypassSecurityTrustUrl(qrobjectURL);
  //         this.tempUserBankInfoDTOList[i].qrImagelogo = this.qrImageURL;
  //        }
  //       }
  //       this.dtTrigger1.next();
  //       this.spinner.hide();
  //     }
  //   );
  // }

  

  getTransactionAllByParams(staticfromDate)
  {
    this.walletHistoryDTOLit = [];
    this.spinner.show();
    this.token = this.storage.retrieve('token');
    let headers = new HttpHeaders();
    headers = headers.set('Authorization', this.token);
    let params = new HttpParams();
    var id = 'tblUser' + this.idIndex;
    var tranalltable = $('#' + id).DataTable();
    tranalltable.destroy();
    this.idIndex = this.idIndex+1;
    if (this.singleDate == '' || this.singleDate == undefined) {
      this.allchangeDate = this.alltodayDate;
    }
    else 
    {
       this.allchangeDate = this.singleDate;
    }
    if (this.alltoDate == '' || this.alltoDate == undefined) {
      this.alltodatechangeDate = this.alltodaytodate;
    }
    else {
      this.alltodatechangeDate = this.alltoDate;
    }   
    var d1 = new Date(staticfromDate);
    var d2 = new Date(this.alltodatechangeDate);
    
    params = params.set('userId', this.userId).set("fromDate",staticfromDate).set("toDate",this.alltodatechangeDate);
    this.http.get(this.funct.ipaddress + 'userbankaccount/getusertransactionlog-by-params', { params: params, headers: headers })
    .pipe(
      catchError(this.handleError.bind(this))
     )
    .subscribe(
      result => {
        this.dto.Response = {};
        this.dto.Response = result;
        this.walletHistoryDTOLit = this.dto.Response;
        var editWallet = 0, noteqamount = 0;
        for( var i=this.walletHistoryDTOLit.length - 1; i>=0; i--){
          if(this.walletHistoryDTOLit[i].cashIn == 'Topup') 
          {
            this.isInsertByAdmin = this.walletHistoryDTOLit[i].isInsertByAdmin;
             if(this.isInsertByAdmin ==  "True") 
             {
                  if(editWallet == 0)
                  {
                    editWallet = parseInt(this.walletHistoryDTOLit[i].wallet);
                    this.walletHistoryDTOLit[i].wallet = new Intl.NumberFormat().format(editWallet);
                    this.walletHistoryDTOLit[i].amount = new Intl.NumberFormat().format(editWallet);
                    this.walletHistoryDTOLit[i].cashIn ="Adjust";
                  }
                  else
                  {
                    editWallet = editWallet + parseInt(this.walletHistoryDTOLit[i].wallet);
                    noteqamount = parseInt(this.walletHistoryDTOLit[i].wallet);
                    this.walletHistoryDTOLit[i].wallet = new Intl.NumberFormat().format(editWallet);
                    this.walletHistoryDTOLit[i].amount = new Intl.NumberFormat().format(noteqamount);
                    this.walletHistoryDTOLit[i].cashIn ="Adjust";
                  }
              
             }
             else
             {
              
                if(editWallet == 0)
                {
                  editWallet = parseInt(this.walletHistoryDTOLit[i].wallet);
                  this.walletHistoryDTOLit[i].wallet = new Intl.NumberFormat().format(editWallet);
                  this.walletHistoryDTOLit[i].amount = new Intl.NumberFormat().format(editWallet);
                }
                else
                {
                  editWallet = editWallet + parseInt(this.walletHistoryDTOLit[i].wallet);
                  noteqamount = parseInt(this.walletHistoryDTOLit[i].wallet);
                  this.walletHistoryDTOLit[i].wallet = new Intl.NumberFormat().format(editWallet);
                  this.walletHistoryDTOLit[i].amount = new Intl.NumberFormat().format(noteqamount);
                }
               
             }
           
          }
            if(this.walletHistoryDTOLit[i].cashIn == 'Game')
            {
              this.isInsertByAdmin = this.walletHistoryDTOLit[i].isInsertByAdmin;
              if(this.isInsertByAdmin == "True") 
              {
                  if(editWallet == 0)
                  {
                    editWallet = parseInt(this.walletHistoryDTOLit[i].wallet);
                    this.walletHistoryDTOLit[i].wallet = new Intl.NumberFormat().format(editWallet);
                    this.walletHistoryDTOLit[i].amount = new Intl.NumberFormat().format(editWallet);
                    this.walletHistoryDTOLit[i].cashIn ="Adjusts";
                  }
                  else{
                    editWallet = editWallet + parseInt(this.walletHistoryDTOLit[i].wallet);
                    noteqamount = parseInt(this.walletHistoryDTOLit[i].wallet);
                    this.walletHistoryDTOLit[i].wallet = new Intl.NumberFormat().format(editWallet);
                    this.walletHistoryDTOLit[i].amount = new Intl.NumberFormat().format(noteqamount);
                    this.walletHistoryDTOLit[i].cashIn ="Adjusts";
                  }
                 
              }
              else
              {
                  if(editWallet == 0)
                  {
                    editWallet = parseInt(this.walletHistoryDTOLit[i].wallet);
                    this.walletHistoryDTOLit[i].wallet = new Intl.NumberFormat().format(editWallet);
                    this.walletHistoryDTOLit[i].amount = new Intl.NumberFormat().format(editWallet);
                  }
                  else{
                    editWallet = editWallet + parseInt(this.walletHistoryDTOLit[i].wallet);
                    noteqamount = parseInt(this.walletHistoryDTOLit[i].wallet);
                    this.walletHistoryDTOLit[i].wallet = new Intl.NumberFormat().format(editWallet);
                    this.walletHistoryDTOLit[i].amount = new Intl.NumberFormat().format(noteqamount);
                  }

              }
               
            }

              if(this.walletHistoryDTOLit[i].cashIn == '2D Win')
              {
                  editWallet = editWallet + parseInt(this.walletHistoryDTOLit[i].amount);
                  this.walletHistoryDTOLit[i].wallet = new Intl.NumberFormat().format(editWallet);
                  this.walletHistoryDTOLit[i].amount = new Intl.NumberFormat().format(this.walletHistoryDTOLit[i].amount);
              }
              if(this.walletHistoryDTOLit[i].cashIn == '3D Win')
              {
                  editWallet =  editWallet + parseInt(this.walletHistoryDTOLit[i].amount);
                  this.walletHistoryDTOLit[i].wallet = new Intl.NumberFormat().format(editWallet);
                  this.walletHistoryDTOLit[i].amount = new Intl.NumberFormat().format(this.walletHistoryDTOLit[i].amount);
              }

              if(this.walletHistoryDTOLit[i].cashIn == 'Point')
              {
                  editWallet = editWallet + parseInt(this.walletHistoryDTOLit[i].amount);
                  this.walletHistoryDTOLit[i].wallet = new Intl.NumberFormat().format(editWallet);
                  this.walletHistoryDTOLit[i].amount = new Intl.NumberFormat().format(this.walletHistoryDTOLit[i].amount);
              }

              if(this.walletHistoryDTOLit[i].cashOut == '2D Bet')
              {
                    if(this.walletHistoryDTOLit[i].discountAmt != null)
                    {
                      editWallet =  editWallet - parseInt(this.walletHistoryDTOLit[i].discountAmt);
                      this.walletHistoryDTOLit[i].wallet = new Intl.NumberFormat().format(editWallet);
                      this.walletHistoryDTOLit[i].amount = new Intl.NumberFormat().format(this.walletHistoryDTOLit[i].discountAmt);
                    }
                    else{
                    editWallet =  editWallet - parseInt(this.walletHistoryDTOLit[i].amount);
                    this.walletHistoryDTOLit[i].wallet = new Intl.NumberFormat().format(editWallet);
                    this.walletHistoryDTOLit[i].amount = new Intl.NumberFormat().format(this.walletHistoryDTOLit[i].amount);
                    } 
              }
              if(this.walletHistoryDTOLit[i].cashOut == '3D Bet')
              {
                    if(this.walletHistoryDTOLit[i].discountAmt != null)
                    {
                    editWallet =  editWallet - parseInt(this.walletHistoryDTOLit[i].discountAmt);
                    this.walletHistoryDTOLit[i].wallet = new Intl.NumberFormat().format(editWallet);
                    this.walletHistoryDTOLit[i].amount = new Intl.NumberFormat().format(this.walletHistoryDTOLit[i].discountAmt);
                    }
                    else
                    {
                    editWallet =  editWallet - parseInt(this.walletHistoryDTOLit[i].amount);
                    this.walletHistoryDTOLit[i].wallet = new Intl.NumberFormat().format(editWallet);
                    this.walletHistoryDTOLit[i].amount = new Intl.NumberFormat().format(this.walletHistoryDTOLit[i].amount);
                    }
              }
              if(this.walletHistoryDTOLit[i].cashOut == 'Withdrawal')
              {
                  this.isInsertByAdmin = this.walletHistoryDTOLit[i].isInsertByAdmin;
                  if(this.isInsertByAdmin ==  "True") 
                  {
                    editWallet =  editWallet - parseInt(this.walletHistoryDTOLit[i].amount);
                    this.walletHistoryDTOLit[i].wallet = new Intl.NumberFormat().format(editWallet);
                    this.walletHistoryDTOLit[i].amount = new Intl.NumberFormat().format(this.walletHistoryDTOLit[i].amount);
                    this.walletHistoryDTOLit[i].cashOut ="Adjust";
                  }
                  else
                  {                      
                      editWallet =  editWallet - parseInt(this.walletHistoryDTOLit[i].amount);
                      this.walletHistoryDTOLit[i].wallet = new Intl.NumberFormat().format(editWallet);
                      this.walletHistoryDTOLit[i].amount = new Intl.NumberFormat().format(this.walletHistoryDTOLit[i].amount);
                  }
          
              }
              if(this.walletHistoryDTOLit[i].cashOut == 'GAME-DEPOSIT')
              {
                  this.isInsertByAdmin = this.walletHistoryDTOLit[i].isInsertByAdmin;
                  if(this.isInsertByAdmin ==  "True")
                  {
                    editWallet =  editWallet - parseInt(this.walletHistoryDTOLit[i].amount);
                    this.walletHistoryDTOLit[i].wallet = new Intl.NumberFormat().format(editWallet);
                    this.walletHistoryDTOLit[i].amount = new Intl.NumberFormat().format(this.walletHistoryDTOLit[i].amount);
                    this.walletHistoryDTOLit[i].cashOut ="Adjusts"
                  }
                  else
                  {
                    editWallet =  editWallet - parseInt(this.walletHistoryDTOLit[i].amount);
                    this.walletHistoryDTOLit[i].wallet = new Intl.NumberFormat().format(editWallet);
                    this.walletHistoryDTOLit[i].amount = new Intl.NumberFormat().format(this.walletHistoryDTOLit[i].amount);                    
               
                  }              
              }  
              
              //AMK FOR FREEZE
              if(this.walletHistoryDTOLit[i].cashOut == 'Freeze')
              {
                  this.isInsertByAdmin = this.walletHistoryDTOLit[i].isInsertByAdmin;
                  if(this.isInsertByAdmin ==  "True")
                  {
                    editWallet =  editWallet - parseInt(this.walletHistoryDTOLit[i].amount);
                    this.walletHistoryDTOLit[i].wallet = new Intl.NumberFormat().format(editWallet);
                    this.walletHistoryDTOLit[i].amount = new Intl.NumberFormat().format(this.walletHistoryDTOLit[i].amount);
                    this.walletHistoryDTOLit[i].cashOut ="Adjusts"
                  }
                  else
                  {
                    editWallet =  editWallet - parseInt(this.walletHistoryDTOLit[i].amount);
                    this.walletHistoryDTOLit[i].wallet = new Intl.NumberFormat().format(editWallet);
                    this.walletHistoryDTOLit[i].amount = new Intl.NumberFormat().format(this.walletHistoryDTOLit[i].amount);                    
               
                  }              
              }

          }
        this.dtTrigger.next();
        this.spinner.hide();
      }
    );
  }
  
   /*transaction detail add this 2021-11-24 -- get detail*/
   getDetail(ID)
   {
     this.spinner.show();
     this.transactionDetailList = [];
     this.token = this.storage.retrieve('token');
     let headers = new HttpHeaders();
     headers = headers.set('Authorization', this.token);
     let params = new HttpParams();
     console.log("Trnasaction : not clicked" +ID);
      params = params.set('tranId', ID);
     this.http.get(this.funct.ipaddress + 'transaction/GetTransactionDetailList', { params: params, headers: headers }).subscribe(
       result => {
         this.dto.Response = {};
         this.dto.Response = result;
         this.transactionDetailList = this.dto.Response;
         console.log("Transaction detail "+JSON.stringify(this.transactionDetailList))
         this.spinner.hide();
         $('#browseAccountData2').modal("show");
       });
   }

   getDetail1(ID)
   {
     this.spinner.show();
     this.transactionDetailList = [];
     this.token = this.storage.retrieve('token');
     let headers = new HttpHeaders();
     headers = headers.set('Authorization', this.token);
     let params = new HttpParams();
     console.log("Trnasaction : not clicked");
      params = params.set('tranId', ID);
    this.http.get(this.funct.ipaddress + 'transaction/GetTransactionDetailList', { params: params, headers: headers }).subscribe(
       result => {
         this.dto.Response = {};
         this.dto.Response = result;
         this.transactionDetailList = this.dto.Response;
         this.spinner.hide();
         $('#browseAccountData3').modal("show");
       }); 
   }

   
   getDetailForFreeze(ID)
   {
     this.spinner.show();
     this.transactionDetailList = [];
     this.token = this.storage.retrieve('token');
     let headers = new HttpHeaders();
     headers = headers.set('Authorization', this.token);
     let params = new HttpParams();
     console.log("Trnasaction : not clicked" +ID);
      params = params.set('tranId', ID);
     this.http.get(this.funct.ipaddress + 'transaction/GetTransactionDetailList', { params: params, headers: headers }).subscribe(
       result => {
         this.dto.Response = {};
         this.dto.Response = result;
         this.transactionDetailList = this.dto.Response;
         console.log("Transaction detail "+JSON.stringify(this.transactionDetailList))
         this.spinner.hide();
         $('#browseAccountDataForFreeze').modal("show");
       });
   }

   getTwodWinnerDetail(id)
   {
    this.spinner.show();
    this.transactionDetailList = [];
    this.token = this.storage.retrieve('token');
    let headers = new HttpHeaders();
    headers = headers.set('Authorization', this.token);
    let params = new HttpParams();
     params = params.set('id', id);
    this.http.get(this.funct.ipaddress + 'winner/Get2DWinnerDetailList', { params: params, headers: headers }).subscribe(
      result => {
        this.dto.Response = {};
        this.dto.Response = result;
        this.winnerDetail = this.dto.Response;
        this.spinner.hide();
        $('#browseAccountData4').modal("show");
      }); 
   }

   getThreedWinnerDetail(id)
   {
    this.spinner.show();
    this.transactionDetailList = [];
    this.token = this.storage.retrieve('token');
    let headers = new HttpHeaders();
    headers = headers.set('Authorization', this.token);
    let params = new HttpParams();
     params = params.set('id', id);
    this.http.get(this.funct.ipaddress + 'winner/Get3DWinnerDetailList', { params: params, headers: headers }).subscribe(
      result => {
        this.dto.Response = {};
        this.dto.Response = result;
        this.winnerDetail = this.dto.Response;
        this.spinner.hide();
        $('#browseAccountData5').modal("show");
      }); 
   }

   getGameTranDetail(id,source)
   {
    this.spinner.show();
    this.token = this.storage.retrieve('token');
    let headers = new HttpHeaders();
    headers = headers.set('Authorization', this.token);
    let params = new HttpParams();
    params = params.set('tranId', id).set('source',source);
    this.http.get(this.funct.ipaddress + 'loginGS/GetGameCashFlowDetailList', { params: params, headers: headers }).subscribe(
      result => {
        this.dto.Response = {};
        this.dto.Response = result;
        this.gameTranDetailList = this.dto.Response;
        this.spinner.hide();
        $('#browseAccountData6').modal("show");
      }); 
   }

  getTwodbetHistoryByParams()
  {
    this.spinner.show();
    this.token = this.storage.retrieve('token');
    let headers = new HttpHeaders();
    headers = headers.set('Authorization',  this.token);
    let params = new HttpParams();
    
    var id = 'tbl2dbet' + this.idtwodindex;
    var twobettable = $('#' + id).DataTable();
    twobettable.destroy();

    this.tempBetHistoryDTOList = [];

    if (this.twodbetDate == '' || this.twodbetDate == undefined) {
      this.twodbetchangeDate = this.twodbettodayDate;
    }
    else 
    {
       this.twodbetchangeDate = this.twodbetDate;
    }
    if (this.twodbettoDate == '' || this.twodbettoDate == undefined) {
      this.twodbettodatechangeDate = this.twodbettodaytodate;
    }
    else 
    {
       this.twodbettodatechangeDate = this.twodbettoDate;
    }
    params = params.set('userId', this.userId).set("fromDate",this.twodbetchangeDate).set("toDate",this.twodbettodatechangeDate);//.set("betamount",this.amount).set("currentwallet",this.wallet);
    this.http.get(this.funct.ipaddress + 'userbankaccount/getuser2dbetlog-by-params', { params: params, headers: headers })
    .pipe(
      catchError(this.handleError.bind(this))
     )
    .subscribe(
      result => {
        this.dto.Response = {};
        this.dto.Response = result;
        this.tempBetHistoryDTOList = this.dto.Response;
        var editWallet = 0;
        for( var i=this.tempBetHistoryDTOList.length - 1; i>=0; i--){
             // if(editWallet == 0)
           //   {
                editWallet = parseInt(this.tempBetHistoryDTOList[i].wallet);
                this.tempBetHistoryDTOList[i].wallet = new Intl.NumberFormat().format(editWallet);
                this.tempBetHistoryDTOList[i].amount = new Intl.NumberFormat().format(this.tempBetHistoryDTOList[i].amount);
            //  }
            /*  else
              {
              editWallet = editWallet - parseInt(this.tempBetHistoryDTOList[i].amount);
              this.tempBetHistoryDTOList[i].wallet = new Intl.NumberFormat().format(editWallet);
              this.tempBetHistoryDTOList[i].amount = new Intl.NumberFormat().format(this.tempBetHistoryDTOList[i].amount);
              }*/
          }
        this.dtTrigger2.next();
        this.spinner.hide();
      }
    );
  }
  getThreedbetHistoryByParams()
  {
    this.spinner.show();
    this.token = this.storage.retrieve('token');
    let headers = new HttpHeaders();
    headers = headers.set('Authorization',  this.token);
    let params = new HttpParams();
    this.tempBetHistoryDTOList1 = [];
    var id = 'tblthreedbet' + this.idthreedbetIndex;
    var threedbettable = $('#' + id).DataTable();
    threedbettable.destroy();
    if (this.threedbetDate == '' || this.threedbetDate == undefined) {
      this.threedbetchangeDate = this.threedbettodaydate;
    }
    else 
    {
       this.threedbetchangeDate = this.threedbetDate;
    }
    if (this.threedbettoDate == '' || this.threedbettoDate == undefined) {
      this.threedbettodatechangeDate = this.threedbettodaytodate;
    }
    else 
    {
       this.threedbettodatechangeDate = this.threedbettoDate;
    }
   
    params = params.set('userId', this.userId).set("fromDate",this.threedbetchangeDate).set("toDate",this.threedbettodatechangeDate);
    this.http.get(this.funct.ipaddress + 'userbankaccount/getuser3dbetlog-by-params', { params: params, headers: headers })
    .pipe(
      catchError(this.handleError.bind(this))
     )
    .subscribe(
      result => {
        this.dto.Response = {};
        this.dto.Response = result;
        this.tempBetHistoryDTOList1 = this.dto.Response;
        var editWallet = 0;
        for( var i=this.tempBetHistoryDTOList1.length - 1; i>=0; i--){
            //  if(editWallet == 0)
             // {
                editWallet = parseInt(this.tempBetHistoryDTOList1[i].wallet);
                this.tempBetHistoryDTOList1[i].wallet = new Intl.NumberFormat().format(editWallet);
                this.tempBetHistoryDTOList1[i].amount = new Intl.NumberFormat().format(this.tempBetHistoryDTOList1[i].amount);
            //  }
             /* else
              {
              editWallet = editWallet - parseInt(this.tempBetHistoryDTOList1[i].amount);
              this.tempBetHistoryDTOList1[i].wallet = new Intl.NumberFormat().format(editWallet);
              this.tempBetHistoryDTOList1[i].amount = new Intl.NumberFormat().format(this.tempBetHistoryDTOList1[i].amount);
              }*/
          }
        this.dtTrigger6.next();//threedwin
        this.spinner.hide();
      }
    );
  }
  getTopupList()
  {
    this.spinner.show();
    this.token = this.storage.retrieve('token');
    let headers = new HttpHeaders();
    headers = headers.set('Authorization',  this.token);
    let params = new HttpParams();
    this.tempFinancialTransactionDTOList = [];
    var id = 'tbltopup' + this.idtopupIndex;
    var topuptable = $('#' + id).DataTable();
    topuptable.destroy();
    if (this.topupDate == '' || this.topupDate == undefined) {
      this.topupchangeDate = this.topuptodayDate;
    }
    else 
    {
       this.topupchangeDate = this.topupDate;
    }
    if (this.topuptoDate == '' || this.topuptoDate == undefined) {
      this.topuptodatechangeDate = this.topuptodaytodate;
    }
    else 
    {
       this.topuptodatechangeDate = this.topuptoDate;
    }
    params = params.set('userId', this.userId).set("fromDate",this.topupchangeDate).set("toDate",this.topuptodatechangeDate);//.set("amount",this.amount).set("walletAmount",this.wallet).set("transactionNo",this.topuptran);
    this.http.get(this.funct.ipaddress + 'userbankaccount/getusertopuplog-by-params', { params: params, headers: headers })
    .pipe(
      catchError(this.handleError.bind(this))
     )
    .subscribe(
      result => {
        this.dto.Response = {};
        this.dto.Response = result;
        this.tempFinancialTransactionDTOList = this.dto.Response;
        var editWallet = 0;
        for( var i=this.tempFinancialTransactionDTOList.length - 1; i>=0; i--){
           
                editWallet = parseInt(this.tempFinancialTransactionDTOList[i].wallet);
                this.tempFinancialTransactionDTOList[i].wallet = new Intl.NumberFormat().format(editWallet);
                this.tempFinancialTransactionDTOList[i].amount = new Intl.NumberFormat().format(this.tempFinancialTransactionDTOList[i].amount);
           
            
          }
        this.dtTrigger4.next();
        this.spinner.hide();
      }
    );
  }
  getWithdrawalList()
  {
    this.spinner.show();
    this.token = this.storage.retrieve('token');
    let headers = new HttpHeaders();
    headers = headers.set('Authorization', this.token);
    let params = new HttpParams();
    this.tempFinancialTransactionDTOList1 = [];
    var id = 'tblwithdrawal' + this.idwithdrawalIndex;
    var withtable = $('#' + id).DataTable();
    withtable.destroy();
    if (this.withDate == '' || this.withDate == undefined) {
      this.withchangeDate = this.withtodayDate;
    }
    else 
    {
       this.withchangeDate = this.withDate;
    }
    if (this.withtoDate == '' || this.withtoDate == undefined) {
      this.withtodatechangeDate = this.withtodaytodate;
    }
    else 
    {
       this.withtodatechangeDate = this.withtoDate;
    }
    params = params.set('userId', this.userId).set("fromDate",this.withchangeDate).set("toDate",this.withtodatechangeDate);//.set("amount",this.amount).set("walletAmount",this.wallet).set("transactionNo",this.withdrawalAcc);
    this.http.get(this.funct.ipaddress + 'userbankaccount/getuserwithdrawallog-by-params', { params: params, headers: headers })
    .pipe(
      catchError(this.handleError.bind(this))
     )
    .subscribe(
      result => {
        this.dto.Response = {};
        this.dto.Response = result;
        this.tempFinancialTransactionDTOList1 = this.dto.Response;
        var editWallet = 0;
        for( var i=this.tempFinancialTransactionDTOList1.length - 1; i>=0; i--){
            //  if(editWallet == 0)
             // {
                editWallet = parseInt(this.tempFinancialTransactionDTOList1[i].wallet);
                this.tempFinancialTransactionDTOList1[i].wallet = new Intl.NumberFormat().format(editWallet);
                this.tempFinancialTransactionDTOList1[i].amount = new Intl.NumberFormat().format(this.tempFinancialTransactionDTOList1[i].amount);
              //}
              //else
              //{
              //editWallet = editWallet - parseInt(this.tempFinancialTransactionDTOList1[i].amount);
             // this.tempFinancialTransactionDTOList1[i].wallet = new Intl.NumberFormat().format(editWallet);
              //this.tempFinancialTransactionDTOList1[i].amount = new Intl.NumberFormat().format(this.tempFinancialTransactionDTOList1[i].amount);
              //}
          }
        console.log("this.topupList >>"+this.tempFinancialTransactionDTOList1);
        this.dtTrigger5.next();
        this.spinner.hide();
      }
    );
  }
  getTwoDwinnerList()
  {
    this.spinner.show();
    this.token = this.storage.retrieve('token');
    let headers = new HttpHeaders();
    headers = headers.set('Authorization', this.token);
    let params = new HttpParams();
    this.tempWinnerDTOList = [];
    var id = 'tbltwodwin' + this.idtwodwinIndex;
    var twodwintable = $('#' + id).DataTable();
    twodwintable.destroy();
    if (this.twodwinDate == '' || this.twodwinDate == undefined) {
      this.twodwinchangeDate = this.twodwintodayDate;
    }
    else 
    {
       this.twodwinchangeDate = this.twodwinDate;
    }
    if (this.twodwintoDate == '' || this.twodwintoDate == undefined) {
      this.twodwintodatechangeDate = this.twodwintodaytodate;
    }
    else 
    {
       this.twodwintodatechangeDate = this.twodwintoDate;
    }
    params = params.set('userId', this.userId).set("fromDate",this.twodwinchangeDate).set("toDate",this.twodwintodatechangeDate);//.set("winamount",this.amount).set("betamount",this.wallet);
    this.http.get(this.funct.ipaddress + 'userbankaccount/getuser2dwinnerlog-by-params', { params: params, headers: headers })
    .pipe(
      catchError(this.handleError.bind(this))
     )
    .subscribe(
      result => {
        this.dto.Response = {};
        this.dto.Response = result;
        this.tempWinnerDTOList = this.dto.Response;
        var editWallet = 0;
        for( var i=this.tempWinnerDTOList.length - 1; i>=0; i--){
                editWallet = parseInt(this.tempWinnerDTOList[i].wallet);
                this.tempWinnerDTOList[i].wallet = new Intl.NumberFormat().format(editWallet);
                this.tempWinnerDTOList[i].amount = new Intl.NumberFormat().format(this.tempWinnerDTOList[i].amount);
                this.tempWinnerDTOList[i].bet_amount = new Intl.NumberFormat().format(this.tempWinnerDTOList[i].bet_amount);
          }
        this.dtTrigger3.next();//twodwin
        this.spinner.hide();
      }
    );
  }
  getThreeDwinnerList()
  {
    this.spinner.show();
    this.token = this.storage.retrieve('token');
    let headers = new HttpHeaders();
    headers = headers.set('Authorization', this.token);
    let params = new HttpParams();
    this.tempWinnerDTOList1 = [];
    var id = 'tblthreedwin' + this.idthreedwinIndex;
    var twodwintable = $('#' + id).DataTable();
    twodwintable.destroy();
      if (this.threedwinDate == '' || this.threedwinDate == undefined) {
        this.threedwinchangeDate = this.threedwintodayDate;
      }
      else 
      {
         this.threedwinchangeDate = this.threedwinDate;
      }
      if (this.threedwintoDate == '' || this.threedwintoDate == undefined) {
        this.threedwintodatechangeDate = this.threedwintodaytodate;
      }
      else 
      {
         this.threedwintodatechangeDate = this.threedwintoDate;
      }
    console.log("All from date change date is : "+this.threedwintodatechangeDate)
    params = params.set('userId', this.userId).set("fromDate",this.threedwinchangeDate).set("toDate",this.threedwintodatechangeDate);//.set("winamount",this.amount).set("betamount",this.wallet);
    this.http.get(this.funct.ipaddress + 'userbankaccount/getuser3dwinnerlog-by-params', { params: params, headers: headers })
    .pipe(
      catchError(this.handleError.bind(this))
     )
    .subscribe(
      result => {
        this.dto.Response = {};
        this.dto.Response = result;
        this.tempWinnerDTOList1 = this.dto.Response;
        var editWallet = 0;
        for( var i=this.tempWinnerDTOList1.length - 1; i>=0; i--){
            
                editWallet = parseInt(this.tempWinnerDTOList1[i].wallet);
                this.tempWinnerDTOList1[i].wallet = new Intl.NumberFormat().format(editWallet);
                this.tempWinnerDTOList1[i].amount = new Intl.NumberFormat().format(this.tempWinnerDTOList1[i].amount);
                this.tempWinnerDTOList1[i].bet_amount = new Intl.NumberFormat().format(this.tempWinnerDTOList1[i].bet_amount);
          }
        console.log("this.threedwinner >>"+this.tempWinnerDTOList1);
        this.dtTrigger7.next();
        this.spinner.hide();
      }
    );
  }

  getUserById() {
    this.spinner.show();
    var id1 = 'tblBank' + this.bankidIndex;
    var table1 = $('#' + id1).DataTable();
    table1.destroy();

    var id2 = 'tbl2dbet'+this.idtwodindex;
    var table2 = $("#"+id2).DataTable();
    table2.destroy();

    var id3 = 'tbltwodwin'+this.idtwodwinIndex;
    var table3 = $("#"+id3).DataTable();
    table3.destroy();

    var id4 = 'tbltopup'+this.idtopupIndex;
    var table4 = $("#"+id4).DataTable();
    table4.destroy();

    var id5 = 'tblwithdrawal'+this.idwithdrawalIndex;
    var table5 = $("#"+id5).DataTable();
    table5.destroy();

    var id6 = 'tblthreedbet'+this.idthreedbetIndex;
    var table6 = $("#"+id6).DataTable();
    table6.destroy();

    var id7 = 'tblthreedwin'+this.idthreedwinIndex;
    var table7 = $("#"+id7).DataTable();
    table7.destroy();

    var id8 = 'tblgame'+this.idgameIndex;
    var table8 = $("#"+id8).DataTable();
    table8.destroy();

    var id9 = 'tblgamewin'+this.idgamewinIndex;
    var table9 = $("#"+id9).DataTable();
    table9.destroy();

    var id10 = 'tblbonus'+this.idbonusIndex;
    var table10 = $("#"+id10).DataTable();
    table10.destroy();

    var id11 = 'tblgamewallet' + this.idgamewallet;
    var table11 = $('#' + id11).DataTable();
    table11.destroy();

    this.token = this.storage.retrieve('token');
    let headers = new HttpHeaders();
    headers = headers.set('Authorization',  this.token);
    let params = new HttpParams();
    params = params.set('id', this.userId);
    this.http.get(this.funct.ipaddress + 'user/DetailsList_Noblance', { params: params, headers: headers })
    .pipe(
      catchError(this.handleError.bind(this))
     )
    .subscribe(
      result => {
        this.dto.Response = {};
          this.dto.Response = result;
          this.userDTO = this.dto.Response;
          this.agentId = this.userDTO.agent_id;
          this.allTranFromDate = this.userDTO.created_date;      
          this.alldate = new Date(this.userDTO.created_date); 
          this.adjustfromdate = new Date(this.userDTO.created_date);//amk
          this.adminId = this.userDTO.admin_id;
          this.statusforEdit = this.userDTO.status;
          if(this.userDTO.gs_game_deposit_balance != null)
          {
            this.userDTO.gs_game_deposit_balance = new Intl.NumberFormat().format(this.userDTO.gs_game_deposit_balance);
          }
          if(this.userDTO.gs_game_withdrawal_balance != null)
          {
            this.userDTO.gs_game_withdrawal_balance = new Intl.NumberFormat().format(this.userDTO.gs_game_withdrawal_balance);
          }
          // if (this.userDTO.imageUrl != null) {
          //   let objectURL =  this.userDTO.imageUrl;
          //   this.imgURL = this.sanitizer.bypassSecurityTrustUrl(objectURL);
          //   this.isProfile = true;
          // }
          // else
          // {
          //   this.isProfile = false;
          // }

          if (this.userDTO.imageUrl != null && this.userDTO.imageUrl !== "") {
            let objectURL =  this.userDTO.imageUrl;
            this.imgURL = this.sanitizer.bypassSecurityTrustUrl(objectURL);
            this.isProfile = true;
        } else {
            this.isProfile = false;
        }
        
          // if(this.userDTO.twodbetlimit != null)
          //    this.userDTO.twodbetlimit  = new Intl.NumberFormat().format(this.userDTO.twodbetlimit);
          // if(this.userDTO.threedbetlimit != null)
          //    this.userDTO.threedbetlimit  = new Intl.NumberFormat().format(this.userDTO.threedbetlimit);

          if(this.userDTO.description != null)
          {
            this.userDTO.description =this.userDTO.description;
          }
             if(this.userDTO.gameWalletModel != null)
             {
               this.gameWalletModel_temp = JSON.stringify(this.userDTO.gameWalletModel);
               this.dtTriggergamewallet.next();
             }
           this.spinner.hide();
      }
    );
  }

  transform(html) {
    return this.sanitizer.bypassSecurityTrustHtml(html);
  }

  goSave() {
    this.edit();
  }

  goCancel() {
    this.editCancel();
  }

  edit() {
      this.spinner.show();
      this.token = this.storage.retrieve('token');
      // let headers = new HttpHeaders();
      // headers = headers.set('Authorization', this.token); 

      	
      let editedArray = this.getEditedArray();   
      let headers = new HttpHeaders();

      if(editedArray.length > 0)
      {
        headers = headers.set('Authorization', this.token)
        .set('editedArray', editedArray); 
      }
      else{
        headers = headers.set('Authorization',  this.token);
      }
      this.editUserDTO.Risky = this.userDTO.isRisky;
      this.editUserDTO.id = this.userId;
      this.editUserDTO.status = this.userDTO.status;
      this.editUserDTO.description = this.userDTO.description;
      this.http.post(this.funct.ipaddress + 'user/edit', this.editUserDTO, { headers: headers })
      .pipe(
        catchError(this.handleError.bind(this))
       )
      .subscribe(
        result => {
          this.dto.Response = {};
          this.dto.Response = result;
          if (this.dto.Response.status == 'Success') {
            this.spinner.hide();
            this.router.navigate(['/user-detail', this.userDTO.id]).then(() => {
              this.toastr.success(this.dto.Response.message, 'Success!', {
                timeOut: 3000,
                positionClass: 'toast-top-right'
              });
            })
          }
          else {
            this.spinner.hide();
            this.toastr.error(this.dto.Response.message, 'Invalid!', {
              timeOut: 3000,
              positionClass: 'toast-top-right',
            });
          }
        }
      );
  }


  //amk
  getUserBalances(userId)
  {
    this.spinner.show();
    this.token = this.storage.retrieve('token');
    let headers = new HttpHeaders();
    headers = headers.set('Authorization', this.token);
    let params = new HttpParams();
     params = params.set('userId', userId);
    this.http.get(this.funct.ipaddress + 'topupWithdrawalAdjust/getUserBalances', { params: params, headers: headers }).subscribe(
      result => {
        this.dto.Response = {};
        this.dto.Response = result;
        this.userbalances = this.dto.Response;
        this.spinner.hide();
      });
  }
  showAddTranModel(type)
  {
    this.addTranType = type;
    $('#browseAccountData9').modal("show");
  }
  addTran(addAmount,addRemark)
  {
    this.token = this.storage.retrieve('token');
    let headers = new HttpHeaders();
    headers = headers.set('Authorization', this.token);
    this.addTranDto.type = this.addTranType;
    const amountValue = this.addTranAmt; 
    this.addTranDto.amount = addAmount;
    this.addTranDto.remark = addRemark;
    this.addTranDto.userId = this.userId;
    this.http.post(this.funct.ipaddress + 'topupWithdrawalAdjust/AddTransaction', this.addTranDto,{ headers: headers })
    .pipe(
      catchError(this.handleError.bind(this))
     )
    .subscribe(
      result => {
        this.dto.Response = {};
        this.dto.Response = result;
        if(this.dto.Response.status == 'Success')
        {
        //this.router.navigate(['/user-detail', this.userId,'u']).then(() => {
          this.router.navigate(['/user-detail', this.userId]).then(() => {
          this.toastr.success(this.dto.Response.message, 'Success!', {
            timeOut: 3000,
            positionClass: 'toast -top-right'
          });
        })
         }
      $('#browseAccountData9').modal("hide");
      }
    );
    $('#browseAccountData9').modal("hide");
  }
  deletTran()//id, tranType
  {
    this.token = this.storage.retrieve('token');
    let headers = new HttpHeaders();
    headers = headers.set('Authorization', this.token);
    this.deletTranDto.tranType = this.trantype;
    this.deletTranDto.tranId = this.deleteId;
    this.deletTranDto.source = this.source;
    this.http.post(this.funct.ipaddress + 'topupWithdrawalAdjust/DeleteTransaction', this.deletTranDto,{ headers: headers })
    .pipe(
      catchError(this.handleError.bind(this))
     )
    .subscribe(
      result => {
        this.dto.Response = {};
        this.dto.Response = result;
        if(this.dto.Response.status == 'Success')
        {       
          this.router.navigate(['/user-detail', this.userId]).then(() => {
          this.toastr.success(this.dto.Response.message, 'Success!', {
            timeOut: 3000,
            positionClass: 'toast -top-right'
          });
        })
         }
      }
    );
  }
  //
  
  flagAdmin(){
    this.agentId = null;
  }

  flagAgent(){
    this.adminId = null;
  }

  editCancel() {
    this.getUserById();
  }
  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
    this.dtTrigger1.unsubscribe();
  }

  preview(files) {
    if (files.length === 0)
      return;
    var mimeType = files[0].type;
    if (mimeType.match(/image\/*/) == null) {
      this.message = "Only images are supported.";
      return;
    }
    var reader = new FileReader();
    this.imagePath = files;
    reader.readAsDataURL(files[0]);
    reader.onload = (_event) => {
      this.imgURL = reader.result;
    //  console.log("imgURL>>>" + JSON.stringify(this.imgURL));
    }
  }

  onChangeTwodbet() {
      this.twodbetDate = $("#twodbetDate").val();
  }
  onChangeTwodbetToDate()
  {
      this.twodbettoDate = $("#twodbettoDate").val();
  }

  onChangeTwodWin() {
    
      this.twodwinDate = $("#towdwinDate").val();
  }

  onChangeTwoDwinToDate()
  {
      this.twodwintoDate = $("#twodwintodate").val();
  }

  onChangeThreedWin() {
    
    this.threedwinDate = $("#threedwinDate").val();
}

onChangeThreeDwinToDate()
{
    this.threedwintoDate = $("#threeedwintodate").val();
    console.log('Threed win to date in onchane : '+this.threedwintoDate)
}


  onChangeTopup() {
      this.topupDate = $("#topupDate").val();
  }

  onChangeTopupToDate()
  {
      this.topuptoDate = $("#topuptodate").val();
  }

  onChangeWith() {
      this.withDate = $("#withDate").val();
  }
  onChangeWithToDate()
  {
      this.withtoDate = $("#withtoDate").val();
  }

 
  onChangeThreeDBetDate()
  {
    this.threedbetDate = $("#threedbetDate").val();
  }
  onChangethreedbetToDate()
  {
    this.threedbettoDate = $("#threedbettodate").val();
  }
  numericOnly(event): boolean { 
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode == 101 || charCode == 69 || charCode == 45 || charCode == 43 || charCode == 46) {
      return false;
    }
    return true;

  }

  goModal(id){
    var id1 = 'tblthreedbetdetail' + this.idthreeddetailIndex;
    var threeddetailtb = $('#' + id1).DataTable();
    threeddetailtb.destroy();
    this.idthreeddetailIndex = this.idthreeddetailIndex +1;

    this.spinner.show();

    this.token = this.storage.retrieve('token');
    let headers = new HttpHeaders();
    headers = headers.set('Authorization', this.token);
    let params = new HttpParams();
     params = params.set('threedbet_id', id);
     console.log("id "+id);
    this.http.get(this.funct.ipaddress + 'threedbet/Get3DdetailList', { params: params, headers: headers }).subscribe(
      result => {
        this.dto.Response = {};
        this.dto.Response = result;
        this.threeDBetDetailDTOList_temp = this.dto.Response;
        this.threeDBetDetailDTOList =  this.threeDBetDetailDTOList_temp.results;
        console.log("Detail list: " +JSON.stringify(this.threeDBetDetailDTOList));
        this.dtTrigger11.next();
      }); 
    this.spinner.hide();
    $('#browseAccountData').modal("show");
  }

  goModal1(id){
    var id1 = 'tbltwodbetdetail' + this.idtwoddetailIndex;
    var twoddetailtb = $('#' + id1).DataTable();
    twoddetailtb.destroy();
    this.idtwoddetailIndex = this.idtwoddetailIndex +1;
    this.spinner.show();
    this.twoDBetDetailDTOList = [];
    this.twoDBetDetailDTOList_temp =[];
    this.token = this.storage.retrieve('token');
    let headers = new HttpHeaders();
    headers = headers.set('Authorization', this.token);
    let params = new HttpParams();
     params = params.set('twodbet_id', id);
     console.log("id "+id);
    this.http.get(this.funct.ipaddress + 'twodbet/Get2DdetailList', { params: params, headers: headers }).subscribe(
      result => {
        this.dto.Response = {};
        this.dto.Response = result;
        this.twoDBetDetailDTOList_temp = this.dto.Response;
        this.twoDBetDetailDTOList = this.twoDBetDetailDTOList_temp.results;
        this.dtTrigger12.next();
      }); 
    this.spinner.hide();
    $('#browseAccountData1').modal("show");
  }

  getQueenMakerTransactionHistory()
  {
    this.spinner.show();
    this.token = this.storage.retrieve('token');
    let headers = new HttpHeaders();
    headers = headers.set('Authorization',  this.token);
    let params = new HttpParams();
    this.tempQMfinancialTransactionDTOList = [];
    var id = 'tblqmtran' + this.tblqmtranid;
    var topuptable = $('#' + id).DataTable();
    topuptable.destroy();
    if (this.qmdate == '' || this.qmdate == undefined) {
      this.queenmakerfchangedate = this.qmtodaydate;
    }
    else 
    {
       this.queenmakerfchangedate = this.qmdate;
    }
    if (this.qmtodate == '' || this.qmtodate == undefined) {
      this.queenmakertchangedate = this.qmtodaytodate;
    }
    else 
    {
       this.queenmakertchangedate = this.qmtodate;
    }
    var formData = new FormData();
    this.displayUserIdTran  = this.userId.toString().padStart(3, '0');
    formData.append("userId", this.userId);
    formData.append("fromDate", this.queenmakerfchangedate);
    formData.append("toDate", this.queenmakertchangedate);
    formData.append("providerId",this.providerId);
    this.http.post(this.funct.ipaddress + 'loginGS/getGameUserTransactionAdminWeb', formData,{ headers: headers })
    .pipe(
      catchError(this.handleError.bind(this))
     )
    .subscribe(
      result => {
        this.dto.Response = {};
        this.dto.Response = result;
        this.tempQMfinancialTransactionDTOList = this.dto.Response;
        var editWallet = 0;      
        this.dtTriggerKM.next();
        this.spinner.hide();
      }
    );
  }
  onchangeQmDate()
  {
    this.qmdate = $("#queenmakerdate").val();
  }
  onchangeQmToDate()
  {
    this.qmtodate = $("#queenmakertodate").val();
  }
 
  
  
  getGameBalanceLog()
  {
    this.spinner.show();
    this.token = this.storage.retrieve('token');
    let headers = new HttpHeaders();
    headers = headers.set('Authorization',  this.token);
    let params = new HttpParams();
    this.userGameLogList = [];
    var id = 'tblgamewinlose' + this.idgamewinIndex;
    var topuptable = $('#' + id).DataTable();
    topuptable.destroy();
    if (this.gamewinDate == '' || this.gamewinDate == undefined) {
      this.gamewinchangeDate = this.gamewintodaydate;
    }
    else 
    {
       this.gamewinchangeDate = this.gamewinDate;
    }
    if (this.gamewintoDate == '' || this.gamewintoDate == undefined) {
      this.gamwinetodatechangeDate = this.gamewintodaytodate;
    }
    else 
    {
       this.gamwinetodatechangeDate = this.gamewintoDate;
    }
    var formData = new FormData();
    this.displayUserId  = this.userId.toString().padStart(3, '0');
    formData.append("userId", this.userId);
    formData.append("fromDate", this.gamewinchangeDate);
    formData.append("toDate", this.gamwinetodatechangeDate);
    if(this.providerId1 == undefined)
    {
      this.providerId1 = '';
    }
    formData.append("providerId",this.providerId1);
    this.http.post(this.funct.ipaddress + 'user/GetGameUserBalanceLog', formData,{ headers: headers })
    .pipe(
      catchError(this.handleError.bind(this))
     )
    .subscribe(
      result => {
        this.dto.Response = {};
        this.dto.Response = result;
        this.userGameLogList = this.dto.Response;
        this.dtTriggergwinlose.next();
        this.spinner.hide();
      }
    );
  }

  onWinLostDate()
  {
    this.gamewinDate = $("#gamewinDate").val();
  }
  onChangeonWinLosttoDate()
  {
    this.gamewintoDate = $("#gamewintodate").val();
  }

  /*get point detail*/
  getPointDetail(uId)
  {
    let headers = new HttpHeaders();
    headers = headers.set('Authorization',  this.token);
    let params = new HttpParams();
    params = params.set('id', uId);
    this.http.get(this.funct.ipaddress + 'user/DetailsList_Noblance', { params: params, headers: headers })
    .pipe(
      catchError(this.handleError.bind(this))
     )
    .subscribe(
      result => {
          this.dto.Response = {};
          this.dto.Response = result;
          this.userDTO = this.dto.Response;
          console.log(">>> "+JSON.stringify(this.userDTO));
          this.agentId = this.userDTO.agent_id;
          this.alldate = new Date(this.userDTO.created_date); /*XXXXX all transaction from date set to register date */
          this.adjustfromdate = new Date(this.userDTO.created_date);//amk
          this.spinner.hide();
       }
       );
    $('#browseAccountData7').modal("show");
  }

  closeTran()
  {
      $('#deleteData').modal("hide");
      this.router.navigate(['/user-detail' ,this.userId]).then(() => {
      this.toastr.error("Game Transaction ", 'Cancel!', {
        timeOut: 3000,
        positionClass: 'toast-top-right',
      });
    });
  }

  delete(deleteId ,trantype, source)
  {
    $("#deleteData").modal("show");
    this.trantype = trantype;
    this.deleteId = deleteId;  
    this.source = source;
  }

  
  GameBalance() {
    this.spinner.show();
    var id11 = 'tblgamewallet' + this.idgamewallet;
    var table11 = $('#' + id11).DataTable();
    table11.destroy();

    this.token = this.storage.retrieve('token');
    let headers = new HttpHeaders();
    headers = headers.set('Authorization',  this.token);
    let params = new HttpParams();
    params = params.set('id', this.userId);
    this.http.get(this.funct.ipaddress + 'user/usergameblance', { params: params, headers: headers })
    .pipe(
      catchError(this.handleError.bind(this))
     )
    .subscribe(
      result => {
        this.dto.Response = {};
          this.dto.Response = result;
          this.gameDTO = this.dto.Response;
          this.spinner.hide();
      }
    );
  }


      
 //deleteBankAcc(userId , payment_id,bank_acc_id)
    deletTranForBankAcc()
    {       
          this.dto.token = this.storage.retrieve('token');
          // let headers = new HttpHeaders();
          // headers = headers.set('Authorization',  this.dto.token);   
          let editedArray = this.getEditedArrayForBankAccountDelete();      
          let headers = new HttpHeaders();
          if(editedArray.length > 0)
          {    
            headers = headers.set('Authorization',  this.dto.token).set("editedArray",editedArray);
          }
          else{
            headers = headers.set('Authorization',  this.dto.token);
          } 
  
          this.addfacdto.userId = this.deleteuseraccid ; 
          this.addfacdto.payment_id = this.deletepaymentId ;
          this.addfacdto.bank_acc_id = this.deletebankaccid;
          this.http.post(this.funct.ipaddress + 'userbankaccount/deleteuserBankAccountForAdmin', this.addfacdto,{ headers: headers })
          .pipe(
            catchError(this.handleError.bind(this))
          )
          .subscribe(
            result => 
            {
              this.dto.Response = {};
              this.dto.Response = result;
              if (this.dto.Response.status == "Success") 
              {
                $("#deleteDataForBank").modal("hide");
                this.getUserbankInfo();
                this.spinner.hide(); 
                this.toastr.success(this.dto.Response.message, 'Success!', {
                  timeOut: 3000,
                  positionClass: 'toast-top-right'
                })     
              }             
              else 
              {             
                $("#deleteDataForBank").modal("hide");              
                this.spinner.hide(); 
                this.toastr.success(this.dto.Response.message, 'Error!', {
                  timeOut: 3000,
                  positionClass: 'toast-top-right'
                }) 
              }

            },
            error => 
            {
              if(error.error.status =="Error")
              {
                $("#deleteDataForBank").modal("hide");              
                this.spinner.hide(); 
                this.toastr.error(error.error.message, 'Error!', 
                {
                  timeOut: 3000,
                  positionClass: 'toast-top-right'
                })
              }
            }
            );
    
  
    }



    deleteBankAcc(userId , payment_id,bank_acc_id, bankaccount_name , bank_type,user_account_no)
    {
      $("#deleteDataForBank").modal("show");
      this.deleteuseraccid = userId;
      this.deletepaymentId = payment_id;     
      this.deletebankaccid = bank_acc_id;
      this.temp_bankaccount = bankaccount_name;
      this.temp_paymenttype = bank_type;
      this.temp_user_account_no = user_account_no;
  
    }



  closeTranForBank()
  {
    $('#deleteDataForBank').modal("hide");
    this.router.navigate(['/user-detail' ,this.userId]).then(() =>
     {
    
  });
  }

  getEditedArray() {
    let editedArray = ''; 
  
    if (this.userDTO.status !== this.statusforEdit) {
      editedArray += 'Status: ' + this.statusforEdit + ' -> ' + this.userDTO.status + ', ';
    }   
    if (editedArray.endsWith(', ')) {
      editedArray = editedArray.slice(0, -2);
    }    
    return editedArray;
  }


  
  //amk added for adjust
  onchangeQmDateForAdjust()
  {
    this.adjfromdate = $("#adjustfromdate").val();
  }
  onchangeQmToDateForAdjust()
  {
    this.adjtodate = $("#adjusttodate").val();
  }

  getAdjustTransactionList()
  {
    this.spinner.show();
    this.token = this.storage.retrieve('token');
    let headers = new HttpHeaders();
    headers = headers.set('Authorization',  this.token);
    let params = new HttpParams();
    this.tempAdjustTransactionList = [];
    var id = 'tbladjust' + this.tbladjustid;
    var topuptable = $('#' + id).DataTable();
    topuptable.destroy();
    if (this.adjfromdate == '' || this.adjfromdate == undefined) 
    {
      this.adjustchangedate = this.adjtodaydate;
    }
    else 
    {
       this.adjustchangedate = this.adjfromdate;
    }
    if (this.adjtodate == '' || this.adjtodate == undefined) {
      this.adjusttochangedate = this.adjtodaytodate;
    }
    else 
    {
       this.adjusttochangedate = this.adjtodate;
    }
    if(this.adjusttype == undefined)
    {
        this.adjusttype =""; 
    } 

    params = params.set('userId', this.userId).set("fromDate",this.adjustchangedate).set("toDate",this.adjusttochangedate).set("type",this.adjusttype);
    this.http.get(this.funct.ipaddress + 'userbankaccount/getuseradjustlog', { params: params, headers: headers })
    
    .pipe(
      catchError(this.handleError.bind(this))
     )
    .subscribe(
      result => {
        this.dto.Response = {};
        this.dto.Response = result;
        this.tempAdjustTransactionList = this.dto.Response;          
        this.dtTriggeradjust.next();
        this.spinner.hide();
      }
    );
  }

  getEditedArrayForBankAccountDelete() 
  {
    let editedArray = ''; 
      if (this.deleteuseraccid !== undefined || this.deleteuseraccid !== null || this.deleteuseraccid !=="") 
      {
        editedArray += 'User Account: ' +  this.temp_user_account_no + ', ';
      }  
      if (this.deletepaymentId !== undefined || this.deletepaymentId !== null || this.deletepaymentId !=="") 
      {
        editedArray += 'PaymentType: ' +  this.temp_paymenttype + ', ';
      }
      if (this.deletebankaccid !== undefined || this.deletebankaccid !== null || this.deletebankaccid !=="") 
      {
        editedArray += 'Bank Account: ' +  this.temp_bankaccount + ', ';
      } 
      if (this.deletebankaccid !== undefined || this.deletebankaccid !== null || this.deletebankaccid !=="" || this.remark != undefined || this.remark != null) 
      {
        editedArray += 'Remark: ' +  this.remark + ', ';
      } 
    
      if (editedArray.endsWith(', ')) {
        editedArray = editedArray.slice(0, -2);
      }    
      return editedArray;

      
  }


  //

 
  onButtonClick(buttonType: string) 
  {
    this.selectedButton = buttonType;
    if(buttonType =="withdrawal")
    {
      this.getUserbankInfo();
    }
    else{
        this.getUserbankInfofortopup();
    }
 
  }
  
  
  getUserbankInfo()
  {
    if (this.dtTrigger1.isStopped) 
    {
      this.dtTrigger1 = new Subject<any>();
    }
    this.spinner.show();
    var id1 = 'tblBank' + this.bankidIndex;
    var table1 = $('#' + id1).DataTable();
    table1.destroy();
    // var id = 'tblUser' + this.idIndex;
    // var tranalltable = $('#' + id).DataTable();
    // tranalltable.destroy();
    this.tempUserBankInfoDTOList = [];
    this.tempUserBankInfoDTOList_topup = [];
    this.token = this.storage.retrieve('token');
    let headers = new HttpHeaders();
    headers = headers.set('Authorization', this.token);
    let params = new HttpParams();
    params = params.set('userId', this.userId);
    this.http.get(this.funct.ipaddress + 'userbankaccount/getuserbankinfo-details-by-params', { params: params, headers: headers })
    .pipe(
      catchError(this.handleError.bind(this))
     )
    .subscribe(
      result => {
        this.dto.Response = {};
        this.dto.Response = result;
        var img;
        var qrimg;
        this.tempUserBankInfoDTOList = this.dto.Response;
      
        for(var i = 0 ; i < this.tempUserBankInfoDTOList.length ; i++)
        {
         img = this.tempUserBankInfoDTOList[i].imageUrl;
         if(img != null)
         {
          let objectURL =  img;
          this.bankimgURL = this.sanitizer.bypassSecurityTrustUrl(objectURL);
          this.tempUserBankInfoDTOList[i].banklogo = this.bankimgURL;
         }
         qrimg = this.tempUserBankInfoDTOList[i].qrImageUrl;
         if(qrimg != null)
         {
          let qrobjectURL =  qrimg;
          this.qrImageURL = this.sanitizer.bypassSecurityTrustUrl(qrobjectURL);
          this.tempUserBankInfoDTOList[i].qrImagelogo = this.qrImageURL;
         }
        }
        this.dtTrigger1.next();
        this.spinner.hide();
      }
    );
  }  

  
  getUserbankInfofortopup()
  {
    if (this.dtTrigger.isStopped) 
    {
      this.dtTrigger = new Subject<any>();
    }
    this.spinner.show();
    var id1 = 'tblBank' + this.bankidIndex;
    var table1 = $('#' + id1).DataTable();
    table1.destroy();
    var id = 'tblUser' + this.idIndex;
    var tranalltable = $('#' + id).DataTable();
    tranalltable.destroy();
    this.tempUserBankInfoDTOList_topup = [];
    this.tempUserBankInfoDTOList = [];
    this.token = this.storage.retrieve('token');
    let headers = new HttpHeaders();
    headers = headers.set('Authorization', this.token);
    let params = new HttpParams();
    params = params.set('userId', this.userId);
    this.http.get(this.funct.ipaddress + 'userbankaccount/getusertopupbankinfo-details-by-params', { params: params, headers: headers })
    .pipe(
      catchError(this.handleError.bind(this))
     )
    .subscribe(
      result => {
        this.dto.Response = {};
        this.dto.Response = result;
        var img;
        var qrimg;
        this.tempUserBankInfoDTOList_topup = this.dto.Response;      
        for(var i = 0 ; i < this.tempUserBankInfoDTOList_topup.length ; i++)
        {
         img = this.tempUserBankInfoDTOList_topup[i].imageUrl;
         if(img != null)
         {
          let objectURL =  img;
          this.bankimgURL = this.sanitizer.bypassSecurityTrustUrl(objectURL);
          this.tempUserBankInfoDTOList_topup[i].banklogo = this.bankimgURL;
         }     
        
        }
        this.dtTrigger.next();
        this.spinner.hide();
      }
    );
  }  

  
  
  showQrModal(qrImagelogo): void 
  {
    if (qrImagelogo) 
    {
      this.qrImageUrl = qrImagelogo;
    } else {
      this.qrImageUrl = undefined;
    }
    this.isQrModalVisible = true;
  }


closeQrModal() {
  this.isQrModalVisible = false;
}

onRiskyPlayerToggle()
 {
   this.userDTO.isRisky = !this.userDTO.isRisky;
  console.log('isRisky:', this.userDTO.isRisky);
}

}