<div class="wrapper">
    <app-header-sidebar></app-header-sidebar>
  
    <div class="content-wrapper">
   
      <div class="content-header">
        <div class="container-fluid">
          <div class="row mb-2" style="border: 1px solid #ced4da;border-radius: 0.25rem;">
            <div class="col-sm-6">
              <h3 class="m-0 text-dark" style="font-weight: bold;padding: 3px 0px 3px 5px;"> User Password Reset </h3>
            </div>
          </div>
        
        </div>
      </div>
      
      <section class="content">
        <div class="container-fluid">
          <form>
            <div class="row">
  
              <div class="col-md-12">
                
                <ul class="nav nav-tabs nav-pills"> 
                  <li  (click)="pendingSearch('tabsearch')">
                    <a  id="pending-tab" data-toggle="tab" style="width:100% !important" href="#pending"> PENDING FOR BANK SLIP </a>
                  </li>
                  <li (click)="approveSearch('tabsearch')">
                    <a id="approve-tab" data-toggle="tab" style="width:100% !important" href="#approve"> APPROVE FOR BANK SLIP</a>
                  </li>
                  <li (click)="rejectedSearch('tabsearch')">
                    <a id="rejected-tab" data-toggle="tab" style="width:100% !important" href="#rejected"> REJECTED FOR BANK SLIP</a>
                  </li>
                  <li (click)="inActivedUserSearch()">
                    <a id="inactived-tab" data-toggle="tab" style="width:100% !important" href="#inactived"> INACTIVED USER BY RESET QUESTION</a>
                  </li>
                </ul>
  
                <!-- Tab panes -->
                <div class="tab-content clearfix">
   
                  <div class="tab-pane" id="approve">
                    <div class="row"> 
                      <div class="form-group col-md-2">
                        <label>Phone Number</label>
                        <input type="number"
                          class="form-control form-control-sm" [(ngModel)]="approvephoneno"
                          (ngModelChange) = "onChangeTagSection()" 
                          [ngModelOptions]="{standalone: true}" placeholder="09*********">
                      </div>
  
                      <div class="form-group col-md-2">
                        <label>From Date</label>
                        <input id="approveFromDate" readonly class="form-control form-control-sm" placeholder="Select Date"
                          [ngxDatePicker]="dateInstanceApprove"  [(ngModel)]="approvefromdate"
                          [ngModelOptions]="{standalone: true}"  (ngModelChange)="onChangeApproveFromDate()">
                        <ngx-date-picker #dateInstanceApprove></ngx-date-picker>
                      </div>
                       
                      <div class="form-group col-md-2">
                        <label>To Date</label>
                        <input id="approveToDate" readonly class="form-control form-control-sm" placeholder="Select Date"
                          [ngxDatePicker]="dateInstanceApprove1"  [(ngModel)]="approvetodate"
                          [ngModelOptions]="{standalone: true}" (ngModelChange)="onChangeApproveToDate()">
                        <ngx-date-picker #dateInstanceApprove1></ngx-date-picker>
                      </div>
     
                      <div class="form-group col-md-2 col-sm-4 col-xs-4">
                        <label></label>
                        <button id="search" type="button" class="btn btn-block btn-primary btn-sm" style="margin-top: 4%;"
                          (click)="approveSearch('btnsearch')">Search</button>
                      </div>  
  
                      <div class="col-md-12">
                        <div class="card">
                          <div class="card-header">
                            <h3 class="card-title" style="font-weight: bold;">Approved List</h3>
                          </div>
                          <div class="card-body">
                            <table width='100%' id="{{'tblapprove' + idapproveIndex}}" datatable
                              [dtTrigger]="dtTrigger1" [dtOptions]="dtOptions1" class="table table-bordered table-hover">
                              <thead>
                                <tr>
                                  <th style="font-family: unicode;" class="text-center">Name</th>
                                  <th style="font-family: unicode;" class="text-center">Phone No</th>
                                  <th style="font-family: unicode;" class="text-center">Request Status</th>
                                  <th style="font-family: unicode;" class="text-center">Request Date</th>
                                  <th style="font-family: unicode;" class="text-center">Updated Date</th>
                                  <th style="font-family: unicode;" class="text-center">Updated By</th>
                                  <th style="font-family: unicode;"  class="text-center" *ngIf="hardcode_edit_bool === true">Action</th> 
                                </tr>
                              </thead> 
                              <tbody>
                                <tr *ngFor="let fgp of fgpwdapproveList">
                                  <td style="font-family: unicode;"  class="text-center">{{fgp.name}}</td>                               
                                  <!-- <td style="font-family: unicode;" class="text-center">
                                    <div *ngIf="fgp.name == null">{{fgp.phone_no}}</div>
                                    <div *ngIf="fgp.name != null"><a [routerLink] = "['/user-forgotpassword-detail-cancel', fgp.user_id]" style="color:#4b88eb"> {{fgp.phone_no}} </a> </div>
                                  </td> -->
                                  <td style="font-family: unicode;" class="text-center">
                                    <div *ngIf="fgp.name == null">{{fgp.phone_no}}</div>
                                    <div *ngIf="fgp.name != null"><a [routerLink] = "['/user-detail', fgp.user_id]"  [queryParams]="{ type: 'forgotpasswordlist' }" style="color:#4b88eb"> {{fgp.phone_no}} </a> </div>
                                  </td>
                                  <td style="font-family: unicode;"  class="text-center">{{fgp.status}}</td> 
                                  <td style="font-family: unicode;"  class="text-center">{{fgp.created_date | date : 'yyyy-MM-dd HH:mm:ss'}}</td> 
                                  <td style="font-family: unicode;"  class="text-center">{{fgp.updated_date | date : 'yyyy-MM-dd HH:mm:ss'}}</td> 
                                  <td style="font-family: unicode;"  class="text-center">{{fgp.updated_by}}</td> 
                                  <td style="font-family: unicode;"  class="text-center" *ngIf="hardcode_edit_bool === true">
                                    <a [routerLink]="['/bank-slip-detail', fgp.id]">
                                      <i class="fas fa-edit" style="color:#4b88eb"></i>
                                    </a>

                                    <!-- <a (click)="addStepAndNavigate(fgp)">
                                      <i class="fas fa-edit" style="color:#4b88eb"></i>
                                    </a> -->
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div> 
                    </div>
                  </div>  
                  <div class="tab-pane" id="pending">
                    <div class="row">
  
                      <div class="form-group col-md-2">
                        <label>Phone Number</label>
                        <input type="number" 
                          class="form-control form-control-sm" [(ngModel)]="pendingphoneno"
                          (ngModelChange) = "onChangePhoneNo()"
                          [ngModelOptions]="{standalone: true}" placeholder="09*********">
                      </div>
  
                      <div class="form-group col-md-2">
                        <label>From Date</label>
                        <input id="pendingFromDate" readonly class="form-control form-control-sm" placeholder="Select Date"
                          [ngxDatePicker]="dateInstancePending"  [(ngModel)]="pendingfromdate"
                          [ngModelOptions]="{standalone: true}" (ngModelChange)="onChangePendingFromDate()">
                        <ngx-date-picker #dateInstancePending></ngx-date-picker>
                      </div>
                      
                      <div class="form-group col-md-2">
                        <label>To Date</label>
                        <input id="pendingToDate" readonly class="form-control form-control-sm" placeholder="Select Date"
                          [ngxDatePicker]="dateInstancePending1"  [(ngModel)]="pendingtodate"
                          [ngModelOptions]="{standalone: true}" (ngModelChange)="onChangePendingToDate()">
                        <ngx-date-picker #dateInstancePending1></ngx-date-picker>
                      </div>
  
                     <!-- <div class="col-md-4"></div> -->
  
                      <div class="form-group col-md-2 col-sm-4 col-xs-4">
                        <label></label>
                        <button id="search" type="button" class="btn btn-block btn-primary btn-sm" style="margin-top: 4%;"
                          (click)="pendingSearch('btnsearch')">Search</button>
                      </div>   
                      <div class="col-md-12">
                        <div class="card">
                          <div class="card-header">
                            <h3 class="card-title" style="font-weight: bold;">Pending List</h3>
                          </div>
                          <div class="card-body">
                            <table width='100%' id="{{'tblpending' + idpendingIndex}}" datatable
                              [dtTrigger]="dtTrigger3" [dtOptions]="dtOptions3" class="table table-bordered table-hover">
                              <thead>
                              <tr>
                                <th style="font-family: unicode;" class="text-center">Name</th>
                                <th style="font-family: unicode;" class="text-center">Phone No</th>
                                <th style="font-family: unicode;" class="text-center">Request Status</th>
                                <th style="font-family: unicode;" class="text-center">Request Date</th>
                                <th style="font-family: unicode;" class="text-center">Updated Date</th>
                                <th style="font-family: unicode;" class="text-center">Updated By</th>
                                <th style="font-family: unicode;" class="text-center" *ngIf="hardcode_edit_bool === true">Action</th> 
                              </tr>
                            </thead>
                              <tbody>
                                <tr *ngFor="let fgp of fgpwdppengingList">
                                  <td style="font-family: unicode;" class="text-center">{{fgp.name}}</td>                                 
                                  <!-- <td style="font-family: unicode;" class="text-center">
                                    <div *ngIf="fgp.name == null">{{fgp.phone_no}}</div>
                                    <div *ngIf="fgp.name != null"><a [routerLink] = "['/user-forgotpassword-detail-cancel', fgp.user_id]" style="color:#4b88eb"> {{fgp.phone_no}} </a> </div>
                                  </td> -->
                                  <td style="font-family: unicode;" class="text-center">
                                    <div *ngIf="fgp.name == null">{{fgp.phone_no}}</div>
                                    <div *ngIf="fgp.name != null"><a [routerLink] = "['/user-detail', fgp.user_id]"  [queryParams]="{ type: 'forgotpasswordlist' }" style="color:#4b88eb"> {{fgp.phone_no}} </a> </div>
                                  </td>
                                  <td style="font-family: unicode;" class="text-center">{{fgp.status}}</td> 
                                  <td style="font-family: unicode;" class="text-center">{{fgp.created_date | date : 'yyyy-MM-dd HH:mm:ss'}}</td> 
                                  <td style="font-family: unicode;" class="text-center">{{fgp.updated_date | date : 'yyyy-MM-dd HH:mm:ss'}}</td> 
                                  <td style="font-family: unicode;" class="text-center">{{fgp.updated_by}}</td> 
                                  <td style="font-family: unicode;" class="text-center" *ngIf="hardcode_edit_bool === true">
                                    <!-- <a [routerLink]="['/bank-slip-detail', fgp.id]">
                                      <i class="fas fa-edit" style="color:#4b88eb"></i>
                                    </a> -->
                                    <a (click)="addStepAndNavigate(fgp)">
                                      <i class="fas fa-edit" style="color:#4b88eb"></i>
                                    </a> 
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
  
                    </div>
                  </div>
  
                  <div class="tab-pane" id="rejected">
                    <div class="row">
  
                      <div class="form-group col-md-2">
                        <label>Phone Number</label>
                        <input type="number"
                          class="form-control form-control-sm" [(ngModel)]="deniedphoneno"
                          (ngModelChange) = "onChangeRejectedPhoneNo()"
                          [ngModelOptions]="{standalone: true}" placeholder="09*********">
                      </div>
  
                      <div class="form-group col-md-2">
                        <label>From Date</label>
                        <input id="deniedFromDate" readonly class="form-control form-control-sm" placeholder="Select Date"
                          [ngxDatePicker]="dateInstanceDenied"  [(ngModel)]="deniedfromdate"
                          [ngModelOptions]="{standalone: true}" (ngModelChange)="onChangeDeniedFromDate()">
                        <ngx-date-picker #dateInstanceDenied></ngx-date-picker>
                      </div>
  
                      <div class="form-group col-md-2">
                        <label>To Date</label>
                        <input id="deniedToDate" readonly class="form-control form-control-sm" placeholder="Select Date"
                          [ngxDatePicker]="dateInstanceDenied1"  [(ngModel)]="deniedtodate"
                          [ngModelOptions]="{standalone: true}" (ngModelChange)="onChangeDeniedToDate()">
                        <ngx-date-picker #dateInstanceDenied1></ngx-date-picker>
                      </div> 
                      <div class="form-group col-md-2 col-sm-4 col-xs-4">
                        <label></label>
                        <button id="search" type="button" class="btn btn-block btn-primary btn-sm" style="margin-top: 4%;"
                          (click)="rejectedSearch('btnsearch')">Search</button>
                      </div>  
  
                      <div class="col-md-12">
                        <div class="card">
                          <div class="card-header">
                            <h3 class="card-title" style="font-weight: bold;">Rejected List</h3>
                          </div>
                          <div class="card-body">
                            <table width='100%' id="{{'tbldenied' + iddeniedIndex}}" datatable
                              [dtTrigger]="dtTrigger4" [dtOptions]="dtOptions4" class="table table-bordered table-hover">
                             <thead>
                              <tr>
                                <th style="font-family: unicode;" class="text-center">Name</th>
                                <th style="font-family: unicode;" class="text-center">Phone No</th>
                                <th style="font-family: unicode;" class="text-center">Request Status</th>
                                <th style="font-family: unicode;" class="text-center">Request Date</th>
                                <th style="font-family: unicode;" class="text-center">Updated Date</th>
                                <th style="font-family: unicode;" class="text-center">Updated By</th>
                                <th style="font-family: unicode;" class="text-center" *ngIf="hardcode_edit_bool === true">Action</th> 
                              </tr>
                             </thead>
                              <tbody>
                                <tr *ngFor="let fgp of fgpwddeniedList">
                                  <td style="font-family: unicode;" class="text-center">{{fgp.name}}</td>
                         
                                  <!-- <td style="font-family: unicode;" class="text-center">
                                    <div *ngIf="fgp.name == null">{{fgp.phone_no}}</div>
                                    <div *ngIf="fgp.name != null"><a [routerLink] = "['/user-forgotpassword-detail-cancel', fgp.user_id]" style="color:#4b88eb"> {{fgp.phone_no}} </a> </div>
                                  </td> -->
                                  <td style="font-family: unicode;" class="text-center">
                                    <div *ngIf="fgp.name == null">{{fgp.phone_no}}</div>
                                    <div *ngIf="fgp.name != null"><a [routerLink] = "['/user-detail', fgp.user_id]"  [queryParams]="{ type: 'forgotpasswordlist' }" style="color:#4b88eb"> {{fgp.phone_no}} </a> </div>
                                  </td>
                                  <td style="font-family: unicode;" class="text-center">{{fgp.status}}</td> 
                                  <td style="font-family: unicode;" class="text-center">{{fgp.created_date | date : 'yyyy-MM-dd HH:mm:ss'}}</td> 
                                  <td style="font-family: unicode;" class="text-center">{{fgp.updated_date | date : 'yyyy-MM-dd HH:mm:ss'}}</td> 
                                  <td style="font-family: unicode;" class="text-center">{{fgp.updated_by}}</td> 
                                  <td style="font-family: unicode;" class="text-center" *ngIf="hardcode_edit_bool === true">
                                    <!-- <a [routerLink]="['/bank-slip-detail', fgp.id]">
                                      <i class="fas fa-edit" style="color:#4b88eb"></i>
                                    </a> -->
                                    
                                    <a (click)="addStepAndNavigate(fgp)">
                                      <i class="fas fa-edit" style="color:#4b88eb"></i>
                                    </a>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
  
                    </div>
                  </div>
  
                  <div class="tab-pane" id="inactived">
                    <div class="row"> 
                      <div class="form-group col-md-2">
                        <label>Phone Number</label>
                        <input type="number"
                          class="form-control form-control-sm" [(ngModel)]="inactivatedphoneno"
                          (ngModelChange) = "onChangeInactivePhoneNo()"
                          [ngModelOptions]="{standalone: true}" placeholder="09*********">
                      </div> 
  
                      <div class="form-group col-md-2 col-sm-4 col-xs-4">
                        <label></label>
                        <button id="search" type="button" class="btn btn-block btn-primary btn-sm" style="margin-top: 4%;"
                          (click)="inActivedUserSearch()">Search</button>
                      </div>  
  
                      <div class="col-md-12">
                        <div class="card">
                          <div class="card-header">
                            <h3 class="card-title" style="font-weight: bold;">InActived List</h3>
                          </div>
                          <div class="card-body">
                            <table width='100%' id="{{'tblinactivate' + inactivatedIndex}}" datatable
                              [dtTrigger]="dtTrigger5" [dtOptions]="dtOptions5" class="table table-bordered table-hover">
                             <thead>
                              <tr>
                                <th style="font-family: unicode;" class="text-center">Name</th>
                                <th style="font-family: unicode;" class="text-center">Phone No</th>
                                <th style="font-family: unicode;" class="text-center">Current Wallet</th>
                                <th style="font-family: unicode;" class="text-center">Register Date</th>
                                <th style="font-family: unicode;" class="text-center">Last Login Date</th>
                                <th style="font-family: unicode;" class="text-center">First Topup Date</th>
                                <th style="font-family: unicode;" class="text-center">First Topup Amount</th>
                                <th style="font-family: unicode;" class="text-center">Last Topup Date</th>
                                <th style="font-family: unicode;" class="text-center">Last Topup Amount</th> 
                                <th style="font-family: unicode;" class="text-center">Updated_by</th> 
                                <th style="font-family: unicode;" class="text-center" *ngIf="hardcode_edit_bool === true">Action</th> 
                              </tr>
                             </thead>
                              <tbody>
                                <tr *ngFor="let fgp of fgpinactivatedList">
                                  <td style="font-family: unicode;" class="text-center">{{fgp.name}}</td>
                                  <!-- <td style="font-family: unicode;" class="text-center"><a [routerLink] = "['/user-forgotpassword-detail-cancel', fgp.id]">{{fgp.phone_no}}</a></td>  -->
                                  <td style="font-family: unicode;" class="text-center"><a [routerLink] = "['/user-detail', fgp.id]" [queryParams]="{ type: 'forgotpasswordlist' }">{{fgp.phone_no}}</a></td> 
                                  <td style="font-family: unicode;" class="text-center">{{fgp.balance}}</td> 
                                  <td style="font-family: unicode;" class="text-center">{{fgp.created_date | date : 'yyyy-MM-dd HH:mm:ss'}}</td> 
                                  <td style="font-family: unicode;" class="text-center">{{fgp.lastLogin | date : 'yyyy-MM-dd HH:mm:ss'}}</td> 
                                  <td style="font-family: unicode;" class="text-center">{{fgp.first_topup_date | date : 'yyyy-MM-dd HH:mm:ss'}}</td>
                                  <td style="font-family: unicode;" class="text-center">{{fgp.first_topup_amt}}</td> 
                                  <td style="font-family: unicode;" class="text-center">{{fgp.last_topup_date | date : 'yyyy-MM-dd HH:mm:ss'}}</td> 
                                  <td style="font-family: unicode;" class="text-center">{{fgp.last_topup_amt}}</td>
                                  <td style="font-family: unicode;" class="text-center">{{fgp.updated_by}}</td>
                                  <td style="font-family: unicode;"  class="text-center" *ngIf="hardcode_edit_bool === true">
                                    <button id="search" type="button" class="btn btn-block btn-primary btn-sm" style="margin-top: 4%;"
                                    (click)="reActive(fgp.phone_no)">Re Active</button> 
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
  
                    </div>
                  </div>
  
                </div>
              </div>
  
            </div>
          </form>
          <!-- /.row -->
  
          <!-- /.row -->
        </div>
        <!--/. container-fluid -->
      </section>
      <!-- /.content -->
    </div>
  
    <aside class="control-sidebar control-sidebar-dark">
      <!-- Control sidebar content goes here -->
    </aside>
  
    <app-footer></app-footer>
    <ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#fff" type="square-jelly-box">
      <p style="font-size: 16px; color: white">Loading...</p>
    </ngx-spinner>
  </div>
  
  <div id="deleteData" class="modal fade">
    <div class="modal-dialog">
        <div class="modal-content">
  
            <div class="modal-body">
                <form>
  
                    <div class="row" style="margin-top: 5%;margin-bottom: 3%;margin-left: 9%;">
                        <div class="form-group col-md-12">
                            <h5> This Account is used by another device. 
                                Please Sign Out. Login Again. </h5>
                        </div>
                    </div>
  
                    <div class="row" style="margin-left: 40%;margin-bottom: 3%;">
                        <div class="col-md-5 col-sm-6" id="deleteBtn">
                            <button type="button" class="btn btn-block btn-success" (click)="OkLogout()"> Ok </button>
                        </div>
                    </div>
                </form>
            </div> 
        </div>
    </div>
  </div> 
   
  