import { Component, OnInit, ViewChild } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,HttpErrorResponse } from '@angular/common/http';
import { DataTableDirective } from 'angular-datatables';
import { Router, NavigationEnd } from '@angular/router';
import { DatePipe, NgStyle } from '@angular/common';
import { LocalStorageService } from 'ngx-webstorage';
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from 'ngx-toastr';
// import { DatePipe } from '@angular/common';

import { DtoService } from '../../service/dto.service';
import { FunctService } from '../../service/funct.service';
import { catchError, retry } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { Subject } from 'rxjs';
import { timeStamp } from 'console';
//amk
import * as XLSX from 'xlsx'; 
import * as fs from 'file-saver';
import { Workbook } from 'exceljs';
import { jsPDF } from "jspdf";
import 'jspdf-autotable'
import autoTable from 'jspdf-autotable'
import { unsupported } from '@angular/compiler/src/render3/view/util';
declare var $: any;

@Component({
  selector: 'app-twod-bet-amount-limitation',
  templateUrl: './twod-bet-amount-limitation.component.html',
  styleUrls: ['./twod-bet-amount-limitation.component.css']
})
export class TwodBetAmountLimitationComponent implements OnInit {

  @ViewChild(DataTableDirective)
  dtElement: DataTableDirective;
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();

  singleDate: string = '';
  status: string = '';
  number: string = '';
  amount: string = '';
  twodbetamountlimitList: any;
  total_bet_limit_amount:any;
  total_percentage :any;
  total_bet_amount_Str:any;
  idIndex: any;
  twodbetId: any;
  token: any;
  time : any;
  isChecked: any = false;
  confirmallArr: any;
  editAmt : any;
  fordate :any;
  todaydate :any;
  changedate: any;
  currentTime : any;
  currentTime1 : any;
  sectionList : any;

  hardcode_edit :any;
  hardcode_add :any;
  hardcode_view: any;
  hardcode_view_bool : any;
  hardcode_add_bool : any;
  hardcode_edit_bool : any;

  //amk  
  fromdate: string ='';
  todate: string = '';
  report_option : string ='';
  choose_msg : string = '';
  isActive : boolean = false;
  ispdfActive : boolean = false;
  iscsvActive : boolean = false;
  clickkbzpay: any = true;
  clickwavepay: any = false;
  csvrep: any = false;
  filename : string = "";
  filename_date :any ;
  grand_total : any;
  twodBetRptList = [];
  twodBetPdfRptList =[];
  myDate = new Date();
  adminObj : any;
  ad_name : any; 
  hardcode_delete_bool:any;
  hardcode_double_bool:any; 
  total_bet_amount:any;
  total_limit_amount:any;
  total_max_amount:any;

  constructor(private storage: LocalStorageService,private datePipe: DatePipe, private spinner: NgxSpinnerService, private dto: DtoService, private http: HttpClient,
    private toastr: ToastrService, private funct: FunctService, private router: Router,) 
    {
      this.storage.store('isNotiSong', "");
    this.idIndex = 1;
    this.status = 'ACTIVE';
    this.hardcode_view ="2DBetAmountLimitation_View";
    this.hardcode_edit = "2DBetAmountLimitation_Edit";   
    this.dto.menuCodes = this.storage.retrieve('menucodes');

    const filteredMenuCodeds_view =[];
    const filteredMenuCodeds_edit =[];
    this.dto.menuCodes.forEach(menuCode => {
      if (menuCode === this.hardcode_edit) {
        filteredMenuCodeds_edit.push(menuCode);
      }
      if (menuCode === this.hardcode_view) {
        filteredMenuCodeds_view.push(menuCode);
      }
    });
     
      if(filteredMenuCodeds_edit.length != 0)
      {
          this.hardcode_edit_bool = true;
      }
      else 
      {
        this.hardcode_edit_bool = false;
      }
      if(filteredMenuCodeds_view.length != 0)
      {
          this.hardcode_view_bool = true;
      }
      else{
        this.hardcode_view_bool = false;
      }

    this.time = "null";
    this.getAllSection();
    this.getCurrentAdmin();
  }

  ngOnInit(): void {
    this.search()
    this.router.routeReuseStrategy.shouldReuseRoute = function () {
      return false;
    };

    this.router.events.subscribe((evt) => {
      if (evt instanceof NavigationEnd) {
        // trick the Router into believing it's last link wasn't previously loaded
        this.router.navigated = false;
        // if you need to scroll back to top, here is the right place
        window.scrollTo(0, 0);
      }
    });

    this.dtOptions = {
      responsive: true,
      order: []
    }

    this.dtOptions.columnDefs = [
      { targets: [0], orderable: false },

    ];

    if (!this.storage.retrieve('loadFlag')) {
      this.storage.store('loadFlag', 'noLoad');
      setTimeout(function () {
        location.reload();
      }, 5);
    }
    else {
      this.storage.clear('loadFlag');
    }

  }

  onChangeSingle() {
    $(document).ready(function () {
      this.singleDate = $("#singleDate").val();
    });
  }
  handleError(error: HttpErrorResponse){
    if(error.status == 423)
    {
      this.spinner.hide();
      $("#deleteData").modal("show");
    }
    if(error.status == 500)
    {
      this.spinner.hide();
      this.toastr.error('Please check connection', 'Invalid!', {
        timeOut: 3000,
        positionClass: 'toast-top-right',
      });
    }
    if(error.status == 403)
    {
      this.spinner.hide();
      var id = 'tblTwoD' + this.idIndex;
      var table = $('#' + id).DataTable()
      this.toastr.error("Limited Access.", 'Invalid!', {
        timeOut: 3000,
        positionClass: 'toast-top-right',
        });
    }
  }

  OkLogout()
    {
      window.location.href ="./ad-login";
    } 
    
  checkuncheckall() {
    if (this.isChecked == true) {
      this.isChecked = false;
    }
    else {
      this.isChecked = true;
    }
  }

  search() 
  {   
    var id = 'tblTwoD' + this.idIndex;
    var table = $('#' + id).DataTable();
    table.destroy();
    this.idIndex = this.idIndex + 1;
    this.spinner.show();
    this.dto.token = this.storage.retrieve('token');
    this.singleDate = $("#singleDate").val();
    let headers = new HttpHeaders();
    headers = headers.set('Authorization',  this.dto.token);
    let params = new HttpParams();
    console.log("Amount is " +this.amount)
    console.log("bet_time is " +this.time)
    if(this.singleDate == undefined || this.singleDate == null || this.singleDate =='')
    {
     this.changedate = this.todaydate;
    }
    else
    {
      this.changedate = this.singleDate;
    }
    this.twodbetamountlimitList = [];
    params = params.set('for_date_time', this.changedate).set('number', this.number).set('amount', this.amount).set('bet_time', this.time).set('type', "2D");  
    this.http.get(this.funct.ipaddress + 'betamountLimitation/twodbetamountLimitation-by-params', { params: params, headers: headers })
    .pipe(
      catchError(this.handleError.bind(this))
     )
    .subscribe(
      result => {
        this.dto.Response = {};
        this.dto.Response = result.list;        
        this.total_bet_limit_amount = result.max_amt_Str;
        if(this.total_bet_limit_amount == undefined || this.total_bet_limit_amount ==null || this.total_bet_limit_amount =="")
        {
          this.total_bet_limit_amount = 0;
        }
        this.total_percentage = result.percentage;
        if(this.total_percentage == undefined || this.total_percentage == null || this.total_percentage =="")
        {
          this.total_percentage =0.00;
        }
        this.total_bet_amount_Str = result.total_bet_amount_Str;
        if(this.total_bet_amount_Str == undefined || this.total_bet_amount_Str == null || this.total_bet_amount_Str =="")
        {
          this.total_bet_amount_Str =0;
        }
        if(this.dto.Response == null)
        {
          this.twodbetamountlimitList.diffAmt = 0;
          this.dtTrigger.next();
          this.spinner.hide();
          return;
        }        
        this.twodbetamountlimitList = this.dto.Response;
        for (let i = 0; i < this.twodbetamountlimitList.length; i++) {
          var limitAmt =this.twodbetamountlimitList[i].max_amt_Str.toString().replace(/,/g, "");
          var totalBetAmt =this.twodbetamountlimitList[i].total_bet_amount_Str.toString().replace(/,/g, "");
          this.twodbetamountlimitList[i].diffAmt = parseInt(limitAmt) - parseInt(totalBetAmt);
      }
        this.dtTrigger.next();
        this.spinner.hide();
      }
    );
   
  }

  
  exportExcel()
  {
    this.twodBetRptList = [];
    this.twodbetamountlimitList = [];
    this.search();
    var repType = '';
    var grand_total = 0;
    var id = 'tblTwoD' + this.idIndex;
    var table = $('#' + id).DataTable();
    table.destroy();
    this.idIndex = this.idIndex + 1;
    this.spinner.show();
    this.dto.token = this.storage.retrieve('token');
    this.singleDate = $("#singleDate").val();
    let headers = new HttpHeaders();
    headers = headers.set('Authorization',  this.dto.token);
    let params = new HttpParams();
    console.log("Amount is " +this.amount)
    console.log("bet_time is " +this.time)
    if(this.singleDate == undefined || this.singleDate == null || this.singleDate =='')
    {
     this.changedate = this.todaydate;
    }
    else
    {
      this.changedate = this.singleDate;
    }
    this.twodbetamountlimitList = [];

    params = params.set('for_date_time', this.changedate).set('number', this.number).set('amount', this.amount).set('bet_time', this.time).set('type', "2D").set('frompage',"Export");
    this.http.get(this.funct.ipaddress + 'betamountLimitation/twodbetamountLimitation-by-params', { params: params, headers: headers })
    .pipe(
      catchError(this.handleError.bind(this))
     )  
      .subscribe(data => {
        this.filename_date = this.datePipe.transform( new Date(),'dd-MM-yyyy');
        this.filename = "TwoDBetAmountLimitationReport";
        this.spinner.hide();
        this.dto.Response = {};
        this.dto.Response = data.list;
        this.total_percentage = data.percentage;
        if(this.total_percentage ==undefined || this.total_percentage == null || this.total_percentage =="")
        {
          this.total_percentage =0.00;
        }
        this.total_bet_amount = data.total_bet_amount;
        if(this.total_bet_amount == undefined || this.total_bet_amount == null || this.total_bet_amount =="")
        {
           this.total_bet_amount=0;
        }
        this.total_max_amount = data.max_amt;   
        if(this.total_max_amount == undefined || this.total_max_amount ==null || this.total_max_amount =="")
        {
          this.total_max_amount =0;
        }     
        this.dto.Response.forEach((row: any) => 
        {    
          var result = row.total_bet_amount_Str.toString().replace(/,/g, "");
          grand_total = grand_total + parseInt(result);
          const selectedFields = {
            section: row.section,
            number: row.number,
            max_amt: row.max_amt,
            total_bet_amount: row.total_bet_amount,
            percentage: row.percentage,               
            different: row.max_amt - row.total_bet_amount,  
          };
		      this.twodBetRptList.push(Object.values(selectedFields));
          this.twodBetPdfRptList.push(Object.values(selectedFields));
     });
        var title = '';
        if(this.time == 'null'){
           title = 'TwoD Bet Amount Limited Report (All)';
        }
        else
           title = 'TwoD Bet Amount Limited Report ('+this.time+')';
        const header = ['Time', '2D Number', 'Limited Amount', 'Total Bet Amount', '%','Different Amount'];              
        const workbook = new Workbook();
        const worksheet = workbook.addWorksheet('TwoD Bet Amount Limited Report');          
        const titleRow = worksheet.addRow([title]);
        titleRow.font = { name: 'Corbel', family: 4, size: 16, underline: 'double', bold: true};
        worksheet.addRow([]);
        titleRow.eachCell(cell => {
          cell.alignment = { horizontal: 'center' };
        }); 
        const subTitleRow = worksheet.addRow(['Printed Date/Time : '+this.filename_date]);
        worksheet.mergeCells('A3:D3');
        worksheet.mergeCells('A1:D2');
        subTitleRow.eachCell(cell => {
          cell.alignment = { horizontal: 'center' };
        });                
        const subTitleRow_print = worksheet.addRow(['Printed By : '+this.ad_name]);
        worksheet.mergeCells('A4:D4');
        subTitleRow_print.eachCell(cell => {
          cell.alignment = { horizontal: 'center' };
        });                
        const headerRow = worksheet.addRow(header);                 
          headerRow.eachCell((cell, number) => {
          cell.fill = {
          type: 'gradient',
          gradient: 'angle',                
          degree: 0,                  
          stops: [                    
            {position:0, color:{argb:'ff095108'}},  
            {position:0.5, color:{argb:'ff095108'}},
            {position:1, color:{argb:'ff095108'}}
          ],
          };
          cell.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
          cell.alignment = {horizontal:'center' };
          cell.font= {color:{argb:'FFFFFF'}};              
          });
          
       
          var tot1 = 0;
          var tot2 = 0;
          var tot3 = 0 ;
          var tot4 = 0;
          this.twodBetRptList.forEach(d => 
          {                        
            const row = worksheet.addRow(d);    

            tot1 = this.total_max_amount;           
            row.alignment = {horizontal :'center',wrapText: true}            

            tot2 = this.total_bet_amount;
            row.alignment = {horizontal :'center',wrapText: true}
            
            tot3 = this.total_percentage;
            row.alignment = {horizontal :'center',wrapText: true}

            const qty4 = row.getCell(6).value;
            var result4 = qty4.toString().replace(/,/g, "");
            tot4 = parseInt(result4) + tot4;
            row.alignment = {horizontal :'center',wrapText: true}  
          }
      );
      this.twodBetRptList = [];
      worksheet.getColumn(1).width = 12;
      worksheet.getColumn(1).alignment = { horizontal: 'center', wrapText: true };
      worksheet.getColumn(2).width = 16;
      worksheet.getColumn(2).alignment = { horizontal: 'center', wrapText: true };
      worksheet.getColumn(3).width = 18;
      worksheet.getColumn(3).alignment = { horizontal: 'center', wrapText: true };
      worksheet.getColumn(4).width = 18;
      worksheet.getColumn(4).alignment = { horizontal: 'center', wrapText: true };
      worksheet.getColumn(5).width = 10;
      worksheet.getColumn(5).alignment = { horizontal: 'right', wrapText: true };
      worksheet.getColumn(6).width = 20;
      worksheet.getColumn(6).alignment = { horizontal: 'right', wrapText: true };

      const footerRow = worksheet.addRow([]);
      footerRow.getCell(2).value = 'Grand Total';
      footerRow.getCell(3).value = tot1.toLocaleString();        
      footerRow.getCell(4).value = tot2.toLocaleString();
      footerRow.getCell(5).value = tot3.toLocaleString();
      footerRow.getCell(6).value = tot4.toLocaleString();
      footerRow.getCell(2).fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: 'FFCCFFE5' }
      };
      
      footerRow.getCell(2).border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
      footerRow.getCell(3).fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: 'FFCCFFE5' }
      };
      footerRow.getCell(3).border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } }; 


      footerRow.getCell(2).border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
      footerRow.getCell(4).fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: 'FFCCFFE5' }
      };
      footerRow.getCell(4).border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };  


      footerRow.getCell(2).border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
      footerRow.getCell(5).fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: 'FFCCFFE5' }
      };
      footerRow.getCell(5).border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };

      
      footerRow.getCell(2).border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
      footerRow.getCell(6).fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: 'FFCCFFE5' }
      };
      footerRow.getCell(6).border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
   
      workbook.xlsx.writeBuffer().then((data: any) => {
      const blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      fs.saveAs(blob, this.filename + repType);
      });
     
          this.toastr.success('TwoD Bet Amount Limited Report', 'Success!', {
            timeOut: 3000,
            positionClass: 'toast-top-right',
          });
        this.spinner.hide();
        });
  }

  getCurrentAdmin() {
    this.dto.token = this.storage.retrieve('token');
    let headers = new HttpHeaders();
    headers = headers.set('Authorization',this.dto.token);
    this.http.get(this.funct.ipaddress + 'admin/GetCurrentAdminDetail', { headers: headers }).subscribe(
      result => {
        this.dto.Response = {};
        this.dto.Response = result;
        this.adminObj = this.dto.Response;
        this.ad_name = this.adminObj.name;
      }
    );
  }

  convert(list, pdfname)
  {
        var doc = new jsPDF('p', 'pt','a4');
        const head = [['Time', '2D Number', 'Limited Amount', 'Total Bet Amount', '%','Different Amount']];
        doc.setFontSize(12);
        doc.setTextColor(0);
        var tot = 0;
        var rows1 = [];
        rows1.push("Total = "+40);
        var title = '';
        if(this.time == 'null'){
          title = '2D Bet Amount Limitation Report (All)';
        }
        else
          title = '2D Bet Amount Limitation Report ('+this.time+')';
        doc.text(title, 40, 20);
        doc.text('Printed By : '+this.ad_name+' - Printed Date : '+this.filename_date,40,40);
        autoTable(doc,
        {
        head: head,
        body: list,
        startY: 50,
        styles: { overflow: "linebreak" },
        didDrawCell: (list) => {
        },
        didParseCell:(list)=>{
          var rows = list.table.body;
          if (list.row.index === rows.length - 1) {     
              list.cell.styles.fillColor = [239, 154, 154];       
          }
        }
        })
        doc.save(pdfname);
    }
  ConvertToCSV(objArray, headerList) 
    {
      let array = typeof objArray != 'object' ? JSON.parse(objArray) : objArray;
      let str = '';
      let row = '';
      for (let index in headerList) {
          row += headerList[index] + ',';
      }
      row = row.slice(0, -1);
      str += row + '\r\n';
      for (let i = 0; i < array.length; i++) {
          let line = '';
          for (let index in headerList) {
             let head = headerList[index];
             var d = array[i][head];
             if(d != null)
             {
             if(d.toString().includes(","))
                d = d.toString().replace(/,/g, "");
             }
             line +=  d + ",";
          }
          line = line.slice(0,-1);
          str += line + '\r\n';
      }
    return str;
    }

  downloadFile(data, filename) 
  {
    let csvData = this.ConvertToCSV(data, ['section', 'number', 'max_amt_Str', 'total_bet_amount_Str', 'percentage','max_amt_Str']);
    console.log(csvData)
    let blob = new Blob([csvData], { type: 'text/csv;charset=utf-8;' });
    let dwldLink = document.createElement("a");
    let url = URL.createObjectURL(blob);
    let isSafariBrowser = navigator.userAgent.indexOf('Safari') != -1 && navigator.userAgent.indexOf('Chrome') == -1;
    if (isSafariBrowser) {  
        dwldLink.setAttribute("target", "_blank");
    }
    dwldLink.setAttribute("href", url);
    dwldLink.setAttribute("download", filename + ".csv");
    dwldLink.style.visibility = "hidden";
    document.body.appendChild(dwldLink);
    dwldLink.click();
    document.body.removeChild(dwldLink);
}

  changeSelection(ev, id)
   {
    console.log("id..... " + id);
    var target = ev.target;
    this.confirmallArr = [];

    if (target.checked && id == 0) {
      for (let i = 0; i < this.twodbetamountlimitList.length; i++) {
        this.twodbetamountlimitList[i].status = "check";
      }
    }
    else if (target.checked && id != 0) {
      for (let i = 0; i < this.twodbetamountlimitList.length; i++) {
        if (this.twodbetamountlimitList[i].id == id) {
          this.twodbetamountlimitList[i].status = "check";
        }
      }
    }
    else if (!target.checked && id == 0) {
      for (let i = 0; i < this.twodbetamountlimitList.length; i++) {
        this.twodbetamountlimitList[i].status = "uncheck";
      }
    }
    else if (!target.checked && id != 0) {
      for (let i = 0; i < this.twodbetamountlimitList.length; i++) {
        if (this.twodbetamountlimitList[i].id == id) {
          this.twodbetamountlimitList[i].status = "uncheck";
        }
      }
    }
    console.log("this.twodwinnerList >>" + JSON.stringify(this.twodbetamountlimitList));
    this.checkedData();
  }

  checkedData() {
    for (let i = 0; i < this.twodbetamountlimitList.length; i++) {
      if (this.twodbetamountlimitList[i].status == "check") {
        this.confirmallArr.push(this.twodbetamountlimitList[i].id);
      }
    }
    console.log("this.confirmallArr >>" + JSON.stringify(this.confirmallArr));
  }

  twoDbetdelete(twodbetId) {
    this.token = this.storage.retrieve('token');
    let headers = new HttpHeaders();
    headers = headers.set('Authorization',  this.token);
    let params = new HttpParams();
    this.twodbetId = twodbetId;
    params = params.set('id', this.twodbetId);
    var formData = new FormData();
    formData.append("id",this.twodbetId);
   console.log("Deleted id is : "+this.twodbetId);
    this.http.post(this.funct.ipaddress + 'betamountLimitation/delete', formData, { headers: headers }).subscribe( 
      result => {
        this.dto.Response = {};
        this.dto.Response = result;
        if (this.dto.Response.status == 'Success') {
          this.spinner.hide();
          this.router.navigate(['/twod-bet-amount-limitation-list']).then(() => {
            this.toastr.success(this.dto.Response.message, 'Success!', {
              timeOut: 3000,
              positionClass: 'toast-top-right'
            });
          })
        }
        else {
          this.spinner.hide();
          this.toastr.error(this.dto.Response.message, 'Invalid!', {
            timeOut: 3000,
            positionClass: 'toast-top-right',
          });
        }
      }
    );
  }

  delAll()
  {
    this.token = this.storage.retrieve('token');
    let headers = new HttpHeaders();
    headers = headers.set('Authorization',  this.token);
    let params = new HttpParams();
    if(this.confirmallArr == null)
    {
      this.toastr.error("Please select at least one record", 'Invalid!', {
        timeOut: 3000,
        positionClass: 'toast-top-right',
      });
      return;
    }
    this.twodbetId = this.confirmallArr.toString();
    params = params.set('id', this.twodbetId);
    var formData = new FormData();
    formData.append("id",this.twodbetId);
    this.http.post(this.funct.ipaddress + 'betamountLimitation/delete', formData, { headers: headers }).subscribe( 
      result => {
        this.dto.Response = {};
        this.dto.Response = result;
        if (this.dto.Response.status == 'Success') {
          this.spinner.hide();
          this.router.navigate(['/twod-bet-amount-limitation-list']).then(() => {
            this.toastr.success(this.dto.Response.message, 'Success!', {
              timeOut: 3000,
              positionClass: 'toast-top-right'
            });
          })
        }
        else {
          this.spinner.hide();
          this.toastr.error(this.dto.Response.message, 'Invalid!', {
            timeOut: 3000,
            positionClass: 'toast-top-right',
          });
        }
      }
    );
  }
 
  showeditAllModal()
  {
    if(this.confirmallArr == null)
    {
      this.toastr.error("Please select at least one record", 'Invalid!', {
        timeOut: 3000,
        positionClass: 'toast-top-right',
      });
      return;
    }
    $('#showeditModal').modal("show");
  }

  editAll()
  {
    this.token = this.storage.retrieve('token');
    let headers = new HttpHeaders();
    headers = headers.set('Authorization',  this.token);
    let params = new HttpParams();
   
    this.twodbetId = this.confirmallArr.toString();
    params = params.set('id', this.twodbetId);
    var formData = new FormData();
    formData.append("id",this.twodbetId);
    if(this.editAmt == null || this.editAmt == 0 || this.editAmt == '' || this.editAmt == undefined)
    {
      this.toastr.error("Invalid amount", 'Invalid!', {
        timeOut: 3000,
        positionClass: 'toast-top-right',
      });
      return;
    }
    formData.append("amount",this.editAmt);
    this.http.post(this.funct.ipaddress + 'betamountLimitation/twodbetLimitupdateAll', formData, { headers: headers }).subscribe( 
      result => {
        this.dto.Response = {};
        this.dto.Response = result;
        if (this.dto.Response.status == 'Success') {
          this.spinner.hide();
          this.router.navigate(['/twod-bet-amount-limitation-list']).then(() => {
            this.toastr.success(this.dto.Response.message, 'Success!', {
              timeOut: 3000,
              positionClass: 'toast-top-right'
            });
          })
        }
        else {
          this.spinner.hide();
          this.toastr.error(this.dto.Response.message, 'Invalid!', {
            timeOut: 3000,
            positionClass: 'toast-top-right',
          });
        }
      }
    );
  }
  getAllSection()
  {
    this.http.get(this.funct.ipaddress + 'twodsection/getTwodSectionList').subscribe( 
      result => {
        this.dto.Response = {};
        this.dto.Response = result;
        this.sectionList = this.dto.Response;
      }
    );
  }
}
