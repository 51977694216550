import { Component, OnInit, ViewChild } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,HttpErrorResponse } from '@angular/common/http';
import { Router, ActivatedRoute } from '@angular/router';
import { FormGroup, FormControl, Validator, Validators } from "@angular/forms";
import { LocalStorageService } from 'ngx-webstorage';
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from 'ngx-toastr';
import { DtoService } from '../../service/dto.service';
import { FunctService } from '../../service/funct.service';
import { Subject } from 'rxjs';
import { DatePipe } from '@angular/common';
import * as XLSX from 'xlsx'; 
import * as fs from 'file-saver';
import { Workbook } from 'exceljs';
import { catchError, retry } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { jsPDF } from "jspdf";
import 'jspdf-autotable'
import autoTable from 'jspdf-autotable'
import { IMultiSelectOption,IMultiSelectSettings,IMultiSelectTexts } from 'ngx-bootstrap-multiselect';

declare const getCurrentDate: any;
declare var $: any;

export class AppModule {
}
@Component({
  selector: 'app-first_topup_promotionuser_report',
  templateUrl: './first_topup_promotionuser_report.component.html',
  styleUrls: ['./first_topup_promotionuser_report.component.css'],
  providers: [DatePipe]
})
export class FirstTopupPromotionUserReportComponent implements OnInit {
  @ViewChild('multiSelect') multiSelect;
  report_option : string ='';
  clickExcel: any = true;
  clickPDF: any = false;
  filename : string = "";
  filename_date :any ;
  promotionUserRptList = [];
  promotionUserRptListPDF =[];
  myDate = new Date();
  adminObj : any;
  ad_name : any;
  statusId : any;
  FromDate : any;
  ToDate : any;
  myOptions: IMultiSelectOption[];
  optionsModel: number[];
  mySettings: IMultiSelectSettings;
  myTexts: IMultiSelectTexts;

  hardcode_edit :any;
  hardcode_add :any;
  hardcode_view: any;
  hardcode_delete:any;
  hardcode_view_bool : any;
  hardcode_add_bool : any;
  hardcode_edit_bool : any;
  hardcode_delete_bool:any;
  hardcode_double_bool:any;

  constructor(private toastr: ToastrService,private datePipe: DatePipe,private storage: LocalStorageService, private spinner: NgxSpinnerService, private http: HttpClient, private dto: DtoService, private router: Router, private funct: FunctService,) 
  {

    this.storage.store('isNotiSong', "");
    this.hardcode_add = "FirstTopUpPromotionReport_Add";
    
    this.dto.menuCodes = this.storage.retrieve('menucodes');

    const filteredMenuCodeds_add =[];
  
    this.dto.menuCodes.forEach(menuCode => {
  
      if (menuCode === this.hardcode_add) {
        filteredMenuCodeds_add.push(menuCode);
      }
    
      
    });
   
    if(filteredMenuCodeds_add.length != 0)
    {
          this.hardcode_add_bool = true;
    }
 
    this.filename_date = this.datePipe.transform(this.myDate,'dd-MM-yyyy');
  }

  ngOnInit(): void 
  {
    this.getCurrentAdmin();
    this.getStatus();

    // Settings configuration
    this.mySettings = {
      enableSearch: true,
      checkedStyle: 'checkboxes',
      buttonClasses: 'btn btn-default btn-block',
      dynamicTitleMaxItems: 3,
      displayAllSelectedText: true,
      showCheckAll : true,
      showUncheckAll : true,
      autoUnselect : true,
      pullRight : false,
      closeOnSelect : false
    };

    // Text configuration
    this.myTexts = {
      checkAll: 'Select all',
      uncheckAll: 'Unselect all',
      checked: 'item selected',
      checkedPlural: 'items selected',
      searchPlaceholder: 'Find',
      searchEmptyResult: 'Nothing found...',
      searchNoRenderText: 'Type in search box to see results...',
      defaultTitle: 'Select',
      allSelected: 'All selected',
    };

    if(!this.storage.retrieve('loadFlag')){
      this.storage.store('loadFlag', 'noLoad');
      setTimeout(function(){
        location.reload();
      }, 5);
    }
    else{
      this.storage.clear('loadFlag');
    }

  }

  getCurrentAdmin() {
    this.dto.token = this.storage.retrieve('token');
    let headers = new HttpHeaders();
    headers = headers.set('Authorization',this.dto.token);
    this.http.get(this.funct.ipaddress + 'admin/GetCurrentAdminDetail', { headers: headers }).subscribe(
      result => {
        this.dto.Response = {};
        this.dto.Response = result;
        this.adminObj = this.dto.Response;
        this.ad_name = this.adminObj.name;
      }
    );
  }


  statusList = [{"id":1, "name":"Approved" }, {"id":2, "name":"Pending" }, {"id":3, "name":"Reach Due Date" }, {"id":4, "name":"No Promotion Users" }];

  getStatus() {
    this.myOptions = this.statusList;
  }

  onChange() {
    console.log("in onchange "+ this.optionsModel);
  }

  handleError(error: HttpErrorResponse){
    if(error.status == 423)
    {
      this.spinner.hide();
      $("#deleteData").modal("show");
    }
    if(error.status == 403)
    {
      this.spinner.hide();
      this.toastr.error("Limited Access.", 'Invalid!', {
        timeOut: 3000,
        positionClass: 'toast-top-right',
      });
    }
    return throwError(error);
  }

  OkLogout()
  {
    window.location.href ="./ad-login";
  } 

  getReportOption(options)
  {
    this.report_option = options
    if(options == "excel")
    {
       this.clickExcel =true;
       this.clickPDF = false;
    }
    if(options == "pdf")
    {
       this.clickExcel =false;
       this.clickPDF = true;
    }
  }

  fileName= 'ExcelSheet.xlsx';    
  exportexcel(): void 
  {  
      let element = document.getElementById('excel-table'); 
      const ws: XLSX.WorkSheet =XLSX.utils.table_to_sheet(element);
      const wb: XLSX.WorkBook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
      XLSX.writeFile(wb, this.filename);
  }

  GenerateReport()
  {
    var repType = '';
    var grand_total_balance = 0;
    var grand_total_first_topup_amt = 0;
    var grand_total_point_wallet = 0;
    var grand_total_bet_amount = 0;
    var grand_total_general_game_betAmt = 0;
    var grand_total_totalBet = 0;
    var grand_total_needtotalBet = 0;
    var grand_total_total_topup = 0;
    var grand_total_total_withdraw = 0;

    this.spinner.show();
    this.promotionUserRptList = [];
    this.promotionUserRptListPDF =[];
    this.dto.token = this.storage.retrieve('token');
    if(this.report_option =='')
    {
      this.report_option = 'excel';
    }
    this.filename = "first_topup_promotion_user_report_"+this.filename_date;
    let headers = new HttpHeaders();
    headers = headers.set('Authorization', this.dto.token);

    if(this.FromDate ==undefined || this.FromDate =="" || this.ToDate == undefined || this.ToDate == "")
    {
      this.toastr.error("Please choose date", 'Invalid!', {
        timeOut: 3000,
        positionClass: 'toast-top-right'
      });
      this.spinner.hide();
      return;
    }
    
    if(this.FromDate <= this.ToDate)
    {

    }
    else
    {
      this.toastr.error("Please choose To date Because less than from date", 'Invalid!', {
        timeOut: 3000,
        positionClass: 'toast-top-right'
      });
      this.spinner.hide();
      return;
    }
    
   
    
    var formData = new FormData();
    formData.append("FromDate", this.FromDate);
    formData.append("ToDate", this.ToDate);

    // if( this.statusId == undefined || this.statusId =="")
    // {
    //   this.toastr.error('Please select status', 'Invalid!', {
    //     timeOut: 3000,
    //     positionClass: 'toast-top-right',
    //   });
    //   this.spinner.hide();
    //   return;
    // }

    if(this.optionsModel == undefined)
    {
      this.toastr.error('Please select status', 'Invalid!', {
        timeOut: 3000,
        positionClass: 'toast-top-right',
      });
      this.spinner.hide();
      return;
    }
    else 
    {
      if(this.optionsModel.length > 0)
      {
         this.statusId = this.optionsModel;
      }
      else
      {
        this.toastr.error('Please select status', 'Invalid!', {
          timeOut: 3000,
          positionClass: 'toast-top-right',
        });
        this.spinner.hide();
        return;
      }
    }
   
    formData.append("statusId", this.statusId);

    if(this.report_option == 'excel')
    {
        repType = ".xlsx";
    }
    if(this.report_option == 'pdf')
    {
        repType = ".pdf";
    }

    this.http.post(this.funct.ipaddress + 'userfinancialreport/RetrieveFirstTopupPromotionUserReport', formData, { headers: headers})
    .pipe(
      catchError(this.handleError.bind(this))
    )
    .subscribe(data => {
      this.spinner.hide();
      this.dto.Response = {};
      this.dto.Response = data;
      this.dto.Response.forEach((row: any) => {
        this.promotionUserRptList.push(Object.values(row));
        this.promotionUserRptListPDF.push(Object.values(row));

        var balance = row.balance_Str.toString().replace(/,/g, "");
        grand_total_balance = grand_total_balance + parseInt(balance) ;

        var first_topup_amt = row.first_topup_amt_Str.toString().replace(/,/g, "");
        grand_total_first_topup_amt = grand_total_first_topup_amt + parseInt(first_topup_amt) ;

        var point_wallet = row.point_wallet_Str.toString().replace(/,/g, "");
        grand_total_point_wallet = grand_total_point_wallet + parseInt(point_wallet) ;

        var bet_amount = row.bet_amount_Str.toString().replace(/,/g, "");
        grand_total_bet_amount = grand_total_bet_amount + parseInt(bet_amount) ;

        var general_game_betAmt = row.general_game_betAmt_Str.toString().replace(/,/g, "");
        grand_total_general_game_betAmt = grand_total_general_game_betAmt + parseInt(general_game_betAmt) ;

        var totalBet = row.totalBet_Str.toString().replace(/,/g, "");
        grand_total_totalBet = grand_total_totalBet + parseInt(totalBet) ;

        var total_topup = row.total_topup_Str.toString().replace(/,/g, "");
        grand_total_total_topup = grand_total_total_topup + parseInt(total_topup) ;

        var total_withdraw = row.total_withdraw_Str.toString().replace(/,/g, "");
        grand_total_total_withdraw = grand_total_total_withdraw + parseInt(total_withdraw) ;

        var needtotalBet = row.needtotalBet_Str.toString().replace(/,/g, "");
        grand_total_needtotalBet = grand_total_needtotalBet + parseInt(needtotalBet) ;

      })

      if(this.report_option == "pdf")
      {
        var pdfname = this.filename + repType;
        var totalList = ['','', 'Grand Total', '',grand_total_balance.toLocaleString(),grand_total_first_topup_amt.toLocaleString(),grand_total_point_wallet.toLocaleString(), '',grand_total_bet_amount.toLocaleString(),grand_total_general_game_betAmt.toLocaleString(),grand_total_totalBet.toLocaleString(),grand_total_total_topup.toLocaleString(),grand_total_total_withdraw.toLocaleString(),grand_total_needtotalBet.toLocaleString(), '', '', '', '', ''];
        this.promotionUserRptListPDF.push(totalList);
        this.convert(this.promotionUserRptListPDF,pdfname);
      }


      //amk


      //

      if(this.report_option == "excel")
      {
        var title = '';
        title = 'First Topup Promotion User Report ('+this.FromDate+' to '+this.ToDate+')';
        const header = ['No', 'Name', 'Phone No', 'App Version', 'Wallet' , 'First Topup Amount','Point Wallet','Point Percentage', '2D/3D Bet Amount', 'Game Bet Amount', 'Total Bet Amount', 'Total Topup', 'Total Withdraw', 'Need Bet Amount' , 'Register Date','First Topup Date','Total Days','Updated Date','Updated By'];
        const workbook = new Workbook();
        const worksheet = workbook.addWorksheet('Promotion User Report');
        const titleRow = worksheet.addRow([title]);
        titleRow.font = { name: 'Corbel', family: 4, size: 16, underline: 'double', bold: true };
        titleRow.eachCell((cell) => {
          cell.style = {
            alignment:{horizontal:'center'},
          };
        });
        worksheet.addRow([]);

        const subTitleRow = worksheet.addRow(['Printed Date : '+this.filename_date]);
        worksheet.mergeCells('A3:D3');//H3
         worksheet.mergeCells('A1:D2');//H2
        subTitleRow.eachCell((cell) => {
          cell.style = {
            alignment:{horizontal:'center'},
          };
        });
        const printed_title = worksheet.addRow(['Printed By : '+this.ad_name]);
        worksheet.mergeCells('A4:D4');
        // ('A4:H4');
        printed_title.eachCell((cell) => {
          cell.style = {
            alignment:{horizontal:'center'},
          };
        });
        
        const headerRow = worksheet.addRow(header);
        headerRow.eachCell((cell, number) => {
          cell.fill = {
            type: 'gradient',
            gradient: 'angle',
            degree: 0,
            stops: [
              {position:0, color:{argb:'ff095108'}},
              {position:0.5, color:{argb:'ff095108'}},
              {position:1, color:{argb:'ff095108'}}
            ],
          };
          cell.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
          cell.alignment = {horizontal:'center' };
          cell.font= {color:{argb:'FFFFFF'}};
        });

        this.promotionUserRptList.forEach(d => {
          const row = worksheet.addRow(d);
          // row.alignment = {horizontal :'center',wrapText: true}
          
          row.getCell(5).alignment = {horizontal : 'center'}
          row.getCell(6).alignment = {horizontal : 'center'}
          row.getCell(7).alignment = {horizontal : 'center'}
     


        });

        worksheet.getColumn(2).width = 20;
        worksheet.getColumn(3).width = 15;
        worksheet.getColumn(4).width = 14;
        worksheet.getColumn(5).width = 13;
        worksheet.getColumn(6).width = 18;
        worksheet.getColumn(7).width = 15;
        worksheet.getColumn(8).width = 17;  
        worksheet.getColumn(9).width = 20;
        worksheet.getColumn(10).width = 18;
        worksheet.getColumn(11).width = 18;
        worksheet.getColumn(12).width = 18;
        worksheet.getColumn(13).width = 17;
        worksheet.getColumn(14).width = 18;
        worksheet.getColumn(15).width = 15;
        worksheet.getColumn(16).width = 15;  
        worksheet.getColumn(17).width = 14;
        worksheet.getColumn(18).width = 15;
        worksheet.getColumn(19).width = 15;       
        worksheet.getColumn(2).alignment = { horizontal: 'center', wrapText: true };
        worksheet.getColumn(3).alignment = { horizontal: 'center', wrapText: true };
        worksheet.getColumn(4).alignment = { horizontal: 'center', wrapText: true };
        worksheet.getColumn(5).alignment = { horizontal: 'right', wrapText: true };
        worksheet.getColumn(6).alignment = { horizontal: 'right', wrapText: true };
        worksheet.getColumn(7).alignment = { horizontal: 'right', wrapText: true };
        worksheet.getColumn(8).alignment = { horizontal: 'center', wrapText: true };
        worksheet.getColumn(9).alignment = { horizontal: 'right', wrapText: true };
        worksheet.getColumn(10).alignment = { horizontal: 'right', wrapText: true };
        worksheet.getColumn(11).alignment = { horizontal: 'right', wrapText: true };
        worksheet.getColumn(12).alignment = { horizontal: 'right', wrapText: true };
        worksheet.getColumn(13).alignment = { horizontal: 'right', wrapText: true };
        worksheet.getColumn(14).alignment = { horizontal: 'right', wrapText: true };
        worksheet.getColumn(15).alignment = { horizontal: 'center', wrapText: true };
        worksheet.getColumn(16).alignment = { horizontal: 'center', wrapText: true };
        worksheet.getColumn(17).alignment = { horizontal: 'center', wrapText: true };
        worksheet.getColumn(18).alignment = { horizontal: 'center', wrapText: true };
        worksheet.getColumn(19).alignment = { horizontal: 'center', wrapText: true };
        

        const footerRow = worksheet.addRow([]);
        footerRow.getCell(2).value = 'Grand Total';
        footerRow.getCell(5).value = grand_total_balance.toLocaleString();
        footerRow.getCell(6).value = grand_total_first_topup_amt.toLocaleString();
        footerRow.getCell(7).value = grand_total_point_wallet.toLocaleString();
        footerRow.getCell(9).value = grand_total_bet_amount.toLocaleString();
        footerRow.getCell(10).value = grand_total_general_game_betAmt.toLocaleString();
        footerRow.getCell(11).value = grand_total_totalBet.toLocaleString();
        footerRow.getCell(12).value = grand_total_total_topup.toLocaleString();
        footerRow.getCell(13).value = grand_total_total_withdraw.toLocaleString();
        footerRow.getCell(14).value = grand_total_needtotalBet.toLocaleString();

        footerRow.getCell(1).fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: 'FFCCFFE5' }
        };
        footerRow.getCell(1).border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' } };

        footerRow.getCell(2).fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: 'FFCCFFE5' }
        };
        footerRow.getCell(2).border = { top: { style: 'thin' }, bottom: { style: 'thin' } };

        footerRow.getCell(3).fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: 'FFCCFFE5' }
        };
        footerRow.getCell(3).border = { top: { style: 'thin' }, bottom: { style: 'thin' } };

        footerRow.getCell(4).fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: 'FFCCFFE5' }
        };
        footerRow.getCell(4).border = { top: { style: 'thin' }, bottom: { style: 'thin' } };

        footerRow.getCell(5).fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: 'FFCCFFE5' }
        };
        footerRow.getCell(5).border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };

        footerRow.getCell(6).fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: 'FFCCFFE5' }
        };
        footerRow.getCell(6).border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };

        footerRow.getCell(7).fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: 'FFCCFFE5' }
        };
        footerRow.getCell(7).border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };

        footerRow.getCell(8).fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: 'FFCCFFE5' }
        };
        footerRow.getCell(8).border = { top: { style: 'thin' }, bottom: { style: 'thin' } };

        footerRow.getCell(9).fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: 'FFCCFFE5' }
        };
        footerRow.getCell(9).border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };

        footerRow.getCell(10).fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: 'FFCCFFE5' }
        };
        footerRow.getCell(10).border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };

        footerRow.getCell(11).fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: 'FFCCFFE5' }
        };
        footerRow.getCell(11).border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };

        footerRow.getCell(12).fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: 'FFCCFFE5' }
        };
        footerRow.getCell(12).border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };

        footerRow.getCell(13).fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: 'FFCCFFE5' }
        };
        footerRow.getCell(13).border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };

        footerRow.getCell(14).fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: 'FFCCFFE5' }
        };
        footerRow.getCell(14).border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };

        footerRow.getCell(15).fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: 'FFCCFFE5' }
        };
        footerRow.getCell(15).border = { top: { style: 'thin' }, bottom: { style: 'thin' } };

        footerRow.getCell(16).fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: 'FFCCFFE5' }
        };
        footerRow.getCell(16).border = { top: { style: 'thin' }, bottom: { style: 'thin' } };

        footerRow.getCell(17).fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: 'FFCCFFE5' }
        };
        footerRow.getCell(17).border = { top: { style: 'thin' }, bottom: { style: 'thin' }};

        footerRow.getCell(18).fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: 'FFCCFFE5' }
        };
        footerRow.getCell(18).border = { top: { style: 'thin' }, bottom: { style: 'thin' } };

        footerRow.getCell(19).fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: 'FFCCFFE5' }
        };
        footerRow.getCell(19).border = { top: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };


      
        workbook.xlsx.writeBuffer().then((data: any) => {
          const blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
          fs.saveAs(blob, this.filename + repType);
        });
      }

      this.toastr.success('First Topup Promotion User Report', 'Success!', {
        timeOut: 3000,
        positionClass: 'toast-top-right',
      });
      this.spinner.hide();
    });
  }

  convert(list, pdfname){
    var doc = new jsPDF('p', 'pt','a4');
    const head = [['No', 'Name', 'Phone No', 'App Version', 'Wallet', 'First Topup Amount','Point Wallet','Point Percentage', '2D/3D Bet Amount', 'Game Bet Amount', 'Total Bet Amount', 'Total Topup', 'Total Withdraw', 'Need Bet Amount', 'Register Date','First Topup Date','Total Days','Updated Date','Updated By']];
    doc.setFontSize(12);
    doc.setTextColor(0);
    var title = '';    
    title = 'First Topup Promotion User Report ('+this.FromDate+' to '+this.ToDate+')';
    doc.text(title, 40, 20);
    doc.text('Printed By : '+this.ad_name+' - Printed Date : '+this.filename_date,40,40);
    autoTable(doc,
      {
        head: head,
        body: list,
        startY: 50,
        styles: { overflow: "linebreak" },
        didDrawCell: (list) => {},
        didParseCell:(list)=>{
          var rows = list.table.body;
          if (list.row.index === rows.length - 1) {
            
          }
        }
      })
    doc.save(pdfname);
  }
}
