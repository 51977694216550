<div class="wrapper">
  <!-- Navbar -->
  <app-header-sidebar></app-header-sidebar>

  <!-- Content Wrapper. Contains page content -->
  <div class="content-wrapper">
    <!-- Content Header (Page header) -->
    <div class="content-header">
      <div class="container-fluid">
      </div>
    </div>
    <!-- /.content-header -->

    <!-- Main content -->
    <section class="content">
      <div class="container-fluid">
        <form>
          <div class="row">
            <div class="col-md-12">
              <div class="card">
                <div class="card-header">
                  <h3 class="card-title" style="font-weight: bold;"> 2D History Log </h3>
                </div>

                <div class="card-body">
                  <form>

                    <div class="row">
                      <label class="col-md-2"> Section </label>
                      <div class="form-group col-md-3">
                        <select class="form-control form-control-sm col-md-12 col-sm-4 col-xs-4"
                          [(ngModel)]="historyLog.section" [ngModelOptions]="{standalone: true}" *ngIf="objectId == null" >
                          <option value="Null">-- Please Select --</option>
                          <option value="10:30 AM">10:30 AM</option>
                          <option value="12:01 PM">12:01 PM</option>
                          <option value="02:30 PM">02:30 PM</option>
                          <option value="04:30 PM">04:30 PM</option>
                        </select>
                        <input class="form-control form-control-sm" [(ngModel)]="historyLog.section"
                          [ngModelOptions]="{standalone: true}" *ngIf="objectId != null" disabled>
                      </div>
                    </div>

                    <div class="row">
                      <label class="col-md-2"> Set </label>
                      <div class="form-group col-md-3">
                        <input class="form-control form-control-sm" [(ngModel)]="historyLog.set"
                          [ngModelOptions]="{standalone: true}">
                      </div>
                    </div>

                    <div class="row">
                      <label class="col-md-2"> Value </label>
                      <div class="form-group col-md-3">
                        <input class="form-control form-control-sm" [(ngModel)]="historyLog.value"
                          [ngModelOptions]="{standalone: true}">
                      </div>
                    </div>

                    <div class="row">
                      <label class="col-md-2"> Result </label>
                      <div class="form-group col-md-3">
                        <input class="form-control form-control-sm" [(ngModel)]="historyLog.result"
                          [ngModelOptions]="{standalone: true}">
                      </div>
                    </div>

                    <div class="row">
                      <div class="col-md-2 col-sm-6">
                        <button type="button" class="btn btn-block btn-success" (click)="goSave()" *ngIf="historyLog.objectId == null" >Save</button>
                        <button type="button" class="btn btn-block btn-success" (click)="goSave()" *ngIf="historyLog.objectId != null" >Save Changes</button>&nbsp;
                      </div>
                      <div class="col-md-2 col-sm-6">
                        <button type="button" class="btn btn-block btn-danger"
                          [routerLink]="['/twod-manual-detail']">Cancel</button>
                      </div>
                    </div>
                  </form>
                </div>

              </div>
            </div>
          </div>
        </form>
      </div>
      <!--/. container-fluid -->
    </section>
    <!-- /.content -->
  </div>

  <aside class="control-sidebar control-sidebar-dark">
    <!-- Control sidebar content goes here -->
  </aside>

  <app-footer></app-footer>

  <ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#fff" type="square-jelly-box">
    <p style="font-size: 16px; color: white">Loading...</p>
  </ngx-spinner>

</div>

<div id="deleteData" class="modal fade">
  <div class="modal-dialog">
      <div class="modal-content">

          <div class="modal-body">
              <form>

                  <div class="row" style="margin-top: 5%;margin-bottom: 3%;margin-left: 9%;">
                      <div class="form-group col-md-12">
                          <h5> This Account is used by another device. 
                              Please Sign Out. Login Again. </h5>
                      </div>
                  </div>

                  <div class="row" style="margin-left: 40%;margin-bottom: 3%;">
                      <div class="col-md-5 col-sm-6" id="deleteBtn">
                          <button type="button" class="btn btn-block btn-success" (click)="OkLogout()"> Ok </button>
                      </div>
                  </div>
              </form>
          </div>

      </div>
  </div>
</div>