<div class="wrapper">
    <app-header-sidebar></app-header-sidebar>
    <div class="content-wrapper">
        <div class="content-header">
            <div class="container-fluid">
            </div>
        </div>

        <section class="content">
            <div class="container-fluid">
                <form>
                    <div class="row">
                        <div class="col-md-12">
                            <div class="card">
                                <div class="card-header">
                                    <h3 class="card-title" style="font-weight: bold;"> Check Game User Name/ User Account  </h3>
                                </div>
                                <div class="card-body">
                                    <form>
                                      <div class="row">
                                        <div class="col-md-12">                                    
                                            <div class="row">
                                                <label class="col-md-2"> Phone Number </label>
                                                <div class="form-group col-md-3">
                                                    <input type = "text" class="form-control form-control-sm" [(ngModel)]="PhoneNumber"  [ngModelOptions]="{standalone: true}" id = "PhoneNumber" placeholder="09*********">
                                                </div>                                                 
                                            </div>

                                            <div class="row">
                                                <label class="col-md-2"> Game UserName </label>
                                                <div class="form-group col-md-3">
                                                    <input type = "text" class="form-control form-control-sm" [(ngModel)]="gameusername"  [ngModelOptions]="{standalone: true}" id = "gameusername" placeholder="shtm********">
                                                </div>                                                 
                                            </div>
                                          
                                            <div class="row">
                                                <label class="col-md-2"> Game Provider </label>
                                                <div class="form-group col-md-3">
                                                    <select class="form-control form-control-sm col-md-12 col-sm-4 col-xs-4"
                                                            [(ngModel)]="GameProvider" [ngModelOptions]="{standalone: true}">                                                            
                                                            <option *ngFor="let gp of GameProviderList" value = "{{gp.provider_id}}" >
                                                            {{gp.display_name}}
                                                            </option>
                                                            <!-- <option value = "">All</option> -->
                                                    </select>                                              
                                                </div>                                                 
                                            </div>

                                            <div class="row" style="margin-top: 1%;" *ngIf="hardcode_edit_bool === true">
                                                <div class="col-md-2 col-sm-3"></div>
                                                <div class="col-md-2 col-sm-3">
                                                    <button type="button" class="btn btn-block btn-success"  (click)="check()"> Check </button>&nbsp;
                                                </div>
                                            </div>   

                                            <div class="row" *ngIf="isPhoneNo == false">
                                                <div class="col-md-2 col-sm-3"></div>
                                                <label class="col-md-4"> {{resultData}} </label>                                                                                            
                                            </div>  
                                            <div class="row" *ngIf="isPhoneNo == true">
                                                <div class="col-md-2 col-sm-3"></div>
                                                <label class="col-md-4"> 
                                                    <a [routerLink] = "['/user-detail', id]" [queryParams]="{ type: 'gameusername' }" class="underlined-link">{{resultData}}</a> </label>                                                                                            
                                            </div>   

                                            
                                            
                                        </div>                            
                                      </div>
                                    </form> 
                                </div>                                
                            </div>
                        </div>
                    </div>
                </form>              
            </div>          
        </section>
    </div>

    <aside class="control-sidebar control-sidebar-dark">
        <!-- Control sidebar content goes here -->
    </aside>

    <app-footer></app-footer>

    <ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#fff" type="square-jelly-box">
        <p style="font-size: 16px; color: white">Loading...</p>
    </ngx-spinner>

</div>


<div id="deleteData" class="modal fade">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-body">
                <form>
                    <div class="row" style="margin-top: 5%;margin-bottom: 3%;margin-left: 9%;">
                        <div class="form-group col-md-12">
                            <h5> This Account is used by another device. 
                                Please Sign Out. Login Again. </h5>
                        </div>
                    </div>

                    <div class="row" style="margin-left: 40%;margin-bottom: 3%;">
                        <div class="col-md-5 col-sm-6" id="deleteBtn">
                            <button type="button" class="btn btn-block btn-success" (click)="OkLogout()"> Ok </button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>