import { Component, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,HttpErrorResponse } from '@angular/common/http';
import { Router, ActivatedRoute   } from '@angular/router';

import { LocalStorageService } from 'ngx-webstorage';
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from 'ngx-toastr';

import { DtoService } from '../../service/dto.service';
import { FunctService } from '../../service/funct.service';
import { Subject } from 'rxjs';
declare var $: any;

import { catchError, retry } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { analyzeAndValidateNgModules, ThrowStmt } from '@angular/compiler';

@Component({
  selector: 'app-comment-detail',
  templateUrl: './twod-package-detail.component.html',
  styleUrls: ['./twod-package-detail.component.css']
})
export class TwodPackageDetailComponent implements OnInit {

  dtTrigger: Subject<any> = new Subject();
  idIndex: any;
  dtOptions: DataTables.Settings = {};
  bankslipDTO: any;
  token: any;
  id: any;
  reply: any;
  fieldTextType: boolean;
  confrimFieldTextType: boolean;
  roleList = [];
  imgURL:any;
  bsstatus:any;
  constructor(private storage: LocalStorageService, private spinner: NgxSpinnerService, private toastr: ToastrService, private http: HttpClient, private dto: DtoService, 
    private router: Router, private route: ActivatedRoute, private funct: FunctService) { 
      this.idIndex = 1;
      this.storage.store('isNotiSong', "");
    }

  ngOnInit(): void {

    this.dtOptions = {
      responsive: true,
      order: []
    }

    this.dtOptions.columnDefs = [
      { targets: [4], orderable: false }
    ];
    if(!this.storage.retrieve('loadFlag')){
      this.storage.store('loadFlag', 'noLoad');
      setTimeout(function(){
        location.reload();
      }, 1);
    }
    else{
      this.storage.clear('loadFlag');
    }

    this.id = this.route.snapshot.paramMap.get("id");
    this.bankslipDTO = {
      id: 0,
      package_name:'',
      package_amount: 0,
      package_odd:0,
      user_limit:0,
      total_number:0,
      status:0,
      created_date:new Date(),
      created_date_str:'',
      created_by:'',
      updated_by:'',
      updated_date:new Date(),
      updated_date_str:'',
    };
    this.getPackageById();

  }
  handleError(error: HttpErrorResponse){
    if(error.status == 423)
    {
      this.spinner.hide();
      $("#deleteData").modal("show");
    }
    if(error.status == 403)
    {
      this.spinner.hide();
      var id = 'tblComment' + this.idIndex;
      var table = $('#' + id).DataTable();
      this.toastr.error("Limited Access.", 'Invalid!', {
        timeOut: 3000,
        positionClass: 'toast-top-right',
        });
    }
    if(error.status == 400)
    {
      this.spinner.hide();
      this.toastr.error("Invalid Parameters.", 'Invalid!', {
        timeOut: 3000,
        positionClass: 'toast-top-right',
        });
    }
    return throwError(error);
    }
    OkLogout()
    {
      window.location.href ="./ad-login";
    } 

  getPackageById(){
    this.token = this.storage.retrieve('token');
    let headers = new HttpHeaders();
    headers = headers.set('Authorization',  this.token);
    let params = new HttpParams();
    params = params.set('package_id',this.id);
    this.http.get(this.funct.ipaddress + 'twodpackage/GetByid', {params: params, headers: headers })
    .pipe(
      catchError(this.handleError.bind(this))
     )
    .subscribe(
      result => {
        this.dto.Response = {};
        this.dto.Response = result;
        this.bankslipDTO = this.dto.Response;
      }
    );
  }
  toggleFieldTextType() {
    this.fieldTextType = !this.fieldTextType;
  }

  toggleConfrimFieldTextType() {
    this.confrimFieldTextType = !this.confrimFieldTextType;
  }

  goSave(){
    this.token = this.storage.retrieve('token');
  let headers = new HttpHeaders();
  headers = headers.set('Authorization',  this.token);
  let params = {
    id : this.id,
    package_name : this.bankslipDTO.package_name,
    package_amount : this.bankslipDTO.package_amount,
    package_odd : this.bankslipDTO.package_odd,
    user_limit : this.bankslipDTO.user_limit,
    total_number : this.bankslipDTO.total_number,
    status : this.bankslipDTO.status
  };
  this.http.post(this.funct.ipaddress + 'twodpackage/update',params,{  headers: headers })
  .pipe(
    catchError(this.handleError.bind(this))
   )
  .subscribe(
    result => {
      this.dto.Response = {};
    this.dto.Response = result;
    if (this.dto.Response.status == 'Success') {
      this.spinner.hide();
      this.router.navigate(['/twod-package-list']).then(() => {
        this.toastr.success(this.dto.Response.message, 'Success!', {
          timeOut: 3000,
          positionClass: 'toast-top-right'
        });
      })
    }
    else{
      this.spinner.hide();
      this.toastr.error(this.dto.Response.message, 'Invalid!', {
      timeOut: 3000,
      positionClass: 'toast-top-right',
      });
    }
    }
  );
  }
}
