
                    <div class="row">
                        <div class="form-group col-md-2 col-sm-4 col-xs-4" style="display: none;" *ngIf="hardcode_add_bool === true">
                            <label></label>
                            <button type="button" class="btn btn-block btn-success btn-sm" style="margin-top: 4%;"
                                [routerLink]="['/gs-game-detail']">
                                Add New
                            </button>
                        </div>
                        <div class="col-md-12">
                            <div class="card">
                                <div class="card-header">
                                    <h3 class="card-title" style="font-weight: bold;"> Twod Section List</h3>
                                </div>
                           
                                <div class="card-body">
                                    <table width='100%' id="{{'tbl2dsection' + idIndex}}" datatable
                                        [dtTrigger]="dtTrigger" [dtOptions]="dtOptions"
                                        class="table table-bordered table-hover">
                                        <thead>
                                            <tr>
                                                <th style="font-family: unicode;">Section Name</th>
                                                <th style="font-family: unicode;">From Time</th> 
                                                <th style="font-family: unicode;">To Time</th> 
                                                <th style="font-family: unicode;">Result Time</th> 
                                          
                                                <th style="font-family: unicode;">Updated Date</th> 
                                                <th style="font-family: unicode;">Updated By</th> 
                                                <th style="font-family: unicode;" *ngIf="hardcode_edit_bool === true">Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr *ngFor="let slist of sectionList">
                                                <td style="font-family: unicode;">{{slist.sectionName}}</td>
                                                <td style="font-family: unicode;">{{slist.fromTime}}</td> 
                                                <td style="font-family: unicode;">{{slist.toTime}}</td> 
                                                <td style="font-family: unicode;">{{slist.resultTime}}</td> 
                                           
                                                <td style="font-family: unicode;">{{slist.updated_date_str}}</td>
                                                <td style="font-family: unicode;">{{slist.updated_user}}</td>
                                                <td style="font-family: unicode;" *ngIf="hardcode_edit_bool === true">
                                                    <a [routerLink]="['/twod-section-detail', slist.id]">
                                                    <i title = "Edit" class="fas fa-edit" style="color:#4b88eb;cursor: pointer;"></i>
                                                    </a>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                         
                            </div>
                        </div>
                    </div>
             



    <ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#fff" type="square-jelly-box">
        <p style="font-size: 16px; color: white">Loading...</p>
    </ngx-spinner>



  <div id="deleteData" class="modal fade">
    <div class="modal-dialog">
        <div class="modal-content">

            <div class="modal-body">
                <form>

                    <div class="row" style="margin-top: 5%;margin-bottom: 3%;margin-left: 9%;">
                        <div class="form-group col-md-12">
                            <h5> This Account is used by another device. 
                                Please Sign Out. Login Again. </h5>
                        </div>
                    </div>

                    <div class="row" style="margin-left: 40%;margin-bottom: 3%;">
                        <div class="col-md-5 col-sm-6" id="deleteBtn">
                            <button type="button" class="btn btn-block btn-success" (click)="OkLogout()"> Ok </button>
                        </div>
                    </div>
                </form>
            </div>

        </div>
    </div>
</div>