import { Component, OnInit, ViewChild } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams ,HttpErrorResponse} from '@angular/common/http';
import { DataTableDirective } from 'angular-datatables';
import { Router, NavigationEnd } from '@angular/router';

import { catchError, retry } from 'rxjs/operators';
import { throwError } from 'rxjs';

import { LocalStorageService } from 'ngx-webstorage';
import { NgxSpinnerService } from "ngx-spinner";
import { DatePipe } from '@angular/common'
import { ToastrService } from 'ngx-toastr';

import { DtoService } from '../../service/dto.service';
import { FunctService } from '../../service/funct.service';
import Responsive from 'datatables.net-responsive'; 

import { Subject } from 'rxjs';
declare var $: any;

@Component({
  selector: 'app-twodbet-cancel-list',
  templateUrl: './twodbet-cancel-list.component.html',
  styleUrls: ['./twodbet-cancel-list.component.css']
})
export class TwodbetCancelRecordComponent implements OnInit {

  @ViewChild(DataTableDirective)
  dtElement: DataTableDirective;
  dtOptions: DataTables.Settings = {};
  dtOptions2: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();
  dtTrigger2: Subject<any> = new Subject();

  singleDate: string = '';
  status: string= '';
  twodbetList: any;
  idIndex: any;
  time: string= '';
  orderby:string='';
  phoneNo: '';
  userList: [];
  token: any;
  sectionList : any;
  packageList: any;

  idIndex2: any;
  id;
  phoneNo2: '';
  totalAmount;
  createdDate: '';
  userName: '';
  twoDBetDetailDTOList;
  twoDBetDetailDTOList_temp : any;
  date : any;
  todayDate : any;
  changeDate : any;
  packageType:any;
  bet_time:any;
  ordermethod:string='';

  itemsPerPage =  10;
  totalItems : any; 
  page = 1;
  
  hardcode_view : any;
  hardcode_delete: any;
  hardcode_delete_bool : any;
  hardcode_view_bool : any;



  constructor(  private toastr: ToastrService, private storage: LocalStorageService,private spinner: NgxSpinnerService, private dto: DtoService, 
    private http: HttpClient, private funct: FunctService, private router: Router, private datepipe: DatePipe,) 
    { 
      this.storage.store('isNotiSong', "");
    this.phoneNo = '';
    this.idIndex = 1;
    this.idIndex2 = 1;
    this.date = new Date();
    this.todayDate = this.datepipe.transform(this.date, 'MMM dd, yyyy');
    this.time="null";
    this.packageType="null";
    
    this.hardcode_view = "BetHistory_View";
    this.hardcode_delete = "BetHistory_Cancel";
 
    this.dto.menuCodes = this.storage.retrieve('menucodes');

    const filteredMenuCodes_delete = [];
    const filteredMenuCodeds_view =[];
    this.dto.menuCodes.forEach(menuCode =>
       {
      if (menuCode === this.hardcode_delete) {
        filteredMenuCodes_delete.push(menuCode);
      }   
      if (menuCode === this.hardcode_view) {
        filteredMenuCodeds_view.push(menuCode);
      }
   
    });
        if(filteredMenuCodes_delete.length != 0)
        {
            this.hardcode_delete_bool = true;
        }
        else
        {
          this.hardcode_delete_bool = false;
        }    
     
        if(filteredMenuCodeds_view.length != 0)
        {
            this.hardcode_view_bool = true;
        }
        else
        {
          this.hardcode_view_bool = false;
        }
    this.getAllSection();
    this.getPackageList();
    this.search();
  }

  ngOnInit(): void {

    this.router.routeReuseStrategy.shouldReuseRoute = function () {
      return false;
    };

    this.router.events.subscribe((evt) => {
      if (evt instanceof NavigationEnd) {
        // trick the Router into believing it's last link wasn't previously loaded
        this.router.navigated = false;
        // if you need to scroll back to top, here is the right place
        window.scrollTo(0, 0);
      }
    });

    this.twoDBetDetailDTOList = [];

    this.dtOptions = {
      // responsive: {
      //   details: {
      //       renderer: Responsive.renderer.listHiddenNodes()
      //   },
      // },
      order:[],
      paging:false
    }

    this.dtOptions2 = {
      responsive: true,
      order:[],
    
    }

    this.dtOptions.columnDefs = [
    ];

    this.dtOptions2.columnDefs = [
     ];

    
    if(!this.storage.retrieve('loadFlag')){
      this.storage.store('loadFlag', 'noLoad');
      setTimeout(function(){
        location.reload();
      }, 5);
    }
    else{
      this.storage.clear('loadFlag');
    }

  }

  onChangeSingle(){
    $(document).ready(function () {
      this.singleDate = $("#singleDate").val();
    });
  }


  getActiveUsers() {
    this.token = this.storage.retrieve('token');
    let headers = new HttpHeaders();
    headers = headers.set('Authorization',  this.token);
    this.http.get(this.funct.ipaddress + 'user/active-users', { headers: headers }).subscribe(
      result => {
        this.dto.Response = {};
        this.dto.Response = result;
        this.userList = this.dto.Response.data.userDTOList;
      }
    );
  }

  handleError(error: HttpErrorResponse){
    if(error.status == 423)
    {
      this.spinner.hide();
      $("#deleteData").modal("show");
    }
    if(error.status == 403)
    {
      this.spinner.hide();
      var id = 'tbltwodbet' + this.idIndex;
      var table = $('#' + id).DataTable();
      this.toastr.error("Limited Access.", 'Invalid!', {
        timeOut: 3000,
        positionClass: 'toast-top-right',
        });
    }
    return throwError(error);
    }
    OkLogout()
    {
      window.location.href ="./ad-login";
    } 
    
  onInput(event: InputEvent) {
    const myanmarCharactersRegex = new RegExp('^[\u1000-\u109F\uAA60-\uAA7F\uA9E0-\uA9FF]+$');
    const inputValue = (event.target as HTMLInputElement).value; 
    if(event.data!=null)
    {
      if (myanmarCharactersRegex.test(inputValue)) 
      {        
        this.spinner.hide();
        this.toastr.error( "Myanmar typing detected!", '', {
        timeOut: 5000,
        positionClass: 'toast-top-right',
        });  
      }
    }   
  }
  search(){
    this.spinner.show();
    this.twodbetList = [];
    var id = 'tbltwodbet' + this.idIndex;
    var table = $('#' + id).DataTable();
    table.destroy();
    this.idIndex = this.idIndex +1;
    this.dto.token = this.storage.retrieve('token');
    this.singleDate = $("#singleDate").val();
    if(this.singleDate == null || this.singleDate == 'undefined')
    {
      this.changeDate = this.todayDate;
    }
    else
     this.changeDate = this.singleDate;
    let headers = new HttpHeaders();
    headers = headers.set('Authorization', this.dto.token);
    let params = new HttpParams();
    params = params.set('createdDate',this.changeDate).set('time',this.time).set('phoneNo',this.phoneNo).set('packageType',this.packageType).set('orderByField',this.orderby).set('orderMethod',this.ordermethod).set("pageNumber","1").set("rowsOfPage","10"); 
    this.http.get(this.funct.ipaddress + 'twodbet/GetCancelListbyParams', {params: params, headers: headers })
    .pipe(
      catchError(this.handleError.bind(this))
     )
    .subscribe(
      result => {
        this.dto.Response = {};
        this.dto.Response = result.results;
        this.twodbetList = this.dto.Response;
        this.totalItems = result.totalRows;
        this.dtTrigger.next();
        this.spinner.hide();
      }
    );
  }

  qty(page:any){
    this.spinner.show();
    this.twodbetList = [];
    var id = 'tbltwodbet' + this.idIndex;
    var table = $('#' + id).DataTable();
    table.destroy();
    this.idIndex = this.idIndex +1;
    this.dto.token = this.storage.retrieve('token');
    this.singleDate = $("#singleDate").val();
    if(this.singleDate == null || this.singleDate == 'undefined')
    {
      this.changeDate = this.todayDate;
    }
    else
     this.changeDate = this.singleDate;
    let headers = new HttpHeaders();
    headers = headers.set('Authorization', this.dto.token);
    let params = new HttpParams();
    params = params.set('createdDate',this.changeDate).set('time',this.time).set('phoneNo',this.phoneNo).set('packageType',this.packageType).set('orderByField',this.orderby).set('orderMethod',this.ordermethod).set("pageNumber","1").set("rowsOfPage","10").set("pageNumber",page.toString()).set("rowsOfPage",this.itemsPerPage.toString()); 
    this.http.get(this.funct.ipaddress + 'twodbet/GetCancelListbyParams', {params: params, headers: headers })
    .pipe(
      catchError(this.handleError.bind(this))
     )
    .subscribe(
      result => {
        this.dto.Response = {};
        this.dto.Response = result.results;
        this.twodbetList = this.dto.Response;
        this.totalItems = result.totalRows;
        this.dtTrigger.next();
        this.spinner.hide();
      }
    );
  }

  goModal(id, phoneNo, userName, totalAmount, createdDate,bet_time){
    var id1 = 'tbltwodbetdetail' + this.idIndex2;
    var table = $('#' + id1).DataTable();
    table.destroy();
    this.idIndex2 = this.idIndex2 +1;

    this.spinner.show();

    this.id = id;
    this.phoneNo2 = phoneNo;
    this.userName = userName;
    this.totalAmount = totalAmount;
    this.createdDate = createdDate;
    this.bet_time=bet_time;
    this.token = this.storage.retrieve('token');
    let headers = new HttpHeaders();
    headers = headers.set('Authorization', this.token);
    let params = new HttpParams();
     params = params.set('id', id);
    this.http.get(this.funct.ipaddress + 'twodbet/GetCancelTwodbetDetail', { params: params, headers: headers })
    .pipe(
      catchError(this.handleError.bind(this))
     )
    .subscribe(
      result => {
        this.dto.Response = {};
        this.dto.Response = result;
        this.twoDBetDetailDTOList_temp = this.dto.Response;
        this.twoDBetDetailDTOList = this.twoDBetDetailDTOList_temp.results;
        this.dtTrigger2.next();
      }); 
    this.spinner.hide();
    $('#browseAccountData').modal("show");
  }
  getAllSection()
  {
    this.http.get(this.funct.ipaddress + 'twodsection/getTwodSectionList').subscribe( 
      result => {
        this.dto.Response = {};
        this.dto.Response = result;
        this.sectionList = this.dto.Response;
      }
    );
  }
  getPackageList()
  {
    this.dto.token = this.storage.retrieve('token');
    let headers = new HttpHeaders();
    headers = headers.set('Authorization',  this.dto.token);
    this.http.get(this.funct.ipaddress + 'twodpackage/GetList', { headers: headers })
            .pipe(
              catchError(this.handleError.bind(this))
            )
            .subscribe(
              result => {
                this.dto.Response = {};
                this.dto.Response = result;
                this.packageList = this.dto.Response;
              });
  }
}
