import { Component, OnInit } from '@angular/core';
@Component({
  selector: 'app-drm-history',
  templateUrl: './drm-history.component.html',
  styleUrls: ['./drm-history.component.css']
})
export class DrmHistoryComponent implements OnInit {
 constructor() { 
  }

  ngOnInit(): void {
  }


}
