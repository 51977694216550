<div class="wrapper">
    <!-- Navbar -->
    <app-header-sidebar></app-header-sidebar>

    <!-- here here here -->
    <!-- Content Wrapper. Contains page content -->
    <div class="content-wrapper">
        <!-- Content Header (Page header) -->
        <div class="content-header">
            <div class="container-fluid">
                <!--<div class="row mb-2">
                    <div class="col-sm-6">
                        <h1 class="m-0 text-dark">2D Result</h1>
                    </div>
                    <div class="col-sm-6">
                        <ol class="breadcrumb float-sm-right">
                            <li class="breadcrumb-item"><a [routerLink]="['/twod-list']">2D Result List</a></li>
                            <li class="breadcrumb-item active">2D Result Detail</li>
                        </ol>
                    </div> 
                </div> -->
                <!-- /.row -->
            </div><!-- /.container-fluid -->
        </div>
        <!-- /.content-header -->

        <!-- Main content -->
        <section class="content">
            <div class="container-fluid">
                <form>
                    <div class="row">
                        <div class="col-md-12">
                            <div class="card">
                                <div class="card-header">
                                    <h3 class="card-title" style="font-weight: bold;"> 2D Live Result Detail </h3>
                                </div>
                                <!-- /.card-header -->
                                <div class="card-body">
                                    <form>

                                        <div class="row">
                                            <label class="col-md-2">Set</label>
                                            <div class="form-group col-md-3">
                                                <input type="text"
                                                    class="form-control form-control-sm" [(ngModel)]="resultDTO.set" id = "set"
                                                    [ngModelOptions]="{standalone: true}"
                                                   >
                                            </div>
                                        </div>
    
                                        <div class="row">
                                            <label class="col-md-2">Value</label>
                                            <div class="form-group col-md-3">
                                                <input type="text" (keyup)="GenerateNumber($event)" id = "resValue"
                                                    class="form-control form-control-sm" [(ngModel)]="resultDTO.value"
                                                    [ngModelOptions]="{standalone: true}"
                                                   >
                                                   <p id ="errMsg"></p>
                                            </div>
                                        </div>

                                        <div class="row">
                                            <label class="col-md-2">Number</label>
                                            <div class="form-group col-md-3">
                                                <input type="text" onKeyPress="if(this.value.length==2) return false;"
                                                    class="form-control form-control-sm" [(ngModel)]="resultDTO.number"
                                                    [ngModelOptions]="{standalone: true}"
                                                    (keypress)="numericOnly($event)">
                                            </div>
                                        </div>

                                       <div class="row">
                                            <label class="col-md-2">Date</label>
                                            <div class="form-group col-md-3">
                                                <input id="singleDate" readonly class="form-control form-control-sm"
                                                    placeholder="Select Date" [ngxDatePicker]="dateInstanceSingle"
                                                    [value]="singleDate"  [(ngModel)]="tdate"  [ngModelOptions]="{standalone: true}"
                                                    (valueChange)="onChangeSingle()"
                                                    >
                                                <ngx-date-picker #dateInstanceSingle></ngx-date-picker>
                                            </div>
                                        </div>

                                    <div class="row">
                                        <label class="col-md-2">Time</label>
                                        <div class="form-group col-md-3">
                                         <select class="time form-control form-control-sm col-md-12 col-sm-4 col-xs-4" [(ngModel)]="resultDTO.time"
                                                [ngModelOptions]="{standalone: true}">
                                                <option value="null">Please select</option>
                                                <option value="10:30 AM" selected>10:30 AM</option>
                                                <option value="12:01 PM">12:01 PM</option>
                                                <option value="02:30 PM">02:30 PM</option>
                                                <option value="04:30 PM">04:30 PM</option>
                                        </select>
                                      </div>
                                    </div>


                                    <div class="row">
                                        <label class="col-md-2">Update 2D3DLive App? </label>
                                        <div class="form-group col-md-3">
                                         <select class="time form-control form-control-sm col-md-12 col-sm-4 col-xs-4" [(ngModel)]="resultDTO.isManual"
                                                [ngModelOptions]="{standalone: true}">
                                                <option value="yes">Yes</option>
                                                <option value="no" selected>No</option>
                                        </select>
                                      </div>
                                    </div>

                                        <div class="row" style="margin-top: 1%;">
                                            <div class="col-md-2 col-sm-6" id="saveBtn">
                                                <button type="button" class="btn btn-block btn-success"
                                                    (click)="goSave()"> Save </button> &nbsp;
                                            </div>

                                            <div class="col-md-2 col-sm-6" id="deleteBtn">
                                                <button type="button" class="btn btn-block btn-secondary"
                                                    (click)="delete()">Delete</button>
                                            </div>

                                            <div class="col-md-2 col-sm-6">
                                                <button type="button" class="btn btn-block btn-danger"
                                                    [routerLink]="['/twod-live-result']"> Cancel </button>
                                            </div>

                                        </div>
                                    </form>
                                </div>
                                <!-- /.card-body -->
                            </div>
                        </div>

                    </div>
                </form>
                <!-- /.row -->

                <!-- /.row -->
            </div>
            <!--/. container-fluid -->
        </section>
        <!-- /.content -->
    </div>

    <aside class="control-sidebar control-sidebar-dark">
        <!-- Control sidebar content goes here -->
    </aside>

    <app-footer></app-footer>

    <ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#fff" type="square-jelly-box">
        <p style="font-size: 16px; color: white">Loading...</p>
    </ngx-spinner>

</div>

<div id="deleteData" class="modal fade">
    <div class="modal-dialog">
        <div class="modal-content">

            <div class="modal-body">
                <form>

                    <div class="row" style="margin-top: 5%;margin-bottom: 3%;margin-left: 9%;">
                        <div class="form-group col-md-12">
                            <h5> Are you sure want to delete? </h5>
                        </div>
                    </div>

                    <div class="row" style="margin-left: 10%;margin-bottom: 3%;">
                        <div class="col-md-5 col-sm-6" id="deleteBtn">
                            <button type="button" class="btn btn-block btn-success" (click)="deleteOk()"> Ok </button>
                        </div>

                        <div class="col-md-5 col-sm-6">
                            <button type="button" class="btn btn-block btn-danger" [routerLink]="['/twod-list']"> Cancel </button>
                        </div>
                    </div>

                </form>
            </div>

        </div>
    </div>
</div>


<div id="deleteData1" class="modal fade">
    <div class="modal-dialog">
        <div class="modal-content">

            <div class="modal-body">
                <form>

                    <div class="row" style="margin-top: 5%;margin-bottom: 3%;margin-left: 9%;">
                        <div class="form-group col-md-12">
                            <h5> This Account is used by another device. 
                                Please Sign Out. Login Again. </h5>
                        </div>
                    </div>

                    <div class="row" style="margin-left: 40%;margin-bottom: 3%;">
                        <div class="col-md-5 col-sm-6" id="deleteBtn">
                            <button type="button" class="btn btn-block btn-success" (click)="OkLogout()"> Ok </button>
                        </div>
                    </div>
                </form>
            </div>

        </div>
    </div>
</div>
